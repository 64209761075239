export const ArrayHelper ={
    sort: (data: any[], order:string, orderBy: string) => {
        const sortGreaterThanValue = order === 'asc' ? 1 : -1;
        const sortLessThanValue = order === 'asc' ? -1 : 1;
        const fieldName = orderBy;

        data.sort((s1, s2) => {

            let s1FieldValue = s1[fieldName];
            if (typeof s1FieldValue === 'string') {
                s1FieldValue = s1FieldValue.toLowerCase();
            }

            let s2FieldValue = s2[fieldName];
            if (typeof s2FieldValue === 'string') {
                s2FieldValue = s2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof s1FieldValue === 'string' && typeof s2FieldValue === 'string') {
                const value = s1FieldValue.localeCompare(s2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (s1FieldValue > s2FieldValue) {
                return sortGreaterThanValue;
            }
            if (s1FieldValue < s2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        return data;
    },
    sum<T>(data: T[], selector: (obj: T) => number) {
        return data.reduce((a, b) => a + selector(b), 0)
    }
}

export default ArrayHelper;