import { EventSchedule, Journey, JourneyCategory, JourneyEvent } from "../entities/Journey";
import { JwtUser } from "../entities/JwtUser";
import Reminder from "../entities/Reminder";
import { addWeeks } from "date-fns";
import React from "react";

export const SupportedPersonalization = [
	"{{Address}}",
	"{{Church}}",
	"{{Event}}",
	"{{First_Name}}",
	"{{Form}}",
	"{{Full_Name}}",
	"{{Journey}}",
	"{{Keyword}}",
	"{{Link}}",
	"{{Media}}",
];

const createEventDate = (event: JourneyEvent, reminder: Reminder | undefined) => {
	let date = event.nextEventTime;

	// This event time review is based on a future event reminder
	if (reminder && reminder.scheduledTime.getTime() > event.nextEventTime.getTime()) {
		if (event.schedule === EventSchedule.Weekly) {
			date = addWeeks(date, 1);
		} else if (event.schedule === EventSchedule.BiWeekly) {
			date = addWeeks(date, 2);
		}
	}

	let hours = date.getHours();
	let minutes = date.getMinutes();
	let isPM = false;

	if (hours > 12) {
		hours = hours - 12;
		isPM = true;
	}

	let timeDate = `${date.toLocaleString("default", { month: "short" })} ${
		date.getDate() < 10 ? "0" : ""
	}${date.getDate()} at ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${isPM ? "PM" : "AM"}`;

	return timeDate;
};

const PersonalizedSpan = (props: {value?: string}) => <span style={{textDecoration: "underline"}}>{props.value}</span>;

const doubleBracketRegex = new RegExp("({{.*?}})");

export const PersonalizedMessage = (props: {message: string, journey?: Journey, user: JwtUser, reminder?: Reminder}) => {
	const {message: str, journey, user, reminder} = props;

	const tokenSplit = str.split(doubleBracketRegex);
	const personalizedElements: React.ReactNode = tokenSplit.map((token, index) => {
		switch (token) {
			case "{{First_Name}}":
				return <PersonalizedSpan key={index} value={"John"} />;
			case "{{Form}}":
				return <PersonalizedSpan key={index} value={"https://rspns.to/aC12d"} />;
			case "{{Full_Name}}":
				return <PersonalizedSpan key={index} value={"John Smith"} />;
			case "{{Church}}":
				return <PersonalizedSpan key={index} value={user.activeChurchName ? user.activeChurchName : "Best Church"} />;
			case "{{Address}}":
				return journey?.extras.address ? <PersonalizedSpan key={index} value={`${journey.extras.address.street}, ${journey.extras.address.zip}`} /> : "{{Address}}";
			case "{{Event}}":
				return journey?.category === JourneyCategory.Informational && journey.event
						? <PersonalizedSpan key={index} value={createEventDate(journey.event, reminder)} />
						: "{{Event}}";
			case "{{Journey}}":
				return <PersonalizedSpan key={index} value={journey?.name} />;
			case "{{Keyword}}":
				return <PersonalizedSpan key={index} value={journey?.keywordTrigger} />;
			case "{{Link}}":
				return journey?.extras.link ? <PersonalizedSpan key={index} value={"https://rspns.to/4Gh1c"} /> : "{{Link}}";
			case "{{Media}}":
				return journey?.extras.mediaLink ? <PersonalizedSpan key={index} value={"https://rspns.to/1Va3d"} /> : "{{Media}}";
			default:
				return token;
		}
	});

	return <React.Fragment children={personalizedElements} />;
};
