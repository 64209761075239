import React, { useEffect, useState } from "react";
import { Journey } from "../entities/Journey";
import JourneyResponse from "../entities/JourneyResponse";
import { Keyword } from "../entities/Keywords/Keyword";
import { useHubEvent } from "../hooks/useHubEvent";
import { useHubSubscription } from "../hooks/useHubSubscription";
import { JourneyDetailContext } from "../hooks/useJourneyDetailContext";
import JourneyResponseService, { convertResponse } from "../services/JourneyResponseService";
import { KeywordService } from "../services/KeywordService";
import { ServerError } from "../services/server/ServerError";
import { ServerResult } from "../services/server/ServerResult";

interface Props {
	journey: Journey;
}

export default function JourneyDetailProvider(props: React.PropsWithChildren<Props>) {
	const { journey } = props;
	const [keyword, setKeyword] = useState<Keyword>();
	const [responses, setResponses] = useState<JourneyResponse[]>([]);
	const [responsesLoadError, setResponsesLoadError] = useState<ServerError>();

	const disableState = useState(false);

	useEffect(() => {
		async function loadJourneyData(journey: Journey) {
			const results = await Promise.all([
				JourneyResponseService.GetResponses(journey.id),
				KeywordService.getJourneyKeyword(journey.id)
			]) 

			const [responseResult, keywordResult] = results;

			if (ServerResult.isSuccess(responseResult)) {
				setResponses(responseResult.data);
			} else {
				setResponsesLoadError(responseResult);
			}

			if (ServerResult.isSuccess(keywordResult)) {
				setKeyword(keywordResult.data);
			}
		}


		if (journey) {
			setResponses([]);
			setResponsesLoadError(undefined);
			setKeyword(undefined);
			loadJourneyData(journey);
		}
	}, [journey]);

	const onResponseEvent = (response:JourneyResponse) => {
		setResponses(responses => responses.some((r) => r.id === response.id) ? responses.map(r => r.id === response.id ? response : r) : [response, ...responses])
	}
	
	useHubSubscription({methodName: "SubscribeToJourney", args: [journey.id]}, {methodName: "UnsubscribeFromJourney", args: [journey.id]});
	useHubEvent("OnJourneyResponseEvent", (response:JourneyResponse) => onResponseEvent(convertResponse(response)));

	const updateResponse = (response: JourneyResponse) => {
		if (!responses) return;
		const updateResponses = responses.map((r) => {
			if (r.id === response.id) return response;
			return r;
		});
		setResponses(updateResponses)
	};

	return (
		<JourneyDetailContext.Provider
			value={{
				responses,
				keyword,
				responsesLoadError,
				disableState,
				updateResponse,
			}}
		>
			{props.children}
		</JourneyDetailContext.Provider>
	);
}