import {
	Checkbox,
	Collapse,
	Fade,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import FollowedUpIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import NotFollowedUpIcon from "@material-ui/icons/RadioButtonUnchecked";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/loaderSpinner";
import ResponseSwitch from "../../components/responseSwitch";
import { Journey } from "../../entities/Journey";
import JourneyResponse from "../../entities/JourneyResponse";
import { SubtractDaysReturnDate, TimeSinceMessage } from "../../functions/datetime";
import { useJourneyDetailContext } from "../../hooks/useJourneyDetailContext";
import { useJourneyNotifications } from "../../hooks/useJourneyNotifications";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { routes } from "../../routes";
import JourneyResponseService, { UpdateFollowUpStatusRequest } from "../../services/JourneyResponseService";
import JourneyService, { ChangeFollowUpStatusRequest } from "../../services/JourneyService";
import { ServerResult } from "../../services/server/ServerResult";
import { orderBy } from "../../utillity/orderBy";
import { useJourneyRouter } from "../Journeys/JourneyPage";
import MobileContactModal from "./MobileContactModal";

export const useStyles = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	input: {
		borderRadius: 8,
		height: 40,
		paddingLeft: 16,
		borderColor: "#D3DCE6",
		color: "#818A91",
	},
	grow: {
		flex: 1,
	},
	dateSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
		[theme.breakpoints.down("md")]: {
			width: 240,
		},
	},
	checkboxLabelRoot: {
		marginRight: 0,
	},
	followUpCheckBox: {
		color: theme.palette.secondaryResponse.main,
	},
	followUpHintBox: {
		backgroundColor: theme.palette.primaryResponse.main,
		borderRadius: 8,
		width: "100%",
		marginTop: 12,
	},
	hintsFollowTextStyle: {
		fontWeight: 400,
		fontSize: 18,
	},
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
}));

enum ResponsesDateRange {
	Last_7_Days = 7,
	Last_30_Days = 30,
	All_Time = 0,
}

enum ResponseFilter {
	All = 0,
	Followed_Up = 1,
	Not_Followed_Up = 2,
}

interface ResponseTabProps {
	journey: Journey;
}

const ResponsesTabMobile = (props: ResponseTabProps) => {
	const classes = useStyles();
	const setErrorAlert = useServerErrorAlert();
	const history = useHistory();
	const { journey } = props;
	const journeyDetailContext = useJourneyDetailContext();
	const journeyRouter = useJourneyRouter();
	const { responses: journeyResponses } = journeyDetailContext;
	const [isDisabled, setIsDisabled] = journeyDetailContext.disableState;

	const [dateRange, setDateRange] = useState(ResponsesDateRange.All_Time);
	const [filterByResponses, setFilterByResponses] = useState(ResponseFilter.All);
	const [searchText, startSearchPeople] = useState("");
	const [responses, setResponses] = useState(journeyResponses);
	const [selectedResponseToContact, setSelectedResponseToContact] = useState<JourneyResponse>();
	const notificationContext = useJourneyNotifications();

	let showFollowUpToolTipHint: boolean = true;
	if (window.localStorage) {
		showFollowUpToolTipHint =
			localStorage.getItem("followUpHintOn") != null ? localStorage.getItem("followUpHintOn") === "true" : true;
	}

	const [followUpHintOn, setFollowUpHintStatus] = useState(showFollowUpToolTipHint);

	useEffect(() => {
		const subscription = notificationContext.journeyNotificationSubscriptions.find((s) => s.journeyId === journey.id);
		if (subscription && subscription.unreadCount > 0) {
			notificationContext.markJourneyAsRead(subscription.subscriptionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onJourneyFollowUpToggle = async () => {
		let req: ChangeFollowUpStatusRequest = {
			journeyId: journey.id,
			followUpEnabled: !journey.followUpEnabled,
		};
		setIsDisabled(true);
		let response = await JourneyService.ChangeFollowUpStatus(req);
		setIsDisabled(false);
		if (ServerResult.isSuccess(response)) {
			journeyRouter.updateJourney(response.data);
		} else {
			setErrorAlert(response);
		}
	};

	const onResponseFollowUpToggle = async (journeyResponse: JourneyResponse) => {
		let req: UpdateFollowUpStatusRequest = {
			responseId: journeyResponse.id,
			haveFollowedUp: !journeyResponse.haveFollowedUp,
		};

		setIsDisabled(true);
		let response = await JourneyResponseService.updateFollowUpStatus(req);
		setIsDisabled(false);
		if (ServerResult.isSuccess(response)) {
			journeyDetailContext.updateResponse(response.data);
		} else {
			setErrorAlert(response);
		}
	};

	const onFollowUpCall = async (journeyResponse: JourneyResponse) => {
		setSelectedResponseToContact(journeyResponse);
	};

	const onFollowedUpTextOrCall = async (journeyResponse: JourneyResponse) => {
		if (!journeyResponse.haveFollowedUp) {
			await onResponseFollowUpToggle(journeyResponse);
		}
	};

	const showFollupUpToolTipHint = (turnOn: boolean) => {
		setFollowUpHintStatus(turnOn);

		if (window.localStorage) {
			localStorage.setItem("followUpHintOn", turnOn + "");
		}
	};

	useEffect(() => {
		if(!journeyResponses){
			return;
		}

		let filteredResponses = journeyResponses;
		if (dateRange !== ResponsesDateRange.All_Time) {
			let lowerDate =
				dateRange === ResponsesDateRange.Last_7_Days
					? SubtractDaysReturnDate(new Date(Date.now()), 7)
					: SubtractDaysReturnDate(new Date(Date.now()), 30);

			filteredResponses = filteredResponses.filter((response) => response.lastTriggeredTimestampUtc > lowerDate);
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredResponses = filteredResponses.filter((response) =>
				(response.attendeeName + response.attendeePhoneNumber).toLowerCase().includes(queryText)
			);
		}

		if (filterByResponses === ResponseFilter.Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => response.haveFollowedUp);
		} else if (filterByResponses === ResponseFilter.Not_Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => !response.haveFollowedUp);
		}

		filteredResponses = filteredResponses
			.filter((a) => a.messageHistory.some((a) => !a.fromSystem))
			.sort(
				orderBy.date(
					(a) =>
						a.messageHistory
							.sort(orderBy.date((h) => h.timestampUtc, "Ascending"))
							.filter((message) => !message.fromSystem)
							.pop()!.timestampUtc,
					"Descending"
				)
			);

		setResponses(filteredResponses);
	}, [dateRange, searchText, filterByResponses, journeyResponses]);

	const renderLoadingPage = () => {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={false} durationMessage={"Loading..."} />
			</div>
		);
	};

	if(!responses){
		return renderLoadingPage();
	}

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid item xs={12}>
					<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
						{responses.length} Response{responses.length > 0 && "s"}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={followUpHintOn}>
						<div className={classNames(classes.followUpHintBox, classes.cardGlowEffect)}>
							<Grid container style={{ padding: 8 }}>
								<Grid item xs={12} style={{ display: "flex" }}>
									<FormControlLabel
										disabled={isDisabled}
										control={
											<ResponseSwitch
												checked={props.journey.followUpEnabled}
												onChange={onJourneyFollowUpToggle}
												name="disable-enable"
											/>
										}
										label={`Follow-up ${props.journey.followUpEnabled ? "on" : "off"}`}
										style={{ marginRight: 0, fontSize: 20 }}
										classes={{
											label: classes.hintsFollowTextStyle,
										}}
									/>
									<div className={classes.grow} />
									<IconButton
										aria-label="close modal"
										component="span"
										onClick={() => showFollupUpToolTipHint(false)}
										style={{ paddingRight: 0, paddingTop: 4 }}
									>
										<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
									</IconButton>
								</Grid>
								<Grid item xs={12} style={{ color: "#FFF", fontSize: 16 }}>
									<Typography variant="h3" style={{ fontWeight: 300 }}>
										Turn this on to track who you have messaged or called in this journey.
									</Typography>
								</Grid>
								<Grid item xs={1} md={2} />
								<Grid item xs={10} md={8}>
									<div
										className={classes.cardGlowEffect}
										style={{
											backgroundColor: "#f8faff",
											borderRadius: 8,
											width: "100%",
											height: 56,
											marginBottom: -34,
											marginTop: 8,
											display: "flex",
											padding: 12,
										}}
									>
										<FormControlLabel
											disabled={isDisabled}
											classes={{
												root: classes.checkboxLabelRoot,
											}}
											control={
												<Checkbox
													icon={<NotFollowedUpIcon />}
													checkedIcon={<FollowedUpIcon />}
													checked={true}
													onChange={() => undefined}
													className={classes.followUpCheckBox}
													color="default"
												/>
											}
											label=""
										/>
										<div>
											<Typography variant="h4">John F.</Typography>
											<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15 }}>
												3 hr
											</Typography>
										</div>
										<div className={classes.grow} />
										<img
											alt="call"
											style={{ width: 22, height: 22, marginTop: 5, marginRight: 3 }}
											src={"https://i.cdn-sc.com/Response/icons/call-message.png"}
										/>
									</div>
								</Grid>
								<Grid item xs={1} md={2} />
							</Grid>
						</div>
					</Collapse>
					<Fade in={!followUpHintOn}>
						<Grid container style={{ padding: 8 }}>
							<Grid item xs={12} style={{ display: "flex" }}>
								<FormControlLabel
									disabled={isDisabled}
									control={
										<ResponseSwitch
											checked={props.journey.followUpEnabled}
											onChange={onJourneyFollowUpToggle}
											name="disable-enable"
										/>
									}
									label={`Follow-up ${props.journey.followUpEnabled ? "on" : "off"}`}
									style={{ marginRight: 0, fontSize: 20 }}
									classes={{
										label: classes.hintsFollowTextStyle,
									}}
								/>
								<IconButton
									aria-label="close modal"
									component="span"
									onClick={() => showFollupUpToolTipHint(true)}
									style={{ paddingRight: 0, color: "#1C4057" }}
								>
									<InfoIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Fade>
				</Grid>
				<Grid item xs={12} style={{ marginTop: !followUpHintOn ? 0 : -24 }}>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchPeople(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
						style={{ width: "100%", marginTop: 0 }}
					/>
				</Grid>
				<Grid item xs={12} style={{ display: "flex" }}>
					{journey.followUpEnabled && (
						<FormControl>
							<Select
								disableUnderline
								labelId="sort-people-select-label"
								id="sort-people-select"
								value={filterByResponses}
								className={classes.dateSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setFilterByResponses(parseInt(event.target.value as string))
								}
							>
								<MenuItem value={ResponseFilter.All}>All Responses</MenuItem>
								<MenuItem value={ResponseFilter.Followed_Up}>Followed up</MenuItem>
								<MenuItem value={ResponseFilter.Not_Followed_Up}>Not Followed up</MenuItem>
							</Select>
						</FormControl>
					)}
					<div className={classes.grow} />
					<FormControl>
						<Select
							disableUnderline
							labelId="date-range-select-label"
							id="date-range-select"
							value={dateRange}
							className={classes.dateSelector}
							onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
								setDateRange(parseInt(event.target.value as string))
							}
						>
							<MenuItem value={ResponsesDateRange.Last_7_Days}>Last 7 Days</MenuItem>
							<MenuItem value={ResponsesDateRange.Last_30_Days}>Last 30 Days</MenuItem>
							<MenuItem value={ResponsesDateRange.All_Time}>All Time</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				{responses.length === 0 && (
					<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
						<div
							className={classes.cardGlowEffect}
							style={{
								backgroundColor: "#FFF",
								borderRadius: 8,
								width: "100%",
								height: 70,
								display: "flex",
								padding: 12,
							}}
						>
							<Typography style={{ fontWeight: 400 }} variant="h4">
								Nothing is here...
							</Typography>
						</div>
					</Grid>
				)}
				{responses.map((response: JourneyResponse, index: number) => {
					return (
						<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }} key={index}>
							<div
								className={classes.cardGlowEffect}
								style={{
									backgroundColor: "#FFF",
									borderRadius: 8,
									width: "100%",
									height: 70,
									display: "flex",
									padding: 12,
								}}
							>
								{journey.followUpEnabled && (
									<FormControlLabel
										classes={{
											root: classes.checkboxLabelRoot,
										}}
										control={
											<Checkbox
												icon={<NotFollowedUpIcon />}
												checkedIcon={<FollowedUpIcon />}
												checked={response.haveFollowedUp}
												onChange={() => onResponseFollowUpToggle(response)}
												className={classes.followUpCheckBox}
												color="default"
											/>
										}
										label=""
									/>
								)}
								<div>
									<Link
										style={{ textDecoration: "none" }}
										to={routes.app.resolve.peopleDetailPage(response.attendeeId)}
									>
										<Typography variant="h4">{response.attendeeName}</Typography>
									</Link>

									<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15, display: "inline-flex" }}>
										{TimeSinceMessage(
											response.messageHistory
												.sort(orderBy.date((h) => h.timestampUtc, "Ascending"))
												.filter((a) => !a.fromSystem)
												.pop()
												?.timestampUtc.toString() ?? ""
										)}
										:&nbsp;
										<Typography
											onClick={() => history.push(routes.app.resolve.inboxDetailPage(response.attendeeId))}
											variant="body2"
											style={{
												color: "#1976d2",
												textDecoration: "underline",
												cursor: "pointer",
												maxWidth: 80,
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{response.messageHistory.filter((a) => !a.fromSystem).pop()?.text}
										</Typography>
									</Typography>
								</div>
								<div className={classes.grow} />
								<img
									onClick={() => onFollowUpCall(response)}
									alt="call"
									style={{ width: 26, height: 26, marginTop: 9, marginRight: 3 }}
									src={"https://i.cdn-sc.com/Response/icons/call-message.png"}
								/>
							</div>
						</Grid>
					);
				})}
			</Grid>
			{selectedResponseToContact !== undefined && (
				<MobileContactModal
					open={selectedResponseToContact !== undefined}
					onClose={() => setSelectedResponseToContact(undefined)}
					responder={selectedResponseToContact}
					followedUp={(selectedResponseToContact) => onFollowedUpTextOrCall(selectedResponseToContact)}
				/>
			)}
		</div>
	);
};

export default ResponsesTabMobile;
