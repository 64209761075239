import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { PropsWithChildren, useEffect } from "react";
import { useHistory } from "react-router";
import { ResponseDialogLayout } from "./Layouts/ResponseDialogLayout";


interface ResponseDialogProps {
    onClose: () => void;
    open: boolean;
	title: string;
}

export function ResponseDialog(props: PropsWithChildren<ResponseDialogProps>) {
    const classes = useStyles();
    const theme = useTheme();
	const history = useHistory();
    const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => history.listen(() => props.onClose()), [history])
	
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			maxWidth="md"
			fullWidth
			classes={{
				paper: classNames(mobileFormFactor ? classes.mobileBackdrop : undefined),
			}}
			fullScreen={mobileFormFactor}
		>
			<ResponseDialogLayout title={props.title} onClose={props.onClose}>
				{props.children}
			</ResponseDialogLayout>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	mobileBackdrop: {
		backgroundColor: "#F5F6F7",
	},
}));