import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { Journey, JourneyType } from "../entities/Journey";
import { isMobile } from "../hooks/isMobile";

const useStyles = makeStyles((theme: Theme) => ({
	overlayRoot: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "calc(100vh - 64px)",
		zIndex: 1300,
		position: "absolute",
		width: "100%",
	},
	actionButton: {
		backgroundColor: theme.palette.primaryResponse.main,
		color: "#FFF",
		height: 42,
		borderRadius: 8,
		paddingLeft: 16,
		paddingRight: 16,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.primaryResponse.main,
		},
	},
	secondaryColor: {
		color: theme.palette.secondaryResponse.main,
	},
	overlayContent: {
		marginTop: -80,
		maxWidth: 410,
	},
}));

interface OverlayProps {
	journey?: Journey
	onButtonClick: () => void;
	buttonText?: string;
}

const OverlayInterrupt = (props: OverlayProps) => {
	const classes = useStyles();
	const mobile = isMobile();
	const [displayText, setDisplayText] = useState({ title: "", body: "", proTip: "" });

	useEffect(() => {
		if(props.journey)
			setDisplayText(displayTextForJourney(props.journey));
		else
			setDisplayText(
				{
					title: "Create your first group",
					body: "Organize and message people at your church. Create a new group to get started.",
					proTip: "Pro Tip: Move people into groups so you can message them"
				}
			)
	}, [props.journey]);

	const displayTextForJourney = (journey: Journey) => {
		switch (journey.type) {
			case JourneyType.Connect_Card:
				return {
					title: "Welcome to Connect Cards",
					body: "The connect card journey allows you to easily connect with first time visitors to your church.",
					proTip: "Pro Tip: Add a link to your website where guests can find out more info.",
				};
			case JourneyType.Sermon_Prompts:
				return {
					title: "Welcome to Sermon Prompts",
					body:
						"Sermon prompts lets you easily send short text prompts throughout the week to reinforce your previous sermon or prepare them for the upcoming sermon.",
					proTip: "Pro Tip: Add direct links to the scripture for the week.",
				};
			case JourneyType.Prayer_Requests:
				return {
					title: "Welcome to Prayer Requests",
					body: "The prayer request journey allows anyone to easily submit prayer requests by texting the keyword.",
					proTip:
						"Pro Tip: Use the Follow Up feature to mark when a request has been prayed for and then send a personal text to let the person know you are praying for them.",
				};
			default:
				return {
					title: "",
					body: "This is a pre-set keyword that only admins have access to.",
					proTip: "Click here to set-up and activate: ",
				};
		}
	};

	return (
		<div className={classes.overlayRoot}>
			<div className={classes.overlayContent} style={{ padding: mobile ? 40 : 0 }}>
				<Grid container>
					<Grid item xs={12} style={{ marginBottom: 12 }}>
						<Typography className={classes.secondaryColor} variant="h2">
							{displayText.title}
						</Typography>
					</Grid>

					<Grid item xs={12} style={{ marginBottom: 12 }}>
						<Typography className={classes.secondaryColor} variant="h3">
							{displayText.body}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.secondaryColor} variant="h3">
							{displayText.proTip}
						</Typography>
					</Grid>
				</Grid>
				<br />
				<Grid direction="row" container>
					<Grid style={{ textAlign: "center" }} item xs={12}>
						<Button className={classes.actionButton} variant="contained" onClick={() => props.onButtonClick()}>
							{props.buttonText ?? "Set Up"}
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default OverlayInterrupt;
