import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { Group } from "../../../entities/Groups/Group";
import { useGroups } from "../../../providers/GroupProvider";
import GroupMultiSelect from "../../Groups/Components/GroupMultiSelect";
import { ImportPeopleCsvModel, useCsvFileStepStyles } from "./UploadCsvFileStep";
import BackArrow from "@material-ui/icons/KeyboardArrowLeft";
import NextArrow from "@material-ui/icons/KeyboardArrowRight";
import { isMobile } from "../../../hooks/isMobile";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";

interface SelectGroupsForCsvStepProps {
	rows: ImportPeopleCsvModel[];
	onBack: () => void;
	onSelectedGroups: (groups: Group[]) => void;
}

export function SelectGroupsForCsvStep(props: SelectGroupsForCsvStepProps) {
	const { rows } = props;
	const xsMobile = isMobile("xs");
	const classes = useCsvFileStepStyles();

	const groupsContext = useGroups();
	const [groups, setGroups] = useState<Group[]>([]);

	const whoIsAdded = rows.length === 1 ? `${rows[0].firstName} ${rows[0].lastName}` : `${rows.length} people`;

	return (
		<VerticalCenter>
			<Grid container justify="center" spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h3" align="center">
						Ready to import <strong>{whoIsAdded}</strong>. Do you also want to add them to a group?
					</Typography>
				</Grid>
				<Grid item>
					<GroupMultiSelect
						style={{maxWidth: 450}}
						groups={groupsContext.groups}
						onSelectedGroupsChange={setGroups}
						placeHolder="Select groups (optional)"
					/>
					<Typography variant="subtitle2" style={{paddingTop: 5}}>
						Select groups above and instantly add {rows.length > 1 ? `all ${whoIsAdded}` : whoIsAdded} on import.
						<br /> Or <ClientLink to={routes.app.resolve.createGroupPage()} color="primary">create a new group</ClientLink> and return back here to import.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="center" spacing={3}>
						<Grid item sm="auto" xs={6}>
							<Button
								variant="contained"
								color="default"
								onClick={props.onBack}
								fullWidth
								size="large"
								className={!xsMobile ? classes.fatButton : undefined}
								startIcon={<BackArrow />}
							>
								Back
							</Button>
						</Grid>
						<Grid item sm="auto" xs={6}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => props.onSelectedGroups(groups)}
								fullWidth
								size="large"
								className={!xsMobile ? classes.fatButton : undefined}
								endIcon={<NextArrow />}
							>
								{groups.length > 0
									? `Add to ${groups.length === 1 ? "group" : `${groups.length} groups`}`
									: "Skip"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</VerticalCenter>
	);
}
