import React, { createContext, PropsWithChildren, useContext, useState } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorPage } from "../../components/ServerErrorPage";
import Attendee from "../../entities/Attendee";
import { useAppData } from "../../providers/AppDataProvider";
import { useAttendees } from "../../providers/AttendeeProvider";
import { routes } from "../../routes";
import { PeopleDetailsPage } from "./PeopleDetailsPage";
import { PeopleListPage } from "./PeopleListPage";

export function PeoplePage(){
    const params = useParams<{ attendeeId?: string }>();
	const appData = useAppData();
	const { groupsLoading, groupServerError, attendeesLoading, attendeeServerError} = appData;	

	if (attendeesLoading || groupsLoading) {
		return <LoadingPage />;
	}

	if(attendeeServerError)
		return <ServerErrorPage serverError={attendeeServerError}/>

	if(groupServerError)
		return <ServerErrorPage serverError={groupServerError}/>

    return (
        <PeopleRouterProvider attendeeId={params.attendeeId}>
            <AttendeePageRouter />
        </PeopleRouterProvider>
    );
}

interface PeopleRouterContext {
    selectedAttendee: Attendee | undefined;
	selectedAttendees: Attendee[];
	selectAttendee: (attendee: Attendee | undefined) => void;
	selectAttendees: (attendees: Attendee[]) => void;
	createAttendee: (attendee: Attendee) => void;
	deleteAttendee: (attendee: Attendee) => void;
}

const PeopleRouterReactContext = createContext<PeopleRouterContext>({} as any);

export function usePeopleRouter() {
	return useContext(PeopleRouterReactContext);
}

function PeopleRouterProvider(props: PropsWithChildren<{ attendeeId: string | undefined }>) {
	const history = useHistory();
	const attendeeContext = useAttendees();

	const [selectedAttendee, setSelectedAttendee] = useState(attendeeContext.attendees.find((g) => g.id === props.attendeeId));
	const [selectedAttendees, setSelectedAttendees] = useState<Attendee[]>([]);

	const onSelect = (attendee: Attendee | undefined) => {
		setSelectedAttendee(attendee);
        if(attendee){
            history.push(routes.app.resolve.peopleDetailPage(attendee.id));
        } else {
            history.push(routes.app.resolve.peopleListPage());
        }
	};

	const onCreate = (attendee: Attendee) => {
		attendeeContext.create(attendee);
		setSelectedAttendee(attendee);
		history.push(routes.app.resolve.peopleDetailPage(attendee.id));
	};

    const onDelete = (attendee: Attendee) => {
        attendeeContext.delete(attendee);
        onSelect(undefined);
    }

	return (
		<PeopleRouterReactContext.Provider value={{ 
			selectedAttendee, 
			selectedAttendees, 
			selectAttendees: setSelectedAttendees,
			createAttendee: onCreate,
			selectAttendee: onSelect,
			deleteAttendee: onDelete 
		}}>
			{props.children}
		</PeopleRouterReactContext.Provider>
	);
}

function AttendeePageRouter() {
	const attendeeRouter = usePeopleRouter();

	return (
		<Switch>
			<Route exact path={[routes.app.peopleListPage, routes.app.invitePersonPage]}>
				<PeopleListPage/>
			</Route>
			{attendeeRouter.selectedAttendee && (
				<Route exact path={routes.app.peopleDetailPage}>
					<PeopleDetailsPage selectedAttendee={attendeeRouter.selectedAttendee} />
				</Route>
			)}
			<Redirect to={routes.app.peopleListPage} />
		</Switch>
	);
}