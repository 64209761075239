import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AwesomeCheckbox } from "../../../components/AwesomeCheckbox";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import Attendee from "../../../entities/Attendee";
import { ChurchOptInStatus } from "../../../entities/ChurchOptInStatus";
import { useAppUser } from "../../../hooks/useAppUser";
import { ButtonLink } from "../ButtonLink";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { VerticalCenter } from "../../../components/VerticalCenter";

interface ConfirmAddToGroupProps {
	attendees: Attendee[];
	onAddAnother: () => void;
	onClose: () => void;
}

export function ConfirmAddToGroup(props: ConfirmAddToGroupProps) {
	const { attendees } = props;
	const attendee = attendees[0];
	const [millisecondsSinceCreation] = useState(Math.abs(new Date().getTime() - attendee.dateCreated.getTime()));
	const recentlyCreated = millisecondsSinceCreation / 1000 <= 100;
	const successfulAdd =
		attendee.optInStatus === ChurchOptInStatus.Accepted ||
		(attendee.optInStatus === ChurchOptInStatus.Pending && recentlyCreated);
	const pendingInvite = attendee.optInStatus === ChurchOptInStatus.Pending && !recentlyCreated;
	const optedOut = attendee.optInStatus === ChurchOptInStatus.Declined;


	return (
		<>
			<ResponseDialogContent>
				{ attendees.length === 1
					?
					<VerticalCenter>
						{successfulAdd && <Success {...props} />}
						{pendingInvite && <PendingInvite {...props} />}
						{optedOut && <DeclinedInvite {...props} />}
					</VerticalCenter>
					:
					<VerticalCenter>
						<MultiAdd {...props}/>
					</VerticalCenter>
				}
			</ResponseDialogContent>
			<ResponseDialogActions
				hideSecondaryButton={true}
				backButtonText="Back"
				onBack={props.onAddAnother}
				nextButtonText="Done"
				onConfirm={props.onClose}
			/>
		</>
	);
}

function Success(props: ConfirmAddToGroupProps) {
	const attendee = props.attendees[0];
	const [user] = useAppUser();

	const sentInvite = `Woo! ${attendee.firstName} has been invited to receive messages from ${user.activeChurchName}.`;
	const alreadyInvited = `Nice, ${attendee.firstName} is ready to receive messages.`;

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<AwesomeCheckbox size={90} color="secondary" />
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					{attendee.optInStatus === ChurchOptInStatus.Pending ? sentInvite : alreadyInvited}
				</Typography>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Would you like to <ButtonLink onClick={props.onAddAnother}>add more people</ButtonLink>?
				</Typography>
			</Grid>
		</Grid>
	);
}

function MultiAdd(props: ConfirmAddToGroupProps) {
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<AwesomeCheckbox size={90} color="secondary" />
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Well done, your group is being updated.
				</Typography>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Would you like to <ButtonLink onClick={props.onAddAnother}>add more people</ButtonLink>?
				</Typography>
			</Grid>
		</Grid>
	);
}

function PendingInvite(props: ConfirmAddToGroupProps) {
	const attendee = props.attendees[0];
	const [user] = useAppUser();

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<AnnouncementIcon style={{ width: 100, height: 100 }} color="primary" />
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Oops, looks like {attendee.firstName} was previously invited but didn't reply YES.
				</Typography>
				<Typography align="center" variant="body2" style={{ fontSize: 18, marginTop: 6 }}>
					Contact {attendee.firstName} directly and ask them to text the word <strong>YES</strong> to{" "}
					{user.responsePhoneNumber ? formatPhoneNumber(user.responsePhoneNumber) : "your response phone number"}.
				</Typography>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Would you like to <ButtonLink onClick={props.onAddAnother}>add more people</ButtonLink>?
				</Typography>
			</Grid>
		</Grid>
	);
}

function DeclinedInvite(props: ConfirmAddToGroupProps) {
	const attendee = props.attendees[0];
	const [user] = useAppUser();

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<ErrorOutlineIcon style={{ width: 100, height: 100 }} color="primary" />
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Uh oh, {attendee.firstName} has unsubscribed from receiving text messages from {user.activeChurchName}.
				</Typography>
				<Typography align="center" variant="body2" style={{ fontSize: 18, marginTop: 6 }}>
					Contact {attendee.firstName} directly and ask them to text the word <strong>SUBSCRIBE</strong> to{" "}
					{user.responsePhoneNumber ? formatPhoneNumber(user.responsePhoneNumber) : "your response phone number"}.
				</Typography>
			</Grid>
			<Grid item>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Would you like to <ButtonLink onClick={props.onAddAnother}>add more people</ButtonLink>?
				</Typography>
			</Grid>
		</Grid>
	);
}
