import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { Journey, JourneyAddress } from "../../../entities/Journey";
import { AddressAutoComplete, GoogleMapsAddress } from "../../AddressAutoComplete";
import { JourneyStepLayout, useJourneyStepStyles } from "../Steps/JourneyStepLayout";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { AddMessageExtraLayout } from "./AddMessageExtraLayout";

type StepProps = {
	mode: "step";
	journey: Journey;
};

type AddProps = {
	mode: "add";
	journey: Journey;
	title: string;
	onCancel: () => void;
	onUpdate: (updatedJourney: Journey) => void;
};

type Props = StepProps | AddProps;

const formatAddress = (journeyAddress: JourneyAddress) => {
	const { street, city, state } = journeyAddress;
	return `${street}, ${city} ${state}`;
};

export function MessageAddressView(props: Props) {
	const classes = useJourneyStepStyles();
	const progress = useJourneySetupProgress();
	const journeyAddress = props.journey.extras.address;
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [address, setAddress] = useState<GoogleMapsAddress | undefined>(
		journeyAddress ? { ...journeyAddress, address: formatAddress(journeyAddress) } : undefined
	);

	const onStepComplete = () => {
		progress.completeStep(getUpdatedJourney());
	};

	const onStepSkipped = () => {
		progress.completeStep({ ...props.journey, extras: { ...props.journey.extras, address: undefined } });
	};

	const getUpdatedJourney = (): Journey => {
		return { ...props.journey, extras: { ...props.journey.extras, address } };
	};

	const view = () => {
		return (
			<>
				<Grid item style={{ textAlign: "center" }} xs={12}>
					<Typography className={classes.progressLabelText} variant="subtitle2" style={{ marginBottom: 30 }}>
						Where is the event?
					</Typography>
				</Grid>
				<Grid item style={{ textAlign: "center" }} xs={12}></Grid>
				<div style={{ width: mobileFormFactor ? "100%" : 300 }}>
					<AddressAutoComplete
						style={{ width: "100%" }}
						label="Address"
						variant="outlined"
						value={address?.address}
						onAddressChange={setAddress}
					/>
				</div>
			</>
		);
	};

	if (props.mode === "add") {
		return (
			<AddMessageExtraLayout
				title={props.title}
				onCancel={props.onCancel}
				onSave={() => props.onUpdate(getUpdatedJourney())}
			>
				{view()}
			</AddMessageExtraLayout>
		);
	}

	return (
		<JourneyStepLayout required={false} onConfirmStep={onStepComplete} skip={address == null} onSkip={onStepSkipped}>
			{view()}
		</JourneyStepLayout>
	);
}
