import { Animation } from "@devexpress/dx-react-chart";
import { ArgumentAxis, BarSeries, Chart, ValueAxis } from "@devexpress/dx-react-chart-material-ui";
import { CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Answer, MultipleAnswer, SingleAnswer } from "../../../../entities/Forms/Answer";
import { QuestionType } from "../../../../entities/Forms/Question";

// const GraphicalOverviewDigestStyles = makeStyles((theme: Theme) => ({}));

interface Props {
	type: QuestionType;
	answers: Answer[];
}

interface GraphData {
	textGrouping: string;
	count: number;
}

interface CustomBar {
	arg: number;
	barWidth: number;
	maxBarWidth: number;
	val: number;
	startVal: number;
	color: string;
	value: string;
}

export function GraphicOverviewDigest(props: Props) {
	const theme = useTheme();
	// const classes = GraphicalOverviewDigestStyles();

	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [data, setData] = useState<GraphData[] | undefined>(undefined);

	useEffect(() => {
		function setUpData() {
			let uniqueTextArray: GraphData[] = [];

			props.answers.forEach((answer) => {
				if (props.type === QuestionType.SingleAnswer) {
					let singleAnswer = answer as SingleAnswer;

					let elementIndex = uniqueTextArray.findIndex((a) => a.textGrouping === singleAnswer.text);

					if (elementIndex > -1) {
						uniqueTextArray[elementIndex].count = uniqueTextArray[elementIndex].count + 1;
					} else {
						uniqueTextArray.push({ textGrouping: singleAnswer.text, count: 1 });
					}
				} else if (props.type === QuestionType.MultipleAnswer) {
					let singleAnswer = answer as MultipleAnswer;

					singleAnswer.texts.forEach((entry) => {
						let elementIndex = uniqueTextArray.findIndex((a) => a.textGrouping === entry);

						if (elementIndex > -1) {
							uniqueTextArray[elementIndex].count = uniqueTextArray[elementIndex].count + 1;
						} else {
							uniqueTextArray.push({ textGrouping: entry, count: 1 });
						}
					});
				}
			});

			setData(uniqueTextArray);
		}

		setUpData();
	}, [props]);

	const getPath = (x: any, width: any, y: any, y1: any) => `M ${x} ${y1}
   L ${width + x} ${y1}
   L ${width + x} ${y + 30}
   L ${x + width} ${y}
   L ${x} ${y}
   Z`;

	const BarWithLabel = (props: CustomBar) => {
		const { arg, barWidth, maxBarWidth, val, startVal, value } = props;

		const width = maxBarWidth * barWidth;
		return (
			<React.Fragment>
				<path d={getPath(arg - width / 2, width, val, startVal)} fill={theme.palette.secondaryResponse.main} />
				<Chart.Label
					x={arg}
					y={(val + startVal) / 2}
					dominantBaseline="middle"
					textAnchor="middle"
					style={{ fill: "#FFF" }}
				>
					{value}
				</Chart.Label>
			</React.Fragment>
		);
	};

	if (!data) {
		return (
			<div style={{ textAlign: "center", paddingTop: 40 }}>
				<CircularProgress style={{ color: theme.palette.secondaryResponse.main }} />
			</div>
		);
	}

	return (
		<div>
			<Chart data={data} width={mobileFormFactor ? window.innerWidth - 24 : 500} height={300}>
				<ArgumentAxis position="left" />
				<ValueAxis showTicks showGrid showLine={true} />

				{/* <BarSeries valueField="count" argumentField="textGrouping" /> */}
				<BarSeries name="Contributions" valueField="count" argumentField="textGrouping" pointComponent={BarWithLabel} />
				<Animation />
			</Chart>
		</div>
	);
}
