import { makeStyles, TextField, Theme, useTheme } from "@material-ui/core";
import React from "react";

const ShortAnswerOverviewDigestStyles = makeStyles((theme: Theme) => ({
	disabledColor: {
		color: theme.palette.secondaryResponse.main,
		fontWeight: 400,
	},
}));

interface Props {
	text: string;
}

export function ShortAnswerOverviewDigest(props: Props) {
	const theme = useTheme();
	const classes = ShortAnswerOverviewDigestStyles();

	return (
		<TextField
			disabled
			style={{
				width: "100%",
				marginTop: 8,
				fontWeight: 400,
				backgroundColor: "#fafafa",
				color: theme.palette.secondaryResponse.main,
			}}
			inputProps={{
				className: classes.disabledColor,
			}}
			variant="outlined"
			multiline
			rowsMax={6}
			value={props.text}
		/>
	);
}
