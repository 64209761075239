import { Grid, makeStyles, Theme, Typography, useTheme, TextField, Card } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";
import { Group } from "../../../entities/Groups/Group";

const useGroupMobileListingStyle = makeStyles((theme: Theme) => ({
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
}));

interface GroupListingTableMobileProps {
	groups: Group[];
    onSelect: (group: Group) => void;
}

export function GroupListingTableMobile(props: GroupListingTableMobileProps){
    const classes = useGroupMobileListingStyle();
    const theme = useTheme();

	const [searchText, startSearchItems] = useState("");
    const [displayedGroups, setDisplayedGroups] = useState(props.groups);

    useEffect(() => {
		let sortedGroups = props.groups;

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			sortedGroups = sortedGroups.filter((listItem) =>
				(listItem.name).toLowerCase().includes(queryText)
			);
		}

		setDisplayedGroups(sortedGroups);
	}, [searchText, props.groups]);

    
    return (
		<Grid container spacing={1}>
            <Grid item xs={12}>
				<Card style={{padding: 12}}>
					<Grid container alignItems="center">
						<Typography variant="h2" gutterBottom>
							{displayedGroups.length} Group{displayedGroups.length !== 1 && "s"}
						</Typography>
						<GridGrow />
					</Grid>
					<Grid item xs={12}>
                        <TextField
                            id="outlined-bare"
                            defaultValue={searchText}
                            margin="normal"
                            variant="outlined"
                            className={classes.searchBar}
                            onChange={(e) => startSearchItems(e.currentTarget.value)}
                            size="small"
                            InputProps={{
                                startAdornment: <Search style={{ marginLeft: -6 }} />,
                            }}
                            style={{ width: "100%", marginTop: 0 }}
                        />
					</Grid>
				</Card>
			</Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {displayedGroups.length === 0 && (
                        <Grid item xs={12}>
                            <Card style={{padding: 12}}>
                                <Typography style={{ fontWeight: 400 }} variant="h4">
                                    {searchText.length !== 0 && `No results found for ${searchText}`}
                                    {searchText.length === 0 && `It looks like you haven't setup any groups yet`}
                                </Typography>
                            </Card>
                        </Grid>
                    )}
                    {displayedGroups.map((group: Group, index: number) => {
                        return (
                            <Grid item xs={12} key={index}						>
                                <Card onClick={() => props.onSelect(group)} style={{padding: 12}}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h4">{group.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                display="inline"
                                                style={{
                                                    color: theme.palette.secondaryResponse.main,
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                                >
                                                Active Members: {group.groupMembers.filter(m => m.groupMembershipStatus !== GroupMembershipStatus.Declined).length}, 
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                display="inline"
                                                style={{
                                                    color: theme.palette.secondaryResponse.main,
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    marginLeft: 7
                                                }}
                                            >
                                                Messages Sent: {group.messagesToGroup.length}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}