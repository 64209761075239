import { Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DateFormat } from "../../../entities/DateFormat";
import { Group } from "../../../entities/Groups/Group";
import { MessageToGroup } from "../../../entities/Groups/MessageToGroup";
import { MessageToGroupStatus } from "../../../entities/Groups/MessageToGroupStatus";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { Enum } from "../../../utillity/Enum";
import { orderBy } from "../../../utillity/orderBy";
import SmsIcon from "@material-ui/icons/Sms";

const useStyles = makeStyles((theme: Theme) => ({
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
}));

interface GroupAnnouncementTableMobileProps {
	selectedGroup: Group;
}

export function GroupAnnouncementTableMobile(props: GroupAnnouncementTableMobileProps) {
	const classes = useStyles();
	const {	selectedGroup: { messagesToGroup, id: groupId }} = props;

	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [selectedMessage, setSelectedMessage] = useState<MessageToGroup | null>(null);
	const [disabled, setDisabled] = useState(false);

	const removeScheduledMessage = async(message:MessageToGroup) => {
		if(message.scheduledSendTime < new Date())
			return setErrorAlert({statusCode: 200, message: "Message cannot be cancelled "})
		setDisabled(true);
		const result = await GroupService.cancelScheduledMessageToGroup({broadcastMessageId: message.broadcastMessageId, groupId})
		setDisabled(false);

		if(ServerResult.isSuccess(result)){
			setSuccessAlert("Message canceled")
			setSelectedMessage(null);
			return;
		}
		
		if(ServerResult.isValidationError(result))
			return setErrorAlert({statusCode: 200, message: "Message cannot be cancelled "})
		setErrorAlert(result); 
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<div style={{ display: "flex" }}>
					<Typography style={{ fontWeight: "bold" }} variant="h3">
						{messagesToGroup.length} Announcement{messagesToGroup.length === 1 ? "" : "s"}
					</Typography>
					<div className={classes.grow} />
				</div>
				<hr className={classes.largeBlueDivider} />
			</Grid>
			<Grid container>
				{messagesToGroup.sort(orderBy.date(m => m.dateCreated, "Descending")).map((message: MessageToGroup, index: number) => {
					return (
						<Grid 
							item
							xs={12}
							style={{ paddingTop: 4, paddingBottom: 4, margin: 4 }}
							key={index} 
						>
							<div
								className={classes.cardGlowEffect}
								style={{
									backgroundColor: "#FFF",
									borderRadius: 8,
									width: "100%",
									height: "fit-content",
									display: "flex",
									padding: 12,
								}}
								onClick={() => message.timeSent === undefined && setSelectedMessage(message)}
							>
								<div>
									<Typography variant="h4">{message.message}</Typography>
									<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15 }}>
										Status:&nbsp;{Enum.print(MessageToGroupStatus, message.status)}
									</Typography>
									{message.timeSent
									?
										<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15 }}>
											Sent On:&nbsp;{message.timeSent.toLocaleString([], DateFormat)}
										</Typography>
									:
										<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15 }}>
											Scheduled For:&nbsp;{message.scheduledSendTime.toLocaleString([], DateFormat)}
										</Typography>
									}
								</div>
							</div>
						</Grid>
					);
				})}
			</Grid>
			<Drawer open={selectedMessage !== null} anchor="bottom" onClose={() => setSelectedMessage(null)} disableBackdropClick={disabled}>
				{ selectedMessage &&
					<List>
						<ListItem onClick={() => removeScheduledMessage(selectedMessage)} disabled={disabled}>
							<ListItemIcon>
								<SmsIcon color="secondary"/>
							</ListItemIcon>
							<ListItemText primary={`Cancel Message`} />
						</ListItem>
					</List>
				}
			</Drawer>
		</Grid>
	);
}
