import {
	AppBar,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	makeStyles,
	Theme,
	Toolbar,
	Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import SyncAlt from "@material-ui/icons/SyncAlt";
import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AssociatedChurchListDialog from "../components/AssociatedChurchList";
import { ResponseMenuBadge } from "../components/ResponseBadge";
import { TextMessagePreviewStatus } from "../entities/Messages/TextMessagePreviewStatus";
import { Role } from "../entities/Role";
import { useAppUser } from "../hooks/useAppUser";
import { useJourneyNotifications } from "../hooks/useJourneyNotifications";
import { usePageTitle } from "../hooks/usePageTitle";
import { useTextMessagePreviews } from "../providers/TextMessagePreviewProvider";
import { routes } from "../routes";
import ArrayHelper from "../utillity/ArrayHelper";
import { CreateNewDialog } from "./CreateNewDialog/CreateNewDialog";
import { useLayoutStyles } from "./Layout";
import { MenuListItem } from "./MenuListItem";

export const useStyles = makeStyles((theme: Theme) => ({
	greenBarHighlight: {
		position: "fixed",
		width: "100%",
		backgroundColor: theme.palette.secondary.main,
		top: 43,
		minHeight: 5,
		boxShadow: "0 8px 6px -6px #C7BAB9",
	},
	appBar: {
		zIndex: 1400,
		backgroundColor: theme.palette.secondaryResponse.main,
		boxShadow: "none",
		height: 48,
	},
	headerBar: {
		position: "fixed",
		zIndex: 2001,
		width: "100%",
		display: "flex",
	},
	mobileDrawerPaper: {
		width: 240,
		backgroundColor: theme.palette.secondaryResponse.main,
		boxShadow: "none",
		top: 0,
	},
	appMenuIcon: {
		color: "#FFF",
	},
	toolbarHeight: {
		height: 48,
		minHeight: 48,
	},
}));

export function RenderMobileNav() {
	const classes = {...useLayoutStyles(), ...useStyles()};
	const [pageTitle] = usePageTitle();
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [user] = useAppUser();
	const { textMessagePreviews } = useTextMessagePreviews();
	const [showChurchList, setShowChurchList] = useState<boolean>(false);
	const [ShowCreateNewDialog, setShowCreateNewDialog] = useState(false);

	const notificationContext = useJourneyNotifications();
	const subscriptions = notificationContext.journeyNotificationSubscriptions;

	const subscriptionsWithUread = subscriptions.filter((s) => s.unreadCount > 0);
	const totalUnread = ArrayHelper.sum(subscriptionsWithUread, (s) => s.unreadCount);
	const unreadMessageCount = textMessagePreviews.filter(p => p.status === TextMessagePreviewStatus.Unread).length;

	return (
		<>
			<AppBar position="fixed" className={classes.appBar}>
				<div className={classes.greenBarHighlight}/>
				<div className={classes.headerBar}>
					<ResponseMenuBadge variant="dot" invisible={totalUnread === 0}>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={() => setMobileNavOpen(!mobileNavOpen)}
							className={classes.appMenuIcon}
						>
							{mobileNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
						</IconButton>
					</ResponseMenuBadge>
					<div style={{ flexGrow: 1, textAlign: "center" }}>
						<Typography style={{ marginTop: 12, color: "#FFF" }} variant="h2">
							{pageTitle}
						</Typography>
					</div>
					<div style={{ width: 48 }}></div>
				</div>
			</AppBar>

			<Drawer
				variant="temporary"
				anchor={"left"}
				BackdropProps={{ invisible: true }}
				open={mobileNavOpen}
				onClose={() => setMobileNavOpen(!mobileNavOpen)}
				classes={{
					paper: classNames(classes.mobileDrawerPaper),
				}}
			>
				<Toolbar className={classes.toolbarHeight} style={{ paddingLeft: 4 }}>
					<IconButton
						color="inherit"
						aria-label="Open drawer"
						onClick={() => setMobileNavOpen(false)}
						className={classes.appMenuIcon}
					>
						{mobileNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
					</IconButton>
				</Toolbar>
				{user.role === Role.UberAdmin && (
					<List style={{ paddingTop: 6, paddingBottom: 4 }}>
						<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
						<ListItem
							style={{ color: "#FFF", paddingTop: 6 }}
							onClick={() => setMobileNavOpen(false)}
							component={Link}
							to={routes.app.impersonateChurch}
						>
							<Typography variant="h3" style={{ color: "#FFF" }}>
								Impersonate Church
							</Typography>
						</ListItem>
					</List>
				)}
				{user.churches.length > 0 && (
					<List style={{ paddingTop: 0 }}>
						<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
						<ListItem
							style={{ color: "#FFF" }}
							onClick={() => (user.churches.length > 1 ? setShowChurchList(true) : undefined)}
						>
							<Typography variant="h3" style={{ color: "#FFF" }}>
								{user.activeChurchName}
							</Typography>
							{user.churches.length > 1 && (
								<IconButton style={{ marginTop: -8, color: "#FFF" }}>
									<SyncAlt />
								</IconButton>
							)}
						</ListItem>
						<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
					</List>
				)}
				{user.role === Role.Collaborator ? (
					<>
						<Link onClick={() => setMobileNavOpen(false)} className={classes.link} to={routes.app.journeyCreatePage}>
							<Button
								classes={{ label: "response-h2" }}
								variant="contained"
								className={classNames(classes.greenbutton, classes.whitenedText, "response-h2")}
							>
								+ Create New
							</Button>
						</Link>
						<List>
							<MenuListItem
								path={routes.app.notifications}
								onClick={() => setMobileNavOpen(false)}
								badge={totalUnread}
							/>
							<MenuListItem path={routes.app.journeyListPage} onClick={() => setMobileNavOpen(false)} />
							<ListItem>
								<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
							</ListItem>
							<MenuListItem path={routes.public.signOutPage} onClick={() => setMobileNavOpen(false)} />
						</List>
					</>
				) : (
					<>
						<Button
							classes={{ label: "response-h2" }}
							variant="contained"
							className={classNames(classes.greenbutton, classes.whitenedText, "response-h2")}
							onClick={() => {
								setMobileNavOpen(false);
								setShowCreateNewDialog(true);
							}}
						>
							+ Create New
						</Button>
						<List>
							<MenuListItem path={routes.app.dashBoardPage} onClick={() => setMobileNavOpen(false)} />
							<MenuListItem
								path={routes.app.notifications}
								onClick={() => setMobileNavOpen(false)}
								badge={totalUnread}
							/>
							<MenuListItem path={routes.app.messagesPage} onClick={() => setMobileNavOpen(false)} badge={unreadMessageCount}/>
							<MenuListItem path={routes.app.groupListPage} onClick={() => setMobileNavOpen(false)} />
							<MenuListItem path={routes.app.journeyListPage} onClick={() => setMobileNavOpen(false)} />
							<MenuListItem path={routes.app.peopleListPage} onClick={() => setMobileNavOpen(false)} />
							<ListItem>
								<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
							</ListItem>
							<MenuListItem path={routes.app.settingsSelectPage} onClick={() => setMobileNavOpen(false)} />
							<ListItem>
								<Divider style={{ backgroundColor: "#FFF", height: 1, width: 200 }} />
							</ListItem>
							<MenuListItem path={routes.public.signOutPage} onClick={() => setMobileNavOpen(false)} />
						</List>
					</>
				)}
			</Drawer>
			{showChurchList && <AssociatedChurchListDialog onClose={() => setShowChurchList(false)} />}
			{ShowCreateNewDialog && (
				<CreateNewDialog isOpen={ShowCreateNewDialog} onClose={() => setShowCreateNewDialog(false)} />
			)}
		</>
	);
}
