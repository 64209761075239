import { FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import Attendee from "../../../entities/Attendee";
import { Answer, MultipleAnswer, RSVPAnswer, ShortAnswer, SignUpListAnswer, SingleAnswer } from "../../../entities/Forms/Answer";
import { Question, QuestionType } from "../../../entities/Forms/Question";
import { MultipleChoiceIndividualResults } from "./QuestionTypeComponents/MultipleChoiceIndividualResults";
import { RSVPIndividualResults } from "./QuestionTypeComponents/RSVPIndividualResults";
import { ShortAnswerOverviewDigest } from "./QuestionTypeComponents/ShortAnswerOverviewDigest";
import { SignUpIndividualResults } from "./QuestionTypeComponents/SignUpIndividualResults";
import { SingleChoiceIndividualResults } from "./QuestionTypeComponents/SingleChoiceIndividualResults";

export const useParticipantsFormDigestStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: 48,
		overflowY: "hidden",
		overflowX: "hidden",
		[theme.breakpoints.down("sm")]: {
			padding: 12,
		},
	},
	pageTitle: {
		color: theme.palette.secondaryResponse.main,
	},
	questionFormSelect: {},
	questionSelect: {
		backgroundColor: "#e2eaf3",
		border: "none",
		minWidth: 400,
		borderRadius: 12,
		paddingTop: 6,
		paddingBottom: 6,

		paddingLeft: 14,
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
		"&:before": {
			display: "none",
		},
		"&:after": {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			minWidth: 200,
		},
	},
	answerBox: {
		border: "1px solid rgba(0,0,0,.2)",
		borderRadius: 12,
		padding: 12,
	},
	answerOwner: {
		borderBottom: "1px solid rgba(0,0,0,.2)",
		marginBottom: 4,
	},
}));

interface ParticipantsFormDigestProps {
	answers: Answer[];
	questions: Question[];
	attendees: Attendee[];
}

export interface ParticipantAnswersData {
	attendeeName: string;
	attendeeId: string;
	attendeePhoneNumber: string;
	answers: QuestionAnswer[];
}

export interface QuestionAnswer {
	questionPrompt: string;
	questionType?: QuestionType;
	questionId?: string;
	answer: Answer;
}

export function ParticipantsFormDigest(props: ParticipantsFormDigestProps) {
	const { questions, answers, attendees } = props;
	const classes = useParticipantsFormDigestStyles();

	const [selectedAttendee, setSelectedAttendee] = useState<ParticipantAnswersData | undefined>(undefined);
	const [data, setData] = useState<ParticipantAnswersData[]>([]);

	useEffect(() => {
		async function buildData() {
			let uniqueAttendees: ParticipantAnswersData[] = [];

			answers.forEach((answer) => {
				let index = uniqueAttendees.findIndex((a) => a.attendeeId === answer.attendeeId);
				let question = questions.find((a) => a.id === answer.questionId);

				if (index < 0) {
					let attendee = attendees.find((a) => a.id === answer.attendeeId);

					uniqueAttendees.push({
						attendeeId: answer.attendeeId,
						attendeeName: attendee ? attendee.name : "Unknown",
						attendeePhoneNumber: attendee ? attendee.phoneNumber : "",
						answers: [
							{
								questionPrompt: question ? question.prompt : "Unknown Question",
								questionType: question?.questionType,
								answer: answer,
							},
						],
					});
				} else {
					uniqueAttendees[index].answers = [
						...uniqueAttendees[index].answers,
						{
							questionPrompt: question ? question.prompt : "Unknown Question",
							questionType: question?.questionType,
							answer: answer,
						},
					];
				}
			});

			setData(uniqueAttendees);

			if (uniqueAttendees.length > 0) {
				setSelectedAttendee(uniqueAttendees[0]);
			}
		}

		buildData();
	}, [attendees, questions, answers]);

	const onQuestionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		let index = data.findIndex((a) => a.attendeeId === (event.target.value as string));

		if (index > -1) {
			setSelectedAttendee(data[index]);
		}
	};

	return (
		<div className={classes.container}>
			<Typography className={classes.pageTitle} variant="h2">
				{data.length} Participant{data.length !== 1 ? "s" : ""}
			</Typography>
			<Grid container style={{ marginTop: 16 }} spacing={4}>
				{!selectedAttendee && (
					<Grid item xs={12}>
						<Typography variant="subtitle1">There are no participants for this form</Typography>
					</Grid>
				)}
				{selectedAttendee && (
					<>
						<Grid item xs={12}>
							<FormControl className={classes.questionFormSelect}>
								<Select
									className={classes.questionSelect}
									value={selectedAttendee.attendeeId}
									onChange={onQuestionTypeChange}
									IconComponent={KeyboardArrowDown}
								>
									{data.map((item: ParticipantAnswersData, index: number) => {
										return (
											<MenuItem key={index} value={item.attendeeId}>
												{item.attendeeName}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						{selectedAttendee.answers.length === 0 && (
							<Grid item xs={12}>
								<Typography variant="subtitle1">This attendee hasn't answered any questions.</Typography>
							</Grid>
						)}
						{selectedAttendee.answers.map((item: QuestionAnswer, index: number) => {
							return (
								<Grid item xs={12} md={3} key={index}>
									<div className={classes.answerBox}>
										<Typography className={classes.answerOwner} variant="subtitle1">
											{item.questionPrompt}
										</Typography>
										{item.questionType && item.questionType === QuestionType.ShortAnswer && (
											<ShortAnswerOverviewDigest text={(item.answer as ShortAnswer).text} />
										)}
										{item.questionType && item.questionType === QuestionType.SingleAnswer && (
											<SingleChoiceIndividualResults answer={item.answer as SingleAnswer} />
										)}
										{item.questionType && item.questionType === QuestionType.MultipleAnswer && (
											<MultipleChoiceIndividualResults answer={item.answer as MultipleAnswer} />
										)}
										{item.questionType && item.questionType === QuestionType.RSVP && (
											<RSVPIndividualResults rsvpCount={(item.answer as RSVPAnswer).attendeeCount} />
										)}
										{item.questionType && item.questionType === QuestionType.SignupList && (
											<SignUpIndividualResults answer={item.answer as SignUpListAnswer} />
										)}
									</div>
								</Grid>
							);
						})}
					</>
				)}
			</Grid>
		</div>
	);
}
