import { Grid, makeStyles,  Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ScrollToIndexWrapper } from "../../../components/ScrollTo";
import { DateFormat } from "../../../entities/DateFormat";
import { MessageToGroup } from "../../../entities/Groups/MessageToGroup";
import { printMessagesGroupedByDate } from "../../../functions/datetime";
import { orderBy } from "../../../utillity/orderBy";
import { GroupAnnouncementMediaBubble } from "../Components/MessageBubble";

interface GroupMessageHistoryProps {
    messageHistory: MessageToGroup[];
}

export function GroupMessageHistory(props:GroupMessageHistoryProps){
    const { messageHistory } = props;
    const classes = useStyles();
    const [orderedMessages, setOrderedMessages] = useState(messageHistory);

    useEffect(() => { setOrderedMessages([...messageHistory].sort(orderBy.date(m => m.dateCreated, "Ascending")))}, [messageHistory]);

    const lastMessageIndex = orderedMessages.length - 1;
    return (
        <Grid container direction="column" wrap="nowrap" className={classes.messageHistoryWrapper}>
            { orderedMessages.map((message, index) => {
                const isLastMessage = index === lastMessageIndex;
                return (
                    <ScrollToIndexWrapper key={index} selectedIndex={isLastMessage}>
                        <Grid item style={{ marginTop: 5, marginBottom: 5 }} >
                            <Typography style={{width: "100%", opacity: .8}} align="center">
                                {message.timeSent 
                                    ? printMessagesGroupedByDate(message.timeSent) 
                                    : `Scheduled for ${message.scheduledSendTime.toLocaleString([], DateFormat)}`}
                            </Typography>
                            <Grid container direction="row-reverse" >
                                <div className={classes.messageFromApp}>
                                    {message.message}
                                </div>
                            </Grid>
                            <Typography align="right" variant="caption" display="block">
                            {message.timeSent && `Sent to ${message.sentToCount} ${message.sentToCount === 1 ? "person" : "people"}`}
                            </Typography>
                        </Grid>
                        {message.media.length !== 0 && 
                            message.media.map((media, i) => 
                            <GroupAnnouncementMediaBubble key={i} scheduledDate={message.timeSent} media={media} />
                        )}
                </ScrollToIndexWrapper>
                );
            })}
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    messageHistoryWrapper: {
        height: "100%",
        overflowY: "auto",
        paddingLeft: 10, 
        paddingRight: 10,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            paddingLeft: 0, 
            paddingRight: 0,
        }
    },
    messageFromApp: {
        display: "inline-block",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: 8,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        whiteSpace: "pre-line",
        overflowWrap: "break-word"
    }
}))
