import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import LoadingSpinner from "../../components/loaderSpinner";
import { PageTitle } from "../../components/PageTitle";
import ResponsePaymentMethod from "../../entities/ResponsePaymentMethod";
import ResponseSubscription from "../../entities/ResponseSubscription";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import ResponseCustomerService from "../../services/ResponseCustomerService";
import ResponseSubscriptionService from "../../services/ResponseSubscriptionService";
import { ServerResult } from "../../services/server/ServerResult";
import ChangePaymentMethod from "./ChangePaymentMethod";
import TabHeaderBar, { SettingsTab } from "./TabHeaderBar";

const PaymentMethod = () => {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [isEnabled, setEnable] = useState(true);
	const setServerErrorAlert = useServerErrorAlert();
	const setServerSuccessAlert = useSuccessAlert();

	const [isLoading, setLoading] = useState(true);

	const [model, setModel] = useState<ResponsePaymentMethod | undefined>();
	const [changePaymentMethod, setChangePaymentMethod] = useState<boolean>();

	const [couponCode, setCouponCode] = useState<string>();
	const [couponCodeError, setCouponCodeError] = useState<string | undefined>();

	React.useEffect(() => {
		const fetch = async () => {
			const result = await ResponseCustomerService.getPayment();
			setLoading(false);

			if (ServerResult.isSuccess<ResponsePaymentMethod>(result)) {
				setModel(result.data);
			} else {
				setServerErrorAlert(result);
			}
		};
		fetch();
	}, [setServerErrorAlert]);

	const onChangePaymentMethod = () => {
		setChangePaymentMethod(true);
		setEnable(false);
	};

	const onSubmitCouponCode = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!model || !couponCode || couponCode.length === 0) return;

		setEnable(false);
		const result = await ResponseSubscriptionService.discountSubscription(couponCode);
		setEnable(true);

		if (ServerResult.isSuccess<ResponseSubscription>(result)) {
			setServerSuccessAlert(`Coupon Code redeemed: ${result.data.discountName} has been applied.`);
		} else if (ServerResult.isValidationError(result)) {
			setCouponCodeError(result.errors[0].errors[0]);
		} else {
			setServerErrorAlert(result);
		}
	};

	if (isLoading) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={isLoading} durationMessage={"Fetching Payment..."} />
			</div>
		);
	}

	return (
		<Grid container spacing={2}>
			{changePaymentMethod && <ChangePaymentMethod />}
			<PageTitle title="Payment" />
			{!mobileFormFactor && (
				<>
					<Grid item xs={12}>
						<Typography variant="h1">Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<TabHeaderBar tab={SettingsTab.Payment_Method} />
					</Grid>
				</>
			)}
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Payment Method" subheader={model ? `${model.brand} ************${model.last4}` : ""} />
					<CardActions>
						<Button
							disabled={!isEnabled}
							variant="contained"
							size="medium"
							color="primary"
							type="submit"
							style={{ marginLeft: 10 }}
							onClick={onChangePaymentMethod}
						>
							Change Payment Method
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Redeem Coupon"  style={{ paddingBottom: 0 }} />
					<form onSubmit={onSubmitCouponCode}>
						<CardContent>
							<TextField
								variant="outlined"
								name="couponCode"
								label="Coupon Code"
								margin="normal"
								onChange={(e) => setCouponCode(e.target.value)}
								error={couponCodeError != null}
								helperText={couponCodeError}
								fullWidth
								required
								type="text"
							/>
						</CardContent>

						<CardActions>
							<Button
								disabled={!isEnabled}
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ marginLeft: 10 }}
							>
								Redeem
							</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
};

export default PaymentMethod;
