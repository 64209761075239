import { useEffect, useMemo, useState } from "react";
import useScript from "./useScript";

type ReturnType = [google.maps.places.AutocompletePrediction[], (query: string) => void, google.maps.Geocoder | undefined];

export default function useGoogleMaps(): ReturnType {
	const status = useScript(
		"https://maps.googleapis.com/maps/api/js?key=AIzaSyAfINFh4YSArpYpQOCw7VXu7woUhjfUJUU&libraries=places"
	);

	const [autoCompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | undefined>();
	const [placesService, setPlacesService] = useState<google.maps.Geocoder | undefined>();
	const [places, setPlaces] = useState<google.maps.places.AutocompletePrediction[]>([]);
	const [query, setQuery] = useState("");

	useEffect(() => {
		if (status === "ready") {
			setAutocompleteService(new google.maps.places.AutocompleteService());
			setPlacesService(new google.maps.Geocoder());
		}
	}, [status]);

	const fetchPredictions = useMemo(
		() =>
			function getPlacePredictions(
				request: { input: string },
				callback: (results: google.maps.places.AutocompletePrediction[]) => void
			) {
				if (autoCompleteService)
					autoCompleteService.getPlacePredictions(
						{ ...request, componentRestrictions: { country: "us" }, types: ["address"] },
						callback
					);
			},
		[autoCompleteService]
	);

	const processPredictions = (results: google.maps.places.AutocompletePrediction[]) => {
		const onlyAddresses = results?.filter((r) => !r.types.some((t) => t === "route")) ?? [];
		setPlaces(onlyAddresses);
	};

	useEffect(() => {
		const placeQuery = query.trim();
		if (placeQuery !== "") {
			fetchPredictions({ input: placeQuery }, processPredictions);
		}
	}, [query, fetchPredictions]);

	return [places, setQuery, placesService];
}
