import ResponseSubscription, { SubscriptionTerm } from "../../entities/ResponseSubscription";
import { GTag } from "./GoogleAnalytics";

export type EventType =
	| "sign_up"
	| "create_password"
	| "create_church_phone"
	| "add_to_cart"
	| "begin_checkout"
	| "add_payment_info"
	| "purchase";

export class EventTracking {
	static SignUp = {
		signup() {
			GTag.event("sign_up", { method: "InApp" });
		},
		createPassword() {
			GTag.event("create_password", { method: "InApp" });
		},
		createChurchPhone() {
			GTag.event("create_church_phone", { method: "InApp" });
		},
		selectedPlan(planId: string, planName: string, price: number) {
			GTag.event("add_to_cart", {
				currency: "USD",
				value: price,
				items: [
					{
						item_id: planId,
						item_name: planName,
					},
				],
			});
		},
		beginCheckout(price: number, planId: string, planName: string, coupon?: string) {
			GTag.event("begin_checkout", {
				currency: "USD",
				items: [
					{
						item_id: planId,
						item_name: planName,
						coupon: coupon,
						price: price,
					},
				],
				value: price,
			});
		},
		purchase(subscription: ResponseSubscription) {
			GTag.event("purchase", {
				currency: "USD",
				value: subscription.price,
				tax: 0,
				affiliation: "Response",
				transaction_id: new Date().getTime().toString(),
				coupon: subscription.couponCode,
				items: [
					{
						item_name: "Response Subscription",
						item_id: subscription.currentPriceId,
						price: subscription.price.toString(),
						item_category: `${subscription.term === SubscriptionTerm.Annual ? "Yearly" : "Monthly"} ${subscription.name}`,
						quantity: "1",
					},
				],
			});
		},
	};
}
