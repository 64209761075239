import React, { useState } from "react";
import { Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Attendee from "../../../entities/Attendee";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { Group } from "../../../entities/Groups/Group";
import { ButtonLink } from "../ButtonLink";

interface SelectExistingPeopleForGroupProps {
	disabled: boolean;
	group: Group;
	returnSelectedAttendees: (value: Attendee[]) => void;
	onNoMoreAttendees: () => void;
}

export function SelectExistingPeopleForGroup(props: SelectExistingPeopleForGroupProps) {
	const { group } = props;
	const classes = useStyles();
	const attendeeContext = useAttendees();
	const [attendeeOptions] = useState<Attendee[]>(
		attendeeContext.attendees.filter((a) => !group.groupMembers.some((m) => m.attendeeId === a.id))
	);
	const [selectedAttendees, setSelectedAttendees] = useState<Attendee[]>([]);

	const onSelect = (values: Attendee[]) => {
		setSelectedAttendees(values);
		props.returnSelectedAttendees(values);
	};

	const filter = createFilterOptions<Attendee>({
		matchFrom: "any",
		stringify: (option: Attendee) => `${option.name}${option.phoneNumber}`,
	});

	if (attendeeOptions.length === 0) {
		return (
			<div style={{ height: 139, margin: 50, width: "100%" }}>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Looks like everyone in the church is added to this group.
				</Typography>
				<Typography align="center" variant="body2" style={{ fontSize: 18 }}>
					Would you like to <ButtonLink onClick={props.onNoMoreAttendees}>invite someone new</ButtonLink>?
				</Typography>
			</div>
		);
	}

	return (
		<Grid container justify="center">
			<Grid item md={6} sm={6} xs={12}>
				<Autocomplete
					multiple
					disabled={props.disabled}
					value={selectedAttendees}
					options={attendeeOptions}
					getOptionSelected={(option, value) => option.name === value.name || option.phoneNumber === value.phoneNumber}
					onChange={(_, value) => onSelect(value)}
					autoHighlight
					clearOnBlur
					clearOnEscape
					handleHomeEndKeys
					fullWidth
					filterSelectedOptions
					style={{height: 100}}
					classes={{tag: classes.chipStyle }}
					filterOptions={(options, params) => {
						return filter(options, params);
					}}
					getOptionLabel={(option: Attendee) => {
						return `${option.name} - ${formatPhoneNumber(option.phoneNumber)}`;
					}}
					renderOption={(option: Attendee) => {
						return `${option.name} - ${formatPhoneNumber(option.phoneNumber)}`;
					}}
					renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add Member" />}
				/>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
    chipStyle: {
        width: 150
    }
}));