import { Modify } from "../../utillity/modifyType";

interface QuestionChoiceBase {}

export enum QuestionChoiceType {
	Unknown = 0,
	ShortAnswer = 1,
	SingleChoice = 2,
	MultipleChoice = 3,
	SignupList = 4,
	RSVP = 5,
}

export type QuestionMultipleChoice = Modify<
	QuestionChoiceBase,
	{
		choiceType: QuestionChoiceType.MultipleChoice;
		text: string;
	}
>;

export type QuestionSignupListChoice = Modify<
	QuestionChoiceBase,
	{
		choiceType: QuestionChoiceType.SignupList;
		text: string;
		quantity: number;
	}
>;

export type QuestionChoice = QuestionMultipleChoice | QuestionSignupListChoice;
