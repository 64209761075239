export function downloadCsv(filename: string, rows: string[][]) {
    var processRow = function (row: string[]) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    const navigatorAny = navigator as any;
    if (navigatorAny.msSaveBlob) { // IE 10+
        navigatorAny.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function readCsvString(strData: string): string[][] {
    const strDelimiter = ",";
    let objPattern = new RegExp(
      (
        "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
      "gi"
      );
    let arrData: string[][] = [[]];
    let arrMatches = null;

    // eslint-disable-next-line no-cond-assign
    while (arrMatches = objPattern.exec(strData)) {
      let strMatchedDelimiter = arrMatches[1];
      let strMatchedValue: string = "";
      if (strMatchedDelimiter.length && (strMatchedDelimiter !== strDelimiter)) {
        arrData.push([]);
      }
      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"),"\"");
      } else {
        strMatchedValue = arrMatches[3];
      }

      // Whitespace sucks
      arrData[arrData.length - 1].push(strMatchedValue?.trim() ?? "");
    }

    if(arrData.length === 0)
        return arrData;

    const headerColumnCount = arrData[0].filter(cell => cell !== "").length;
    // for simplicity sake, the all child array items should be as big as the first row since we're assuming it's the header row
    const uniformArray: string[][] = [];
    arrData.forEach(row => {
        // Blank rows suck
        if(row.every(cell => cell === ""))
            return;

        const difference = headerColumnCount - row.length;
        if(difference === 0)
            uniformArray.push(row);
        else if(difference > 0)
            uniformArray.push([...row, ...Array.from(new Array(difference), () => "")]);
        else
            uniformArray.push(row.slice(0, headerColumnCount))
    });

    return uniformArray;
  }