import {
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import { Journey } from "../../../entities/Journey";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout, useJourneyStepStyles } from "../Steps/JourneyStepLayout";
import { AddMessageExtraLayout } from "./AddMessageExtraLayout";

const useStyles = makeStyles((theme: Theme) => ({
	responseNumberPaper: {
		padding: "0px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			responseNumberPaper: {
				margin: "0px 20px",
				padding: "0px 30px",
			},
		},
	},
	areaCodeBox: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		"& > fieldset": {
			borderRight: "none",
		},
	},
	remainingNumberBox: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	selectInput: {
		minWidth: 110,
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
	},
}));

type StepProps = {
	mode: "step";
	journey: Journey;
}

type AddProps = {
	mode: "add";
	journey: Journey;
	title: string;
	onCancel: () => void;
	onUpdate: (updatedJourney: Journey) => void;
}

type Props = StepProps | AddProps;

enum LinkType {
	Image = 0,
	Youtube = 1,
	Vimeo = 2,
}

const imageRegExpression = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/gi;
const vimeoRegExpression = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;
const youTubeRegExpression = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;

export function MessageMediaView(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useStyles() };
	const progress = useJourneySetupProgress();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { journey } = props;

	const [typeToAdd, setTypeToAdd] = useState(LinkType.Image);
	const [unvalidatedLink, setUnvalidatedLink] = useState<string | undefined>(undefined);
	const [validatedLink, setValidatedLink] = useState<string | undefined>(journey.extras.mediaLink);
	const [linkError, setLinkUrlError] = useState<string | undefined>(undefined);
	const [disable, setDisable] = useState(false);
	const [successValidationMessage, setSuccessValidationMessage] = useState<string | undefined>(undefined);

	const completeStep = () => {
		if (unvalidatedLink === undefined || unvalidatedLink.length === 0) {
			const updatedJourney:Journey = {
				...journey,
				extras: {
					...journey.extras,
					mediaLink: validatedLink,
				},
			};

			if(props.mode === "add"){
				props.onUpdate(updatedJourney);
			} else {
				progress.completeStep(updatedJourney);
			}

			return;
		}

		if (disable) {
			return;
		}

		setLinkUrlError(undefined);
		setDisable(true);

		let regex = new RegExp(
			typeToAdd === LinkType.Image
				? imageRegExpression
				: typeToAdd === LinkType.Youtube
				? youTubeRegExpression
				: vimeoRegExpression
		);
		let errorMessage: string | undefined = undefined;

		if (!unvalidatedLink.match(regex)) {
			switch (typeToAdd) {
				case LinkType.Image:
					errorMessage = "Sorry, this Image url is invalid. (Example: https://i.cdn-sc.com/users/default-avatar.jpg)";
					break;
				case LinkType.Youtube:
					errorMessage = "Sorry, this YouTube url is invalid. (Example: https://youtu.be/ELIqj32_IJs)";
					break;
				default:
					errorMessage = "Sorry, this Vimeo url is invalid. (Example: https://vimeo.com/265636445)";
			}
		}

		if (errorMessage !== undefined) {
			setLinkUrlError(errorMessage);
			setDisable(false);
			return;
		}

		setValidatedLink(unvalidatedLink);
		// setUnvalidatedLink(undefined); // Jacob added this but things be breaking
		setSuccessValidationMessage("Your link is valid, moving to the next step...");

		const updatedJourney:Journey = {
			...journey,
			extras: {
				...journey.extras,
				mediaLink: unvalidatedLink,
			},
		};
		
		setTimeout(() => {
			if(props.mode === "add"){
				props.onUpdate(updatedJourney);
			} else {
				progress.completeStep(updatedJourney);
			}
		}, 3000);
	};

	const skipStep = () => {
		progress.completeStep({
			...journey,
			extras: {
				...journey.extras,
				mediaLink: undefined,
			},
		});
	}

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setTypeToAdd(parseInt(event.target.value as string));
	};

	const determineRenderBox = (link: string) => {
		let content: any | undefined = undefined;

		let imgRegex = new RegExp(imageRegExpression);
		let youTubeRegex = new RegExp(youTubeRegExpression);
		let vimeoRegex = new RegExp(vimeoRegExpression);

		if (link.match(imgRegex)) {
			content = <img style={{ maxHeight: 250, maxWidth: "100%" }} alt={"link"} title="Image" src={link} />;
		} else if (link.match(youTubeRegex)) {
			const parsedLink = link.match(youTubeRegex)!;
			content = (
				<iframe
					title="YouTube"
					width={mobileFormFactor ? "100%" : 435}
					height={250}
					frameBorder={0}
					allowFullScreen
					src={`https://www.youtube-nocookie.com/embed/${parsedLink[1]}`}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				/>
			);
		} else if (link.match(vimeoRegex)) {
			const parsedLink = link.match(vimeoRegex)!;
			content = (
				<iframe
					title="Vimeo"
					width={mobileFormFactor ? "100%" : 435}
					height={250}
					frameBorder={0}
					allow="autoplay"
					allowFullScreen
					src={`https://player.vimeo.com/video/${parsedLink[1]}?color=231967d&title=0&byline=0`}
				/>
			);
		}

		if (content === undefined) {
			return undefined;
		}

		return (
			<>
				<Typography variant="subtitle1" style={{ fontSize: 14, opacity: 0.8, marginTop: 12 }}>
					Current attached media
				</Typography>
				{content}
			</>
		);
	};

	const view = () => {
		return (
			<Grid item xs={12}>
				<div style={{ width: mobileFormFactor ? "100%" : 400, margin: "0 auto" }}>
					<Typography className={classes.progressLabelText} variant="subtitle2">
						What media link do you want to send people when they text "{props.journey.keywordTrigger}"?
					</Typography>

					{successValidationMessage !== undefined && (
						<Typography
							variant="subtitle1"
							style={{ fontSize: 18, opacity: 0.8, marginTop: 12, color: theme.palette.success.main }}
						>
							{successValidationMessage}
						</Typography>
					)}
					<FormControl style={{ marginTop: 24, display: "flex", flexDirection: "row" }}>
						<FormControl variant="outlined" style={{ minWidth: 110 }}>
							<InputLabel id="media-type-label">Type</InputLabel>
							<Select
								labelId="media-type-label"
								id="media-type"
								className={classes.selectInput}
								value={typeToAdd}
								onChange={handleChange}
								label="Type"
								disabled={disable}
							>
								<MenuItem value={LinkType.Image}>Image</MenuItem>
								<MenuItem value={LinkType.Youtube}>YouTube</MenuItem>
								<MenuItem value={LinkType.Vimeo}>Vimeo</MenuItem>
							</Select>
						</FormControl>
						<TextField
							label="Enter the url for your link"
							variant="outlined"
							value={unvalidatedLink}
							style={{ width: mobileFormFactor ? "100%" : 295 }}
							InputProps={{
								classes: {
									root: classes.remainingNumberBox,
								},
								endAdornment:
									unvalidatedLink && unvalidatedLink.length > 0 ? (
										<InputAdornment position="end">
											<Cancel
												style={{ opacity: 0.5, cursor: "pointer" }}
												onClick={() => {
													setLinkUrlError(undefined);
													setUnvalidatedLink("");
												}}
											/>
										</InputAdornment>
									) : undefined,
							}}
							onChange={(e) => setUnvalidatedLink(e.currentTarget.value)}
							helperText={linkError}
							error={linkError !== undefined}
						/>
					</FormControl>
					{validatedLink && determineRenderBox(validatedLink)}
					<Typography variant="subtitle1" style={{ fontSize: 14, opacity: 0.8, marginTop: 12 }}>
						To keep the message short and sweet, we'll shorten the link (ex: https://rspns.to/4Gh1c)
					</Typography>
				</div>
			</Grid>
		);
	}
	
	if(props.mode === "add"){
		return(
			<AddMessageExtraLayout title={props.title} onCancel={props.onCancel} onSave={completeStep}>
				{view()}
			</AddMessageExtraLayout>
		);
	}

	return (
		<JourneyStepLayout required={false} onConfirmStep={completeStep} skip={!validatedLink && (unvalidatedLink == null || unvalidatedLink.trim() === "")} onSkip={skipStep}>
			{view()}
		</JourneyStepLayout>
	);
}
