import { Coupon } from "../entities/Coupon";
import ResponseSubscription from "../entities/ResponseSubscription";
import ResponseUpcomingInvoice from "../entities/ResponseUpcomingInvoice";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

type ServerResponseSubscription = Modify<
	ResponseSubscription,
	{
		startDate: string;
		endDate?: string;
		trialStart?: string;
		trialEnd?: string;
		cancelOn?: string;
		currentPeriodStart: string;
		currentPeriodEnd: string;
		nextInvoiceDate?: string;
	}
>;

const convert = (model: ServerResponseSubscription): ResponseSubscription => {
	return {
		...model,
		startDate: new Date(model.startDate),
		endDate: model.endDate ? new Date(model.endDate) : undefined,
		trialStart: model.trialStart ? new Date(model.trialStart) : undefined,
		trialEnd: model.trialEnd ? new Date(model.trialEnd) : undefined,
		cancelOn: model.cancelOn ? new Date(model.cancelOn) : undefined,
		currentPeriodStart: new Date(model.currentPeriodStart),
		currentPeriodEnd: new Date(model.currentPeriodEnd),
		nextInvoiceDate: model.nextInvoiceDate ? new Date(model.nextInvoiceDate) : undefined,
	};
};

export const ResponseSubscriptionService = {
	get() {
		return WebClient.Convert.Get(`${apiRoot}/subscription`, convert);
	},
	cancelSubscription(reason: string) {
		return WebClient.Convert.Put.Unvalidated(`${apiRoot}/subscription/cancel`, {cancelReason :reason}, convert);
	},
	upgradeSubscription(newPlanId: string) {
		return WebClient.Convert.Put.Unvalidated(`${apiRoot}/subscription/update`, { id: newPlanId }, convert);
	},
	checkUpgradeSubscription(newPlanId: string) {
		return WebClient.Put.Unvalidated<number>(`${apiRoot}/subscription/estimate-update`, { id: newPlanId });
	},
	discountSubscription(code: string) {
		return WebClient.Convert.Put.Validated(`${apiRoot}/subscription/coupon`, { code }, convert);
	},
	getUpcomingInvoice() {
		return WebClient.Get<ResponseUpcomingInvoice | null>(`${apiRoot}/subscription/get-next-invoice`);
	},
	getUpdatePaymentSession() {
		return WebClient.Get<string>(`${apiRoot}/subscription/update-payment-session`);
	},
	getCoupon(code: string) {
		return WebClient.Get<Coupon>(`${apiRoot}/subscription/get-coupon/${code}`);
	},
	changeSubscription(newSubscriptionId: string, churchId: string){
		return WebClient.Put.Unvalidated(`${apiRoot}/subscription/change-subscription`, { newSubscriptionId, churchId })
	}
};

export default ResponseSubscriptionService;
