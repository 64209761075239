import { Grid, makeStyles,  Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAttendeeTextMessageHistory } from "../../../hooks/useAttendeeTextMessageHistory";
import { useMessageRouter } from "../MessagePage";
import { ScrollTo } from "../../../components/ScrollTo";
import TextMessageHistoryItem from "../../../entities/Messages/TextMessageHistoryItem";
import { printMessagesGroupedByDate } from "../../../functions/datetime";
import { MessageBubble } from "../Components/MessageBubble";

interface MessagesGroupedBy {
    [key: string]: TextMessageHistoryItem[]
}

interface InboxMessageHistoryProps {
}

function groupByDate(dic:MessagesGroupedBy, message:TextMessageHistoryItem){
    const messageDateString = message.dateCreated.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
    if(messageDateString in dic){
        dic[messageDateString] = [...dic[messageDateString], message]
    } else {
        dic[messageDateString] = [message]
    }
    return dic
}

export function InboxMessageHistory(props:InboxMessageHistoryProps){
    const classes = useStyles();
    const messageRouterContext = useMessageRouter();
    const { selectedAttendee } = messageRouterContext;
    const selectedAttendeeMessageHistory = useAttendeeTextMessageHistory(selectedAttendee);
    const [groupedMessages, setGroupedMessages] = useState<MessagesGroupedBy>({})

    useEffect(() => {
        const groupedMessageDictonary = [...selectedAttendeeMessageHistory].reduce<MessagesGroupedBy>(groupByDate, {})
        setGroupedMessages(groupedMessageDictonary)
    }, [selectedAttendeeMessageHistory])

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.messageHistoryWrapper}>
            {Object.values(groupedMessages).map((messages, i) => {
                return <Grid key={i} item container direction="column" wrap="nowrap">
                    <Typography style={{color: "#666", fontSize: 12}} align="center">{printMessagesGroupedByDate(messages[0].dateCreated)}</Typography>
                        { messages.map((message, i) => <MessageBubble message={message} key={i}/>)}
                        <div ref={r => r?.scrollIntoView()}/>
                        <ScrollTo behavior="smooth" key={`ScrollTo-${messages.length}`}/>
                </Grid>
            })}
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    messageHistoryWrapper: {
        height: "100%",
        width: "100%",
        overflowY: "auto",
        paddingLeft: 10, 
        paddingRight: 10,
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0, 
            paddingRight: 0,
        }
    },
    messageFromApp: {
        display: "inline-block",
        maxWidth: "50%",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: 8,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        whiteSpace: "pre-line",
        overflowWrap: "break-word"
    },
    messageFromAttendee: {
        display: "inline-block",
        maxWidth: "50%",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.lightGrayBlue.main,
        padding: 8,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        whiteSpace: "pre-line",
        overflowWrap: "break-word"
    }

}))
