import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ResponseDialog } from '../../components/ResponseDialog/ResponseDialog';
import AddPeopleView from './AddPeopleView';
import CreateGroupView from './CreateGroupView';
import { SelectCreateAction } from './SelectCreateAction';
import SelectGroupToMessage from './SelectGroupToMessage';

interface CreateNewDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

const getDialogTitle = (value:("CreateGroup"| "SendMessage" | "AddPeople" | null)) => {
	switch (value) {
		case "CreateGroup":
			return "Create Group"
		case "AddPeople":
			return "Add Person"
		case "SendMessage":
			return "Send Group Announcement"
		default:
			return "Create New"
	}
}

export function CreateNewDialog(props: CreateNewDialogProps){
	const history = useHistory();
	const [selectedAction, setSelectedAction] = useState<"CreateGroup"| "SendMessage" | "AddPeople" | null>(null)

	useEffect(() => history.listen(() => props.onClose()), [history, props])

    return (
        <ResponseDialog 
            onClose={props.onClose} 
            open={props.isOpen} 
            title={getDialogTitle(selectedAction)}
        >
			{selectedAction === "CreateGroup" && <CreateGroupView onClose={props.onClose} onBack={() => setSelectedAction(null)}/>}
			{selectedAction === "SendMessage" && <SelectGroupToMessage onClose={props.onClose} onBack={() => setSelectedAction(null)}/>}
			{selectedAction === "AddPeople" && <AddPeopleView onClose={props.onClose} onBack={() => setSelectedAction(null)} />}
			{selectedAction === null && <SelectCreateAction onClose={props.onClose} onActionSelect={(action) => setSelectedAction(action)}  />}
        </ResponseDialog>
    )
}
