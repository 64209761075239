import { Grid, IconButton, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { PropsWithChildren } from "react";
import { ResponseDialogLayoutProps, useCommonLayoutStyle } from "./ResponseDialogLayout";


export function ResponseDialogDesktopLayout(props: PropsWithChildren<ResponseDialogLayoutProps>) {
    const { title } = props;
	const classes = { ...useCommonLayoutStyle(), ...useStyles() };

	return (
		<Grid container style={{ overflowY: "auto" }}>
			<Grid className={classes.titlebar} item xs={12}>
				<Typography className={classes.titlebartitle} variant={"h4"} style={{ paddingLeft: 50 }}>
					{title}
				</Typography>
				<IconButton className={classes.titleCloseIcon} aria-label="close modeal" component="span" onClick={props.onClose}>
					<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
				</IconButton>
			</Grid>
			{props.children}
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	titlebar: {
		width: "100%",
		height: 54,
		backgroundColor: theme.palette.secondaryResponse.main,
		display: "flex",
		alignItems: "center",
	},
}));