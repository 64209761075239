import Reminder, { getDurationNodaTime } from "../entities/Reminder";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

type ServerReminder = Modify<
	Reminder,
	{
		timeBeforeEvent: string;
		timeSent?: string;
		scheduledTime: string;
	}
>;

const convert = (serverModel: ServerReminder): Reminder => {
	return {
		...serverModel,
		timeBeforeEvent: getDurationNodaTime(serverModel.timeBeforeEvent),
		timeSent: serverModel.timeSent ? new Date(serverModel.timeSent) : undefined,
		scheduledTime: new Date(serverModel.scheduledTime),
	};
};

export default class ReminderService {
	static async GetReminders(journeyId: string) {
		return WebClient.Convert.Get<ServerReminder[], Reminder[]>(
			`${apiRoot}/journeys/informational/reminders/${journeyId}`,
			(serverReminder) => serverReminder.map(convert)
		);
	}

	static async CreateReminder(journeyId: string, message: string, reminderOffSet: string) {
		return WebClient.Convert.Post.Validated(
			`${apiRoot}/journeys/informational/reminders`,
			{ journeyId, message, reminderOffSet },
			convert
		);
	}

	static async UpdateReminder(journeyId: string, message: string, reminderOffSet: string, broadcastMessageId: string) {
		return WebClient.Convert.Put.Validated(
			`${apiRoot}/journeys/informational/reminders`,
			{ journeyId, message, reminderOffSet, broadcastMessageId },
			convert
		);
	}

	static async CancelReminder(broadcastMessageId: string) {
		return WebClient.Delete(`${apiRoot}/journeys/informational/reminders/${broadcastMessageId}`);
	}
}
