import { Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ResponseDialogActions } from "../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { VerticalCenter } from "../../components/VerticalCenter";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { useGroups } from "../../providers/GroupProvider";
import { routes } from "../../routes";
import { CreateGroupRequest, GroupService } from "../../services/GroupService";
import { ServerResult } from "../../services/server/ServerResult";

interface CreateGroupViewProps {
	onClose: () => void;
	onBack: () => void;
}

export default function CreateGroupView(props: CreateGroupViewProps) {
	const groupsContext = useGroups();
	const history = useHistory();
	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [groupName, setGroupName] = useState<string | null>();
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		setError(undefined);

		if (groupsContext.groups) {
			const existingGroup = groupsContext.groups.find((g) => g.name === groupName);
			if (existingGroup) {
				setError(`${existingGroup.name} already exists`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupName]);

	const onSubmit = async () => {
		if (!groupName || error) {
			if (!error) {
				setError("Group name cannot be blank");
			}
			return;
		}

		setError(undefined);
		const request: CreateGroupRequest = { name: groupName };

		setIsDisabled(true);
		var result = await GroupService.create(request);
		setIsDisabled(false);

		if (ServerResult.isSuccess(result)) {
			setSuccessAlert(`${groupName} created`);
			groupsContext.create(result.data);
			history.push(routes.app.resolve.groupDetailPage(result.data.id));
			props.onClose();
		} else if (ServerResult.isValidationError(result)) {
			setError(result.errors[0].errors[0]);
		} else {
			setErrorAlert(result);
		}
	};

	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Grid container spacing={2} justify="center">
						<Grid item xs={12}>
							<Typography align="center" variant="body2" style={{ fontSize: 22 }}>
								Name your new group
							</Typography>
						</Grid>
						<Grid item md="auto" xs={12}>
							<TextField
								variant="outlined"
								disabled={isDisabled}
								label="Group Name"
								style={{ width: mobileFormFactor ? "100%" : 350  }}
								required
								type="text"
								value={groupName}
								onChange={(v) => setGroupName(v.currentTarget.value)}
								placeholder="Group Name"
								error={error !== undefined}
								helperText={error}
							/>
						</Grid>
					</Grid>
				</VerticalCenter>
			</ResponseDialogContent>
			<ResponseDialogActions
				onConfirm={onSubmit}
				onBack={props.onBack}
				nextButtonText={"Create"}
				backButtonText={"Back"}
				disabled={isDisabled}
				hideSecondaryButton={true}
			/>
		</>
	);
}
