import { useEffect } from "react";
import { useHub } from "./useHub";

export function useHubEvent(name: string, functionToFire: (...args: any[]) => void, dependencies?: unknown[]) {
    const hub = useHub();
	const { hubReady } = hub;
    useEffect(() => {
		if(hubReady){
			return hub.onEvent(name, functionToFire);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubReady, ...(dependencies ?? [])])
}