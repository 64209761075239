import { RegisterChurchRequest } from "../pages/SignUp/ChurchSetupDialog/Components/ChurchRegistrationForm";
import { FieldValidationError } from "../services/server/ServerValidationError";

export function ChurchRegistrationFormValidator(request:RegisterChurchRequest){
    const errors:FieldValidationError[] = []
    validateZipCode(request.zipCode, errors);
    validateState(request.state, errors);
    validateAddress(request.churchAddress, errors);
    validateCity(request.city, errors);
    return errors;
}

function validateZipCode(zipCode:string, fieldErrors:FieldValidationError[]){
    let errors = []
    if(/\D/gi.test(zipCode))
        errors.push("Zip code can only contain numaric values.")
    if(zipCode.length !== 5)
        errors.push("Invalid zip(please use 5 digit US zipcode.")
    if(errors.length !== 0)
        fieldErrors.push({field: "ZipCode", errors});
}

function validateState(state:string, fieldErrors:FieldValidationError[]){
    let errors = []
    if(/\d/gi.test(state) || state.length !== 2)
        errors.push("Invalid State(please use 2 digit US State format.")
    if(errors.length !== 0)
        fieldErrors.push({field: "State", errors})
}

function validateAddress(address:string, fieldErrors:FieldValidationError[]){
    let errors = []
    if(!/\d/gi.test(address))
        errors.push("Please include street number with address.")
    if(address.length > 100)
        errors.push("Address is too long.")
    if(address.length === 0)
        errors.push("Address cannot be blank.")
    if(errors.length !== 0)
        fieldErrors.push({field: "ChurchAddress", errors});
}

function validateCity(city:string, fieldErrors:FieldValidationError[]){
    let errors = []
    if(city.length > 100)
        errors.push("City Name is too long.")
    if(city.length === 0)
        errors.push("Invalid City name.")
    if(errors.length !== 0)
        fieldErrors.push({field: "City", errors});
}