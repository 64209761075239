import { Grid, Typography, Popover, useTheme, TextField, Button } from "@material-ui/core";
import React, { MutableRefObject, useRef, useState } from "react";
import Picker, { IEmojiData } from "emoji-picker-react";
import { EmojiEmotionsOutlined, OndemandVideoOutlined } from "@material-ui/icons";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import LinkIcon from "@material-ui/icons/Link";
import { useServerErrorAlert } from "../hooks/useServerErrorAlert";
import { isMobile } from "../hooks/isMobile";

interface AddMessageExtrasButtonsProps {
    componentRef: MutableRefObject<HTMLInputElement | null>;
    disabled: boolean;
    uploadedImageCount: number;
    uploadFileKey: number
    toggleDisabled: () => void;
    onAddMedia: (file:File) => void;
    onAddToMessage: (token:string) => void;
}

export function AddMessageExtrasButtons(props:AddMessageExtrasButtonsProps){
    const {disabled, uploadedImageCount, uploadFileKey, componentRef} = props;
    const mobile = isMobile();
    const errorAlert = useServerErrorAlert();
    const theme = useTheme();

    const [showEmojiMenu, setShowEmojiMenu] = useState(false);
	const [showLinkMenu, setShowLinkMenu] = useState(false);
	const [linkType, setLinkType] = useState<"video" | "url">("url")
    const [inputUrl, setInputUrl] = useState("");

	const fileUploadInput = useRef<HTMLInputElement | null>(null);

	const onLinkKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
		console.log(e)
		if (!mobile && (e.key === "Enter" || e.key === "Tab")) {
			e.preventDefault();
			e.stopPropagation();
			props.onAddToMessage(inputUrl);
			setInputUrl("");
		}
	};

	const handleLinkSave = () => {
		props.onAddToMessage(inputUrl);
		setInputUrl("");
	}

	const handleEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
		props.onAddToMessage(data.emoji);
		setShowEmojiMenu(false);
	};

	const onFileUploadClick = () => {
		if (fileUploadInput.current) fileUploadInput.current.click();
	};

	const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files == null || files.length === 0) return;

		const file = files[0];
		if (file.size > 3000000) {
			errorAlert({ message: "Images must be less than 3 MB", statusCode: 400 });
			return;
		}
        props.onAddMedia(file);
	};

    return (
        <>
            {showEmojiMenu && (
                <Popover
                    open={showEmojiMenu}
                    onBackdropClick={() => setShowEmojiMenu(false)}
                    anchorEl={componentRef.current}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Picker disableAutoFocus={true} groupNames={{ smileys_people: "PEOPLE" }} onEmojiClick={handleEmojiClick} />
                </Popover>
            )}
            {showLinkMenu && (
                <Popover
                    open={showLinkMenu}
                    onBackdropClick={() => { setShowLinkMenu(false); setInputUrl("")}}
                    anchorEl={componentRef.current}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Grid item xs={12} style={{padding: 10, minWidth: 300}}>
                        <Typography  variant="subtitle2">
                            { linkType === "url"
                                ? "Type or paste your link below..."
                                : "Add a Youtube or Vimeo link to share"
                            }
                        </Typography>
                        <div>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type={"url"}
                                disabled={disabled}
                                placeholder={ linkType === "url"
                                    ? "https://example.com"
                                    : "https://youtube.com/watch?v="
                                }
                                value={inputUrl}
                                onKeyPress={onLinkKeyPress}
                                onChange={((e) => setInputUrl(e.target.value))}
                                margin="normal"
                                style={{ marginTop: 10, marginBottom: 10, backgroundColor: "#FFF" }}
                            />
                        </div>
                        { linkType === "url" &&
                            <Typography variant="subtitle1" style={{ fontSize: 14, opacity: 0.8, marginBottom: 5 }}>
                                To keep the message short and sweet, we'll shorten the link
                            </Typography>
                        }
                        <Grid container justify="flex-end">
                            <Button color="primary" variant="contained" onClick={handleLinkSave}>
                            { linkType === "url"
                                ? "Insert Link"
                                : "Insert Video Link"
                            }
                            </Button>
                        </Grid>
                    </Grid>
                </Popover>
            )}
            <Grid container spacing={1} justify="center">
                <Grid item md={6} xs={4} spacing={1} container>
                    { !mobile && 
                    <Grid item lg={6} xs={12}>
                        <Button 
                            startIcon={<EmojiEmotionsOutlined color="secondary" />} 
                            endIcon={<AddCircleOutline color="primary"/>} 
                            onClick={() => setShowEmojiMenu(true) }
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                            disabled={disabled}
                        >
                            Emoji
                        </Button>
                    </Grid>
                    }
                    <Grid item lg={6} xs={12}>
                        <Button 
                            startIcon={
                                <LinkIcon style={{
                                    color: theme.palette.secondary.main,
                                    transform: "rotate(-46deg)",
                                }} />
                            } 
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                            onClick={() => {setShowLinkMenu(true); setLinkType("url")}}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                            disabled={disabled}
                        >
                            Link
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={8} container spacing={1}>
                    <Grid item lg={6} md={12} xs={6}>
                        <Button 
                            startIcon={<ImageOutlinedIcon  color="secondary" />} 
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                            onClick={onFileUploadClick}
                            disabled={uploadedImageCount > 4 || disabled}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                            >
                            <input
                                ref={fileUploadInput}
                                name="Media"
                                type="file"
                                style={{ display: "none" }}
                                onChange={onFileSelected}
                                key={`uploadFileKey${uploadFileKey}`}
                                />
                                Image
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={12} xs={6}>
                        <Button 
                            fullWidth
                            startIcon={<OndemandVideoOutlined  color="secondary" />} 
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                            onClick={() => {setShowLinkMenu(true); setLinkType("video")}}
                            disabled={disabled}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            >
                            Video
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}