import { useEffect, useState } from "react";
import Attendee from "../entities/Attendee";
import TextMessageHistoryItem from "../entities/Messages/TextMessageHistoryItem";
import { SerializedTextMessageHistoryItem, deserializeTextMessagehistory, MessageService } from "../services/MessageService";
import { ServerResult } from "../services/server/ServerResult";
import { useHubEvent } from "./useHubEvent";
import { useHubSubscription } from "./useHubSubscription";
import { useServerErrorAlert } from "./useServerErrorAlert";
import { TextMessageDirection } from "../entities/Messages/TextMessageDirection";
import { sounds } from "../utillity/sounds/Sounds";

export function useAttendeeTextMessageHistory(attendee?: Attendee){
    const setErrorAlert = useServerErrorAlert();
    const [selectedAttendeeId, setSelectedAttendeeId] = useState(attendee);
	const [selectedAttendeeMessageHistory, setSelectedAttendeeMessageHistory] = useState<TextMessageHistoryItem[]>([])

	useEffect(() => {
		async function getAttendeeMessageHistory(id: string){
			const result = await MessageService.getAttendeeMessageHistory(id);

			if(ServerResult.isSuccess(result))
				return setSelectedAttendeeMessageHistory(result.data)

			return setErrorAlert({message: "Unable to load message history", statusCode: 200})
		}

        if(selectedAttendeeId)
		    getAttendeeMessageHistory(selectedAttendeeId.id)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAttendeeId])

    useEffect(() => {
        setSelectedAttendeeId(attendee);
    }, [attendee])
    
    const onMessageEvent = (message:TextMessageHistoryItem) => {
		setSelectedAttendeeMessageHistory(selectedAttendeeMessageHistory => {
			if(selectedAttendeeMessageHistory.some(m => m.id === message.id))
				return selectedAttendeeMessageHistory.map(m => m.id === message.id ? message : m)
			if(message.direction === TextMessageDirection.Receive)
				sounds.pop.play();
		 	return [...selectedAttendeeMessageHistory, message]
		}
		)
		
	}
    
    useHubSubscription(
        {methodName: "SubscribeToAttendeeTextMessageHistory", args: [selectedAttendeeId?.id]},
        {methodName: "UnsubscribeToAttendeeTextMessageHistory", args: [selectedAttendeeId?.id]}
    )
	useHubEvent("OnMessageHistoryChangeEvent", (message:SerializedTextMessageHistoryItem) => onMessageEvent(deserializeTextMessagehistory(message)));

    return selectedAttendeeMessageHistory;
}