import { Button, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { MultistepDialogStepLayoutProps, useCommonLayoutStyle } from "./MultistepDialogStepLayout";
import BackArrow from "@material-ui/icons/KeyboardArrowLeft";
import NextArrow from "@material-ui/icons/KeyboardArrowRight";
import { useDialogSteps } from "../CurrentDialogStep";


export function MultistepMobileLayout(props: React.PropsWithChildren<MultistepDialogStepLayoutProps>) {
    const { disabled, backButtonText, nextButtonText, title, hideBackStep } = props;
    const dialogContext = useDialogSteps();

	let windowSize = window.innerWidth;
	let swoopWidth = windowSize * 1.6;
	let leftPos = ((swoopWidth - windowSize) / 2) * -1;

	const classes = { ...useCommonLayoutStyle(), ...useStyles() };

	return (
		<>
			<div
				className={classes.swoopBox}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			></div>
			<div
				className={classes.swoopBoxGreen}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			/>
			<div className={classes.headerBar}>
				<Typography className={classes.titlebartitle} variant="h1" style={{ paddingLeft: 14 }}>
					{title}
				</Typography>
				{!hideBackStep &&
					<IconButton className={classes.titleCloseIcon} aria-label="close modeal" component="span" disabled={disabled} onClick={dialogContext.onClose}>
						<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
					</IconButton>
				}
			</div>

			<Paper className={classes.setupContent}>
				<Grid container style={{ display: "block", overflowY: "auto", height: "100%" }}>
					<Grid item xs={12} style={{ marginLeft: 12, marginRight: 12, height: "100%" }}>
						<Grid container direction="column" justify="center" alignItems="center" style={{height: "calc(100% - 59px)"}}>
							<Grid item>
							{props.children}
							</Grid>
						</Grid>
					</Grid>
					<Grid style={{ display: "flex", position: "absolute", bottom: 0, width: "100%" }} item xs={12}>
						{!props.hideBackStep && 
							<Button
							variant="contained"
							color="primary"
							size="large"
							disabled={disabled}
							className={classNames(classes.actionButtons, classes.backButton)}
							startIcon={<BackArrow style={{ marginRight: 0 }} />}
							onClick={dialogContext.previousStep}
							>
								{backButtonText ?? "Back"}
							</Button>
						}
						<div className={classes.grow} />
						{!props.required && props.skip ? (
							<Button
								disabled={disabled}
								variant="contained"
								color="primary"
								size="large"
								className={classNames(classes.actionButtons, classes.nextButton)}
								endIcon={<NextArrow />}
								onClick={props.onSkip}
							>
								Skip
							</Button>
						) : (
							!(props.required && props.hideNextStep)
                            &&
                            <Button
								disabled={disabled}
								variant="contained"
								color="primary"
								size="large"
								className={classNames(classes.actionButtons, classes.nextButton)}
								endIcon={<NextArrow />}
								onClick={props.onConfirmStep}
							>
								{nextButtonText ?? "Next"}
							</Button>
						)}
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	swoopBox: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.secondaryResponse.main,
		zIndex: 2000,
		top: 0,
	},
	swoopBoxGreen: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.primaryResponse.main,
		top: 40,
		boxShadow: "0 8px 6px -6px #C7BAB9",
	},
	headerBar: {
		position: "fixed",
		zIndex: 2001,
		width: "100%",
		display: "flex",
		alignItems: "center",
	},
	setupContent: {
		position: "fixed",
		paddingBottom: 59,
		paddingLeft: 6,
		paddingRight: 6,
		zIndex: 2001,
		top: 55,
		bottom: 0,
		left: 8,
		right: 8,
		borderRadius: "12px 12px 0 0",
		overflowY: "auto", overflowX: "hidden",
	},
}));