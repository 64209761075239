import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PhoneTextField } from "../../../components/PhoneTextField";
import ChurchDetails from "../../../entities/AdminTools/ChurchDetails";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import ImpersonationMetricService from "../../../services/ImpersonationMetricService";
import { ServerResult } from "../../../services/server/ServerResult";

export function UpdateResponsePhoneNumber(props: {churchDetails: ChurchDetails}){
    const { churchDetails } = props;
    const [phoneNumber, setPhoneNumber] = useState(churchDetails.responsePhoneNumber.phoneNumber)
    const [disabled, setDisabled] = useState(false)

    const setServerError = useServerErrorAlert();
    const setSuccessAlert = useSuccessAlert();

    const submitNewPhoneNumber = async() => {
        if(/\D/gi.test(phoneNumber) || phoneNumber.length !== 10)
            return setServerError({statusCode: 200, message: "Invalid PhoneNumber"})

        const request = { phoneNumber, churchId: churchDetails.churchId}

        setDisabled(true)
        const response = await ImpersonationMetricService.ChangeResponseNumber(request)
        setDisabled(false)

        if(ServerResult.isSuccess(response))
            setSuccessAlert("Success");
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Change {churchDetails.responsePhoneNumberProvider} Phonenumber</Typography>
                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href={ churchDetails.responsePhoneNumberProvider === "SignalWire"
                        ?"https://response.signalwire.com/phone_numbers/new"
                        :"https://dashboard.bandwidth.com/portal/r/a/5008747/numbers/search-and-buy"
                    }
                >Check available numbers</a>
            </Grid>
            <Grid item xs={12}>
                <PhoneTextField disabled={disabled} onChangePhoneNumber={setPhoneNumber} defaultValue={churchDetails.responsePhoneNumber.phoneNumber?.slice(2)} variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={submitNewPhoneNumber} variant="contained" color="primary" disabled={disabled}>
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}