import { Grid, Typography, CircularProgress, useTheme, Fade } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { AwesomeCheckbox } from "../../../components/AwesomeCheckbox";
import { useDialogSteps } from "../../../components/MultistepDialog/CurrentDialogStep";
import { MultistepDialogStepLayout } from "../../../components/MultistepDialog/Layouts/MultistepDialogStepLayout";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { useInterval } from "../../../hooks/useInterval";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useUser } from "../../../hooks/useUser";
import { AuthService } from "../../../services/AuthService";
import { RegistrationService } from "../../../services/RegistrationService";
import { ServerResult } from "../../../services/server/ServerResult";
import CheckIcon from "@material-ui/icons/Check";

export default function AcquireResponseNumber(){
    const setServerError = useServerErrorAlert();
	const theme = useTheme();
	const [user, setUser] = useUser();
    const [displayNumber, setDisplayPhoneNumber] = useState("");
    const dialogContext = useDialogSteps();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function acquireResponseNumber(){
			setIsLoading(true);
			
			const result = await RegistrationService.acquireResponseNumber();
			
            if(ServerResult.isError(result))
                return setServerError(result)

            const number = formatPhoneNumber(result.data);
            setDisplayPhoneNumber(number!)
            setIsLoading(false);
		}

        if(user && !user.responsePhoneNumber)
            acquireResponseNumber();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const handleExit = async() => {  
        const refreshResult = await AuthService.refreshUser();
    
        if(ServerResult.isError(refreshResult))
            return setServerError(refreshResult)

        if (ServerResult.isSuccess(refreshResult)){
            setUser(refreshResult.data.jwt);
            dialogContext.nextStep();
        }
    }

	return (
        <MultistepDialogStepLayout 
            title="Get your custom church number" 
            required={true} 
            hideNextStep={isLoading} 
            disabled={isLoading} 
            nextButtonText="Finish Setup"
            onConfirmStep={handleExit}
            hideBackStep={true}
        >
            <VerticalCenter>
                <Grid container justify="center">
                    <Grid item sm={8} xs={12}>
                        {isLoading 
                        ?
                            <Grid container direction="column" justify="center" alignItems="center">
                                <CircularProgress 
                                    size={80} 
                                    style={{
                                        color: theme.palette.primaryResponse.main,
                                        marginTop: 30,
                                        marginBottom: 40,
                                    }}
                                />
                                <RenderLoadingMessages />
                            </Grid>
                        :
                            <Grid container direction="column" spacing={2}>
                                { displayNumber &&
                                    <Grid item>
                                        <Typography align="center" variant="body2" style={{ fontSize: 18 }}>
                                            You did it! Here's your new number: {displayNumber}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item>
                                    <AwesomeCheckbox size={90} color="secondary" />
                                </Grid>
                                { displayNumber && user &&
                                    <Grid item>
                                        <Typography align="center" variant="body2" style={{ fontSize: 18 }}>
                                            {`${user.activeChurchName}: ${displayNumber}`}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </VerticalCenter>
		</MultistepDialogStepLayout>
	);
}

interface LoadingMessage {
    fadeIn: boolean;
    completed: boolean;
    text: "Creating your church profile" | "Searching available numbers" | "Claiming your custom number" | "Finishing your account setup"
}

function RenderLoadingMessages(){
    const [loadingStep, setLoadingStep] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState<LoadingMessage>({ fadeIn: true, completed: false, text: "Creating your church profile"});
   
    useInterval(() => {
        setLoadingMessage(getStep(loadingStep))
        setLoadingStep(loadingStep + 1)
    }, 2000)

    const getStep = (step:number):LoadingMessage => {
        switch(step){
            case 0:
                return {fadeIn: true, completed:false, text: "Creating your church profile"}
            case 1:
                return {fadeIn: true, completed:true, text: "Creating your church profile"}
            case 2:
                return {fadeIn: false, completed:true, text: "Creating your church profile"}
            case 3:
                return {fadeIn: true, completed:false, text: "Searching available numbers"}
            case 4:
                return {fadeIn: true, completed:true, text: "Searching available numbers"}
            case 5:
                return {fadeIn: false, completed:true, text: "Searching available numbers"}
            case 6:
                return {fadeIn: true, completed:false, text: "Claiming your custom number"}
            case 7:
                return {fadeIn: true, completed:true, text: "Claiming your custom number"}
            case 8:
                return {fadeIn: false, completed:true, text: "Claiming your custom number"}
            default:
                return {fadeIn: true, completed:true, text: "Finishing your account setup"}
        }
    }

    const {fadeIn, completed, text} = loadingMessage;
        
    return (
        <Fade in={fadeIn} exit={!fadeIn} timeout={{enter:500, exit:700}}>
            <Typography variant="h2">
                <Fade in={completed} exit={!completed} timeout={{enter: 500}}>
                    <CheckIcon color="secondary" style={{verticalAlign: "bottom", marginRight: 3}}/>
                </Fade>
                { text }
            </Typography> 
        </Fade>
    )
}