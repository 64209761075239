import { useEffect, useState } from "react";

export default function useScrollEvent(timeout: number):boolean {
	const [scrolling, setScrolling] = useState(false);
    let handle:NodeJS.Timeout | null = null;

    const onScroll = () => {
		setScrolling(true)
        if (handle) {
             clearTimeout(handle);
        }
        handle = setTimeout(() => setScrolling(false), timeout || 200); // default 200 ms
    };

	useEffect(() => {
		document.querySelector("#MobileContent")?.addEventListener('scroll', onScroll);
		document.querySelector("#main")?.addEventListener('scroll', onScroll);
		return () => {
			if (handle) {
				  clearTimeout(handle);
			}
			document.querySelector("#MobileContent")?.removeEventListener('scroll', onScroll);
			document.querySelector("#main")?.removeEventListener('scroll', onScroll);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return scrolling;
};
