import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";
import { MessageInput } from "../Components/MessageInput";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useMessageRouter } from "../MessagePage";
import { InboxMessageHistory } from "./InboxMessageHistory";
import { MessageService } from "../../../services/MessageService";
import { ChurchOptInStatus } from "../../../entities/ChurchOptInStatus";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";

interface DesktopInboxPageProps {}

export function DesktopInboxPage(props: DesktopInboxPageProps) {
	const messageRouter = useMessageRouter();
	const { selectedAttendee } = messageRouter;
	const setErrorAlert = useServerErrorAlert();
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const handleSend = (message:string, media:TextMessageMedia[]) => {
		if(selectedAttendee && selectedAttendee.optInStatus === ChurchOptInStatus.Accepted)
			sendMessage(selectedAttendee.id, message, media)
	}

	const sendMessage = async(attendeeId:string, message:string, media:TextMessageMedia[]) => {
		setFieldErrors([]);
		setDisabled(true);
		const result = await MessageService.sendDirectMessage({attendeeId, message, media});
		setDisabled(false);

		if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

		if (ServerResult.isError(result)) return setErrorAlert(result);

		if (ServerResult.isError(result)) return setErrorAlert(result);
	};

	return (
		
		<div style={{ height: "100%" }}>
			<div style={{ height: 55, padding: 15, borderBottom: "1px solid #e0e0e0" }}>
				<Typography>
					To:&nbsp;
					{selectedAttendee && (
						<ClientLink to={routes.app.resolve.peopleDetailPage(selectedAttendee.id)}>
							{selectedAttendee.name}
						</ClientLink>
					)}
				</Typography>
			</div>
			<div style={{ height: "calc(100% - 55px)", overflowY: "auto", paddingTop: 10 }}>
				<Grid container justify="center" style={{ minHeight: "calc(100% - 110px)" }}>
					<Grid item xl={8} lg={10} md={11}>
						{selectedAttendee && <InboxMessageHistory />}
					</Grid>
				</Grid>

				<div style={{ position: "sticky", bottom: 0, zIndex: 1000, backgroundColor: "#FFF", paddingBottom: 10 }}>
					<Grid container justify="center">
						<Grid item xl={8} lg={10} md={11}>
							<MessageInput
								disabled={selectedAttendee === undefined || disabled}
								onSend={handleSend}
								errors={fieldErrors}
								key={`${selectedAttendee?.id}`}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
}
