import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, List, ListItem, ListItemText, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useMessageRouter } from "../MessagePage";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import { orderBy } from "../../../utillity/orderBy";
import { useTextMessagePreviews } from "../../../providers/TextMessagePreviewProvider";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { SendDirectMessageDialog } from "../Components/SendDirectMessageDialog";
import { MessagePreviewListItem } from "../Components/MessagePreviewListItem";
import { ButtonLink } from "../../Groups/ButtonLink";
import { TextMessagePreviewStatus } from "../../../entities/Messages/TextMessagePreviewStatus";
import { MessageService } from "../../../services/MessageService";
import { matchPath, useLocation } from "react-router-dom";

interface InboxMessagePreviewProps {
}

export function InboxMessagePreview(props:InboxMessagePreviewProps){
    const history = useHistory();
    const location = useLocation();	
    const { textMessagePreviews } = useTextMessagePreviews();
    const { attendees } = useAttendees();
    const messageRouter = useMessageRouter();
    const { selectedAttendee } = messageRouter;
    const selectedAttendeeId = selectedAttendee?.id;

    const isSendMessageRoute = matchPath(location.pathname, {path: routes.app.sendMessagePage, exact: true, strict: true}) != null;

    const [displayPreviews, setDisplayPreviews] = useState(textMessagePreviews);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const [showSendMessageDialog, setShowSendMessageDialog] = useState(isSendMessageRoute);

    useEffect(() => {
		if(!showSendMessageDialog && isSendMessageRoute){
			history.replace(routes.app.inboxPage)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSendMessageRoute, showSendMessageDialog])

    useEffect(() => {
        let updatedPreviews = [...textMessagePreviews];
        
        updatedPreviews = updatedPreviews.sort(orderBy.optional.date(p => p.orderByDate, "Descending", "optionalEnd"))

        if(searchText){
            const searchRegex = new RegExp(searchText, "i");
            const filteredAttendees = [...attendees].filter((attendee) => {
                if(searchRegex.test(attendee.name))
                    return true;
                if(searchRegex.test(attendee.phoneNumber))
                    return true;
                return false; 
            })
            updatedPreviews = updatedPreviews.filter((preview) => {
                if(filteredAttendees.some(a => a.id === preview.attendeeId))
                    return true
                if(searchRegex.test(preview.previewMessage))
                    return true;
                return false;

            }) 
        }

        setDisplayPreviews(updatedPreviews);
    }, [textMessagePreviews, searchText, attendees])

    useEffect(() => {
        if(!selectedAttendeeId)
            return;
        
        const selectedPreview = textMessagePreviews.find(p => p.attendeeId === selectedAttendeeId);
        if(selectedPreview && selectedPreview.status === TextMessagePreviewStatus.Unread)
            MessageService.markMessageAsRead(selectedPreview.id);
        
    }, [selectedAttendeeId, textMessagePreviews])

    return (
        <Grid container direction="column" wrap="nowrap" style={{ height: "100%" }}>
            <Grid item container alignContent="center" justify="space-between" style={{ padding: 5 }}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-bare"
                        defaultValue={searchText}
                        variant="outlined"
                        fullWidth
                        // className={classes.searchBar}
                        onChange={(e) => setSearchText(e.currentTarget.value)}
                        size="small"
                        InputProps={{
                            startAdornment: <Search style={{ marginLeft: -6 }} />,
                        }}
                        style={{ marginTop: 0, marginBottom: 5 }}
                        />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" onClick={() => setShowSendMessageDialog(true)}>
                        New Message
                    </Button>
                </Grid>
            </Grid>
            <Grid item style={{ overflowY: "auto" }}>
                <List style={{paddingBottom: 0, paddingTop: 0}}>
                    <Divider component="li" />
                    { displayPreviews.length === 0 &&
                        <>
                        <ListItem>
                            <ListItemText
                                primary={<Typography>You don't have any messages yet.</Typography>}
                                secondary={
                                    <Typography>
                                        <ButtonLink onClick={() => setShowSendMessageDialog(true)}>Send a message.</ButtonLink>
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        </>
                    }
                    {
                        selectedAttendee && !textMessagePreviews.some(p => p.attendeeId === selectedAttendee.id)
                        &&
                        <MessagePreviewListItem 
                                onClick={() => history.push(routes.app.resolve.inboxDetailPage(selectedAttendee.id))} 
                                message={"It's going to be great!"} 
                                title={`New message to ${selectedAttendee.name} >`} 
                                displayDate={undefined} 
                                isSelected={true}
                                status={TextMessagePreviewStatus.Unread}
                            />
                    }
                    {displayPreviews.map((preview) => {
                        const attendee = attendees.find(a => a.id === preview.attendeeId);
                        const isSelected = selectedAttendee?.id === preview.attendeeId ?? false;
                        return attendee ? (
                            <MessagePreviewListItem 
                                key={attendee.id} 
                                onClick={() => history.push(routes.app.resolve.inboxDetailPage(preview.attendeeId))} 
                                message={preview.previewMessage} 
                                title={attendee.name} 
                                displayDate={preview.lastTextMessageDate} 
                                isSelected={isSelected}
                                status={preview.status}
                            />
                        ) : null;
                    })}
                </List>
            </Grid>
            {showSendMessageDialog && <SendDirectMessageDialog isOpen={showSendMessageDialog} onClose={() => setShowSendMessageDialog(false)} /> }
        </Grid>
    )
}