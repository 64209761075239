import React, { createContext, PropsWithChildren, useContext, useState } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorPage } from "../../components/ServerErrorPage";
import { useAdminToolsContext } from "../../providers/AdminToolsProvider";
import { useAppData } from "../../providers/AppDataProvider";
import { routes } from "../../routes";
import { ChurchMetrics } from "../../services/ImpersonationMetricService";
import { AdminToolsDetailPage } from "./AdminToolsDetailPage";
import { ImpersonateChurch } from "./ImpersonateChurch";

export function AdminToolsPage(){
    const params = useParams<{ churchId?: string }>();
	const appData = useAppData();
	const { groupsLoading, groupServerError, attendeesLoading, attendeeServerError} = appData;	

	if (attendeesLoading || groupsLoading) {
		return <LoadingPage />;
	}

	if(attendeeServerError)
		return <ServerErrorPage serverError={attendeeServerError}/>

	if(groupServerError)
		return <ServerErrorPage serverError={groupServerError}/>

    return (
        <AdminToolsRouterProvider churchId={params.churchId}>
            <AdminToolsPageRouter />
        </AdminToolsRouterProvider>
    );
}

interface AdminToolsRouterContext {
    selectedChurch: ChurchMetrics | undefined;
	selectChurch: (church: ChurchMetrics | undefined) => void;
}

const AdminToolsRouterReactContext = createContext<AdminToolsRouterContext>({} as any);

export function useAdminRouter() {
	return useContext(AdminToolsRouterReactContext);
}

function AdminToolsRouterProvider(props: PropsWithChildren<{ churchId: string | undefined }>) {
	const history = useHistory();
    const adminToolsContext = useAdminToolsContext();
    
	const [selectedChurch, setSelectedChurch] = useState<ChurchMetrics | undefined>(adminToolsContext.churches?.find(c => c.churchId === props.churchId));

	const onSelect = (church: ChurchMetrics | undefined) => {
		setSelectedChurch(church);
        if(church){
            history.push(routes.app.resolve.AdminToolsDetailPage(church.churchId));
        } else {
            history.push(routes.app.impersonateChurch);
        }
	};

	return (
		<AdminToolsRouterReactContext.Provider value={{ 
			selectedChurch, 
			selectChurch: onSelect,
		}}>
			{props.children}
		</AdminToolsRouterReactContext.Provider>
	);
}

function AdminToolsPageRouter() {
	const router = useAdminRouter();
	return (
		<Switch>
			<Route exact path={routes.app.impersonateChurch}>
				<ImpersonateChurch />
			</Route>
			{ router.selectedChurch &&
				<Route exact path={routes.app.AdminToolsDetailPage}>
					<AdminToolsDetailPage churchId={router.selectedChurch.churchId} />
				</Route>
			}
			<Redirect to={routes.app.impersonateChurch} />
		</Switch>
	);
}