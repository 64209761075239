import {
	Button,
	Grid,
	IconButton,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import PdfDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { useState } from "react";
import LoadingSpinner from "../../components/loaderSpinner";
import { PageTitle } from "../../components/PageTitle";
import ResponseInvoice from "../../entities/ResponseInvoice";
import { buildDateTimeString } from "../../functions/datetime";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { ResponseCustomerService } from "../../services/ResponseCustomerService";
import { ServerResult } from "../../services/server/ServerResult";
import TabHeaderBar, { SettingsTab } from "./TabHeaderBar";

export const useConnectStyles = makeStyles((theme: Theme) => ({
	invoiceLine: {
		padding: 12,
	},
}));

const Invoices = () => {
	const classes = useConnectStyles();

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const setServerErrorAlert = useServerErrorAlert();

	const [isLoading, setLoading] = useState(true);
	const [invoices, setInvoices] = useState<ResponseInvoice[] | undefined>();

	React.useEffect(() => {
		const fetch = async () => {
			const result = await ResponseCustomerService.getInvoices();
			setLoading(false);

			if (ServerResult.isSuccess<ResponseInvoice[]>(result)) {
				setInvoices(result.data);
			} else {
				setServerErrorAlert(result);
			}
		};
		fetch();
	}, [setServerErrorAlert]);

	const currencyFormat = (num: number) => {
		return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	};

	if (isLoading) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={isLoading} durationMessage={"Fetching Invoice List..."} />
			</div>
		);
	}

	const renderInvoices = (invoices: ResponseInvoice[]) => {
		return (
			<TableBody style={{backgroundColor: "#FFF"}}>
				{invoices.map((invoice, index) => {
					return (
						<TableRow>
							<TableCell>{buildDateTimeString(invoice.created.toString(), true)}</TableCell>
							<TableCell>{currencyFormat(invoice.total)}</TableCell>
							<TableCell align="right">
								{!mobileFormFactor && (
									<Button
										href={invoice.invoicePdfUrl}
										target="_blank"
										rel="noopener noreferrer"
										className={classes.invoiceLine}
										variant="outlined"
										startIcon={<PdfDownloadIcon />}
										color="primary"
									>
										Download PDF
									</Button>
								)}
								{mobileFormFactor && (
									<IconButton
										href={invoice.invoicePdfUrl}
										target="_blank"
										rel="noopener noreferrer"
										aria-label="download"
										color="primary"
										style={{ opacity: 0.7 }}
									>
										<PdfDownloadIcon />
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	return (
		<Grid container spacing={2}>
			<PageTitle title="Invoices" />
			{!mobileFormFactor && (
				<>
					<Grid item xs={12}>
						<Typography variant="h1">Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<TabHeaderBar tab={SettingsTab.Invoices} />
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<TableContainer>
					<Table
						style={{
							maxWidth: 800,
							margin: "0 auto",
							border: "1px solid rgba(0,0,0,.1)",
							borderRadius: 4,
						}}
						size="small"
						stickyHeader
						aria-label="sticky table"
					>
						<TableHead>
							<TableRow>
								<TableCell align="left">Invoices</TableCell>
								<TableCell />
								<TableCell />
							</TableRow>
						</TableHead>
						{invoices && renderInvoices(invoices)}
					</Table>
				</TableContainer>
				{!invoices && <div>no invoices</div>}
			</Grid>
		</Grid>
	);
};

export default Invoices;
