export enum TextMessageType
{
    DirectMessage = 1,
    GroupAnnouncement = 2,
    Notification = 3,
    Invite = 4,
    Keyword = 5,
    AutomaticResponse = 6,
    Unsubscribe = 7,
    UnsubscribeAll = 8,
    Introduction = 9,
    Help = 10,
    Prompt = 11,
    OptIn = 12,
    OptOut = 13,
    JourneyBroadcastMessage = 14,
}