import React from "react";
import { Modal } from "@material-ui/core";
import { isMobile } from "../hooks/isMobile";

interface ImageDialogProps {
    imageSource: string;
    open: boolean;
    close: () => void;
}

export function ImageDialog(props:ImageDialogProps){
    const { imageSource, open } = props;
    const mobile = isMobile();

    return (
        <Modal 
            open={open} 
            onClick={() => props.close()} 
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <img
                src={imageSource}
                alt="MMS"
                style={{
                    maxWidth: !mobile ? "80%" : "100%",
                    maxHeight: !mobile ? "80%" : "100%",
                    marginLeft: !mobile ? "10%" : undefined,
                }}
            />
        </Modal>
    )
}