import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { resolveEnvironment, RuntimeEnvironment } from "../../Environment";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import ResponseSubscriptionService from "../../services/ResponseSubscriptionService";
import { ServerResult } from "../../services/server/ServerResult";
const stripePromise = loadStripe(
	resolveEnvironment() === RuntimeEnvironment.Production 
	? "pk_live_51HcdYkApTuIEGikUMSiunti5TP6OdAsKBTJinfecWYInyE4CkrX5UFdE8SYFxzzUqAj1Z6c32szCfQaoweJBSkEP00ruQLo90S"
	: "pk_test_51HcdYkApTuIEGikUQsRrAKAuG2xzDT4iSMrRSsx2VC1EmppcG0f3o78DcHbwLlkbsEILb7mcF90nL6kNyFwepDO900p1ucRHSj"
);

export function ChangePaymentMethod() {
	const setServerErrorAlert = useServerErrorAlert();
	useEffect(() => {
		async function getSession() {
			const result = await ResponseSubscriptionService.getUpdatePaymentSession();
			if (ServerResult.isSuccess(result)) {
				const stripe = await stripePromise;
				await stripe!.redirectToCheckout({
					sessionId: result.data,
				});
			} else {
				setServerErrorAlert(result);
			}
		}
		getSession();
	}, [setServerErrorAlert]);

	return null;
}

export default ChangePaymentMethod;
