import { ChurchOptInStatus } from "./ChurchOptInStatus";
import { Role } from "./Role";
import SmsMessage from "./SmsMessage";

export default interface Attendee {
	id: string;
	churchId: string;
	phoneNumber: string;
	name: string;
	firstName: string;
	activeJourneyId?: string;
	messageHistory: SmsMessage[];
	nullableUserId: string | null;
	role: Role;
	lastActivityDate: Date;
	dateCreated: Date;
	email: string | null;
	optInStatus: ChurchOptInStatus;
}

export enum AdminInviteStatus {
	Pending = 0,
    Accepted = 1
}

export const printAttendeeStatus = (attendee: Attendee) => {
    if(attendee.optInStatus === ChurchOptInStatus.Pending)
        return "Pending church invite";
    
    if(attendee.optInStatus === ChurchOptInStatus.Declined)
        return "Opted out of church messages";

    return "Subscribed"
}