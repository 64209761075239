import { Grid, makeStyles, SwipeableDrawer, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import { TextMessageMedia } from "../entities/Messages/TextMessageMedia";
import { useAppUser } from "../hooks/useAppUser";
import { PersonalizedMessage } from "../utillity/Personalization";
import { useJourneyMessagePreviewStyles } from "./JourneySetup/Steps/JourneyMessagePreviewStep";
import { useJourneyStepStyles } from "./JourneySetup/Steps/JourneyStepLayout";
import CloseIcon from "@material-ui/icons/Close";

interface FancyPhoneProps {
	keyword?: string;
	completionResponse: string;
	prompt?: string;
	media?: TextMessageMedia[];
	removeImage?: (media:TextMessageMedia) => void;
}

export function FancyPhone(props: FancyPhoneProps) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <MobileView {...props} />;
	}

	return <DesktopView {...props} />;
}

function MobileView(props: FancyPhoneProps) {
	const classes = { ...useJourneyStepStyles(), ...useJourneyMessagePreviewStyles(), ...useStyles() };
	const [user] = useAppUser();

	const {completionResponse, keyword, prompt, media} = props;
	const [showMobilePreviewDrawer, setShowMobilePreviewDrawer] = useState(false);

	return (
		<>
			<div
				className={classes.previewTab}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setShowMobilePreviewDrawer(true);
				}}
			>
				<p className={classes.previewText}>PREVIEW</p>
			</div>
			<SwipeableDrawer
				anchor={"right"}
				open={showMobilePreviewDrawer}
				onClose={() => setShowMobilePreviewDrawer(false)}
				onOpen={() => setShowMobilePreviewDrawer(true)}
				onBackdropClick={() => setShowMobilePreviewDrawer(false)}
			>
				<div className={classes.drawerHeader}>
					<Typography variant="h2">Message Preview</Typography>
					<div className={classes.grow} />
					<Cancel style={{ fontSize: 24 }} onClick={() => setShowMobilePreviewDrawer(false)} />
				</div>
				<div style={{ textAlign: "center", marginTop: 20 }}>
					<img
						style={{ width: window.innerWidth * 0.8, maxWidth: 400 }}
						src={"https://i.cdn-sc.com/Response/phoneoutline.png"}
						alt="Follow Up"
					/>
				</div>
				<div className={classes.mobilePhoneContent}>
					<Grid container>
						{ keyword &&
							<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
								<div className={classes.userMessage}>
									{keyword}
								</div>
							</Grid>
						}
						{prompt &&
							<>
								<Grid item xs={12} style={{ marginBottom: 8 }}>
									<div className={classes.systemMessage}>
										<PersonalizedMessage message={prompt} user={user} />
									</div>
								</Grid>
								<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
									<div className={classes.userMessage}>
										<i>Example message:</i>{" Yes/No"}
									</div>
								</Grid>
							</>
						}
						{completionResponse.length > 0 &&
							<Grid item xs={12}>
								<div className={classes.systemMessage}>
									<PersonalizedMessage message={completionResponse} user={user} />
								</div>
							</Grid>
						}
						{media && media.map(m => 
							<Grid item xs={12}>
								<CloseIcon style={{ position: "relative", top: 5, right: 5 }} color="secondary" onClick={() => props.removeImage && props.removeImage(m)}/>
								<img
									style={{
										display: "flex", 
										maxWidth: "75%", 
										width: "75%",
										borderRadius: 10,
									}}
									src={m.url}
									alt="MMS"
								/>
							</Grid>
						)}
					</Grid>
				</div>
			</SwipeableDrawer>
		</>
	);
}

function DesktopView(props: FancyPhoneProps) {
	const [user] = useAppUser();
	const classes = useStyles();
	const {completionResponse, keyword, prompt, media} = props;

	return (
		<Grid item xs={4} style={{ position: "relative" }}>
			<div style={{ textAlign: "center", minHeight: 493 }}>
				<img src={"https://i.cdn-sc.com/Response/phoneoutline.png"} alt="Follow Up" />
			</div>
			<div className={classes.desktopPhoneContent}>
				<Grid container>
					{ keyword &&
						<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
							<div className={classes.userMessage}>
								{keyword}
							</div>
						</Grid>
					}
					{prompt &&
						<>
							<Grid item xs={12} style={{ marginBottom: 8 }}>
								<div className={classes.systemMessage}>
									<PersonalizedMessage message={prompt} user={user} />
								</div>
							</Grid>
							<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
								<div className={classes.userMessage}>
									<i>Example message:</i>{" Yes/No"}
								</div>
							</Grid>
						</>
					}
					{completionResponse.length > 0 &&
						<Grid item xs={12}>
							<div className={classes.systemMessage}>
								<PersonalizedMessage message={completionResponse} user={user} />
							</div>
						</Grid>
					}
					{media && media.map(m => 
						<Grid item xs={12}>
							<CloseIcon style={{ position: "relative", top: 5, right: 5 }} color="secondary" onClick={() => props.removeImage && props.removeImage(m)}/>
							<img
								style={{
									display: "flex", 
									maxWidth: "75%", 
									width: "75%",
									borderRadius: 10,
								}}
								src={m.url}
								alt="MMS"
							/>
						</Grid>
					)}
				</Grid>
			</div>
		</Grid>
	);
}

const useStyles = makeStyles((theme:Theme) => ({
	systemMessage: {
		maxWidth: 160,
		color: "#1C4057",
		backgroundColor: "rgb(227,228,229)",
		padding: 8,
		borderRadius: 10,
		borderBottomLeftRadius: 0,
		overflowWrap: "anywhere",
	},
	userMessage: {
		maxWidth: 160,
		color: "#FFF",
		backgroundColor: "rgb(0,156,255)",
		padding: 8,
		borderRadius: 10,
		borderBottomRightRadius: 0,
		overflowWrap: "anywhere",
	},
	desktopPhoneContent: {
		position: "absolute",
		overflowY: "auto",
		overflowX: "hidden",
		top: 115,
		bottom: 46,
		left: 55,
		right: 57,
	},
	mobilePhoneContent: {
		position: "absolute",
		overflowY: "auto",
		overflowX: "hidden",
		top: window.innerWidth > 600 ? 210 : 185,
		bottom: 46,
		left: window.innerWidth > 600 ? 60 : window.innerWidth > 400 ? 50 : 35,
		right: window.innerWidth > 600 ? 60 : window.innerWidth > 400 ? 50 : 35,
	},
	drawerHeader: {
		display: "flex",
		paddingTop: 16,
		paddingLeft: 24,
		paddingRight: 24,
		color: theme.palette.secondaryResponse.main,
	},
	previewTab: {
		position: "absolute",
		top: -20,
		width: "50%",
		backgroundColor: theme.palette.secondaryResponse.main,
		color: "#FFF",
		textAlign: "center",
		borderBottomRightRadius: 16,
		borderBottomLeftRadius: 16,
		zIndex: 1200,
	},
	previewText: {
		marginBottom: 0,
		fontSize: 22,
		letterSpacing: 1,
	}
}))