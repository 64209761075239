import { Answer } from "../entities/Forms/Answer";
import { Form } from "../entities/Forms/Form";
import { Question } from "../entities/Forms/Question";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export type ServerForm = Modify<Form, {}>;

export type ServerAnswer = Modify<
	Answer,
	{
		answerDate: string;
	}
>;

export type CreateFormRequest = {
	title: string;
	description: string;
	journeyId?: string;
	questions: Question[];
};

export type UpdateFormRequest = {
	formId: string;
	journeyId: string | undefined;
	title: string;
	description: string;
	questions: Question[];
};

export type SubmitFormAnswersRequest = {
	formId: string;
	attendeeId: string;
	answers: Answer[];
};

const convertForm = (serverModel: ServerForm): Form => {
	return { ...serverModel };
};

const convertAnswer = (serverModel: ServerAnswer): Answer => {
	const answer: unknown = {
		...serverModel,
		answerDate: new Date(serverModel.answerDate),
	};
	return answer as Answer;
};

export const FormService = {
	get(id: string) {
		return WebClient.Convert.Get(`${apiRoot}/forms/${id}`, convertForm);
	},
	getAnonymousForm(churchId: string, formId: string, attendeeId: string) {
		return WebClient.Convert.Get(`${apiRoot}/forms/${churchId}/${formId}/${attendeeId}`, convertForm);
	},
	getAnswersByAttendeeForForm(churchId: string, formId: string, attendeeId: string) {
		return WebClient.Convert.Get<ServerAnswer[], Answer[]>(
			`${apiRoot}/forms/answers/${churchId}/${formId}/${attendeeId}`,
			(serverReminder) => serverReminder.map(convertAnswer)
		);
	},
	create(request: CreateFormRequest) {
		return WebClient.Convert.Post.Validated(`${apiRoot}/forms`, request, convertForm);
	},
	update(request: UpdateFormRequest) {
		return WebClient.Convert.Put.Validated(`${apiRoot}/forms`, request, convertForm);
	},
	submitFormAnswer(request: SubmitFormAnswersRequest) {
		return WebClient.Post.Validated(`${apiRoot}/forms/answers`, request);
	},
	getResults(id: string) {
		return WebClient.Convert.Get<ServerAnswer[], Answer[]>(`${apiRoot}/forms/results/${id}`, (serverReminder) =>
			serverReminder.map(convertAnswer)
		);
	},
};
