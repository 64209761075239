import { DialogTitle, DialogContent, DialogActions, Button, useTheme, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { Group } from "../../../entities/Groups/Group";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { GroupService } from "../../../services/GroupService";
import { useGroupRouter } from "../GroupPage";

interface DeleteGroupDialogProps {
    isOpen: boolean;
    onClose: () => void;
    group: Group;
}

export function DeleteGroupDialog(props: DeleteGroupDialogProps){
    const { group } = props;

    const theme = useTheme();    
    const successAlert = useSuccessAlert();
    const [disabled, setDisabled] = useState(false);
    const groupRouter = useGroupRouter();

    const onDeleteGroup = async() => {
        setDisabled(true);
        await GroupService.delete(group.id);
        setDisabled(false);
        successAlert(`Deleted ${group.name}`)
        groupRouter.deleteGroup(group)
    }
    return (
        <Dialog open={props.isOpen} onClose={() => props.onClose()}>
            <DialogTitle id="delete-Group-title">Delete Group</DialogTitle>
            <DialogContent>Are you sure you want to delete this group, this cannot be undone?</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="default"
                    size="medium"
                    onClick={props.onClose}
                    disabled={disabled}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    style={{color: theme.palette.error.main, borderColor: theme.palette.error.main}}
                    size="medium"
                    disabled={disabled}
                    onClick={() => onDeleteGroup()}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}