import { FormControl, Grid, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { Group } from "../../../entities/Groups/Group";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { useAutoShortLinkState } from "../../../hooks/useAutoShortLinkState";
import { FancyPhone } from "../../../components/FancyPhone";
import { CreateAddToGroupKeywordRrequest, KeywordService } from "../../../services/KeywordService";
import { isMobile } from "../../../hooks/isMobile";
import { PersonalizationButtons } from "../../../components/PersonalizationButtons";
import { useGroupRouter } from "../GroupPage";
import { Keyword } from "../../../entities/Keywords/Keyword";
import { StringHelper } from "../../../utillity/StringHelper";

interface AddToGroupKeywordDialogProps {
	group: Group;
	onClose: () => void;
}

const keywordLength = 12;

export function AddToGroupKeywordDialog(props: AddToGroupKeywordDialogProps) {
	const { group } = props;
	const groupId = group.id;
	const groupRouterContext = useGroupRouter();
	const { selectedGroupKeyword } = groupRouterContext;
	const mobile = isMobile();
	const theme = useTheme();
	const classes = useStyles();
	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [disabled, setDisabled] = useState(false);
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [smsSelectionIndex, setSmsSelectionIndex] = useState(0);
	const [smsSelectionStart,setSmsSelectionStart] = useState(0);
	const [smsBoxFocused, setSmsBoxFocused] = useState(false);

	const [keyword, setKeyword] = useState(selectedGroupKeyword?.displayKeyword ?? "");
	const autoLinkContext = useAutoShortLinkState(selectedGroupKeyword?.completionResponse ?? "", smsBoxFocused ? smsSelectionIndex : null, { groupId });
	const { hasShortLinks, processingLinks} = autoLinkContext;
	const [completionResponse, setCompletionResponse] = autoLinkContext.state;

	const [submitionRequest, setSubmissionRequest] = useState<CreateAddToGroupKeywordRrequest>();

	useEffect(() => {
		if (submitionRequest == null || processingLinks) {
			return;
		}

		async function createKeyword(request: CreateAddToGroupKeywordRrequest) {
			setFieldErrors([]);
			const result = await KeywordService.createGroupKeyword(request);
			setDisabled(false);
			setSubmissionRequest(undefined);

			if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

			if (ServerResult.isError(result)) return setErrorAlert(result);

			groupRouterContext.setGroupKeyword(result.data);
			setSuccessAlert("Keyword created");
			
			props.onClose();
		}

	async function update(request: CreateAddToGroupKeywordRrequest, groupKeyword: Keyword) {
			setFieldErrors([]);

			const result = await KeywordService.update({ id: groupKeyword.id ,...request});
			setDisabled(false);
			setSubmissionRequest(undefined);

			if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

			if (ServerResult.isError(result)) return setErrorAlert(result);

			groupRouterContext.setGroupKeyword(result.data);
			setSuccessAlert("Keyword updated");
			
			props.onClose();
		}

		selectedGroupKeyword 
			? update({ ...submitionRequest, completionResponse }, selectedGroupKeyword) 
			: createKeyword({ ...submitionRequest, completionResponse })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processingLinks, submitionRequest]);

	const onSubmit = async () => {
		const request: CreateAddToGroupKeywordRrequest = {
			keyword: keyword,
			completionResponse: completionResponse,
			groupId: group.id,
		};
		setDisabled(true);
		setSubmissionRequest(request);
	};


	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Grid container justify="center">
						<Grid item sm={8} xs={12}>
							<Grid container justify="center" direction="column">
								<Grid item style={{ textAlign: "center" }} xs={12}>
									<Typography className={classes.progressLabelText} variant="subtitle2">
										What keyword would you like a person to text in order to join {group.name}?
									</Typography>
								</Grid>
								<Grid item xs={12} style={{ width: mobile ? "100%" : 300, marginBottom: 10 }}>
									<TextField
										disabled={disabled}
										value={keyword}
										onChange={(e) => setKeyword(e.target.value)}
										placeholder="Keyword"
										margin="normal"
										variant="outlined"
										error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Keyword")}
										helperText={FieldValidationErrorUtils.getFieldError(fieldErrors, "Keyword")}
									/>
									<Typography variant="subtitle1" className={classes.instructionText}>
										Up to {keywordLength} characters, no spaces please!
									</Typography>
								</Grid>
								<FormControl fullWidth>
									<Typography className={classes.progressLabelLighterText} variant="subtitle2">
										Type a response
									</Typography>
									<TextField
										disabled={disabled}
										placeholder="Type your SMS message..."
										id="outlined-bare"
										value={completionResponse}
										margin="normal"
										variant="outlined"
										fullWidth
										style={{ marginTop: 0, marginBottom: 5 }}
										multiline={true}
										rowsMax={4}
										rows={3}
										onChange={(e) => {
											setCompletionResponse(e.currentTarget.value);
											setSmsSelectionIndex(e.currentTarget.selectionEnd ?? 0);
											setSmsSelectionStart(e.currentTarget.selectionStart ?? 0);
										}}
										onFocus={(e) => {
											setSmsBoxFocused(true)
										}}
										onBlur={() => setSmsBoxFocused(false)}
										error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "CompletionResponse")}
										helperText={FieldValidationErrorUtils.getFieldError(fieldErrors, "CompletionResponse")}
									/>
								</FormControl>
								{hasShortLinks && (
								<div style={{ height: 44, width: "100%" }}>
										<div
											style={{
												backgroundColor: "#7ac142",
												border: `1px solid ${theme.palette.success.dark}`,
												borderRadius: 12,
												padding: 7,
											}}
										>
											<Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap">
												<Grid item>
													<img src="/images/wand.png" alt="Wand" />
												</Grid>
												<Grid item>
													<Typography variant="subtitle2" style={{ color: "#fff", fontSize: 15 }}>
														Poof! Your link was magically shortened (we use Narnia-approved magic)
													</Typography>
												</Grid>
											</Grid>
										</div>
								</div>
								)}
								<PersonalizationButtons onAddPersonalization={(token) => setCompletionResponse(StringHelper.insert(completionResponse, token, smsSelectionStart))} />
							</Grid>
						</Grid>
						<FancyPhone keyword={keyword} completionResponse={completionResponse}/>
					</Grid>
				</VerticalCenter>
			</ResponseDialogContent>
			<ResponseDialogActions
				hideSecondaryButton={true}
				disabled={disabled}
				hideNextStep={false}
				onConfirm={onSubmit}
				nextButtonText={selectedGroupKeyword ? "Save" : "Create"}
				onBack={props.onClose}
				backButtonText="Cancel"
			/>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		padding: 8,
		height: 100,
		"& > textarea": {
			padding: 10,
			height: "100%",
			width: "100%",
		},
		width: "100%",
	},
	progressLabelLighterText: {
		color: "#404448",
		fontSize: 15,
	},
	editBox: {
		width: "100%",
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
	},
	instructionText: {
		textDecoration: "italics",
		color: "#404448",
		fontSize: 14,
	},
	progressLabelText: {
		color: "#404448",
		fontSize: 17,
		marginBottom: 30,
		[theme.breakpoints.down("sm")]: {
			fontSize: 15,
		},
	},
}));
