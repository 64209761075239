import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { ResponseDialogDesktopLayout } from "./ResponseDialogDesktopLayout";
import { ResponseDialogMobileLayout } from "./ResponseDialogMobileLayout";


export interface ResponseDialogLayoutProps {
	onClose: () => void;
    title: string;
};

export function ResponseDialogLayout(props: PropsWithChildren<ResponseDialogLayoutProps>){
    const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <ResponseDialogMobileLayout {...props} />;
	}
	return <ResponseDialogDesktopLayout {...props} />;
}

export const useCommonLayoutStyle = makeStyles((theme: Theme) => ({
	titlebartitle: {
		color: "#FFF",
		width: "100%",
	},
	titleCloseIcon: {
		float: "right",
	},
	grow: {
		flex: 1,
	},
	actionButtons: {
		borderRadius: 8,
		height: 36,
		margin: 9,
		marginBottom: 14,
		fontSize: "1rem"
	},
	backButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
	nextButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
	},
}));