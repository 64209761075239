import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDialogSteps } from "../../../components/MultistepDialog/CurrentDialogStep";
import { MultistepDialogStepLayout } from "../../../components/MultistepDialog/Layouts/MultistepDialogStepLayout";
import Church from "../../../entities/Church";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { RegistrationService } from "../../../services/RegistrationService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { ChurchRegistrationFormValidator } from "../../../utillity/ChurchRegistrationFormValidator";
import { RegisterChurchRequest, ChurchRegistrationForm } from "../ChurchSetupDialog/Components/ChurchRegistrationForm";
import { UserPhoneNumberForm } from "../ChurchSetupDialog/Components/UserPhoneNumberForm";

export function UpdateChurchBrand(props:{ church: Church}){
    const setServerError = useServerErrorAlert();
    const dialogContext = useDialogSteps();
    const [request, setRequest] = useState<RegisterChurchRequest | null>(null)
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
    const [errors, setErrors] = useState<FieldValidationError[]>([])
    const [disabled, setDisabled] = useState(false)
    
    const submitRequests = async(validRequest:RegisterChurchRequest, validPhoneNumber:string) => {
        setErrors([]);
        setDisabled(true);
        const response = await RegistrationService.updateChurch({...validRequest, mobilePhoneNumber: validPhoneNumber});
        setDisabled(false)

        if(ServerResult.isError(response))
            return setServerError({message: "Something went wrong", statusCode: 500});

        if(ServerResult.isValidationError(response))
            return setErrors(response.errors);

        dialogContext.nextStep()
    }

    const handleSubmit = () => {
        if(!request || !phoneNumber)
            return

        const validationErrors = ChurchRegistrationFormValidator(request)

        if(validationErrors.length > 0)
            return setErrors(validationErrors);
        
        submitRequests(request, phoneNumber)
    }

    return (
        <MultistepDialogStepLayout 
            title="Church Profile" 
            required={true} 
            hideNextStep={false} 
            disabled={disabled} 
            onConfirmStep={handleSubmit} 
            nextButtonText="Send Verification"
            hideBackStep={true}
        >
            <Grid container justify="center" style={{height: "100%"}}>
                <Grid item sm={8} xs={12}>
                    <Typography align="center" variant="h4" style={{ marginBottom: 16 }}>
                        Let's verify your church and contact info...
                    </Typography>
                    <ChurchRegistrationForm errors={errors} onRequestUpdate={setRequest} disabled={disabled} existingChurch={props.church}/>
                    <UserPhoneNumberForm errors={errors} onUpdate={setPhoneNumber} disabled={disabled} />
                </Grid>
            </Grid>
        </MultistepDialogStepLayout>
    )
}