import { ServerError } from "./ServerError";
import { ServerValidationError } from "./ServerValidationError";
import { ServerSuccess } from "./ServerSuccess";

export const ServerResult  = {
	isSuccess: <T>(model: ServerSuccess<any> | ServerError | ServerValidationError) : model is ServerSuccess<T>  => {
		const object: any = model;
		return object.data !== undefined;
	},
	isError: (
		model: ServerSuccess<any> | ServerError | ServerValidationError
	): model is ServerError => {
		const object: any = model;
		return object.message !== undefined && object.statusCode !== undefined;
	},
	isValidationError: (
		model: ServerSuccess<any> | ServerError | ServerValidationError
	): model is ServerValidationError => {
		const object: any = model;
		return object.errors !== undefined;
	}
}
