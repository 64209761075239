import React, { createContext, PropsWithChildren, useContext } from "react";
import { Group } from "../entities/Groups/Group";

interface GroupContext {
	groups: Group[];
	create: (group: Group) => void;
	update: (group: Group) => void;
	delete: (group: Group) => void;
}

const GroupReactContext = createContext<GroupContext>({} as any);

export function useGroups() {
	return useContext(GroupReactContext);
}

export function GroupProvider(props: PropsWithChildren<{ groupState: [Group[], (groups: Group[]) => void] }>) {
	const [groups, setGroups] = props.groupState;

	const create = (group: Group) => {
        setGroups([...groups.filter(g => g.id !== group.id), group])
    };

	const update = (group: Group) => {
        const updatedGroups = groups.map(g => g.id === group.id ? group : g);
        setGroups([...updatedGroups]);
    };

	const deleteGroup = (group: Group) => {
        const updatedGroups = groups.filter(g => g.id !== group.id);
        setGroups([...updatedGroups]);
    };

	return (
		<GroupReactContext.Provider
			value={{
				groups,
				create,
				update,
				delete: deleteGroup,
			}}
		>
			{props.children}
		</GroupReactContext.Provider>
	);
}