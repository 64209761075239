import { FormControl, InputAdornment, Button, TextField, CircularProgress } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useRef, useState } from "react";
import { NarniaMagicSnackBar } from "../../../components/NarniaMagicSnackBar";
import { isMobile } from "../../../hooks/isMobile";
import { useAutoShortLinkState } from "../../../hooks/useAutoShortLinkState";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { sounds } from "../../../utillity/sounds/Sounds";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { TextMessageMediaService } from "../../../services/TextMessageMediaService";
import { ServerResult } from "../../../services/server/ServerResult";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";
import { ImagePreviewList } from "../../../components/ImagePreviewList";
import { AddMessageExtrasButtons } from "../../../components/AddMessageExtrasButtons";
import { StringHelper } from "../../../utillity/StringHelper";

interface MessageInputProps {
	disabled?: boolean;
	onSend: (message: string, media: TextMessageMedia[]) => void;
	errors: FieldValidationError[];
}

export function MessageInput(props: MessageInputProps) {
	const errorAlert = useServerErrorAlert();
	const componentref = useRef<HTMLInputElement | null>(null);

	const [showNarniaMagic, setShowNarniaMagic] = useState(true);
	const [smsBoxFocused, setSmsBoxFocused] = useState(false);
	const [sendingMessage, setSendingMessage] = useState(false);
	const [uploadingFile, setUploadingFile] = useState(false);
	const [uploadFileKey, setUploadFileKey] = useState(0);

	const [smsSelectionIndex, setSmsSelectionIndex] = useState(0);
	const [images, setImages] = useState<TextMessageMedia[]>([]);

	const autoLinkContext = useAutoShortLinkState("", smsBoxFocused ? smsSelectionIndex : null, {});
	const { processingLinks, hasShortLinks } = autoLinkContext;
	const [message, setMessage] = autoLinkContext.state;
	const mobile = isMobile();

	useEffect(() => {
		if (processingLinks || !sendingMessage) {
			return;
		}

		if (!processingLinks && sendingMessage) props.onSend(message, images);
		sounds.popOut.play();
		setSendingMessage(false);
		setMessage("");
		setImages([]);
		setSmsBoxFocused(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sendingMessage, processingLinks]);

	const onSubmit = () => {
		setSendingMessage(true);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (sendingMessage) return;

		setMessage(e.currentTarget.value);
		setSmsSelectionIndex(e.currentTarget.selectionEnd ?? 0);
	};

	const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" && !e.shiftKey && !mobile) {
			e.preventDefault();
			e.stopPropagation();
			onSubmit();
		}
	};

	const handleFileUpload = async(file:File) => {
		setUploadingFile(true)
		const result = await TextMessageMediaService.upload(file);
		setUploadingFile(false)
		if (ServerResult.isSuccess(result)) setImages([...images, result.data]);
		else errorAlert(result);
		setUploadFileKey(uploadFileKey + 1);
	};

	return (
		<>
			<FormControl style={{ width: "100%" }} ref={componentref}>
				<ImagePreviewList images={images} removeImage={(image) => setImages([...images.filter((i) => i !== image)])} />
				<TextField
					fullWidth
					multiline
					variant="outlined"
					type="text"
					disabled={props.disabled || sendingMessage}
					placeholder="Type your SMS message..."
					id="outlined-bare"
					value={message}
					margin="normal"
					style={{ marginTop: 0, marginBottom: 5, backgroundColor: "#FFF" }}
					onChange={onChange}
					onKeyPress={onKeyPress}
					onFocus={() => setSmsBoxFocused(true)}
					onBlur={() => setSmsBoxFocused(false)}
					error={FieldValidationErrorUtils.isFieldInError(props.errors, "Message")}
					helperText={FieldValidationErrorUtils.getFieldError(props.errors, "Message")}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{uploadingFile  && <CircularProgress color="secondary" size={20} style={{marginRight: 5}}/>}
								<Button
									endIcon={<SendIcon />}
									color="primary"
									variant="contained"
									disabled={props.disabled || uploadingFile}
									onClick={onSubmit}
								>
									Send
								</Button>
							</InputAdornment>
						),
					}}
				></TextField>
			</FormControl>
				<div style={{ marginLeft: 5, marginRight: 5 }}>
					<AddMessageExtrasButtons 
						componentRef={componentref} 
						disabled={props.disabled || uploadingFile}
						toggleDisabled={() => setUploadingFile(!uploadingFile)}
						uploadedImageCount={images.length} 
						uploadFileKey={0} 
						onAddMedia={(media) => handleFileUpload(media)}
						onAddToMessage={(token) => setMessage(StringHelper.insert(message, token, smsSelectionIndex))}
					/>
				</div>
			{hasShortLinks && <NarniaMagicSnackBar open={showNarniaMagic} onClose={() => setShowNarniaMagic(false)} />}
		</>
	);
}