import { FormControl, Grid, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import SendIcon from "@material-ui/icons/Send";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { useAutoShortLinkState } from "../../../hooks/useAutoShortLinkState";
import { ResponseDialog } from "../../../components/ResponseDialog/ResponseDialog";
import { ButtonLink } from "../../Groups/ButtonLink";
import Attendee from "../../../entities/Attendee";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { MessageService, SendDirectMessageRequest } from "../../../services/MessageService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import AddPeopleView from "../../../layout/CreateNewDialog/AddPeopleView";
import { useHistory } from "react-router";
import { routes } from "../../../routes";


interface SendDirectMessageDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedAttendee?: Attendee
}

export function SendDirectMessageDialog(props:SendDirectMessageDialogProps){
	const theme = useTheme();
	const classes = useStyles();
    const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
    const attendeeContext = useAttendees();
    const { attendees } = attendeeContext;
    const history = useHistory();

    const filter = createFilterOptions<Attendee>({
        matchFrom: "any",
		stringify: (option: Attendee) => `${option.name}${option.phoneNumber}`,
	});
	
    const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(props.selectedAttendee ?? null)
    const [AttendeeOptions] = useState<Attendee[]>(attendees);
	
    const [disabled, setDisabled] = useState(false);
    const [showAttendeeForm, setShowAttendeeForm] = useState(false);
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	
    const [smsSelectionIndex, setSmsSelectionIndex] = useState(0);
	const [smsBoxFocused, setSmsBoxFocused] = useState(false);
    
	const autoLinkContext = useAutoShortLinkState("", smsBoxFocused ? smsSelectionIndex : null);
	const { hasShortLinks, processingLinks} = autoLinkContext;
	const [message, setMessage] = autoLinkContext.state;
	const [submitionRequest, setSubmissionRequest] = useState<SendDirectMessageRequest>();
    
    useEffect(() => {
		if (processingLinks || !submitionRequest) {
			return;
		}

		async function submitRequest(request: SendDirectMessageRequest) {
			setFieldErrors([]);
			const result = await MessageService.sendDirectMessage(request);
			setDisabled(false);

			if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

			if (ServerResult.isError(result)) return setErrorAlert(result);

			setSuccessAlert("Message Sent");
			history.push(routes.app.resolve.inboxDetailPage(request.attendeeId));
		}

		submitRequest({ ...submitionRequest, message });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processingLinks, submitionRequest]);

    const onSubmit = async () => {
        if(selectedAttendee && message.length !== 0){
            setDisabled(true);
            setSubmissionRequest({attendeeId: selectedAttendee.id, message: message, media:[]})
        }
	};

    return (
        <ResponseDialog onClose={() => !disabled && props.onClose()} open={props.isOpen} title={"Send a message"}>
            {showAttendeeForm 
            ? <AddPeopleView onBack={() => setShowAttendeeForm(false)} onClose={props.onClose} />
            :<>
                <ResponseDialogContent>
                    <VerticalCenter>
                        <Grid container justify="center">
                            <Grid item sm={9} xs={12}>
                                <Typography align="center" variant="h2" style={{ marginBottom: 24 }}>
                                    Send a message or <ButtonLink onClick={() => setShowAttendeeForm(true)}>invite someone new</ButtonLink>.
                                </Typography>
                                <Autocomplete
                                    disabled={disabled}
                                    value={selectedAttendee}
                                    options={AttendeeOptions}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    onChange={(_, value) => setSelectedAttendee(value)}
                                    autoHighlight
                                    clearOnBlur
                                    clearOnEscape
                                    handleHomeEndKeys
                                    filterSelectedOptions
                                    fullWidth
                                    style={{minHeight: 60}}
                                    noOptionsText="No more people to add"
                                    filterOptions={(options, params) => {
                                        return filter(options, params);
                                    }}
                                    getOptionLabel={(option: Attendee) => {
                                        return option.name;
                                    }}
                                    renderOption={(option: Attendee) => {
                                        return option.name;
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={ "Choose someone"} />}
                                />
                                <FormControl fullWidth>
                                    <Typography className={classes.progressLabelLighterText} variant="subtitle2">
                                        SMS text message:
                                    </Typography>
                                    <TextField
                                        placeholder="Type your SMS message..."
                                        id="outlined-bare"
                                        value={message}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        multiline={true}
                                        rowsMax={4}
                                        rows={3}
                                        onChange={(e) => {
                                            setMessage(e.currentTarget.value);
                                            setSmsSelectionIndex(e.currentTarget.selectionEnd ?? 0);
                                        }}
                                        onFocus={() => setSmsBoxFocused(true)}
                                        onBlur={() => setSmsBoxFocused(false)}
                                        error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Message")}
                                        helperText={FieldValidationErrorUtils.getFieldError(fieldErrors, "Message")}
                                    />
                                </FormControl>
                                <div style={{ height: 44, width: "100%" }}>
                                    {hasShortLinks && (
                                        <div
                                            style={{
                                                backgroundColor: "#7ac142",
                                                border: `1px solid ${theme.palette.success.dark}`,
                                                borderRadius: 12,
                                                padding: 7,
                                            }}
                                        >
                                            <Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap">
                                                <Grid item>
                                                    <img src="/images/wand.png" alt="Wand" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" style={{ color: "#fff", fontSize: 15 }}>
                                                        Poof! Your link was magically shortened (we use Narnia-approved magic)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </VerticalCenter>
                </ResponseDialogContent>
                <ResponseDialogActions
                    hideSecondaryButton={true}
                    disabled={disabled}
                    hideNextStep={false}
                    onConfirm={onSubmit}
                    nextButtonText={"Send"}
                    nextButtonIcon={<SendIcon />}
                    onBack={props.onClose}
                    backButtonText={"Cancel"}
                />
            </>
            }
        </ResponseDialog>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		padding: 8,
		height: 100,
		"& > textarea": {
			padding: 10,
			height: "100%",
			width: "100%",
		},
		width: "100%",
	},
	progressLabelLighterText: {
		color: "#404448",
		fontSize: 15,
	},
	editBox: {
		width: "100%",
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
	},
}));