import { Fade, Theme, Tooltip, withStyles } from "@material-ui/core";
import React from "react";

interface ResponseTooltipProps {
	text: string;
	childComp: JSX.Element;
	placement:
		| "top-start"
		| "top"
		| "top-end"
		| "left-start"
		| "left"
		| "left-end"
		| "right-start"
		| "right"
		| "right-end"
		| "bottom-start"
		| "bottom"
		| "bottom-end";
}

const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.secondaryResponse.main,
		boxShadow: theme.shadows[1],
		fontSize: 14,
	},
	arrow: {
		color: theme.palette.secondaryResponse.main,
	},
}))(Tooltip);

export function ResponseTooltip(props: ResponseTooltipProps) {
	const { text, childComp, placement } = props;

	return (
		<LightTooltip
			TransitionComponent={Fade}
			TransitionProps={{ timeout: 600 }}
			title={text}
			arrow
			placement={placement}
			interactive
		>
			{childComp}
		</LightTooltip>
	);
}
