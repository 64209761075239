import { Chart, LineSeries } from "@devexpress/dx-react-chart-material-ui";
import { useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SubtractDaysReturnDate } from "../../../functions/datetime";
import { useAttendees } from "../../../providers/AttendeeProvider";


interface ChartEntry {
	chartDate: Date;
	count: number;
}

export function PeopleGraph() {
	const { attendees } = useAttendees();
	const theme = useTheme();
	const [attendeeData, setAttendeeData] = useState<ChartEntry[]>([]);

	useEffect(() => {
		function buildChartData() {
            let chartData:ChartEntry[] = []

            for(let daysAgo = 0; daysAgo < 30; daysAgo++){
                const selectedDay = SubtractDaysReturnDate(new Date(), daysAgo);
                const dailyCount = attendees.filter((a) => a.dateCreated.getDate() === selectedDay.getDate()).length;
                chartData.push({chartDate: selectedDay, count: dailyCount})
            }
            setAttendeeData(chartData);
		}

		buildChartData();
	}, [attendees]);

	return (
        <Chart data={attendeeData} height={50} width={50}>
            <LineSeries
                valueField="count"
                argumentField="chartDate"
                name="attendees"
                color={theme.palette.primaryResponse.main}
            />
        </Chart>
	);
}