import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import React, { useState } from "react";
import useGoogleMaps from "../hooks/useGoogleMaps";

export interface GoogleMapsAddress {
	address: string;
	street: string;
	city: string;
	state: string;
	zip: string;
}

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	textField: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		width: "25ch",
	},
	iosFriendly: {
		fontSize: 16,
	},
}));

interface Option extends google.maps.places.AutocompletePrediction {}

interface Props extends OutlinedTextFieldProps {
	value?: string;
	onAddressChange: (address: GoogleMapsAddress) => void;
}

export function AddressAutoComplete(props: Props) {
	const { onAddressChange, value, ...textFieldProps } = props;
	const classes = useStyles();
	const [places, setQuery, geoCodeService] = useGoogleMaps();

	const [address, setAddress] = useState<string | undefined>(value ? value : undefined);
	const [showAutoComplete, setShowAutoComplete] = useState(value == null || value === "");
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	const onAddressSelected = (geocodedAddresses: google.maps.GeocoderResult[]) => {
		setDisableAutoComplete(false);
		if (geocodedAddresses.length === 0) {
			return;
		}
		const geocodedAddress = geocodedAddresses[0];
		const parts = geocodedAddress.address_components;
		const streetNumber = parts.find((p) => p.types.some((type) => type === "street_number"))?.long_name ?? "";
		const route = parts.find((p) => p.types.some((type) => type === "route"))?.short_name ?? "";
		const street = `${streetNumber} ${route}`;
		const city = parts.find((p) => p.types.some((type) => type === "locality"))?.short_name ?? "";
		const state = parts.find((p) => p.types.some((type) => type === "administrative_area_level_1"))?.short_name ?? "";
		const postalCode = parts.find((p) => p.types.some((type) => type === "postal_code"))?.long_name ?? "";
		const address: GoogleMapsAddress = {
			street,
			city,
			state,
			zip: postalCode,
			address: `${street}, ${city} ${state}`,
		};
		setAddress(address.address);
		setShowAutoComplete(false);
		onAddressChange(address);
	};

	if (!showAutoComplete) {
		return (
			<TextField
				{...textFieldProps}
				disabled
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={() => setShowAutoComplete(true)}>
								<EditIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				value={address}
			/>
		);
	}

	return (
		<Autocomplete
			getOptionLabel={(option) => option.description}
			filterOptions={(x) => x}
			options={places}
			value={null}
			disabled={disableAutoComplete}
			onClose={() => setShowAutoComplete(address == null)}
			onChange={(_, newValue) => {
				if (geoCodeService && newValue) {
					setDisableAutoComplete(true);
					geoCodeService.geocode({ placeId: newValue.place_id }, onAddressSelected);
				}
			}}
			onInputChange={(_, newInputValue) => {
				setQuery(newInputValue);
			}}
			renderInput={(params) => (
				<TextField
					{...textFieldProps}
					{...params}
					InputProps={{
						...params.InputProps,
						classes: {
							input: classes.iosFriendly,
						},
					}}
				/>
			)}
			renderOption={(option: Option) => {
				const matches = option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
}