import { useTheme, useMediaQuery, Grid, Button } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { GridGrow } from "../../GridGrow";
import { useCommonLayoutStyle } from "./ResponseDialogLayout";

interface ResponseDialogActionsProps {
	onConfirm: () => void;
	onBack: () => void;
	onSecondary?: () => void;
	disabled?: boolean;
	backButtonText?: string;
	nextButtonText?: string;
	secondaryButtonText?: string;
	nextButtonIcon?: React.ReactNode;
	secondaryButtonIcon?: React.ReactNode;
	hideSecondaryButton: boolean;
	hideNextStep?: boolean;
}

export function ResponseDialogActions(props: ResponseDialogActionsProps) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return (
			<MobileContainer>
				<ButtonActions {...props} />
			</MobileContainer>
		);
	}

    return (
        <DesktopContainer>
            <ButtonActions {...props} />
        </DesktopContainer>
    );
}

function ButtonActions(props: ResponseDialogActionsProps) {
	const { disabled, backButtonText, nextButtonText, hideNextStep, hideSecondaryButton, secondaryButtonText } = props;
	const classes = useCommonLayoutStyle();
	return (
		<>
			<Button
				disabled={disabled}
				variant="contained"
				color="primary"
				size="large"
				className={classNames(classes.actionButtons, classes.backButton)}
				onClick={props.onBack}
			>
				{backButtonText ?? "Back"}
			</Button>
			<GridGrow/>
			{!hideSecondaryButton && (
				<Button
					disabled={disabled}
					variant="outlined"
					color="primary"
					size="large"
					endIcon={props.secondaryButtonIcon}
					className={classNames(classes.actionButtons)}
					onClick={props.onSecondary}
				>
					{secondaryButtonText}
				</Button>
			)}
			{!hideNextStep && (
				<Button
					disabled={disabled}
					variant="contained"
					color="primary"
					size="large"
					endIcon={props.nextButtonIcon}
					className={classNames(classes.actionButtons, classes.nextButton)}
					onClick={props.onConfirm}
				>
					{nextButtonText ?? "Next"}
				</Button>
			)}
		</>
	);
}

function MobileContainer(props: React.PropsWithChildren<{}>) {
	return (
		<Grid style={{ display: "flex", position: "absolute", bottom: 0, right: 0, width: "100%" }} item xs={12}>
			{props.children}
		</Grid>
	);
}

function DesktopContainer(props: React.PropsWithChildren<{}>) {
	return (
		<Grid item xs={12} style={{ display: "inline-flex" }}>
			{props.children}
		</Grid>
	);
}
