import { MenuItem, Select, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { SignupItem, SignUpListAnswer } from "../../../entities/Forms/Answer";
import { QuestionType, SignupListQuestion } from "../../../entities/Forms/Question";
import { FormError } from "../../../pages/Forms/FormResponder";
import { useAnswerCommonstyles } from "../AnswerInput";

interface SignupListAnswerInputProps {
	question: SignupListQuestion;
	answer?: SignUpListAnswer;
	onAnswerChanged: (answer: SignUpListAnswer) => void;
	onAnswerDeleted: (question: SignupListQuestion) => void;
	attendeeId: string;
	error: FormError[];
}

interface SignUpItemUserChoice {
	text: string;
	userQuantity?: number;
	maxQuantity: number;
}

export function SignupListAnswerInput(props: SignupListAnswerInputProps) {
	const { question, answer, attendeeId, error } = props;
	const classes = useAnswerCommonstyles();
	const [selection, setSelection] = useState<SignUpItemUserChoice[]>([]);

	useEffect(() => {
		function setUpList() {
			const answers = answer;
			let selections: SignUpItemUserChoice[] = [];

			question.choices.forEach((item) => {
				if (!answers) {
					selections.push({ text: item.text, userQuantity: undefined, maxQuantity: item.quantity });
				} else {
					let answerIndex = answers.signupItems.findIndex((a) => a.text === item.text);

					if (answerIndex > -1) {
						let foundItem = answers.signupItems[answerIndex];
						selections.push({ text: foundItem.text, userQuantity: foundItem.quantity, maxQuantity: item.quantity });
					} else {
						selections.push({ text: item.text, userQuantity: undefined, maxQuantity: item.quantity });
					}
				}
			});
			setSelection(selections);
		}

		setUpList();
	}, [answer, question]);

	const onChange = (e: React.ChangeEvent<{ value: unknown }>, choiceText: string) => {
		const signUpItem = question.choices.find((a) => a.text === choiceText);

		if (!signUpItem) {
			return;
		}

		let selectedQuantity = isNaN(Number(e.target.value)) ? undefined : Number(e.target.value);

		if (!answer) {
			if (selectedQuantity) {
				props.onAnswerChanged({
					id: uuid(),
					questionId: question.id,
					questionType: QuestionType.SignupList,
					signupItems: [{ text: choiceText, quantity: selectedQuantity }],
					attendeeId: attendeeId,
				});
			}
		} else {
			let answeredItemIndex = answer.signupItems.findIndex((a) => a.text === signUpItem.text);

			if (!selectedQuantity) {
				let choicesSelected = answer.signupItems.filter((a) => a.text !== choiceText);

				if (choicesSelected.length === 0) {
					props.onAnswerDeleted(question);
					return;
				}
			}

			if (answeredItemIndex < 0) {
				props.onAnswerChanged({
					id: answer ? answer.id : uuid(),
					questionId: question.id,
					questionType: QuestionType.SignupList,
					signupItems: selectedQuantity
						? [...answer.signupItems, { text: choiceText, quantity: selectedQuantity }]
						: answer.signupItems.filter((a) => a.text !== choiceText),
					attendeeId: attendeeId,
				});
			} else {
				let updatedItems: SignupItem[] = selectedQuantity
					? answer.signupItems.map((item) =>
							item.text === choiceText ? { ...item, quantity: selectedQuantity as number } : item
					  )
					: answer.signupItems.filter((a) => a.text !== choiceText);

				props.onAnswerChanged({
					id: answer ? answer.id : uuid(),
					questionId: question.id,
					questionType: QuestionType.SignupList,
					signupItems: updatedItems,
					attendeeId: attendeeId,
				});
			}
		}
	};

	return (
		<div className={classes.answerContainer}>
			<div>
				<div style={{ display: "flex", width: "100%" }}>
					<span style={{ fontWeight: "bold", fontSize: 16 }}>
						<span className={classes.numberQuestion}>{question.index + 1}.</span>
						<span className={classes.prompt}>{question.prompt}</span>
					</span>
					{question.required && (
						<Typography className={classes.required} variant="subtitle1">
							*Required
						</Typography>
					)}
					<div className={classes.grow} />
					{answer && (
						<Typography
							onClick={() => props.onAnswerDeleted(question)}
							className={classes.resetHeader}
							variant="subtitle1"
						>
							(Reset)
						</Typography>
					)}
				</div>
			</div>
			<Typography className={classes.infoHeader} style={{ marginBottom: 6 }} variant="subtitle1">
				Select any still available
			</Typography>
			{error.length > 0 && (
				<Typography className={classes.errorMessage} variant="subtitle1">
					{error.map((a) => `${a.error} `.trim())}
				</Typography>
			)}
			<Table>
				<TableBody>
					{selection.map((choice, i) => (
						<TableRow key={i}>
							<TableCell>
								<Typography style={{ marginRight: 6 }}>{choice.text}</Typography>
							</TableCell>
							<TableCell align="right">
								{choice.userQuantity || choice.maxQuantity > 0 ? (
									<Select
										className={classes.quantityDropDown}
										value={choice.userQuantity ? choice.userQuantity : "-"}
										onChange={(e) => onChange(e, choice.text)}
										IconComponent={KeyboardArrowDown}
									>
										<MenuItem key={-1} value={"-"}>
											{"-"}
										</MenuItem>
										{new Array(choice.maxQuantity + 1).fill(null).map((_, i) =>
											i > 0 ? (
												<MenuItem key={i} value={i}>
													{i}
												</MenuItem>
											) : undefined
										)}
									</Select>
								) : (
									<Typography>Nothing Available</Typography>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
