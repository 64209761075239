export default interface Reminder {
	id: string;
	journeyId: string;
	message: string;
	messageType: MessageType;
	timeBeforeEvent: DurationNodaTime;
	timeSent?: Date;
	scheduledTime: Date;
}

export enum MessageType {
	Scheduled = 0,
	Recurring,
}

export interface DurationNodaTime {
	minutes: number;
	hours: number;
	days: number;
}

export function getDurationNodaTime(duration: string) {
	let splits = duration.split(":").reverse();

	let dur: DurationNodaTime = {
		minutes: +splits[1],
		hours: +splits[2] % 24,
		days: Math.trunc(+splits[2] / 24),
	};

	return dur;
}

export function createDurationFromDurationNodaTime(durationNodaTime: DurationNodaTime) {
	return `${durationNodaTime.days < 10 && "0"}${durationNodaTime.days}:${durationNodaTime.hours < 10 && "0"}${
		durationNodaTime.hours
	}:${durationNodaTime.minutes < 10 && "0"}${durationNodaTime.minutes}:00`;
}
