import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { matchPath, Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { Group } from "../../entities/Groups/Group";
import { LoadingPage } from "../../components/LoadingPage";
import { routes } from "../../routes";
import { GroupListPage } from "./GroupListPage";
import { GroupDetailPage } from "./GroupDetailPage";
import { useGroups } from "../../providers/GroupProvider";
import { useAppData } from "../../providers/AppDataProvider";
import { ServerErrorPage } from "../../components/ServerErrorPage";
import { Keyword } from "../../entities/Keywords/Keyword";

export function GroupPage() {
	const params = useParams<{ groupId?: string }>();
	const appData = useAppData();
	const { groupsLoading, groupServerError, attendeesLoading, attendeeServerError} = appData;

	if (groupsLoading || attendeesLoading) {
		return <LoadingPage />;
	}

	if(groupServerError)
		return <ServerErrorPage serverError={groupServerError}/>

	if(attendeeServerError)
		return <ServerErrorPage serverError={attendeeServerError}/>

	return (
		<GroupRouterProvider groupId={params.groupId}>
			<GroupPageRouter/>
		</GroupRouterProvider>
	);
}

interface GroupRouterContext {
    selectedGroup: Group | undefined;
	selectedGroupKeyword: Keyword | undefined;
	createGroup: (group: Group) => void;
	deleteGroup: (group: Group) => void;
	selectGroup: (group: Group) => void;
	setGroupKeyword: (keyword: Keyword | undefined) => void;
}

const GroupRouterReactContext = createContext<GroupRouterContext>({} as any);

export function useGroupRouter() {
	return useContext(GroupRouterReactContext);
}

function GroupRouterProvider(props: PropsWithChildren<{ groupId: string | undefined }>) {
	const history = useHistory();
	const { groupId } = props;
	const groupContext = useGroups();
	const { groups } = groupContext;

	const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(groups.find((g) => g.id === groupId));
	const [selectedGroupKeyword, setSelectedGroupKeyword] = useState<Keyword>();

	useEffect(() => setSelectedGroupKeyword(undefined), [selectedGroup, groupId]);

	useEffect(() => {
		setSelectedGroup(groups.find((g) => g.id === groupId));
	}, [groups, groupId]);


	const onSelect = (group: Group) => {
		setSelectedGroup(group);
		history.push(routes.app.resolve.groupDetailPage(group.id));
	};

	const onCreate = (group: Group) => {
		groupContext.create(group);
		setSelectedGroup(group);
		history.push(routes.app.resolve.groupDetailPage(group.id));
	};

	const onDelete = (group: Group) => {
		groupContext.delete(group);
		setSelectedGroup(undefined);
		history.push(routes.app.resolve.groupListPage());
	};

	return (
		<GroupRouterReactContext.Provider value={{ 
			selectedGroup,
			selectedGroupKeyword,
			createGroup: onCreate,
			selectGroup: onSelect,
			deleteGroup: onDelete,
			setGroupKeyword: setSelectedGroupKeyword}}>
			{props.children}
		</GroupRouterReactContext.Provider>
	);
}

function GroupPageRouter() {
	const groupRouter = useGroupRouter();
	const location = useLocation();
	const history = useHistory();

	const isCreateGroupRoute = matchPath(location.pathname, {path: routes.app.createGroupPage, exact: true, strict: true}) != null;

	useEffect(() => {
		if(isCreateGroupRoute){
			history.replace(routes.app.groupListPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Switch>
			<Route exact path={[routes.app.groupListPage, routes.app.createGroupPage]}>
				<GroupListPage createGroup={isCreateGroupRoute}/>
			</Route>
			{groupRouter.selectedGroup && (
				<Route exact path={routes.app.groupDetailPage}>
					<GroupDetailPage selectedGroup={groupRouter.selectedGroup} />
				</Route>
			)}
			<Redirect to={routes.app.groupListPage} />
		</Switch>
	);
}
