import Auth from "../authorization/Auth";
import { ServerResult } from "./server/ServerResult";
import { ServerSuccess } from "./server/ServerSuccess";
import { serializeAxiosError, WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";
import axios, { AxiosError } from "axios";

export interface SignInRequest {
	email: string;
	password: string;
}

interface SignInResponse {
	jwtToken: string;
}

interface resetNewUserPasswordResponse {
	jwtToken: string;
}

interface UpdateUserResponse {
	jwtToken: string;
}

export interface UpdateUserRequest {
	firstName: string;
	lastName: string;
	email: string;
}

export const AuthService = {
	async signin(request: SignInRequest) {
		const result = await WebClient.Post.Validated<SignInResponse>(`${apiRoot}/auth/signin`, request);
		if (ServerResult.isSuccess(result)) {
			Auth.setToken(result.data.jwtToken);
			const success: ServerSuccess<true> = {
				data: true,
			};
			return success;
		}
		return result;
	},
	async signOut() {
		const result = await WebClient.Get(`${apiRoot}/auth/sign-out`);
		if (ServerResult.isSuccess(result)) {
			Auth.clearToken();
		}
		return result;
	},
	async refreshUser() {
		try {
			const response = await axios.get(`${apiRoot}/auth/refresh`, {
				withCredentials: true,
				headers: {
					Pragma: "no-cache",
					ResponseClient: window.location.origin,
				},
			});
			const jwt = response.data.data.jwt as string;
			Auth.setToken(jwt);
			const success: ServerSuccess<{jwt: string}> = {
				data: { jwt }
			}
			return success;
		} catch (error) {
			Auth.redirectToSignInPage();
			return serializeAxiosError(error as AxiosError);
		}
	},
	async forgotPassword(email: string) {
		const result = await WebClient.Post.Unvalidated<string>(`${apiRoot}/auth/forgotpassword`, { email });

		return result;
	},
	async resetNewUserPassword(password: string, token: string) {
		return await WebClient.Post.Validated<SignInResponse>(`${apiRoot}/auth/resetnewuserpassword`, { password, token });
	},
	async resetPassword(password: string, token: string) {
		const result = await WebClient.Post.Validated<string>(`${apiRoot}/auth/resetpassword`, { password, token });
		if (ServerResult.isSuccess(result)) {
		}
		return result;
	},
	async update(request: UpdateUserRequest) {
		const result = await WebClient.Put.Validated<resetNewUserPasswordResponse>(`${apiRoot}/auth/update`, request);
		if (ServerResult.isSuccess(result)) {
			Auth.setToken(result.data.jwtToken);
			return result;
		}
		return result;
	},
	async updatePassword(password: string, oldPassword: string) {
		const result = await WebClient.Put.Validated<UpdateUserResponse>(`${apiRoot}/auth/update-password`, {
			password,
			confirmPassword: password,
			oldPassword,
		});
		if (ServerResult.isSuccess(result)) {
			Auth.setToken(result.data.jwtToken);
			return result;
		}
		return result;
	},
	async updateChurch(churchId: string) {
		const result = await WebClient.Put.Unvalidated<UpdateUserResponse>(`${apiRoot}/auth/church`, { churchId });
		if (ServerResult.isSuccess(result)) {
			Auth.setToken(result.data.jwtToken);
			return result;
		}
		return result;
	},
	async getJwt() {
		const token = Auth.getSavedToken();
		const user = Auth.getJwtUser();

		if (!token || !user || user.expiration.getTime() < new Date().getTime()) {
			const response = await AuthService.refreshUser();
			if (ServerResult.isSuccess(response)) {
				Auth.setToken(response.data.jwt);
				return response.data.jwt;
			}
			throw new Error(response.message);
		}

		return token;
	},
	async changeInboxNotificationStatus(status: boolean){
		const result = await WebClient.Put.Unvalidated<UpdateUserResponse>(`${apiRoot}/auth/update-inbox-message-preference`, { receiveNotifications: status });
		if (ServerResult.isSuccess(result)) {
			Auth.setToken(result.data.jwtToken);
			return result;
		}
		return result;
	}
};

export default AuthService;


