import { useEffect } from "react";
import { usePageTitle } from "../hooks/usePageTitle";

export function PageTitle(props: {title: string}) {
    const title = props.title;
    const [, setPageTitle] = usePageTitle();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setPageTitle(title), [title]);

    return null;
}