import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { SingleAnswer } from "../../../entities/Forms/Answer";
import { QuestionType, SingleAnswerQuestion } from "../../../entities/Forms/Question";
import { FormError } from "../../../pages/Forms/FormResponder";
import { useAnswerCommonstyles } from "../AnswerInput";

interface SingleAnswerInputProps {
	question: SingleAnswerQuestion;
	answer?: SingleAnswer;
	onAnswerChanged: (answer: SingleAnswer) => void;
	onAnswerDeleted: (question: SingleAnswerQuestion) => void;
	attendeeId: string;
	error: FormError[];
}

export function SingleAnswerInput(props: SingleAnswerInputProps) {
	const { question, answer, attendeeId, error } = props;
	const classes = useAnswerCommonstyles();
	const [selection, setSelection] = useState(-1);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const index = Number(e.target.value);
		setSelection(index);
		props.onAnswerChanged({
			id: answer ? answer.id : uuid(),
			questionId: question.id,
			questionType: QuestionType.SingleAnswer,
			text: question.choices[index].text,
			attendeeId: attendeeId,
		});
	};

	return (
		<div className={classes.answerContainer}>
			<div>
				<div style={{ display: "flex", width: "100%" }}>
					<span style={{ fontWeight: "bold", fontSize: 16 }}>
						<span className={classes.numberQuestion}>{question.index + 1}.</span>
						<span className={classes.prompt}>{question.prompt}</span>
					</span>
					{question.required && (
						<Typography className={classes.required} variant="subtitle1">
							*Required
						</Typography>
					)}
					<div className={classes.grow} />
					{answer && (
						<Typography
							onClick={() => props.onAnswerDeleted(question)}
							className={classes.resetHeader}
							variant="subtitle1"
						>
							(Reset)
						</Typography>
					)}
				</div>

				<Typography className={classes.infoHeader} variant="subtitle1">
					Choose one
				</Typography>
			</div>
			{error.length > 0 && (
				<Typography className={classes.errorMessage} variant="subtitle1">
					{error.map((a) => `${a.error} `.trim())}
				</Typography>
			)}
			<FormControl component="fieldset">
				<RadioGroup aria-label="choice" value={selection} onChange={onChange}>
					{question.choices.map((choice, i) => (
						<FormControlLabel
							key={i}
							value={i}
							control={
								<Radio
									disableRipple
									classes={{ root: classes.radio, checked: classes.checked }}
									checked={answer != null && answer.text === choice.text}
								/>
							}
							label={choice.text}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</div>
	);
}
