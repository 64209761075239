import React, { useEffect, useState } from "react";
import {
	Grid,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { orderBy, SortDirection } from "../../../utillity/orderBy";
import { MessageToGroup } from "../../../entities/Groups/MessageToGroup";
import { MessageToGroupStatus } from "../../../entities/Groups/MessageToGroupStatus";
import { Enum } from "../../../utillity/Enum";
import { GridGrow } from "../../../components/GridGrow";
import MoreIcon from "@material-ui/icons/MoreVert"
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { DateFormat } from "../../../entities/DateFormat";

const useGroupListingStyle = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
}));

interface GroupAnnouncementTableProps {
	groupId: string;
	messages: MessageToGroup[];
}

const GroupAnnouncementTable = (props: GroupAnnouncementTableProps) => {
	const [sortField, setSortField] = useState<"announcement" | "sentAnnouncementCount" | "date/time" | "sentTime"| "announcementStatus">("date/time");
	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
	const classes = useGroupListingStyle();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedMessage, setSelectedMessage] = useState<MessageToGroup | null>(null);
	const [disabled, setDisabled] = useState(false);

	const [searchText, startSearchItems] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Descending");

	const [displayedMessages, setDisplayedMessages] = useState<MessageToGroup[]>([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	useEffect(() => {
		let sortedMessages = props.messages;

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			sortedMessages = sortedMessages.filter((listItem) =>
				(listItem.message).toLowerCase().includes(queryText)
			);
		}

		switch (sortField) {
			case "announcement":
				sortedMessages.sort(orderBy.string((i) => i.message, "Ascending"));
				break;
            case"sentAnnouncementCount":
                sortedMessages.sort(orderBy.number((i) => i.sentToCount, "Ascending"));
                break;
            case"announcementStatus":
                sortedMessages.sort(orderBy.number((i) => i.status, "Ascending"));
                break;
            case"date/time":
                sortedMessages.sort(orderBy.date((i) => i.dateCreated, "Ascending"));
                break;
			case"sentTime":
                sortedMessages.sort(orderBy.date((i) => i.timeSent ?? i.scheduledSendTime, "Ascending"));
                break;
            default:
				break;
		}

		if(sortDirection === "Descending")
			sortedMessages.reverse();

		setDisplayedMessages(sortedMessages);
		setPage(0);
	}, [searchText, sortDirection, props.messages, sortField]);

	const removeScheduledMessage = async(message:MessageToGroup) => {
		if(message.scheduledSendTime < new Date())
			return setErrorAlert({statusCode: 200, message: "Message cannot be cancelled "})
		setDisabled(true);
		const result = await GroupService.cancelScheduledMessageToGroup({broadcastMessageId: message.broadcastMessageId, groupId: props.groupId})
		setDisabled(false);

		if(ServerResult.isSuccess(result)){
			setSuccessAlert("Message canceled");
			setAnchorEl(null);
			return;
		} 
		
		if(ServerResult.isValidationError(result))
			return setErrorAlert({statusCode: 200, message: "Message cannot be cancelled "})
		
		setErrorAlert(result); 
	}

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid container>
					<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							{displayedMessages.length} Announcement{displayedMessages.length === 1 ? "" : "s"}
						</Typography>
					</Grid>
					<GridGrow />
					<Grid item>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchItems(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
						/>
					</Grid>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid container>
					<GridGrow />
					<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={displayedMessages.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
							onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
							labelRowsPerPage="Show"
						/>
				</Grid>
				<Grid item xs={12} style={{ marginTop: 8 }}>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell
										align="left"
										sortDirection={
											sortField === "announcement"
												? sortDirection === "Ascending"
													? "asc"
													: "desc"
												: false
										}
									>
										<TableSortLabel
											active={sortField === "announcement"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("announcement");
												setSortDirection(
													sortDirection === "Ascending" ? "Descending" : "Ascending"
												);
											}}
										>
											Announcement
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "announcementStatus"
												? sortDirection === "Ascending"
													? "asc"
													: "desc"
												: false
										}
									>
										<TableSortLabel
											active={sortField === "announcementStatus"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("announcementStatus");
												setSortDirection(
													sortDirection === "Ascending" ? "Descending" : "Ascending"
												);
											}}
										>
											Status
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "sentAnnouncementCount"
												? sortDirection === "Ascending"
													? "asc"
													: "desc"
												: false
										}
									>
										<TableSortLabel
											active={sortField === "sentAnnouncementCount"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("sentAnnouncementCount");
												setSortDirection(
													sortDirection === "Ascending" ? "Descending" : "Ascending"
												);
											}}
										>
											Sent To People
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "sentTime"
												? sortDirection === "Ascending"
													? "asc"
													: "desc"
												: false
										}
									>
										<TableSortLabel
											active={sortField === "sentTime"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("sentTime");
												setSortDirection(
													sortDirection === "Ascending" ? "Descending" : "Ascending"
												);
											}}
										>
											Time Sent
										</TableSortLabel>
									</TableCell>
									<TableCell padding="checkbox" size="small"/>
								</TableRow>
							</TableHead>
							<TableBody>
								{displayedMessages.length === 0 && (
									<TableRow>
										<TableCell>
											<Typography style={{ paddingTop: 24 }} variant="h4">
												No Announcements
											</Typography>
										</TableCell>
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell padding="checkbox" size="small"/>
									</TableRow>
								)}
								{(rowsPerPage > 0
									? displayedMessages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: displayedMessages
								).map((message: MessageToGroup, index: number) => {
									return (
										<TableRow
											key={index}
											hover
										>
											<TableCell>{message.message}</TableCell>
											<TableCell>{Enum.print(MessageToGroupStatus, message.status)}</TableCell>
											<TableCell>{message.sentToCount}</TableCell>
											<TableCell>
												{message.timeSent 
												? message.timeSent.toLocaleString([], DateFormat)
												: `Scheduled for ${message.scheduledSendTime.toLocaleString([], DateFormat)}`
												}
											</TableCell>
											{ message.timeSent
												?
												<TableCell size="small" padding="checkbox" />
												:
												<TableCell 
													padding="checkbox" 
													size="small" 
													onClick={(e) => {
													setAnchorEl(e.currentTarget);
													setSelectedMessage(message);
												}} >
												<IconButton>
													<MoreIcon />
												</IconButton>
											</TableCell>
											}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					{ anchorEl && selectedMessage &&
						<Menu
						id="menu-collaborator"
						anchorEl={anchorEl}
						autoFocus={false}
						anchorOrigin={{
							vertical: "center",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "center",
							horizontal: "right",
						}}
						open={selectedMessage !== null}
						onClose={() => {
							setAnchorEl(null)
							setSelectedMessage(null)
						}}>
						<MenuItem onClick={() => removeScheduledMessage(selectedMessage!)} disabled={disabled}>
							Delete Message
						</MenuItem>
					</Menu>
				}
				</Grid>
			</Grid>
		</div>
	);
};

export default GroupAnnouncementTable;