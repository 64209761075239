export interface ServerValidationError {
	errors: FieldValidationError[];
}

export interface FieldValidationError {
	field: string;
	errors: string[];
}

export const FieldValidationErrorUtils = {
	isFieldInError: (errors: FieldValidationError[], fieldName: string): boolean => {
		return errors.some((error) => error.field === fieldName);
	},

	getFieldError: (errors: FieldValidationError[], fieldName: string): string | undefined => {
		return errors.find(e=> e.field === fieldName)?.errors[0];
	},

	getFieldErrorSummary: (errors: FieldValidationError[], fieldName: string): string => {
		let retVal = "";
		errors
			.filter((error) => error.field === fieldName)
			.forEach((error) => {
				error.errors.forEach((str) => (retVal += str + " "));
			});
		return retVal.trimEnd();
	},

	hasGenericError: (errors: FieldValidationError[]): boolean => {
		return errors.map((error) => error.field).indexOf("") > -1;
	},

	getGenericErrorSummary: (errors: FieldValidationError[]): string => {
		let retVal = "";
		errors
			.filter((error) => error.field === "")
			.forEach((error) => {
				error.errors.forEach((str) => (retVal += str + " "));
			});
		return retVal;
	},
};
