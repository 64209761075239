import { TextMessageDirection } from "./TextMessageDirection";
import { TextMessageMedia } from "./TextMessageMedia";
import { TextMessageStatus } from "./TextMessageStatus";
import { TextMessageType } from "./TextMessageType";

export default interface TextMessageHistoryItem {
    id: string;
    journeyId: string | undefined;
    journeyName: string | undefined;
    groupId: string | undefined;
    groupName: string | undefined;
    sentByUserId: string | undefined;
    sentByUserName: string | undefined;
    status: TextMessageStatus;
    message: string;
    direction: TextMessageDirection;
    type: TextMessageType;
    dateCreated: Date;
    media: TextMessageMedia[];
}

export const printMessageType = (message: TextMessageHistoryItem) => {
    if(message.direction === TextMessageDirection.Receive){
        switch(message.type){
            case TextMessageType.DirectMessage:
                return "";
            case TextMessageType.GroupAnnouncement:
                return `Group Announcement`;
            case TextMessageType.Keyword:
                return `Keyword`;
            case TextMessageType.Unsubscribe:
            case TextMessageType.UnsubscribeAll:
               return "Unsubscribe"
            case TextMessageType.Help:
                return "Help"
            case TextMessageType.Introduction:
                return "Introduction"
            case TextMessageType.OptIn:
                return "Opt in"
            case TextMessageType.OptOut:
                return "Opt out"
            default:
                return ""
        }
    }

    switch(message.type){
        case TextMessageType.DirectMessage:
            return `Direct Message`;
        case TextMessageType.GroupAnnouncement:
            return `Group Announcement`;
        case TextMessageType.Keyword:
            return `Keyword`;
        case TextMessageType.Notification:
            return "Notification";
        case TextMessageType.AutomaticResponse:
            return "Automatic Response";
        case TextMessageType.Invite:
            return "Church Invite";
        case TextMessageType.Help:
            return "Help"
        case TextMessageType.Introduction:
            return "Introduction"
        case TextMessageType.OptIn:
            return "Opt in"
        case TextMessageType.OptOut:
            return "Opt out"
        default:
            return ""
    }
}