export const formatPhoneNumber = (phoneNumberString: string) => {
	let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		// executive order from jacob 9/27/21
		// let intlCode = match[1] ? "+1 " : "";
		return [ "(", match[2], ") ", match[3], "-", match[4]].join("");
	}
	return null;
};

export const formatShortenedPhoneNumber = (phoneNumberString: string) => {
	let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	let match = cleaned.match(/(\d{3})(\d{4})$/);
	if (match) {
		return [match[1], "-", match[2]].join("");
	}
	return null;
};
