export enum RuntimeEnvironment {
    LocalDev,
    Development,
    Production
}

export function resolveEnvironment(){
    if(window.location.host.includes("localhost")){
		return RuntimeEnvironment.LocalDev;
	}

	if(window.location.host.includes("app.joinresponse.dev")){
		return RuntimeEnvironment.Development
	}
	
	return RuntimeEnvironment.Production
}