import { Drawer, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Select, Theme, Typography, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";
import { Group } from "../../../entities/Groups/Group";
import { GroupMember, printGroupMemberStatus } from "../../../entities/Groups/GroupMember";
import { routes } from "../../../routes";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import PeopleIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Close";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useGroups } from "../../../providers/GroupProvider";

const useStyles = makeStyles((theme: Theme) => ({
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},	
    grow: {
		flex: 1,
	},
    cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
	filterSelector: {
		color: theme.palette.secondaryResponse.main,
		backgroundColor: "#fafafa",
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		}
	}
}));

interface MemberTableMobileProps {
    selectedGroup: Group
}

export function MemberTableMobile(props: MemberTableMobileProps) {
	const classes = useStyles();
	const setServerError = useServerErrorAlert();
	const groupContext = useGroups();

    const {selectedGroup} = props;
	const [displayMembers, setDisplayMembers] = useState(selectedGroup.groupMembers);
	const [filterByItem, setFilterByItem] = useState<GroupMembershipStatus>(GroupMembershipStatus.Accepted);
	const [selectedMember, setSelectedMember] = useState<GroupMember | null>(null);
	const [disabled, setDisabled] = useState(false);

	

	useEffect(() => {
		let sortedmembers = props.selectedGroup.groupMembers.filter(m => {
			if(filterByItem === GroupMembershipStatus.Declined){
				return m.groupMembershipStatus === filterByItem;
			} else {
				return m.groupMembershipStatus !== GroupMembershipStatus.Declined
			}
		})

		setDisplayMembers(sortedmembers);
	}, [props.selectedGroup, filterByItem]);

	const removeGroupMember = async(attendeeId:string) => {
		setDisabled(true)
		const result = await GroupService.removeAttendeesFromGroup({attendeeIds:[attendeeId], groupId: selectedGroup.id})
		setDisabled(false)

		if(ServerResult.isError(result))
			return setServerError(result)
		
		if(ServerResult.isSuccess(result)){
			groupContext.update(result.data);
			setSelectedMember(null);
		}
	}

	return (            
        <Grid container>
            <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                    <Typography style={{ fontWeight: "bold" }} variant="h3">
					{`${displayMembers.length} ${displayMembers.length === 1 ? "Person" : "People"}`}
                    </Typography>
                    <div className={classes.grow} /> 
					<Grid item style={{ alignSelf: "center"}}>
						<FormControl>
							<Select
								disableUnderline
								labelId="filter-journey-select-label"
								id="filter-journey-select"
								value={filterByItem}
								className={classes.filterSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setFilterByItem(parseInt(event.target.value as string))
								}
							>
							<MenuItem value={GroupMembershipStatus.Accepted}>
								Accepted
							</MenuItem>
							<MenuItem value={GroupMembershipStatus.Declined}>
								Declined
							</MenuItem>
							</Select>
						</FormControl>
					</Grid>
                </div>
                <hr className={classes.largeBlueDivider} />
            </Grid>
            <Grid container>

            {displayMembers.map((member: GroupMember, index: number) => {
					return (
						<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4, margin: 4 }} key={index}>
							<div
								className={classes.cardGlowEffect}
								style={{
									backgroundColor: "#FFF",
									borderRadius: 8,
									width: "100%",
									height: "fit-content",
									display: "flex",
									padding: 12,
								}}
								onClick={() => setSelectedMember(member)}
							>
								<div>
									<Typography variant="h4">{`${member.firstName} ${member.lastName}`}</Typography>
									<Typography variant="h4" style={{ fontWeight: 400, fontSize: 15, display: "inline-flex" }}>
										Status
										:&nbsp;
										<Typography
											variant="body2"
											style={{
												maxWidth: 80,
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{printGroupMemberStatus(member)}									
										</Typography>
									</Typography>
									</div>
								<div className={classes.grow} />
							</div>
						</Grid>
					);
				})}
            </Grid>
			<Drawer open={selectedMember !== null} anchor="bottom" onClose={() => setSelectedMember(null)} disableBackdropClick={disabled}>
				{ selectedMember &&
					<List>
						<ListItem onClick={() => window.location.href = routes.app.resolve.peopleDetailPage(selectedMember.attendeeId)} disabled={disabled}>
							<ListItemIcon>
								<PeopleIcon color="secondary"/>
							</ListItemIcon>
							<ListItemText primary={`Manage contact info`} />
						</ListItem>
						<Divider component="li" />
						<ListItem onClick={() => window.location.href = `tel:${selectedMember.phoneNumber}`} disabled={disabled}>
							<ListItemIcon>
								<PhoneIcon color="secondary"/>
							</ListItemIcon>
							<ListItemText primary={`Call ${selectedMember.firstName}`} />
						</ListItem>
						<Divider component="li" />
						<ListItem button onClick={() => window.location.href = `sms:${selectedMember.phoneNumber}`} disabled={disabled}>
							<ListItemIcon>
								<SmsIcon color="secondary"/>
							</ListItemIcon>
							<ListItemText primary={`Text ${selectedMember.firstName}`} />
						</ListItem>
						{ selectedMember.groupMembershipStatus !== GroupMembershipStatus.Declined && 
							<>
								<Divider component="li" />
								<ListItem button onClick={() => removeGroupMember(selectedMember.attendeeId)} disabled={disabled}>
									<ListItemIcon>
										<DeleteIcon color="secondary"/>
									</ListItemIcon>
									<ListItemText primary={`Remove from this group`} />
								</ListItem>
							</>
						}
					</List>
				}
			</Drawer>
        </Grid>
	);
}