import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { StepProps } from "./Step";

const useStyles = makeStyles((theme: Theme) => ({
	progressConnector: {
		backgroundColor: theme.palette.secondary.main,
		height: 3,
		marginRight: 6,
		marginTop: 9,
		flex: "1 1 auto",
	},
}));

export function MultiStepBar(props: React.PropsWithChildren<{}>) {
	const classes = useStyles();
	const children = React.Children.toArray(props.children);
	const childrenProps = children.map((c) => (c as any)?.props as StepProps);

	if (children.length === 0) return null;
	return (
		<Grid container wrap="nowrap">
			{children.map((child, i) => (
				<React.Fragment key={i}>
					<Grid item>{child}</Grid>
					{i < children.length - 1 && (
						<div
							className={classes.progressConnector}
							style={{
								opacity:
									i + 1 < children.length && !childrenProps[i + 1].active && !childrenProps[i + 1].complete
                                    ? 0.5
                                    : undefined,
							}}
						/>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
}
