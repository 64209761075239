import { Keyword } from "../entities/Keywords/Keyword";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface KeywordValidationResponse {
	isValid: boolean;
	message: string;
}

export interface CreateAddToGroupKeywordRrequest {
	groupId: string;
	keyword: string;
	completionResponse: string;
}

export const KeywordService = {
    validateKeyword: (request: { journeyId: string; keyword: string }) => WebClient.Post.Unvalidated<KeywordValidationResponse>(`${apiRoot}/keywords/validate`, request),
	getJourneyKeyword: (journeyId:string) => WebClient.Get<Keyword>(`${apiRoot}/keywords/journey-keyword/${journeyId}`),
	getGroupKeyword: (groupId:string) => WebClient.Get<Keyword>(`${apiRoot}/keywords/group-keyword/${groupId}`),
	createGroupKeyword: (request: CreateAddToGroupKeywordRrequest) => WebClient.Post.Validated<Keyword>(`${apiRoot}/keywords/create-group-keyword`, request),
	update: (request: { id:string, keyword: string, completionResponse: string }) => WebClient.Put.Validated<Keyword>(`${apiRoot}/keywords/update-keyword`, request),
	delete: (keywordId:string) => WebClient.Delete(`${apiRoot}/keywords/${keywordId}`)
};
