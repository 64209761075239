import React, { useState } from "react";
import { LoadingPage } from "../../components/LoadingPage";
import { Group } from "../../entities/Groups/Group";
import { SelectGroupsForCsvStep } from "./UploadCsvSteps/SelectGroupsForCsvStep";
import { ImportPeopleCsvModel, UploadCsvFileStep } from "./UploadCsvSteps/UploadCsvFileStep";
import { ImportStep } from "./UploadCsvSteps/ImportStep";
import { CsvUploadCompleteStep } from "./UploadCsvSteps/CsvUploadCompleteStep";
import { Grid } from "@material-ui/core";
import { MultiStepBar } from "../../components/MultiStepBar";
import { Step } from "../../components/Step";
import { useAttendees } from "../../providers/AttendeeProvider";

enum UploadCsvStep {
	UploadCsvFile = 1,
	SelectGroup = 2,
	Import = 3,
	Complete = 4,
}

export function UploadCsvPage() {
	const attendeeContext = useAttendees();
	const { attendees } = attendeeContext;

	const [step, setStep] = useState(UploadCsvStep.UploadCsvFile);
	const [csvRows, setCsvRows] = useState<ImportPeopleCsvModel[]>([]);
	const [groups, setGroups] = useState<Group[]>([]);

	const onCsvUploaded = (rows: ImportPeopleCsvModel[]) => {
		setCsvRows(rows);
		setStep(UploadCsvStep.SelectGroup);
	};

	const onBackToCsvUpload = () => {
		setCsvRows([]);
		setStep(UploadCsvStep.UploadCsvFile);
	};

	const onGroupsSelected = (groups: Group[]) => {
		setGroups(groups);
		setStep(UploadCsvStep.Import);
	};

	const onRestart = () => {
		setCsvRows([]);
		setGroups([]);
		setStep(UploadCsvStep.UploadCsvFile);
	};

	const renderStep = () => {
		switch (step) {
			case UploadCsvStep.UploadCsvFile:
				return <UploadCsvFileStep onCsvUploaded={onCsvUploaded} allAttendees={attendees} />;
			case UploadCsvStep.SelectGroup:
				return <SelectGroupsForCsvStep rows={csvRows} onSelectedGroups={onGroupsSelected} onBack={onBackToCsvUpload} />;
			case UploadCsvStep.Import:
				return <ImportStep rows={csvRows} groups={groups} onComplete={() => setStep(UploadCsvStep.Complete)} />;
			case UploadCsvStep.Complete:
				return <CsvUploadCompleteStep onRestart={onRestart} />;
			default:
				return null;
		}
	};

	if (!attendees) {
		return <LoadingPage />;
	}

	return (
		<Grid container style={{ minHeight: "inherit" }}>
			<Grid item xs={12}>
				<Grid container justify="center">
					<Grid item lg={6} sm={8} xs={12}>
						<MultiStepBar>
							<Step complete={step > 1} active={step === UploadCsvStep.UploadCsvFile}>Upload CSV</Step>
							<Step complete={step > 2} active={step === UploadCsvStep.SelectGroup}>Add To Group</Step>
							<Step complete={step > 3} active={step === UploadCsvStep.Import}>Import</Step>
						</MultiStepBar>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ height: "100%" }}>
				{renderStep()}
			</Grid>
		</Grid>
	);
}
