import {
	Breadcrumbs,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import Attendee, { AdminInviteStatus } from "../../entities/Attendee";
import { JourneyType } from "../../entities/Journey";
import { Role } from "../../entities/Role";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { routes } from "../../routes";
import AttendeeService, { SubscribedJourney } from "../../services/AttendeeService";
import { ServerResult } from "../../services/server/ServerResult";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { usePeopleRouter } from "./PeoplePage";
import { useAttendees } from "../../providers/AttendeeProvider";
import { PageTitle } from "../../components/PageTitle";
import { useGroups } from "../../providers/GroupProvider";
import { Group } from "../../entities/Groups/Group";
import { GroupService } from "../../services/GroupService";
import { SendDirectMessageDialog } from "../Messages/Components/SendDirectMessageDialog";
import { useHistory } from "react-router-dom";
import { ChurchOptInStatus } from "../../entities/ChurchOptInStatus";
import { AttendeeInfoCard } from "./Components/AttendeeInfoCard";
import { GridBreak } from "../../components/GridBreak";

const usePeopleDetailStyle = makeStyles((theme: Theme) => ({
	manageButton: {
		color: theme.palette.secondaryResponse.main,
		borderColor: theme.palette.secondaryResponse.main,
		height: 50,
		fontWeight: 600,
		opacity: 0.9,
	},
	errorText: {
		color: theme.palette.error.main,
	},
}));

interface PeopleDetailsPageProps {
	selectedAttendee: Attendee;
}

export function PeopleDetailsPage(props: PeopleDetailsPageProps) {
	const { selectedAttendee } = props;
	const userId = selectedAttendee.nullableUserId;
	const classes = usePeopleDetailStyle();
	const history = useHistory();
	const [user] = useAppUser();
	const setServerErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const peopleRouter = usePeopleRouter();
	const attendeeContext = useAttendees();
	const groupContext = useGroups();

	const [disabled, setDisabled] = useState(false);

	const [isJourneyListLoading, setJourneyStateLoading] = useState(true);
	const [statusLoading, setStatusLoading] = useState(userId != null);
	const [inviteStatus, setInviteStatus] = useState<AdminInviteStatus>();
	const [groupsAndJourneys, setGroupsAndJourneys] = useState<(Group | SubscribedJourney)[]>([]);

	const [helpText, setHelpText] = useState<string>("");
	const [showSendMessageDialog, setShowSendMessageDialog] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [reloadJourneys, setReloadJourneys] = useState(true);

	useEffect(() => setHelpText(""), [selectedAttendee]);

	useEffect(() => {
		if (reloadJourneys !== true) return;

		async function getJourneys() {
			const result = await AttendeeService.getJourneysForAttendee(selectedAttendee.id);
			const attendeeGroups = groupContext.groups.filter((g) =>
				g.groupMembers.some((m) => m.attendeeId === selectedAttendee.id)
			);
			if (ServerResult.isSuccess(result)) {
				setGroupsAndJourneys([...attendeeGroups, ...result.data]);
				setReloadJourneys(false);
				setJourneyStateLoading(false);
			} else {
				setServerErrorAlert(result);
			}
		}
		getJourneys();
	}, [reloadJourneys, selectedAttendee, setServerErrorAlert, groupContext.groups]);

	useEffect(() => {
		if (userId) {
			setStatusLoading(true);
			AttendeeService.getAdminInviteStatus(userId).then((result) => {
				if (ServerResult.isSuccess(result)) setInviteStatus(result.data);
				else setInviteStatus(undefined);
				setStatusLoading(false);
			});
		} else {
			setStatusLoading(false);
		}
	}, [userId]);

	const removeFromGroup = async (attendeeId: string, groupId: string) => {
		setDisabled(true);
		const result = await GroupService.removeAttendeesFromGroup({ attendeeIds: [attendeeId], groupId });
		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setSuccessAlert("Removed from group");
			groupContext.update(result.data);
			setReloadJourneys(true);
		} else if (ServerResult.isError(result)) {
			setServerErrorAlert(result);
		}
	};

	const unsubscribeFromJourney = async (subscriberId: string) => {
		setDisabled(true);
		const result = await AttendeeService.unsubscribeFromJourney(subscriberId);
		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setSuccessAlert("Unsubscribed from journey.");
			setReloadJourneys(true);
		} else {
			setServerErrorAlert(result);
		}
	};

	const deleteAttendee = async () => {
		setDisabled(true);
		const result = await AttendeeService.delete(selectedAttendee.id);
		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setSuccessAlert(result.data + "");
			setShowConfirmDelete(false);
			peopleRouter.deleteAttendee(selectedAttendee);
		} else {
			setServerErrorAlert(result);
		}
	};

	const resendInvite = async () => {
		if (userId == null) return;

		setDisabled(true);
		const result = await AttendeeService.resendInvite(userId);
		setDisabled(false);

		if (ServerResult.isSuccess(result)) setSuccessAlert(result.data);
		else setServerErrorAlert(result);
	};

	const makeAttendeeAdmin = async () => {
		if (selectedAttendee.optInStatus !== ChurchOptInStatus.Accepted) {
			setServerErrorAlert({ statusCode: 400, message: "Attendee must be opted-in to receive messages" });
			return;
		}

		if (!selectedAttendee.email) {
			setServerErrorAlert({ statusCode: 400, message: "Email address required for Administrators" });
			setHelpText("Add Email Address");
			return;
		}

		setDisabled(true);
		const result = await AttendeeService.makeAdmin(selectedAttendee.id);
		setDisabled(false);

		if (ServerResult.isValidationError(result)) {
			setServerErrorAlert({ statusCode: 400, message: result.errors[0].errors[0] });
			return;
		}

		if (ServerResult.isError(result)) {
			setServerErrorAlert(result);
			return;
		}
		setSuccessAlert("Success, Invitation E-mail has been sent");
		attendeeContext.update(result.data);
		peopleRouter.selectAttendee(result.data);
	};

	return (
		<>
			<PageTitle title={selectedAttendee.name} />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
						<Button onClick={() => peopleRouter.selectAttendee(undefined)}>People</Button>
						<Typography color="textPrimary">{selectedAttendee.name}</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item lg={3} sm={6} xs={12}>
					{statusLoading ? (
						<Card style={{height: "100%"}}>
							<div style={{ textAlign: "center", paddingTop: 40 }}>
								<CircularProgress color="primary" />
							</div>
						</Card>
					) : (
						<AttendeeInfoCard
							attendee={selectedAttendee}
							disabled={disabled}
							showPulse={helpText !== ""}
							status={inviteStatus}
						/>
					)}
				</Grid>
				<Grid item lg={4} sm={6} xs={12}>
					<Card>
						<CardHeader title="Manage" />
						<CardContent>
							{helpText !== "" && <Typography className={classes.errorText}>{helpText}</Typography>}
							<div style={{ display: "flex", flexDirection: "column" }}>
								<Button
									variant="outlined"
									fullWidth
									className={classes.manageButton}
									style={{ marginBottom: 8 }}
									onClick={() => history.push(routes.app.resolve.inboxDetailPage(selectedAttendee.id))}
									disabled={disabled}
								>
									See Messages
								</Button>
								<Button
									variant="outlined"
									fullWidth
									className={classes.manageButton}
									style={{ marginBottom: 8 }}
									onClick={() => setShowSendMessageDialog(true)}
									disabled={disabled}
								>
									Send Text Message
								</Button>
								{inviteStatus === AdminInviteStatus.Pending ? (
									<Button
										variant="outlined"
										fullWidth
										style={{ marginBottom: 8 }}
										className={classes.manageButton}
										disabled={disabled}
										onClick={resendInvite}
									>
										Resend Admin Invite Email
									</Button>
								) : (
									<Button
										variant="outlined"
										fullWidth
										style={{ marginBottom: 8 }}
										className={classes.manageButton}
										disabled={disabled || selectedAttendee.role !== Role.Attendee}
										onClick={makeAttendeeAdmin}
									>
										Make Admin
									</Button>
								)}

								<Button
									variant="outlined"
									fullWidth
									className={classes.manageButton}
									onClick={() => setShowConfirmDelete(true)}
									disabled={selectedAttendee.phoneNumber === user.phoneNumber || disabled}
								>
									Delete
								</Button>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<GridBreak />
				<Grid item lg={7} xs={12}>
					<Card>
						<CardHeader title="Groups and Journeys" />
						<CardContent>
							{isJourneyListLoading && (
								<div style={{ textAlign: "center", paddingTop: 40 }}>
									<CircularProgress color="primary" />
								</div>
							)}
							{!isJourneyListLoading && (
								<TableContainer>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												<TableCell align="left">Name</TableCell>
												<TableCell align="left">Type</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{groupsAndJourneys.length === 0 && (
												<TableRow>
													<TableCell>
														<Typography style={{ fontWeight: 400 }} variant="h4">
															No Groups or Journeys...
														</Typography>
													</TableCell>
													<TableCell />
													<TableCell />
												</TableRow>
											)}
											{groupsAndJourneys.map((item: Group | SubscribedJourney, index: number) => {
												if ("keywordTrigger" in item) {
													return (
														<TableRow key={index} hover>
															<TableCell
																onClick={() =>
																	window.location.replace(
																		routes.app.resolve.journeyDetailPage(
																			item.category,
																			item.id
																		)
																	)
																}
															>
																{item.name}
															</TableCell>
															<TableCell>{JourneyType[item.type].replace(/_/g, " ")}</TableCell>
															<TableCell>
																<Button
																	size="small"
																	variant="outlined"
																	fullWidth
																	className={classes.manageButton}
																	disabled={disabled}
																	onClick={(e) => {
																		e.preventDefault();
																		unsubscribeFromJourney(item.subscriberId);
																	}}
																>
																	Unsubscribe
																</Button>
															</TableCell>
														</TableRow>
													);
												} else {
													return (
														<TableRow key={index} hover>
															<TableCell
																onClick={() =>
																	window.location.replace(
																		routes.app.resolve.groupDetailPage(item.id)
																	)
																}
															>
																{item.name}
															</TableCell>
															<TableCell>Group</TableCell>
															<TableCell>
																<Button
																	size="small"
																	variant="outlined"
																	fullWidth
																	className={classes.manageButton}
																	disabled={disabled}
																	onClick={(e) => {
																		e.preventDefault();
																		removeFromGroup(selectedAttendee.id, item.id);
																	}}
																>
																	Remove
																</Button>
															</TableCell>
														</TableRow>
													);
												}
											})}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Dialog open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
					<DialogTitle>Are you sure you want to delete {selectedAttendee.name}?</DialogTitle>
					<DialogActions>
						<Button variant="outlined" onClick={deleteAttendee} disabled={disabled}>
							Yes
						</Button>
						<Button variant="outlined" onClick={() => setShowConfirmDelete(false)} disabled={disabled}>
							No
						</Button>
					</DialogActions>
				</Dialog>
				<SendDirectMessageDialog
					isOpen={showSendMessageDialog}
					onClose={() => setShowSendMessageDialog(false)}
					selectedAttendee={selectedAttendee}
				/>
			</Grid>
		</>
	);
}
