import { resolveEnvironment, RuntimeEnvironment } from "../Environment";

const getRoot = () => {
	const environment = resolveEnvironment();
	switch(environment){
		case RuntimeEnvironment.LocalDev: return "http://localhost:37573";
		case RuntimeEnvironment.Development: return "https://api.joinresponse.dev";
		default : return "https://api.joinresponse.com";
	}
} 

export const apiRoot =`${getRoot()}/api`;
export const apiServer = getRoot();