import { SermonPrompt } from "../entities/SermonPrompt";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

type ServerSermonPrompt = Modify<
	SermonPrompt,
	{
		scheduledTime: string;
		timeSent?: string;
	}
>;

export interface CreateSermonPromptRequest {
	date: string;
	message: string;
}

export interface UpdateSermonPromptMessageRequest {
	id: string;
	message: string;
}

const convert = (serverModel: ServerSermonPrompt): SermonPrompt => {
	return {
		...serverModel,
		scheduledTime: new Date(serverModel.scheduledTime),
		timeSent: serverModel.timeSent ? new Date(serverModel.timeSent) : undefined,
	};
};

export const SermonPromptService = {
	cancel(broadcastMessageId: string) {
		return WebClient.Delete(`${apiRoot}/sermon-prompts/${broadcastMessageId}`);
	},
	create(request: CreateSermonPromptRequest) {
		return WebClient.Convert.Post.Validated(`${apiRoot}/sermon-prompts`, request, convert);
	},
	getSermonPrompts() {
		return WebClient.Convert.Get<ServerSermonPrompt[], SermonPrompt[]>(`${apiRoot}/sermon-prompts`, (m) => m.map(convert));
	},
	setMessage(request: UpdateSermonPromptMessageRequest) {
		return WebClient.Convert.Put.Validated(`${apiRoot}/sermon-prompts/set-message`, request, convert);
	},
};
