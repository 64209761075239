import { FormControl, FormHelperText, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Journey, JourneyCategory, JourneyType } from "../../../entities/Journey";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { KeywordService } from "../../../services/KeywordService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import ResponseInputComponent from "../../responseInputComponent";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout } from "./JourneyStepLayout";

const useStyles = makeStyles((theme: Theme) => ({
	gutter: {
		paddingRight: 6,
		paddingLeft: 6,
	},
	labelBlueTone: {
		color: theme.palette.secondaryResponse.main,
	},
	divider: {
		border: "none",
		backgroundColor: "#F5F6F7",
		height: 3,
		width: "100%",
	},

	responseTextArea: {
		color: theme.palette.secondaryResponse.main,
		fontWeight: 400,
	},
	mobileTimelineGrayLine: {
		height: "100%",
		width: 2,
		backgroundColor: "#EFEFEF",
		border: "none",
		marginBlockStart: 0,
		marginBlockEnd: 0,
	},
	userMessageBubble: {
		backgroundColor: "#EFEBF9",
		color: "#1C4057",
		padding: 8,
		borderRadius: 10,
		borderBottomLeftRadius: 0,
	},
}));

interface Props {
	journey: Journey;
}

export function MobileKeywordAndPreviewStep(props: Props) {
	const classes = useStyles();
	const progress = useJourneySetupProgress();
	const { fieldErrors } = progress;
	const setErrorAlert = useServerErrorAlert();
	const journey = props.journey;

	const [keyword, setKeyword] = useState(journey.keywordTrigger);
	const [completionResponse, setCompletionResponse] = useState(journey.completionResponse);
	const [promptMessage, setPromptMessage] = useState<string | undefined>(
		journey.category === JourneyCategory.Prompt ? journey.prompt : undefined
	);
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const completeStep = async () => {
		progress.setDisabled(true);
		let response = await KeywordService.validateKeyword({ journeyId: journey.id, keyword });
		progress.setDisabled(false);
		if (ServerResult.isSuccess(response)) {
			if (response.data.isValid) {
				setErrorMessage("");
				setSuccessMessage("Looks good!");
				setTimeout(() => {
					let updatedJourney: Journey = { ...journey, keywordTrigger: keyword, completionResponse };
					if (promptMessage && updatedJourney.category === JourneyCategory.Prompt) {
						updatedJourney.prompt = promptMessage;
					}
					progress.completeStep(updatedJourney);
				}, 500);
			} else {
				setSuccessMessage("");
				setErrorMessage(response.data.message);
			}
		} else {
			setErrorAlert(response);
		}
	};

	return (
		<JourneyStepLayout required={true} onConfirmStep={completeStep}>
			<Grid item xs={12} className={classes.gutter}>
				<Typography className={classes.labelBlueTone} variant="subtitle2">
					Keyword
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.gutter} style={{ marginTop: 2 }}>
				<ResponseInputComponent
					successMessage={successMessage}
					errorMessage={errorMessage}
					value={keyword}
					onChange={setKeyword}
					maxLength={9}
				/>
			</Grid>
			<Grid item xs={12}>
				<div style={{ height: 30, width: "100%" }}></div>
				<hr className={classes.divider} />
			</Grid>
			<Grid item xs={12} className={classes.gutter} style={{ display: "flex", paddingBottom: 17, overflowY: "hidden" }}>
				<div style={{ minWidth: 48, textAlign: "center", marginTop: 9 }}>
					<div
						style={{
							backgroundColor: "#EFEFEF",
							width: 16,
							height: 16,
							borderRadius: 8,
							margin: "0 auto",
						}}
					/>
					<hr className={classes.mobileTimelineGrayLine} />
				</div>
				<div className={classes.userMessageBubble}>
					<div>{keyword}</div>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				className={classes.gutter}
				style={{
					display: "flex",
					overflowY: "hidden",
					paddingBottom: journey.category === JourneyCategory.Prompt ? 12 : 0,
				}}
			>
				<div style={{ minWidth: 48 }}>
					<div
						style={{
							backgroundColor: "#1C4057",
							width: 22,
							height: 22,
							borderRadius: 11,
							margin: "0 auto",
							marginTop: 0,
							textAlign: "center",
						}}
					>
						<span style={{ color: "#FFF", fontSize: 15 }}>1</span>
					</div>
					{journey.category === JourneyCategory.Prompt && <hr className={classes.mobileTimelineGrayLine} />}
				</div>
				<div style={{ width: "100%", paddingLeft: 4 }}>
					<div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
						<div style={{ fontStyle: "italic", color: "#818A91", width: "100%" }}>
							{journey.category === JourneyCategory.Prompt ? "Message One" : "Your Response"}
						</div>
					</div>
					{journey.category === JourneyCategory.Prompt && (
						<>
							<FormControl
								style={{ width: "100%" }}
								error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Prompt")}
							>
								<TextField
									id="outlined-bare"
									defaultValue={journey.prompt}
									margin="normal"
									variant="outlined"
									style={{ width: "100%", marginTop: 0 }}
									multiline={true}
									onChange={(e) => setPromptMessage(e.currentTarget.value)}
									InputProps={{
										classes: {
											root: classes.responseTextArea,
										},
									}}
								/>
								<FormHelperText
									dangerouslySetInnerHTML={{
										__html: FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "Prompt"),
									}}
								/>
							</FormControl>
						</>
					)}
					{journey.category !== JourneyCategory.Prompt && (
						<FormControl
							style={{ width: "100%" }}
							error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "CompletionResponse")}
						>
							<TextField
								id="outlined-bare"
								defaultValue={journey.completionResponse}
								margin="normal"
								variant="outlined"
								style={{ width: "100%", marginTop: 0 }}
								multiline={true}
								onChange={(e) => setCompletionResponse(e.currentTarget.value)}
								InputProps={{
									classes: {
										root: classes.responseTextArea,
									},
								}}
							/>
							<FormHelperText
								dangerouslySetInnerHTML={{
									__html: FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "CompletionResponse"),
								}}
							/>
						</FormControl>
					)}
				</div>
			</Grid>
			{journey.category === JourneyCategory.Prompt && (
				<>
					<Grid
						item
						xs={12}
						className={classes.gutter}
						style={{ display: "flex", overflowY: "hidden", paddingBottom: 20 }}
					>
						<div style={{ minWidth: 48 }}>
							<div style={{ display: "grid" }}>
								<hr className={classes.mobileTimelineGrayLine} style={{ minHeight: 6 }} />
								<div
									style={{
										backgroundColor: "#EFEFEF",
										width: 16,
										height: 16,
										borderRadius: 8,
										margin: "0 auto",
									}}
								/>
							</div>

							<hr className={classes.mobileTimelineGrayLine} />
						</div>
						<div className={classes.userMessageBubble}>
							<div>
								<i>Example message:</i>{" "}
								{journey.type === JourneyType.Gather_Emails
									? "john.smith@myemail.com"
									: "Please pray for my cat's surgery tomorrow"}
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						className={classes.gutter}
						style={{
							display: "flex",
							overflowY: "hidden",
						}}
					>
						<div style={{ minWidth: 48 }}>
							<div
								style={{
									backgroundColor: "#1C4057",
									width: 22,
									height: 22,
									borderRadius: 11,
									margin: "0 auto",
									marginTop: 0,
									textAlign: "center",
								}}
							>
								<span style={{ color: "#FFF", fontSize: 15 }}>2</span>
							</div>
						</div>
						<div style={{ width: "100%", paddingLeft: 4 }}>
							<div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
								<div style={{ fontStyle: "italic", color: "#818A91", width: "100%" }}>Message Two</div>
							</div>

							<FormControl
								style={{ width: "100%" }}
								error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "CompletionResponse")}
							>
								<TextField
									id="outlined-bare"
									defaultValue={journey.completionResponse}
									margin="normal"
									variant="outlined"
									style={{ width: "100%", marginTop: 0 }}
									multiline={true}
									onChange={(e) => setCompletionResponse(e.currentTarget.value)}
									InputProps={{
										classes: {
											root: classes.responseTextArea,
										},
									}}
								/>
								<FormHelperText
									dangerouslySetInnerHTML={{
										__html: FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "CompletionResponse"),
									}}
								/>
							</FormControl>
						</div>
					</Grid>
				</>
			)}
		</JourneyStepLayout>
	);
}
