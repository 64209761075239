import { Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { ResponseDialogLayoutProps, useCommonLayoutStyle } from "./ResponseDialogLayout";


export function ResponseDialogMobileLayout(props: React.PropsWithChildren<ResponseDialogLayoutProps>) {
	const { title } = props;

	let windowSize = window.innerWidth;
	let swoopWidth = windowSize * 1.4;
	let leftPos = ((swoopWidth - windowSize) / 2) * -1;

	const classes = { ...useCommonLayoutStyle(), ...useStyles() };

	return (
		<>
			<div
				className={classes.swoopBox}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			></div>
			<div
				className={classes.swoopBoxGreen}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			/>
			<div className={classes.headerBar}>
				<Typography className={classes.titlebartitle} variant="h1" style={{ paddingLeft: 14, fontSize: 24 }}>
					{title}
				</Typography>
				<IconButton
					className={classes.titleCloseIcon}
					aria-label="close modal"
					component="span"
					onClick={props.onClose}
				>
					<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
				</IconButton>
			</div>
			<Paper className={classes.setupContent}>
				<Grid container style={{  height: "100%" }}>
					{props.children}
				</Grid>
			</Paper>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	swoopBox: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.secondaryResponse.main,
		zIndex: 2000,
		top: 0,
	},
	swoopBoxGreen: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.primaryResponse.main,
		top: 40,
		boxShadow: "0 8px 6px -6px #C7BAB9",
	},
	headerBar: {
		position: "fixed",
		zIndex: 2001,
		width: "100%",
		display: "flex",
		alignItems: "center",
	},
	setupContent: {
		position: "fixed",
		paddingBottom: 59,
		paddingLeft: 6,
		paddingRight: 6,
		zIndex: 2001,
		top: 55,
		bottom: 0,
		left: 8,
		right: 8,
		borderRadius: "12px 12px 0 0",
		overflowY: "auto", overflowX: "hidden",
	},
}));
