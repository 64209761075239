import { makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
	loadingContainer: {
		position: "relative",
		margin: "0 auto",
		display: "block",
		width: 240,
		height: 240,
	},
	circle: {
		border: "3px solid",
		borderColor: theme.palette.primaryResponse.main,
		boxShadow: "0 0 2px #888",
		position: "relative",
		margin: "0 auto",
		height: 240,
		width: 240,
		borderRadius: 120,
		backgroundColor: "transparent",
	},
	"@keyframes myOrbit1": {
		from: { transform: "rotate(0deg) translateX(120px) rotate(0deg)" },
		to: { transform: "rotate(360deg) translateX(120px) rotate(-360deg)" },
	},
	"@keyframes myOrbit2": {
		from: { transform: "rotate(30deg) translateX(120px) rotate(30deg)" },
		to: { transform: "rotate(390deg) translateX(120px) rotate(-390deg)" },
	},
	"@keyframes myOrbit3": {
		from: { transform: "rotate(60deg) translateX(120px) rotate(60deg)" },
		to: { transform: "rotate(420deg) translateX(120px) rotate(-420deg)" },
	},
	"@keyframes myOrbit4": {
		from: { transform: "rotate(90deg) translateX(120px) rotate(90deg)" },
		to: { transform: "rotate(450deg) translateX(120px) rotate(-450deg)" },
	},
	ball: {
		left: 115,
		position: "absolute",
		top: 115,
		width: 10,
		height: 10,
		borderRadius: 5,
		backgroundColor: theme.palette.primaryResponse.main,
		animationTimingFunction: "linear",
		animationDuration: "3s",
		animationIterationCount: "infinite",
	},
	ball1: {
		animationName: "$myOrbit1",
	},
	ball2: {
		animationName: "$myOrbit2",
	},
	ball3: {
		animationName: "$myOrbit3",
	},
	ball4: {
		animationName: "$myOrbit4",
	},
	message: {
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
		marginTop: 6,
	},
}));

interface LoadingSpinnerProps {
	finishMessage?: string;
	isComplete: boolean;
	durationMessage?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
	const classes = useStyles();

	return (
		<div className={classes.loadingContainer}>
			<div className={classes.circle}></div>
			<div className={classNames(classes.ball, classes.ball1)} />
			<div className={classNames(classes.ball, classes.ball2)} />
			<div className={classNames(classes.ball, classes.ball3)} />
			<div className={classNames(classes.ball, classes.ball4)} />
			{props.durationMessage && (
				<Typography className={classes.message} variant="body1" color="textSecondary" align="center">
					{props.durationMessage}
				</Typography>
			)}
		</div>
	);
};

export default LoadingSpinner;
