import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

interface ChurchRegistrationRequest {
    churchName: string;
    churchAddress: string;
    city: string;
    state: string;
    zipCode: string;
    mobilePhoneNumber: string;
    email?: string;
}

export const RegistrationService = {
	setUserPhone: (phoneNumber: string) => WebClient.Post.Validated(`${apiRoot}/registration/set-user-phone`, { phoneNumber }),
	verifyPhone: (verificationCode: string) => WebClient.Put.Validated(`${apiRoot}/registration/validate-user-phone-token`, { verificationCode }),
    registerChurch: (request:ChurchRegistrationRequest) => WebClient.Post.Validated(`${apiRoot}/registration/register-church`, request),
    updateChurch: (request:ChurchRegistrationRequest) => WebClient.Post.Validated(`${apiRoot}/registration/update-church-Brand`, request),
    acquireResponseNumber: () => WebClient.Post.Unvalidated<string>(`${apiRoot}/registration/acquire-response-number`, {})
}