import React, { useRef, useState } from "react";
import { Button, FormHelperText, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useAppUser } from "../../../hooks/useAppUser";
import { downloadCsv, readCsvString } from "../../../utillity/CsvHelper";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Attendee from "../../../entities/Attendee";
import { CsvResults } from "./CsvResults";
import { VerticalCenter } from "../../../components/VerticalCenter";

export interface ImportPeopleCsvModel {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	exists: boolean;
	valid: boolean;
}

export interface CsvValidationError {
	row: number;
	column: number;
	error: string;
}

interface UploadCsvFileStepProps {
	allAttendees: Attendee[];
	onCsvUploaded: (results: ImportPeopleCsvModel[]) => void;
}

export function UploadCsvFileStep(props: UploadCsvFileStepProps) {
	const { allAttendees } = props;
	const [user] = useAppUser();
	const theme = useTheme();
	const classes = useCsvFileStepStyles();
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const [inputKey, setInputKey] = useState(1);
	const [disabled, setDisabled] = useState(false);
	const [fileError, setFileError] = useState<string>();
	const [fileName, setFileName] = useState<string>();

	const [results, setResults] = useState<ImportPeopleCsvModel[]>();
	const [resultErrors, setResultErrors] = useState<CsvValidationError[]>();

	const downloadSampleFile = () => {
		const rows = [
			["FirstName", "LastName", "Phone", "Email"],
			[user.firstName, user.lastName, user.phoneNumber!.substr(2), user.email],
		];
		downloadCsv("import-people.csv", rows);
	};

	const onUploadButtonClicked = () => {
		setFileError(undefined);
		setResults(undefined);
		setResultErrors(undefined);
		fileInputRef.current?.click();
	};

	const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		setInputKey((k) => k + 1);
		if (!files || !files[0]) {
			setFileError("Couldn't find uploaded file");
			return;
		}

		const file = files[0];
		const fileName = files[0].name;

		if (fileName.toLowerCase().lastIndexOf(".csv") === -1) {
			setFileError("Please upload only CSV files");
			return;
		}

		setFileName(fileName);

		const reader = new FileReader();

		reader.addEventListener("load", (e) => {
			setDisabled(false);

			if (!e.target || typeof e.target.result !== "string") {
				setFileError("Parsing error");
				return;
			}

			const csvRows = readCsvString(e.target.result);
			if (csvRows.length <= 1) {
				setFileError("CSV file empty");
				return;
			}

			const headers = csvRows[0].join(",");
			if (headers !== "FirstName,LastName,Phone,Email" && headers !== "FirstName,LastName,Phone,Email,Error") {
				setFileError("Your uploaded CSV file can't be processed. Please use the downloadable template file.");
				return;
			}

			const { results, validationErrors } = parseCsvRows(csvRows, allAttendees);

			setResults(results);
			setResultErrors(validationErrors);
		});

		setDisabled(true);
		reader.readAsBinaryString(file);
	};

	const onImport = () => {
		if (!results) return;
		const validResults = results.filter((r) => r.valid);
		if (validResults.length > 0) props.onCsvUploaded(validResults);
	};

	return (
		<VerticalCenter>
			<Grid container justify="center">
				<Grid item xs={12} style={{ opacity: results ? 0.7 : undefined }}>
					<Typography variant="h1" align="center">
						Upload a CSV file and import people instantly
					</Typography>
				</Grid>
				<Grid item xs={12} style={{ opacity: results ? 0.7 : undefined }}>
					<Grid container justify="center" style={{ paddingTop: 40 }} spacing={4}>
						<Grid item>
							<Button
								variant="contained"
								onClick={downloadSampleFile}
								className={classes.fatButton}
								disabled={disabled}
								startIcon={<GetAppIcon />}
							>
								Download template file
							</Button>
							<div style={{ maxWidth: 300 }}>
								<Typography variant="body2" align="left" style={{ margin: 10 }}>
									Download the template file to see the required format for imports. Replace the sample data
									with your own.
								</Typography>
							</div>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={onUploadButtonClicked}
								className={classes.fatButton}
								disabled={disabled}
								startIcon={<CloudUploadIcon />}
							>
								Upload CSV file
							</Button>
							<input
								onChange={onFileSelected}
								multiple={false}
								ref={fileInputRef}
								type="file"
								accept=".csv"
								hidden
								key={`inputKey-${inputKey}`}
							/>
							<div style={{ maxWidth: 300 }}>
								{fileError && (
									<FormHelperText style={{ color: theme.palette.error.main, textAlign: "center" }}>
										{fileError}
									</FormHelperText>
								)}
								<Typography variant="body2" align="left" style={{ marginTop: fileError ? undefined : 10 }}>
									Select your file to bulk import your church contact list. Make sure your file matches the
									format of the template file.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Grid>
				{results && resultErrors && (
					<Grid item xs={12}>
						<CsvResults
							results={results}
							resultErrors={resultErrors}
							fileName={fileName}
							onReuploadClick={onUploadButtonClicked}
							onImportClicked={onImport}
						/>
					</Grid>
				)}
			</Grid>
		</VerticalCenter>
	);
}

export const useCsvFileStepStyles = makeStyles(() => ({
	fatButton: { padding: 15, width: 300, borderRadius: 10 },
	checkListIcon: { marginTop: 6, marginRight: 6, fontSize: 18 },
}));

const sanitizePhoneNumber = (phoneNumber: string) => {
	if (phoneNumber.startsWith("+1")) phoneNumber = phoneNumber.substr(2);
	return phoneNumber.replace(/\D/g, "");
};

const isValidEmail = (email: string) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

function parseCsvRows(csvRows: string[][], allAttendees: Attendee[] | undefined) {
	const results: ImportPeopleCsvModel[] = [];
	const validationErrors: CsvValidationError[] = [];
	csvRows.forEach((row, index) => {
		if (index > 0) {
			const phone = sanitizePhoneNumber(row[2]);
			const model: ImportPeopleCsvModel = {
				firstName: row[0],
				lastName: row[1],
				phone,
				email: row[3],
				exists: allAttendees!.some((a) => a.phoneNumber === `+1${phone}`),
				valid: false,
			};

			const errorCount = validationErrors.length;

			if (!model.exists && model.firstName === "")
				validationErrors.push({ row: index + 1, column: 1, error: "First Name Missing" });

			if (!model.exists && model.lastName === "")
				validationErrors.push({ row: index + 1, column: 2, error: "Last Name Missing" });

			const duplicateIndex = results.findIndex((r) => r.phone === model.phone);
			if (model.phone === "") {
				validationErrors.push({ row: index + 1, column: 3, error: "Phone Number Missing" });
			} else if (model.phone.length !== 10) {
				validationErrors.push({ row: index + 1, column: 3, error: "Phone Number Invalid" });
			} else if (duplicateIndex !== -1) {
				validationErrors.push({
					row: index + 1,
					column: 3,
					error: `Phone Number is a duplicate of row ${duplicateIndex + 2}`,
				});
			}

			if (!model.exists && model.email !== "" && !isValidEmail(model.email))
				validationErrors.push({ row: index + 1, column: 4, error: "Email Invalid" });

			model.valid = validationErrors.length === errorCount;

			results.push(model);
		}
	});
	return { results, validationErrors };
}
