import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getPlanDescription, ResponseSubscriptionPlan } from "../../../entities/ResponseSubscriptionPlan";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import ResponseSubscriptionPlanService from "../../../services/ResponseSubscriptionPlanService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";

export interface NewAccountRegistrationForm {
    fullName: string;
    emailAddress: string;
    password: string;
    churchName: string;
    subscriptionId: string;
}

export interface AccountRegistrationFormProps {
    disabled?: boolean;
}

export function AccountRegistrationForm(props:AccountRegistrationFormProps){
	const { disabled } = props;
    const setServerError = useServerErrorAlert();
    const query = useQueryParams();
    
    const [errors, setErrors] = useState<FieldValidationError[]>([]);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [plans, setPlans] = useState<ResponseSubscriptionPlan[]>([]);
	const [request, setRequest] = useState<NewAccountRegistrationForm>({
        emailAddress: query.get("emailAddress") ?? "",
        fullName: query.get("fullName") ?? "",
        churchName: query.get("churchName") ?? "",
        password: "",
        subscriptionId: query.get("subscriptionId") ?? ""
	});

	useEffect(() => {
		let errors:FieldValidationError[] = [];

		if(query.has("fullNameError"))
			errors.push({ field: "FullName", errors: [ query.get("fullNameError")! ]})
		if(query.has("churchNameError"))
			errors.push({ field: "ChurchName", errors: [ query.get("churchNameError")! ]})
		if(query.has("emailAddressError"))
		errors.push({ field: "EmailAddress", errors: [ query.get("emailAddressError")! ]})
		if(query.has("passwordError"))
			errors.push({ field: "Password", errors: [ query.get("passwordError")! ]})
		if(query.has("subscriptionIdError"))
			errors.push({ field: "SubscriptionId", errors: [ query.get("subscriptionIdError")! ]})
		
		setErrors(errors);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    useEffect(() => {
		async function fetchPlans() {
			const result = await ResponseSubscriptionPlanService.getPublicPlans();

			if (ServerResult.isSuccess(result)) {
				setPlans(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    return (
        <>
            <TextField
                name="FullName"
                label="Full Name"
                value={request.fullName}
                onChange={(e) => setRequest({...request, fullName: e.target.value})}
                margin="normal"
                fullWidth
                required
                type="text"
                disabled={disabled}
                error={FieldValidationErrorUtils.isFieldInError(errors, "FullName")}
                helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "FullName")}
            />
            <TextField
                name="ChurchName"
                label="Church Name"
                value={request.churchName}
                onChange={(e) => setRequest({...request, churchName: e.target.value})}
                margin="normal"
                fullWidth
                required
                type="text"
                disabled={disabled}
                error={FieldValidationErrorUtils.isFieldInError(errors, "ChurchName")}
                helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "ChurchName")}
            />
            <TextField
                name="EmailAddress"
                label="Email"
                value={request.emailAddress}
                onChange={(e) => setRequest({...request, emailAddress: e.target.value})}
                margin="normal"
                fullWidth
                required
                type="text"
                disabled={disabled}
                error={FieldValidationErrorUtils.isFieldInError(errors, "EmailAddress")}
                helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "EmailAddress")}
            />
            <TextField
                name="Password"
                label="Create new password"
                value={request.password}
                onChange={(e) => setRequest({...request, password: e.target.value})}
                margin="normal"
                fullWidth
                required
                type="password"
                disabled={disabled}
                error={FieldValidationErrorUtils.isFieldInError(errors, "Password")}
                helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "Password")}
            />
            <FormControl margin="normal" fullWidth required >
                <InputLabel>Select Plan</InputLabel>
                <Select
                    name="SubscriptionId"
                    label="Select Plan"
                    id="plan-select"
                    value={request.subscriptionId}
                    required
                    fullWidth
                    onChange={(e) => setRequest({...request, subscriptionId: e.target.value as string})}
                    error={FieldValidationErrorUtils.isFieldInError(errors, "SubscriptionId")}
                    >
                    {plans.map((plan) => {
                        return (
                            <MenuItem key={plan.id} value={plan.id} > 
                                {getPlanDescription(plan)}
                            </MenuItem>
                        )})
                    }
                </Select>
            </FormControl>
            <TextField
                name="TimeZoneId"
                value={timeZone}
                type="text"
                disabled={disabled}
                style={{ visibility: "hidden", height: 0 }}
            />
        </>
    )
}