import { Button, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../hooks/isMobile";
import { routes } from "../../routes";
import { apiRoot } from "../../services/Settings";
import { AccountRegistrationForm } from "./Components/AccountRegistrationForm";

const useConnectStyles = makeStyles((theme: Theme) => ({
	registerPaper: {
		padding: "0px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			registerPaper: {
				margin: "0px 20px",
				padding: "0px 30px",
			},
		},
	},
	registerButton: {
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	responseBlueText: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
}));

export default function RegisterProfile() {
	const classes = useConnectStyles();
	const mobile = isMobile();

	return (
		<Grid style={{ height: "100vh", overflowY: "auto" }} container direction="column" justify="center" alignItems="center">
			<Grid item style={{ height: mobile ? "100%" : undefined}}>
				<Paper className={classes.registerPaper}>
					<Grid container direction="column" justify="center" alignItems="center">
						<div style={{ maxWidth: 350 }}>
							<img
								src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
								style={{ maxWidth: "100%", height: "auto" }}
								alt="Response"
							/>
						</div>
						<Typography variant="h5">Create your account</Typography>
					</Grid>
					<form action={`${apiRoot}/registration`} method="post" >
						<AccountRegistrationForm />
						<Hidden smDown>
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
								style={{ padding: "30px 0px 70px 0px" }}
							>
								<Link
									to={`${routes.public.signInPage}?redirect=${routes.app.dashBoardPage}`}
									style={{ textDecoration: "none" }}
								>
									<Button
										variant="text"
										size="medium"
										color="primary"
										type="button"
										className={classes.responseBlueText}
										style={{ fontWeight: 600 }}
									>
										Already have an account?
									</Button>
								</Link>
								<Button
									variant="contained"
									size="medium"
									color="primary"
									type="submit"
									style={{ minWidth: 150 }}
									className={classes.registerButton}
								>
									Create Account
								</Button>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Grid container direction="row" justify="space-between" alignItems="center">
								<Link
									to={`${routes.public.signInPage}?redirect=${routes.app.dashBoardPage}`}
									style={{ textDecoration: "none" }}
								>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.responseBlueText}
										style={{ fontWeight: 600 }}
									>
										Already have an account?
									</Button>
								</Link>
							</Grid>
							<Button
								fullWidth
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ margin: "20px 0px 70px 0px" }}
								className={classes.registerButton}
							>
								Create Account
							</Button>
						</Hidden>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}
