import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Answer, SignUpListAnswer } from "../../../../entities/Forms/Answer";
import { SignupListQuestion } from "../../../../entities/Forms/Question";

const SignupOverviewDigestStyles = makeStyles((theme: Theme) => ({
	prompt: {
		fontSize: 14,
		color: theme.palette.secondaryResponse.main,
		fontWeight: 400,
	},
	grouping: {
		fontSize: 16,
		borderBottom: "1px solid",
		borderColor: theme.palette.lightGrayBlue.main,
	},
}));

interface Props {
	question: SignupListQuestion;
	answers: Answer[];
}

interface SignUpData {
	textGrouping: string;
	requestedQuantity: number;
	count: number;
}

export function SignupOverviewDigest(props: Props) {
	const classes = SignupOverviewDigestStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const [data, setData] = useState<SignUpData[]>([]);

	useEffect(() => {
		function setUpData() {
			let uniqueTextArray: SignUpData[] = [];

			props.question.choices.forEach((choice) => {
				props.answers.forEach((answer) => {
					let singleAnswer = answer as SignUpListAnswer;

					let selectedByItem = singleAnswer.signupItems.filter((item) => item.text === choice.text);

					let elementIndex = uniqueTextArray.findIndex((a) => a.textGrouping === choice.text);

					if (elementIndex > -1) {
						uniqueTextArray[elementIndex].count =
							uniqueTextArray[elementIndex].count + selectedByItem.reduce((n, { quantity }) => n + quantity, 0);
					} else {
						uniqueTextArray.push({
							requestedQuantity: choice.quantity,
							textGrouping: choice.text,
							count: selectedByItem.reduce((n, { quantity }) => n + quantity, 0),
						});
					}
				});
			});

			setData(uniqueTextArray);
		}

		setUpData();
	}, [props]);

	return (
		<div>
			<Grid container spacing={mobileFormFactor ? 2 : 8}>
				{data.map((item: SignUpData, index: number) => {
					return (
						<Grid item xs={12} md={3} key={index} style={{ marginTop: 8 }}>
							<Typography className={classes.grouping}>
								<b>{item.textGrouping}</b>
							</Typography>
							<Typography className={classes.prompt}>
								<b>Requested: </b>
								{item.requestedQuantity}
							</Typography>
							<Typography className={classes.prompt}>
								<b>Filled: </b>
								{item.count}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
}
