import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../routes";

export function SubscriptionCanceledDialog(){
    const history = useHistory();

    const onSignOut = () => {
        history.replace(routes.public.signOutPage);
    }

    return <Dialog open={true}>
        <DialogTitle>This subscription has been canceled</DialogTitle>
        <DialogContent>Please contact Customer Support at <a href="mailto:support@joinresponse.com">support@joinresponse.com</a> to reactivate</DialogContent>
        <DialogActions><Button variant="outlined" onClick={onSignOut}>Sign Out</Button></DialogActions>
    </Dialog>
}