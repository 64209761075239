import { Button, Grid, useTheme } from "@material-ui/core";
import React from "react";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import { isMobile } from "../hooks/isMobile";

interface PersonalizationButtonsProps {
    onAddPersonalization: (token:string) => void;
}

export function PersonalizationButtons(props:PersonalizationButtonsProps){
    const theme = useTheme();    
    const mobile = isMobile();

    return (
        <Grid container spacing={1} justify="center">
            <Grid item xs={4} spacing={1} container>
                <Grid item xs={12}>
                    <Button 
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                            onClick={() => props.onAddPersonalization("{{First_Name}}")}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                            >
                        First Name
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={8} spacing={1} container>
                <Grid item xs={6}>
                    <Button 
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                            onClick={() => props.onAddPersonalization("{{Full_Name}}")}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                        >
                        Full Name
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button 
                            onClick={() => props.onAddPersonalization("{{Church}}")}
                            style={{ 
                                background: theme.palette.lightGrayBlue.main,
                            }}
                            fullWidth
                            endIcon={!mobile && <AddCircleOutline color="primary"/>} 
                        >
                        Church
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}