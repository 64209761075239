import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { routes } from "../../routes";

export default function AuthPage() {
	const [, setUser] = useUser();
	const urlParams = new URLSearchParams(window.location.search);
	let logout = urlParams.get("logout");
	if (logout != null) {
		setUser(undefined);
	}
	let token = urlParams.get("token");
	if (token != null && token.length > 0) {
		setUser(token);
	}
	const [redirect] = useState(urlParams.get("redirect") ?? routes.app.dashBoardPage);

	return <Redirect to={redirect} />;
}
