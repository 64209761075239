import React from "react";
import JourneyResponse from "../entities/JourneyResponse";
import { Keyword } from "../entities/Keywords/Keyword";
import { ServerError } from "../services/server/ServerError";

export type JourneyDetailState = {
	responses: JourneyResponse[];
	keyword?: Keyword;
	responsesLoadError: ServerError | undefined;
	disableState: [boolean, (isDisabled: boolean) => void];
	updateResponse: (response: JourneyResponse) => void;
};

export const JourneyDetailContext = React.createContext<JourneyDetailState>({} as any);

export function useJourneyDetailContext() {
	return React.useContext(JourneyDetailContext);
}
