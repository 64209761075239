import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { printTimeSinceDate } from "../../functions/datetime";
import { JourneyNotificationSubscription, useJourneyNotifications } from "../../hooks/useJourneyNotifications";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { PageTitle } from "../../components/PageTitle";

const useStyles = makeStyles((theme: Theme) => ({
	menuItemContainer: {
		display: "flex",
		width: "100%",
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 15,
		paddingRight: 4,
	},
	menuItemText: {
		lineHeight: 1.4,
		fontSize: 16,
		paddingTop: 0,
		paddingBottom: 0,
	},
	unreadText: {
		fontWeight: 400,
		marginLeft: 5,
	},
	timespanText: {
		fontWeight: 400,
		marginLeft: 4,
		lineHeight: 1.4,
		fontSize: 13,
		color: "#797979",
	},
}));

export function Notifications(props: {}) {
	const classes = useStyles();
	const history = useHistory();
	const notificationContext = useJourneyNotifications();
	const subscriptions = notificationContext.journeyNotificationSubscriptions;

	const onSelect = (subscription: JourneyNotificationSubscription) =>
		history.push(routes.app.resolve.journeyDetailPageResponses(subscription.journeyCategory, subscription.journeyId));

	return (
		<div>
			<PageTitle title="Notifications" />
            <Typography style={{padding: 5}}>{!subscriptions.some(s => s.unreadCount > 0) ? "No " : ""}Unread Notifications</Typography>
			<Grid container>
				{subscriptions
					.filter((s) => s.unreadCount > 0)
					.map((s, i) => (
						<Grid item xs={12}>
							<div onClick={() => onSelect(s)} className={classes.menuItemText} key={s.subscriptionId}>
								<div
									style={{ borderTop: i > 0 ? "1px solid #c1c1c1" : undefined }}
									className={classes.menuItemContainer}
								>
									<div>
										<span style={{fontWeight: 600}}>{s.journeyName}</span>
										<span className={classes.unreadText}>- {s.unreadCount} new,</span>
										<span className={classes.timespanText}>
											{printTimeSinceDate(s.lastMessageReceivedTime)}
										</span>
									</div>
									<div style={{ flex: 1 }} />
									<ChevronRightIcon />
								</div>
							</div>
						</Grid>
					))}
			</Grid>
		</div>
	);
}
