import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme, } from "@material-ui/core";
import { Group } from "../../../entities/Groups/Group";
import classNames from "classnames";
import { GroupTab } from "../../../entities/GroupTab";
import { GroupOverViewMobile } from "./GroupOverViewMobile";
import MemberTable from "./MemberTable";
import GroupAnnouncementTable from "./GroupAnnouncementTable";
import { ResponseFab } from "../../../components/ResponseFab";
import { AddGroupMemberDialog } from "../AddGroupMemberDialog/AddGroupMemberDialog";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { routes } from "../../../routes";
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) => ({
	contentPaper: {
		
	},
	hiddenTab: {
		opacity: 0.7,
		backgroundImage: "linear-gradient(#FFFFFF, #FBFBFB)",
		zIndex: 0,
	},
	activeTab: {
		backgroundColor: "#FFF",
	},
	pageTabs: {
		borderRadius: 5,
		borderBottomLeftRadius:0,
		borderBottomRightRadius:0,
		border: "1px #E2E2E2 solid",
		borderBottom: "none",
		zIndex: 2,
		textAlign: "center",
		color: theme.palette.secondaryResponse.main,
		minWidth: 150,
		paddingBottom: 5,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
		height: 48,
		fontSize: 18,
		paddingTop: 12,
		paddingLeft: 15,
		paddingRight: 15,
	},
	contentCard: {
		backgroundColor: "#FFF",
		marginRight: 16,
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: -1,
		border:"1px #E2E2E2 solid",
		[theme.breakpoints.down("md")]: {
			marginRight: 0,
            boxShadow: "none"
        }
	},
}));


export function GroupOverView(props: {group: Group}){
	const query = useQueryParams();
	const histoy = useHistory();
    const classes = useStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const [selectedTab, setSelectedTab ] = useState(query.get("tab") === "announcements" ? GroupTab.Announcements : GroupTab.People)

	const [showAddGroupDialog, setShowAddGroupDialog] = useState(false);

	useEffect(() => {
		query.get("tab") === "announcements" 
			? setSelectedTab(GroupTab.Announcements) 
			: setSelectedTab(GroupTab.People)
		},[query])

	const handleTabChange = (tab: GroupTab) => {
		tab === GroupTab.Announcements
		? histoy.push(routes.app.resolve.groupDetailPageAnnouncements(props.group.id))
		: histoy.push(routes.app.resolve.groupDetailPagePeople(props.group.id))
	}
		
    return (
		<>
		{mobileFormFactor
			? (
				<GroupOverViewMobile group={props.group} updateSelectedTab={(tab) => handleTabChange(tab)}  />
			)
			: (
				<Grid container style={{ position: "relative" }}>
					<Grid container style={{ display: "flex", height: 80 }} alignItems="flex-end">
						<Typography
							key={`1-${selectedTab}`}
							className={classNames(
								classes.pageTabs,
								selectedTab === GroupTab.People ? classes.activeTab : classes.hiddenTab 
								)}
								variant="h2"
								onClick={() => handleTabChange(GroupTab.People)}
								>
							People
						</Typography>
						<Typography
							key={`2-${selectedTab}`}
							className={classNames(
								classes.pageTabs,
								selectedTab === GroupTab.Announcements ? classes.activeTab : classes.hiddenTab 
								)}
								variant="h2"
								color="primary"
								onClick={() => handleTabChange(GroupTab.Announcements)}
								>
							Announcements
						</Typography>
					</Grid>
					<Grid className={classes.contentCard} item xs={12}>
						{selectedTab === GroupTab.People && <MemberTable members={props.group.groupMembers} selectedGroup={props.group} onInvitepeople={() => setShowAddGroupDialog(true)}/>}
						{selectedTab === GroupTab.Announcements && <GroupAnnouncementTable groupId={props.group.id} messages={props.group.messagesToGroup} />}
					</Grid>
				</Grid>
			)}
			<ResponseFab 
				onClick={() => setShowAddGroupDialog(true)}
				extendedButtonText={"+ Add to Group"} 
			/>
			<AddGroupMemberDialog selectedGroup={props.group} open={showAddGroupDialog} onClose={() => setShowAddGroupDialog(false)}/>
		</>
		)
	}