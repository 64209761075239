import { Theme, Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import CreateGroupDialog from "./Components/CreateGroupDialog";
import {GroupListingTable} from "./Components/GroupListingTable";
import { useGroupRouter } from "./GroupPage";
import { useGroups } from "../../providers/GroupProvider";
import {GroupListingTableMobile} from "./Components/GroupListingTableMobile";
import { isMobile } from "../../hooks/isMobile";
import OverlayInterrupt from '../../components/firstTimeOverlay';

const useGroupStyles = makeStyles((theme: Theme) => ({
	contentPaper: {
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: 20,
        [theme.breakpoints.down("md")]: {
            boxShadow: "none"
        }
	},
}))

interface GroupListPageProps {
    createGroup: boolean;
}

export function GroupListPage(props: GroupListPageProps){
    const groupContext = useGroups();
    const { groups } = groupContext;
    const groupRouter = useGroupRouter();
    const classes = useGroupStyles();
	const mobile = isMobile();
    const [showOverlay, setShowOverlay] = useState(groups.length === 0);
    const [showCreate, setShowCreate] = useState(props.createGroup);
    
    return (
        <>
            {showOverlay && <OverlayInterrupt onButtonClick={() => {setShowOverlay(false); setShowCreate(true)}} buttonText={"Create New Group"}/>}
			<div style={{ opacity: showOverlay ? 0.1 : 1 }}>
                <PageTitle title="Groups" />
                {!mobile && 
                    <>
                        <Typography variant="h1">
                            Groups
                        </Typography>
                        <Grid container>
                            <Paper className={classes.contentPaper}>
                                <GroupListingTable groups={groupContext.groups} onSelect={groupRouter.selectGroup} />
                            </Paper>
                        </Grid>
                    </>
                }
                {mobile && <GroupListingTableMobile groups={groupContext.groups} onSelect={groupRouter.selectGroup} />}
                <CreateGroupDialog open={showCreate} />
            </div>
        </>
    );
}