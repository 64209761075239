import React from "react";
import { Journey } from "../../entities/Journey";
import { FieldValidationError } from "../../services/server/ServerValidationError";
import { JourneySetupStep, JourneyStep } from "../../utillity/JourneySetupStepsHelper";

interface JourneySetupProgress {
	step: JourneySetupStep;
	steps: JourneySetupStep[];
	updateMode: boolean;
	addStepGoToStep: (steps: JourneySetupStep[], step: JourneyStep) => void;
	removeStepUpdateJourney: (steps: JourneySetupStep[], jounrey: Journey) => void;
	goToStep: (step: JourneyStep) => void;
	completeStep: (journey: Journey) => void;
	backStep?: (journey: Journey) => void;
	updateJourney: (journey: Journey) => void;

	disabled: boolean;
	setDisabled: (disabled: boolean) => void;

	fieldErrors: FieldValidationError[];
	setFieldErrors: (fieldErrors: FieldValidationError[]) => void;

	closeSetup: () => void;
}

export const JourneySetupProgressContext = React.createContext<JourneySetupProgress>({} as any);

export function useJourneySetupProgress() {
	return React.useContext(JourneySetupProgressContext);
}
