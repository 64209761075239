import { Grid, Typography, Button, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { routes } from "../../routes";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { ResponseDialogContent } from "../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { ResponseDialogActions } from "../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { VerticalCenter } from "../../components/VerticalCenter";

interface SelectCreateActionProps {
	onClose: () => void;
	onActionSelect: (action: "CreateGroup" | "SendMessage" | "AddPeople") => void;
}

export function SelectCreateAction(props: SelectCreateActionProps) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Grid container justify="space-between" spacing={2} style={{ padding: !mobileFormFactor ? 50 : undefined }}>
						<Grid item xs={12}>
							<Typography
								align="center"
								variant="body2"
								style={{ fontSize: 22, paddingBottom: 20 }}
							>
								What would you like to create?
							</Typography>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Button
								color="primary"
								className={classes.bigButton}
								onClick={() => props.onActionSelect("CreateGroup")}
								variant="contained"
								fullWidth
								startIcon={<AddIcon />}
							>
								Create Group
							</Button>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Button
								color="primary"
								className={classes.bigButton}
								onClick={() => props.onActionSelect("AddPeople")}
								variant="contained"
								fullWidth
								startIcon={<AddIcon />}
							>
								Add People
							</Button>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Button
								color="primary"
								className={classes.bigButton}
								variant="contained"
								onClick={() => {
									history.push(routes.app.journeyCreatePage);
									props.onClose();
								}}
								fullWidth
								startIcon={<AddIcon />}
							>
								Create Journey
							</Button>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Button
								color="primary"
								className={classes.bigButton}
								onClick={() => props.onActionSelect("SendMessage")}
								variant="contained"
								fullWidth
								startIcon={<AddIcon />}
							>
								Group Announcement
							</Button>
						</Grid>
					</Grid>
				</VerticalCenter>
			</ResponseDialogContent>
			<ResponseDialogActions onConfirm={() => {}} onBack={props.onClose} backButtonText="Cancel" hideNextStep={true} hideSecondaryButton={true}/>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	bigButton: { paddingLeft: 80, paddingRight: 80, paddingTop: 15, paddingBottom: 15, borderRadius: 14 },
}));
