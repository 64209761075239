import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { Auth } from "../../authorization/Auth";
import { LoadingPage } from "../../components/LoadingPage";
import { useUser } from "../../hooks/useUser";
import { routes } from "../../routes";
import AppLayout from "../AppLayout";
import { AppRoutes } from "./AppRoutes";
import { PublicRoutes } from "./PublicRoutes";

export function AppRouter() {
	const [user] = useUser();
	return (
		<BrowserRouter>
			<PublicRoutes>
				<Route path="/app">
					<AppLayout>
						<AppRoutes />
					</AppLayout>
				</Route>
				{!user && Auth.signedIn() && <ReloadBecauseUserIsJacked />}
				{!user && !Auth.signedIn() && (
					<Redirect
						to={routes.public.resolve.signInPage(
							window.location.pathname.includes("/app") ? window.location.pathname : undefined
						)}
					/>
				)}
				{user && <Redirect to={routes.app.dashBoardPage} />}
			</PublicRoutes>
		</BrowserRouter>
	);
}

function ReloadBecauseUserIsJacked() {
	useEffect(() => {
		window.location.reload();
	}, []);
	return <Route><LoadingPage /></Route>;
}
