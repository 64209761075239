import { Grid, SwipeableDrawer, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import { Journey, JourneyCategory, JourneyType } from "../../entities/Journey";
import { useAppUser } from "../../hooks/useAppUser";
import { PersonalizedMessage } from "../../utillity/Personalization";
import { useJourneyMessagePreviewStyles } from "./Steps/JourneyMessagePreviewStep";
import { useJourneyStepStyles } from "./Steps/JourneyStepLayout";

interface MobileMessagePreviewProps {
	journey: Journey;
	completionResponse: string;
	promptMessage?: string;
}

export function MobileMessagePreview(props: MobileMessagePreviewProps) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <MobileView {...props} />;
	}

	return <DesktopView {...props} />;
}

function MobileView(props: MobileMessagePreviewProps) {
	const classes = { ...useJourneyStepStyles(), ...useJourneyMessagePreviewStyles() };
	const theme = useTheme();
	const [user] = useAppUser();

	const { journey, promptMessage, completionResponse } = props;

	const [showMobilePreviewDrawer, setShowMobilePreviewDrawer] = useState(false);

	return (
		<>
			<div
				className={classes.mobilePreviewMessage}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setShowMobilePreviewDrawer(true);
				}}
			>
				<p className={classes.mobilePreviewMessageText}>PREVIEW</p>
			</div>
			<SwipeableDrawer
				anchor={"right"}
				open={showMobilePreviewDrawer}
				onClose={() => setShowMobilePreviewDrawer(false)}
				onOpen={() => setShowMobilePreviewDrawer(true)}
				onBackdropClick={() => setShowMobilePreviewDrawer(false)}
			>
				<div
					style={{
						display: "flex",
						paddingTop: 16,
						paddingLeft: 24,
						paddingRight: 24,
						color: theme.palette.secondaryResponse.main,
					}}
				>
					<Typography variant="h2">Message Preview</Typography>
					<div className={classes.grow} />
					<Cancel style={{ fontSize: 24 }} onClick={() => setShowMobilePreviewDrawer(false)} />
				</div>
				<div style={{ textAlign: "center", marginTop: 20 }}>
					<img
						style={{ width: window.innerWidth * 0.8, maxWidth: 400 }}
						src={"https://i.cdn-sc.com/Response/phoneoutline.png"}
						alt="Follow Up"
					/>
				</div>
				<div
					style={{
						position: "absolute",
						overflowY: "auto",
						overflowX: "hidden",
						top: window.innerWidth > 600 ? 210 : 185,
						bottom: 46,
						left: window.innerWidth > 600 ? 60 : window.innerWidth > 400 ? 50 : 35,
						right: window.innerWidth > 600 ? 60 : window.innerWidth > 400 ? 50 : 35,
					}}
				>
					<Grid container>
						<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
							<div
								style={{
									maxWidth: 160,
									color: "#FFF",
									backgroundColor: "rgb(0,156,255)",
									padding: 8,
									borderRadius: 10,
									borderBottomRightRadius: 0,
									overflowWrap: "anywhere",
								}}
							>
								{journey.keywordTrigger}
							</div>
						</Grid>
						{journey.category === JourneyCategory.Prompt && promptMessage && (
							<>
								<Grid item xs={12} style={{ marginBottom: 8 }}>
									<div
										style={{
											maxWidth: 160,
											backgroundColor: "rgb(227,228,229)",
											padding: 8,
											borderRadius: 10,
											borderBottomLeftRadius: 0,
											overflowWrap: "anywhere",
										}}
									>
										<PersonalizedMessage message={promptMessage} journey={journey} user={user} />
									</div>
								</Grid>
								<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
									<div
										style={{
											maxWidth: 160,
											color: "#FFF",
											backgroundColor: "rgb(0,156,255)",
											padding: 8,
											borderRadius: 10,
											borderBottomRightRadius: 0,
											overflowWrap: "anywhere",
										}}
									>
										<i>Example message:</i>{" "}
										{journey.type === JourneyType.Gather_Emails
											? "jsmith@myemail.com"
											: "Please pray for my cat's surgery tomorrow"}
									</div>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<div
								style={{
									maxWidth: 160,
									color: "#1C4057",
									backgroundColor: "rgb(227,228,229)",
									padding: 8,
									borderRadius: 10,
									borderBottomLeftRadius: 0,
									overflowWrap: "anywhere",
								}}
							>
								<PersonalizedMessage message={completionResponse} journey={journey} user={user} />
							</div>
						</Grid>
					</Grid>
				</div>
			</SwipeableDrawer>
		</>
	);
}

function DesktopView(props: MobileMessagePreviewProps) {
	const [user] = useAppUser();
	const { journey, promptMessage, completionResponse } = props;
	return (
		<Grid item xs={4} style={{ position: "relative" }}>
			<div style={{ textAlign: "center", minHeight: 493 }}>
				<img src={"https://i.cdn-sc.com/Response/phoneoutline.png"} alt="Follow Up" />
			</div>
			<div
				style={{
					position: "absolute",
					overflowY: "auto",
					overflowX: "hidden",
					top: 115,
					bottom: 46,
					left: 69,
					right: 72,
				}}
			>
				<Grid container>
					<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
						<div
							style={{
								maxWidth: 160,
								color: "#FFF",
								backgroundColor: "rgb(0,156,255)",
								padding: 8,
								borderRadius: 10,
								borderBottomRightRadius: 0,
								overflowWrap: "anywhere",
							}}
						>
							{journey.keywordTrigger}
						</div>
					</Grid>
					{journey.category === JourneyCategory.Prompt && promptMessage && (
						<>
							<Grid item xs={12} style={{ marginBottom: 8 }}>
								<div
									style={{
										maxWidth: 160,
										backgroundColor: "rgb(227,228,229)",
										padding: 8,
										borderRadius: 10,
										borderBottomLeftRadius: 0,
										overflowWrap: "anywhere",
									}}
								>
									<PersonalizedMessage message={promptMessage} journey={journey} user={user} />
								</div>
							</Grid>
							<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: 8 }}>
								<div
									style={{
										maxWidth: 160,
										color: "#FFF",
										backgroundColor: "rgb(0,156,255)",
										padding: 8,
										borderRadius: 10,
										borderBottomRightRadius: 0,
										overflowWrap: "anywhere",
									}}
								>
									<i>Example message:</i>{" "}
									{journey.type === JourneyType.Gather_Emails
										? "john.smith@myemail.com"
										: "Please pray for my cat's surgery tomorrow"}
								</div>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<div
							style={{
								maxWidth: 160,
								color: "#1C4057",
								backgroundColor: "rgb(227,228,229)",
								padding: 8,
								borderRadius: 10,
								borderBottomLeftRadius: 0,
								overflowWrap: "anywhere",
							}}
						>
							<PersonalizedMessage message={completionResponse} journey={journey} user={user} />
						</div>
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
}
