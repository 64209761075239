import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Answer, MultipleAnswer, RSVPAnswer, ShortAnswer, SignUpListAnswer, SingleAnswer } from "../../entities/Forms/Answer";
import { Question, QuestionType } from "../../entities/Forms/Question";
import { FormError } from "../../pages/Forms/FormResponder";
import { MultipleAnswerInput } from "./answers/MultipleAnsweInput";
import { RSVPInput } from "./answers/RSVPAnswerInput";
import { ShortAnswerInput } from "./answers/ShortAnswerInput";
import { SignupListAnswerInput } from "./answers/SignupListAnswerInput";
import { SingleAnswerInput } from "./answers/SingleAnswerInput";

interface AnswerInputProps {
	question: Question;
	answer?: Answer;
	onAnswerChanged: (answer: Answer) => void;
	onAnswerDeleted: (question: Question) => void;
	attendeeId: string;
	errors: FormError[];
}

export const useAnswerCommonstyles = makeStyles((theme: Theme) => ({
	answerContainer: {
		overflow: "hidden",
		backgroundColor: "#f5f6f7",
		borderRadius: 12,
		marginBottom: 14,
		width: "100%",
		padding: 16,
	},
	shortPrompAnswerContainer: {
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	promptQuestionShortAnswer: {
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	titleCloseIcon: {
		float: "right",
		marginTop: -8,
		marginRight: -8,
	},
	grow: {
		flex: 1,
	},
	closeIcon: {
		marginTop: -8,
		marginRight: -8,
	},
	questionPromptBase: {
		width: 240,
	},
	numberQuestion: {
		marginRight: 5,
		paddingTop: 10,
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
	},
	prompt: {
		fontSize: 16,
		color: theme.palette.secondaryResponse.main,
	},
	infoHeader: {
		fontSize: 14,
		fontStyle: "italic",
		opacity: 0.6,
		marginBottom: 2,
	},
	resetHeader: {
		fontSize: 14,
		color: theme.palette.error.main,
		opacity: 0.6,
		marginBottom: 2,
		cursor: "pointer",
		marginLeft: 4,
		textDecoration: "underline",
	},
	errorMessage: {
		fontSize: 14,
		color: theme.palette.error.main,
		opacity: 0.8,
		cursor: "pointer",
	},
	required: {
		fontSize: 12,
		opacity: 0.7,
		marginBottom: 2,
		marginLeft: 6,
	},
	radio: {
		"&$checked": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	checked: {},
	quantityDropDown: {
		marginLeft: 2,
		backgroundColor: "#e2eaf3",
		border: "none",
		width: 52,
		color: "rgba(0,0,0,.6)",
		fontSize: 16,
		borderRadius: 13,
		paddingLeft: 9,
		"&:before": {
			display: "none",
		},
		"&:after": {
			display: "none",
		},
	},
	inputBox: {
		width: "100%",
		marginTop: 8,
	},
}));

export function AnswerInput(props: AnswerInputProps) {
	const { question, answer } = props;
	switch (question.questionType) {
		case QuestionType.ShortAnswer:
			return (
				<ShortAnswerInput
					question={question}
					answer={answer as ShortAnswer}
					onAnswerChanged={props.onAnswerChanged}
					onAnswerDeleted={props.onAnswerDeleted}
					attendeeId={props.attendeeId}
					error={props.errors.filter((a) => a.questionId === question.id)}
				/>
			);
		case QuestionType.SingleAnswer:
			return (
				<SingleAnswerInput
					question={question}
					answer={answer as SingleAnswer}
					onAnswerChanged={props.onAnswerChanged}
					onAnswerDeleted={props.onAnswerDeleted}
					attendeeId={props.attendeeId}
					error={props.errors.filter((a) => a.questionId === question.id)}
				/>
			);
		case QuestionType.MultipleAnswer:
			return (
				<MultipleAnswerInput
					question={question}
					answer={answer as MultipleAnswer}
					onAnswerChanged={props.onAnswerChanged}
					onAnswerDeleted={props.onAnswerDeleted}
					attendeeId={props.attendeeId}
					error={props.errors.filter((a) => a.questionId === question.id)}
				/>
			);
		case QuestionType.SignupList:
			return (
				<SignupListAnswerInput
					question={question}
					answer={answer as SignUpListAnswer}
					onAnswerChanged={props.onAnswerChanged}
					onAnswerDeleted={props.onAnswerDeleted}
					attendeeId={props.attendeeId}
					error={props.errors.filter((a) => a.questionId === question.id)}
				/>
			);
		case QuestionType.RSVP:
			return (
				<RSVPInput
					question={question}
					answer={answer as RSVPAnswer}
					onAnswerChanged={props.onAnswerChanged}
					onAnswerDeleted={props.onAnswerDeleted}
					attendeeId={props.attendeeId}
					error={props.errors.filter((a) => a.questionId === question.id)}
				/>
			);
	}

	return null;
}
