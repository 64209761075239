import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Clock from "@material-ui/icons/AccessTime";
import LeftArrow from "@material-ui/icons/KeyboardArrowLeft";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import React, { useEffect, useState } from "react";
import PersonalizedMessageTextBox from "../../components/PersonalizedMessageTextBox";
import { Journey, JourneyCategory, JourneyType } from "../../entities/Journey";
import ScheduledMessage from "../../entities/ScheduledMessage";
import {
	AddDaysReturnDate,
	buildTimeSendDisplay,
	createNodaTimeLocalDateString as convertDateToNodaTimeLocalDateString,
	getLastSunday,
	getShortWeekDay,
	setDateTime,
	SubtractDaysReturnDate,
} from "../../functions/datetime";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import {
	CreateSermonPromptRequest,
	SermonPromptService,
	UpdateSermonPromptMessageRequest,
} from "../../services/SermonPromptService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationError } from "../../services/server/ServerValidationError";

export interface ActiveDay {
	id: number;
	message?: ScheduledMessage;
	todayDate: Date;
	unsavedChanges?: string;
	showUpcomingBoxPrior: boolean;
	inFuture: boolean;
}

export const useMessageStyles = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	dateSelector: {
		color: theme.palette.secondaryResponse.main,
		paddingTop: 12,
	},
	arrows: {
		color: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	dateColumn: {
		minWidth: 200,
	},
	contentColumn: {
		width: "100%",
	},
	statusColumn: {
		minWidth: 100,
	},
	statusBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	statusDot: {
		width: 12,
		height: 12,
		borderRadius: 6,
		marginTop: 12,
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		},
	},
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		padding: 8,
		height: 72,
		"& > textarea": {
			height: "100%",
		},
	},
	upcomingBar: {
		height: 40,
		marginTop: 15,
		marginBottom: 15,
		backgroundColor: "#f5f6f7",
		paddingTop: 10,
		marginRight: 60,
		marginLeft: 60,
		borderRadius: 20,
		[theme.breakpoints.down("sm")]: {
			marginRight: 15,
			marginLeft: 15,
		},
	},
	largeBlueDivider: {
		opacity: 0.3,
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
}));

const MessageTab = (props: {journey: Journey}) => {
	const { journey } = props;
	const classes = useMessageStyles();
	const setServerErrorAlert = useServerErrorAlert();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const setSuccessAlert = useSuccessAlert();
	const [user] = useAppUser();

	const [scheduledMessages, setScheduledMessages] = useState<ScheduledMessage[]>([]);
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([])

	const [activeDays, setActiveDays] = useState<ActiveDay[] | undefined>(undefined);

	const [unsavedActiveDayFocusChange, setActiveDayUnsavedFocusChange] = useState<ActiveDay | undefined>(undefined);
	const [askedToDeleteActiveDay, setAskedToDeleteActiveDay] = useState<ActiveDay | undefined>(undefined);

	const [disabled, setDisabled] = useState(true);

	const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>({
		start: getLastSunday(),
		end: AddDaysReturnDate(getLastSunday(), 6),
	});

	useEffect(() => {
		if (journey.type !== JourneyType.Sermon_Prompts) return;

		async function getScheduledMessages() {
			const result = await SermonPromptService.getSermonPrompts();
			if (ServerResult.isSuccess(result)) {
				setScheduledMessages(result.data);

				setDisabled(false);
			} else {
				setServerErrorAlert(result);
			}
		}
		getScheduledMessages();
	}, [journey, setServerErrorAlert]);

	useEffect(() => {
		setActiveDays(undefined);

		let days: ActiveDay[] = [];

		let loopDate = new Date(dateRange.start);
		let now = new Date();
		const showUpcomingBar = now.getTime() > dateRange.start.getTime() && now.getTime() < dateRange.end.getTime();
		let foundUpcomingDay = false;

		while (loopDate <= dateRange.end) {
			let dayNumber = loopDate.getDay();
			const loopDateStr = loopDate.toDateString();

			let scheduledMessage = scheduledMessages.find((a) => a.scheduledTime.toDateString() === loopDateStr);

			if (scheduledMessage) {
				const scheduledInFuture = scheduledMessage.scheduledTime.getTime() > now.getTime();
				days.push({
					id: scheduledMessage.scheduledTime.getTime(),
					todayDate: new Date(loopDate),
					message: scheduledMessage,
					showUpcomingBoxPrior: !showUpcomingBar || foundUpcomingDay ? false : scheduledInFuture,
					inFuture: scheduledInFuture,
				});

				foundUpcomingDay = scheduledInFuture;
			} else if (
				journey.category === JourneyCategory.Sermon_Prompts &&
				journey.promptMessageSchedule.daysToRun.includes(dayNumber)
			) {
				const timeToSend = setDateTime(loopDate, journey.promptMessageSchedule.timeToRun);
				const scheduledInFuture = timeToSend.getTime() > now.getTime();
				days.push({
					id: loopDate.getTime(),
					todayDate: new Date(loopDate),
					message: undefined,
					showUpcomingBoxPrior: !showUpcomingBar || foundUpcomingDay ? false : scheduledInFuture,
					inFuture: scheduledInFuture,
				});

				foundUpcomingDay = scheduledInFuture;
			}

			loopDate = AddDaysReturnDate(loopDate, 1);
		}

		setActiveDays(days);
	}, [scheduledMessages, dateRange, journey]);

	const buildDateRangeDisplay = () => {
		return `${dateRange.start.toLocaleString("en-us", {
			month: "short",
		})} ${dateRange.start.getDate()} - ${dateRange.end.toLocaleString("en-us", {
			month: "short",
		})} ${dateRange.end.getDate()}`;
	};

	const updateActiveDayItem = (activeDay: ActiveDay, value: string) => {
		if (!activeDays) {
			return;
		}

		setActiveDays(activeDays.map((x) => (x.id === activeDay.id ? { ...x, unsavedChanges: value } : x)));
		setFieldErrors([]);
	};

	const handleBlur = (activeDay: ActiveDay) => {
		if (activeDay.unsavedChanges && !disabled) {
			setActiveDayUnsavedFocusChange(activeDay);
		}
	};

	const buildContentBox = (activeDay: ActiveDay) => {
		return (
			<>
				<div style={{ display: "flex", width: "100%" }}>
					<PersonalizedMessageTextBox
						journey={journey}
						message={
							activeDay.unsavedChanges !== undefined
								? activeDay.unsavedChanges
								: activeDay.message
								? activeDay.message.message
								: activeDay.inFuture
								? ""
								: "No Message"
						}
						user={user}
						onChange={(activeDay, value) => updateActiveDayItem(activeDay, value)}
						onBlur={(activeDay) => handleBlur(activeDay)}
						activeDay={activeDay}
						onDelete={(activeDay) => setAskedToDeleteActiveDay(activeDay)}
						showAdornment={mobileFormFactor}
						fieldErrors={fieldErrors}
					/>
					{!mobileFormFactor && (
						<>
							{activeDay.unsavedChanges !== undefined ? (
								<ButtonGroup
									orientation="vertical"
									aria-label="vertical outlined button group"
									variant="text"
									style={{ minWidth: 64, marginLeft: -1 }}
								>
									<Button
										onMouseDown={(e) => e.preventDefault()}
										onClick={() => saveMessage(activeDay)}
										style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
										variant="outlined"
										color="primary"
									>
										Save
									</Button>
									<Button
										onMouseDown={(e) => e.preventDefault()}
										onClick={() => resetActiveDaysMessages(activeDay)}
										style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
										variant="outlined"
										color="default"
									>
										Cancel
									</Button>{" "}
								</ButtonGroup>
							) : activeDay.message && activeDay.inFuture ? (
								<ButtonGroup
									orientation="vertical"
									aria-label="vertical outlined button group"
									variant="text"
									style={{ minWidth: 64, marginLeft: -1, height: "100%" }}
								>
									<Button
										onMouseDown={(e) => e.preventDefault()}
										onClick={() => setAskedToDeleteActiveDay(activeDay)}
										style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, color: theme.palette.error.main, borderColor: theme.palette.error.main}}
										variant="outlined"
									>
										Delete
									</Button>
								</ButtonGroup>
							) : undefined}
						</>
					)}
				</div>
				{mobileFormFactor && (
					<div style={{ display: "flex", width: "100%", marginTop: 6 }}>
						{activeDay.unsavedChanges !== undefined && (
							<div style={{ display: "flex", width: "100%" }}>
								<div className={classes.grow} />
								<Button
									onMouseDown={(e) => e.preventDefault()}
									onClick={() => resetActiveDaysMessages(activeDay)}
									style={{ marginRight: 8 }}
									variant="outlined"
									color="default"
								>
									Cancel
								</Button>
								<Button
									onMouseDown={(e) => e.preventDefault()}
									onClick={() => saveMessage(activeDay)}
									variant="outlined"
									color="primary"
								>
									Save
								</Button>
							</div>
						)}
					</div>
				)}
			</>
		);
	};

	const buildStatusBox = (activeDay: ActiveDay) => {
		let color: string = theme.palette.primaryResponse.main;
		let status: string = "Unknown";

		if (!activeDay.message) {
			color = "#FF0000";
			status = "No Message";
		} else if (activeDay.message.timeSent) {
			color = theme.palette.primaryResponse.main;
			status = "Sent";
		} else {
			color = "#FFFF00";
			status = "Scheduled";
		}

		return (
			<div className={classes.statusBox}>
				<div className={classes.statusDot} style={{ backgroundColor: color }} />
				<div>
					<Typography variant="body1">{status}</Typography>
				</div>
			</div>
		);
	};

	const onCloseUnsavedDialog = () => {
		if (!activeDays || !unsavedActiveDayFocusChange) {
			return;
		}

		setActiveDays(activeDays.map((x) => (x.id === unsavedActiveDayFocusChange.id ? { ...x, unsavedChanges: undefined } : x)));
		setActiveDayUnsavedFocusChange(undefined);
		setFieldErrors([])
	};

	const resetActiveDaysMessages = (activeDay: ActiveDay) => {
		if (!activeDays) {
			return;
		}
		setFieldErrors([])
		setActiveDays(activeDays.map((x) => (x.id === activeDay.id ? { ...x, unsavedChanges: undefined } : x)));
	};

	const saveMessage = async (activeDay: ActiveDay) => {
		setFieldErrors([])
		if (activeDay.unsavedChanges === undefined) {
			return;
		}

		if (activeDay.message) {
			await updateMessage(activeDay);
		} else {
			await createMessage(activeDay);
		}
	};

	const createMessage = async (activeDay: ActiveDay) => {
		let message: CreateSermonPromptRequest = {
			date: convertDateToNodaTimeLocalDateString(activeDay.todayDate),
			message: activeDay.unsavedChanges!,
		};

		setDisabled(true);
		const result = await SermonPromptService.create(message);

		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setScheduledMessages([...scheduledMessages, result.data]);
			setActiveDayUnsavedFocusChange(undefined);
			setSuccessAlert("Message created");
		} else if (ServerResult.isValidationError(result)) {
			setFieldErrors(result.errors);
		} else {
			setServerErrorAlert(result);
		}
	};

	const updateMessage = async (activeDay: ActiveDay) => {
		let message: UpdateSermonPromptMessageRequest = {
			id: activeDay.message!.id,
			message: activeDay.unsavedChanges!,
		};

		const result = await SermonPromptService.setMessage(message);

		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setActiveDayUnsavedFocusChange(undefined);
			setScheduledMessages(scheduledMessages.map((x) => (x.id === result.data.id ? result.data : x)));
			setSuccessAlert("Message updated.");
		} else if (ServerResult.isValidationError(result)) {
			setFieldErrors(result.errors);
		} else {
			setServerErrorAlert(result);
		}
	};

	const deleteActiveDaysMessage = async () => {
		if (askedToDeleteActiveDay === undefined || askedToDeleteActiveDay.message === undefined) {
			return;
		}

		const id = askedToDeleteActiveDay.message.id;
		const result = await SermonPromptService.cancel(askedToDeleteActiveDay.message.id);

		setDisabled(false);
		setAskedToDeleteActiveDay(undefined);

		if (ServerResult.isSuccess(result)) {
			setScheduledMessages(scheduledMessages.filter((a) => a.id !== id));
			setSuccessAlert("Message has been removed.");
		} else if (ServerResult.isValidationError(result)) {
			setFieldErrors(result.errors);
		} else {
			setServerErrorAlert(result);
		}
	};

	const renderDesktopListing = () => {
		return (
			<>
				<Grid item xs={12} style={{ display: "flex", paddingBottom: 6 }}>
					<div className={classes.dateColumn}>
						<Typography variant="h3">Date</Typography>
					</div>
					<div className={classes.contentColumn}>
						<Typography variant="h3">Message Content</Typography>
					</div>
					<div>
						<Typography variant="h3" style={{ marginRight: 25 }}>
							Status
						</Typography>
					</div>
				</Grid>
				{activeDays === undefined ? (
					<Grid item xs={12}>
						<div style={{ textAlign: "center", paddingTop: 40 }}>
							<CircularProgress style={{ color: theme.palette.secondaryResponse.main }} />
						</div>
					</Grid>
				) : (
					activeDays.map((activeDay: ActiveDay, index: number) => {
						return (
							<Grid item xs={12} key={index}>
								{activeDay.showUpcomingBoxPrior && (
									<div style={{ textAlign: "center" }}>
										<div className={classes.upcomingBar}>
											<Typography
												style={{ color: theme.palette.secondaryResponse.main, fontSize: 16 }}
												variant="h2"
											>
												Upcoming
											</Typography>
										</div>
									</div>
								)}

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginBottom: 16,
										minHeight: 76,
										borderTop: "1px solid rgb(0,0,0,.23)",
										paddingTop: 16,
									}}
								>
									<div className={classes.dateColumn}>
										<Typography variant="h3">
											{`${getShortWeekDay(
												activeDay.todayDate.getDay()
											)}, ${activeDay.todayDate.toLocaleString("en-us", {
												month: "short",
											})} ${activeDay.todayDate.getDate()}`}
										</Typography>
									</div>
									<div className={classes.contentColumn}>{buildContentBox(activeDay)}</div>
									<div className={classes.statusColumn}>{buildStatusBox(activeDay)}</div>
								</div>
							</Grid>
						);
					})
				)}
			</>
		);
	};

	const renderMobileListing = () => {
		return (
			<>
				{!activeDays ? (
					<Grid item xs={12}>
						<div style={{ textAlign: "center", paddingTop: 40 }}>
							<CircularProgress style={{ color: theme.palette.secondaryResponse.main }} />
						</div>
					</Grid>
				) : (
					activeDays.map((activeDay: ActiveDay, index: number) => {
						return (
							<Grid item xs={12} key={index}>
								{activeDay.showUpcomingBoxPrior && (
									<>
										<hr className={classes.largeBlueDivider} />
										<div style={{ textAlign: "center" }}>
											<div className={classes.upcomingBar}>
												<Typography
													style={{ color: theme.palette.secondaryResponse.main, fontSize: 16 }}
													variant="h2"
												>
													Upcoming
												</Typography>
											</div>
										</div>
									</>
								)}

								<hr className={classes.largeBlueDivider} />
								<div style={{ display: "flex", flexDirection: "row", paddingTop: 12, paddingBottom: 12 }}>
									<Clock style={{ marginRight: 4, color: theme.palette.primaryResponse.main }} />

									<Typography variant="h4" style={{ marginTop: 2 }}>
										{`${getShortWeekDay(activeDay.todayDate.getDay())}, ${activeDay.todayDate.toLocaleString(
											"en-us",
											{
												month: "short",
											}
										)} ${activeDay.todayDate.getDate()}`}
									</Typography>
									<div className={classes.grow} />
									{buildStatusBox(activeDay)}
								</div>
								<div style={{ paddingTop: 12, paddingBottom: 12 }}>{buildContentBox(activeDay)}</div>
							</Grid>
						);
					})
				)}
			</>
		);
	};

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid item xs={12} style={{ textAlign: "center", marginTop: 12 }}>
					<FormControl style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
						<IconButton
							className={classes.arrows}
							onClick={() =>
								setDateRange({
									start: SubtractDaysReturnDate(dateRange.start, 7),
									end: SubtractDaysReturnDate(dateRange.end, 7),
								})
							}
						>
							<LeftArrow />
						</IconButton>
						<Typography className={classes.dateSelector} variant="h2">
							{buildDateRangeDisplay()}
						</Typography>
						<IconButton
							className={classes.arrows}
							onClick={() =>
								setDateRange({
									start: AddDaysReturnDate(dateRange.start, 7),
									end: AddDaysReturnDate(dateRange.end, 7),
								})
							}
						>
							<RightArrow />
						</IconButton>
					</FormControl>
					{journey.category === JourneyCategory.Sermon_Prompts && (
						<>
							<FormControl
								style={{
									display: "flex",
									flexDirection: "row",
									marginBottom: 14,
									justifyContent: "center",
								}}
							>
								<Typography
									className={classes.dateSelector}
									style={{ marginTop: -8, paddingTop: 0, fontWeight: 600, fontSize: 16, marginBottom: 8 }}
									variant="h3"
								>
									Scheduled: {buildTimeSendDisplay(journey.promptMessageSchedule.timeToRun)} (
									{user.tzdbTimeZone})
								</Typography>
							</FormControl>
						</>
					)}
				</Grid>
				{!mobileFormFactor && renderDesktopListing()}
				{mobileFormFactor && renderMobileListing()}
			</Grid>
			<Dialog
				open={unsavedActiveDayFocusChange !== undefined}
				onClose={onCloseUnsavedDialog}
				disableBackdropClick
				fullScreen={mobileFormFactor}
			>
				<DialogTitle id="cancel-sub-title">Unsaved Changes</DialogTitle>
				<DialogContent>You have unsaved changes, did you want to save?</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="default" size="medium" onClick={onCloseUnsavedDialog} disabled={disabled}>
						No, delete changes
					</Button>
					<Button
						variant="outlined"
						color="primary"
						size="medium"
						disabled={disabled}
						onClick={() => saveMessage(unsavedActiveDayFocusChange!)}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={askedToDeleteActiveDay !== undefined}
				onClose={() => setAskedToDeleteActiveDay(undefined)}
				disableBackdropClick
				fullScreen={mobileFormFactor}
			>
				<DialogTitle id="cancel-sub-title">Delete Message</DialogTitle>
				<DialogContent>Are you sure you want to delete this message?</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="default"
						size="medium"
						onClick={() => setAskedToDeleteActiveDay(undefined)}
						disabled={disabled}
					>
						Cancel
					</Button>
					<Button
						variant="outlined"
						color="primary"
						size="medium"
						disabled={disabled}
						onClick={deleteActiveDaysMessage}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default MessageTab;
