import { makeStyles, Theme, Typography } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowForwardIos";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import JourneySetup, { CreateJourneyType } from "../../components/JourneySetup/journeySetUp";
import { VerticalCenter } from "../../components/VerticalCenter";
import { useJourneyNotifications } from "../../hooks/useJourneyNotifications";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useJourneys } from "../../providers/JourneyProvider";
import { routes } from "../../routes";

export const useCreateJourneyClasses = makeStyles((theme: Theme) => ({
	selection: {
		width: 460,
		padding: "0 45px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			padding: "0 15px",
		},
	},
	tile: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		width: "100%",
		height: 70,
		display: "flex",
		padding: 12,
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		marginBottom: 16,
		cursor: "pointer",
	},
	iconBox: {
		width: 54,
		paddingTop: 10,
		paddingLeft: 7,
	},
	seperator: {
		height: 34,
		marginTop: 7,
		width: 2,
		backgroundColor: "rgba(0,0,0, .1)",
	},
	tileText: {
		paddingTop: 15,
		paddingLeft: 8,
		flex: 1,
		whiteSpace: "nowrap",
	},
	arrowBox: {
		width: 30,
		paddingTop: 11,
		color: "#000",
		opacity: 0.3,
	},
}));

export function CreateJourney() {
	const history = useHistory();
	const classes = useCreateJourneyClasses();
	const notifications = useJourneyNotifications();
	const journeyContext = useJourneys();
	const [, setPageTitle] = usePageTitle();
	setPageTitle("Create Journey");

	const [creationType, setCreationType] = useState<CreateJourneyType | undefined>(undefined);

	return (
		<VerticalCenter>
			{creationType !== undefined && (
				<JourneySetup
					mode="create"
					type={creationType}
					onClose={() => setCreationType(undefined)}
					onCreate={(journey) => {
						setCreationType(undefined);
						journeyContext.addJourney(journey);
						notifications.subcribeToJourneyNotifications(journey);
						history.push(routes.app.resolve.journeyDetailPage(journey.category, journey.id));
					}}
				/>
			)}
			<div className={classes.selection}>
				<Typography style={{ fontSize: 16, opacity: 0.6, color: "#000", marginBottom: 24 }} variant="h3">
					What would you like to create?
				</Typography>
				<CreateJourneyCard type={CreateJourneyType.Volunteer_Sign_Up} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Event_Sign_Up} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Group_Sign_Up} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Gather_Emails} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Survey_Your_Church} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Share_A_Link} onSelect={setCreationType} />
				<CreateJourneyCard type={CreateJourneyType.Custom} onSelect={setCreationType} />
			</div>
		</VerticalCenter>
	);
}

const CreateJourneyCard = (props: { type: CreateJourneyType; onSelect: (type: CreateJourneyType) => void }) => {
	const classes = useCreateJourneyClasses();
	let iconPath: string | undefined = undefined;
	let createType: CreateJourneyType | undefined = undefined;
	let title = "";

	switch (props.type) {
		case CreateJourneyType.Volunteer_Sign_Up:
			iconPath = "https://i.cdn-sc.com/Response/icons/volunteer-icon.svg";
			createType = CreateJourneyType.Volunteer_Sign_Up;
			title = "Volunteer Sign-up";
			break;
		case CreateJourneyType.Event_Sign_Up:
			iconPath = "https://i.cdn-sc.com/Response/icons/event-icon.svg";
			createType = CreateJourneyType.Event_Sign_Up;
			title = "Event Sign-up";
			break;
		case CreateJourneyType.Group_Sign_Up:
			iconPath = "https://i.cdn-sc.com/Response/icons/group-icon.svg";
			createType = CreateJourneyType.Group_Sign_Up;
			title = "Group Sign-up";
			break;
		case CreateJourneyType.Gather_Emails:
			iconPath = "https://i.cdn-sc.com/Response/icons/emails-icon.svg";
			createType = CreateJourneyType.Gather_Emails;
			title = "Gather Emails";
			break;
		case CreateJourneyType.Survey_Your_Church:
			iconPath = "https://i.cdn-sc.com/Response/icons/ask-church-icon.svg";
			createType = CreateJourneyType.Survey_Your_Church;
			title = "Survey your church";
			break;
		case CreateJourneyType.Share_A_Link:
			iconPath = "https://i.cdn-sc.com/Response/icons/info-icon.svg";
			createType = CreateJourneyType.Share_A_Link;
			title = "Share a link";
			break;
		case CreateJourneyType.Custom:
			createType = CreateJourneyType.Custom;
			title = "Custom";
			break;
		default:
			break;
	}

	if (createType === undefined) {
		return null;
	}

	return (
		<div className={classes.tile} onClick={() => props.onSelect(props.type)}>
			<div className={classes.iconBox}>
				{iconPath !== undefined && <img style={{ maxWidth: 35 }} alt="icon" src={iconPath} />}
			</div>
			<div className={classes.seperator} style={{ display: iconPath !== undefined ? "inline" : "none" }} />
			<div className={classes.tileText}>
				<Typography variant="h3">{title}</Typography>
			</div>
			<div className={classes.arrowBox}>
				<ArrowRight />
			</div>
		</div>
	);
};
