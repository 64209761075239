import { makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import TodoStepDot from "@material-ui/icons/Brightness1";
import CompletedStepDot from "@material-ui/icons/CheckCircle";
import { useDialogSteps } from "./CurrentDialogStep";

const useStyles = makeStyles((theme: Theme) => ({
	stepDotLabels: {
		color: theme.palette.primaryResponse.main,
		fontWeight: "bold",
		marginRight: 6,
	},
	upcomingStepDotLabels: {
		opacity: 0.5,
	},
	finishedStepDotLabels: {
		"&:hover": {
			cursor: 'pointer'
		},
	},
	currentStepDot: {
		height: 18,
		width: 18,
		color: theme.palette.primaryResponse.main,
		marginRight: 6,
	},
	upcomingDot: {
		height: 18,
		width: 18,
		color: theme.palette.primaryResponse.main,
		opacity: 0.5,
		marginRight: 6,
	},
	finishedDot: {
		height: 18,
		width: 18,
		color: theme.palette.primaryResponse.main,
		marginRight: 6,
		"&:hover": {
			cursor: 'pointer'
		},
	},
	progressConnector: {
		backgroundColor: theme.palette.primaryResponse.main,
		height: 3,
		width: 120,
		marginRight: 6,
		marginTop: 8,
	},
	fadeIt: {
		opacity: 0.3,
	},
}));


export function MultiStepDialogProgress() {
	const classes = useStyles();
	const dialogContext = useDialogSteps();
	const { stepNumber, steps, goToStep } = dialogContext;

	const progressSteps = steps.map((step, i) => {
		const indexOfCurrentStep = steps.findIndex((allStep) => allStep.number === stepNumber);
		const indexOfThisStep = steps.findIndex((allStep) => allStep.number === step.number);

		const complete = indexOfThisStep < indexOfCurrentStep;
		const currentStep = indexOfThisStep === indexOfCurrentStep;
		const isBeforeLastStep = i < steps.length - 1;

		return (
			<React.Fragment key={i}>
				<ProgressStep complete={complete} name={step.name} currentStep={currentStep} handleClick={() => { complete && !currentStep && goToStep(step.number)}}/>
				{isBeforeLastStep && (
					<span
						className={classNames(classes.progressConnector, !complete && classes.fadeIt)}
						style={{ width: steps.length > 5 ? 80 : 120 }}
					/>
				)}
			</React.Fragment>
		);
	});

	return <div style={{ display: "inline-flex" }}>{progressSteps}</div>;
}

function ProgressStep(props: { name: string; complete: boolean; currentStep: boolean, handleClick:() => void}) {
	const classes = useStyles();
	const stepDot = () => {
		if (props.complete) return <CompletedStepDot className={classes.finishedDot} onClick={props.handleClick}/>;
		return <TodoStepDot className={classNames(classes.currentStepDot, !props.currentStep && classes.fadeIt)} onClick={props.handleClick}/>;
	};
	return (
		<>
			{stepDot()}
			<Typography
				onClick={props.handleClick}
				className={classNames(
					classes.stepDotLabels,
					props.complete ? classes.finishedStepDotLabels : undefined,
					props.complete || props.currentStep ? undefined : classes.upcomingStepDotLabels
				)}
				variant="subtitle2"
			>
				{props.name}
			</Typography>{" "}
		</>
	);
}
