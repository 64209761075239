import { FormHelperText, makeStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";

const codeFormatRegex = new RegExp(/^[0-9]{6}/);
const placeHolderArray = new Array<string>(6).fill(".")
const clearIndex = -1;

export function VerificationCodeInput(props:{ onInputChange:(value:string) => void; hasError:boolean; disabled:boolean }) {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState<string[]>(placeHolderArray)
    const [activeBoxIndex, setActiveBoxIndex] = useState<number>(clearIndex)

    const inputRef = useRef<HTMLInputElement>(null)
    const inputBoxesRef = useMemo(() => new Array(6).fill(null).map(() => React.createRef<HTMLDivElement>()), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.onInputChange(inputValue.join("")), [inputValue])
    
    useEffect(() => {
        if(inputBoxesRef[0].current)
            inputBoxesRef[0].current?.focus()
    }, [inputBoxesRef])

    useEffect(() => {
        const currentInput = inputRef.current;

        if(!currentInput)
            return

        const onPaste = (e: ClipboardEvent) => {
            e.preventDefault();
    
            const pastedString = e.clipboardData?.getData('text');
            if (!pastedString) 
                return;

            const digits = pastedString.replace(/\D/g, "")
    
            const isNumber = !isNaN(Number(digits));
            if (isNumber)
                setInputValue(digits.split(''));
        };
    
        currentInput.addEventListener('paste', onPaste);
        return () => currentInput.removeEventListener('paste', onPaste);
    }, [inputRef]);

    const getItem = (index: number) => inputBoxesRef[index]?.current;
    const focusItem = (index: number):void => getItem(index)?.focus();
    const blurItem = (index: number):void => getItem(index)?.blur();
  
    const onItemFocus = (index: number) => {
        setActiveBoxIndex(index);
        if (inputRef.current)
            inputRef.current.focus();
    };

    const onInputBlur = () => {
        if (activeBoxIndex)
            blurItem(activeBoxIndex);
    
        setActiveBoxIndex(clearIndex);
    };

    const handleMobileAutoComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tokenValue = e.target.value.replace(/\D/g, "");

        if(codeFormatRegex.test(tokenValue))
            setInputValue(tokenValue.split(''));
        if(activeBoxIndex)
            blurItem(activeBoxIndex)
    };

    const handleKeyInput = ({ key }: React.KeyboardEvent) => {
        const nextIndex = activeBoxIndex + 1;
        const prevIndex = activeBoxIndex - 1;
    
        const currentInput = inputRef.current;
        const currentItem = getItem(activeBoxIndex);
    
        const isLast = nextIndex === 6;
        const isDeleting = key === "Delete" || key === "Backspace";
    
        onItemFocus(activeBoxIndex);
    
        if(isDeleting) {
            const updatedValue = inputValue.map((v,i) => i === activeBoxIndex ? "." : v );
            setInputValue(updatedValue);
    
            if(activeBoxIndex !== 0) {
                setActiveBoxIndex(prevIndex);
                focusItem(prevIndex);
            }
    
            return;
        }
    
        if (isNaN(Number(key)))
            return;
    
        const updatedValue = inputValue.map((v,i) => i === activeBoxIndex ? key : v );
        setInputValue(updatedValue);
    
        if (!isLast) {
            setActiveBoxIndex(nextIndex);
            focusItem(nextIndex);
            return;
        }
    
        if (currentInput) 
            currentInput.blur();
        if (currentItem) 
            currentItem.blur();
    
        setActiveBoxIndex(-1);
    };

    const renderBoxes = (ref:React.RefObject<HTMLDivElement>, index:number) => {
        return (
            <Grid 
                item 
                key={index}
                ref={ref}
                role="button"
                tabIndex={0}
                className={classNames(
                    classes.characterBoxes,
                    index === activeBoxIndex && classes.activeBox,
                    (index === 0 || index === 3) && classes.beginningBox,
                    (index === 2 || index === 5) && classes.beginningEnding,)}
                onFocus={() => onItemFocus(index)}
            >
                {inputValue[index] !== "." && inputValue[index] }
            </Grid>
        )
    }
      
    return (
        <>
            <Grid container alignItems="center" justify="center">
                <input
                    disabled={props.disabled}
                    ref={inputRef} 
                    autoComplete='verification-code'
                    type='text'
                    onChange={handleMobileAutoComplete}
                    onKeyUp={handleKeyInput}
                    onBlur={onInputBlur}
                    className={classes.inputBox}
                    value={inputValue.join("")}
                    maxLength={6}
                    inputMode="tel"
                />
                {inputBoxesRef.slice(0,3).map(renderBoxes)}
                <Grid item className={classes.seporator}>
                    -
                </Grid>
                {inputBoxesRef.slice(3).map((ref, index) => renderBoxes(ref, index + 3))}
            </Grid>
            { props.hasError &&
                <FormHelperText error={props.hasError} style={{textAlign: "center"}}>
                    Invalid Code
                </FormHelperText>
            }
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    seporator: {
        marginRight: 2,
        marginLeft: 2,
        textAlign: "center",
        outline: "none",
        borderRadius: 3,
        height: 40,
        width: 40,
        fontSize: 25,
        zIndex: 1
    },
    characterBoxes: {
        textAlign: "center",
        border: "solid 1px #ccc",
        outline: "none",
        height: 40,
        width: 40,
        fontSize: 25,
        zIndex: 1
    },
    beginningBox: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    beginningEnding: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    activeBox: {
        boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}`
    },
    inputBox: {
        '&:focus-visible': {
            outline: "none"
        },
        opacity: 0,
        height: 0,
        width: 0,
        zIndex: -10000000,
        border: "none"
    }
}))