import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import LoadingSpinner from "../../components/loaderSpinner";
import { PageTitle } from "../../components/PageTitle";
import { PhoneMask } from "../../components/PhoneMask";
import Church from "../../entities/Church";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { ChurchService, UpdateChurchRequest } from "../../services/ChurchService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import TabHeaderBar, { SettingsTab } from "./TabHeaderBar";

const ChurchInfo = () => {
	const setServerErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
	const [isEnabled, setEnabled] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const [churchPhoneNumber, setChurchPhoneNumber] = useState<string | undefined>();
	const [churchPhoneNumberError, setChurchPhoneNumberError] = useState<string | undefined>();

	const [church, setChurch] = useState<Church>();

	React.useEffect(() => {
		const fetch = async () => {
			const result = await ChurchService.getActiveChurch();
			setIsLoading(false);

			if (ServerResult.isSuccess<Church>(result)) {
				const { phoneNumber } = result.data;
				setChurchPhoneNumber(phoneNumber ? phoneNumber.replace("+1", "") : "");
				setChurch(result.data);
			} else {
				setServerErrorAlert(result);
			}
		};
		fetch();
	}, [setServerErrorAlert]);


	const onChangeChurchPhoneNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setChurchPhoneNumberError(undefined);
		setChurchPhoneNumber(event.target.value);
	};

	const onSubmitUpdateChurchInfo = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const request: UpdateChurchRequest = {
			phoneNumber: `+1${churchPhoneNumber?.replace(/\D/g, "")}`,
		};

		setEnabled(false);
		let result = await ChurchService.updateChurch(request);
		setEnabled(true);

		if (ServerResult.isSuccess<Church>(result)) {
			const { phoneNumber } = result.data;
			setChurchPhoneNumber(phoneNumber ? phoneNumber.replace("+1", "") : "");
			setSuccessAlert("Church info updated");
		} else if (ServerResult.isValidationError(result)) {
			setChurchPhoneNumberError(FieldValidationErrorUtils.getFieldError(result.errors, "PhoneNumber"));
		} else if (ServerResult.isError(result)) {
			setServerErrorAlert(result);
		}
	};

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (isLoading) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={isLoading} durationMessage={"Gather Account Info..."} />
			</div>
		);
	}

	return (
		<Grid container spacing={2}>
			<PageTitle title="Church Info" />
			{!mobileFormFactor && (
				<>
					<Grid item>
						<Typography variant="h1">Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<TabHeaderBar tab={SettingsTab.Church_Info} />
					</Grid>
				</>
			)}
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Update Church Info" style={{ paddingBottom: 0 }} />
					<form onSubmit={onSubmitUpdateChurchInfo}>
						<CardContent>
							<TextField
								variant="outlined"
								disabled={true}
								label="Church Name"
								margin="normal"
								fullWidth
								type="text"
								value={church?.name ?? null}
							/>
							<TextField
								variant="outlined"
								disabled={!isEnabled}
								value={churchPhoneNumber}
								label="Church Phone Number"
								onChange={onChangeChurchPhoneNumber}
								margin="normal"
								fullWidth
								required
								onFocus={(e) => {
									e.preventDefault();
									const { target } = e;
									target.focus();
									target.setSelectionRange(1, 2);
								}}
								InputProps={{
									inputComponent: PhoneMask,
								}}
								error={churchPhoneNumberError != null}
								helperText={churchPhoneNumberError}
							/>
							<TextField
								variant="outlined"
								disabled={true}
								value={church?.responsePhoneNumber?.replace("+1", "") ?? null}
								label="Response Phone Number"
								margin="normal"
								fullWidth
								InputProps={{
									inputComponent: PhoneMask,
								}}
							/>
							<TextField
								variant="outlined"
								disabled={true}
								value={church?.tzdbTimeZone ?? null}
								margin="normal"
								label="Timezone"
								fullWidth
							/>
						</CardContent>

						<CardActions>
							<Button
								disabled={!isEnabled}
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ marginLeft: 10 }}
							>
								Update
							</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
};

export default ChurchInfo;
