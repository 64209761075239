import { Button, Grid, IconButton, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useJourneySetupProgress } from "../useJourneySetupProgress";

export const useJourneyStepStyles = makeStyles((theme: Theme) => ({
	progressLabelText: {
		color: "#404448",
		fontSize: 17,
		[theme.breakpoints.down("sm")]: {
			fontSize: 15,
		},
	},

	progressLabelLighterText: {
		color: "#404448",
		fontSize: 15,
	},
}));

const useDesktop = makeStyles((theme: Theme) => ({
	titlebar: {
		width: "100%",
		height: 54,
		backgroundColor: theme.palette.secondaryResponse.main,
		display: "flex",
		alignItems: "center",
	},
	progressTracker: {
		height: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	progressPage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	progressActions: {
		display: "inline-flex",
	},
}));

const useMobile = makeStyles((theme: Theme) => ({
	swoopBox: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.secondaryResponse.main,
		zIndex: 2000,
		top: 0,
	},
	swoopBoxGreen: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.primaryResponse.main,
		top: 40,
		boxShadow: "0 8px 6px -6px #C7BAB9",
	},
	headerBar: {
		position: "fixed",
		zIndex: 2001,
		width: "100%",
		display: "flex",
		alignItems: "center",
	},
	setupContent: {
		position: "fixed",
		padding: "16px 0 6px 0",
		zIndex: 2001,
		top: 64,
		bottom: 0,
		left: 16,
		right: 16,
		borderRadius: "12px 12px 0 0",
	},
}));

const useCommon = makeStyles((theme: Theme) => ({
	titlebartitle: {
		color: "#FFF",
		width: "100%",
	},
	titleCloseIcon: {
		float: "right",
	},
	grow: {
		flex: 1,
	},
	actionButtons: {
		borderRadius: 8,
		height: 36,
		margin: 12,
		marginBottom: 24,
	},
	backButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
	nextButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
	},
}));

interface AddMessageExtraLayoutProps {
	title: string;
	onSave: () => void;
	onCancel: () => void;
}

export function AddMessageExtraLayout(props: React.PropsWithChildren<AddMessageExtraLayoutProps>) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <MobileLayout {...props} />;
	}
	return <DesktopLayout {...props} />;
}

function DesktopLayout(props: React.PropsWithChildren<AddMessageExtraLayoutProps>) {
	const progress = useJourneySetupProgress();
	const { disabled } = progress;

	const classes = { ...useCommon(), ...useDesktop() };

	return (
		<Grid container style={{ overflowY: "auto" }}>
			<Grid className={classes.titlebar} item xs={12}>
				<Typography className={classes.titlebartitle} variant={"h4"} style={{ paddingLeft: 50 }}>
					{props.title}
				</Typography>
				<IconButton
					className={classes.titleCloseIcon}
					aria-label="close modeal"
					component="span"
					onClick={props.onCancel}
				>
					<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
				</IconButton>
			</Grid>
			<Grid className={classes.progressPage} item xs={12}>
				<Grid style={{ minHeight: 495 }} container direction="row" justify="center" alignContent="center">
					{props.children}
				</Grid>
			</Grid>
			<Grid className={classes.progressActions} item xs={12}>
				<Button
					disabled={disabled}
					variant="contained"
					color="primary"
					size="large"
					className={classNames(classes.actionButtons, classes.backButton)}
					onClick={props.onCancel}
				>
					Cancel
				</Button>
				<div className={classes.grow} />

				<Button
					disabled={disabled}
					variant="contained"
					color="primary"
					size="large"
					className={classNames(classes.actionButtons, classes.nextButton)}
					onClick={props.onSave}
				>
					Save
				</Button>
			</Grid>
		</Grid>
	);
}

function MobileLayout(props: React.PropsWithChildren<AddMessageExtraLayoutProps>) {
	const progress = useJourneySetupProgress();
	const { disabled, closeSetup } = progress;

	let windowSize = window.innerWidth;
	let swoopWidth = windowSize * 1.6;
	let leftPos = ((swoopWidth - windowSize) / 2) * -1;

	const classes = { ...useCommon(), ...useMobile() };

	return (
		<>
			<div
				className={classes.swoopBox}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			></div>
			<div
				className={classes.swoopBoxGreen}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			/>
			<div className={classes.headerBar}>
				<Typography className={classes.titlebartitle} variant="h1" style={{ paddingLeft: 14 }}>
					{props.title}
				</Typography>
				<IconButton className={classes.titleCloseIcon} aria-label="close modeal" component="span" onClick={closeSetup}>
					<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
				</IconButton>
			</div>

			<Paper className={classes.setupContent}>
				<Grid container style={{ display: "block", overflowY: "auto", height: "100%" }}>
					<Grid item xs={12} style={{ paddingLeft: 12, paddingRight: 12 }}>
						{props.children}
					</Grid>
					<Grid style={{ display: "flex", position: "absolute", bottom: 0, width: "100%" }} item xs={12}>
						<Button
							variant="contained"
							color="primary"
							size="large"
							disabled={disabled}
							className={classNames(classes.actionButtons, classes.backButton)}
							onClick={props.onCancel}
						>
							Cancel
						</Button>
						<div className={classes.grow} />
						<Button
							variant="contained"
							color="primary"
							size="large"
							disabled={disabled}
							className={classNames(classes.actionButtons, classes.nextButton)}
							onClick={props.onSave}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
