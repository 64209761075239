import { Button, Grid, InputBase, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import Warning from "@material-ui/icons/WarningRounded";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Form } from "../../entities/Forms/Form";
import { Question, QuestionType, ShortAnswerQuestion } from "../../entities/Forms/Question";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { FormService } from "../../services/FormService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import LoadingSpinner from "../loaderSpinner";
import { ScrollTo } from "../ScrollTo";
import { QuestionBuilder } from "./QuestionBuilder";

const useFormBuilderStyles = makeStyles((theme: Theme) => ({
	gridItem: {
		maxWidth: 700,
		margin: "0 auto",
	},
	gridContainer: {
		position: "absolute",
		top: 60,
		left: 0,
		right: 0,
		bottom: 78,
		[theme.breakpoints.down("sm")]: {
			top: 4,
			padding: 4,
		},
	},
	titleSection: {
		overflowX: "hidden",
		overflowY: "auto",
		zIndex: 2,
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		},
	},
	grow: {
		flex: 1,
	},
	formTitleInput: {
		fontSize: 20,
		fontWeight: 700,
		width: "100%",
	},
	formIntroInput: {
		fontSize: 16,
		fontWeight: 500,
		width: "100%",
	},
	inputText: {
		color: "rgb(0,0,0,1)",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 10,
			paddingRight: 10,
		},
	},
	addButton: {
		color: theme.palette.secondaryResponse.main,
		fontSize: 14,
		"&:hover": {
			backgroundColor: "#FFF",
		},
	},
	questions: {
		//maxHeight: 490,
		marginTop: 20,
		// overflowY: "auto",
		// overflowX: "hidden",
	},
	actionButtons: {
		borderRadius: 8,
		height: 36,
		margin: 12,
		marginBottom: 0,
		[theme.breakpoints.down("sm")]: {
			marginBottom: 24,
		},
	},
	cancelButton: {
		left: 10,
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
		[theme.breakpoints.down("sm")]: {
			left: 0,
		},
	},
	saveButton: {
		right: 10,
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
		[theme.breakpoints.down("sm")]: {
			right: 20,
		},
	},
	progressActions: {
		display: "inline-flex",
	},
	warning: {
		color: "#d37833",
		backgroundColor: "#fbefe4",
		border: "1px solid #d37833",
		borderRadius: 4,
		padding: 10,
		fontSize: 15,
		marginBottom: 12,
		display: "flex",
	},
	errorIcon: {
		color: theme.palette.error.main,
		marginRight: 8,
	},
}));

interface NewFormProps {
	mode: "create";
	journeyId?: string;
	initialQuestions: Question[];
	onSaved: (formId: string) => void;
	onCancel: () => void;
}

interface EditFormProps {
	mode: "edit";
	formId: string;
	journeyId?: string;
	onSaved: (formId: string) => void;
	onCancel: () => void;
}

export function FormBuilder(props: NewFormProps | EditFormProps) {
	const classes = useFormBuilderStyles();
	const { journeyId, onCancel, onSaved } = props;
	const formId = props.mode === "edit" ? props.formId : undefined;

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [user] = useAppUser();
	const setServerErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
	const [form, setForm] = useState<Form | undefined>();
	const [titleError, setTitleError] = useState<string | undefined>(undefined);
	const [introError, setIntroError] = useState<string | undefined>(undefined);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		async function getForm(formId: string) {
			const response = await FormService.get(formId);
			if (ServerResult.isSuccess(response)) {
				setForm(response.data);
			} else {
				setServerErrorAlert(response);
			}
		}

		if (formId && form === undefined) {
			getForm(formId);
		} else if (form === undefined) {
			setForm({
				id: "invalidGuid",
				churchId: user.activeChurchId!,
				journeyId: journeyId,
				title: "",
				description: "",
				questions: props.mode === "create" ? props.initialQuestions : [],
			});
		}
	}, [journeyId, formId, setServerErrorAlert, user.activeChurchId, props, form]);

	const onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!form) return;
		setForm({ ...form, title: e.target.value });
	};

	const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!form) return;
		setForm({ ...form, description: e.target.value });
	};

	const onAddQuestion = () => {
		if (!form) return;

		const question: ShortAnswerQuestion = {
			id: uuid(),
			index: form.questions.length,
			required: false,
			questionType: QuestionType.ShortAnswer,
			prompt: "",
		};

		const questions = [...form.questions, question];
		setForm({ ...form, questions: questions });
	};

	const onQuestionChange = (question: Question) => {
		if (!form) return;

		let questions: Question[] = [];

		form.questions.forEach((a) => {
			if (a.id === question.id) {
				questions.push(question);
			} else {
				questions.push(a);
			}
		});

		setForm({ ...form, questions: questions });
	};

	const onQuestionDelete = (question: Question) => {
		if (!form) return;
		const questions = [...form.questions].filter((q) => q.id !== question.id);
		setForm({ ...form, questions: questions });
	};

	const onSave = async () => {
		setDisabled(true);
		setTitleError(undefined);
		setIntroError(undefined);
		if (formId) {
			await updateForm();
		} else {
			await createForm();
		}
		setDisabled(false);
	};

	const updateForm = async () => {
		if (!form || !formId) {
			return;
		}

		let questionErrors = validateQuestions(form.questions);

		if (questionErrors !== undefined) {
			setServerErrorAlert({ statusCode: 0, message: questionErrors });
			return;
		}

		const response = await FormService.update({
			formId: formId,
			title: form.title,
			description: form.description,
			journeyId: journeyId,
			questions: form.questions,
		});
		if (ServerResult.isSuccess(response)) {
			setForm(response.data);
			setSuccessAlert("Form successfully updated.");
			onSaved(response.data.id);
		} else if (ServerResult.isValidationError(response)) {
			let titleError = FieldValidationErrorUtils.getFieldError(response.errors, "Title");
			let introError = FieldValidationErrorUtils.getFieldError(response.errors, "Description");

			setTitleError(titleError);
			setIntroError(introError);

			let message = titleError ? `${titleError} ` : "";
			message += introError ? `${introError} ` : "";

			if (FieldValidationErrorUtils.isFieldInError(response.errors, "Questions")) {
				message += "There are a max of 50 questions per form";
			}

			setServerErrorAlert({ statusCode: 0, message: message.trim() });
		} else if (ServerResult.isError(response)) {
			setServerErrorAlert(response);
		}
	};

	const createForm = async () => {
		if (!form) {
			return;
		}

		let questionErrors = validateQuestions(form.questions);

		if (questionErrors !== undefined) {
			setServerErrorAlert({ statusCode: 0, message: questionErrors });
			return;
		}

		const response = await FormService.create({
			title: form.title,
			description: form.description,
			journeyId: journeyId,
			questions: form.questions,
		});
		if (ServerResult.isSuccess(response)) {
			setForm(response.data);
			setSuccessAlert("Form successfully created.");
			onSaved(response.data.id);
		} else if (ServerResult.isValidationError(response)) {
			let titleError = FieldValidationErrorUtils.getFieldError(response.errors, "Title");
			let introError = FieldValidationErrorUtils.getFieldError(response.errors, "Description");

			setTitleError(titleError);
			setIntroError(introError);

			let message = titleError ? `${titleError} ` : "";
			message += introError ? `${introError} ` : "";

			if (FieldValidationErrorUtils.isFieldInError(response.errors, "Questions")) {
				message += "There are a max of 50 questions per form";
			}

			setServerErrorAlert({ statusCode: 0, message: message.trim() });
		} else if (ServerResult.isError(response)) {
			setServerErrorAlert(response);
		}
	};

	const validateQuestions = (questions: Question[]) => {
		let message: string | undefined = undefined;

		if (questions.length === 0) {
			message = "There must be one question for the form";
			return message;
		}

		questions.forEach((question) => {
			if (question.prompt.length === 0) {
				message = "Every question must have a prompt filled out.";
				return message;
			} else if (question.prompt.length > 200) {
				message = "Max length of question prompt is 200 characters";
				return message;
			}

			if (question.questionType === QuestionType.MultipleAnswer || question.questionType === QuestionType.SingleAnswer) {
				if (question.choices === undefined || question.choices.length === 0) {
					message = "All questions that have choices must have at least one choice.";
					return message;
				} else if (question.choices.filter((a) => a.text.length === 0).length > 0) {
					message = "All choices must have text associated with them.";
					return message;
				}
			}

			if (question.questionType === QuestionType.SignupList) {
				if (question.choices === undefined || question.choices.length === 0) {
					message = "All questions that have choices must have at least one choice";
					return message;
				} else {
					question.choices.forEach((choice) => {
						if (choice.text === undefined || choice.text.length === 0) {
							message = "All signup list options must be filled out.";
							return message;
						} else if (choice.quantity === 0) {
							message = "Quantity in signup list options cannot be 0.";
							return message;
						}
					});
				}
			}
		});

		return message;
	};

	if (!form) {
		return (
			<div style={{ margin: "0 auto" }}>
				<LoadingSpinner isComplete={false} durationMessage={"Loading..."} />
			</div>
		);
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12} className={classNames(classes.gridContainer, classes.gridItem, classes.titleSection)}>
					{formId && (
						<div className={classes.warning}>
							<div style={{ width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Warning style={{ fontSize: 28 }} />
							</div>
							<div>
								Warning! Editing questions on a live or previously active form can seriously impact the accuracy
								of your responses; we recommend editing questions only to fix mistakes or to re-use a form.
							</div>
						</div>
					)}
					<InputBase
						inputProps={{
							className: classes.inputText,
						}}
						startAdornment={titleError !== undefined ? <ScrollTo><ErrorIcon className={classes.errorIcon} /></ScrollTo>  : null}
						autoFocus
						className={classes.formTitleInput}
						defaultValue={form.title}
						placeholder="Untitled form"
						onChange={onTitleChange}
					/>
					<hr style={{ height: 2, margin: 0, opacity: 0.4, width: "100%" }} />
					<InputBase
						inputProps={{
							className: classes.inputText,
						}}
						startAdornment={introError !== undefined ? <ScrollTo><ErrorIcon className={classes.errorIcon} /></ScrollTo> : null}
						className={classes.formIntroInput}
						defaultValue={form.description}
						placeholder="Form description"
						multiline={true}
						rowsMax={4}
						onChange={onDescriptionChange}
					/>
					<div className={classes.questions}>
						{form.questions.map((q, i) => (
							<div key={i} className={classes.gridItem}>
								<QuestionBuilder question={q} onChange={onQuestionChange} onDelete={onQuestionDelete} />
							</div>
						))}
					</div>
					<div className={classes.gridItem}>
						<Button
							className={classes.addButton}
							startIcon={<AddCircle style={{ fontSize: 32 }} />}
							onClick={onAddQuestion}
						>
							Add Question
						</Button>
					</div>
				</Grid>
				<Grid
					style={{ display: "flex", position: "absolute", bottom: mobileFormFactor ? 0 : 30, width: "100%" }}
					item
					xs={12}
				>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						size="large"
						onClick={onCancel}
						className={classNames(classes.actionButtons, classes.cancelButton)}
					>
						Cancel
					</Button>
					<div className={classes.grow} />

					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						size="large"
						className={classNames(classes.actionButtons, classes.saveButton)}
						onClick={onSave}
					>
						Save
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
