import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { Journey } from "../../../entities/Journey";
import ResponseInputComponent from "../../responseInputComponent";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout, useJourneyStepStyles } from "../Steps/JourneyStepLayout";
import { AddMessageExtraLayout } from "./AddMessageExtraLayout";

type StepProps = {
	mode: "step";
	journey: Journey;
}

type AddProps = {
	mode: "add";
	journey: Journey;
	title: string;
	onCancel: () => void;
	onUpdate: (updatedJourney: Journey) => void;
}

type Props = StepProps | AddProps;

const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export function MessageLinkView(props: Props) {
	const classes = useJourneyStepStyles();
	const progress = useJourneySetupProgress();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { journey } = props;

	const [linkUrl, setLinkUrl] = useState<string | undefined>(journey.extras.link);
	const [linkError, setLinkUrlError] = useState<string | undefined>(undefined);

	const handleLinkSetup = () => {
		setLinkUrlError(undefined);
		let regex = new RegExp(expression);

		if (!(linkUrl === undefined || linkUrl.length === 0) && !linkUrl.match(regex)) {
			setLinkUrlError("Link is invalid, try something like https://mylink.png");
			return;
		}

		const updatedJourney:Journey = {
			...journey,
			extras: {
				...journey.extras,
				link: linkUrl?.length === 0 ? undefined : linkUrl,
			}
		};

		if(props.mode === "add"){
			props.onUpdate(updatedJourney);
		} else {
			progress.completeStep(updatedJourney);
		}
	};

	const skipStep = () => {
		setLinkUrlError(undefined);
		progress.completeStep({
			...journey,
			extras: {
				...journey.extras,
				link: undefined,
			},
		});
	};

	const view = () => {
		return (
			<Grid item xs={12}>
				<div style={{ width: mobileFormFactor ? "100%" : 400, margin: "0 auto" }}>
					<Typography className={classes.progressLabelText} variant="subtitle2">
						What link do you want to send people when they text "{props.journey.keywordTrigger}"?
					</Typography>
					<div style={{ width: mobileFormFactor ? "100%=" : 300 }}>
						<ResponseInputComponent
							value={linkUrl ?? ""}
							onChange={setLinkUrl}
							placeHolder={"https://mylink.png"}
							errorMessage={linkError}
							type={"url"}
						/>
					</div>
					<Typography variant="subtitle1" style={{ fontSize: 14, opacity: 0.8, marginTop: 12 }}>
						To keep the message short and sweet, we'll shorten the link (ex: https://rspns.to/4Gh1c)
					</Typography>
				</div>
			</Grid>
		);
	}

	if(props.mode === "add"){
		return(
			<AddMessageExtraLayout title={props.title} onCancel={props.onCancel} onSave={handleLinkSetup}>
				{view()}
			</AddMessageExtraLayout>
		);
	}

	return (
		<JourneyStepLayout required={false} onConfirmStep={handleLinkSetup} skip={linkUrl == null || linkUrl.trim() === ""} onSkip={skipStep}>
			{view()}
		</JourneyStepLayout>
	);
}
