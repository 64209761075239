import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AwesomeCheckbox } from "../../../components/AwesomeCheckbox";
import { MultistepDialogStepLayout } from "../../../components/MultistepDialog/Layouts/MultistepDialogStepLayout";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { useUser } from "../../../hooks/useUser";
import AuthService from "../../../services/AuthService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";

export function VerifiedBrand(props: {onVerified: () => void}) {
    const [user, setUser] = useUser();
    const setServerError = useServerErrorAlert();

    const handleExit = async() => {  
        const refreshResult = await AuthService.refreshUser();
    
        if(ServerResult.isError(refreshResult))
            return setServerError(refreshResult)

        if (ServerResult.isSuccess(refreshResult)){
            setUser(refreshResult.data.jwt);
            props.onVerified();
        }
    }

	return (
        <MultistepDialogStepLayout 
            title="Verified" 
            required={true}
            disabled={false}
            hideNextStep={false}
            nextButtonText="Finish"
            onConfirmStep={handleExit}
            hideBackStep={true}
        >
            <VerticalCenter>
                <Grid container justify="center">
                    <Grid item sm={8} xs={12}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography align="center" variant="body2" style={{ fontSize: 18 }}>
                                    You did it!
                                </Typography>
                            </Grid>
                            <Grid item>
                                <AwesomeCheckbox size={90} color="secondary" />
                            </Grid>
                            { user && user.responsePhoneNumber &&
                                <Grid item>
                                    <Typography align="center" variant="body2" style={{ fontSize: 18 }}>
                                        {`${user.activeChurchName}: ${formatPhoneNumber(user.responsePhoneNumber)}`}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </VerticalCenter>
		</MultistepDialogStepLayout>
	);
}