import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { ServerError } from "../services/server/ServerError";

export function ServerErrorPage(props: { serverError: ServerError }) {
	const { serverError } = props;
	return (
		<Grid container justify="center" alignItems="center" style={{ height: "92vh" }}>
			<Grid item>
				<Typography variant="h1">
					{serverError.statusCode} - {serverError.message}
				</Typography>
			</Grid>
		</Grid>
	);
}
