import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import Expand from "@material-ui/icons/ExpandMore";
import LeftArrow from "@material-ui/icons/KeyboardArrowLeft";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import { ResponseSubscriptionPlan } from "../../../entities/ResponseSubscriptionPlan";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { ResponseSubscriptionService } from "../../../services/ResponseSubscriptionService";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import ResponseSubscription from "../../../entities/ResponseSubscription";
import { isMobile } from "../../../hooks/isMobile";
import { NumberHelper } from "../../../utillity/NumberHelper";
import { GridGrow } from "../../../components/GridGrow";
import { buildDateTimeString } from "../../../functions/datetime";

export const useStyles = makeStyles((theme: Theme) => ({
    selectPlanButton: {
		backgroundColor: theme.palette.secondaryResponse.main,
		width: 140,
		borderRadius: 6,
	},
    scrollButton: {
		paddingBottom: 40,
	},
    lineItem: {
		display: "flex",
		border: "1px solid rgba(0,0,0,.1)",
		padding: 4,
	},
    planCard: {
		marginTop: 10,
		width: 185,
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		"&:hover": {
			transform: "translateY(-5px) scale(1.01) translateZ(0)",
		},
		[theme.breakpoints.only("xs")]: {
			width: "100%",
		},
	},
    cardGrid: {
		marginTop: 15,
		paddingBottom: 40,
		paddingLeft: 10,
		[theme.breakpoints.up("sm")]: {
			overflow: "auto",
			flexWrap: "nowrap",
		},
	},
    mainPrice: {
		fontWeight: 800,
		opacity: 0.7,
	},
	priceDescription: {
		lineHeight: 1,
		marginTop: 4,
		color: "#000",
		opacity: 0.6,
		fontWeight: 600,
		fontSize: 12,
	},
	peopleDescription: {
		fontWeight: 500,
	},
    cardFooter: {
		display: "flex",
		flexDirection: "column",
		marginTop: 0,
		marginBottom: 40,
	},
    lineItemDescription: {
		marginBottom: 12,
	},
}));

const ScrollWidth = 1920;

interface ChangePlanSelectionProps {
    annualBilling: boolean;
    subscription: ResponseSubscription | undefined;
    plans: ResponseSubscriptionPlan[];
    onSubscriptionUpdated: (subscription: ResponseSubscription) => void;
}

export function ChangePlanSelection(props: ChangePlanSelectionProps) {
    const {plans, subscription} = props;

    const classes = useStyles();
    const mobile = isMobile();
	const setServerErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

    const [disable, setDisable] = useState(false);
	const [showPlans, setShowPlans] = useState(false);
	const [annualBilling, setAnnualBilling] = useState(false);
	const [estimateSubscriptionChange, setShowEstimateSubscriptionChange] = useState<ResponseSubscriptionPlan | undefined>(
		undefined
	);
	const [estimatedPlanChangeCost, setEstimatedPlanChangeCost] = useState<number | undefined>(undefined);

	const checkSubscriptionChange = async (plan: ResponseSubscriptionPlan) => {
		setShowEstimateSubscriptionChange(plan);
		const result = await ResponseSubscriptionService.checkUpgradeSubscription(plan.id);
		if (ServerResult.isSuccess(result)) {
			setEstimatedPlanChangeCost(result.data);
		} else {
			setServerErrorAlert(result);
			setShowEstimateSubscriptionChange(undefined);
		}
	};

	const scrollRight = () => {
		let ele = document.getElementById("MainContainer");
		if (ele && ele != null) {
			ele.scrollLeft += 200;
		}
	};

	const scrollLeft = () => {
		let ele = document.getElementById("MainContainer");
		if (ele && ele != null) {
			ele.scrollLeft -= 200;
		}
	};

	const upgradeSubscription = async () => {
		if (!estimateSubscriptionChange) {
			return;
		}

		setDisable(true);
		const result = await ResponseSubscriptionService.upgradeSubscription(estimateSubscriptionChange.id);
		setDisable(false);
		if (ServerResult.isSuccess(result)) {
			setSuccessAlert("Subscription has been updated");
			setShowEstimateSubscriptionChange(undefined);
			setEstimatedPlanChangeCost(undefined);
			props.onSubscriptionUpdated(result.data);
		} else {
			setServerErrorAlert(result);
		}
	};

	return (
		<Accordion expanded={showPlans} style={{boxShadow: "none"}}>
			<AccordionSummary
				expandIcon={<Expand />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				onClick={() => setShowPlans(!showPlans)}
			>
				<Typography variant="body1" style={{ textAlign: "center" }}>
					Looking to change your plan? Expand to see your options
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} style={{ textAlign: "center" }}>
						<Typography variant="h5">Select Plan</Typography>
					</Grid>
					<Grid item xs={12} style={{ textAlign: "center", marginTop: 4 }}>
						<Button
							variant="contained"
							size="medium"
							color="primary"
							type="submit"
							className={classes.selectPlanButton}
							style={{ opacity: annualBilling ? 0.6 : 1 }}
							onClick={() => setAnnualBilling(false)}
						>
							Monthly
						</Button>
						<Button
							variant="contained"
							size="medium"
							color="primary"
							type="submit"
							style={{ opacity: annualBilling ? 1 : 0.6 }}
							className={classes.selectPlanButton}
							onClick={() => setAnnualBilling(true)}
						>
							Annually
						</Button>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: "flex" }}>
							{!mobile && window.innerWidth < ScrollWidth && (
								<IconButton className={classes.scrollButton} onClick={() => scrollLeft()}>
									<LeftArrow />
								</IconButton>
							)}
							<Grid container id="MainContainer" direction="row" alignItems="center" className={classes.cardGrid}>
								{plans
									.filter((sp) => (annualBilling ? sp.billingPeriod === 12 : sp.billingPeriod === 1))
									.reverse()
									.map((p) => {
										return (
											<Grid item key={p.id}>
												<Card
													className={classes.planCard}
													raised={true}
													style={{
														boxShadow:
															subscription?.currentPriceId === p.id
																? "0px 0px 7px 3px rgba(64, 199, 163)"
																: "0px 0px 7px 3px rgba(0,0,0,0.1)",
														marginRight: subscription?.currentPriceId === p.id ? 5 : 0,
													}}
												>
													<CardHeader
														style={{
															paddingBottom: 0,
														}}
														title={
															<>
																<div style={{ display: "flex" }}>
																	<div
																		style={{
																			fontWeight: 800,
																			opacity: 0.4,
																			fontSize: 24,
																		}}
																	>
																		$
																	</div>
																	<Typography className={classes.mainPrice} variant="h1">
																		{annualBilling
																			? NumberHelper.convertToDollarsReturnsNumber(
																					p.price / 12
																			  )
																			: p.price}
																	</Typography>
																</div>
																<Typography className={classes.priceDescription} variant="body1">
																	per month
																	{annualBilling && ","}
																</Typography>
																{annualBilling && (
																	<Typography
																		className={classes.priceDescription}
																		variant="body1"
																	>
																		{`billed annually $${p.price}`}
																	</Typography>
																)}
															</>
														}
													/>
													<CardContent>
														{p.people && (
															<>
																<Divider
																	style={{
																		height: 3,
																		opacity: 0.5,
																		width: 40,
																	}}
																/>
																<Typography
																	className={classes.peopleDescription}
																	style={{ marginTop: 12 }}
																	variant="h3"
																>
																	Up to <strong>{p.people}</strong>
																</Typography>
																<Typography className={classes.peopleDescription} variant="h3">
																	active contacts
																</Typography>
															</>
														)}
													</CardContent>
													<CardActions className={classes.cardFooter}>
														<Button
															variant="contained"
															size="medium"
															color="primary"
															type="submit"
															disabled={subscription?.currentPriceId === p.id}
															className={classes.selectPlanButton}
															onClick={() => checkSubscriptionChange(p)}
															style={{
																color: subscription?.currentPriceId === p.id ? "#000" : "#FFF",
															}}
														>
															{subscription?.currentPriceId === p.id
																? "Current Plan"
																: "Change Plan"}
														</Button>
													</CardActions>
												</Card>
											</Grid>
										);
									})}
							</Grid>

							{!mobile && window.innerWidth < ScrollWidth && (
								<IconButton className={classes.scrollButton} onClick={() => scrollRight()}>
									<RightArrow />
								</IconButton>
							)}
						</div>
					</Grid>
				</Grid>
			</AccordionDetails>
			<Dialog
				open={estimateSubscriptionChange !== undefined}
				onClose={() => {
					setShowEstimateSubscriptionChange(undefined);
					setEstimatedPlanChangeCost(undefined);
				}}
				fullScreen={mobile}
			>
				<DialogTitle id="cancel-sub-title">
					Change subscription to {estimateSubscriptionChange?.people} attendees plan?
				</DialogTitle>
				{estimatedPlanChangeCost && estimateSubscriptionChange && (
					<>
						<DialogContent>
							<DialogContentText>
								<b style={{ color: "#000", marginBottom: 4 }}> Summary </b>
								<br />
								<div className={classes.lineItem} style={{ borderRadius: "4px 4px 0 0" }}>
									<Typography variant="caption">{estimateSubscriptionChange.people} Attendees</Typography>
									<GridGrow/>
									<Typography variant="caption">${estimateSubscriptionChange.price.toFixed(2)}</Typography>
								</div>
								<div className={classes.lineItem} style={{ borderTop: 0 }}>
									<b style={{ color: "#000", fontSize: 13 }}>Amount being charged today</b>
									<GridGrow/>
									<span style={{ color: "#000" }}>
										${estimatedPlanChangeCost < 0 ? "0.00" : estimatedPlanChangeCost.toFixed(2)}
									</span>
								</div>
								<div
									className={classes.lineItem}
									style={{
										borderTop: 0,
										borderRadius: "0 0 4px 4px",
										fontWeight: 300,
										backgroundColor: "#f2f4f5",
										display: "inline-block",
									}}
								>
									<Typography className={classes.lineItemDescription} variant="caption">
										{" "}
										{estimatedPlanChangeCost < 0
											? `A credit of $${estimatedPlanChangeCost * -1} will be applied to your account.`
											: "You authorize Response to charge you for the amount above."}
									</Typography>
									<br />
									<Typography className={classes.lineItemDescription} variant="caption">
										{" "}
										Your subscription renews{" "}
										{estimateSubscriptionChange.billingPeriod === 12 ? "yearly" : "each month"} until you
										cancel.
									</Typography>
									<br />
									<Typography className={classes.lineItemDescription} variant="caption">
										The next charge will be ${estimateSubscriptionChange.price}, minus any applicable
										discounts or credits, on{" "}
										<b>
											{estimateSubscriptionChange.billingPeriod === 12
												? buildDateTimeString(
														new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString(),
														true
												  )
												: buildDateTimeString(
														new Date(new Date().setMonth(new Date().getMonth() + 1)).toString(),
														true
												  )}
										</b>
									</Typography>
									<br />
									<Typography className={classes.lineItemDescription} variant="caption"></Typography>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								variant="outlined"
								color="default"
								size="medium"
								onClick={() => {
									setShowEstimateSubscriptionChange(undefined);
									setEstimatedPlanChangeCost(undefined);
								}}
								disabled={disable}
							>
								Close
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="medium"
								onClick={upgradeSubscription}
								disabled={disable}
							>
								Change Subscription
							</Button>
						</DialogActions>
					</>
				)}
				{!estimatedPlanChangeCost && (
					<div style={{ textAlign: "center", paddingTop: 40, height: 180 }}>
						<CircularProgress color="secondary" />
						<Typography variant="h4">loading...</Typography>
					</div>
				)}
			</Dialog>
		</Accordion>
	);
}
