import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

export const useConnectStyles = makeStyles((theme: Theme) => ({
	hiddenTab: {
		opacity: 0.4,
	},
	pageTabs: {
		color: theme.palette.secondaryResponse.main,
		marginRight: 80,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
		[theme.breakpoints.down("md")]: {
			marginRight: 14,
		},
	},
	tabIndicator: {
		borderBottomStyle: "solid",
		borderBottomWidth: 3,
		borderColor: theme.palette.secondaryResponse.main,
	},

	linkText: {
		textDecoration: "underline",
		cursor: "pointer",
		fontWeight: "normal",
	},
	defaultTabStyle: {
		textAlign: "center",
		height: 48,
		boxShadow: "0 2px inset #d6d6d6, 0 -1px #c4c4c4",
		color: theme.palette.secondaryResponse.main,
		backgroundColor: theme.palette.lightGrayBlue.main,
		fontSize: 18,
		paddingTop: 12,
	},
	selectedTabStyle: {
		textDecoration: "underline",
		boxShadow: "0 -3px #dfe1e1",
		backgroundColor: "#FFF",
		fontWeight: 700,
	},
}));

export enum SettingsTab {
	Church_Info = "Church_Info",
	Personal_Info = "Personal_Info",
	Subscription = "Subscription",
	Payment_Method = "Payment_Method",
	Invoices = "Invoices",
}

type TabHeaderBarProps = {
	tab: SettingsTab;
};

const TabHeaderBar = (props: TabHeaderBarProps) => {
	const classes = useConnectStyles();
	const selectedPage = props.tab;

	const selectedTab = (tab: string) => {
		return (
			<Typography className={classes.pageTabs} variant="h2" key={tab}>
				<span className={classes.tabIndicator}>{tab.toString().replace("_", " ")}</span>
			</Typography>
		);
	};

	const unselectedTab = (tab: string) => {
		return (
			<Link to={determinUrlPath(tab)} style={{ textDecoration: "none" }} key={tab}>
				<Typography className={classNames(classes.pageTabs, classes.hiddenTab)} variant="h2">
					{tab.toString().replace("_", " ")}
				</Typography>
			</Link>
		);
	};

	const determinUrlPath = (tab: string) => {
		switch (tab) {
			case SettingsTab.Church_Info:
				return routes.app.churchInfoPage;
			case SettingsTab.Personal_Info:
				return routes.app.personalInfoPage;
			case SettingsTab.Subscription:
				return routes.app.subscriptionPage;
			case SettingsTab.Payment_Method:
				return routes.app.paymentMethodPage;
			case SettingsTab.Invoices:
				return routes.app.invoicesPage;
			default:
				return routes.app.churchInfoPage;
		}
	};

	return (
		<Grid container style={{ position: "relative" }}>
			<Grid item xs={12} style={{ display: "flex" }}>
				{Object.values(SettingsTab).map((value) => {
					return SettingsTab[value] === selectedPage.toString()
						? selectedTab(SettingsTab[value])
						: unselectedTab(SettingsTab[value]);
				})}
			</Grid>
		</Grid>
	);
};

export default TabHeaderBar;
