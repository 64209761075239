import React, { PropsWithChildren, useEffect, useRef } from "react";

export function ScrollTo(props: PropsWithChildren<{behavior?: "smooth" | "auto"}>){
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if(divRef.current){
            divRef.current.scrollIntoView({ behavior: props.behavior ?? 'smooth' })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef])

    return <div ref={divRef}>{props.children}</div>
}

export function ScrollToIndexWrapper(props:PropsWithChildren<{selectedIndex: boolean}>){
    if(props.selectedIndex){
        return <ScrollTo>{props.children}</ScrollTo>
    };
    return <>{props.children}</>
}