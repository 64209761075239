import { apiRoot } from "./Settings";
import { WebClient } from "./server/WebClient";
import ResponseInvoice from "../entities/ResponseInvoice";
import ResponsePaymentMethod, { UpdateResponsePaymentMethod } from "../entities/ResponsePaymentMethod";

export const ResponseCustomerService = {
    getInvoices() {
        return WebClient.Get<ResponseInvoice[]>(`${apiRoot}/customer/invoices`);
    },
    getPayment() {
        return WebClient.Get<ResponsePaymentMethod>(`${apiRoot}/customer/payment`);
    },
    updatePayment(request: UpdateResponsePaymentMethod) {
        return WebClient.Put.Validated<ResponsePaymentMethod>(`${apiRoot}/customer/payment`,  request );
    }
}

export default ResponseCustomerService;