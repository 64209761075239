import { Chart, LineSeries } from "@devexpress/dx-react-chart-material-ui";
import { Divider, Grid, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";
import { DashboardData, SentMessages } from "../../../services/DashboardService";

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: 20,
	},
	cardTitle: {},
	totalCount: {
		color: theme.palette.secondaryResponse.main,
		paddingRight: 10,
		alignSelf: "center",
	},
	addedCount: {
		color: theme.palette.secondaryResponse.main,
	},
	icon: {
		fontSize: 14,
	},
}));

export function MessageCard(props: { data: DashboardData }) {
	const classes = useStyles();
	const [recentMessages] = useState<SentMessages[]>(props.data.recentSentMessages);
	const [recentMessageCount] = useState(props.data.recentSentMessages.reduce((a, b) => a + b.messagesSent, 0));

	const theme = useTheme();

	return (
		<Paper elevation={4} className={classes.paper}>
			<Grid container direction="column">
				<Grid item xs={12}>
					<Typography variant="h4">MESSAGES SENT IN THE LAST 30 DAYS</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h1" className={classes.totalCount}>
								{recentMessageCount}
							</Typography>
						</Grid>
						<Grid item>
							<Chart data={recentMessages} height={50} width={50}>
								<LineSeries
									valueField="messagesSent"
									argumentField="date"
									color={theme.palette.primaryResponse.main}
								/>
							</Chart>
						</Grid>
					</Grid>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<ClientLink to={routes.app.sendMessagePage} style={{ color: theme.palette.jacobsBlue.main }}>
						Send a message
					</ClientLink>
				</Grid>
			</Grid>
		</Paper>
	);
}
