import { FormGroup, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { GridGrow } from "../../../../components/GridGrow";
import { useAppUser } from "../../../../hooks/useAppUser";
import useGoogleMaps from "../../../../hooks/useGoogleMaps";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../../services/server/ServerValidationError";
import parse from "autosuggest-highlight/parse";
import Church from "../../../../entities/Church";

interface Option extends google.maps.places.AutocompletePrediction {}

export interface RegisterChurchRequest {
    churchName: string;
    churchAddress: string;
    city: string;
    state: string;
    zipCode: string;
    emailAddress?: string;
}

export interface ChurchRegistrationFormProps {
    disabled?: boolean;
    errors: FieldValidationError[];
    onRequestUpdate: (request:RegisterChurchRequest) => void;
    existingChurch?: Church;
}

export function ChurchRegistrationForm(props:ChurchRegistrationFormProps){
    const {disabled, errors, existingChurch} = props;
    const [places, setQuery, geoCodeService] = useGoogleMaps();

    const [disableAutoComplete, setDisableAutoComplete] = useState(false);
    const [openAddressOptions, setOpenAddressOptions] = useState(false);
    const [user,] = useAppUser();
    const [request, setRequest] = useState<RegisterChurchRequest>({
        churchName: existingChurch?.name ?? user.activeChurchName,
        churchAddress: existingChurch?.address ?? "",
        city: existingChurch?.city ?? "",
        state: existingChurch?.state ??  "",
        zipCode: existingChurch?.zipCode ?? "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.onRequestUpdate(request), [request]);


	const onAddressSelected = (geocodedAddresses: google.maps.GeocoderResult[]) => {
		setDisableAutoComplete(false);
		if (geocodedAddresses.length === 0) {
			return;
		}
		const geocodedAddress = geocodedAddresses[0];
		const parts = geocodedAddress.address_components;
		const streetNumber = parts.find((p) => p.types.some((type) => type === "street_number"))?.long_name ?? "";
		const route = parts.find((p) => p.types.some((type) => type === "route"))?.short_name ?? "";
		const street = `${streetNumber} ${route}`;
		const city = parts.find((p) => p.types.some((type) => type === "locality"))?.short_name ?? "";
		const state = parts.find((p) => p.types.some((type) => type === "administrative_area_level_1"))?.short_name ?? "";
		const postalCode = parts.find((p) => p.types.some((type) => type === "postal_code"))?.long_name ?? "";
		const updatedRequest: RegisterChurchRequest = {
            churchName: request.churchName,
			churchAddress: street,
			city,
			state,
			zipCode: postalCode,
		};
		setRequest(updatedRequest);
	};
    
    return (
        <FormGroup>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Church profile:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="ChurchName"
                        label="Church Name"
                        variant="outlined"
                        value={request.churchName}
                        onChange={(e) => setRequest({...request, churchName: e.target.value})}
                        margin="none"
                        fullWidth
                        required
                        type="text"
                        disabled={disabled}
                        error={FieldValidationErrorUtils.isFieldInError(errors, "ChurchName")}
                        helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "ChurchName")}
                    />
                </Grid>
                { FieldValidationErrorUtils.isFieldInError(errors, "email") &&
                    <Grid item xs={12}>
                        <TextField
                            name="Email"
                            label="Contact Email Address"
                            variant="outlined"
                            value={request.emailAddress}
                            onChange={(e) => setRequest({...request, emailAddress: e.target.value})}
                            margin="none"
                            fullWidth
                            required
                            type="text"
                            disabled={disabled}
                            error={FieldValidationErrorUtils.isFieldInError(errors, "email")}
                            helperText={"Anti-spam regulations do not allow this type of email address. Please try a different email address."}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Autocomplete
                        open={openAddressOptions}
                        onOpen={() => {
                          // only open when in focus and inputValue is not empty
                          if(request.churchAddress)
                            setOpenAddressOptions(true);
                        }}
                        onClose={() => setOpenAddressOptions(false)}
                        getOptionLabel={(option) => option.structured_formatting.main_text}
                        filterOptions={(x) => x}
                        options={places}
                        fullWidth
                        disabled={disableAutoComplete || disabled}
                        freeSolo
                        value={null}
                        onChange={(_, newValue) => {
                            if (geoCodeService && newValue) {
                                setDisableAutoComplete(true);
                                geoCodeService.geocode({ placeId: typeof(newValue) === "string" ? undefined : newValue.place_id }, onAddressSelected);
                            }
                        } }
                        onInputChange={(_, newInputValue, reason) => {
                            if(reason === "clear" || reason === "reset"){
                                setRequest({
                                    churchName: request.churchName,
                                    churchAddress: "",
                                    city: "",
                                    state: "",
                                    zipCode: "",})
                            }

                            if(reason === "input"){
                                setRequest({...request, churchAddress: newInputValue})
                            }
                            setQuery(newInputValue);
                        } }
                        renderOption={(option: Option) => {
                            const matches = option.structured_formatting.main_text_matched_substrings;
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length])
                            );

                            return (
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                        <Typography variant="body2" color="textSecondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        } } 
                        renderInput={(params) => (
                            <TextField
                                required
                                label="Church Address"
                                variant="outlined"
                                value={request.churchAddress}
                                margin="none"
                                type="text"
                                onChange={(e) => setRequest({...request, churchAddress: e.target.value})}
                                error={FieldValidationErrorUtils.isFieldInError(errors, "ChurchAddress") || FieldValidationErrorUtils.isFieldInError(errors, "Unknown")}
                                helperText={
                                    FieldValidationErrorUtils.isFieldInError(errors, "ChurchAddress") || FieldValidationErrorUtils.getFieldErrorSummary(errors, "Unknown")
                                    ? FieldValidationErrorUtils.getFieldErrorSummary(errors, "ChurchAddress")|| FieldValidationErrorUtils.getFieldErrorSummary(errors, "Unknown")
                                    : <>Physical address only, no PO Boxes</>
                                }
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="City"
                        variant="outlined"
                        value={request.city}
                        onChange={(e) => setRequest({...request, city: e.target.value})}
                        margin="none"
                        fullWidth
                        required
                        inputProps={{ maxLength: 100 }}
                        type="text"
                        disabled={disabled}
                        error={FieldValidationErrorUtils.isFieldInError(errors, "City")}
                        helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "City")}
                    />
                </Grid>
                <Grid item container>
                    <Grid item xs={5}>
                        <TextField
                            label="State"
                            variant="outlined"
                            value={request.state}
                            onChange={(e) =>{
                                const updateValue = /\D/gi.test(e.target.value) || e.target.value === ''
                                if(updateValue)
                                    setRequest({...request, state: e.target.value})
                            }}                        
                            margin="none"
                            required
                            fullWidth
                            inputProps={{ maxLength: 2 }}
                            type="text"
                            disabled={disabled}
                            error={FieldValidationErrorUtils.isFieldInError(errors, "State")}
                            helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "State")}
                        />
                    </Grid>
                    <GridGrow />
                    <Grid item xs={5}>
                        <TextField
                            label="Zip Code"
                            variant="outlined"
                            value={request.zipCode}
                            onChange={(e) =>{
                                const updateValue = /\d/gi.test(e.target.value) || e.target.value === ''
                                if(updateValue)
                                    setRequest({...request, zipCode: e.target.value})
                            }}
                            margin="none"
                            required
                            fullWidth
                            type="text"
                            inputProps={{ maxLength: 5 }}
                            disabled={disabled}
                            error={FieldValidationErrorUtils.isFieldInError(errors, "ZipCode")}
                            helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "ZipCode")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </FormGroup>
    )
}