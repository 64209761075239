import {
	FormControl,
	FormHelperText,
	Grid,
	makeStyles,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { Journey, JourneyCategory } from "../../../entities/Journey";
import { MessageExtraType } from "../../../entities/MessageExtraType";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { SupportedPersonalization } from "../../../utillity/Personalization";
import { AddJourneyMessageExtra } from "../Extras/AddJourneyMessageExtra";
import { JourneyMessageExtras } from "../JourneyMessageExtras";
import { FieldErrorStrings } from "../journeySetUp";
import { MobileMessagePreview } from "../MobileMessagePreview";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout, useJourneyStepStyles } from "./JourneyStepLayout";

export const useJourneyMessagePreviewStyles = makeStyles((theme: Theme) => ({
	icons: {
		color: theme.palette.secondaryResponse.main,
		cursor: "pointer",
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
	},
	option: {
		height: 48,
		width: "100%",
		backgroundColor: "rgb(245,246,247)",
		display: "flex",
		borderTop: "1px solid rgb(227, 231, 240)",
		borderLeft: "1px solid rgb(227, 231, 240)",
	},
	optionIcon: {
		width: 20,
		marginLeft: 20,
	},
	linkIcon: {
		color: theme.palette.primaryResponse.main,
		fontSize: 26,
		width: 30,
		marginTop: 10,
		transform: "rotate(-46deg)",
		marginLeft: 14,
	},
	mediaIcon: {
		color: theme.palette.primaryResponse.main,
		width: 24,
		marginTop: 12,
		marginLeft: 16,
	},
	formIcon: {
		color: theme.palette.primaryResponse.main,
		width: 24,
		marginTop: 12,
		marginLeft: 16,
		marginRight: -3,
	},
	borderRight: {
		borderRight: "1px solid rgb(227, 231, 240)",
	},
	borderBottom: {
		borderBottom: "1px solid rgb(227, 231, 240)",
	},
	optionText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		marginTop: 12,
		marginLeft: 14,
	},
	grow: {
		flex: 1,
	},
	optionEndIcon: {
		marginRight: 20,
		marginTop: 12,
		cursor: "pointer",
	},
	optionFilledText: {
		maxWidth: 200,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	deleteIcon: {
		color: theme.palette.error.main,
		marginRight: 6,
		opacity: 0.8,
	},
	mobilePreviewMessage: {
		position: "absolute",
		top: 260,
		right: -16,
		height: 120,
		width: 38,
		backgroundColor: theme.palette.secondaryResponse.main,
		color: "#FFF",
		borderTopLeftRadius: 16,
		borderBottomLeftRadius: 16,
		zIndex: 1200,
	},
	mobilePreviewMessageText: {
		transform: "rotate(-90deg)",
		marginTop: 76,
		fontSize: 22,
		letterSpacing: 1,
	},
	titleCloseIcon: {
		float: "right",
	},
	personalizedOptionText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		marginTop: 7,
		marginLeft: 14,
	},
	personalizedOption: {
		display: "flex",
		height: 40,
		width: 140,
		backgroundColor: "rgb(245,246,247)",
		border: "1px solid rgb(227, 231, 240)",
		cursor: "pointer",
		marginRight: 8,
		"&:hover": {
			opacity: 0.8,
		},
		[theme.breakpoints.down("sm")]: {
			width: "50%",
			marginRight: 0,
		},
	},
	personalizedOptionIcons: {
		color: theme.palette.secondaryResponse.main,
		cursor: "pointer",
		marginRight: 5,
		marginTop: 7,
	},
}));

interface Props {
	journey: Journey;
}

export function JourneyMessagePreviewStep(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useJourneyMessagePreviewStyles() };
	const { journey } = props;
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const progress = useJourneySetupProgress();
	const { fieldErrors } = progress;

	const [addMessageExtra, setAddMessageExtra] = useState<MessageExtraType | undefined>();

	const completionResponseInputRef = React.useRef<HTMLInputElement>(null);
	const [selectionStartCompletionResponse, setSelectionStartCompletionResponse] = useState<number | null>(null);
	const updateSelectionStartCompletionResponse = () => {
		setSelectionStartPrompt(null);
		setSelectionStartCompletionResponse(
			completionResponseInputRef.current != null ? completionResponseInputRef.current.selectionStart : null
		);
	};

	const promptResponseInputRef = React.useRef<HTMLInputElement>(null);
	const [selectionStartPrompt, setSelectionStartPrompt] = useState<number | null>(null);
	const updateSelectionStartPrompt = () => {
		setSelectionStartCompletionResponse(null);
		setSelectionStartPrompt(promptResponseInputRef.current != null ? promptResponseInputRef.current.selectionStart : null);
	};

	const [completionResponse, setCompletionResponse] = useState(journey.completionResponse);
	const [promptMessage, setPromptMessage] = useState<string | undefined>(
		journey.category === JourneyCategory.Prompt ? journey.prompt : undefined
	);

	const checkMissingPersonalizationTokens = (text: string, journey: Journey) => {
		let tokens = text.match(/\{{(.*?)\}}/g) || [];
		let knownTokenErrorList: string[] = [];
		let unknownTokenErrorList: string[] = [];

		tokens.forEach((token) => {
			if (token === "{{Media}}" && !journey.extras.mediaLink) {
				knownTokenErrorList.push("{{Media}}");
			} else if (token === "{{Link}}" && !journey.extras.link) {
				knownTokenErrorList.push("{{Link}}");
			} else if (token === "{{Event}}" && journey.category === JourneyCategory.Informational && !journey.event) {
				knownTokenErrorList.push("{{Event}}");
			} else if (token === "{{Address}}" && !journey.extras.address) {
				knownTokenErrorList.push("{{Address}}");
			} else if (token === "{{Form}}" && !journey.extras.formId) {
				knownTokenErrorList.push("{{Form}}");
			} else if (SupportedPersonalization.indexOf(token) < 0) {
				unknownTokenErrorList.push(token);
			}
		});

		let knownMessage =
			knownTokenErrorList.length > 0
				? knownTokenErrorList.join(" ,") +
				  (knownTokenErrorList.length !== 1 ? " are" : " is") +
				  " in your message but missing from your Journey."
				: "";
		let unknownMessage =
			unknownTokenErrorList.length > 0
				? unknownTokenErrorList.join(" ,") +
				  (unknownTokenErrorList.length !== 1 ? " are" : " is") +
				  " in your message but not part of Response personalization system."
				: "";
		return (knownMessage + " " + unknownMessage).trim();
	};

	const completeStep = () => {
		let updatedJourney: Journey = { ...journey, completionResponse };
		if (promptMessage && updatedJourney.category === JourneyCategory.Prompt) {
			updatedJourney.prompt = promptMessage;
		}
		progress.completeStep(updatedJourney);
	};

	const backStep = () => {
		if (progress.backStep) {
			let updatedJourney: Journey = { ...journey, completionResponse };
			if (promptMessage && updatedJourney.category === JourneyCategory.Prompt) {
				updatedJourney.prompt = promptMessage;
			}
			progress.backStep(updatedJourney);
		}
	};

	const addPersonalization = (token: string) => {
		if (selectionStartCompletionResponse != null) {
			let textBeforeCursorPosition = completionResponse.substring(0, selectionStartCompletionResponse);
			let textAfterCursorPosition = completionResponse.substring(
				selectionStartCompletionResponse,
				completionResponse.length
			);
			setCompletionResponse(`${textBeforeCursorPosition} ${token} ${textAfterCursorPosition}`);
		} else if (selectionStartPrompt != null && promptMessage) {
			let textBeforeCursorPosition = promptMessage.substring(0, selectionStartPrompt);
			let textAfterCursorPosition = promptMessage.substring(selectionStartPrompt, promptMessage.length);
			setPromptMessage(`${textBeforeCursorPosition} ${token} ${textAfterCursorPosition}`);
		} else {
			setCompletionResponse(completionResponse + " " + token);
		}
	};

	const onAddJourneyExtra = (extra: MessageExtraType) => {
		setAddMessageExtra(extra);
	};

	const onJourneyUpdatedWithMessageExtra = (updatedJourney: Journey, type: MessageExtraType) => {
		const message = completionResponse.toLowerCase();

		if (type === MessageExtraType.Address && !message.includes("{{address}}")) {
			addPersonalization("{{Address}}");
		} else if (type === MessageExtraType.Event && !message.includes("{{event}}")) {
			addPersonalization("{{Event}}");
		} else if (type === MessageExtraType.Link && !message.includes("{{link}}")) {
			addPersonalization("{{Link}}");
		} else if (type === MessageExtraType.Media && !message.includes("{{media}}")) {
			addPersonalization("{{Media}}");
		} else if (type === MessageExtraType.Form && !message.includes("{{form}}")) {
			addPersonalization("{{Form}}");
		}
		progress.updateJourney(updatedJourney);
		setAddMessageExtra(undefined);
	};

	if (addMessageExtra) {
		return (
			<AddJourneyMessageExtra
				journey={journey}
				type={addMessageExtra}
				onCancel={() => setAddMessageExtra(undefined)}
				onUpdate={onJourneyUpdatedWithMessageExtra}
			/>
		);
	}

	return (
		<JourneyStepLayout required={true} onConfirmStep={completeStep} onBackStep={backStep}>
			<Grid item xs={mobileFormFactor ? 12 : 8} style={{ color: "#000" }}>
				<Grid
					container
					style={{
						paddingLeft: mobileFormFactor ? 0 : 32,
						marginTop: 24,
						overflowY: mobileFormFactor ? "auto" : "inherit",
					}}
				>
					{FieldValidationErrorUtils.isFieldInError(fieldErrors, FieldErrorStrings.Event_Past_Due) && (
						<Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
							The event for this journey is in the past, you will not be able to change this journey
						</Typography>
					)}
					<Grid item xs={12}>
						<Typography className={classes.progressLabelText} variant="subtitle2">
							What would you like to say when somebody texts <b>{journey.keywordTrigger}</b>?
						</Typography>
					</Grid>
					{journey.category === JourneyCategory.Prompt && (
						<Grid item xs={12} style={{ marginTop: 16 }}>
							<FormControl
								style={{ width: "100%" }}
								error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Prompt")}
							>
								<Typography className={classes.progressLabelLighterText} variant="subtitle2">
									Message One
								</Typography>
								<TextField
									id="outlined-bare"
									value={promptMessage}
									inputRef={promptResponseInputRef}
									onSelect={updateSelectionStartPrompt}
									margin="normal"
									variant="outlined"
									style={{ width: "100%", marginTop: 0 }}
									multiline={true}
									rowsMax={4}
									onChange={(e) => {
										//updateJourneyPromptMessage(e.currentTarget.value);
										setPromptMessage(e.currentTarget.value);
									}}
									error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Prompt")}
								/>
								<FormHelperText
									dangerouslySetInnerHTML={{
										__html: FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "Prompt"),
									}}
								/>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12} style={{ marginTop: 16 }}>
						<FormControl
							style={{ width: "100%" }}
							error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "CompletionResponse")}
						>
							<Typography className={classes.progressLabelLighterText} variant="subtitle2">
								{journey.category === JourneyCategory.Prompt ? "Message Two" : "Type a response"}
							</Typography>
							<TextField
								id="outlined-bare"
								inputRef={completionResponseInputRef}
								onSelect={updateSelectionStartCompletionResponse}
								value={completionResponse}
								margin="normal"
								variant="outlined"
								style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
								multiline={true}
								rowsMax={4}
								onChange={(e) => {
									// updateJourneyCompletionMessage(e.currentTarget.value);
									setCompletionResponse(e.currentTarget.value);
								}}
								error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "CompletionResponse")}
							/>
							<FormHelperText
								dangerouslySetInnerHTML={{
									__html: FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "CompletionResponse"),
								}}
							/>
							<FormHelperText
								style={{ color: theme.palette.error.main }}
								dangerouslySetInnerHTML={{
									__html: checkMissingPersonalizationTokens(completionResponse, journey),
								}}
							/>
						</FormControl>
					</Grid>
					<JourneyMessageExtras
						journey={journey}
						onAddPersonalization={addPersonalization}
						onSetJourneyExtra={onAddJourneyExtra}
					/>
				</Grid>
			</Grid>
			<MobileMessagePreview journey={journey} completionResponse={completionResponse} promptMessage={promptMessage} />
		</JourneyStepLayout>
	);
}
