import TextField, { TextFieldProps } from "@material-ui/core/TextField/TextField";
import React from "react";
import { PhoneMask } from "./PhoneMask";

interface Props {
    onChangePhoneNumber: (phoneNumber: string) => void;
}

export function PhoneTextField(props: TextFieldProps & Props) {
    const { onChangePhoneNumber, ...textFieldProps } = props;
    return (
        <TextField
            {...textFieldProps}
            onChange={(e) => onChangePhoneNumber(e.target.value.replace(/\D/g, ""))}
            type="tel"
            inputProps={{
                pattern: props.value ? "\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}" : undefined,
            }}
            onFocus={(e) => {
                e.preventDefault();
                const { target } = e;
                target.focus();
                const blankIndex = (target.value ?? "").indexOf("_");
                if (blankIndex !== -1) {
                    target.setSelectionRange(blankIndex, blankIndex + 1);
                }
            }}
            InputProps={{
                inputComponent: PhoneMask,
            }}
        />
    );
}