import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import TodoStepDot from "@material-ui/icons/Brightness1";
import CompletedStepDot from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme: Theme) => ({
	stepDotLabel: {
		color: theme.palette.secondary.main,
		fontWeight: "bold",
		marginRight: 6,
	},
	dot: {
		height: 18,
		width: 18,
		color: theme.palette.secondary.main,
		marginRight: 6,
		marginTop: 2,
	},
	progressConnector: {
		backgroundColor: theme.palette.secondary.main,
		height: 3,
		width: 120,
		marginRight: 6,
		marginTop: 8,
	},
}));

export interface StepProps {
	complete?: boolean;
	active?: boolean;
	onClick?: () => void;
}

export function Step(props: React.PropsWithChildren<StepProps>) {
	const { complete, active } = props;
	const classes = useStyles();

	return (
		<Grid container alignItems="flex-start" alignContent="flex-start" wrap="nowrap">
			<Grid item>
				{complete ? (
					<CompletedStepDot
						className={classes.dot}
						style={{ cursor: props.onClick ? "pointer" : undefined }}
						onClick={props.onClick}
					/>
				) : (
					<TodoStepDot
						className={classes.dot}
						style={{ opacity: !active ? 0.5 : undefined, cursor: props.onClick ? "pointer" : undefined }}
						onClick={props.onClick}
					/>
				)}
			</Grid>
			<Grid item>
				<Typography
					onClick={props.onClick}
					noWrap
					className={classes.stepDotLabel}
					style={{ opacity: !active && !complete ? 0.5 : undefined, cursor: props.onClick ? "pointer" : undefined }}
					variant="subtitle2"
				>
					{props.children}
				</Typography>
			</Grid>
		</Grid>
	);
}
