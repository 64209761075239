import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	makeStyles,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import Auth from "../../authorization/Auth";
import { PageTitle } from "../../components/PageTitle";
import { PhoneMask } from "../../components/PhoneMask";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { useUser } from "../../hooks/useUser";
import AuthService from "../../services/AuthService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import TabHeaderBar, { SettingsTab } from "./TabHeaderBar";

export const usePersonalStyles = makeStyles((theme: Theme) => ({}));

const PersonalInfo = () => {
	const setServerErrorAlert = useServerErrorAlert();
	const setServerSuccessAlert = useSuccessAlert();

	const [user] = useAppUser();
	const [, setUser] = useUser();

	const [isEnabled, setEnable] = useState(true);

	const [firstName, setFirstName] = useState<string>(user.firstName);
	const [firstNameError, setFirstNameError] = useState<string | undefined>();

	const [lastName, setLastName] = useState<string>(user.lastName);
	const [lastNameError, setLastNameError] = useState<string | undefined>();

	const [emailAddress, setEmailAddress] = useState<string>(user.email);
	const [emailAddressError, setEmailAddressError] = useState<string | undefined>();

	const [oldPassword, setOldPassword] = useState<string>();
	const [oldPasswordError, setOldPasswordError] = useState<string | undefined>();

	const [password, setPassword] = useState<string>();
	const [passwordError, setPasswordError] = useState<string | undefined>();

	const [confirmPassword, setConfirmPassword] = useState<string>();

	const onChangeFirstName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setFirstNameError(undefined);
		setFirstName(event.target.value);
	};

	const onChangeLastName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setLastNameError(undefined);
		setLastName(event.target.value);
	};

	const onChangeEmailAddress = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmailAddressError(undefined);
		setEmailAddress(event.target.value);
	};

	const onChangeOldPassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setOldPassword(event.target.value);
		setOldPasswordError(undefined);
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordError(undefined);
	};

	const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setConfirmPassword(event.target.value);
	};

	const onSubmitUpdateUserInfo = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!user) Auth.redirectToSignInPage();
		else {
			const request = {
				firstName,
				lastName,
				email: emailAddress,
			};

			setEnable(false);
			const result = await AuthService.update(request);
			setEnable(true);

			if (ServerResult.isSuccess(result)) {
				setUser(result.data.jwtToken);
				setServerSuccessAlert("Updated information");
			} else if (ServerResult.isValidationError(result)) {
				setFirstNameError(FieldValidationErrorUtils.getFieldError(result.errors, "FirstName"));
				setLastNameError(FieldValidationErrorUtils.getFieldError(result.errors, "LastName"));
				setEmailAddressError(FieldValidationErrorUtils.getFieldError(result.errors, "Email"));
			} else {
				setServerErrorAlert(result);
			}
		}
	};

	const onSubmitUpdatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!user) Auth.redirectToSignInPage();
		else {
			if (password !== confirmPassword) {
				setPasswordError("New password and confirm password do not match");
				return;
			}

			setEnable(false);
			const result = await AuthService.updatePassword(password!, oldPassword!);
			setEnable(true);

			if (ServerResult.isSuccess(result)) {
				setUser(result.data.jwtToken);
				setServerSuccessAlert("Updated");
			} else if (ServerResult.isValidationError(result)) {
				setPasswordError(FieldValidationErrorUtils.getFieldError(result.errors, "Password"));
				setOldPasswordError(FieldValidationErrorUtils.getFieldError(result.errors, "OldPassword"));
			} else {
				setServerErrorAlert(result);
			}
		}
	};

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Grid container spacing={2}>
			<PageTitle title="Personal Info"/>
			{!mobileFormFactor && (
				<>
					<Grid item xs={12}>
						<Typography variant="h1">Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<TabHeaderBar tab={SettingsTab.Personal_Info} />
					</Grid>
				</>
			)}
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Contact Information" style={{ paddingBottom: 0 }} />
					<form onSubmit={onSubmitUpdateUserInfo}>
						<CardContent>
							<TextField
								variant="outlined"
								disabled={!isEnabled}
								label="First Name"
								margin="normal"
								fullWidth
								required
								type="text"
								value={firstName}
								onChange={onChangeFirstName}
								error={firstNameError != null}
								helperText={firstNameError}
							/>
							<TextField
								variant="outlined"
								disabled={!isEnabled}
								label="Last Name"
								margin="normal"
								fullWidth
								required
								type="text"
								value={lastName}
								onChange={onChangeLastName}
								error={lastNameError != null}
								helperText={lastNameError}
							/>
							<TextField
								variant="outlined"
								value={user.phoneNumber ? user.phoneNumber.replace("+1", "") : ""}
								label="Your Phone Number"
								margin="normal"
								fullWidth
								disabled
								required
								onFocus={(e) => {
									e.preventDefault();
									const { target } = e;
									target.focus();
									target.setSelectionRange(1, 2);
								}}
								InputProps={{
									inputComponent: PhoneMask,
								}}
							/>
							<TextField
								variant="outlined"
								disabled={!isEnabled}
								label="E-Mail Address"
								value={emailAddress}
								onChange={onChangeEmailAddress}
								error={emailAddressError != null}
								helperText={emailAddressError}
								margin="normal"
								fullWidth
								required
								type="text"
							/>
						</CardContent>

						<CardActions>
							<Button
								disabled={!isEnabled}
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ marginLeft: 10 }}
							>
								Update
							</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Update Password" style={{ paddingBottom: 0 }} />
					<form onSubmit={onSubmitUpdatePassword}>
						<CardContent style={{ paddingBottom: 0 }}>
							<TextField
								variant="outlined"
								label="Old Password"
								value={oldPassword}
								onChange={onChangeOldPassword}
								error={oldPasswordError != null}
								helperText={oldPasswordError}
								margin="normal"
								fullWidth
								required
								type="password"
								disabled={!isEnabled}
							/>
							<TextField
								variant="outlined"
								label="New Password"
								value={password}
								onChange={onChangePassword}
								error={passwordError != null}
								helperText={passwordError}
								margin="normal"
								fullWidth
								required
								type="password"
								disabled={!isEnabled}
							/>
							<TextField
								variant="outlined"
								label="Confirm New Password"
								value={confirmPassword}
								onChange={onChangeConfirmPassword}
								margin="normal"
								fullWidth
								required
								type="password"
								disabled={!isEnabled}
							/>
						</CardContent>

						<CardActions>
							<Button
								disabled={!isEnabled}
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ marginLeft: 10 }}
							>
								Update
							</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
};
export default PersonalInfo;
