import { Grid, GridSize, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { JourneyTab } from "../../../entities/journeyTab";
import { Enum } from "../../../utillity/Enum";

export const useTabStyles = makeStyles((theme: Theme) => ({
	defaultTabStyle: {
		textAlign: "center",
		height: 48,
		boxShadow: "0 2px inset #d6d6d6, 0 -1px #c4c4c4",
		color: theme.palette.secondaryResponse.main,
		backgroundColor: theme.palette.lightGrayBlue.main,
		fontSize: 18,
		paddingTop: 12,
	},
	selectedTabStyle: {
		textDecoration: "underline",
		boxShadow: "0 -3px #dfe1e1",
		backgroundColor: "#FFF",
		fontWeight: 700,
	},
	pageTabs: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 200,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
	},
	hiddenTab: {
		opacity: 0.4,
	},
	tabIndicator: {
		backgroundColor: theme.palette.secondaryResponse.main,
		height: 3,
		position: "absolute",
		top: 24,
		transitionProperty: "all",
		transitionDuration: "300ms",
		transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
		transitionDelay: "0ms",
		width: 110,
	},
}));

type ActiveTab = "First" | "Middle" | "Last";

interface TabProps {
	tabs: JourneyTab[];
	selectedTab: JourneyTab;
	onChange: (tab: JourneyTab) => void;
}

const JourneyDetailTabs = (props: TabProps) => {
	const classes = useTabStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { onChange, tabs, selectedTab } = props;

	const selectedIndex = tabs.indexOf(selectedTab);
	let activeTab: ActiveTab = "Middle";
	if (selectedIndex === 0) {
		activeTab = "First";
	} else if (selectedIndex === tabs.length - 1) {
		activeTab = "Last";
	}

	const tabGridWidth = (12 / tabs.length) as GridSize;

	if (mobileFormFactor) {
		return (
			<Grid container style={{ marginTop: -4 }}>
				{tabs.map((tab, i) => (
					<Grid item xs={tabGridWidth} key={i}>
						<MobileTab tabs={tabs} tab={tab} activeTab={activeTab} onSelect={props.onChange} />
					</Grid>
				))}
			</Grid>
		);
	}

	const tabIndicatorXPos = activeTab === "First" ? 0 : activeTab === "Last" && tabs.length > 2 ? 400 : 200;
	return (
		<Grid container style={{ position: "relative" }}>
			{tabs.map((tab, i) => (
				<Typography
					className={classNames(classes.pageTabs, selectedTab !== tab && classes.hiddenTab)}
					variant="h2"
					onClick={() => onChange(tab)}
					key={i}
				>
					{Enum.print(JourneyTab, tab)}
				</Typography>
			))}
			<span className={classes.tabIndicator} style={{ left: tabIndicatorXPos }} />
		</Grid>
	);
};

interface MobileTabProps {
	tab: JourneyTab;
	activeTab: ActiveTab;
	tabs: JourneyTab[];
	onSelect: (selectedTab: JourneyTab) => void;
}

function MobileTab(props: MobileTabProps) {
	const { tabs, tab, activeTab } = props;
	const tabIndex = tabs.indexOf(tab);

	if (tabIndex === 0) {
		return <FirstMobileTab tab={tab} activeTab={activeTab} onSelect={props.onSelect} />;
	}
	if (tabIndex === tabs.length - 1) {
		return <LastMobileTab tab={tab} activeTab={activeTab} onSelect={props.onSelect} />;
	}

	return <MiddleMobileTab tab={tab} activeTab={activeTab} onSelect={props.onSelect} />;
}

function FirstMobileTab(props: { tab: JourneyTab; activeTab: ActiveTab; onSelect: (selectedTab: JourneyTab) => void }) {
	const { tab, activeTab } = props;
	const classes = useTabStyles();

	return (
		<div
			style={{
				borderTopLeftRadius: 9,
				borderBottomRightRadius: activeTab === "Middle" ? 4 : 0,
			}}
			className={classNames(classes.defaultTabStyle, activeTab === "First" && classes.selectedTabStyle)}
			onClick={() => props.onSelect(props.tab)}
		>
			{Enum.print(JourneyTab, tab)}
		</div>
	);
}

function MiddleMobileTab(props: { tab: JourneyTab; activeTab: ActiveTab; onSelect: (selectedTab: JourneyTab) => void }) {
	const { tab, activeTab } = props;
	const classes = useTabStyles();

	return (
		<div
			style={{
				borderTopRightRadius: 0,
				borderBottomLeftRadius: activeTab === "First" ? 4 : 0,
				borderBottomRightRadius: activeTab === "Last" ? 4 : 0,
			}}
			className={classNames(classes.defaultTabStyle, activeTab === "Middle" && classes.selectedTabStyle)}
			onClick={() => props.onSelect(props.tab)}
		>
			{Enum.print(JourneyTab, tab)}
		</div>
	);
}

function LastMobileTab(props: { tab: JourneyTab; activeTab: ActiveTab; onSelect: (selectedTab: JourneyTab) => void }) {
	const { tab, activeTab } = props;
	const classes = useTabStyles();

	return (
		<div
			style={{
				borderTopRightRadius: 9,
				borderBottomLeftRadius: activeTab === "Middle" ? 4 : 0,
			}}
			className={classNames(classes.defaultTabStyle, activeTab === "Last" && classes.selectedTabStyle)}
			onClick={() => props.onSelect(props.tab)}
		>
			{Enum.print(JourneyTab, tab)}
		</div>
	);
}

export default JourneyDetailTabs;
