import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { SubscriptionCanceledDialog } from "../components/SubscriptionCanceledDialog";
import { AppUserContext } from "../hooks/useAppUser";
import { PageTitleContext } from "../hooks/usePageTitle";
import { useUser } from "../hooks/useUser";
import { VerifyBrandDialog } from "../pages/SignUp/VerifyBrandDialog/VerifyBrandDialog";
import { AppDataProvider } from "../providers/AppDataProvider";
import { HubProvider } from "../providers/HubProvider";
import { JourneyNotificationProvider } from "../providers/JourneyNotificationProvider";
import { routes } from "../routes";
import ResponseSubscriptionService from "../services/ResponseSubscriptionService";
import { ServerResult } from "../services/server/ServerResult";
import Layout from "./Layout";

export default function AppLayout(props: React.PropsWithChildren<{}>) {
	const [user, setJwtUser] = useUser();
	const location = useLocation();

	const [redirectToSignUp, setRedirectToSignUp] = useState(false);
	const [subscriptionIsCanceled, setSubscriptionIsCanceled] = useState(false);
	const [title, setTitle] = useState("Response");

	useEffect(() => {
		async function checkIfSubscriptionIsCanceled() {
			// This is not really suffiencent to make sure canceled users don't use the system
			// It's kind of lame
			const subscription = await ResponseSubscriptionService.get();
			if (ServerResult.isSuccess(subscription) && subscription.data.isCanceled) {
				setSubscriptionIsCanceled(true);
			}
		}

		if (!user) {
			return setRedirectToSignUp(true);
		} else if(user && user.responsePhoneNumber) {
			checkIfSubscriptionIsCanceled();
		}
	}, [user]);

	const onChangeTitle = (newTitle: string) => {
		if (newTitle === title) {
			return;
		}
		document.title = newTitle;
		setTitle(newTitle);
	};

	if (!user) {
		return <Redirect to={`${routes.public.signInPage}?redirect=${encodeURI(location.pathname + location.search)}`} />;
	}

	if (redirectToSignUp) {
		return <Redirect to={routes.public.registerPage} />;
	}

	return (
		<AppUserContext.Provider value={[user, setJwtUser]}>
			<HubProvider>
				<AppDataProvider>
					<PageTitleContext.Provider value={[title, onChangeTitle]}>
						<JourneyNotificationProvider>
							<Layout>
								{subscriptionIsCanceled && <SubscriptionCanceledDialog />}
								<VerifyBrandDialog />
								{!subscriptionIsCanceled && props.children }
							</Layout>
						</JourneyNotificationProvider>
					</PageTitleContext.Provider>
				</AppDataProvider>
			</HubProvider>
		</AppUserContext.Provider>
	);
}
