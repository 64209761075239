import {
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	makeStyles,
	Radio,
	RadioGroup,
	Snackbar,
	Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LoadingPage } from "../components/LoadingPage";
import { ServerErrorPage } from "../components/ServerErrorPage";
import { VerticalCenter } from "../components/VerticalCenter";
import { MessagePreferences, MessagePreferencesSubscription } from "../entities/MessagePreferences";
import { isMobile } from "../hooks/isMobile";
import { MessagePreferencesService, UpdateMessagePreferencesRequest } from "../services/MessagePreferencesService";
import { ServerError } from "../services/server/ServerError";
import { ServerResult } from "../services/server/ServerResult";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
	greyBox: {
		padding: 15,
		borderRadius: 15,
		backgroundColor: "#f5f6f7",
	},
}));

export function MessagePreferencesPage() {
	const mobile = isMobile();
	const classes = useStyles();
	const params = useParams<{ churchId: string; attendeeId: string }>();
	const { attendeeId, churchId } = params;

	const [messagePreferances, setMessagePreferences] = useState<MessagePreferences>();
	const [error, setError] = useState<ServerError>();
	const [disabled, setDisabled] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string>();

	const [optedIntoChurchMessages, setOptedIntoChurchMessages] = useState(true);
	const [subscriptions, setSubscriptions] = useState<MessagePreferencesSubscription[]>([]);

	useEffect(() => {
		async function loadAttendee() {
			const result = await MessagePreferencesService.get(churchId, attendeeId);
			if (ServerResult.isSuccess(result)) setMessagePreferences(result.data);
			else setError(result);
		}
		loadAttendee();
	}, [attendeeId, churchId]);

	useEffect(() => {
		if (messagePreferances) {
			setOptedIntoChurchMessages(messagePreferances.optedIntoChurchMessages);
			setSubscriptions(messagePreferances.subscriptions);
		}
	}, [messagePreferances]);

	const onChangeOptIn = async (optedIn: boolean) => {
		setOptedIntoChurchMessages(optedIn);
		await updatePreferences({attendeeId, churchId, optedIntoChurchMessages: optedIn, subscriptions})
	}

	const onChangeSubscription = async (subscription: MessagePreferencesSubscription, checked: boolean) => {
		const updatedSubscriptions = [...subscriptions].map((s) =>
			s.id === subscription.id ? { ...s, subscribed: checked } : s
		);
		setSubscriptions(updatedSubscriptions);
		await updatePreferences({attendeeId, churchId, optedIntoChurchMessages, subscriptions: updatedSubscriptions})
	};

	const updatePreferences = async (request: UpdateMessagePreferencesRequest) => {
		setDisabled(true);
		const result = await MessagePreferencesService.update(request)
		setDisabled(false);
		if(ServerResult.isSuccess(result)){
			setMessagePreferences(result.data);
			const message = request.optedIntoChurchMessages ? "We've updated your preferences" : `You will no longer receive messages from ${messagePreferances?.churchName}`;
			setSuccessMessage(message)
		} else {
			setError(result);
		}
	}

	if (error) return <ServerErrorPage serverError={error} />;

	if (!messagePreferances) return <LoadingPage />;

	const { firstName, churchName } = messagePreferances;

	return (
		<Containter>
			<Grid container justify={mobile ? "flex-end" : "flex-start"} spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h2" gutterBottom>
						Messaging Preferences
					</Typography>
					<Typography variant="body1">
						{firstName}, how do you want {churchName} to message you?
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.greyBox}>
						<Typography variant="h4" gutterBottom>
							I want messages from {churchName}:
						</Typography>
						<RadioGroup
							aria-label="choice"
							value={optedIntoChurchMessages}
							onChange={(e) => onChangeOptIn(e.target.value === "yes")}
						>
							<FormControlLabel
								value="yes"
								control={<Radio disableRipple color="primary" checked={optedIntoChurchMessages} />}
								label="Yes"
								disabled={disabled}
							/>
							<FormControlLabel
								value="no"
								control={<Radio disableRipple color="primary" checked={!optedIntoChurchMessages} />}
								label="No"
								disabled={disabled}
							/>
						</RadioGroup>
					</div>
				</Grid>
				{subscriptions.length > 0 && (
					<Grid item xs={12}>
						<div className={classes.greyBox}>
							<Typography variant="h4" gutterBottom>
								Message me about:
							</Typography>
							<FormControl component="fieldset">
								{subscriptions.map((subscription, i) => (
									<FormControlLabel
										key={i}
										control={
											<Checkbox
												onChange={(e) => onChangeSubscription(subscription, e.target.checked)}
												disableRipple
												disabled={!optedIntoChurchMessages || disabled}
												value={subscription.id}
												checked={subscription.subscribed}
												color="primary"
											/>
										}
										style={{
											color: messagePreferances.subscriptions.some(
												(s) => s.id === subscription.id && s.subscribed
											)
												? undefined
												: "#666",
										}}
										label={`${subscription.name}${
											messagePreferances.subscriptions.some((s) => s.id === subscription.id && s.subscribed)
												? ""
												: " [Unsubscribed]"
										}`}
									/>
								))}
							</FormControl>
						</div>
					</Grid>
				)}
			</Grid>
			<Snackbar open={successMessage != null} autoHideDuration={6000} onClose={() => setSuccessMessage(undefined)}>
				<MuiAlert elevation={6} variant="filled" severity="success" style={{fontWeight: 600}} >
					{successMessage}
				</MuiAlert>
			</Snackbar>
		</Containter>
	);
}

function Containter(props: React.PropsWithChildren<{}>) {
	if (isMobile()) return <div style={{ padding: 10, backgroundColor: "#FFF", height: "100%"}}>{props.children}</div>;

	return (
		<VerticalCenter>
			<Grid container justify="center" style={{maxHeight: "100vh", overflowY: "auto"}}>
				<Grid item>
					<Card style={{ margin: 20, padding: 40, maxWidth: 700 }}>{props.children}</Card>
				</Grid>
			</Grid>
		</VerticalCenter>
	);
}
