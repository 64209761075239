import { FormControl, Grid, IconButton, makeStyles, MenuItem, Select, Theme, Typography, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { DayOfWeek } from "../../../entities/DayOfWeek";
import { JourneyType, MessageSchedule, SermonPromptJourney } from "../../../entities/Journey";
import { setDateTime } from "../../../functions/datetime";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { FieldErrorStrings } from "../journeySetUp";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout, useJourneyStepStyles } from "./JourneyStepLayout";

const useStyles = makeStyles((theme: Theme) => ({
	hours: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderRight: "none",
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
	},
	minutes: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderLeft: "none",
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
		"& > div:focus": {
			backgroundColor: "#FFF",
		},
	},
	twelveHour: {
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
		"& > div:focus": {
			backgroundColor: "#FFF",
		},
	},
	niceInputText: {
		color: "rgb(129, 138, 145)",
		fontSize: 20,
	},
	minutesInput: {
		paddingLeft: "9px !important",
		paddingRight: "18px !important",
	},
	hoursInput: {
		paddingLeft: "18px !important",
		paddingRight: "9px !important",
	},
	twelveHourInput: {
		paddingRight: "15px !important",
	},
	colonSpan: {
		borderTop: "1px solid rgba(0,0,0,.23)",
		borderBottom: "1px solid rgba(0,0,0,.23)",
		fontSize: 27,
		paddingTop: 4,
		color: "rgb(129, 138, 145)",
	},
	daySelector: {
		color: theme.palette.secondaryResponse.main,
		height: 50,
		width: 50,
		marginRight: 4,
		"&:hover": {
			backgroundColor: "#c0cfec",
			opacity: 0.7,
		},
		[theme.breakpoints.down("sm")]: {
			height: 40,
			width: 40,
		},
	},
	daySelected: {
		backgroundColor: "#c0cfec",
	},
}));

export enum TwelveHour {
	AM = 0,
	PM = 1,
}

interface Props {
	journey: SermonPromptJourney;
}

export function SermonPromptScheduleStep(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useStyles() };
	const { journey } = props;
	const theme = useTheme();

	const progress = useJourneySetupProgress();

	const [messageSchedule, setMessageSchedule] = useState<MessageSchedule>(journey.promptMessageSchedule);
	const [mightSendMessage, setMightSendMessage] = useState(false);

	let hour: string = "09";
	let minute: string = "00";
	let defaultTwelveHour: TwelveHour = TwelveHour.AM;

	let splits = messageSchedule.timeToRun.split(":");

	if (splits.length === 3) {
		defaultTwelveHour = +splits[0] < 12 ? TwelveHour.AM : TwelveHour.PM;
		hour = +splits[0] > 12 ? (+splits[0] % 12).toString() : splits[0];

		if (hour.length === 1) {
			hour = "0" + hour;
		}

		minute = splits[1];
	}

	const hours = hour;
	const minutes = minute;
	const [twelveHour, setTwelveHour] = useState(defaultTwelveHour);
	const daysOfWeek =
		journey.type === JourneyType.Sermon_Prompts ? messageSchedule.daysToRun : [DayOfWeek.Monday, DayOfWeek.Wednesday];

	const updateDaysOfWeek = (dayOfWeek: DayOfWeek) => {
		let days = daysOfWeek;
		if (days.includes(dayOfWeek)) {
			days = [...daysOfWeek.filter((a) => a !== dayOfWeek)];
		} else {
			days = [...daysOfWeek, dayOfWeek];
		}
		setMessageSchedule({ timeToRun: `${hours}:${minutes}:00`, daysToRun: days });
	};

	const completeStep = () => {
		let schedule = messageSchedule;

		if (twelveHour === TwelveHour.PM) {
			let hr = +hours < 12 ? +hours + 12 : hours;

			schedule = {
				...schedule,
				timeToRun: `${hr}:${minutes}:00`,
			};
		} else {
			let hr = +hours > 12 ? +hours % 12 : +hours;
			schedule = {
				...schedule,
				timeToRun: `${hr}:${minutes}:00`,
			};
		}

		if (FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.MessageSchedule)) {
			progress.setFieldErrors(progress.fieldErrors.filter((a) => a.field !== FieldErrorStrings.MessageSchedule));
		}

		progress.completeStep({ ...props.journey, promptMessageSchedule: schedule });
	};

	const checkIfMessageMightSendEarly= (hour: string, minutes: string, amPm: TwelveHour) => {
		const minutesNum = parseInt(minutes);
		const hoursNum = parseInt(hour) + (amPm === TwelveHour.PM ? 12 : 0);
		const updatedTime = `${String(hoursNum).padStart(2, '0')}:${String(minutesNum).padStart(2, '0')}:00`
		
		const before = setDateTime(new Date(), messageSchedule.timeToRun);
		const after = setDateTime(new Date(), updatedTime);
		const now = new Date();

		if (after.getTime() < now.getTime() && now.getTime() < before.getTime()) {
			setMightSendMessage(true);
		}
	}

	return (
		<JourneyStepLayout required={true} onConfirmStep={completeStep}>
			<Grid item xs={12}>
				<Grid container style={{ paddingLeft: 32, marginTop: 24 }}>
					<Grid item xs={12}>
						<Typography className={classes.progressLabelText} variant="subtitle2">
							What time would you like these messages to be sent out
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControl style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
							<FormControl variant="outlined">
								<Select
									className={classes.hours}
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={hours}
									onChange={(e) => {
										setMessageSchedule({
											timeToRun: `${e.target.value as string}:${minutes}:00`,
											daysToRun: daysOfWeek,
										});						
										
										checkIfMessageMightSendEarly(e.target.value as string, minutes, twelveHour)
									}}
									inputProps={{
										classes: {
											root: classNames(classes.niceInputText, classes.hoursInput),
										},
									}}
								>
									<MenuItem value={"12"}>12</MenuItem>
									<MenuItem value={"01"}>01</MenuItem>
									<MenuItem value={"02"}>02</MenuItem>
									<MenuItem value={"03"}>03</MenuItem>
									<MenuItem value={"04"}>04</MenuItem>
									<MenuItem value={"05"}>05</MenuItem>
									<MenuItem value={"06"}>06</MenuItem>
									<MenuItem value={"07"}>07</MenuItem>
									<MenuItem value={"08"}>08</MenuItem>
									<MenuItem value={"09"}>09</MenuItem>
									<MenuItem value={"10"}>10</MenuItem>
									<MenuItem value={"11"}>11</MenuItem>
								</Select>
							</FormControl>
							<span className={classes.colonSpan}>:</span>
							<FormControl variant="outlined">
								<Select
									className={classes.minutes}
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={minutes}
									onChange={(e) => {
										setMessageSchedule({
											timeToRun: `${hours}:${e.target.value as string}:00`,
											daysToRun: daysOfWeek,
										});
										checkIfMessageMightSendEarly(hours, e.target.value as string, twelveHour)
									}}
									inputProps={{
										classes: {
											root: classNames(classes.niceInputText, classes.minutesInput),
										},
									}}
								>
									<MenuItem value={"00"}>00</MenuItem>
									<MenuItem value={"15"}>15</MenuItem>
									<MenuItem value={"30"}>30</MenuItem>
									<MenuItem value={"45"}>45</MenuItem>
								</Select>
							</FormControl>
							<FormControl variant="outlined" style={{ marginLeft: 12 }}>
								<Select
									className={classes.twelveHour}
									labelId="ampm-select-outlined-label"
									id="ampm-select-outlined"
									value={twelveHour}
									onChange={(e) => {
										const updatedTwelveHour: TwelveHour = +(e.target.value as string);
										setTwelveHour(updatedTwelveHour)
										checkIfMessageMightSendEarly(hours, minutes, updatedTwelveHour)
									}}
									inputProps={{
										classes: {
											root: classNames(classes.niceInputText, classes.twelveHourInput),
										},
									}}
								>
									<MenuItem value={TwelveHour.AM}>AM</MenuItem>
									<MenuItem value={TwelveHour.PM}>PM</MenuItem>
								</Select>
							</FormControl>
						</FormControl>
					</Grid>
					{
						mightSendMessage
						&&
						<Grid item xs={12}>
							<Typography>Warning: Changing the scheduled time can cause a message scheduled for today to send early</Typography>
						</Grid>
					}
					<Grid item xs={12} style={{ marginTop: 40 }}>
						<Typography className={classes.progressLabelText} variant="subtitle2">
							Select one or multiple days
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControl
							style={{ marginTop: 16, display: "flex", flexDirection: "row" }}
							error={FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.MessageSchedule)}
						>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Sunday) !== undefined ? classes.daySelected : undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Sunday)}
								aria-label="sunday"
							>
								S
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Monday) !== undefined ? classes.daySelected : undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Monday)}
								aria-label="monday"
							>
								M
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Tuesday) !== undefined
										? classes.daySelected
										: undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Tuesday)}
								aria-label="tuesday"
							>
								T
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Wednesday) !== undefined
										? classes.daySelected
										: undefined
								)}
								aria-label="wednesday"
								onClick={() => updateDaysOfWeek(DayOfWeek.Wednesday)}
							>
								W
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Thursday) !== undefined
										? classes.daySelected
										: undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Thursday)}
								aria-label="thursday"
							>
								T
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Friday) !== undefined ? classes.daySelected : undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Friday)}
								aria-label="friday"
							>
								F
							</IconButton>
							<IconButton
								className={classNames(
									classes.daySelector,
									daysOfWeek.find((a) => a === DayOfWeek.Saturday) !== undefined
										? classes.daySelected
										: undefined
								)}
								onClick={() => updateDaysOfWeek(DayOfWeek.Saturday)}
								aria-label="saturday"
							>
								S
							</IconButton>
						</FormControl>
						{FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.MessageSchedule) && (
							<Typography
								className={classes.progressLabelText}
								style={{ color: theme.palette.error.main, fontSize: 14 }}
								variant="subtitle2"
							>
								{FieldValidationErrorUtils.getFieldErrorSummary(
									progress.fieldErrors,
									FieldErrorStrings.MessageSchedule
								)}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
		</JourneyStepLayout>
	);
}
