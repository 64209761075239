import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Journey } from "../entities/Journey";
import { JwtUser } from "../entities/JwtUser";
import { PersonalizedMessage } from "../utillity/Personalization";

export const usePersonalizedStyles = makeStyles(() => ({
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		padding: 8,
		height: 72,
		"& > textarea": {
			height: "100%",
		},
	},
	editBox: {
		width: "100%",
	},
	pretendBox: {
		minHeight: 72,
		border: "1px solid rgb(0,0,0,.2)",
		borderRadius: 4,
		width: "100%",
		padding: 8,
		paddingTop: 4,
		maxHeight: 240,
		overflowY: "auto",
	},
}));

const PersonalizedTextBox = (props: { message: string; journey: Journey; user: JwtUser; onChange: (value: string) => void }) => {
	const classes = usePersonalizedStyles();
	const { message, journey, user, onChange } = props;
	const [hasFocus, setHasFocus] = useState(false);

	if (hasFocus) {
		return (
			<TextField
				style={{ width: "100%" }}
				id="outlined-multiline-static"
				multiline
				rows={2}
				variant="outlined"
				className={classes.editBox}
				autoFocus
				onBlur={() => {
					setHasFocus(false);
				}}
				value={message}
				onChange={(e) => onChange(e.currentTarget.value)}
				InputProps={{
					classes: {
						root: classes.messageBox,
					},
				}}
			/>
		);
	} else {
		return (
			<div
				onClick={() => {
					setHasFocus(true);
				}}
				className={classes.pretendBox}
			>
				<Typography variant="subtitle1">
					<PersonalizedMessage message={message} journey={journey} user={user} />
				</Typography>
			</div>
		);
	}
};

export default PersonalizedTextBox;
