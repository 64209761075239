import React from "react";
import { ButtonLink } from "../../Groups/ButtonLink";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { AwesomeCheckbox } from "../../../components/AwesomeCheckbox";
import { HorizontalCentered } from "../../../components/HorizontalCentered";
import NextArrow from "@material-ui/icons/KeyboardArrowRight";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Grid, Typography, Button, useTheme } from "@material-ui/core";
import { downloadCsv } from "../../../utillity/CsvHelper";
import { CsvValidationError, ImportPeopleCsvModel, useCsvFileStepStyles } from "./UploadCsvFileStep";
import { ScrollTo } from "../../../components/ScrollTo";

interface CsvResultsProps {
	results: ImportPeopleCsvModel[];
	resultErrors: CsvValidationError[];
	fileName: string | undefined;
	onReuploadClick: () => void;
    onImportClicked: () => void;
}

export function CsvResults(props: CsvResultsProps) {
	const { results, resultErrors } = props;
	const theme = useTheme();
	const classes = useCsvFileStepStyles();

	const validCount = results.filter((r) => r.valid).length;
	const invalidCount = results.filter((r) => !r.valid).length;
	const existsCount = results.filter((r) => r.valid).filter((r) => r.exists).length;
	const notExistsCount = results.filter((r) => r.valid).filter((r) => !r.exists).length;
	const missingEmailCount = results.filter((r) => !r.exists && r.email === "").length;

	const onDownloadErrorDetails = () => {
		const rows = [
			["FirstName", "LastName", "Phone", "Email", "Error"],
			...results.map((result, index) => {
				const errors = resultErrors.filter((e) => e.row === index + 2);
				return [result.firstName, result.lastName, result.phone, result.email, errorSummary(errors)];
			}),
		];
		downloadCsv(`${props.fileName?.replace(/\.[^/.]+$/, "") ?? "import-people"}-with-errors.csv`, rows);
	};

	return (
		<Grid container direction="column" style={{ marginTop: 50 }}>
			<Grid item>
				<Typography variant="h1" align="center" style={{ margin: 13 }}>
					{invalidCount > 0 ? (
						<ErrorOutlineIcon style={{ width: "3em", height: "3em" }} />
					) : (
						<AwesomeCheckbox color="secondary" size={55} />
					)}
				</Typography>
				<Typography variant="h1" align="center" style={{ fontWeight: 500, fontSize: 26, marginBottom: 13 }}>
					{validCount === 0 ? (
						<>Oops, we weren't able to add anyone from the file you chose:</>
					) : invalidCount > 0 ? (
						<>
							We can import <strong>{validCount}</strong> of the <strong>{results.length} people</strong> you added:
						</>
					) : (
						<>
							Ready to import{" "}
							<strong>
								{validCount} {validCount === 1 ? "person" : "people"}
							</strong>
						</>
					)}
				</Typography>
			</Grid>
			<Grid item>
				<HorizontalCentered>
					<table style={{ fontSize: 18, color: "#4c4c4c" }}>
						<tbody>
							{invalidCount > 0 && (
								<tr>
									<td> </td>
									<td>{results.length}</td>
									<td>Requested in CSV</td>
								</tr>
							)}
							{notExistsCount > 0 && (
								<tr>
									<td>
										<CheckIcon color="secondary" className={classes.checkListIcon} />
									</td>
									<td>{notExistsCount}</td>
									<td>{notExistsCount === 1 ? "Person" : "People"} will be invited</td>
								</tr>
							)}
							{existsCount > 0 && (
								<tr>
									<td>
										<CheckIcon color="secondary" className={classes.checkListIcon} />
									</td>
									<td>{existsCount}</td>
									<td>{existsCount === 1 ? "Person" : "People"} will be updated</td>
								</tr>
							)}
							{missingEmailCount > 0 && (
								<tr>
									<td>
										<WarningIcon
											className={classes.checkListIcon}
											style={{ color: theme.palette.warning.main }}
										/>
									</td>
									<td>{missingEmailCount}</td>
									<td>Email {missingEmailCount === 1 ? "address" : "addresses"} missing (optional)</td>
								</tr>
							)}
							{invalidCount > 0 && (
								<tr>
									<td>
										<NotInterestedIcon
											className={classes.checkListIcon}
											style={{ color: theme.palette.error.main }}
										/>
									</td>
									<td>{invalidCount}</td>
									<td>
										Cannot be imported:{" "}
										<ButtonLink onClick={onDownloadErrorDetails}>Download error details</ButtonLink>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</HorizontalCentered>
			</Grid>
			<Grid item>
				<Grid container justify="center" alignItems="center" style={{ marginTop: 15 }} spacing={2}>
					{invalidCount > 0 && (
						<Grid item>
							<Button
								variant="outlined"
								color="primary"
								className={classes.fatButton}
								startIcon={<CloudUploadIcon />}
								onClick={props.onReuploadClick}
							>
								Upload a new CSV
							</Button>
						</Grid>
					)}
					<Grid item>
						<ScrollTo>
							<Button variant="contained" color="primary" className={classes.fatButton} onClick={props.onImportClicked} startIcon={<NextArrow />}>
								{invalidCount === 0
									? "Start Import"
									: `Import ${validCount} ${validCount === 1 ? "person" : "people"}`}
							</Button>
						</ScrollTo>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const errorSummary = (errors: CsvValidationError[]) => {
	if (errors.length === 0) return "";
	if (errors.length === 1) return errors[0].error;
	if (errors.length === 2) return `${errors[0].error} and ${errors[1].error}`;
	const firstErrors = errors.map((e) => e.error).splice(0, errors.length - 1);
	const lastError = errors[errors.length - 1];
	return `${firstErrors.join(", ")}, and ${lastError.error}`;
};
