import {
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	MenuItem,
	Select,
	Theme,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Close";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React, { useState } from "react";
import { Question, QuestionType } from "../../entities/Forms/Question";
import { QuestionChoiceType, QuestionMultipleChoice, QuestionSignupListChoice } from "../../entities/Forms/QuestionChoice";
import ResponseSwitch from "../responseSwitch";
import { MultipleChoiceAnswerDetailBuilder } from "./Questions/MultipleChoiceAnswerDetailBuilder";
import { RSVPAnswerDetail } from "./Questions/RSVPAnswerDetail";
import { ShortAnswerDetail } from "./Questions/ShortAnswerQuestion";
import { SignupListAnswerDetail } from "./Questions/SignupListAnswerDetail";

const useQuestionBuilderStyles = makeStyles((theme: Theme) => ({
	questionContainer: {
		overflow: "hidden",
		backgroundColor: "#f5f6f7",
		borderRadius: 12,
		padding: 14,
		paddingTop: 4,
		marginBottom: 14,
	},
	titleCloseIcon: {
		float: "right",
		marginTop: -4,
		marginRight: -8,
	},
	grow: {
		flex: 1,
	},
	closeIcon: {
		marginTop: -8,
		marginRight: -8,
	},
	questionPromptBase: {
		width: 240,
	},
	inputText: {
		color: theme.palette.secondaryResponse.main,
		fontWeight: 500,
		fontSize: 16,
	},
	numberQuestion: {
		marginRight: 5,
		paddingTop: 10,
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
	},
	promptBox: {
		borderBottom: "1px solid rgba(0,0,0,.2)",
		height: "fit-content",
	},
	questionFormSelect: {},
	questionSelect: {
		backgroundColor: "#e2eaf3",
		border: "none",
		width: 240,
		borderRadius: 12,
		paddingLeft: 14,
		color: "rgba(0,0,0,.6)",
		fontSize: 16,
		"&:before": {
			display: "none",
		},
		"&:after": {
			display: "none",
		},
	},
}));

interface QuestionBuilderProps {
	question: Question;
	onChange: (question: Question) => void;
	onDelete: (question: Question) => void;
}

export function QuestionBuilder(props: QuestionBuilderProps) {
	const { question } = props;
	const classes = useQuestionBuilderStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [multipleChoices, setMultipleChoices] = useState<QuestionMultipleChoice[]>(
		question.questionType === QuestionType.SingleAnswer || question.questionType === QuestionType.MultipleAnswer
			? question.choices
			: [{ text: "", choiceType: QuestionChoiceType.MultipleChoice }]
	);
	const [signUpChoices, setSignUpChoices] = useState<QuestionSignupListChoice[]>(
		question.questionType === QuestionType.SignupList
			? question.choices
			: [{ text: "", choiceType: QuestionChoiceType.SignupList, quantity: 0 }]
	);

	const onQuestionTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const updatedQuestion = { ...question };
		updatedQuestion.prompt = e.target.value;
		props.onChange(updatedQuestion);
	};

	const onQuestionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const questionType = event.target.value as QuestionType;
		const updatedQuestion = { ...question };
		updatedQuestion.questionType = questionType;
		props.onChange(updatedQuestion);
	};

	const onQuestionChoiceChange = (choices: QuestionMultipleChoice[]) => {
		const updatedQuestion = { ...question };
		if (
			updatedQuestion.questionType === QuestionType.SingleAnswer ||
			updatedQuestion.questionType === QuestionType.MultipleAnswer
		) {
			updatedQuestion.choices = [...choices];
		}
		setMultipleChoices(choices);
		props.onChange(updatedQuestion);
	};

	const onSignupListChoiceChange = (choices: QuestionSignupListChoice[]) => {
		const updatedQuestion = { ...question };
		if (updatedQuestion.questionType === QuestionType.SignupList) {
			updatedQuestion.choices = [...choices];
		}
		setSignUpChoices(choices);
		props.onChange(updatedQuestion);
	};

	const onDelete = () => {
		props.onDelete(question);
	};

	const onRequireChange = () => {
		props.onChange({ ...question, required: !question.required });
	};

	return (
		<div className={classes.questionContainer}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					{!mobileFormFactor && (
						<div style={{ display: "flex" }}>
							<div style={{ display: "flex", width: "100%" }}>
								<span className={classes.promptBox}>
									<span className={classes.numberQuestion}>{question.index + 1}.</span>
									<InputBase
										inputProps={{
											className: classes.inputText,
										}}
										className={classes.questionPromptBase}
										placeholder={"Enter your question"}
										defaultValue={question.prompt}
										onChange={onQuestionTextChange}
									/>
								</span>
							</div>
							<div className={classes.grow} />
							<FormControlLabel
								control={
									<ResponseSwitch
										checked={question.required}
										onChange={onRequireChange}
										name="required-toggle"
									/>
								}
								label={question.required ? "Required" : "Not Required"}
								style={{ marginRight: 0 }}
							/>
							<IconButton
								className={classes.titleCloseIcon}
								aria-label="close modeal"
								component="span"
								onClick={onDelete}
							>
								<CancelIcon />
							</IconButton>
						</div>
					)}
					{mobileFormFactor && (
						<>
							<div style={{ display: "flex" }}>
								<div style={{ display: "flex", width: "100%" }}>
									<span className={classes.promptBox}>
										<span className={classes.numberQuestion}>{question.index + 1}.</span>
										<InputBase
											inputProps={{
												className: classes.inputText,
											}}
											className={classes.questionPromptBase}
											placeholder={"Enter your question"}
											defaultValue={question.prompt}
											onChange={onQuestionTextChange}
										/>
									</span>
								</div>
								<div className={classes.grow} />
								<IconButton
									className={classes.titleCloseIcon}
									aria-label="close modeal"
									component="span"
									onClick={onDelete}
								>
									<CancelIcon />
								</IconButton>
							</div>
							<div style={{ display: "flex" }}>
								<div className={classes.grow} />
								<FormControlLabel
									control={
										<ResponseSwitch
											checked={question.required}
											onChange={onRequireChange}
											name="required-toggle"
										/>
									}
									label={question.required ? "Required" : "Not Required"}
									style={{ marginRight: 0 }}
								/>
							</div>
						</>
					)}
				</Grid>
				<Grid item xs={12}>
					<FormControl className={classes.questionFormSelect}>
						<Select
							className={classes.questionSelect}
							value={question.questionType}
							onChange={onQuestionTypeChange}
							IconComponent={KeyboardArrowDown}
						>
							<MenuItem value={QuestionType.ShortAnswer}>Short answer</MenuItem>
							<MenuItem value={QuestionType.SingleAnswer}>Single answer</MenuItem>
							<MenuItem value={QuestionType.MultipleAnswer}>Multiple answer</MenuItem>
							<MenuItem value={QuestionType.SignupList}>Signup list</MenuItem>
							<MenuItem value={QuestionType.RSVP}>RSVP</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				{question.questionType === QuestionType.ShortAnswer && <ShortAnswerDetail />}
				{question.questionType === QuestionType.SingleAnswer && (
					<MultipleChoiceAnswerDetailBuilder
						choices={multipleChoices}
						onChange={onQuestionChoiceChange}
						type="single"
					/>
				)}
				{question.questionType === QuestionType.MultipleAnswer && (
					<MultipleChoiceAnswerDetailBuilder
						choices={multipleChoices}
						onChange={onQuestionChoiceChange}
						type="multiple"
					/>
				)}
				{question.questionType === QuestionType.SignupList && (
					<SignupListAnswerDetail choices={signUpChoices} onChange={onSignupListChoiceChange} />
				)}
				{question.questionType === QuestionType.RSVP && <RSVPAnswerDetail />}
			</Grid>
		</div>
	);
}
