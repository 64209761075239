import { Button, Grid, IconButton, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { useDialogSteps } from "../CurrentDialogStep";
import BackArrow from "@material-ui/icons/KeyboardArrowLeft";
import NextArrow from "@material-ui/icons/KeyboardArrowRight";
import { MultistepDialogStepLayoutProps, useCommonLayoutStyle } from "./MultistepDialogStepLayout";
import { MultiStepDialogProgress } from "../MultiStepDialogProgress";


export function MultistepDesktopLayout(props: PropsWithChildren<MultistepDialogStepLayoutProps>) {
    const { disabled, backButtonText, nextButtonText, title, hideBackStep } = props;
    const dialogContext = useDialogSteps();
	const classes = { ...useCommonLayoutStyle(), ...useStyles() };
	const hasMultipleSteps = dialogContext.steps.length !== 1;

	return (
		<Grid container style={{ overflowY: "auto" }}>
			<Grid className={classes.titlebar} item xs={12}>
				<Typography className={classes.titlebartitle} variant={"h4"} style={{ paddingLeft: 50 }}>
					{title}
				</Typography>
				{!hideBackStep &&
					<IconButton className={classes.titleCloseIcon} aria-label="close modeal" component="span" disabled={disabled} onClick={dialogContext.onClose}>
						<img src={"https://i.cdn-sc.com/Response/icons/x_cancel.svg"} alt="cancel" />
					</IconButton>
				}
			</Grid>
				
			{hasMultipleSteps && 
				<Grid className={classes.progressTracker} item xs={12}>
					<MultiStepDialogProgress />
				</Grid>
			}

			<Grid className={classes.progressPage} item xs={12} style={{marginLeft: 20, marginRight: 20, minHeight: 450}}>
				{props.children}
			</Grid>
			<Grid className={classes.progressActions} item xs={12}>
				{!props.hideBackStep &&
					<Button
					disabled={disabled}
					variant="contained"
					color="primary"
					size="large"
					className={classNames(classes.actionButtons, classes.backButton)}
					startIcon={<BackArrow style={{ marginRight: 0 }} />}
					onClick={dialogContext.previousStep}
				>
					{backButtonText ?? "Back"}
				</Button>
				}
				<div className={classes.grow} />
				{!props.required && props.skip ? (
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						size="large"
						className={classNames(classes.actionButtons, classes.nextButton)}
						endIcon={<NextArrow />}
						onClick={props.onSkip}
					>
						Skip
					</Button>
				) : (
                    !(props.required && props.hideNextStep)
                    &&
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						size="large"
						className={classNames(classes.actionButtons, classes.nextButton)}
						endIcon={<NextArrow />}
						onClick={props.onConfirmStep}
					>
						{nextButtonText ?? "Next"}
					</Button>
				)}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	titlebar: {
		width: "100%",
		height: 54,
		backgroundColor: theme.palette.secondaryResponse.main,
		display: "flex",
		alignItems: "center",
	},
	progressTracker: {
		height: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	progressPage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	progressActions: {
		display: "inline-flex",
	},
}));