import { Link } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

interface ClientLinkProps {
    to: string;
    style?: React.CSSProperties;
    color?: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error",
    disabled?: boolean;
}

export function ClientLink(props: React.PropsWithChildren<ClientLinkProps>) {
	const history = useHistory();
	let style: React.CSSProperties = { cursor: "pointer" };
    if(props.style){
        style = {...style, ...props.style};
    }

    const onClick = () => {
        if(!props.disabled){
            history.push(props.to)
        }
    }

	return (
		<Link color={props.color} style={style} onClick={onClick} underline={"always"}>
			{props.children}
		</Link>
	);
}
