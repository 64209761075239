import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import LoadingSpinner from "../../components/loaderSpinner";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";

export function SignOut() {
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		async function signOut() {
			await AuthService.signOut();
			setRedirect(true);
		}
		signOut();
	}, []);

	if (redirect) {
		return <Redirect to={routes.public.signInPage} />;
	}

	return <LoadingSpinner isComplete />;
}
