import React from "react";
import "./App.css";
import { GlobalLayout } from "./layout/GlobalLayout";
import { AppRouter } from "./layout/routes/AppRouter";

function App() {
	return (
		<GlobalLayout>
			<AppRouter />
		</GlobalLayout>
	);
}

export default App;
