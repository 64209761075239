import { Checkbox, FormControl, FormControlLabel, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { MultipleAnswer } from "../../../../entities/Forms/Answer";

const MultipleChoiceIndividualResultsStyles = makeStyles((theme: Theme) => ({
	radio: {
		cursor: "default",
		"&$checked": {
			color: theme.palette.secondaryResponse.main,
			"&:hover": {
				backgroundColor: "#FFF",
			},
		},
		"&:hover": {
			backgroundColor: "#FFF",
		},
	},
	checked: {},
}));

interface Props {
	answer: MultipleAnswer;
}

export function MultipleChoiceIndividualResults(props: Props) {
	const classes = MultipleChoiceIndividualResultsStyles();
	const { answer } = props;

	return (
		<FormControl component="fieldset">
			{answer.texts.map((choice: string, index: number) => {
				return (
					<FormControlLabel
						key={index}
						control={
							<Checkbox
								disableRipple
								focusRipple={false}
								value={choice}
								classes={{ root: classes.radio, checked: classes.checked }}
								checked
							/>
						}
						classes={{ root: classes.radio }}
						label={choice}
					/>
				);
			})}
		</FormControl>
	);
}
