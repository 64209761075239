import { useEffect } from "react";
import { useHub } from "./useHub";


export interface HubFunction {
	methodName: string, 
	args?: any[]
}  

export function useHubSubscription(subscriptionFunction: HubFunction, unsubscribeFunction?: HubFunction) {
    const hub = useHub();
	const { hubReady } = hub;
	const subscribeArgs = subscriptionFunction.args;
	const unsubscribeArgs = unsubscribeFunction?.args;

    useEffect(() => {
		if(hubReady){
			subscribeArgs ? hub.invoke(subscriptionFunction.methodName, ...subscribeArgs) : hub.invoke(subscriptionFunction.methodName);
			return unsubscribeFunction 
			? () => unsubscribeArgs ? hub.invoke(unsubscribeFunction.methodName, ...unsubscribeArgs) : hub.invoke(unsubscribeFunction.methodName)
			: () => {};
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubReady, subscribeArgs, unsubscribeArgs])
}