import { makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Answer, ShortAnswer } from "../../../entities/Forms/Answer";
import { Question, QuestionType } from "../../../entities/Forms/Question";
import { GraphicOverviewDigest } from "./QuestionTypeComponents/GraphicOverviewDigest";
import { RSVPOverviewDigest } from "./QuestionTypeComponents/RSVPOverviewDigest";
import { ShortAnswerOverviewDigest } from "./QuestionTypeComponents/ShortAnswerOverviewDigest";
import { SignupOverviewDigest } from "./QuestionTypeComponents/SignUpOverviewDigest";

const useOverviewFormDigest = makeStyles((theme: Theme) => ({
	container: {
		padding: 48,
		[theme.breakpoints.down("sm")]: {
			padding: 12,
		},
	},
	pageTitle: {
		color: theme.palette.secondaryResponse.main,
	},
	numberQuestion: {
		marginRight: 5,
		paddingTop: 10,
		color: theme.palette.secondaryResponse.main,
		fontSize: 20,
	},
	prompt: {
		fontSize: 16,
		color: theme.palette.secondaryResponse.main,
	},
	questionBlock: {
		marginTop: 18,
	},
	fadedText: {
		fontSize: 16,
		color: theme.palette.secondaryResponse.main,
		opacity: 0.7,
	},
	grow: {
		flex: 1,
	},
}));

interface OverviewFormDigestProps {
	answers: Answer[];
	questions: Question[];
}

interface QuestionData {
	question: Question;
	answers: Answer[];
}

export function OverviewFormDigest(props: OverviewFormDigestProps) {
	const { questions, answers } = props;
	const classes = useOverviewFormDigest();

	const [questionData, setQuestionData] = useState<QuestionData[]>([]);
	// const [viewAll, showViewAll] = useState<QuestionType | undefined>(undefined);

	useEffect(() => {
		async function buildData() {
			let data: QuestionData[] = [];

			questions.forEach((item) => {
				let itemAnswers = answers.filter((a) => a.questionId === item.id);

				data.push({ question: item, answers: itemAnswers });
			});

			setQuestionData(data);
		}

		buildData();
	}, [questions, answers]);

	return (
		<div className={classes.container}>
			<Typography className={classes.pageTitle} variant="h2">
				Overview
			</Typography>

			{questionData.map((data: QuestionData, index: number) => {
				return (
					<div className={classes.questionBlock}>
						<div style={{ display: "flex", width: "100%" }}>
							<span style={{ fontWeight: "bold", fontSize: 16 }}>
								<span className={classes.numberQuestion}>{index + 1}.</span>
								<span className={classes.prompt}>{data.question.prompt}</span>
							</span>
						</div>
						<div style={{ display: "flex", width: "100%" }}>
							<span className={classes.fadedText}>
								{data.answers.length} Answer{data.answers.length !== 1 ? "s" : ""}{" "}
							</span>
							<div className={classes.grow} />
							{/* {data.question.questionType === QuestionType.ShortAnswer && (
								<span className={classes.fadedText} style={{ cursor: "pointer" }}>
									View All <KeyboardArrowRight className={classes.fadedText} />
								</span>
							)} */}
						</div>

						{data.question.questionType === QuestionType.ShortAnswer &&
							data.answers.slice(0, data.answers.length > 3 ? 3 : data.answers.length).map((answer) => {
								return <ShortAnswerOverviewDigest text={(answer as ShortAnswer).text} />;
							})}
						{(data.question.questionType === QuestionType.SingleAnswer ||
							data.question.questionType === QuestionType.MultipleAnswer) &&
							data.answers.length > 0 && (
								<GraphicOverviewDigest type={data.question.questionType} answers={data.answers} />
							)}
						{data.question.questionType === QuestionType.SignupList && data.answers.length > 0 && (
							<SignupOverviewDigest question={data.question} answers={data.answers} />
						)}
						{data.question.questionType === QuestionType.RSVP && data.answers.length > 0 && (
							<RSVPOverviewDigest answers={data.answers} />
						)}
					</div>
				);
			})}
		</div>
	);
}
