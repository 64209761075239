import React from "react";
import { Journey, JourneyCategory } from "../../entities/Journey";
import { JourneySetupStep, JourneyStep } from "../../utillity/JourneySetupStepsHelper";
import { MessageAddressView } from "./Extras/MessageAddressView";
import { MessageEventCalendarView } from "./Extras/MessageEventCalendarView";
import { MessageFormView } from "./Extras/MessageFormView";
import { MessageLinkView } from "./Extras/MessageLinkView";
import { MessageMediaView } from "./Extras/MessageMediaView";
import { JourneyMessagePreviewStep } from "./Steps/JourneyMessagePreviewStep";
import { KeywordStep } from "./Steps/KeywordStep";
import { MobileKeywordAndPreviewStep } from "./Steps/MobileKeywordAndPreviewStep";
import { SermonPromptScheduleStep } from "./Steps/SermonPromptScheduleStep";

export function JourneySetUpStepView(props: {
	currentStep: JourneySetupStep;
	journey: Journey;
	onStepNotFound: (journeyStep: JourneyStep) => void;
}) {
	const journeyStep = props.currentStep.step;
	const progressJourney = props.journey;

	if (journeyStep === JourneyStep.MobileKeywordAndPreview) {
		return <MobileKeywordAndPreviewStep journey={progressJourney} />;
	}

	if (journeyStep === JourneyStep.Keyword) {
		return <KeywordStep journey={progressJourney} />;
	}

	if (journeyStep === JourneyStep.JourneyPreview) {
		return <JourneyMessagePreviewStep journey={progressJourney} />;
	}

	if (journeyStep === JourneyStep.SermonPromptSchedule && progressJourney.category === JourneyCategory.Sermon_Prompts) {
		return <SermonPromptScheduleStep journey={progressJourney} />;
	}

	if (journeyStep === JourneyStep.EventCalendar && progressJourney.category === JourneyCategory.Informational) {
		return <MessageEventCalendarView journey={progressJourney} mode="step" />;
	}

	if (journeyStep === JourneyStep.Address) {
		return <MessageAddressView journey={progressJourney} mode="step" />;
	}

	if (journeyStep === JourneyStep.Form) {
		return <MessageFormView journey={progressJourney} mode="step" />;
	}

	if (journeyStep === JourneyStep.Link) {
		return <MessageLinkView journey={progressJourney} mode="step" />;
	}

	if (journeyStep === JourneyStep.Media) {
		return <MessageMediaView journey={progressJourney} mode="step" />;
	}

	props.onStepNotFound(journeyStep);
	return null;
}
