import { FormGroup, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PhoneTextField } from "../../../../components/PhoneTextField";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../../services/server/ServerValidationError";

interface UnserPhoneNumberFormProps {
    disabled: boolean;
    onUpdate: (phoneNumber:string) => void;
    errors: FieldValidationError[]
}

export function UserPhoneNumberForm(props:UnserPhoneNumberFormProps){
    const {disabled, errors} = props;
    const [phoneNumber, setPhoneNumber] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.onUpdate(phoneNumber), [phoneNumber])
    return (
        <FormGroup>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Your personal mobile number (for verification):
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PhoneTextField
                        variant="outlined"
                        disabled={disabled}
                        value={phoneNumber}
                        onChangePhoneNumber={(value) => setPhoneNumber(value)}
                        margin="none"
                        fullWidth
                        required
                        type="text"
                        error={FieldValidationErrorUtils.isFieldInError(errors, "PhoneNumber") || FieldValidationErrorUtils.isFieldInError(errors, "mobilePhone")}
                        helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "PhoneNumber") || FieldValidationErrorUtils.getFieldErrorSummary(errors, "mobilePhone")}
                    />
                </Grid>
            </Grid>
        </FormGroup>
    )
}