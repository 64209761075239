import React, { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { apiRoot } from "../services/Settings";
import AuthService from "../services/AuthService";
import { HubContext } from "../hooks/useHub";

export function HubProvider(props: React.PropsWithChildren<{}>){

    const [connection, setConnection] = useState<HubConnection | undefined>();
    const [hubReady, setHubReady] = useState(false);

    useEffect(() => {
		const connection = new HubConnectionBuilder()
		.withUrl(`${apiRoot}/notificationhub`, { accessTokenFactory: AuthService.getJwt})
		.withAutomaticReconnect()
		.build();

		connection.on("Message", (message: string) => console.log("Message", message));

        connection.onclose(() => setHubReady(false));
        connection.onreconnecting(() => setHubReady(false));
        connection.onreconnected(() => setHubReady(true));

		connection
			.start()
			.then(() => {
				if (connection.state === HubConnectionState.Connected) {
					setConnection(connection);
                    setHubReady(true)
				} else {
					console.error("Hub connection not connected");
				}
			})
			.catch((e) => console.error(e));

		// can we make this async?
		return () => {
			connection.stop();
		};
	}, []);

    const onEvent = (name: string, functionToFire: (...args: any[]) => void) => {
        if(connection && connection.state === HubConnectionState.Connected){
            connection.on(name, functionToFire);
            return () => connection.off(name, functionToFire);
        }
        return () => {};
    }

    function invoke<T>(methodName: string, ...args: any[]): Promise<T | undefined> {
        if (connection && connection.state === HubConnectionState.Connected) {
            return connection.invoke(methodName, ...args);
        }
        return Promise.resolve(undefined);
    }

    return(
        <HubContext.Provider value={{
            connection,
            hubReady,
            onEvent,
            invoke
        }}>
            {props.children}
        </HubContext.Provider>
    );
}