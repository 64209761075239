import { MessagePreferences, MessagePreferencesSubscription } from "../entities/MessagePreferences";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface UpdateMessagePreferencesRequest {
    churchId: string;
    attendeeId: string;
    optedIntoChurchMessages: boolean;
    subscriptions: MessagePreferencesSubscription[];
}

export const MessagePreferencesService = {
    get: (churchId: string, attendeeId: string) => WebClient.Get<MessagePreferences>(`${apiRoot}/message-preferences?churchId=${churchId}&attendeeId=${attendeeId}`),
    update: (request: UpdateMessagePreferencesRequest) => WebClient.Put.Unvalidated<MessagePreferences>(`${apiRoot}/message-preferences`, request)
};
