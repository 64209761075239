import { FormControl, FormControlLabel, makeStyles, Theme } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import { SignupItem, SignUpListAnswer } from "../../../../entities/Forms/Answer";

const SignUpIndividualResultsStyles = makeStyles((theme: Theme) => ({
	icon: {
		color: theme.palette.secondaryResponse.main,
		fontSize: 21,
		margin: "2px 8px 2px 11px",
	},
}));

interface Props {
	answer: SignUpListAnswer;
}

export function SignUpIndividualResults(props: Props) {
	const classes = SignUpIndividualResultsStyles();
	const { answer } = props;

	return (
		<FormControl component="fieldset">
			{answer.signupItems.map((item: SignupItem, index: number) => {
				return (
					<FormControlLabel
						key={index}
						value={answer.id}
						control={<DoneIcon className={classes.icon} />}
						label={`${item.text}: ${item.quantity}`}
					/>
				);
			})}
		</FormControl>
	);
}
