import { Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useState } from "react";
import { ResponseDialogActions } from "../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { VerticalCenter } from "../../components/VerticalCenter";
import { Group } from "../../entities/Groups/Group";
import { SendGroupAnnouncementDialog } from "../../pages/Groups/Components/SendGroupAnnouncementDialog";
import { useGroups } from "../../providers/GroupProvider";

interface SelectGroupToMessageProps {
	onClose: () => void;
	onBack: () => void;
}

export default function SelectGroupToMessage(props: SelectGroupToMessageProps) {
	const groupContext = useGroups();
	const [groupOptions] = useState<Group[]>(groupContext.groups);
	const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

	const filter = createFilterOptions<Group>({
		matchFrom: "any",
		stringify: (option: Group) => option.name,
	});

	if (selectedGroup) {
		return <SendGroupAnnouncementDialog group={selectedGroup} onClose={props.onClose} onBack={() => setSelectedGroup(null)} />;
	}

	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Typography align="center" variant="body2" style={{ fontSize: 22, paddingBottom: 20 }}>
						Select group for your announcement
					</Typography>
					<Grid container justify="center">
						<Grid item sm={6} xs={12}>
							<Autocomplete<Group>
								value={selectedGroup}
								options={groupOptions}
								onChange={(_, value) => setSelectedGroup(value)}
								autoHighlight
								clearOnBlur
								clearOnEscape
								handleHomeEndKeys
								filterOptions={(options, params) => {
									return filter(options, params);
								}}
								getOptionLabel={(option: Group) => {
									return option.name;
								}}
								renderOption={(option: Group) => {
									return option.name;
								}}
								renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Group" />}
							/>
						</Grid>
					</Grid>
				</VerticalCenter>
			</ResponseDialogContent>
			<ResponseDialogActions onConfirm={() => {}} onBack={props.onBack} hideNextStep={true} backButtonText={"Back"} hideSecondaryButton={true} />
		</>
	);
}
