export enum TextMessageStatus{
    Unknown = 0,
    Queued = 1,
    Sending = 2,
    Sent = 3,
    Delivered = 4,
    Undelivered = 5,
    Failed = 6,
    Receiving = 7,
    Received = 8
}