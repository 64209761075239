import {
	Grid,
	Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";
import { MessageInput } from "../Components/MessageInput";
import { GroupMessageHistory } from "./GroupMessageHistory";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { Group } from "../../../entities/Groups/Group";
import { GridGrow } from "../../../components/GridGrow";
import { useMessageRouter } from "../MessagePage";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";

interface MobileGroupMessageDetailPageProps {
	selectedGroup: Group;
}

export function MobileGroupMessageDetailPage(props: MobileGroupMessageDetailPageProps) {
	const { selectedGroup } = props;
	const messageRouter = useMessageRouter();

	const setErrorAlert = useServerErrorAlert();
	const setSuccess = useSuccessAlert();
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const handleSend = (message:string, media: TextMessageMedia[]) => {
		if(selectedGroup)
			sendMessage(selectedGroup.id, message, media)
	}

	const sendMessage = async(groupId:string, message:string, media: TextMessageMedia[]) => {
		setFieldErrors([]);
		setDisabled(true);
		const result = await GroupService.scheduleMessageToGroup({groupId, message, media, scheduledTime: new Date(Date.now())});
		setDisabled(false);

		if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

		if (ServerResult.isError(result)) return setErrorAlert(result);

		messageRouter.updateGroup(result.data)
		setSuccess("Message Sent")
	}

	return (
		<div style={{ height: "100%", width: "100%", overflowY: "auto", position: "absolute", top: 0, left: 0}}>
			<Grid container style={{position: "sticky", top: 0, zIndex: 1000 , backgroundColor: "#FFF", borderBottom: "1px solid #e0e0e0", padding: 15 }}>
				<Grid item xs={4}>
					<ClientLink to={routes.app.groupMessagePage}>
						Back
					</ClientLink>
				</Grid>
				<GridGrow />
				<Grid item xs={8}>
					<Typography>To:&nbsp;
						<ClientLink to={routes.app.resolve.groupDetailPage(selectedGroup.id)}>
							{selectedGroup.name}
						</ClientLink>
					</Typography>
				</Grid>
				<GridGrow />
			</Grid>
			<Grid container justify="center" style={{ minHeight: "calc(100% - 86px)", background: "#FFF" }}>
				<Grid item xs={12}>
					<GroupMessageHistory  messageHistory={selectedGroup.messagesToGroup}/> 
				</Grid>
			</Grid>

			<div style={{ position: "sticky", bottom: 0, zIndex: 1000, backgroundColor: "#FFF", paddingBottom: 10 }}>
				<Grid container justify="center">
					<Grid item xs={12}>
						<MessageInput
							disabled={selectedGroup === undefined || disabled}
							onSend={handleSend}
							errors={fieldErrors}
							key={`${selectedGroup.id}`}				
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}