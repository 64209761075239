import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Journey } from "../../../entities/Journey";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { KeywordService } from "../../../services/KeywordService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import ResponseInputComponent from "../../responseInputComponent";
import { FieldErrorStrings } from "../journeySetUp";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { JourneyStepLayout, useJourneyStepStyles } from "./JourneyStepLayout";

interface Props {
	journey: Journey;
}

export const useKeywordStyles = makeStyles((theme: Theme) => ({
	progressLabelText: {
		color: "#404448",
		fontSize: 17,
		marginBottom: 30,
		[theme.breakpoints.down("sm")]: {
			fontSize: 15,
		},
	},
	instructionText: {
		textDecoration: "italics",
		color: "#404448",
		fontSize: 14,
	},
}));

const keywordLength = 12;

export function KeywordStep(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useKeywordStyles() };
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const progress = useJourneySetupProgress();
	const setErrorAlert = useServerErrorAlert();

	const [keyword, setKeyword] = useState(props.journey.keywordTrigger);
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		function checkErrors() {
			if (FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.Keyword)) {
				setErrorMessage(FieldValidationErrorUtils.getFieldErrorSummary(progress.fieldErrors, FieldErrorStrings.Keyword));
			}
		}
		checkErrors();
	}, [progress.fieldErrors]);

	const validateKeyword = async () => {
		progress.setDisabled(true);
		let response = await KeywordService.validateKeyword({ journeyId: props.journey.id, keyword });
		progress.setDisabled(false);
		if (ServerResult.isSuccess(response)) {
			if (response.data.isValid) {
				setErrorMessage("");
				setSuccessMessage("Looks good!");
				setTimeout(() => progress.completeStep({ ...props.journey, keywordTrigger: keyword }), 500);
			} else {
				setSuccessMessage("");
				setErrorMessage(response.data.message);
			}
		} else {
			setErrorAlert(response);
		}
	};

	return (
		<JourneyStepLayout required={true} onConfirmStep={validateKeyword}>
			<Grid item style={{ textAlign: "center" }} xs={12}>
				<Typography className={classes.progressLabelText} variant="subtitle2">
					What keyword would you like a person to text in order to join this journey?
				</Typography>
			</Grid>
			<Grid item style={{ textAlign: "center" }} xs={12}></Grid>
			<div style={{ width: mobileFormFactor ? "100%" : 300, marginTop: mobileFormFactor ? 16 : 0 }}>
				<ResponseInputComponent
					successMessage={successMessage}
					errorMessage={errorMessage}
					value={keyword}
					onChange={setKeyword}
					maxLength={keywordLength}
					placeHolder="Keyword"
					onEnter={validateKeyword}
				/>
				<Typography variant="subtitle1" className={classes.instructionText}>
					Up to {keywordLength} characters, no spaces please!
				</Typography>
			</div>
		</JourneyStepLayout>
	);
}
