export enum Role {
	Admin = 1,
	Collaborator = 2,
	UberAdmin = 3,
	Attendee = 4,
}

export function getRole(roleId: number) {
	switch (roleId) {
		case Role.Admin:
			return Role.Admin;
		case Role.Collaborator:
			return Role.Collaborator;
		case Role.UberAdmin:
			return Role.UberAdmin;
		case Role.Attendee:
			return Role.Attendee;
		default:
			return Role.Attendee;
	}
}
