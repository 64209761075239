import { FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import Attendee from "../../../entities/Attendee";
import { Answer, MultipleAnswer, RSVPAnswer, ShortAnswer, SignUpListAnswer, SingleAnswer } from "../../../entities/Forms/Answer";
import { Question, QuestionType } from "../../../entities/Forms/Question";
import { MultipleChoiceIndividualResults } from "./QuestionTypeComponents/MultipleChoiceIndividualResults";
import { RSVPIndividualResults } from "./QuestionTypeComponents/RSVPIndividualResults";
import { ShortAnswerOverviewDigest } from "./QuestionTypeComponents/ShortAnswerOverviewDigest";
import { SignUpIndividualResults } from "./QuestionTypeComponents/SignUpIndividualResults";
import { SingleChoiceIndividualResults } from "./QuestionTypeComponents/SingleChoiceIndividualResults";

export const useQuestionsFormDigestStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: 48,
		overflowY: "hidden",
		overflowX: "hidden",
		[theme.breakpoints.down("sm")]: {
			padding: 12,
		},
	},
	pageTitle: {
		color: theme.palette.secondaryResponse.main,
	},
	questionFormSelect: {},
	questionSelect: {
		backgroundColor: "#e2eaf3",
		border: "none",
		minWidth: 400,
		paddingTop: 6,
		paddingBottom: 6,
		borderRadius: 12,
		paddingLeft: 14,
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
		"&:before": {
			display: "none",
		},
		"&:after": {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			minWidth: 200,
			width: "100%",
		},
	},
	answerBox: {
		border: "1px solid rgba(0,0,0,.2)",
		borderRadius: 12,
		padding: 12,
	},
	answerOwner: {
		borderBottom: "1px solid rgba(0,0,0,.2)",
	},
}));

interface QuestionsFormDigestProps {
	answers: Answer[];
	questions: Question[];
	attendees: Attendee[];
}

interface QuestionAnswersData {
	attendeeName: string;
	answer: Answer;
}

export function QuestionsFormDigest(props: QuestionsFormDigestProps) {
	const { questions, answers, attendees } = props;
	const classes = useQuestionsFormDigestStyles();

	const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(
		questions.length === 0 ? undefined : questions[0].id
	);
	const [selectedAnswers, setAnswers] = useState<QuestionAnswersData[]>([]);

	useEffect(() => {
		async function buildData() {
			if (selectedQuestion) {
				let wantedAnswers = answers.filter((a) => a.questionId === selectedQuestion);

				let readyData: QuestionAnswersData[] = [];
				wantedAnswers.forEach((item) => {
					let attendeeIndex = attendees.findIndex((a) => a.id === item.attendeeId);
					let attendeeName = attendeeIndex > -1 ? attendees[attendeeIndex].name : "Unknown";

					readyData.push({
						attendeeName: attendeeName,
						answer: item,
					});
				});

				setAnswers(readyData);
			}
		}

		buildData();
	}, [selectedQuestion, answers, attendees]);

	const onQuestionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		let index = questions.findIndex((a) => a.id === (event.target.value as string));

		if (index > -1) {
			setSelectedQuestion(questions[index].id);
		}
	};

	return (
		<div className={classes.container}>
			<Typography className={classes.pageTitle} variant="h2">
				{questions.length} Question{questions.length !== 1 ? "s" : ""}
			</Typography>
			<Grid container style={{ marginTop: 16 }} spacing={4}>
				{!selectedQuestion && (
					<Grid item xs={12}>
						<Typography variant="subtitle1">There are no questions for this form</Typography>
					</Grid>
				)}
				{selectedQuestion && (
					<>
						<Grid item xs={12}>
							<FormControl className={classes.questionFormSelect}>
								<Select
									className={classes.questionSelect}
									value={selectedQuestion}
									onChange={onQuestionTypeChange}
									IconComponent={KeyboardArrowDown}
								>
									{questions.map((item: Question, index: number) => {
										return (
											<MenuItem key={index} value={item.id}>
												{item.prompt}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						{selectedAnswers.length === 0 && (
							<Grid item xs={12}>
								<Typography variant="subtitle1">There are no answers to this question.</Typography>
							</Grid>
						)}
						{selectedAnswers.map((item: QuestionAnswersData, index: number) => {
							return (
								<Grid item xs={12} md={3} key={index}>
									<div className={classes.answerBox}>
										<Typography className={classes.answerOwner} variant="subtitle1">
											{item.attendeeName}
										</Typography>
										{item.answer.questionType === QuestionType.ShortAnswer && (
											<ShortAnswerOverviewDigest text={(item.answer as ShortAnswer).text} />
										)}
										{item.answer.questionType === QuestionType.SingleAnswer && (
											<SingleChoiceIndividualResults answer={item.answer as SingleAnswer} />
										)}
										{item.answer.questionType === QuestionType.MultipleAnswer && (
											<MultipleChoiceIndividualResults answer={item.answer as MultipleAnswer} />
										)}
										{item.answer.questionType === QuestionType.RSVP && (
											<RSVPIndividualResults rsvpCount={(item.answer as RSVPAnswer).attendeeCount} />
										)}
										{item.answer.questionType === QuestionType.SignupList && (
											<SignUpIndividualResults answer={item.answer as SignUpListAnswer} />
										)}
									</div>
								</Grid>
							);
						})}
					</>
				)}
			</Grid>
		</div>
	);
}
