import { Button, Checkbox, IconButton, makeStyles, Radio, TextField, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Close";
import React from "react";
import { QuestionChoiceType, QuestionMultipleChoice } from "../../../entities/Forms/QuestionChoice";

const useMultipleSingleChoiceStyles = makeStyles((theme: Theme) => ({
	infoHeader: {
		fontSize: 14,
		fontStyle: "italic",
		opacity: 0.6,
		marginBottom: 2,
	},
	optionField: {
		paddingTop: 5,
		"& > div:first-child": {
			"&:before": {
				display: "none",
			},
			"&:hover": {
				"&:before": {
					display: "block",
					opacity: 0.6,
				},
			},
		},
	},
	optionText: {
		fontSize: 16,
		color: "rgba(0,0,0,.6)",
		fontWeight: 500,
	},
	addOptionButton: {
		paddingLeft: 0,
		paddingTop: 0,
		marginTop: -11,
		fontSize: 16,
		opacity: 0.9,
		"&:hover": {
			backgroundColor: "initial",
			textDecoration: "underline",
		},
	},
}));

interface MultipleChoiceAnswerDetailProps {
	type: "single" | "multiple";
	choices: QuestionMultipleChoice[];
	onChange: (choices: QuestionMultipleChoice[]) => void;
}

export function MultipleChoiceAnswerDetailBuilder(props: MultipleChoiceAnswerDetailProps) {
	const classes = useMultipleSingleChoiceStyles();
	// const [choiceText, setChoiceText] = useState<string>(`Option ${props.choices.length + 1}`);
	const { type, choices, onChange } = props;

	const onDelete = (choiceIndex: number) => {
		const choices = [...props.choices];
		choices.splice(choiceIndex, 1);
		props.onChange(choices);
		// setChoiceText(`Option ${props.choices.length}`);
	};

	const onAdd = () => {
		const option: QuestionMultipleChoice = {
			choiceType: QuestionChoiceType.MultipleChoice,
			text: "",
		};
		props.onChange([...props.choices, option]);
		// setChoiceText(`Option ${props.choices.length + 2}`);
	};

	const onTextChange = (text: string, choiceIndex: number) => {
		if (text.length === 0) {
			onDelete(choiceIndex);
		} else {
			let changedChoices = [...choices];
			changedChoices[choiceIndex].text = text;
			onChange(changedChoices);
		}
	};

	return (
		<div style={{ marginLeft: 4 }}>
			<Typography className={classes.infoHeader} variant="subtitle1">
				{type === "single"
					? "Allows single selection on a multiple choice question."
					: "Allows multiple selections on a multiple choice question."}
			</Typography>
			<div style={{ maxHeight: 300, overflowY: "auto" }}>
				{props.choices.map((c, index) => (
					<div style={{ display: "flex" }} key={index}>
						{type === "single" ? <Radio disabled /> : <Checkbox disabled />}

						<TextField
							value={choices[index].text}
							placeholder={`Option ${index + 1}`}
							type="text"
							onChange={(e) => onTextChange(e.target.value, index)}
							className={classes.optionField}
							inputProps={{
								maxLength: 100,
								className: classes.optionText,
							}}
						/>

						{index > 0 && (
							<IconButton aria-label="close modeal" component="span" onClick={() => onDelete(index)}>
								<CancelIcon style={{ fontSize: 20 }} />
							</IconButton>
						)}
					</div>
				))}
			</div>
			<div style={{ display: "flex" }}>
				<div onClick={onAdd} style={{ cursor: "pointer" }}>
					<AddIcon style={{ opacity: 0.6, margin: "8px 9px 0px 9px" }} />
					<Button className={classes.addOptionButton} onClick={onAdd}>
						{" "}
						Add Option
					</Button>
				</div>
			</div>
		</div>
	);
}
