import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";
import { MessageInput } from "../Components/MessageInput";
import { GroupMessageHistory } from "./GroupMessageHistory";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useMessageRouter } from "../MessagePage";
import { useGroups } from "../../../providers/GroupProvider";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";

interface DesktopGroupMessagePageProps {}

export function DesktopGroupMessagePage(props: DesktopGroupMessagePageProps) {
	const messageRouter = useMessageRouter();
	const groupContext = useGroups();
	const { groups } = groupContext;
	const { selectedGroup } = messageRouter;

	const setErrorAlert = useServerErrorAlert();
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const handleSend = (message: string, media: TextMessageMedia[]) => {
		if (selectedGroup) sendMessage(selectedGroup.id, message, media);
	};

	const sendMessage = async (groupId: string, message: string, media:TextMessageMedia[]) => {
		setFieldErrors([]);
		setDisabled(true);
		const result = await GroupService.scheduleMessageToGroup({ groupId, message, media, scheduledTime: new Date(Date.now()) });
		setDisabled(false);

		if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

		if (ServerResult.isError(result)) return setErrorAlert(result);

		messageRouter.updateGroup(result.data);
	};

	return (
		<div style={{ height: "100%" }}>
			<div style={{ height: 55, padding: 15, borderBottom: "1px solid #e0e0e0" }}>
				<Typography>
					To:&nbsp;
					{selectedGroup && (
						<ClientLink to={routes.app.resolve.groupDetailPage(selectedGroup.id)}>{selectedGroup.name}</ClientLink>
					)}
					{groups.length === 0 && <ClientLink to={routes.app.createGroupPage}>Create a group</ClientLink>}
				</Typography>
			</div>
			<div style={{ height: "calc(100% - 55px)", overflowY: "auto", paddingTop: 10 }}>
				<Grid container justify="center" style={{ minHeight: "calc(100% - 110px)" }}>
					<Grid item xl={8} lg={10} md={11}>
						{selectedGroup && <GroupMessageHistory messageHistory={selectedGroup.messagesToGroup} />}
					</Grid>
				</Grid>

				<div style={{ position: "sticky", bottom: 0, zIndex: 1000, backgroundColor: "#FFF", paddingBottom: 10 }}>
					<Grid container justify="center">
						<Grid item xl={8} lg={10} md={11}>
						<MessageInput disabled={selectedGroup === undefined || disabled} onSend={handleSend} errors={fieldErrors} />
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
}
