import { Grid, LinearProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { Group } from "../../../entities/Groups/Group";
import { useHubEvent } from "../../../hooks/useHubEvent";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import AttendeeService from "../../../services/AttendeeService";
import { ServerResult } from "../../../services/server/ServerResult";
import { ImportPeopleCsvModel } from "./UploadCsvFileStep";

interface ImportStepProps {
	rows: ImportPeopleCsvModel[];
	groups: Group[];
    onComplete: () => void;
}

export function ImportStep(props: ImportStepProps) {
	const { groups, rows } = props;

    const serverErrorAlert = useServerErrorAlert()

	const [peopleToInvite] = useState(rows.filter((r) => !r.exists).length);
	const [peopleToUpdate] = useState(rows.filter((r) => r.exists).length);
	const [peopleImportCount, setPeopleImportCount] = useState(0);
	const [currentCount, setCurrentCount] = useState(0);
	const [currentTotal, setCurrentTotal] = useState(0);
	const [header, setHeader] = useState("");
	const [progress, setProgress] = useState(0);

    useEffect(() => {
        async function importPeople(){
            const result = await AttendeeService.bulkImport({people: rows, groupIds: groups.map(g => g.id)})
            if(ServerResult.isSuccess(result)){
                props.onComplete()
            } else {
                serverErrorAlert(result);
            }
        }
        importPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	useEffect(() => {
		const currentProgress = (peopleImportCount / rows.length) * 100;
		const currentCount = peopleImportCount < peopleToInvite ? peopleImportCount : peopleImportCount - peopleToInvite;
		const currentTotal = peopleImportCount < peopleToInvite ? peopleToInvite : peopleToUpdate;
		let header = `Adding to ${groups.length === 1 ? groups[0].name : "Groups"}`;
		if (peopleImportCount < peopleToInvite) header = "Sending Invite";
		else if (peopleImportCount < rows.length) header = "Updating People";

		setCurrentCount(currentCount);
		setCurrentTotal(currentTotal);
		setHeader(header);
		setProgress(currentProgress);
	}, [groups, peopleImportCount, peopleToInvite, peopleToUpdate, rows]);

	useHubEvent("OnChurchAttendeeSaveEvent", () => setPeopleImportCount((c) => c + 1));

	const peopleImportComplete = peopleImportCount >= rows.length;

	return (
		<VerticalCenter>
			<Grid container justify="center" spacing={3}>
				<Grid item xs={12}>
					<Typography align="center" variant="h1">
						{header}
					</Typography>
				</Grid>
				<Grid item sm={8} xs={12}>
					{!peopleImportComplete ? (
						<LinearProgress variant="determinate" value={progress} style={{ borderRadius: 7, height: 13 }} />
					) : (
						<LinearProgress color="secondary" style={{ borderRadius: 7, height: 13 }} />
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h1" align="center">{!peopleImportComplete ? `${currentCount} / ${currentTotal}` : "..."}</Typography>
				</Grid>
			</Grid>
		</VerticalCenter>
	);
}
