import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface ShortLink {
    url: string;
    shortLink: string;
}

interface CreateShortLinkRequest {
    url: string;
    groupId?: string;
}

export interface CreateShortLinkModel {
    createShortLinkRequests: CreateShortLinkRequest[]
}

export const ShortLinkService = {
    create: (model: CreateShortLinkModel) => WebClient.Post.Validated<ShortLink[]>(`${apiRoot}/short-link/`, model)
};