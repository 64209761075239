export interface ResponseSubscriptionPlan {
	id: string;
	isPublic: boolean;
	people?: number;
	price: number;
	billingPeriod: Term;
}

export enum Term {
	Month = 1,
	Annual = 12,
}

export const getPlanDescription = (plan: ResponseSubscriptionPlan) => {
	const price = `$${plan.price}`;
	const term = plan.billingPeriod === Term.Annual ? "yr" : "month";
	if(!plan.people)
		return `${price}\\${term}`;
	if(plan.people <= 99){
		return `${price}\\${term} for small churches`;
	}
	if(plan.people <= 499){
		return `${price}\\${term} for medium churches`;
	}
	
	return `${price}\\${term} for large churches`;
}
