import {
	Avatar,
	Button,
	Drawer,
	Hidden,
	IconButton,
	List,
	makeStyles,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SyncAlt from "@material-ui/icons/SyncAlt";
import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AssociatedChurchListDialog from "../components/AssociatedChurchList";
import { useAppUser } from "../hooks/useAppUser";
import { routes } from "../routes";
import { MenuListItem } from "./MenuListItem";
import { RenderMobileNav } from "./MobileNav";
import { useJourneyNotifications } from "../hooks/useJourneyNotifications";
import { NotificationBell } from "./NotificationBell";
import { Role } from "../entities/Role";
import ArrayHelper from "../utillity/ArrayHelper";
import { CreateNewDialog } from "./CreateNewDialog/CreateNewDialog";
import { useTextMessagePreviews } from "../providers/TextMessagePreviewProvider";
import { TextMessagePreviewStatus } from "../entities/Messages/TextMessagePreviewStatus";
import { ChurchSetupDialog } from "../pages/SignUp/ChurchSetupDialog/ChurchSetupDialog";

export const useLayoutStyles = makeStyles((theme: Theme) => ({
	root: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		zIndex: 1,
		overflow: "hidden",
		flexGrow: 1,
		display: "flex",
	},
	grow: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		width: 240,
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	content: {
		flexGrow: 1,
		minWidth: 0, // So the Typography noWrap works
		backgroundColor: "#f3f3f3",
		[theme.breakpoints.up('md')]: {
			overflowY: "auto",
			backgroundColor: theme.palette.background.default,
		},
		overflowX: "hidden"
	},
	desktopContent: {
		minHeight: "calc(100vh - 128px)",
		height: "calc(100vh - 128px)",
		paddingBottom: 64,
		paddingLeft: 25,
		paddingRight: 25
	},
	mobileContent: {
		position: "absolute",
		top: 48,
		bottom: 0,
		left: 0,
		right: 0,
		overflowY: "auto",
		overflowX: "hidden",
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 10,
		paddingRight: 10,
	},
	toolbar: theme.mixins.toolbar,
	whitenedText: {
		color: "#FFF",
	},
	responseTitle: {
		padding: 16,
		paddingBottom: 26,
	},
	greenbutton: {
		textTransform: "none",
		width: 195,
		borderRadius: 8,
		marginLeft: 16,
		backgroundColor: theme.palette.primaryResponse.main,
		"&:hover": {
			backgroundColor: theme.palette.primaryResponse.main,
			opacity: 0.8,
		},
	},
	link: {
		textDecoration: "none",
	},
	navListItem: {
		"&:hover": {
			backgroundColor: "rgb(0,0,0,.2)",
		},
	},
	listItemSelected: {
		backgroundColor: "#4d687a",
	},
	avatar: {
		backgroundColor: theme.palette.primary.light,
	},
	customBarHeight: {
		height: 80,
		width: "100%",
	},
	navWaterMark: {
		position: "absolute",
		left: 5,
		bottom: 50,
		width: 230,
	},
}));

function Layout(props: React.PropsWithChildren<{}>) {
	const classes = useLayoutStyles();
	const [user] = useAppUser();
	const { textMessagePreviews } = useTextMessagePreviews();
	const shortBroswer = useMediaQuery("(max-height:650px)");
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const notificationContext = useJourneyNotifications();
	const subscriptions = notificationContext.journeyNotificationSubscriptions;

	const [showChurchList, setShowChurchList] = useState<boolean>(false);
	const [showCreateNewDialog, setShowCreateNewDialog] = useState(false);

	const renderNav = () => {
		const notificationCount = ArrayHelper.sum(subscriptions, (s) => s.unreadCount);
		const unreadMessageCount = textMessagePreviews.filter(p => p.status === TextMessagePreviewStatus.Unread).length;

		return (
			<>
				<Typography variant="h2" className={classNames(classes.responseTitle, classes.whitenedText)}>
					Response
				</Typography>
				<Button
					classes={{ label: "response-h2" }}
					variant="contained"
					className={classNames(classes.greenbutton, classes.whitenedText, "response-h2")}
					onClick={() => setShowCreateNewDialog(true)}
				>
					+ Create New
				</Button>
				<List>
					<MenuListItem path={routes.app.dashBoardPage} />
					<MenuListItem path={routes.app.messagesPage} badge={unreadMessageCount} />
					<MenuListItem path={routes.app.groupListPage} />
					<MenuListItem path={routes.app.journeyListPage} badge={notificationCount} />
					<MenuListItem path={routes.app.peopleListPage} />
					<MenuListItem path={routes.app.settingsPage} />
				</List>
			</>
		);
	};

	return (
		<div className={classes.root}>
			<Hidden smDown>
				<Drawer
					variant="permanent"
					open
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					{renderNav()}
					{!shortBroswer && (
						<img
							className={classes.navWaterMark}
							src={"https://i.cdn-sc.com/Response/logos/watermark-image.png"}
							alt="watermark"
						/>
					)}
				</Drawer>
			</Hidden>
			<Hidden mdUp>
				<RenderMobileNav />
			</Hidden>
			<main id="main" className={classes.content}>
				<Hidden smDown>
					<Toolbar>
						<div className={classes.grow} />
						<div style={{ display: "flex" }}>
							<NotificationBell />
						</div>
						<div style={{ display: "flex" }}>
							{user.churches.length > 0 && (
								<div style={{ paddingTop: 9, marginRight: 9, display: "flex" }}>
									<Typography variant="h3">{user.activeChurchName}</Typography>
									{user.churches.length > 1 && (
										<IconButton style={{ marginTop: -12 }} onClick={() => setShowChurchList(true)}>
											<SyncAlt />
										</IconButton>
									)}
								</div>
							)}
							{user.userImage ? (
								<Avatar className={classes.avatar} src={user.userImage} />
							) : (
								<Avatar className={classes.avatar}>
									{user.firstName.charAt(0)}
									{user.lastName.charAt(0)}
								</Avatar>
							)}
							<div style={{ paddingTop: 9, marginLeft: 9, display: "flex" }}>
								<Typography variant="h3">{`${user.firstName} ${user.lastName}`}</Typography>
								<IconButton style={{ marginTop: -12 }} onClick={(e) => setAnchorEl(e.currentTarget)}>
									<ExpandMore />
								</IconButton>
							</div>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={anchorEl != null}
								onClose={() => setAnchorEl(null)}
							>
								<MenuItem
									style={{ padding: "8 6" }}
									onClick={() => setAnchorEl(null)}
									component={Link}
									to={routes.app.settingsPage}
								>
									Settings
								</MenuItem>
								{user.role === Role.UberAdmin && (
									<MenuItem
										style={{ padding: "8 6" }}
										onClick={() => setAnchorEl(null)}
										component={Link}
										to={routes.app.impersonateChurch}
									>
										Impersonate Church
									</MenuItem>
								)}
								<MenuItem
									style={{ padding: "8 6" }}
									onClick={() => setAnchorEl(null)}
									component={Link}
									to={routes.public.signOutPage}
								>
									Logout
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
					<div className={classes.desktopContent}>{props.children}</div>
				</Hidden>
				<Hidden mdUp>
					<div id="MobileContent" className={classes.mobileContent}>
					{props.children}
					</div>
				</Hidden>
				
			</main>
			{!user.responsePhoneNumber && <ChurchSetupDialog />}
			{showChurchList && <AssociatedChurchListDialog onClose={() => setShowChurchList(false)} />}
			{showCreateNewDialog && (
				<CreateNewDialog isOpen={showCreateNewDialog} onClose={() => setShowCreateNewDialog(false)} />
			)}
		</div>
	);
}

export default Layout;
