import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { GridGrow } from "../../../components/GridGrow";
import Attendee from "../../../entities/Attendee";
import { ChurchOptInStatus } from "../../../entities/ChurchOptInStatus";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { routes } from "../../../routes";
import { MessageService } from "../../../services/MessageService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { MessageInput } from "../Components/MessageInput";
import { InboxMessageHistory } from "./InboxMessageHistory";

interface MobileInboxDetailPageProps {
    selectedAttendee: Attendee
}

export function MobileInboxDetailPage(props:MobileInboxDetailPageProps){
    const { selectedAttendee } = props;
    const setErrorAlert = useServerErrorAlert();
	const setSuccess = useSuccessAlert();
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const handleSend = (message:string, media:TextMessageMedia[]) => {
		if(selectedAttendee && selectedAttendee.optInStatus === ChurchOptInStatus.Accepted)
			sendMessage(selectedAttendee.id, message, media)
	}

	const sendMessage = async(attendeeId:string, message:string, media:TextMessageMedia[]) => {
		setFieldErrors([]);
		setDisabled(true);
		const result = await MessageService.sendDirectMessage({attendeeId, message, media});
		setDisabled(false);

		if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

		if (ServerResult.isError(result)) return setErrorAlert(result);

		setSuccess("Message Sent")
	}

    return (
        <div style={{ height: "100%", width: "100%", overflowY: "auto", position: "absolute", top: 0, left: 0}}>
            <Grid item container style={{position: "sticky", top: 0, zIndex: 1000 , backgroundColor: "#FFF", borderBottom: "1px solid #e0e0e0", padding: 15 }}>
                <Grid item xs={4}>
                    <ClientLink to={routes.app.inboxPage}>
                        Back
                    </ClientLink>
                </Grid>
                <GridGrow />
                <Grid item xs={8}>
                    <Typography>To:&nbsp;
                        <ClientLink to={routes.app.resolve.peopleDetailPage(selectedAttendee.id)}>
                            {selectedAttendee.name}
                        </ClientLink>
                    </Typography>
                </Grid>
                <GridGrow />
            </Grid>
            <Grid container justify="center" style={{ minHeight: "calc(100% - 110px)", background: "#FFF" }}>
                <Grid item xs={12}>
                    {selectedAttendee && <InboxMessageHistory />}
                </Grid>
            </Grid>

            <div style={{ position: "sticky", bottom: 0, zIndex: 1000, backgroundColor: "#FFF", paddingBottom: 10 }}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <MessageInput
                            disabled={selectedAttendee === undefined || disabled}
                            onSend={handleSend}
                            errors={fieldErrors}
                            key={`${selectedAttendee?.id}`}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}