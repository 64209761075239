import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { PageTitle } from "../../components/PageTitle";
import { isMobile } from "../../hooks/isMobile";
import JourneyListingTable from "./Components/JourneyListingTable";

const JourneyListPage = () => {
	const mobile = isMobile();

	return (
		<div>
			<PageTitle title="Journeys" />
			{!mobile && (
				<Typography gutterBottom variant="h1">
					Journeys
				</Typography>
			)}
			<Paper style={{paddingBottom: 5}}>
				<JourneyListingTable />
			</Paper>
		</div>
	);
};

export default JourneyListPage;
