import { fade, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";

export function Pulse(props: PropsWithChildren<{on?: boolean}>){
    const classes = useStyles();

    return(
        <span className={classNames(props.on && classes.responseHelpPulse)}>
            {props.children}
        </span>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    responseHelpPulse: {
		animation: "$greenPulse 2s infinite",
		borderRadius: "50%"
	},
	'@keyframes greenPulse': {
		"0%": {
			boxShadow: `0 0 0 0 ${fade(theme.palette.secondary.main , 1)}`,
			
		},
		'70%': {
			boxShadow: `0 0 0 10px ${fade(theme.palette.secondary.main , 0)}`,
		},
		'100%': {
			boxShadow: `0 0 0 0 ${fade(theme.palette.secondary.main , 0)}`,
		}
	},
}));
