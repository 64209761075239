import React, { useEffect, useState } from "react";
import {
	FormControl,
	Grid,
	makeStyles,
	Menu,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
	IconButton,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { orderBy, SortDirection } from "../../../utillity/orderBy";
import { GroupMember, printGroupMemberStatus } from "../../../entities/Groups/GroupMember";
import { buildDateTimeString } from "../../../functions/datetime";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { ButtonLink } from "../ButtonLink";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { Enum } from "../../../utillity/Enum";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";
import MoreIcon from "@material-ui/icons/MoreVert"
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useGroups } from "../../../providers/GroupProvider";
import { Group } from "../../../entities/Groups/Group";
import { ChurchOptInStatus } from "../../../entities/ChurchOptInStatus";

const useGroupListingStyle = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
	filterSelector: {
		color: theme.palette.secondaryResponse.main,
		backgroundColor: "#fafafa",
		marginLeft: 16,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	menuDetails: {
		"&:hover": {
			backgroundColor: "inherit",
			cursor: "default"
		}
	}
}));

interface MemberTableProps {
	members: GroupMember[];
	selectedGroup: Group;
	onInvitepeople: () => void
}

const MemberTable = (props: MemberTableProps) => {
	const [sortField, setSortField] = useState<"memberName" | "inviteStatus" | "dateJoined" | "phoneNumber">("dateJoined");

	const classes = useGroupListingStyle();
	const groupContext = useGroups();;
	const setServerError = useServerErrorAlert();

	const [searchText, startSearchItems] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");
	const [displayedMembers, setDisplayedMembers] = useState(props.members);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedMember, setSelectedMember] = useState<GroupMember | null>(null)
	const [filterByItem, setFilterByItem] = useState<GroupMembershipStatus>(GroupMembershipStatus.Accepted);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		let sortedmembers = props.members.filter(m => {
			if(filterByItem === GroupMembershipStatus.Declined){
				return m.groupMembershipStatus === filterByItem || m.churchOptInStatus === ChurchOptInStatus.Declined;
			} else {
				return m.groupMembershipStatus !== GroupMembershipStatus.Declined
			}
		})

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			sortedmembers = sortedmembers.filter((listItem) =>
				(listItem.firstName).toLowerCase().includes(queryText)
			);
		}
		switch (sortField) {
			case "memberName":
				sortedmembers.sort(orderBy.string((i) => `${i.firstName} ${i.lastName}`, sortDirection));
				break;
            case"inviteStatus":
                sortedmembers.sort(orderBy.number((i) => i.groupMembershipStatus, sortDirection));
                break;
            case"phoneNumber":
                sortedmembers.sort(orderBy.string((i) => i.phoneNumber, sortDirection));
                break;
            case"dateJoined":
                sortedmembers.sort(orderBy.date((i) => i.timeJoined, sortDirection));
                break;
            default:
				break;
		}

		setDisplayedMembers(sortedmembers);
		setPage(0);
	}, [searchText, sortDirection, props.members, sortField, filterByItem]);
	
	const removeGroupMember = async(attendeeId:string) => {
		setDisabled(true)
		const result = await GroupService.removeAttendeesFromGroup({attendeeIds: [attendeeId], groupId: props.selectedGroup.id})
		setDisabled(false)

		if(ServerResult.isError(result))
			return setServerError(result)
		
		if(ServerResult.isSuccess(result)){
			groupContext.update(result.data);
			setSelectedMember(null);
			setAnchorEl(null)
		}
	}
	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid container>
					<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							{`${displayedMembers.length} ${displayedMembers.length === 1 ? "Person" : "People"}`}
						</Typography>
					</Grid>
					<div className={classes.grow}/>
					<Grid item>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchItems(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
						/>
					</Grid>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid container>
					<Grid item style={{ alignSelf: "center"}}>
						<FormControl>
							<Select
								disableUnderline
								labelId="filter-journey-select-label"
								id="filter-journey-select"
								value={filterByItem}
								className={classes.filterSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setFilterByItem(parseInt(event.target.value as string))
								}
							>
							<MenuItem value={GroupMembershipStatus.Accepted}>
								Active
							</MenuItem>
							<MenuItem value={GroupMembershipStatus.Declined}>
								Declined
							</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<div className={classes.grow}/>
					<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={displayedMembers.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
							onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
							labelRowsPerPage="Show"
						/>
				</Grid>
					<Grid item xs={12} style={{ marginTop: 8 }}>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
											sortDirection={
												sortField === "memberName"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "memberName"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("memberName");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Name
											</TableSortLabel>
										</TableCell>
										<TableCell
											align="left"
											sortDirection={
												sortField === "inviteStatus"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "inviteStatus"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("inviteStatus");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Invitation Status
											</TableSortLabel>
										</TableCell>
										<TableCell
											align="left"
											sortDirection={
												sortField === "phoneNumber"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "phoneNumber"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("phoneNumber");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Phone
											</TableSortLabel>
										</TableCell>
										<TableCell
											align="left"
											sortDirection={
												sortField === "phoneNumber"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "dateJoined"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("dateJoined");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Date Joined
											</TableSortLabel>
										</TableCell>
										<TableCell padding="checkbox" size="small"/>
									</TableRow>
								</TableHead>
								<TableBody>
									{displayedMembers.length === 0 && (
										<TableRow>
											<TableCell>
												<Typography style={{ paddingTop: 24 }} variant="body2">
												{ props.members.length === 0
													? <>It's lonely in here. <ButtonLink onClick={props.onInvitepeople}>Add People to your group</ButtonLink></>
													: <>0 people {Enum.print(GroupMembershipStatus, filterByItem).toLowerCase()}</>
												}
												</Typography>
											</TableCell>
											<TableCell />
											<TableCell />
											<TableCell />
											<TableCell padding="checkbox" size="small"/>
										</TableRow>
									)}
									{(rowsPerPage > 0
										? displayedMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: displayedMembers
									).map((member: GroupMember, index: number) => {
										return (
											<TableRow
												key={index}
											>
												<TableCell>
													<Link
														style={{ color: "rgb(25, 118, 210)" }} 
														to={routes.app.resolve.peopleDetailPage(member.attendeeId)} >
													{`${member.firstName} ${member.lastName}`}
													</Link>
												</TableCell>
												<TableCell>{printGroupMemberStatus(member)}</TableCell>
												<TableCell>{formatPhoneNumber(member.phoneNumber)}</TableCell>
												<TableCell>{buildDateTimeString(member.timeJoined.toDateString(), true)}</TableCell>
												<TableCell padding="checkbox" size="small" onClick={(e) => {
														setAnchorEl(e.currentTarget);
														setSelectedMember(member);
													}} >
													<IconButton>
														<MoreIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
			</Grid>
			{anchorEl && selectedMember && (
				<Menu
					id="menu-collaborator"
					anchorEl={anchorEl}
					autoFocus={false}
					anchorOrigin={{
						vertical: "center",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "center",
						horizontal: "right",
					}}
					open={anchorEl != null}
					onClose={() => {
						setAnchorEl(null);
						setSelectedMember(null);
					}}
				>
					<MenuItem 
						onClick={() => window.location.href = routes.app.resolve.peopleDetailPage(selectedMember.attendeeId)}
						disabled={disabled}
					>
						Manage contact info
					</MenuItem>
					{ selectedMember.groupMembershipStatus !== GroupMembershipStatus.Declined
						&&
					<MenuItem 
						onClick={() => removeGroupMember(selectedMember.attendeeId)}
						disabled={disabled}
					>
						Remove from this group
					</MenuItem>
					}
				</Menu>
			)}
		</div>
	);
};

export default MemberTable;