import { DurationNodaTime } from "../entities/Reminder";

//MM/DD/YYYY HH:MM AM/PM
export const buildDateTimeString = (timestampString: string, showOnlyDate?: boolean, showHrsAndMinutes?: boolean) => {
	let timestamp = new Date(timestampString);

	let datePortion = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()}`;

	let hours = timestamp.getHours();
	let minutes = timestamp.getMinutes();
	let ampm = hours >= 12 ? "PM" : "AM";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	let stringMinutes = minutes < 10 ? "0" + minutes : minutes;
	let timePortion = hours + ":" + stringMinutes + ":" + timestamp.getSeconds() + "." + timestamp.getMilliseconds() + " " + ampm;

	if (showOnlyDate) {
		return `${datePortion}`;
	}

	if(showHrsAndMinutes)
		return `${datePortion} ${hours}:${stringMinutes}${ampm}`;

	return `${datePortion} ${timePortion}`;
};

export const SubtractDaysReturnDate = (date: Date, daysIntoThePast: number) => {
	let newDate = new Date(date.setDate(date.getDate() - daysIntoThePast));
	return new Date(newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear());
};

export const AddDaysReturnDate = (date: Date, daysIntoTheFuture: number) => {
	let newDate = new Date(date.setDate(date.getDate() + daysIntoTheFuture));
	return new Date(newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear());
};

// If last 24, return hours since, otherwise return date
export const TimeSinceMessage = (timestampString: string | Date) => {
	let timestamp = new Date(timestampString);

	var diff = (timestamp.getTime() - new Date(Date.now()).getTime()) / 1000;
	diff /= 60 * 60;

	let hourDiff = Math.abs(Math.round(diff));

	if (hourDiff < 25) {
		if (hourDiff === 0) {
			hourDiff = 1;
		}
		return `${hourDiff} hr`;
	}

	let datePortion = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()}`;

	return `${datePortion}`;
};

export const printTimeSinceDate = (date: Date | null | undefined) => {
	if(date == null){
		return "Unknown";
	}

	const now = new Date();
	// get total seconds between the times
	let time = Math.abs(date.getTime() - now.getTime());

	const weekInMilliseconds = (1000 * 60 * 60 * 24 * 7);
	const dayInMilliseconds = (1000 * 60 * 60 * 24);
	const hourInMilliseconds = (1000 * 60 * 60);
	const minuteInMilliseconds = (1000 * 60 );
	const secondInMilliseconds = (1000);

	// More than 3 weeks ago so just show a date
	if(time / weekInMilliseconds > 3){
		return date.toLocaleDateString()
	}
	// If there are weeks
	if (time > weekInMilliseconds) {
		const weeks = Math.floor(time / weekInMilliseconds);
		return `${weeks} week${weeks > 1 ? "s" : ""}`
	}
	// If there are days
	if (time > dayInMilliseconds) {
		const days = Math.floor(time / dayInMilliseconds);
		return `${days} day${days > 1 ? "s" : ""}`
	}
	// If there are hours
	if (time > hourInMilliseconds) {
		const hours = Math.floor(time / hourInMilliseconds);
		return `${hours} hr${hours > 1 ? "s" : ""}`
	}
	// If there are minutes
	if (time > minuteInMilliseconds) {
		const minutes = Math.floor(time / minuteInMilliseconds);
		return `${minutes} min${minutes > 1 ? "s" : ""}`
	}

	// Otherwise, use seconds
	const seconds = Math.floor(time / secondInMilliseconds);
	return `${seconds} sec${seconds > 1 ? "s" : ""}`
};

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const getShortWeekDay = (dayNumber: number) => {
	return days[dayNumber];
};

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
export const getLongNameMonth = (monthNumber: number) => {
	return months[monthNumber];
};

export const getDayWithTenseString = (dayNumber: number) => {
	let tense = "";

	if (dayNumber >= 4 && dayNumber <= 20) {
		tense = "th";
	} else if (dayNumber % 10 === 1) {
		tense = "st";
	} else if (dayNumber % 10 === 2) {
		tense = "nd";
	} else if (dayNumber % 10 === 3) {
		tense = "rd";
	} else {
		tense = "th";
	}

	return `${dayNumber}${tense}`;
};

export const stripTimeFromDate = (date: Date) => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getLastSunday = () => {
	let now = new Date();
	let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	return new Date(today.setDate(today.getDate() - today.getDay()));
};

export const buildTimeSendDisplay = (timeSet: string) => {
	let splits = timeSet.split(":");
	let AMPM = "AM";
	let hours = splits[0];
	let minutes = splits[1];

	if (+hours > 11) {
		AMPM = "PM";
	}

	if (+hours > 12) {
		hours = (+hours % 12).toString();
	}

	return `${+hours}:${minutes} ${AMPM}`;
};

export const createNodaTimeLocalDateString = (date: Date) => {
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();

	return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getDateFromDuration = (duration: DurationNodaTime, orginDate: Date) => {
	let date = new Date(orginDate.setDate(orginDate.getDate() - duration.days));
	date = new Date(date.setHours(date.getHours() - duration.hours));
	date = new Date(date.setMinutes(date.getMinutes() - duration.minutes));

	return date;
};

export const convertToLocalTime = (javascriptDate: Date): string => {
	return (
		javascriptDate.getFullYear() +
		"-" +
		zeroPad(javascriptDate.getMonth() + 1) +
		"-" +
		zeroPad(javascriptDate.getDate()) +
		"T" +
		zeroPad(javascriptDate.getHours()) +
		":" +
		zeroPad(javascriptDate.getMinutes()) +
		":" +
		zeroPad(javascriptDate.getSeconds()) +
		"." +
		(javascriptDate.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
	);
};

export const zeroPad = (number: Number): string => {
	if (number < 10) {
		return "0" + number;
	}
	return number.toString();
};

export const setDateTime = (date: Date, time: string) => {
	const dateParts = date.toLocaleDateString().split("/");
	const year = dateParts[2];
	const month = dateParts[0].length === 1 ? `0${dateParts[0]}` : dateParts[0];
	const day = dateParts[1].length === 1 ? `0${dateParts[1]}` : dateParts[1];
	return new Date(`${year}-${month}-${day}T${time}`);
};

export const printMessagesGroupedByDate = (date: Date) => {
	const now = new Date();
	const lastYear = now.getFullYear() - 1;

	const yesterdayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
	const sevenDaysAgoDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
	const oneYearAgoDate = new Date(lastYear, now.getMonth(), now.getDate());
	
	const timeString = date.toLocaleTimeString("en-US", {hour: '2-digit', minute:'2-digit', hour12: true})

	if(oneYearAgoDate > date){
		return date.toLocaleDateString("en-US",{year: 'numeric', month: '2-digit', day: '2-digit'});
	}
	
	if (sevenDaysAgoDate > date) {
		return `${date.toLocaleDateString("en-US",{ weekday: 'short', month: 'short', day: 'numeric' })}, ${timeString}`;
	}
	
	if(yesterdayDate > date){
		return `${date.toLocaleDateString("en-US",{ weekday: 'long'})} ${timeString}`;
	}

	if(yesterdayDate.getDate() === date.getDate()){
		return `Yesterday ${timeString}`;
	}
	
	return `Today ${timeString}`;
};