export default interface ResponseSubscription {
	id: string;
	name: string;
	discountName: string;
	couponCode?: string;
	price: number;
	status: SubscriptionStatus;
	startDate: Date;
	endDate?: Date;
	trialStart?: Date;
	trialEnd?: Date;
	cancelAtPeriodEnd: boolean;
	cancelOn?: Date;
	isCanceled: boolean;
	currentPeriodStart: Date;
	currentPeriodEnd: Date;
	term: SubscriptionTerm;
	nextInvoiceAmountDue?: number;
	nextInvoiceDate?: Date;
	currentPaymentMethodId: string;
	currentPriceId: string;
}

export enum SubscriptionTerm {
	Month = 1,
	Annual = 2,
}

export enum SubscriptionStatus {
	Unknown = 0,
	Incomplete = 1,
	Incomplete_Expired = 2,
	Trialing = 3,
	Active = 4,
	Past_Due = 5,
	Canceled = 6,
	Unpaid = 7,
}
