import { Grid, makeStyles, Theme, } from "@material-ui/core";
import React from "react";
import { PageTitle } from "../../components/PageTitle";
import { Group } from "../../entities/Groups/Group";
import { GroupDetailsSection } from "./Components/GroupDetailsSection";
import { GroupOverView } from "./Components/GroupOverView";

const useGroupStyles = makeStyles((theme: Theme) => ({
	GroupsContainer: {
		paddingLeft: 16,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0,
		},
	},
	pageHeader: {
		color: theme.palette.secondaryResponse.main,
		marginBottom: 15,
	},
	contentPaper: {
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: 20,
        [theme.breakpoints.down("md")]: {
            boxShadow: "none"
        },
        paddingLeft: 48,
        paddingRight: 48,
        paddingBottom: 20,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
	},
    contentCard: {
		marginRight: 16,
		[theme.breakpoints.down("md")]: {
            marginRight: 0
		},
	},
}))

interface GroupDetailPageProps {
    selectedGroup: Group;
}

export function GroupDetailPage(props: GroupDetailPageProps){
    const group = props.selectedGroup;
    const classes = useGroupStyles();

    return (
        <>
            <PageTitle title={group.name} />
            <div className={classes.GroupsContainer}>
                <Grid container style={{ position: "relative" }} alignItems="center">
                    <GroupDetailsSection selectedGroup={group} />
                    <GroupOverView group={group} />
                </Grid>
            </div>
        </>
    )
}