import React, { useState } from "react";
import { Grid, makeStyles, Theme, useTheme, } from "@material-ui/core";
import { Group } from "../../../entities/Groups/Group";
import { MemberTableMobile } from "./MemberTableMobile";
import classNames from "classnames";
import { GroupTab } from "../../../entities/GroupTab";
import SwipeableViews from "react-swipeable-views";
import { GroupAnnouncementTableMobile } from "./GroupAnnouncementTableMobile";

export const useStyles = makeStyles((theme: Theme) => ({
	toolbar: theme.mixins.toolbar,
	toolbarCustom: {
		[theme.breakpoints.down("md")]: {
			backgroundColor: "#e5e5e5",
			minHeight: 60,
		},
	},
	setUpContainer: {
		minHeight: 400,
		width: "100%",
		paddingTop: 10,
		paddingLeft: 48,
		paddingRight: 48,
		backgroundColor: "#FFF",
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	defaultTabStyle: {
		textAlign: "center",
		height: 48,
		boxShadow: "0 2px inset #d6d6d6, 0 -1px #c4c4c4",
		color: theme.palette.secondaryResponse.main,
		backgroundColor: theme.palette.lightGrayBlue.main,
		fontSize: 18,
		paddingTop: 12,
	},
	selectedTabStyle: {
		textDecoration: "underline",
		boxShadow: "0 -3px #dfe1e1",
		backgroundColor: "#FFF",
		fontWeight: 700,
	},
}));

interface GroupOverViewMobileProps {
	group: Group;
	updateSelectedTab: (tab:GroupTab) => void;
}

export function GroupOverViewMobile(props: GroupOverViewMobileProps){
    const classes = useStyles();
	const theme = useTheme();
	const [selectedTab, setSelectedTab ] = useState(GroupTab.People)

	const onSelectTab = (tab:GroupTab) => {
		props.updateSelectedTab(tab);
		setSelectedTab(tab);
	}

    return (
		<>
			<div className={classNames(classes.toolbar, classes.toolbarCustom)} />
			<Grid container style={{ marginTop: -4 }}>
				<Grid item xs={6}>
					<div
						style={{
							borderTopLeftRadius: 9,
							borderBottomRightRadius: selectedTab !== GroupTab.People ? 4 : undefined,
						}}
						className={classNames(
							classes.defaultTabStyle,
							selectedTab === GroupTab.People ? classes.selectedTabStyle : undefined
						)}
						onClick={() => onSelectTab(GroupTab.People)}
					>
						People
					</div>
				</Grid>
				<Grid item xs={6}>
					<div
						style={{
							borderTopRightRadius: 9,
							borderBottomLeftRadius: selectedTab !== GroupTab.Announcements ? 4 : undefined,
						}}
						className={classNames(
							classes.defaultTabStyle,
							selectedTab === GroupTab.Announcements ? classes.selectedTabStyle : undefined
						)}
						onClick={() => onSelectTab(GroupTab.Announcements)}
					>
						Announcements
					</div>
				</Grid>
			</Grid>

			<div className={classes.setUpContainer}>
				<Grid container>
					<SwipeableViews
						axis={theme.direction === "rtl" ? "x-reverse" : "x"}
						index={selectedTab}
						onChangeIndex={(index: GroupTab) => onSelectTab(index)}
						style={{ backgroundColor: "#FFF", width: "100%", paddingLeft: 8, paddingRight: 8 }}
					>
						<div style={{ width: "100%" }} hidden={selectedTab !== GroupTab.People} dir={theme.direction}>
							<MemberTableMobile selectedGroup={props.group}/>
						</div>
						<div
							style={{ width: "100%" }}
							role="tabpanel"
							hidden={selectedTab !== GroupTab.Announcements}
							dir={theme.direction}
						>
							<GroupAnnouncementTableMobile selectedGroup={props.group} />
						</div>
					</SwipeableViews>
				</Grid>
			</div>
		</>
		)
	}