import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface SentMessages {
    date: Date;
    messagesSent: number;
}

export interface DashboardData {
    hasSentMessage: boolean;
    daysAccountHasBeenOpen: number;
    recentSentMessages: SentMessages[];
    dismissedIds: string[];
}

type SerializedSentMessages = Modify<SentMessages, { date: string}>;
type SerializedDashboardData = Modify<DashboardData, { recentSentMessages: SerializedSentMessages[]}>;

const deserializeSentMessages = (model: SerializedSentMessages): SentMessages => ({
	...model,
	date: new Date(model.date),
});

const deserializeDashboardData = (model: SerializedDashboardData): DashboardData => ({
	...model,
    recentSentMessages: model.recentSentMessages.map(deserializeSentMessages)
});

export const DashboardService = {
    get: () => WebClient.Convert.Get(`${apiRoot}/dashboard`, deserializeDashboardData),
    dismissBlock: (blockId: string) => WebClient.Get(`${apiRoot}/dashboard/dismiss/${blockId}`)
}