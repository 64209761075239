import { Grid, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

const useCommon = makeStyles((theme: Theme) => ({
	titlebartitle: {
		color: "#FFF",
		width: "100%",
	},
	titleCloseIcon: {
		float: "right",
	},
	grow: {
		flex: 1,
	},
	actionButtons: {
		borderRadius: 8,
		height: 36,
		margin: 12,
		marginBottom: 0,
		[theme.breakpoints.down("sm")]: {
			marginBottom: 24,
		},
	},
	backButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
	nextButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
	},
	progressActions: {
		display: "inline-flex",
	},
}));

const useDesktop = makeStyles((theme: Theme) => ({
	titlebar: {
		width: "100%",
		height: 54,
		backgroundColor: theme.palette.secondaryResponse.main,
		display: "flex",
		alignItems: "center",
	},
	progressPage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const useMobile = makeStyles((theme: Theme) => ({
	swoopBox: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.secondaryResponse.main,
		zIndex: 2000,
		top: 0,
	},
	swoopBoxGreen: {
		position: "fixed",
		borderRadius: "50%/0 0 100px 100px",
		backgroundColor: theme.palette.primaryResponse.main,
		top: 40,
		boxShadow: "0 8px 6px -6px #C7BAB9",
	},
	headerBar: {
		position: "fixed",
		zIndex: 2001,
		width: "100%",
		display: "flex",
		alignItems: "center",
	},
	setupContent: {
		position: "fixed",
		padding: "16px 0 6px 0",
		zIndex: 2001,
		top: 64,
		bottom: 0,
		left: 16,
		right: 16,
		borderRadius: "12px 12px 0 0",
	},
}));

type Props = {
	formId?: string;
	journeyId?: string;
};

export function FormBuilderStepLayout(props: React.PropsWithChildren<Props>) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <MobileLayout {...props} />;
	}
	return <DesktopLayout {...props} />;
}

function DesktopLayout(props: React.PropsWithChildren<Props>) {
	const classes = { ...useCommon(), ...useDesktop() };
	const { formId } = props;
	return (
		<Grid container style={{ height: 800, width: 960 }}>
			<Grid className={classes.titlebar} item xs={12}>
				<Typography className={classes.titlebartitle} variant={"h4"} style={{ paddingLeft: 50 }}>
					{formId ? "Edit form" : "Add a form"}
				</Typography>
			</Grid>
			<Grid className={classes.progressPage} item xs={12}>
				<Grid style={{ minHeight: 600 }} container direction="row">
					{props.children}
				</Grid>
			</Grid>
		</Grid>
	);
}

function MobileLayout(props: React.PropsWithChildren<Props>) {
	const classes = { ...useCommon(), ...useMobile() };

	let windowSize = window.innerWidth;
	let swoopWidth = windowSize * 1.6;
	let leftPos = ((swoopWidth - windowSize) / 2) * -1;

	return (
		<>
			<div
				className={classes.swoopBox}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			></div>
			<div
				className={classes.swoopBoxGreen}
				style={{
					width: swoopWidth,
					minHeight: 100,
					left: leftPos,
				}}
			/>

			<Paper className={classes.setupContent}>
				<Grid container style={{ display: "block", overflowY: "auto", height: "100%" }}>
					<Grid item xs={12} style={{ paddingLeft: 12, paddingRight: 12 }}>
						{props.children}
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
