import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import Clock from "@material-ui/icons/AccessTime";
import MoreOptionsIcon from "@material-ui/icons/AddCircleOutline";
import CheckMark from "@material-ui/icons/Check";
import LessOptionsIcon from "@material-ui/icons/RemoveCircleOutline";
import classNames from "classnames";
import React, { useState } from "react";
import { Journey } from "../entities/Journey";
import { JwtUser } from "../entities/JwtUser";
import Reminder from "../entities/Reminder";
import PersonalizedTextBox from "./PersonalizedTextBox";

export const useReminderListStyles = makeStyles((theme: Theme) => ({
	checkMark: {
		fontSize: 20,
		marginTop: 4,
	},
	lineItem: {
		paddingBottom: 2,
		paddingTop: 2,
		display: "flex",
		borderRadius: 12,
		width: 200,
		"&:hover": {
			backgroundColor: "rgba(245, 245, 247)",
			cursor: "pointer",
		},
	},
	selectedItem: {
		backgroundColor: "rgba(0,0,0,.1)",
	},
	disabledLine: {
		opacity: 0.3,

		"&:hover": {
			cursor: "not-allowed",
		},
	},
	icons: {
		color: theme.palette.secondaryResponse.main,
		cursor: "pointer",
		marginRight: 8,
		marginTop: 7,
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
	},
	option: {
		display: "flex",
		height: 40,
		width: 140,
		backgroundColor: "rgb(245,246,247)",
		border: "1px solid rgb(227, 231, 240)",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
		[theme.breakpoints.down("sm")]: {
			width: "50%",
		},
	},
	optionText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		marginTop: 8,
		marginLeft: 14,
	},
	grow: {
		flex: 1,
	},
}));

interface UIReminder {
	id: number;
	text: string;
	days: number;
	hours: number;
	minutes: number;
	nodaTimeDuration: string;
}

const Reminders: UIReminder[] = [
	{
		id: 0,
		text: "30 minutes before",
		days: 0,
		hours: 0,
		minutes: 30,
		nodaTimeDuration: "00:30:00.000000000",
	},
	{
		id: 1,
		text: "1 hour before",
		days: 0,
		hours: 1,
		minutes: 0,
		nodaTimeDuration: "01:00:00.000000000",
	},
	{
		id: 2,
		text: "4 hours before",
		days: 0,
		hours: 4,
		minutes: 0,
		nodaTimeDuration: "04:00:00.000000000",
	},
	{
		id: 3,
		text: "1 day before",
		days: 1,
		hours: 0,
		minutes: 0,
		nodaTimeDuration: "24:00:00.000000000",
	},
	{
		id: 4,
		text: "3 days before",
		days: 3,
		hours: 0,
		minutes: 0,
		nodaTimeDuration: "72:00:00.000000000",
	},
	{
		id: 5,
		text: "1 week before",
		days: 7,
		hours: 0,
		minutes: 0,
		nodaTimeDuration: "168:00:00.000000000",
	},
];

interface ReminderDialogProps {
	reminders: Reminder[];
	isVisible: boolean;
	disabled: boolean;
	reminderInEdit?: Reminder;
	onClose: () => void;
	onSubmit: (duration: string, message: string) => void;
	journey: Journey;
	user: JwtUser;
}

const ReminderDialog = (props: ReminderDialogProps) => {
	const classes = useReminderListStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { reminders, isVisible, disabled, onClose, onSubmit, reminderInEdit, journey, user } = props;
	const [userSelectedReminder, setUserSelectedReminder] = useState<UIReminder | undefined>(undefined);
	const [message, setMessage] = useState(reminderInEdit ? reminderInEdit.message : "");

	const [messageError, setMessageError] = useState<string | undefined>(undefined);
	const [scheduleError, setScheduleError] = useState<string | undefined>(undefined);
	const [showOptions, setShowOptions] = useState(false);

	const isReminderSelected = (uiReminder: UIReminder) => {
		return reminders.some(
			(ele) =>
				ele.timeBeforeEvent.days === uiReminder.days &&
				ele.timeBeforeEvent.hours === uiReminder.hours &&
				ele.timeBeforeEvent.minutes === uiReminder.minutes
		);
	};

	const submitReminder = () => {
		let hasError = false;
		if (message == null || message.length === 0) {
			setMessageError("A message is required for the reminder");
			hasError = true;
		}

		if (!reminderInEdit && !userSelectedReminder) {
			setScheduleError("A schedule send time is required for the reminder");
			hasError = true;
		}

		if (hasError) {
			return;
		}

		let item = userSelectedReminder ? userSelectedReminder : reminderInEdit?.timeBeforeEvent;

		if (item) {
			let hrs = item.days * 24 + item.hours;

			let duration = `${hrs < 10 ? "0" : ""}${hrs}:${item.minutes < 10 ? "0" : ""}${item.minutes}:00.000000000`;

			onSubmit(duration, message);
		}
	};

	const buildLineItem = (item: UIReminder) => {
		let alreadySelected = isReminderSelected(item);

		let durationBreakdown = reminderInEdit?.timeBeforeEvent;

		let matchInEdit =
			durationBreakdown?.days === item.days &&
			durationBreakdown?.hours === item.hours &&
			durationBreakdown?.minutes === item.minutes;

		let checkAndNotDisabled = (matchInEdit && !userSelectedReminder) || userSelectedReminder?.id === item.id;
		let checkAndDisabled = alreadySelected && userSelectedReminder?.id !== item.id && !matchInEdit;

		return (
			<div
				key={item.id}
				className={classNames(
					classes.lineItem,
					userSelectedReminder?.id === item.id && classes.selectedItem,
					checkAndDisabled && classes.disabledLine
				)}
				onClick={
					!checkAndDisabled
						? () => {
								setUserSelectedReminder(item);
								setScheduleError(undefined);
						  }
						: undefined
				}
			>
				<div style={{ width: 24 }}>
					{(checkAndNotDisabled || checkAndDisabled) && <CheckMark className={classes.checkMark} />}
				</div>
				<Typography variant="subtitle1">{item.text}</Typography>
			</div>
		);
	};

	const addPersonalization = (token: string) => {
		setMessage(message + " " + token);
	};

	return (
		<Dialog open={isVisible} onClose={() => onClose()} fullScreen={mobileFormFactor}>
			<DialogTitle id="add-reminder-title" disableTypography>
				<div style={{ display: "flex" }}>
					<Clock style={{ marginRight: 4, color: theme.palette.primaryResponse.main }} />
					<Typography
						style={{ color: theme.palette.secondaryResponse.main, fontSize: 20, fontWeight: 600, paddingLeft: 2 }}
						variant="h2"
					>
						Schedule
					</Typography>
				</div>
			</DialogTitle>
			<DialogContent style={{ color: theme.palette.secondaryResponse.main }}>
				When would you like to send this reminder
				<div style={{ marginTop: 12 }}>
					{Reminders.map((item: UIReminder) => {
						return buildLineItem(item);
					})}
					{scheduleError && (
						<Typography style={{ color: theme.palette.error.main, fontSize: 12 }} variant="subtitle1">
							{scheduleError}
						</Typography>
					)}
				</div>
				<div style={{ marginTop: 12, width: !mobileFormFactor ? 420 : "100%" }}>
					<Typography variant="subtitle1">Message</Typography>
					<PersonalizedTextBox
						journey={journey}
						message={message}
						user={user}
						onChange={(value) => setMessage(value)}
					/>
					{messageError && (
						<Typography style={{ color: theme.palette.error.main, fontSize: 12 }} variant="subtitle1">
							{messageError}
						</Typography>
					)}
				</div>
				<Grid container>
					<Grid item xs={12} style={{ marginTop: 4 }}>
						{!showOptions ? (
							<div style={{ cursor: "pointer", display: "flex" }} onClick={() => setShowOptions(true)}>
								<MoreOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
								<Typography
									className={classes.blueStrongText}
									style={{ cursor: "pointer", marginTop: 6 }}
									variant="subtitle2"
								>
									More Options
								</Typography>{" "}
							</div>
						) : (
							<div style={{ cursor: "pointer", display: "flex" }} onClick={() => setShowOptions(false)}>
								<LessOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
								<Typography className={classes.blueStrongText} variant="subtitle2" style={{ marginTop: 6 }}>
									Less Options
								</Typography>{" "}
							</div>
						)}
					</Grid>
				</Grid>
				{showOptions && (
					<div style={{ display: "flex", flexWrap: "wrap", maxWidth: !mobileFormFactor ? 420 : "100%" }}>
						<div className={classes.option} onClick={() => addPersonalization("{{First_Name}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								First Name
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Full_Name}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Full Name
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Keyword}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Keyword
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Link}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Link
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Media}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Media
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Address}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Address
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Church}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Church
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Event}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Event
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
						<div className={classes.option} onClick={() => addPersonalization("{{Form}}")}>
							<Typography className={classes.optionText} variant="subtitle2">
								Form
							</Typography>
							<div className={classes.grow} />
							<MoreOptionsIcon className={classes.icons} />
						</div>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="default" size="medium" onClick={() => onClose()} disabled={disabled}>
					Cancel
				</Button>
				<Button variant="outlined" color="primary" size="medium" disabled={disabled} onClick={() => submitReminder()}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReminderDialog;
