import React, { useEffect } from "react";
import { LoadingPage } from "../../components/LoadingPage";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useUser } from "../../hooks/useUser";
import { routes } from "../../routes";
import ResponseSubscriptionService from "../../services/ResponseSubscriptionService";
import { ServerResult } from "../../services/server/ServerResult";
import { EventTracking } from "../../utillity/Tracking/EventTracking";

export function CheckoutComplete(){
    const [, setUser] = useUser();
    const params = useQueryParams();

    const jwt = params.get("token")!;

    useEffect(() => {
        async function recordPurchase(){
            const response = await ResponseSubscriptionService.get();
            if(ServerResult.isSuccess(response)){
                if(localStorage.getItem("purchase") == null){
                    EventTracking.SignUp.purchase(response.data);
                    localStorage.setItem("purchase", "true");
                }
                
            }
            
            window.location.href = routes.app.dashBoardPage;
        }
        try{
            setUser(jwt);
            recordPurchase();
        } catch{
            window.location.href = routes.app.dashBoardPage;
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LoadingPage/>;
}