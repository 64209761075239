import { Grid, Typography} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { ResponseDialog } from "../../components/ResponseDialog/ResponseDialog";
import { ResponseFab } from "../../components/ResponseFab";
import { isMobile } from "../../hooks/isMobile";
import AddPeopleView from "../../layout/CreateNewDialog/AddPeopleView";
import { routes } from "../../routes";
import { PeopleActions } from "./Components/PeopleActions";
import PeopleListingTable from "./Components/PeopleListingTable";
import PeopleMobileListing from "./Components/PeopleMobileListing";
import { usePeopleRouter } from "./PeoplePage";

export function PeopleListPage() {
	const PeopleRouterContext = usePeopleRouter();
	const mobile = isMobile();
	const location = useLocation();
	const history = useHistory();
	const isInvitePersonRouteRoute = matchPath(location.pathname, {path: routes.app.invitePersonPage, exact: true, strict: true}) != null;

	const [showBulkWarning, setShowBulkWarning] = useState(false);
	const [showAddPersonDialog, setShowAddPersonDialog] = useState(isInvitePersonRouteRoute);
	
	useEffect(() => {
		if(!showAddPersonDialog && isInvitePersonRouteRoute){
			history.replace(routes.app.peopleListPage)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInvitePersonRouteRoute, showAddPersonDialog])

	useEffect(() => setShowBulkWarning(false), [PeopleRouterContext.selectedAttendees])

	return (
		<>
			<PageTitle title="People" />
			{!mobile && (
				<>
					<Typography variant="h1">
						People
					</Typography>
					<Grid container style={{marginTop: 20}}>
						<PeopleListingTable showWarning={showBulkWarning} />
						<PeopleActions setWarning={(value) => setShowBulkWarning(value)} showWarning={showBulkWarning} />
					</Grid>
				</>
			)}
			{mobile && <PeopleMobileListing />}
			{ showAddPersonDialog
				?
				<ResponseDialog onClose={() => setShowAddPersonDialog(false)} open={showAddPersonDialog} title={"Add Person"} >
					<AddPeopleView onClose={() => setShowAddPersonDialog(false)} onBack={() => setShowAddPersonDialog(false)} />
				</ResponseDialog>
				: 
				<ResponseFab onClick={() => setShowAddPersonDialog(true)} extendedButtonText={"+ Add Person"} />
			}
		</>
	);
}
