import { Grid, Typography, Theme, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { ImageDialog } from "../../../components/ImageDialog";
import { TextMessageDirection } from "../../../entities/Messages/TextMessageDirection";
import TextMessageHistoryItem, { printMessageType } from "../../../entities/Messages/TextMessageHistoryItem";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";
import { TextMessageMediaCategory } from "../../../entities/Messages/TextMessageMediaCategory";
import { TextMessageStatus } from "../../../entities/Messages/TextMessageStatus";
import { TextMessageType } from "../../../entities/Messages/TextMessageType";
import { useJourneys } from "../../../providers/JourneyProvider";
import { routes } from "../../../routes";
import { Enum } from "../../../utillity/Enum";
import { ButtonLink } from "../../Groups/ButtonLink";

const printDisplayDate = (date: Date) => {
	const dateString = date.toLocaleDateString("en-us", { month: "short", day: "2-digit" });
	const timeString = date.toLocaleTimeString("en-us", { hour: "numeric", minute: "2-digit" });
	return `${dateString}, ${timeString}`;
};

export function MessageBubble(props: { message: TextMessageHistoryItem }) {
	const history = useHistory();
	const { journeys } = useJourneys();
	const { message } = props;

	const handleJourneyLink = (journeyId: string) => {
		const journey = journeys.find((j) => j.id === journeyId);
		history.push(routes.app.resolve.journeyDetailPage(journey!.category, journeyId));
	};

	const handleGroupLink = (groupId: string) => {
		history.push(routes.app.resolve.groupDetailPage(groupId));
	};

	return message.direction === TextMessageDirection.Send ? (
		<SystemMessageBubble message={message} handleJourneyLink={handleJourneyLink} handleGroupLink={handleGroupLink} />
	) : (
		<AttendeeMessageBubble message={message} handleJourneyLink={handleJourneyLink} handleGroupLink={handleGroupLink} />
	);
}

interface MessageBubbleProps {
	message: TextMessageHistoryItem;
	handleJourneyLink: (joruneyId: string) => void;
	handleGroupLink: (groupId: string) => void;
}

export function AttendeeMessageBubble(props: MessageBubbleProps) {
	const { message } = props;
	return (
		<>
			{message.message.length > 0 && <AttendeeTextMessageBubble {...props} messageText={message.message} />}
			{message.media.map((media, i) => (
				<AttendeeMediaMessageBubble key={i} {...props} media={media} />
			))}
		</>
	);
}

export function SystemMessageBubble(props: MessageBubbleProps) {
	const { message } = props;
	return (
		<>
			{message.message.length > 0 && <SystemTextMessageBubble {...props} messageText={message.message} />}
			{message.media.map((media, i) => (
				<SystemMediaMessageBubble key={i} {...props} media={media} />
			))}
		</>
	);
}

interface SystemTextMessageBubbleProps extends MessageBubbleProps {
	messageText: string;
}

export function SystemTextMessageBubble(props: SystemTextMessageBubbleProps) {
	const classes = useStyles();
	const { message, messageText } = props;
	const [expand, setExpand] = useState(false);

	return (
		<Grid container item style={{ marginTop: 5, marginBottom: 5 }} justify="flex-end">
			<Grid container item xs={8} justify="flex-end">
				<Grid item style={{ cursor: "pointer" }} onClick={() => setExpand(!expand)}>
					<Grid container item justify="flex-end" direction="column" alignItems="flex-end">
						<div className={classes.messageFromApp} style={{ backgroundColor: expand ? "#124b70" : undefined }}>
							{messageText}
						</div>
					</Grid>
					<Typography align="right" className={classes.captionText}>
						{message.status === TextMessageStatus.Unknown ? "Pending" : Enum.print(TextMessageStatus, message.status)}
					</Typography>
					<Collapse in={expand}>
						{message.type === TextMessageType.Keyword && (
							<Typography align="right" className={classes.captionText}>
								<ButtonLink onClick={() => props.handleJourneyLink(message.journeyId!)}>
									{message.journeyName}
								</ButtonLink>
							</Typography>
						)}
						{message.type === TextMessageType.GroupAnnouncement && (
							<Typography align="right" className={classes.captionText}>
								<ButtonLink onClick={() => props.handleGroupLink(message.groupId!)}>
									{message.groupName}
								</ButtonLink>
							</Typography>
						)}
						<Typography align="right" className={classes.captionText}>
							{printDisplayDate(message.dateCreated)}
						</Typography>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
}

interface SystemMediaMessageBubbleProps extends MessageBubbleProps {
	media: TextMessageMedia;
}

export function SystemMediaMessageBubble(props: SystemMediaMessageBubbleProps) {
	const classes = useStyles();
	const { message, media } = props;
	const [fullScreen, setFullscreen] = useState(false);

	if(media.category === TextMessageMediaCategory.Unknown || media.category === TextMessageMediaCategory.PlainText)
		return null;

	return (
		<Grid container style={{ marginTop: 5, marginBottom: 5 }} justify="flex-end">
			<Grid item xs={8} >
				<Grid container justify="flex-end" direction="column">
					{media.category === TextMessageMediaCategory.Image && (
						<img
							style={{ marginLeft: "25%"}}
							src={media.url}
							alt="MMS"
							className={classes.mediaClass}
							onClick={() => setFullscreen(true)}
						/>
					)}

					{media.category === TextMessageMediaCategory.Video && (
						<video style={{ display: "block", width: "75%", borderRadius: 10, marginLeft: "25%" }} controls>
							<source src={media.url} />
							<div className={classes.messageFromApp}>
							{message.sentByUserName} has sent a video that is not supported
							</div>
						</video>

					)}
					<Typography align="right" className={classes.captionText}>
						{printDisplayDate(message.dateCreated)}
					</Typography>
				</Grid>
			</Grid>
			<ImageDialog imageSource={media.url} open={fullScreen} close={() => setFullscreen(false)}  />
		</Grid>
	);
}

interface GroupAnnouncementMediaBubbleProps{
	scheduledDate: Date | undefined;
	media: TextMessageMedia;
}

export function GroupAnnouncementMediaBubble(props: GroupAnnouncementMediaBubbleProps) {
	const classes = useStyles();
	const { media, scheduledDate } = props;
	const [fullScreen, setFullscreen] = useState(false);

	if(media.category === TextMessageMediaCategory.Unknown || media.category === TextMessageMediaCategory.PlainText)
		return null;

	return (
		<Grid container style={{ marginTop: 5, marginBottom: 5 }} justify="flex-end">
			<Grid item xs={8} >
				<Grid container justify="flex-end" direction="column">
					{media.category === TextMessageMediaCategory.Image && (
						<img
							style={{ marginLeft: "25%"}}
							src={media.url}
							alt="MMS"
							className={classes.mediaClass}
							onClick={() => setFullscreen(true)}
						/>
					)}

					{media.category === TextMessageMediaCategory.Video && (
						<video style={{ display: "block", width: "75%", borderRadius: 10, marginLeft: "25%" }} controls>
							<source src={media.url} />
							<div className={classes.messageFromApp}>
							video format is not supported
							</div>
						</video>

					)}
					<Typography align="right" className={classes.captionText}>
						{scheduledDate && printDisplayDate(scheduledDate)}
					</Typography>
				</Grid>
			</Grid>
			<ImageDialog imageSource={media.url} open={fullScreen} close={() => setFullscreen(false)}  />
		</Grid>
	);
}

interface AttendeeTextMessageBubbleProps extends MessageBubbleProps {
	messageText: string;
}

function AttendeeTextMessageBubble(props: AttendeeTextMessageBubbleProps) {
	const classes = useStyles();
	const { message, messageText } = props;
	const [expand, setExpand] = useState(false);

	return (
		<Grid item style={{ marginTop: 5, marginBottom: 5 }}>
			<Grid container item xs={8}>
				<Grid item style={{ cursor: "pointer", width: "fit-content" }} onClick={() => setExpand(!expand)}>
					<div className={classes.messageFromAttendee} style={{ backgroundColor: expand ? "#c9c9c9" : undefined }}>
						{messageText}
					</div>
					<Typography align="left" className={classes.captionText}>
						{printMessageType(message)}
						{message.type === TextMessageType.Keyword && message.journeyName
							?
							<>
								{" - "}
								<ButtonLink onClick={() => props.handleJourneyLink(message.journeyId!)}>
									{message.journeyName}
								</ButtonLink>
							</>
							:
							<>
								{" - "}
								<ButtonLink onClick={() => props.handleGroupLink(message.groupId!)}>
									{message.groupName}
								</ButtonLink>
							</>
						}
					</Typography>
					<Collapse in={expand}>
						<Typography align="left" className={classes.captionText}>
							{printDisplayDate(message.dateCreated)}
						</Typography>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
}

interface AttendeeMediaMessageBubbleProps extends MessageBubbleProps {
	media: TextMessageMedia;
}

export function AttendeeMediaMessageBubble(props: AttendeeMediaMessageBubbleProps) {
	const classes = useStyles();
	const { message, media } = props;
	const [fullScreen, setFullscreen] = useState(false);

	if(media.category === TextMessageMediaCategory.Unknown || media.category === TextMessageMediaCategory.PlainText)
		return null;

	return (
		<Grid item style={{ marginTop: 5, marginBottom: 5 }}>
			<Grid container item xs={8}>
				<Grid item style={{ width: "fit-content" }} container direction="column">
					{media.category === TextMessageMediaCategory.Image && (
						<img
							style={{ display: "block", width: "75%", borderRadius: 10 }}
							src={media.url}
							alt="MMS"
							onClick={() => setFullscreen(true)}
						/>
					)}

					{media.category === TextMessageMediaCategory.Video && (
						<video style={{ display: "block", width: "75%", borderRadius: 10 }} controls>
							<source src={media.url} />
							<div className={classes.messageFromAttendee}>
								Video format not  that is not supported
							</div>
						</video>

					)}
					<Typography align="left" className={classes.captionText}>
						{printDisplayDate(message.dateCreated)}
					</Typography>
				</Grid>
				<ImageDialog imageSource={media.url} open={fullScreen} close={() => setFullscreen(false)}  />
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	announcementBubble: {
		display: "inline-block",
		maxWidth: "50%",
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		padding: 8,
		borderRadius: 10,
		borderBottomRightRadius: 0,
		whiteSpace: "pre-line",
		overflowWrap: "break-word",
	},
	messageFromApp: {
		display: "inline-block",
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		padding: 8,
		borderRadius: 10,
		borderBottomRightRadius: 0,
		whiteSpace: "pre-line",
		overflowWrap: "break-word",
	},
	messageFromAttendee: {
		display: "inline-block",
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.lightGrayBlue.main,
		padding: 8,
		borderRadius: 10,
		borderBottomLeftRadius: 0,
		whiteSpace: "pre-line",
		overflowWrap: "break-word",
	},
	captionText: {
		display: "block",
		fontSize: "0.75em",
		lineHeight: 1.55,
		marginTop: 5,
		color: "#333",
		fontWeight: "normal",
	},
	mediaClass: {
		display: "flex", 
		maxWidth: "75%", 
		width: "75%",
		borderRadius: 10,
	}
}));
