import Attendee, { AdminInviteStatus } from "../entities/Attendee";
import { Journey } from "../entities/Journey";
import SmsMessage from "../entities/SmsMessage";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export type ServerAttendee = Modify<
	Attendee,
	{
		lastActivityDate: string;
		dateCreated: string;
	}
>;

type ServerSmsMessage = Modify<
	SmsMessage,
	{
		timestampUtc: string;
	}
>;

export type SubscribedJourney = Journey & { subscriberId: string };

export interface InviteNewContactToChurchRequest {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string | undefined;
}

interface BulkImportPersonRequest {
	firstName: string;
	lastName: string;
	phone: string;
	email: string | undefined;
	exists: boolean;
}

interface BulkImportPeopleRequest {
	people: BulkImportPersonRequest[]
	groupIds: string[]
}


export const convertAttendee = (serverModel: ServerAttendee): Attendee => {
	return {
		...serverModel,
		lastActivityDate: new Date(serverModel.lastActivityDate),
		dateCreated: new Date(serverModel.dateCreated),
	};
};

const convertSmsMessage = (serverModel: ServerSmsMessage): SmsMessage => {
	return {
		...serverModel,
		timestampUtc: new Date(serverModel.timestampUtc),
	};
};

export const AttendeeService = {
	delete(id: string) {
		return WebClient.Delete(`${apiRoot}/attendees/${id}`);
	},
	getAttendeesForChurch() {
		return WebClient.Convert.Get<ServerAttendee[], Attendee[]>(`${apiRoot}/attendees`, (serverAttendees) =>
			serverAttendees.map(convertAttendee)
		);
	},
	getJourneysForAttendee(id: string) {
		return WebClient.Get<SubscribedJourney[]>(`${apiRoot}/attendees/${id}/journeys`);
	},
	getMessageHistoryForAttendee(id: string) {
		return WebClient.Convert.Get<ServerSmsMessage[], SmsMessage[]>(
			`${apiRoot}/attendees/${id}/message-history`,
			(serverSmsMessages) => serverSmsMessages.map(convertSmsMessage)
		);
	},
	getAdminInviteStatus: (userId: string) => WebClient.Get<AdminInviteStatus>(`${apiRoot}/attendees/admin-status/${userId}`),
	unsubscribeFromJourney(subscriberId: string) {
		return WebClient.Delete(`${apiRoot}/attendees/unsubscribe/${subscriberId}`);
	},
	unsubscribeFromAllJourneys(attendeeId: string) {
		return WebClient.Delete(`${apiRoot}/attendees/${attendeeId}/unsubscribe`);
	},
	updateAttendee(id: string, churchId: string, name: string, emailAddress: string | null) {
		return WebClient.Convert.Put.Validated<ServerAttendee, Attendee>(
			`${apiRoot}/attendees`,
			{ id, churchId, name, emailAddress },
			(serverAttendee) => convertAttendee(serverAttendee)
		);
	},
	makeAdmin(id: string) {
		return WebClient.Convert.Post.Validated(`${apiRoot}/attendees/promote-to-admin`, { id }, convertAttendee);
	},
	resendInvite: (userId: string) => WebClient.Post.Unvalidated<string>(`${apiRoot}/attendees/resend-invite`, { adminUserId: userId}),
	inviteNewContactToChurch(request: InviteNewContactToChurchRequest) {
		return WebClient.Convert.Post.Validated(`${apiRoot}/attendees`, request, convertAttendee);
	},
	bulkImport(request: BulkImportPeopleRequest) {
		return WebClient.Post.Unvalidated<null>(`${apiRoot}/attendees/bulk-import`, request);
	},
	optInPendingAttendees(churchId:string) {
		return WebClient.Put.Unvalidated(`${apiRoot}/attendees/opt-in-pending-attendees/${churchId}`, null);
	}
};

export default AttendeeService;
