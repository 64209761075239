import { JwtUser } from "../entities/JwtUser";
import { getRole } from "../entities/Role";
import { routes } from "../routes";

const token_key = "auth-token";

export interface JWTPayload {
	nameid: string;
	given_name: string;
	family_name: string;
	email: string;
	churches: {ChurchId: string; Role: string, AttendeeId: string }[];
	activeChurchId: string;
	activeChurchName: string;
	role: string;
	mobilePhone?: string;
	responsePhoneNumber?: string;
	tzdbTimeZone: string;
	exp: number;
	receiveInboxNotifications: "False" | "True";
}

const deserializeAssociatedChurch = (associatedChurch: {ChurchId: string; Role: string, AttendeeId: string }) =>{
	return {
		churchId: associatedChurch.ChurchId,
		role: getRole(parseInt(associatedChurch.Role, 10)),
		attendeeId: associatedChurch.AttendeeId,
	}
}

const deserializeToken = (jwt: string): JwtUser => {
	const payloadBase64 = jwt.split(".")[1];
	const payload = atob(payloadBase64);
	const jwtPayload = JSON.parse(payload) as JWTPayload;
	// Second pass needed for nested object
	jwtPayload.churches = JSON.parse(jwtPayload.churches as any);
	return {
		userId: jwtPayload.nameid,
		firstName: jwtPayload.given_name,
		lastName: jwtPayload.family_name,
		email: jwtPayload.email,
		churches: jwtPayload.churches.map(deserializeAssociatedChurch),
		activeChurchId: jwtPayload.activeChurchId,
		activeChurchName: jwtPayload.activeChurchName,
		role: getRole(parseInt(jwtPayload.role, 10)),
		phoneNumber: jwtPayload.mobilePhone,
		responsePhoneNumber: jwtPayload.responsePhoneNumber,
		tzdbTimeZone: jwtPayload.tzdbTimeZone,
		expiration: new Date(jwtPayload.exp * 1000),
		recieveInboxNotifications: jwtPayload.receiveInboxNotifications === "True"
	};
};

const hasValidToken = (jwt: string) => {
	try {
		deserializeToken(jwt);
		return true;
	} catch {
		return false;
	}
}

export const Auth = {
	setToken(token: string) {
		window.localStorage.setItem(token_key, token);
		return deserializeToken(token);
	},
	clearToken() {
		window.localStorage.removeItem(token_key);
	},
	signedIn() {
		return window.localStorage.getItem(token_key) != null;
	},
	getSavedToken() {
		return window.localStorage.getItem(token_key);
	},
	getJwtUser(): JwtUser | undefined {
		const token = this.getSavedToken();
		if (!token) {
			return;
		}

		if(!hasValidToken(token)){
			Auth.clearToken();
			return;
		}

		return deserializeToken(token);
	},
	redirectToSignInPage() {
		Auth.clearToken();
		window.location.assign(
			`${routes.public.signInPage}?redirect=${encodeURI(window.location.pathname + window.location.search)}`
		);
	},
};

export default Auth;
