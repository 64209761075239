import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../../components/LoadingPage";
import { resolveEnvironment, RuntimeEnvironment } from "../../Environment";

const stripePromise = loadStripe(
	resolveEnvironment() === RuntimeEnvironment.Production
		? "pk_live_51HcdYkApTuIEGikUMSiunti5TP6OdAsKBTJinfecWYInyE4CkrX5UFdE8SYFxzzUqAj1Z6c32szCfQaoweJBSkEP00ruQLo90S"
		: "pk_test_51HcdYkApTuIEGikUQsRrAKAuG2xzDT4iSMrRSsx2VC1EmppcG0f3o78DcHbwLlkbsEILb7mcF90nL6kNyFwepDO900p1ucRHSj"
);

type PlanCheckout = {
};

export function CheckoutPlan(props: PlanCheckout) {
	const params = useParams<{ sessionId?: string }>();
	useEffect(() => {
		async function getSession(sessionId: string) {
				const stripe = await stripePromise;
				await stripe!.redirectToCheckout({
					sessionId,
				});
			}

		if(params.sessionId)
			getSession(params.sessionId);
	}, [params.sessionId]);

	return <LoadingPage />;
}

export default CheckoutPlan;
