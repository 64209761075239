import { FormControl, FormControlLabel, makeStyles, Radio, Theme } from "@material-ui/core";
import React from "react";
import { SingleAnswer } from "../../../../entities/Forms/Answer";

const SingleChoiceIndividualResultsStyles = makeStyles((theme: Theme) => ({
	radio: {
		cursor: "default",
		"&$checked": {
			color: theme.palette.secondaryResponse.main,
			"&:hover": {
				backgroundColor: "#FFF",
			},
		},
		"&:hover": {
			backgroundColor: "#FFF",
		},
	},
	checked: {},
}));

interface Props {
	answer: SingleAnswer;
}

export function SingleChoiceIndividualResults(props: Props) {
	const classes = SingleChoiceIndividualResultsStyles();
	const { answer } = props;

	return (
		<FormControl component="fieldset">
			<FormControlLabel
				value={answer.id}
				control={
					<Radio
						focusRipple={false}
						disableRipple
						classes={{ root: classes.radio, checked: classes.checked }}
						checked
					/>
				}
				classes={{ root: classes.radio }}
				label={answer.text}
			/>
		</FormControl>
	);
}
