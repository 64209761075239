import { JourneyCategory } from "./entities/Journey";

export const routes = {
	public: {
		registerPage: "/register",
		checkOutPage: "/checkout/:sessionId",
		checkoutComplete: "/checkout-complete",
		signInPage: "/sign-in",
		signOutPage: "/sign-out",
		forgotPassword: "/forgot-password",
		resetPassword: "/reset-password",
		setPassword: "/set-password",
		auth: "/auth",
		formResponderPage: "/form/:churchId/:formId/:attendeeId",
		messagePreferences: "/message-preferences/:churchId/:attendeeId",
		resolve: {
			signInPage: (redirect?: string) => `${routes.public.signInPage}${redirect ? `?redirect=${encodeURI(redirect)}` : ""}`,
			signOutPage: () => routes.public.signOutPage,
			registerPage: () => routes.public.registerPage,
			checkOutPage: (sessionId:string) => `/checkout/${sessionId}`,
			checkoutComplete: () => routes.public.checkoutComplete,
			forgotPassword: () => routes.public.forgotPassword,
			resetPassword: () => routes.public.resetPassword,
			setPassword: () => routes.public.setPassword,
			auth: () => routes.public.auth,
			formResponderPage: () => routes.public.formResponderPage,
			messagePreferences: (churchId: string, attendeeId: string) => `/message-preferences/${churchId}/${attendeeId}`
		},
	},
	app: {
		dashBoardPage: "/app/dashboard",
		notifications: "/app/notifications",
		impersonateChurch: "/app/admintools",
		AdminToolsDetailPage: "/app/admintools/:churchId",
		journeyListPage: "/app/journeys",
		journeyDetailPage: "/app/journey/:category/:journeyId",
		journeyDetailPageOverview: "/app/journey/:category/:journeyId?tab=overview",
		journeyDetailPageResponses: "/app/journey/:category/:journeyId?tab=responses",
		journeyDetailPageMessages: "/app/journey/:category/:journeyId?tab=messages",
		journeyDetailPageReminders: "/app/journey/:category/:journeyId?tab=reminders",
		journeyCreatePage: "/app/create-journey",
		journeyNotFound: "/app/journey-not-found",
		acceptCollaboratorInvite: "/app/accept-collaborator-invite/:journeyCollaboratorId/:journeyCollaboratorInvitationSecret",
		connectCardsPage: "/app/connect-cards",
		prayerRequestsPage: "/app/prayer-requests",
		sermonPromptsPage: "/app/sermon-prompts",
		peopleListPage: "/app/people",
		invitePersonPage: "/app/invite-person",
		peopleDetailPage: "/app/people/:attendeeId",
		settingsPage: "/app/settings",
		settingsSelectPage: "/app/settings/select",
		churchInfoPage: "/app/settings/church-info",
		personalInfoPage: "/app/settings/personal-info",
		subscriptionPage: "/app/settings/subscription",
		paymentMethodPage: "/app/settings/payment-method",
		invoicesPage: "/app/settings/invoices",
		formResultsPage: "/app/form-results/:id/:journeyId/:categoryId/:journeyType",
		groupListPage: "/app/groups",
		createGroupPage: "/app/create-group",
		groupDetailPage: "/app/groups/:groupId",
		groupDetailPagePeople: "/app/groups/:groupId?tab=people",
		groupDetailPageAnnouncements: "/app/groups/:groupId?tab=announcements",
		uploadCsvPage: "/app/upload-csv",
		messagesPage: "/app/messages",
		inboxPage: "/app/messages/inbox",
		sendMessagePage: "/app/send-message",
		inboxDetailPage: "/app/messages/inbox/:attendeeId",
		groupMessagePage: "/app/messages/groups",
		groupMessageDetailPage: "/app/messages/groups/:groupId",
		setupPhoneNumber: "/app/setup-phone-number",

		resolve: {
			dashBoardPage: () => routes.app.dashBoardPage,
			impersonateChurch: () => routes.app.impersonateChurch,
			AdminToolsDetailPage: (churchId: string) => `/app/admintools/${churchId}`,
			journeyListPage: () => routes.app.journeyListPage,
			journeyDetailPage: (category: JourneyCategory, journeyId: string) => `/app/journey/${category}/${journeyId}`,
			journeyDetailPageOverview: (category: JourneyCategory, journeyId: string) =>
				`/app/journey/${category}/${journeyId}?tab=overview`,
			journeyDetailPageResponses: (category: JourneyCategory, journeyId: string) =>
				`/app/journey/${category}/${journeyId}?tab=responses`,
			journeyDetailPageMessages: (category: JourneyCategory, journeyId: string) =>
				`/app/journey/${category}/${journeyId}?tab=messages`,
			journeyDetailPageReminders: (category: JourneyCategory, journeyId: string) =>
				`/app/journey/${category}/${journeyId}?tab=reminders`,
			journeyCreatePage: () => routes.app.journeyCreatePage,
			journeyNotFound: (journeyId?: string) => `${routes.app.journeyListPage}${journeyId ? `?id=${journeyId}` : ""}`,
			acceptCollaboratorInvite: (journeyCollaboratorId: string, journeyCollaboratorInvitationSecret: string) => `/app/accept-collaborator-invite/${journeyCollaboratorId}/${journeyCollaboratorInvitationSecret}`,
			connectCardsPage: () => routes.app.connectCardsPage,
			prayerRequestsPage: () => routes.app.prayerRequestsPage,
			sermonPromptsPage: () => routes.app.sermonPromptsPage,
			peopleListPage: () => routes.app.peopleListPage,
			invitePersonPage: () => routes.app.invitePersonPage,
			peopleDetailPage: (attendeeId:string) => `${routes.app.peopleListPage}/${attendeeId}`,
			settingsPage: () => routes.app.settingsPage,
			settingsSelectPage: () => routes.app.settingsSelectPage,
			churchInfoPage: () => routes.app.churchInfoPage,
			personalInfoPage: () => routes.app.personalInfoPage,
			subscriptionPage: () => routes.app.subscriptionPage,
			paymentMethodPage: () => routes.app.paymentMethodPage,
			invoicesPage: () => routes.app.invoicesPage,
			formResultsPage: () => routes.app.formResultsPage,
			groupListPage: () => routes.app.groupListPage,
			createGroupPage: () => routes.app.createGroupPage,
			groupDetailPage: (groupId: string) => `${routes.app.groupListPage}/${groupId}`,
			groupDetailPagePeople: (groupId: string) => `${routes.app.groupListPage}/${groupId}?tab=people`,
			groupDetailPageAnnouncements: (groupId: string) => `${routes.app.groupListPage}/${groupId}?tab=announcements`,
			uploadCsvPage: () => routes.app.uploadCsvPage,
			messagesPage: () => routes.app.messagesPage,
			inboxPage: () => routes.app.inboxPage,
			sendMessagePage: () => routes.app.sendMessagePage,
			inboxDetailPage: (attendeeId:string) => `${routes.app.inboxPage}/${attendeeId}`,
			groupMessagePage: () => routes.app.groupMessagePage,
			groupMessageDetailPage: (groupId:string) => `${routes.app.groupMessagePage}/${groupId}`,
			setupPhoneNumber: () => routes.app.setupPhoneNumber
		},
	},
};

