import { Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import RightChevronIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Group } from "../../../entities/Groups/Group";
import { Journey } from "../../../entities/Journey";
import { useGroups } from "../../../providers/GroupProvider";
import { useJourneys } from "../../../providers/JourneyProvider";
import { routes } from "../../../routes";
import { orderBy } from "../../../utillity/orderBy";

const usePeopleStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: 20,
		width: "100%",
	},
	grow: {
		flex: 1,
	},
	cardGlowEffect: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		width: "100%",
		height: 50,
		display: "flex",
		padding: 12,
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.7,
		},
	},
	buttonRoot: {
		height: 36,
		width: 36,
		marginTop: -5,
	},
	contactLable: {
		height: 30,
		width: 30,
	},
}));

export function RecentJourneys() {
	const history = useHistory();
	const { journeys } = useJourneys();
	const { groups } = useGroups();
	const classes = usePeopleStyles();
	const [lastThreeJourneys, setLastThreeJourneys] = useState<Journey[]>([]);
	const [lastThreeGroups, setLastThreeGroups] = useState<Group[]>([]);

	useEffect(() => {
		function getLastThree() {
			setLastThreeJourneys(journeys?.sort(orderBy.date((j) => j.dateCreated, "Descending")).slice(0, 3) ?? []);
			setLastThreeGroups(groups?.sort(orderBy.date((g) => g.dateCreated, "Descending")).slice(0, 3) ?? []);
		}

		getLastThree();
	}, [journeys, groups]);

	return (
		<Paper elevation={4} className={classes.paper}>
			<Grid container spacing={3} direction="column">
				<Grid item xs={12}>
					<Grid container justify="space-between" style={{ marginBottom: 20 }}>
						<Grid item>
							<Typography variant="h2">Groups</Typography>
						</Grid>
						<Grid item>
							<Link to={routes.app.groupListPage}>
								All Groups
								<RightChevronIcon fontSize="small" style={{ verticalAlign: "middle" }} />
							</Link>
						</Grid>
					</Grid>
					<Typography variant="h4" style={{ marginBottom: 8 }}>
						Most Recent
					</Typography>
					<Grid container alignItems="center" justify="center">
						{lastThreeGroups.length === 0 && 
							<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
							<div
								onClick={() => history.push(routes.app.createGroupPage)}
								className={classes.cardGlowEffect}
							>
								<div>
									<Typography style={{ marginTop: 3 }} variant="h4">
										Create a group
									</Typography>
								</div>
								<div className={classes.grow} />
								<IconButton
									classes={{
										label: classes.contactLable,
										root: classes.buttonRoot,
									}}
								>
									<RightChevronIcon />
								</IconButton>
							</div>
						</Grid>
						}
						{lastThreeGroups.map((group: Group, index: number) => {
							return (
								<Grid key={index} item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
									<div
										onClick={() => history.push(routes.app.resolve.groupDetailPage(group.id))}
										className={classes.cardGlowEffect}
									>
										<div>
											<Typography style={{ marginTop: 3 }} variant="h4">
												{group.name}
											</Typography>
										</div>
										<div className={classes.grow} />
										<IconButton
											classes={{
												label: classes.contactLable,
												root: classes.buttonRoot,
											}}
										>
											<RightChevronIcon />
										</IconButton>
									</div>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="space-between" style={{ marginBottom: 20 }}>
						<Grid item>
							<Typography variant="h2">Journeys</Typography>
						</Grid>
						<Grid item>
							<Link to={routes.app.journeyListPage}>
								All Journeys
								<RightChevronIcon fontSize="small" style={{ verticalAlign: "middle" }} />
							</Link>
						</Grid>
					</Grid>
					<Typography variant="h4" style={{ marginBottom: 8 }}>
						Most Recent
					</Typography>
					<Grid container alignItems="center" justify="center">
						{lastThreeJourneys.map((journey: Journey, index: number) => {
							return (
								<Grid key={index} item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
									<div
										onClick={() => history.push(routes.app.resolve.journeyDetailPage(journey.category, journey.id))}
										className={classes.cardGlowEffect}
									>
										<div>
											<Typography style={{ marginTop: 3 }} variant="h4">
												{journey.name}
											</Typography>
										</div>
										<div className={classes.grow} />
										<IconButton
											classes={{
												label: classes.contactLable,
												root: classes.buttonRoot,
											}}
										>
											<RightChevronIcon />
										</IconButton>
									</div>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}
