import { CircularProgress, Grid, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { ResponseDialog } from "../../../components/ResponseDialog/ResponseDialog";
import { ResponseTooltip } from "../../../components/ResponseTooltip";
import { DateFormat } from "../../../entities/DateFormat";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";
import { Group } from "../../../entities/Groups/Group";
import { isMobile } from "../../../hooks/isMobile";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { KeywordService } from "../../../services/KeywordService";
import { ServerResult } from "../../../services/server/ServerResult";
import { AddGroupMemberDialog } from "../AddGroupMemberDialog/AddGroupMemberDialog";
import { useGroupRouter } from "../GroupPage";
import { AddToGroupKeywordDialog } from "./AddToGroupKeywordDialog";
import { DeleteGroupDialog } from "./DeleteGroupDialog";
import { DeleteKeywordDialog } from "./DeleteKeywordDialog";
import EditGroupDialog from "./EditGroupDialog";
import { SendGroupAnnouncementDialog } from "./SendGroupAnnouncementDialog";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 5, 
            paddingRight: 5
        }
    },
	paper: {
		width: 200,
        height: "100%",
		padding: 20,
		[theme.breakpoints.down("sm")]: {
			width: "100%",
            padding: 10,
		},
	},
	totalCount: {
		color: theme.palette.secondaryResponse.main,
		paddingRight: 10,
		alignSelf: "center",
	},
	icon: {
        marginLeft: 5,
		fontSize: 14,
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},	
	linkText: {
		textDecoration: "underline",
		cursor: "pointer",
		fontWeight: "normal",
	},
}));

interface GroupDetailsSectionProps {
    selectedGroup: Group;
}

export function GroupDetailsSection(props: GroupDetailsSectionProps) {
	const classes = useStyles();
	const theme = useTheme();
    const mobile = isMobile();
    const GroupRouterContext = useGroupRouter();
    const { selectedGroupKeyword: SelectedGroupKeyword } = GroupRouterContext;

    const setServerError = useServerErrorAlert();

    const [showAddToGroupDialog, setShowAddToGroupDialog] = useState(false)
    const [showSendAnnouncementDialog, setShowSendAnnouncementDialog] = useState(false)
    const [showDeleteGroupDialog, setShowDeleteGroupDialog] = useState(false)
    const [showKeywordDialog, setShowKeywordDialog] = useState(false);
    const [showEditGroupDialog, setShowEditGroupDialog] = useState(false)
    const [loadingKeyword, setLoadingKeyword] = useState(true)
    const [showDeleteKeywordDialog, setShowDeleteKeywordDialog] = useState(false)

    const {id, dateModified, dateCreated, messagesToGroup, groupMembers, lastModifiedByUserName, createdByUserName, name} = props.selectedGroup;
    const modifiedDate = dateModified < dateCreated ? dateCreated : dateModified;

    useEffect(() => {
        async function getGroupKeyword(){
            const response = await KeywordService.getGroupKeyword(id)
            setLoadingKeyword(false)

            if(ServerResult.isSuccess(response))
                GroupRouterContext.setGroupKeyword(response.data)
            if(ServerResult.isError(response))
                return setServerError(response)
        }
        getGroupKeyword()
    // eslint-disable-next-line
    }, [props.selectedGroup])

	return (
        <Grid container style={{marginBottom:  mobile ? 0 : 20, paddingRight: mobile ? 0 : 16}}>  
            <Grid container>
                <Grid container alignItems="baseline" className={classes.header} >
                    <Typography variant={mobile ? "h2" : "h1"}>
                        {name}
                    </Typography>
                    <Typography
                        align="right"
                        onClick={() => setShowEditGroupDialog(true)}
                        className={classes.linkText}
                        style={{marginLeft: 5}}
                        variant="body1"
                        >
                        Rename
                    </Typography>
                    <GridGrow/>
                    <Typography
                        onClick={() => setShowDeleteGroupDialog(true)}
                        className={classes.linkText}
                        style={{ color: theme.palette.error.main}}
                        variant="body1"
                        >
                        Delete Group
                    </Typography>
                </Grid>
                <hr className={classes.largeBlueDivider} />
            </Grid>
            <Grid container spacing={2} style={{margin: 5}}>
                <Grid item md="auto" xs={12}>
                    <Paper elevation={4} className={classes.paper}>
                        <div style={{ display: "flex" }}>
                            <ResponseTooltip
                                text="The amount of people who have joined your group."
                                placement={"right-start"}
                                childComp={
                                    <Typography variant="h3">
                                        People
                                        <InfoOutlined className={classes.icon} />
                                    </Typography>
                                }
                                />
                        </div>
                        <div>
                            <Typography variant="h1" className={classes.totalCount}>
                                {groupMembers.filter(m => m.groupMembershipStatus !== GroupMembershipStatus.Declined).length}
                            </Typography>
                            <Typography
                                onClick={() => setShowAddToGroupDialog(true)}
                                className={classes.linkText}
                                style={{ marginRight: 16 }}
                                variant="body1"
                                >
                                Add to Group
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item md="auto" xs={12}>
                    <Paper elevation={4} className={classes.paper}>
                        <div style={{ display: "flex" }}>
                            <ResponseTooltip
                                text="Number of announcements sent to this group"
                                placement={"right-start"}
                                childComp={
                                    <Typography variant="h3">
                                        Announcements
                                        <InfoOutlined className={classes.icon} />
                                    </Typography>
                                }
                                />
                        </div>
                        <div >
                            <Typography variant="h1" className={classes.totalCount}>
                                {messagesToGroup.length}
                            </Typography>
                            <Typography
                                onClick={() => setShowSendAnnouncementDialog(true)}
                                className={classes.linkText}
                                style={{ marginRight: 16 }}
                                variant="body1"
                                >
                                Send Announcement
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item md="auto" xs={12}>
                    <Paper elevation={4} className={classes.paper} style={{width: "100%", minWidth: 200}}>
                        <div style={{ display: "flex" }}>
                            <ResponseTooltip
                                text="Keyword people can text to join this group"
                                placement={"right-start"}
                                childComp={
                                    <Typography variant="h3">
                                        Keyword
                                        <InfoOutlined className={classes.icon} />
                                    </Typography>
                                }
                                />
                        </div>
                        <div >
                            { loadingKeyword
                                ? <CircularProgress color="secondary" size={40} style={{ marginTop: 8, marginBottom: 8}}/>
                                :
                                <>
                                    { SelectedGroupKeyword 
                                        ?
                                        <Typography variant="h1" className={classes.totalCount}>
                                        {SelectedGroupKeyword.displayKeyword}
                                        </Typography>
                                        :
                                        <Typography className={classes.totalCount} style={{paddingTop: 8, paddingBottom: 8}}>
                                        Add a keyword for people to join your group
                                        </Typography>
                                    }
                                    <Grid container spacing={1}>
                                        <GridGrow />
                                        <Grid item>
                                            <Typography
                                                onClick={() => setShowKeywordDialog(true)}
                                                className={classes.linkText}
                                                variant="body1"
                                                >
                                                {SelectedGroupKeyword ? "Edit" : "Add keyword"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                onClick={() => setShowDeleteKeywordDialog(true)}
                                                className={classes.linkText}
                                                color="error"
                                                variant="body1"
                                                >
                                                {SelectedGroupKeyword && "Delete"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </div>
                    </Paper>
                </Grid>
                <GridGrow />
                <Grid item xs={12} md="auto">
                    <Typography align="right" variant="body2">
                        Created by {createdByUserName} on {dateCreated.toLocaleString([], DateFormat)}
                    </Typography>
                    
                    <Typography align="right" variant="body2">
                        Edited by {lastModifiedByUserName} on {modifiedDate.toLocaleString([], DateFormat)}
                    </Typography>
                </Grid>
            </Grid>
            {showAddToGroupDialog && <AddGroupMemberDialog selectedGroup={props.selectedGroup} open={showAddToGroupDialog} onClose={() => setShowAddToGroupDialog(false)} />}
            {showEditGroupDialog && <EditGroupDialog selectedGroup={props.selectedGroup} isOpen={showEditGroupDialog} onClose={() => setShowEditGroupDialog(false)}/>}
            {showSendAnnouncementDialog && 
                <ResponseDialog open={showSendAnnouncementDialog} onClose={() => setShowSendAnnouncementDialog(false)} title="Send a Message">
                    <SendGroupAnnouncementDialog group={props.selectedGroup} onClose={() => setShowSendAnnouncementDialog(false)} />
                </ResponseDialog>
            }
            {showDeleteGroupDialog && <DeleteGroupDialog isOpen={showDeleteGroupDialog} onClose={() => setShowDeleteGroupDialog(false)} group={props.selectedGroup} />}
            {showKeywordDialog && 
            <ResponseDialog open={showKeywordDialog} onClose={() => setShowKeywordDialog(false)} title={SelectedGroupKeyword ? "Edit keyword" : "Add a keyword"}>
                <AddToGroupKeywordDialog onClose={() => setShowKeywordDialog(false)} group={props.selectedGroup}/>
            </ResponseDialog>
            }
            {showDeleteKeywordDialog && SelectedGroupKeyword &&
                <DeleteKeywordDialog 
                    isOpen={showDeleteKeywordDialog} 
                    onClose={() => setShowDeleteKeywordDialog(false)} 
                    keyword={SelectedGroupKeyword} />
            }
        </Grid>
	);
}