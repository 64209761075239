import { ChurchOptInStatus } from "../ChurchOptInStatus";
import { GroupMembershipStatus } from "../GroupMembershipStatus";

export interface GroupMember {
    attendeeId: string;
    churchOptInStatus: ChurchOptInStatus;
    groupMembershipStatus: GroupMembershipStatus;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    timeJoined: Date;
    timeLeft: Date | null;
}

export const printGroupMemberStatus = (member: GroupMember) => {
    if(member.churchOptInStatus === ChurchOptInStatus.Pending)
        return "Pending church invite";
    
    if(member.churchOptInStatus === ChurchOptInStatus.Declined)
        return "Opted out of church messages";

    if(member.groupMembershipStatus === GroupMembershipStatus.Declined)
        return "Unsubscribed";

    return "Subscribed"
}