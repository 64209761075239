import React from "react";

interface ButtonLinkProps {
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
}

export function ButtonLink(props: React.PropsWithChildren<ButtonLinkProps>) {
	return (
		<button
			style={{
				backgroundColor: "transparent",
				border: "none",
				cursor: "pointer",
				textDecoration: "underline",
				display: "inline",
				margin: 0,
				padding: 0,
				color: "inherit",
				fontSize: "inherit",
				fontWeight: 600
			}}
			disabled={props.disabled}
            onClick={props.onClick}
			type="button"
		>
			{props.children}
		</button>
	);
}
