import {
	Typography,
	CardContent,
	Button,
	Theme,
	Card,
	CardHeader,
	CardActions,
	makeStyles,
	useMediaQuery,
	useTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Hidden,
} from "@material-ui/core";
import React, { useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { Pulse } from "../../../components/Pulse";
import ResponseInputComponent from "../../../components/responseInputComponent";
import Attendee, { AdminInviteStatus, printAttendeeStatus } from "../../../entities/Attendee";
import { Role } from "../../../entities/Role";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useAttendees } from "../../../providers/AttendeeProvider";
import AttendeeService from "../../../services/AttendeeService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { usePeopleRouter } from "../PeoplePage";

interface AttendeeInfoCardProps {
	attendee: Attendee;
	status: AdminInviteStatus | undefined;
	disabled: boolean;
	showPulse: boolean;
}

export function AttendeeInfoCard(props: AttendeeInfoCardProps) {
	const { attendee, disabled, showPulse } = props;

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [editAttendeeMeta, setEditAttendeeMeta] = useState(false);

	const onFollowUpCall = async (attendee: Attendee) => {
		window.location.href = `tel:${attendee.phoneNumber}`;
	};

	return (
		<>
			<Card style={{ height: "100%" }}>
				<Grid container direction="column" style={{ height: "100%" }}>
					<Grid item>
						<CardHeader
							title={attendee.name}
							subheader={`Last SMS Activity: ${attendee.lastActivityDate.toLocaleDateString()}`}
							action={
								<Hidden mdUp>
									<Pulse on={showPulse}>
										<Button
											variant="outlined"
											size="small"
											disabled={disabled}
											onClick={() => setEditAttendeeMeta(true)}
										>
											Edit
										</Button>
									</Pulse>
								</Hidden>
							}
						/>
					</Grid>
					<Grid item>
						<CardContent>
							<Typography variant="body2">
								<strong>Status: </strong> {printAttendeeStatus(attendee)}
							</Typography>
							<Typography variant="body2">
								<strong>Role: </strong> {Role[attendee.role]}{" "}
								{props.status === AdminInviteStatus.Pending && (
									<strong style={{ paddingLeft: 4 }}>[Pending Invite]</strong>
								)}
							</Typography>
							<Typography variant="body2">
								<strong>Email Address: </strong> {attendee.email}
							</Typography>
							{mobileFormFactor ? (
								<div style={{ display: "flex" }} onClick={() => onFollowUpCall(attendee)}>
									<img
										alt="call"
										style={{ width: 26, height: 26, marginTop: 9, marginRight: 3 }}
										src={"https://i.cdn-sc.com/Response/icons/call-message.png"}
									/>
									<Typography style={{ paddingTop: 10, marginLeft: 10 }} variant="h3" component="p">
										{formatPhoneNumber(attendee.phoneNumber.replace("+1", ""))}
									</Typography>
								</div>
							) : (
								<Typography variant="body2">
									<strong>Phone Number: </strong> {formatPhoneNumber(attendee.phoneNumber)}
								</Typography>
							)}
						</CardContent>
					</Grid>
					<GridGrow />
					<Grid item>
						<CardActions>
							<Hidden smDown>
								<Pulse on={showPulse}>
									<Button color="primary" disabled={disabled} onClick={() => setEditAttendeeMeta(true)}>
										Edit
									</Button>
								</Pulse>
							</Hidden>
						</CardActions>
					</Grid>
				</Grid>
			</Card>
			<EditAttendeeDialog attendee={attendee} open={editAttendeeMeta} onClose={() => setEditAttendeeMeta(false)} />
		</>
	);
}

interface EditAttendeeDialogProps {
	attendee: Attendee;
	open: boolean;
	onClose: () => void;
}

function EditAttendeeDialog(props: EditAttendeeDialogProps) {
	const { attendee } = props;
	const classes = useStyles();

	const peopleRouter = usePeopleRouter();
	const attendeeContext = useAttendees();

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const [disabled, setDisabled] = useState(false);
	const setServerErrorAlert = useServerErrorAlert();

	const [nameChangeValue, setNameChangeValue] = useState(attendee.name);
	const [emailChangeValue, setEmailChangeValue] = useState(attendee.email);
	const [nameChangeError, setNameChangeError] = useState<string | undefined>();
	const [emailChangeError, setEmailChangeError] = useState<string | undefined>();

	const saveMeta = async () => {
		setDisabled(true);
		const result = await AttendeeService.updateAttendee(attendee.id, attendee.churchId, nameChangeValue, emailChangeValue);
		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			attendeeContext.update(result.data);
			peopleRouter.selectAttendee(result.data);
			props.onClose();
		} else if (ServerResult.isValidationError(result)) {
			setNameChangeError(FieldValidationErrorUtils.getFieldError(result.errors, "Name"));
			setEmailChangeError(FieldValidationErrorUtils.getFieldError(result.errors, "EmailAddress"));
		} else {
			setServerErrorAlert(result);
		}
	};

	const onClose = () => {
		props.onClose();
		setNameChangeError(undefined);
		setEmailChangeError(undefined);
		setNameChangeValue(attendee.name);
		setEmailChangeValue(attendee.email);
	};

	return (
		<Dialog open={props.open} fullScreen={mobileFormFactor} onClose={onClose}>
			<DialogTitle>Edit {attendee.name}</DialogTitle>
			<DialogContent>
				<Grid item xs={12}>
					<Typography className={classes.progressLabelText} variant="subtitle2">
						Name
					</Typography>
				</Grid>
				<div style={{ width: 300 }}>
					<ResponseInputComponent
						value={nameChangeValue}
						errorMessage={nameChangeError}
						onChange={(e) => setNameChangeValue(e)}
					/>
				</div>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Typography className={classes.progressLabelText} variant="subtitle2">
						E-mail Address
					</Typography>
				</Grid>
				<div style={{ width: 300 }}>
					<ResponseInputComponent
						value={emailChangeValue ?? ""}
						onChange={(e) => setEmailChangeValue(e)}
						errorMessage={emailChangeError}
					/>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: 15, marginTop: 5 }}>
				<Button variant="outlined" size="large" onClick={onClose} disabled={disabled}>
					Cancel
				</Button>
				<GridGrow />
				<Button variant="contained" color="primary" size="large" onClick={saveMeta} disabled={disabled}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	progressLabelText: {
		color: "rgb(129, 138, 145)",
		fontSize: 17,
	},
}));
