import React from "react";

export function ShortAnswerDetail() {
	return (
		<div
			style={{
				marginLeft: 4,
				marginTop: 12,
				padding: 4,
				width: "100%",
				backgroundColor: "#FFF",
				height: 50,
				border: "1px solid rgba(0, 0, 0, .1)",
			}}
		>
			<span style={{ fontStyle: "italic", opacity: 0.6, fontSize: 12 }}>Type short answer here.</span>
		</div>
	);
}
