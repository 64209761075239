import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../components/LoadingPage";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useAppData } from "../../providers/AppDataProvider";
import { DashboardData, DashboardService } from "../../services/DashboardService";
import { ServerResult } from "../../services/server/ServerResult";
import Dashboard from "./Dashboard";

export function DashBoardPage(){
    const appData = useAppData();
    const errorAlert = useServerErrorAlert();
    const { attendeesLoading, groupsLoading, journeysLoading } = appData;

    const [data, setData] = useState<DashboardData>()

    useEffect(() => {
        async function loadData(){
            const result = await DashboardService.get();
            if(ServerResult.isSuccess(result)){
                setData(result.data);
                (window as any).dismissBlock = DashboardService.dismissBlock;
            }else {
                errorAlert(result);
            }
        }
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (attendeesLoading || groupsLoading || journeysLoading || data == null) {
		return <LoadingPage />;
	}

    return <Dashboard data={data} />
}