import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { DialogStep } from "./MultistepDialog"

interface MultistepDialogContext {
	onClose: () => void;
	nextStep: () => void; 
	previousStep: () => void; 
	goToStep: (stepNumber: number) => void;
	stepNumber: number;
	steps: {name: string, number: number}[]
}

const MultistepDialogReactContext = createContext<MultistepDialogContext>({} as any);

export function useDialogSteps() {
	return useContext(MultistepDialogReactContext);
}

interface CurrentDialogStepProps {
    onClose: () => void
	steps: DialogStep[];
}

export function CurrentDialogStep(props: CurrentDialogStepProps){

	const [dialogSteps, setDialogSteps] = useState(props.steps);
	const [stepNumber, setStepNumber] = useState(1);
	const [stepComponent, setStepComponent] = useState<ReactNode>(null)

	useEffect(() => {
		const stepIndex = stepNumber - 1;
		if(stepIndex >= 0 || stepIndex < dialogSteps.length)
			setStepComponent(dialogSteps[stepIndex].step)
	}, [stepNumber, dialogSteps])

	useEffect(() => {
		setDialogSteps(props.steps)
	}, [props.steps])

	const nextStep = () => {
		const nextStepNumber = stepNumber + 1;
		if(nextStepNumber <= dialogSteps.length){
			setStepNumber(nextStepNumber);
		} else {
			props.onClose();
		}
	}

	const previousStep = () => {
		const previousStepNumber = stepNumber - 1;
		if(previousStepNumber >= 1){
			setStepNumber(previousStepNumber);
		} else {
			props.onClose();
		}
	}

	const goToStep = (goToStepNumber: number) => {
		if(goToStepNumber >= 1 && goToStepNumber <= dialogSteps.length)
			setStepNumber(goToStepNumber);
	}

    return (
		<MultistepDialogReactContext.Provider
			value={{
				stepNumber,
				steps: dialogSteps.map((ds, index) => ({name: ds.name, number: index + 1})),
				nextStep,
				previousStep,
				goToStep,
				onClose: props.onClose,
			}}
		>
			{stepComponent}
		</MultistepDialogReactContext.Provider>
	);
}

