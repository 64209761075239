import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Role } from "../entities/Role";
import { useAppUser } from "../hooks/useAppUser";
import ImpersonationMetricService, { ChurchMetrics } from "../services/ImpersonationMetricService";
import { ServerResult } from "../services/server/ServerResult";

interface AdminToolsContext {
	churches: ChurchMetrics[];
}

const AdminToolsReactContext = createContext<AdminToolsContext>({} as any);

export function useAdminToolsContext() {
	return useContext(AdminToolsReactContext);
}

export function AdminToolsProvider(props: PropsWithChildren<{}>){
	const [user] = useAppUser()

	const [churches, setChurches] = useState<ChurchMetrics[]>([]);

    useEffect(() => {
		async function loadChurches() {
			const result = await ImpersonationMetricService.GetAll();
			if (ServerResult.isSuccess(result))
				setChurches(result.data);
		}

		if(user.role === Role.UberAdmin)
			loadChurches();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if(user.role !== Role.UberAdmin)
		return <>{props.children}</>

	return (
		<AdminToolsReactContext.Provider value={{ churches }}>
			{props.children}
		</AdminToolsReactContext.Provider>
	);
}