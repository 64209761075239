import {
	FormControl,
	Grid,
	makeStyles,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Journey, JourneyType } from "../../../entities/Journey";
import { isMobile } from "../../../hooks/isMobile";
import { useJourneys } from "../../../providers/JourneyProvider";
import { Enum } from "../../../utillity/Enum";
import { orderBy, SortDirection } from "../../../utillity/orderBy";
import { useJourneyRouter } from "../JourneyPage";

const useJourneyListingStyle = makeStyles((theme: Theme) => ({
	contentPaper: {
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: 20,
		[theme.breakpoints.down("md")]: {
            boxShadow: "none"
        }
	},
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	spanDivider: {
		backgroundColor: "#E4EBF9",
		opacity: 0.6,
	},
	internalTopPadding: {
		paddingTop: 42,
	},
	input: {
		borderRadius: 8,
		height: 40,
		paddingLeft: 16,
		borderColor: "#D3DCE6",
		color: "#818A91",
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	linkText: {
		textDecoration: "underline",
		cursor: "pointer",
		fontWeight: "normal",
	},
	disableToggleRow: {
		display: "flex",
		width: "100%",
		marginTop: 8,
	},
	filterSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	sortSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
}));

interface JourneyListingTableProps {
}

const JourneyListingTable = (props: JourneyListingTableProps) => {
	const [sortField, setSortField] = useState<"keyword" | "people" | "type" | "creator">("keyword");
	const journeyRouter = useJourneyRouter();
	const journeyContext = useJourneys();
	const { journeys } = journeyContext
	const classes = useJourneyListingStyle();

	const [searchText, startSearchItems] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");
	const [filteredItems, setFilteredItems] = useState(journeys);
	const [filterByItem, setFilterByItem] = useState(JourneyType.Unknown);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const theme = useTheme();
	const mobileFormFactor = isMobile();

	useEffect(() => {
		let filteredItems = journeys;

		if (filterByItem !== JourneyType.Unknown) {
			filteredItems = filteredItems.filter((a) => a.type === filterByItem);
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredItems = filteredItems.filter((listItem) =>
				(listItem.name + listItem.keywordTrigger).toLowerCase().includes(queryText)
			);
		}

		switch (sortField) {
			case "keyword":
				filteredItems.sort(orderBy.string((i) => i.keywordTrigger, sortDirection));
				break;
			case "type":
				filteredItems.sort(orderBy.number((i) => i.type, sortDirection));
				break;
			default:
				break;
		}

		setFilteredItems(filteredItems);
		setPage(0);
	}, [searchText, sortDirection, journeys, sortField, filterByItem]);

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
			{!mobileFormFactor && (
				<>
				<Grid container>
					<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							{filteredItems.length} Journey{filteredItems.length !== 1 && "s"}
						</Typography>
					</Grid>
					<div className={classes.grow}/>
					<Grid item>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchItems(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
					/>
					</Grid>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid container>
					<Grid item xs={4} style={{ textAlign: "left", alignSelf: "center"}}>
							<FormControl>
							<Select
								disableUnderline
								labelId="filter-journey-select-label"
								id="filter-journey-select"
								value={filterByItem}
								className={classes.filterSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setFilterByItem(parseInt(event.target.value as string))
								}
							>
								{Enum.list(JourneyType).map((journey, index) => {
									return (
										<MenuItem key={index} value={journey.id}>
											{journey.id === 0 ? "All" : journey.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<div className={classes.grow}/>
						<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={filteredItems.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
						onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
							setRowsPerPage(parseInt(event.target.value, 10));
							setPage(0);
						}}
						labelRowsPerPage="Show"
						/>
				</Grid>
						<Grid item xs={12} style={{ marginTop: 8 }}>
							<TableContainer>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											<TableCell
												align="left"
												sortDirection={
													sortField === "keyword"
														? sortDirection === "Ascending"
															? "asc"
															: "desc"
														: false
												}
											>
												<TableSortLabel
													active={sortField === "keyword"}
													direction={sortDirection === "Ascending" ? "asc" : "desc"}
													onClick={() => {
														setSortField("keyword");
														setSortDirection(
															sortDirection === "Ascending" ? "Descending" : "Ascending"
														);
													}}
												>
													Keyword
												</TableSortLabel>
											</TableCell>
											<TableCell
												align="left"
												sortDirection={
													sortField === "type"
														? sortDirection === "Ascending"
															? "asc"
															: "desc"
														: false
												}
											>
												<TableSortLabel
													active={sortField === "type"}
													direction={sortDirection === "Ascending" ? "asc" : "desc"}
													onClick={() => {
														setSortField("type");
														setSortDirection(
															sortDirection === "Ascending" ? "Descending" : "Ascending"
														);
													}}
												>
													Journey Type
												</TableSortLabel>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{filteredItems.length === 0 && (
											<TableRow>
												<TableCell>
													<Typography style={{ paddingTop: 24 }} variant="h4">
														No journeys
													</Typography>
												</TableCell>
												<TableCell />
												<TableCell />
											</TableRow>
										)}
										{(rowsPerPage > 0
											? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											: filteredItems
										).map((item: Journey, index: number) => {
											return (
												<TableRow
													key={index}
													hover
													onClick={() => journeyRouter.selectJourney(item)}
													onDoubleClick={() => journeyRouter.selectJourney(item)}
												>
													<TableCell>{item.keywordTrigger}</TableCell>
													<TableCell>{JourneyType[item.type].replace(/_/g, " ")}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</>
				)}
				{mobileFormFactor && (
					<>
						<Grid container>
							<Grid item xs={6} style={{ textAlign: "left"}}>
								<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
									{filteredItems.length} Journey{filteredItems.length !== 1 && "s"}
								</Typography>
							</Grid>
							<hr className={classes.largeBlueDivider} />
						</Grid>
						<Grid item xs={12} style={{ marginTop: 12 }}>
							<TextField
								id="outlined-bare"
								defaultValue={searchText}
								margin="normal"
								variant="outlined"
								className={classes.searchBar}
								onChange={(e) => startSearchItems(e.currentTarget.value)}
								size="small"
								InputProps={{
									startAdornment: <Search style={{ marginLeft: -6 }} />,
								}}
								style={{ width: "100%", marginTop: 0 }}
							/>
						</Grid>
						<Grid item xs={12} style={{ display: "flex", marginBottom: 6 }}>
							<FormControl>
								<Select
									disableUnderline
									labelId="filter-journey-select-label"
									id="filter-journey-select"
									value={filterByItem}
									className={classes.filterSelector}
									onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
										setFilterByItem(parseInt(event.target.value as string))
									}
								>
									{Enum.list(JourneyType).map((journey, index) => {
										return (
											<MenuItem key={index} value={journey.id}>
												{journey.id === 0 ? "All" : journey.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							<div className={classes.grow} />
						</Grid>
						{filteredItems.length === 0 && (
							<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
								<div
									className={classes.cardGlowEffect}
									style={{
										backgroundColor: "#FFF",
										borderRadius: 8,
										width: "100%",
										height: 70,
										display: "flex",
										padding: 12,
									}}
								>
									<Typography style={{ fontWeight: 400 }} variant="h4">
										Nothing is here...
									</Typography>
								</div>
							</Grid>
						)}
						{filteredItems.map((journey: Journey, index: number) => {
							return (
								<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }} key={index}>
									<div
										onClick={() => journeyRouter.selectJourney(journey)}
										className={classes.cardGlowEffect}
										style={{
											backgroundColor: "#FFF",
											borderRadius: 8,
											width: "100%",
											height: 70,
											display: "flex",
											padding: 12,
										}}
									>
										<div>
											<Typography variant="h4">{journey.name}</Typography>
											<Typography
												variant="h4"
												style={{ fontWeight: 400, fontSize: 15, display: "inline-flex" }}
											>
												<Typography
													variant="body2"
													style={{
														color: theme.palette.secondaryResponse.main,
														cursor: "pointer",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
													}}
												>
													Type: {JourneyType[journey.type].replace(/_/g, " ")}
												</Typography>
											</Typography>
										</div>
									</div>
								</Grid>
							);
						})}
					</>
				)}
			</Grid>
		</div>
	);
};

export default JourneyListingTable;
