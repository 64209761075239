import { ThemeOptions, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
	interface Palette {
		mediumGrayBlue: Palette["primary"];
		primaryResponse: Palette["primary"];
		secondaryResponse: Palette["primary"];
		errorResponse: Palette["primary"];
		grayTextColor: Palette["primary"];
		lightGrayBlue: Palette["primary"];
		jacobsBlue: Palette["primary"];
	}
	interface PaletteOptions {
		mediumGrayBlue: PaletteOptions["primary"];
		primaryResponse: PaletteOptions["primary"];
		secondaryResponse: PaletteOptions["primary"];
		errorResponse: PaletteOptions["primary"];
		grayTextColor: PaletteOptions["primary"];
		lightGrayBlue: PaletteOptions["primary"];
		jacobsBlue: PaletteOptions["primary"];
	}
}

const productionStyles: ThemeOptions = {
	typography: {
		fontFamily: "Open Sans",
		h1: {
			fontSize: "32px",
			fontWeight: "bold",
			color: "#1C4057",
		},
		h2: {
			fontSize: "20px",
			fontWeight: "bold",
			color: "#1C4057",
		},
		h3: {
			fontSize: "18px",
			color: "#1C4057",
		},
		h4: {
			fontSize: "16px",
			fontWeight: "bold",
			color: "#1C4057",
		},
		button: {
			textTransform: "none",
		},
		body1: {
			fontSize: "14px",
			fontWeight: "bold",
		},
		body2: {
			fontSize: "14px",
		},
	},
	palette: {
		primary: {
			main: "#1C4057",
			contrastText: "#fff"
		},
		secondary: {
			main: "#40C7A3",
			contrastText: "#fff"
		},
		error: {
			main: "#dc004e",
			contrastText: "#fff"
		},
		mediumGrayBlue: {
			main: "#818A91",
		},
		primaryResponse: {
			main: "#40C7A3",
		},
		secondaryResponse: {
			main: "#1C4057",
		},
		errorResponse: {
			main: "#CC0808",
		},
		grayTextColor: {
			main: "#626c73",
		},
		lightGrayBlue: {
			main: "#E5E5E5",
		},
		jacobsBlue: {
			main: "#1C6AB9",
		},
	},
	overrides: {
		MuiCheckbox: {
			root: {
				"&$checked": {
					color: "#1C4057",
				},
			},
		},
		MuiToolbar: {
			root: {
				minHeight: 48,
			},
		},
		MuiInputLabel: {
			root: {
				fontWeight: 400,
				fontSize: 16
			},
		},
		MuiTextField: {
			root: {
				fontSize: 16
			}	
		},
		MuiInput: {
			root: {
				fontWeight: 400,
			},
			input: {
				fontSize: 16
			}
		},
		MuiInputBase: {
			root: {
				fontSize: 16
			}
		},
		MuiTouchRipple: {
			child: {
				backgroundColor: "#E5E5E5"
			}
		},
		MuiButton: {
			root: {
				fontWeight: 600,
				borderRadius: 9
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 14
			}

		},
		MuiCard: {
			root: {
				borderRadius: 5
			}
		}
	},
};

// const devStyles: ThemeOptions = {
// 	...productionStyles,
// 	palette: {
// 		secondary: {
// 			main: "#CC0808",
// 		},
// 		mediumGrayBlue: {
// 			main: "#818A91",
// 		},
// 		primaryResponse: {
// 			main: "#1C4057",
// 		},
// 		secondaryResponse: {
// 			main: "#40C7A3",
// 			light: "#EF3BF9",
// 		},
// 		errorResponse: {
// 			main: "#CC0808",
// 		},
// 		grayTextColor: {
// 			main: "#626c73",
// 		},
// 		lightGrayBlue: {
// 			main: "#E5E5E5",
// 		},
// 	},
// 	overrides: {
// 		MuiCheckbox: {
// 			root: {
// 				"&$checked": {
// 					color: "#1C4057",
// 				},
// 			},
// 		},
// 		MuiToolbar: {
// 			root: {
// 				minHeight: 48,
// 			},
// 		},
// 		MuiInputLabel: {
// 			root: {
// 				fontWeight: 400,
// 			},
// 		},
// 		MuiInput: {
// 			root: {
// 				fontWeight: 400,
// 			},
// 		},
// 	},
// };
// https://xd.adobe.com/view/6b3bf675-4398-4b33-a990-8cb881b77eb4-3e68/specs/
// based from Style guide
export const ResponseTheme = createMuiTheme(
	// resolveEnvironment() === RuntimeEnvironment.Development ? devStyles : productionStyles
	productionStyles
);
