export const StringHelper = {
    toTitleCase: (str: string) => {
        return str.replace(/([^\W_]+[^\s-]*) */g, function (txt: string):string{
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },
    insert: (initialText: string, token: string, selectionStart: number | null) => {
        if (selectionStart != null) {
            const textBeforeCursorPosition = initialText.substring(0, selectionStart);
            const textAfterCursorPosition = initialText.substring(selectionStart, initialText.length);
            return (`${textBeforeCursorPosition}${token}${textAfterCursorPosition}`);
        } else {
            return initialText + " " + token;
        }
    }
}