import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { HorizontalCentered } from "../../components/HorizontalCentered";
import { PageTitle } from "../../components/PageTitle";
import { useAppUser } from "../../hooks/useAppUser";
import { useAttendees } from "../../providers/AttendeeProvider";
import { useGroups } from "../../providers/GroupProvider";
import { useJourneys } from "../../providers/JourneyProvider";
import { DashboardData } from "../../services/DashboardService";
import { getDataLayer } from "../../utillity/Tracking/GoogleAnalytics";
import { MessageCard } from "./Components/MessageCard";
import { PeopleCard } from "./Components/PeopleCard";
import { RecentJourneys } from "./Components/RecentJourneys";

const Dashboard = (props: { data: DashboardData }) => {
	const dataLayer = getDataLayer();
	const [user] = useAppUser();
	const { attendees } = useAttendees();
	const { groups } = useGroups();
	const { journeys } = useJourneys();

	useEffect(() => {
		dataLayer.push({
			event: "account-info",
			people: attendees.length,
			recentSentMessages: props.data.recentSentMessages.map(m => m.messagesSent).reduce((prev, next) => prev + next),
			hasSentMessage: props.data.hasSentMessage,
			groups: groups.length,
			journeys: journeys.length,
			age: props.data.daysAccountHasBeenOpen,
			username: user.firstName,
			dismissedBlocks: props.data.dismissedIds.join(","),
			responsePhoneNumber: user.responsePhoneNumber ? user.responsePhoneNumber.substring(1) : undefined,
			churchName: user.activeChurchName
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container spacing={3}>
			<PageTitle title="Dashboard" />
			<Grid item  style={{maxWidth: 850}} xs={12}>
				<HorizontalCentered>
					<Grid container>
						<Grid item xs={12} id="dash-block-1" />
						<Grid item xs={12} id="dash-block-2" />
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item md={6} xs={12}>
									<PeopleCard />
								</Grid>
								<Grid item md={6} xs={12}>
									<MessageCard data={props.data} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} id="dash-block-3" />
						<Grid item xs={12} id="dash-block-4" />
						<Grid item xs={12} id="dash-block-5" />
						<Grid item xs={12} style={{marginTop: 30}}>
							<RecentJourneys />
						</Grid>
					</Grid>
				</HorizontalCentered>
			</Grid>
		</Grid>
	);
};

export default Dashboard;
