import { FormControl, Grid, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";
import { Group } from "../../../entities/Groups/Group";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { useGroups } from "../../../providers/GroupProvider";
import { GroupService, ScheduleMessageToGroupRequest } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import SendIcon from "@material-ui/icons/Send";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { useAutoShortLinkState } from "../../../hooks/useAutoShortLinkState";
import { FancyPhone } from "../../../components/FancyPhone";
import { PersonalizationButtons } from "../../../components/PersonalizationButtons";
import { AddMessageExtrasButtons } from "../../../components/AddMessageExtrasButtons";
import { TextMessageMedia } from "../../../entities/Messages/TextMessageMedia";
import { StringHelper } from "../../../utillity/StringHelper";
import { TextMessageMediaService } from "../../../services/TextMessageMediaService";
import { Schedule } from "@material-ui/icons";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";

interface SendGroupAnnouncementDialogProps {
	group: Group;
	onClose: () => void;
	onBack?: () => void;
}

export function SendGroupAnnouncementDialog(props: SendGroupAnnouncementDialogProps) {
	const { group } = props;
	const groupId = group.id;

	const inputRef = useRef<HTMLInputElement>(null);
	const theme = useTheme();
	const GroupContext = useGroups();
	const classes = useStyles();
	const history = useHistory();
	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [uploadFileKey, setUploadFileKey] = useState(0);
	const [images, setImages] = useState<TextMessageMedia[]>([]);
	const [disabled, setDisabled] = useState(false);
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [smsSelectionIndex, setSmsSelectionIndex] = useState(0);
	const [smsSelectionStart,setSmsSelectionStart] = useState(0);
	const [smsBoxFocused, setSmsBoxFocused] = useState(false);
	const [scheduleOpen, setScheduleOpen] = useState(false);
	const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
	const [invalidDate, setInvalidDate] = useState<string | undefined>(undefined);
	
	const autoLinkContext = useAutoShortLinkState("", smsBoxFocused ? smsSelectionIndex : null, { groupId });
	const { hasShortLinks, processingLinks} = autoLinkContext;
	const [message, setMessage] = autoLinkContext.state;

	const [submitionRequest, setSubmissionRequest] = useState<ScheduleMessageToGroupRequest>();

	useEffect(() => {
		if (submitionRequest == null || processingLinks) {
			return;
		}

		async function submitRequest(request: ScheduleMessageToGroupRequest) {
			setFieldErrors([]);
			const result = await GroupService.scheduleMessageToGroup(request);
			setDisabled(false);
			setSubmissionRequest(undefined);

			if (ServerResult.isValidationError(result)) return setFieldErrors(result.errors);

			if (ServerResult.isError(result)) return setErrorAlert(result);

			setSuccessAlert(scheduledDate ? "Message Scheduled" : "Message Sent");
			GroupContext.update(result.data);
			props.onClose();
			if(history.location.pathname.includes("app/groups"))
				history.push(routes.app.resolve.groupDetailPageAnnouncements(groupId))
		}

		submitRequest({ ...submitionRequest, message });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processingLinks, submitionRequest]);

	const acceptedMemberCount = group.groupMembers.filter(
		(m) => m.groupMembershipStatus === GroupMembershipStatus.Accepted
	).length;

	const onSubmit = async () => {
		const request: ScheduleMessageToGroupRequest = {
			groupId: group.id,
			message: message,
			media: images,
			scheduledTime: scheduledDate ?? new Date(Date.now()),
		};
		setDisabled(true);
		setSubmissionRequest(request);
	};

	const onSchedule = async (date: MaterialUiPickersDate) => {
		setInvalidDate(undefined)
		if(!date)
			return;

		date.setSeconds(0, 0);

		if (date.getTime() < new Date().getTime())
			return setErrorAlert({message: "Date is in the past.", statusCode: 200 });

		if(date.getHours() > 23 || date.getHours() < 5)
			return setErrorAlert({message: "Scheduled time must be between 5am and 11pm", statusCode: 200 });
			
		const request: ScheduleMessageToGroupRequest = {
			groupId: group.id,
			message: message,
			media: images,
			scheduledTime: date,
		};

		setDisabled(true);
		setSubmissionRequest(request);
	};

	const toggleDisabled = () => setDisabled(!disabled);

	const handleFileUpload = async(file:File) => {
		setDisabled(true)
		const result = await TextMessageMediaService.upload(file);
		setDisabled(false)
		if (ServerResult.isSuccess(result)) setImages([...images, result.data]);
		else setErrorAlert(result);
		setUploadFileKey(uploadFileKey + 1);
	};

	const handleDate = (date: MaterialUiPickersDate) => {
		setInvalidDate(undefined)
		if(date){
			if (date.getTime() < new Date().getTime())
				return setInvalidDate("Date is in the past.");
			if(date.getHours() > 23 || date.getHours() < 5)
				return setInvalidDate("Scheduled time must be between 5am and 11pm");
			date.setSeconds(0, 0);
			setScheduledDate(date);
		}
	}
	
	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Grid container justify="center">
						<Grid item md={8} xs={12}>
							<Typography align="center" variant="body2" style={{ fontSize: 16, marginBottom: 16, marginTop: 44 }}>
								Send an announcement to{" "}
								<strong>
									{acceptedMemberCount} {acceptedMemberCount === 1 ? "person" : "people"}
								</strong>{" "}
								in {group.name}?
							</Typography>
							<FormControl fullWidth>
								<Typography className={classes.progressLabelLighterText} variant="subtitle2">
									SMS text message:
								</Typography>
								<TextField
									ref={inputRef}
									placeholder="Type your SMS message..."
									id="outlined-bare"
									value={message}
									margin="normal"
									variant="outlined"
									fullWidth
									style={{ marginTop: 0, marginBottom: 0 }}
									multiline={true}
									rowsMax={4}
									rows={3}
									onChange={(e) => {
										setMessage(e.currentTarget.value);
										setSmsSelectionIndex(e.currentTarget.selectionEnd ?? 0);
										setSmsSelectionStart(e.currentTarget.selectionStart ?? 0);
									}}
									onFocus={() => setSmsBoxFocused(true)}
									onBlur={() => setSmsBoxFocused(false)}
									error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Message")}
									helperText={FieldValidationErrorUtils.getFieldError(fieldErrors, "Message")}
								/>
							</FormControl>
							<div style={{ height: 44, width: "100%" }}>
								{hasShortLinks && (
									<div
										style={{
											backgroundColor: "#7ac142",
											border: `1px solid ${theme.palette.success.dark}`,
											borderRadius: 12,
											padding: 7,
										}}
									>
										<Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap">
											<Grid item>
												<img src="/images/wand.png" alt="Wand" />
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" style={{ color: "#fff", fontSize: 15 }}>
													Poof! Your link was magically shortened (we use Narnia-approved magic)
												</Typography>
											</Grid>
										</Grid>
									</div>
								)}
							</div>
							<PersonalizationButtons onAddPersonalization={(token) => setMessage(StringHelper.insert(message, token, smsSelectionStart))} />
							<AddMessageExtrasButtons
								componentRef={inputRef}
								disabled={disabled}
								uploadedImageCount={images.length}
								uploadFileKey={uploadFileKey}
								onAddMedia={(file) => handleFileUpload(file) }
								onAddToMessage={(token) => setMessage(StringHelper.insert(message, token, smsSelectionStart))} 
								toggleDisabled={() => toggleDisabled()} />
						</Grid>
						<FancyPhone completionResponse={message} media={images} removeImage={(image) => setImages(images.filter(i => i !== image))}/>
					</Grid>
				</VerticalCenter>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DateTimePicker
						inputProps={{style: {display: "none"}}}
						value={scheduledDate}
						disablePast
						onChange={(date) => handleDate(date)}
						onAccept={(date) => onSchedule(date)}
						showTodayButton
						open={scheduleOpen}
						onClose={() => setScheduleOpen(false)}
						okLabel="Schedule"
						minDate={new Date().toLocaleString()}
						error={invalidDate !== undefined}
					/>
				</MuiPickersUtilsProvider>
			</ResponseDialogContent>
			<ResponseDialogActions
				disabled={disabled}
				hideNextStep={false}
				onConfirm={onSubmit}
				nextButtonText={"Send"}
				nextButtonIcon={<SendIcon />}
				onBack={props.onBack ?? props.onClose}
				onSecondary={() => setScheduleOpen(true)}
				secondaryButtonText="Schedule"
				secondaryButtonIcon={<Schedule />}
				hideSecondaryButton={false}
				backButtonText="Cancel"
			/>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		padding: 8,
		height: 100,
		"& > textarea": {
			padding: 10,
			height: "100%",
			width: "100%",
		},
		width: "100%",
	},
	progressLabelLighterText: {
		color: "#404448",
		fontSize: 15,
	},
	editBox: {
		width: "100%",
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
	},
}));