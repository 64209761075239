import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import AttendeeService from "../../../services/AttendeeService";
import { ServerResult } from "../../../services/server/ServerResult";

export function OptInPendingAttendees(props:{ churchId: string }){
    const setSuccessAlert = useSuccessAlert();
    const [disabled, setDisabled] = useState(false);

    const handleClick = async() => {
        setDisabled(true);
        const response = await AttendeeService.optInPendingAttendees(props.churchId);
        setDisabled(false);

        if(ServerResult.isSuccess(response))
            return setSuccessAlert("Attendees opted into church")
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
                <Typography>Opt-in all pending attendees for church</Typography>
            </Grid>
            <Grid item xs={6}>
                <Button disabled={disabled} onClick={handleClick} variant="contained" color="primary">
                    Confirm Opt-In
                </Button>
            </Grid>
        </Grid>
    )
}