import { Badge, createStyles, Theme, withStyles } from "@material-ui/core";

export const ResponseBadge = withStyles((theme: Theme) =>
createStyles({
    badge: {
        top: 5,
        right: -15,
        backgroundColor: theme.palette.primaryResponse.main,
        color: theme.palette.primary.contrastText
    },
})
)(Badge);

export const ResponseMenuBadge = withStyles((theme: Theme) =>
createStyles({
    badge: {
        top: 18,
        right: 14,
        backgroundColor: theme.palette.primaryResponse.main,
        color: theme.palette.primary.contrastText,
        zIndex: -1
    },
})
)(Badge);