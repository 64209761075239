import React from "react";
import { Journey, JourneyCategory } from "../../../entities/Journey";
import { MessageExtraType } from "../../../entities/MessageExtraType";
import { MessageAddressView } from "./MessageAddressView";
import { MessageEventCalendarView } from "./MessageEventCalendarView";
import { MessageFormView } from "./MessageFormView";
import { MessageLinkView } from "./MessageLinkView";
import { MessageMediaView } from "./MessageMediaView";

interface AddJourneyMessageExtraProps {
	type: MessageExtraType;
	journey: Journey;
	onCancel: () => void;
	onUpdate: (updatedJourney: Journey, type: MessageExtraType) => void;
}

export function AddJourneyMessageExtra(props: AddJourneyMessageExtraProps) {
	const { type, journey } = props;
	switch (type) {
		case MessageExtraType.Address:
			return (
				<MessageAddressView
					mode="add"
					journey={journey}
					title="Add Address to Journey"
					onCancel={props.onCancel}
					onUpdate={(j) => props.onUpdate(j, MessageExtraType.Address)}
				/>
			);
		case MessageExtraType.Event:
			return journey.category === JourneyCategory.Informational ? (
				<MessageEventCalendarView
					mode="add"
					journey={journey}
					title="Add Event to Journey"
					onCancel={props.onCancel}
					onUpdate={(j) => props.onUpdate(j, MessageExtraType.Event)}
				/>
			) : null;
		case MessageExtraType.Link:
			return (
				<MessageLinkView
					mode="add"
					journey={journey}
					title="Add Link to Journey"
					onCancel={props.onCancel}
					onUpdate={(j) => props.onUpdate(j, MessageExtraType.Link)}
				/>
			);
		case MessageExtraType.Media:
			return (
				<MessageMediaView
					mode="add"
					journey={journey}
					title="Add Media to Journey"
					onCancel={props.onCancel}
					onUpdate={(j) => props.onUpdate(j, MessageExtraType.Media)}
				/>
			);
		case MessageExtraType.Form:
			return (
				<MessageFormView
					mode="add"
					journey={journey}
					title="Add Form to Journey"
					onCancel={props.onCancel}
					onUpdate={(j) => props.onUpdate(j, MessageExtraType.Form)}
				/>
			);
		default:
			return null;
	}
}
