import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, Typography, FormHelperText, TextField, FormControl } from '@material-ui/core';
import React, { useState } from 'react'
import ResponseSubscription from '../../../entities/ResponseSubscription';
import { buildDateTimeString } from '../../../functions/datetime';
import { useServerErrorAlert } from '../../../hooks/useServerErrorAlert';
import { useSuccessAlert } from '../../../hooks/useSuccessAlert';
import ResponseSubscriptionService from '../../../services/ResponseSubscriptionService';
import { ServerResult } from '../../../services/server/ServerResult';

interface DialogProps {
    open: boolean;
    toggleClose: () => void;
    subscription: ResponseSubscription;
    updateSubscription: (subscription:ResponseSubscription) => void
}

export function CancelDialog(props:DialogProps){
    const {open, subscription} = props; 
    const theme = useTheme();
    const setServerErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

    const [cancelReason, setCancelReason] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [displayCanceled, setDisplayCanceled] = useState<boolean>(subscription.isCanceled);

    const cancelSubscription = async () => {
		setDisabled(true);
		const result = await ResponseSubscriptionService.cancelSubscription(cancelReason);
		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			setSuccessAlert("Subscription will expire at the end of this billing period.");
			props.updateSubscription(result.data);
            setDisplayCanceled(true);
            setTimeout(props.toggleClose, 3000);
		} else {
			setServerErrorAlert(result);
		}
	};

    const showCancelPrompt = () => {
        return(
        <>
            <DialogTitle id="cancel-sub-title">Are you sure you want to cancel your subscription?</DialogTitle>
            <DialogContent>
                <FormControl
                    style={{width: "100%"}}
                    error={cancelReason.length < 20}
                    >
                    <Typography>Please share why you want to cancel:</Typography>
                    <TextField
                        id="outlined-bare"
                        value={cancelReason}
                        margin="normal"
                        variant="outlined"
                        style={{ marginTop: 0, marginBottom: 0 }}
                        fullWidth
                        multiline={true}
                        onChange={(e) => {
                            setCancelReason(e.currentTarget.value);
                        }}
                        error={cancelReason.length < 20}
                        placeholder="I’m cancelling my account because…"
                    />
                    {cancelReason.length < 20 ? (
                        <FormHelperText>Required character count: {cancelReason.length}/20</FormHelperText>
                    ): (
                        <FormHelperText>Character count: {cancelReason.length}/20</FormHelperText>
                    )}
                </FormControl>
                { mobileFormFactor ? (
                    <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                        Need Help? Text us at <a href="tel:+17192154561">(719) 215-4561</a> or <a href="mailto:support@joinresponse.com">email us</a>
                    </Typography>
                    ):(
                    <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                        Need Help? Text us at (719) 215-4561 or <a href="mailto:support@joinresponse.com">email us</a>
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="outlined" 
                    style={{color: theme.palette.error.main, borderColor: theme.palette.error.main}}
                    size="medium" 
                    onClick={cancelSubscription} 
                    disabled={disabled || cancelReason.length < 20}
                >
                    Yes, Cancel Subscription
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    onClick={props.toggleClose}
                    disabled={disabled}
                    style={{ textDecoration: "underline"}}
                >
                    No, don't cancel
                </Button>
            </DialogActions>
        </>
        )
    }

    const showCancelState = function(){
        return (
            <>
                <DialogTitle id="cancel-sub-title">Subscription Cancelled</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You will still be able to access your account until{" "}
                        {subscription?.currentPeriodEnd
                            ? buildDateTimeString(subscription?.currentPeriodEnd.toString(), true)
                            : "your subscription ends"}
                        { mobileFormFactor ? (
                            <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                                Need Help? Text us at <a href="tel:+1719-645-4113">(719) 645-4113</a> or <a href="mailto:support@joinresponse.com">email us</a>
                            </Typography>
                            ):(
                            <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                                Need Help? Text us at (719) 645-4113 or <a href="mailto:support@joinresponse.com">email us</a>
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        type="submit"
                        style={{backgroundColor: theme.palette.secondaryResponse.main}}
                        onClick={props.toggleClose}
                        >
                        close
                    </Button>
                </DialogActions>
            </>
        )
    }

    return (
        <Dialog open={open} onClose={props.toggleClose} fullScreen={mobileFormFactor}>
        { displayCanceled && showCancelState()}
        { !displayCanceled && showCancelPrompt()}
        </Dialog>
    )
}