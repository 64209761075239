import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { AwesomeCheckbox } from "../../../components/AwesomeCheckbox";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { isMobile } from "../../../hooks/isMobile";
import { routes } from "../../../routes";
import { useCsvFileStepStyles } from "./UploadCsvFileStep";
import ReplayIcon from "@material-ui/icons/Replay";

export function CsvUploadCompleteStep(props: { onRestart: () => void }) {
	const xsMobile = isMobile("xs");
	const classes = useCsvFileStepStyles();
	const history = useHistory();

	return (
		<VerticalCenter>
			<Grid container justify="center" spacing={5}>
				<Grid item xs={12}>
					<Typography variant="h1" align="center">
						Successfully Imported People
					</Typography>
				</Grid>
				<Grid item>
					<AwesomeCheckbox size={100} color="primary" />
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="center" spacing={3}>
						<Grid item sm="auto" xs={6}>
							<Button
								variant="contained"
								color="default"
								onClick={props.onRestart}
								fullWidth
								size="large"
								className={!xsMobile ? classes.fatButton : undefined}
								startIcon={<ReplayIcon />}
							>
								Upload Another CSV
							</Button>
						</Grid>
						<Grid item sm="auto" xs={6}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => history.push(routes.app.resolve.peopleListPage())}
								fullWidth
								size="large"
								className={!xsMobile ? classes.fatButton : undefined}
							>
								See All People
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</VerticalCenter>
	);
}
