import { HubConnection } from "@microsoft/signalr";
import React from "react";

interface Hub {
	connection: HubConnection | undefined;
	hubReady: boolean;
	onEvent: (name: string, functionToFire: (...args: any[]) => void) => () => void;
	invoke<T = any>(methodName: string, ...args: any[]): Promise<T | undefined>;
}

export const HubContext = React.createContext<Hub>({} as any);

export function useHub() {
	return React.useContext(HubContext);
}
