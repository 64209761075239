import { Modify } from "../utillity/modifyType";
import { DayOfWeek } from "./DayOfWeek";

export interface JourneyBase {
	id: string;
	churchId: string;
	enabled: boolean;
	name: string;
	type: JourneyType;
	category: JourneyCategory.Unknown;
	keywordTrigger: string;
	completionResponse: string;
	followUpEnabled: boolean;
	hasSubscribers: boolean;
	extras: JourneyExtras;
	dateCreated: Date;
}

export type InformationalJourney = Modify<
	JourneyBase,
	{
		event?: JourneyEvent;
		category: JourneyCategory.Informational;
	}
>;

export type PromptJourney = Modify<
	JourneyBase,
	{
		category: JourneyCategory.Prompt;
		prompt: string;
	}
>;

export type SermonPromptJourney = Modify<
	JourneyBase,
	{
		category: JourneyCategory.Sermon_Prompts;
		type: JourneyType.Sermon_Prompts;
		promptMessageSchedule: MessageSchedule;
	}
>;

export type Journey = InformationalJourney | PromptJourney | SermonPromptJourney | JourneyBase;

export interface MessageSchedule {
	daysToRun: DayOfWeek[];
	timeToRun: string;
}

export enum JourneyType {
	Unknown = 0,
	Connect_Card = 1,
	Prayer_Requests = 2,
	Sermon_Prompts = 3,
	Volunteer_Sign_Up = 4,
	Event_Sign_Up = 5,
	Group_Sign_Up = 6,
	Gather_Emails = 7,
	Survey_Your_Church = 8,
	Share_A_Link = 9,
	Custom = 10,
}

export enum JourneyCategory {
	Unknown = 0,
	Informational = 1,
	Prompt = 2,
	Sermon_Prompts = 3,
}

export enum EventSchedule {
	Unknown = 0,
	DoesNotRepeat = 1,
	Weekly = 2,
	BiWeekly = 3,
}

export interface JourneyExtras {
	address?: JourneyAddress;
	formId?: string;
	mediaLink?: string;
	link?: string;
}

export interface JourneyEvent {
	originalTime: Date;
	nextEventTime: Date;
	schedule: EventSchedule;
}


export interface JourneyAddress {
	street: string;
	city: string;
	state: string;
	zip: string;
}
