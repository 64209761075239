import { Button, IconButton, makeStyles, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Close";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { QuestionChoiceType, QuestionSignupListChoice } from "../../../entities/Forms/QuestionChoice";

export const useSignUpListStyles = makeStyles((theme: Theme) => ({
	infoHeader: {
		fontSize: 14,
		fontStyle: "italic",
		opacity: 0.6,
		marginBottom: 2,
	},
	optionField: {
		paddingTop: 5,
		"& > div:first-child": {
			"&:before": {
				display: "none",
			},
			"&:hover": {
				"&:before": {
					display: "block",
					opacity: 0.6,
				},
			},
		},
	},
	optionText: {
		fontSize: 16,
		color: "rgba(0,0,0,.6)",
		fontWeight: 500,
	},
	addOptionButton: {
		paddingLeft: 0,
		fontSize: 16,
		opacity: 0.9,
		"&:hover": {
			backgroundColor: "initial",
			textDecoration: "underline",
		},
	},
	quantityDropDown: {
		marginLeft: 2,
		backgroundColor: "#e2eaf3",
		border: "none",
		width: 52,
		color: "rgba(0,0,0,.6)",
		fontSize: 16,
		borderRadius: 13,
		paddingLeft: 9,
		"&:before": {
			display: "none",
		},
		"&:after": {
			display: "none",
		},
	},
	addIcon: {
		fontSize: 24,
		opacity: 0.6,
	},
}));

interface SignupListAnswerDetailProps {
	choices: QuestionSignupListChoice[];
	onChange: (choices: QuestionSignupListChoice[]) => void;
}

export function SignupListAnswerDetail(props: SignupListAnswerDetailProps) {
	const classes = useSignUpListStyles();
	const { choices, onChange } = props;

	const onDelete = (choiceIndex: number) => {
		const choices = [...props.choices];
		choices.splice(choiceIndex, 1);
		props.onChange(choices);
	};

	const onAdd = () => {
		const option: QuestionSignupListChoice = {
			choiceType: QuestionChoiceType.SignupList,
			text: "",
			quantity: 0,
		};
		const choices = [...props.choices, option];
		props.onChange(choices);
	};

	const onTextChange = (text: string, choiceIndex: number) => {
		if (text.length === 0) {
			onDelete(choiceIndex);
		} else {
			let changedChoices = [...choices];
			changedChoices[choiceIndex].text = text;
			onChange(changedChoices);
		}
	};

	const onNumberChange = (count: number, choiceIndex: number) => {
		let changedChoices = [...choices];
		changedChoices[choiceIndex].quantity = count;
		onChange(changedChoices);
	};

	return (
		<div style={{ marginLeft: 4 }}>
			<Typography className={classes.infoHeader} variant="subtitle1">
				Set number of signups available for each item.
			</Typography>
			<div style={{ maxHeight: 300, overflowY: "auto" }}>
				{props.choices.map((c, index) => (
					<div style={{ display: "flex" }} key={index}>
						<TextField
							value={choices[index].text}
							placeholder={`Option ${index + 1}`}
							type="text"
							onChange={(e) => onTextChange(e.target.value, index)}
							className={classes.optionField}
							inputProps={{
								maxLength: 100,
								className: classes.optionText,
							}}
						/>

						<Select
							className={classes.quantityDropDown}
							value={c.quantity}
							onChange={(e) => onNumberChange(e.target.value as number, index)}
							inputProps={{ max: 100, min: 0 }}
							IconComponent={KeyboardArrowDown}
						>
							{new Array(100).fill(null).map((_, i) => (
								<MenuItem key={i + 1} value={i + 1}>
									{i + 1}
								</MenuItem>
							))}
						</Select>

						{index > 0 && (
							<IconButton aria-label="close modeal" component="span" onClick={() => onDelete(index)}>
								<CancelIcon style={{ fontSize: 20 }} />
							</IconButton>
						)}
					</div>
				))}
			</div>
			<div style={{ display: "flex", marginTop: 4 }}>
				<Button className={classes.addOptionButton} onClick={onAdd} startIcon={<Add className={classes.addIcon} />}>
					{" "}
					Add Option
				</Button>
			</div>
		</div>
	);
}
