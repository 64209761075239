import { Grid } from "@material-ui/core";
import React from "react";
import LoadingSpinner from "./loaderSpinner";

export function LoadingPage(props: {message?: string}) {
	return (
		<Grid container justify="center" alignItems="center" style={{ height: "80%" }}>
			<Grid item>
				<LoadingSpinner durationMessage={props.message} isComplete={false} />
			</Grid>
		</Grid>
	);
}
