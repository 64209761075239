import { JourneyNotificationSubscription } from "../hooks/useJourneyNotifications";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export const JourneyNotificationSubscriptionService = {
    clearJourneyNotifications(journeyId: string) {
		  return WebClient.Put.Unvalidated(`${apiRoot}/journeys/notifications/clear/${journeyId}`, {});
	},
    createJourneySubscription(journeyId: string) {
		  return WebClient.Post.Unvalidated<JourneyNotificationSubscription>(`${apiRoot}/journeys/notifications`, { journeyId });
    },
    disableSubscription(subscriptionId: string) {
		  return WebClient.Delete(`${apiRoot}/journeys/notifications/${subscriptionId}`);
	}
}