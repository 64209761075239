import { Button, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import NotFoundIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AnswerInput } from "../../components/Forms/AnswerInput";
import { GridGrow } from "../../components/GridGrow";
import LoadingSpinner from "../../components/loaderSpinner";
import { Answer } from "../../entities/Forms/Answer";
import { Form } from "../../entities/Forms/Form";
import { Question, QuestionType } from "../../entities/Forms/Question";
import { isMobile } from "../../hooks/isMobile";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { FormService } from "../../services/FormService";
import { ServerResult } from "../../services/server/ServerResult";

const useStyles = makeStyles((theme: Theme) => ({
	homeRoot: {
		padding: 24,
		margin: "0 auto",
		marginTop: 60,
		maxWidth: 1000,
		height: "calc(100% - 60px)",
		overflowY: "auto",
		[theme.breakpoints.down("sm")]: {
			padding: 8,
			marginTop: 0,
			height: "100%"
		},
		[theme.breakpoints.down("xs")]: {
			padding: 0,
		},
	},
	mobileContent: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		overflowY: "auto",
		overflowX: "hidden",
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 10,
		paddingRight: 10,
	},
	

	headerText: {
		fontSize: 20,
		fontWeight: 700,
		color: theme.palette.secondaryResponse.main,
		[theme.breakpoints.down("xs")]: {
			padding: 10,
			marginTop: 25,
		},
	},
	smallFormFactorPadding: {
		[theme.breakpoints.down("xs")]: {
			padding: 10,
		},
	},
	buttonContainer: {
		position: "absolute",
		bottom: 24,
		left: 24,
		right: 24,
		display: "flex",
	},
	submit: {
		backgroundColor: theme.palette.secondaryResponse.main,
		marginBottom: 12,
		"&:hover": {
			backgroundColor: theme.palette.secondaryResponse.main,
			opacity: 0.7,
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: 30,
		},
		[theme.breakpoints.down("xs")]: {
			marginRight: 10,
		},
	},
}));

export interface FormError {
	questionId: string;
	error: string;
}

const FormResponder = () => {
	const classes = useStyles();

	const params = useParams<{ churchId: string; formId: string; attendeeId: string }>();
	const churchId = params.churchId;
	const formId = params.formId;
	const attendeeId = params.attendeeId;

	const [, setPageTitle] = usePageTitle();

	useEffect(() => {
		setPageTitle("Form Responder");
	}, [setPageTitle]);

	const setServerErrorAlert = useServerErrorAlert();
	const mobile = isMobile();
	const [form, setForm] = useState<Form | undefined>();
	const [answers, setAnswers] = useState<Answer[]>([]);
	const [notFound, setNotFound] = React.useState(!churchId || !formId || !attendeeId);
	const [hasAnswersErrored, setHasAnsweredErrored] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);
	const [validationErrors, setValidationErrors] = React.useState<FormError[]>([]);
	const [doneSon, setDoneSon] = React.useState(false);

	React.useEffect(() => {
		const fetch = async () => {
			const result = await FormService.getAnonymousForm(churchId, formId, attendeeId);
			if (ServerResult.isSuccess<Form>(result)) {
				const resultAnswer = await FormService.getAnswersByAttendeeForForm(churchId, formId, attendeeId);

				if (ServerResult.isSuccess<Answer[]>(resultAnswer)) {
					setForm(result.data);
					setAnswers(resultAnswer.data);
				} else {
					setHasAnsweredErrored(true);
				}
			} else {
				setNotFound(true);
			}
		};
		fetch();
	}, [churchId, formId, attendeeId, setServerErrorAlert]);

	const renderLoadingPage = () => {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={false} durationMessage={"Loading..."} />
			</div>
		);
	};

	const onAnswerChanged = (answer: Answer) => {
		const hasAnswer = answers.some((a) => a.questionId === answer.questionId);

		const updatedAnswers = hasAnswer
			? [...answers].map((a) => (a.questionId === answer.questionId ? answer : a))
			: [...answers, { ...answer }];

		setAnswers(updatedAnswers);
	};

	const onAnswerDeleted = (question: Question) => {
		const updatedAnswers = [...answers].filter((a) => a.questionId !== question.id);

		setAnswers(updatedAnswers);
	};

	const onSubmit = async () => {
		if (!form) return;
		setValidationErrors([]);

		let isValid = validateAnswers();

		if (!isValid) {
			window.scrollTo(0, 0);
			return;
		}
		setDisabled(true);
		const response = await FormService.submitFormAnswer({ formId, attendeeId, answers });
		setDisabled(false);

		if (ServerResult.isSuccess(response)) {
			setDoneSon(true);
		} else if (ServerResult.isValidationError(response)) {
			//setValidationErrors(response.errors);
		} else {
			setServerErrorAlert(response);
		}
	};

	const validateAnswers = () => {
		if (!form) {
			return false;
		}
		let errors: FormError[] = [];

		form.questions.forEach((question) => {
			if (question.required) {
				let answer = answers.find((a) => a.questionId === question.id);

				if (!answer) {
					if (question.questionType !== QuestionType.SignupList) {
						errors.push({ questionId: question.id, error: "Question is required." });
					} else {
						let hasQuanityRemaining = question.choices.filter((a) => a.quantity > 0);
						if (hasQuanityRemaining.length > 0) {
							errors.push({ questionId: question.id, error: "One item is required to sign up for." });
						}
					}
				} else if (answer.questionType === QuestionType.SignupList && answer.signupItems.length === 0) {
					if (question.questionType === QuestionType.SignupList) {
						let hasQuanityRemaining = question.choices.filter((a) => a.quantity > 0);
						let userHasNoSelections = answer.signupItems.filter((a) => a.quantity > 0);

						if (hasQuanityRemaining.length > 0 && userHasNoSelections.length === 0) {
							errors.push({ questionId: question.id, error: "One item is required to sign up for." });
						}
					}
				}
			}
		});

		setValidationErrors(errors);
		return errors.length === 0;
	};

	if (notFound) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<NotFoundIcon style={{ fontSize: 44 }} />{" "}
				<Typography style={{ fontSize: 24 }}> We apologize for the problem but this form was not found.</Typography>
			</div>
		);
	}

	if (hasAnswersErrored) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<NotFoundIcon style={{ fontSize: 44 }} />{" "}
				<Typography style={{ fontSize: 24 }}>
					{" "}
					We apologize for the problem but we were unable to retrieve your previous answers.
				</Typography>
			</div>
		);
	}

	if (!form) return renderLoadingPage();

	if (doneSon)
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<Typography className={classes.headerText} variant="h1">
					Thank you!
				</Typography>
				<Typography className={classes.headerText} variant="h1">
					Your response has been submitted
				</Typography>
			</div>
		);

	return (
		<Paper className={mobile ? classes.mobileContent : classes.homeRoot}>
			<Typography className={classes.headerText} variant="h1">
				{form.title}
			</Typography>
			<Typography className={classes.smallFormFactorPadding} style={{ marginBottom: 26, marginTop: 6 }} variant="subtitle1">
				{form.description}
			</Typography>
			<Grid container style={{ marginBottom: 20, height: "100%" }}>
				{form.questions.map((question) => {
					return (
						<Grid item xs={12} key={question.id} style={{ display: "flex" }}>
							<AnswerInput
								question={question}
								answer={answers.find((a) => a.questionId === question.id)}
								onAnswerChanged={onAnswerChanged}
								onAnswerDeleted={onAnswerDeleted}
								attendeeId={attendeeId}
								errors={validationErrors}
							/>
						</Grid>
					);
				})}
				<Grid item xs={12} container>
					<GridGrow />
					<Button
						variant="contained"
						size="medium"
						color="primary"
						disabled={disabled}
						onClick={onSubmit}
						className={classes.submit}
					>
						Submit Your Answers
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default FormResponder;
