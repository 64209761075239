import Attendee from "../entities/Attendee";
import { Journey, JourneyCategory, JourneyExtras, JourneyType } from "../entities/Journey";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export type CreateJourneyRequest = {
	name: string;
	type: JourneyType;
	keywordTrigger: string;
	completionResponse: string;
	extras: JourneyExtras;
}

export type UpdateJourneyRequest = {
	journeyId: string;
	churchId: string;
	name: string;
	keywordTrigger: string;
	completionResponse: string;
	enabled?: boolean;
	extras: JourneyExtras;
}

export interface ChangeFollowUpStatusRequest {
	journeyId: string;
	followUpEnabled: boolean;
}

export type SubscribedAttendee = Attendee & { journeyId: string };

export const convertJourney = (serverModel: Journey): Journey => {
	 if (serverModel.category === JourneyCategory.Informational)
	 {
		return {
			...serverModel,
			dateCreated: new Date(serverModel.dateCreated),
			event: serverModel.event ? {
				...serverModel.event,
				originalTime: new Date(serverModel.event.originalTime),
				nextEventTime: new Date(serverModel.event.nextEventTime),
			} :
			undefined
		}
	}
	else
	{
		return {
			...serverModel,
			dateCreated: new Date(serverModel.dateCreated),
		};
	}
}

export default class JourneyService {
	static GetJourneys() {
		return WebClient.Convert.Get<Journey[], Journey[]>(`${apiRoot}/journeys`, j => j.map(convertJourney));
	}

	static GetCollaboratorJourneys() {
		return WebClient.Convert.Get<Journey[], Journey[]>(`${apiRoot}/collaborators/journeys`, j => j.map(convertJourney));
	}

	static GetAdminJourney(type: JourneyType.Connect_Card | JourneyType.Prayer_Requests | JourneyType.Sermon_Prompts) {
		return WebClient.Convert.Get(`${apiRoot}/journeys/type/${type}`, convertJourney);
	}

	static GetJourneySubscribers(journeyId: string) {
		return WebClient.Get<SubscribedAttendee[]>(`${apiRoot}/journeys/${journeyId}/subscribers`);
	}

	static GetJourneyReminderss(journeyId: string) {
		return WebClient.Get<SubscribedAttendee[]>(`${apiRoot}/journeys/${journeyId}/subscribers`);
	}

	public static async Create(request: CreateJourneyRequest) {
		const response = await WebClient.Convert.Post.Validated(`${apiRoot}/journeys`, request, convertJourney);
		return response;
	}

	public static async Disable(request: Journey) {
		const response = await WebClient.Convert.Put.Unvalidated(`${apiRoot}/journeys/disable`, request, convertJourney);
		return response;
	}

	public static async Delete(request: Journey) {
		return WebClient.Delete(`${apiRoot}/journeys/${request.id}`);
	}

	public static async ChangeFollowUpStatus(request: ChangeFollowUpStatusRequest) {
		const response = await WebClient.Convert.Put.Unvalidated(`${apiRoot}/journeys/follow-up-status`, request, convertJourney);
		return response;
	}

	public static async ValidateKeyword(request: { journeyId: string; keyword: string }) {
		return await WebClient.Post.Unvalidated<{ isValid: boolean; message: string }>(`${apiRoot}/journeys/validate`, request);
	}
}
