import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { PhoneMask } from "../../../components/PhoneMask";
import { routes } from "../../../routes";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";

export interface AddNewAttendeeRequest {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
}

interface AddNewAttendeeFormProps {
	disabled: boolean;
	errors: FieldValidationError[];
	onRequestUpdated: (request: AddNewAttendeeRequest) => void;
}

export function AddNewAttendeeForm(props: AddNewAttendeeFormProps) {
	const { disabled, errors } = props;

	const [request, setRequest] = useState<AddNewAttendeeRequest>({
		email: "",
		firstName: "",
		lastName: "",
		phoneNumber: "",
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => props.onRequestUpdated(request), [request]);

	return (
		<Grid container justify="center" spacing={2}>
			<Grid item xs={12}>
				<Typography align="center" variant="body2">
					Enter details to add someone new, or{" "}
					<ClientLink to={routes.app.resolve.uploadCsvPage()}>upload a CSV</ClientLink> file.
				</Typography>{" "}
			</Grid>
			<Grid item md={5} sm={6} xs={6}>
				<TextField
					variant="outlined"
					disabled={disabled}
					label="First Name"
					fullWidth
					required
					type="text"
					value={request.firstName}
					onChange={(e) => setRequest({ ...request, firstName: e.target.value })}
					error={FieldValidationErrorUtils.isFieldInError(errors, "Name")}
					helperText={FieldValidationErrorUtils.isFieldInError(errors, "Name") && "First name is required"}
				/>
			</Grid>
			<Grid item md={5} sm={6} xs={6}>
				<TextField
					variant="outlined"
					disabled={disabled}
					label="Last Name"
					fullWidth
					required
					type="text"
					value={request.lastName}
					onChange={(e) => setRequest({ ...request, lastName: e.target.value })}
					error={FieldValidationErrorUtils.isFieldInError(errors, "Name")}
					helperText={FieldValidationErrorUtils.isFieldInError(errors, "Name") && "Last name is required"}
				/>
			</Grid>
			<Grid item md={5} sm={6} xs={12}>
				<TextField
					variant="outlined"
					disabled={disabled}
					value={request.phoneNumber}
					label="Phone Number"
					onChange={(e) => setRequest({ ...request, phoneNumber: e.target.value })}
					fullWidth
					required
					onFocus={(e) => {
						e.preventDefault();
						const { target } = e;
						target.focus();
						target.setSelectionRange(1, 2);
					}}
					InputProps={{
						inputComponent: PhoneMask,
					}}
					error={
						FieldValidationErrorUtils.isFieldInError(errors, "PhoneNumber") ||
						FieldValidationErrorUtils.isFieldInError(errors, "AttendeeId")
					}
					helperText={
						FieldValidationErrorUtils.getFieldError(errors, "PhoneNumber") ??
						FieldValidationErrorUtils.getFieldError(errors, "AttendeeId")
					}
				/>
			</Grid>
			<Grid item md={5} sm={6} xs={12}>
				<TextField
					variant="outlined"
					disabled={disabled}
					label="Email Address"
					value={request.email}
					onChange={(e) => setRequest({ ...request, email: e.target.value })}
					error={FieldValidationErrorUtils.isFieldInError(errors, "Email")}
					helperText={FieldValidationErrorUtils.getFieldError(errors, "Email")}
					fullWidth
					type="text"
				/>
			</Grid>
		</Grid>
	);
}
