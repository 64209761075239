import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ResponseInputComponent from "../../../components/responseInputComponent";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { CreateGroupRequest, GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useGroups } from "../../../providers/GroupProvider";
import { useGroupRouter } from "../GroupPage";
import { ResponseFab } from "../../../components/ResponseFab";

export const useCreateGroupStyles = makeStyles((theme: Theme) => ({
	cancelButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
    groupNameInput: {
        maxWidth: 350
    },
}));

interface CreateGroupDialogProps {
    open: boolean
}

export default function CreateGroupDialog(props:CreateGroupDialogProps) {
	const theme = useTheme();
    const classes = useCreateGroupStyles();
    const groupsContext = useGroups();
    const groupsRouter = useGroupRouter();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
    const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [isEnabled, setEnabled] = useState<boolean>(true);
	const [showCreateGroup, setShowCreateGroup] = useState<boolean>(props.open);
	const [groupName, setGroupName] = useState<string | undefined>();
    const [groupExists, setGroupExists] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        setError(undefined)
        setGroupExists(false)

        if(groupsContext.groups){
            const existingGroup = groupsContext.groups.find(g => g.name === groupName);
            if(existingGroup){
                setError(`${existingGroup.name} already exists`)
                setGroupExists(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupName])

    useEffect(() => {
        setShowCreateGroup(props.open)
    }, [props.open])

	const onSubmit = async () => {
        if(!groupName || error){
            if(!error){
                setError("Group name cannot be blank")
            }
            return;
        }


        setError(undefined)
        const request:CreateGroupRequest = {name: groupName}
		
        setEnabled(false);
        var result = await GroupService.create(request);
		setEnabled(true);

		if (ServerResult.isSuccess(result)) {
            setSuccessAlert(`${groupName} created`)
            setGroupName(undefined)
            setShowCreateGroup(false)
            groupsContext.create(result.data)
            groupsRouter.selectGroup(result.data)
		} else if (ServerResult.isValidationError(result)) {
			setError(result.errors[0].errors[0]);
		} else {
			setErrorAlert(result);
		}
	};

    if(!showCreateGroup){
        return (
            <ResponseFab onClick={() => setShowCreateGroup(true)} extendedButtonText={"+ New Group"}/>
        );
    }

	return (
		<Dialog open={showCreateGroup} maxWidth={"md"} fullScreen={mobileFormFactor} onBackdropClick={() => setShowCreateGroup(false)}>
			<DialogTitle id="createGroup-dialog-title">Create Group</DialogTitle>
			<DialogContent>
				<DialogContentText>
                    Group Name
				</DialogContentText>
                    <Grid container justify="center" direction="row">
                        <div style={{ width: 350 }}>
                            <ResponseInputComponent
                                errorMessage={error}
                                value={groupName ?? ""}
                                onChange={setGroupName}
                                placeHolder="Group Name"
                                onEnter={onSubmit}
                            />
                        </div>
                    </Grid>
                    <Grid 
                        container 
                        direction="row" 
                        justify="flex-end"
                        alignItems='center'
                        spacing={2} 
                        style={{ padding: "15px 0px" }}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={classes.cancelButton}
                                onClick={() => setShowCreateGroup(false)}
                                disabled={!isEnabled}
                                >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                style={{backgroundColor: theme.palette.secondaryResponse.main}}
                                disabled={!isEnabled || groupExists}
                                onClick={onSubmit}
                                >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
			</DialogContent>
		</Dialog>
	);
}
