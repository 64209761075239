import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const RSVPIndividualResultsStyles = makeStyles((theme: Theme) => ({
	text: {
		color: "#000",
		opacity: 0.7,
		fontSize: 16,
	},
}));

interface Props {
	rsvpCount: number;
}

export function RSVPIndividualResults(props: Props) {
	const classes = RSVPIndividualResultsStyles();
	const { rsvpCount } = props;

	return <Typography className={classes.text}>{rsvpCount}</Typography>;
}
