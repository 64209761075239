import { makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { ResponseDialog } from "../../../components/ResponseDialog/ResponseDialog";
import Attendee from "../../../entities/Attendee";
import { Group } from "../../../entities/Groups/Group";
import { AddToGroupView } from "./AddToGroupView";
import { ConfirmAddToGroup } from "./ConfirmAddToGroup";

export const useInviteStyles = makeStyles((theme: Theme) => ({
	cancelButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
    groupNameInput: {
        maxWidth: 350
    },
}));

interface AddGroupMemberDialogProps {
    selectedGroup: Group;
    open: boolean;
    onClose: () => void
}


export function AddGroupMemberDialog(props:AddGroupMemberDialogProps) {
    const [addedAttendees, setAddedAttendees] = useState<Attendee[] | null>(null);

    const onClose = () => {
        setAddedAttendees(null);
        props.onClose();
    }

    return(
        <ResponseDialog 
            open={props.open} 
            onClose={props.onClose}
            title="Add to Group"
        >
            {
                addedAttendees
                ?
                <ConfirmAddToGroup attendees={addedAttendees} onAddAnother={() => setAddedAttendees(null)} onClose={onClose} />
                :
                <AddToGroupView group={props.selectedGroup} onCompleteAdd={setAddedAttendees} onCancel={onClose} />
            }
            
        </ResponseDialog>
    );
}