import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import Clock from "@material-ui/icons/AccessTime";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReminderDialog from "../../components/ReminderDialog";
import { EventSchedule, InformationalJourney, JourneyCategory, JourneyEvent } from "../../entities/Journey";
import Reminder from "../../entities/Reminder";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import ReminderService from "../../services/ReminderService";
import { ServerResult } from "../../services/server/ServerResult";
import { orderBy } from "../../utillity/orderBy";
import { PersonalizedMessage } from "../../utillity/Personalization";

export const useReminderStyles = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	internalTopPadding: {
		paddingTop: 42,
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	linkText: {
		textDecoration: "underline",
		cursor: "pointer",
		fontWeight: "normal",
	},
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		padding: 8,
		height: 72,
		"& > textarea": {
			height: "100%",
		},
	},
	deleteButton: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main
	}
}));

const ReminderTab = (props: { journey: InformationalJourney }) => {
	const classes = useReminderStyles();
	const setServerErrorAlert = useServerErrorAlert();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const setSuccessAlert = useSuccessAlert();
	const [user] = useAppUser();

	const { journey } = props;

	const [reminders, setReminders] = useState<Reminder[]>([]);
	const [disabled, setDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const [showAddReminder, setShowAddReminder] = useState(false);
	const [reminderInEdit, setReminderInEdit] = useState<Reminder | undefined>(undefined);
	const [showMaxRemindersWarning, setShowMaxRemindersWarning] = useState(false);
	const [userRequestDeletionReminder, setUserRequestDeletionReminder] = useState<Reminder | undefined>(undefined);

	useEffect(() => {
		if (journey.category !== JourneyCategory.Informational) return;

		async function getReminders() {
			const result = await ReminderService.GetReminders(journey.id);
			if (ServerResult.isSuccess(result)) {
				setReminders(result.data);
				setDisabled(false);
				setIsLoading(false);
			} else {
				setServerErrorAlert(result);
			}
		}
		getReminders();
	}, [journey, setServerErrorAlert]);

	const getDateFromTimeFrom = (reminder: Reminder, journeyEvent: JourneyEvent) => {
		let duration = reminder.timeBeforeEvent;
		let reminderDate = reminder.scheduledTime;
		let hours = reminder.scheduledTime.getHours();
		let minutes = reminder.scheduledTime.getMinutes();
		let isPM = false;

		let occurance = "";

		if (journeyEvent.schedule === EventSchedule.BiWeekly || journeyEvent.schedule === EventSchedule.Weekly) {
			const isFutureReminder = journeyEvent.nextEventTime < reminder.scheduledTime;
			occurance = isFutureReminder ? "next event" : "upcoming event";
		}

		if (hours === 12) {
			isPM = true;
		} else if (hours > 12) {
			hours = hours - 12;
			isPM = true;
		}

		return (
			<div>
				<Typography variant="h4" style={{ marginTop: 2 }}>
					{duration.days === 7 && `1 week before ${occurance}`}
					{duration.days < 7 &&
						duration.days > 0 &&
						`${duration.days} day${duration.days > 1 ? "s" : ""} before ${occurance}`}
					{duration.hours > 0 && `${duration.hours} hour${duration.hours > 1 ? "s" : ""} before ${occurance}`}
					{duration.minutes > 0 && `30 mins before ${occurance}`}
				</Typography>
				<Typography variant="subtitle1" style={{ opacity: 0.6, color: "#000" }}>
					Scheduled for {hours}:{minutes < 10 ? "0" : ""}
					{minutes} {isPM ? "pm" : "am"}{" "}
					{`${reminderDate.getMonth() + 1}/${
						reminderDate.getDate() < 10 ? "0" : ""
					}${reminderDate.getDate()}/${reminderDate.getFullYear()}`}
				</Typography>
			</div>
		);
	};

	const editReminder = (reminder: Reminder) => {
		setReminderInEdit(reminder);
		setShowAddReminder(true);
	};

	const submitReminder = async (duration: string, message: string) => {
		setDisabled(true);
		const inEdit = reminderInEdit !== undefined;
		const result = reminderInEdit
			? await ReminderService.UpdateReminder(journey.id, message, duration, reminderInEdit.id)
			: await ReminderService.CreateReminder(journey.id, message, duration);

		setDisabled(false);
		if (ServerResult.isSuccess(result)) {
			let successMessage = inEdit ? "Reminder updated successfully" : "Reminder created successfully";
			if (inEdit && reminders) {
				setReminders(reminders.map((x) => (x.id === result.data.id ? result.data : x)));
			} else {
				setReminders([...reminders, result.data]);
			}
			setSuccessAlert(successMessage);
			setReminderInEdit(undefined);
			setShowAddReminder(false);
		} else if (ServerResult.isValidationError(result)) {
			setServerErrorAlert({ statusCode: 0, message: result.errors[0].errors[0] });
		} else {
			setServerErrorAlert(result);
		}
	};

	const submitDelete = async () => {
		if (!userRequestDeletionReminder) {
			return;
		}
		const id = userRequestDeletionReminder.id;
		const result = await ReminderService.CancelReminder(userRequestDeletionReminder.id);

		setUserRequestDeletionReminder(undefined);
		if (ServerResult.isSuccess(result)) {
			setReminders(reminders.filter((a) => a.id !== id));
			setSuccessAlert("Reminder successfully deleted");
		} else if (ServerResult.isValidationError(result)) {
			setServerErrorAlert({ statusCode: 0, message: result.errors[0].errors[0] });
		} else {
			setServerErrorAlert(result);
		}
	};
	if (!journey.event) {
		return null;
	}

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid className={classNames(classes.internalTopPadding)} item xs={12}>
					<div style={{ display: "flex" }}>
						<Typography style={{ fontWeight: "bold" }} variant="h3">
							Reminders
						</Typography>
						<div className={classes.grow} />
						<Button
							variant="contained"
							style={{ backgroundColor: theme.palette.secondaryResponse.main, color: "#FFF" }}
							onClick={() => (reminders.length === 3 ? setShowMaxRemindersWarning(true) : setShowAddReminder(true))}
						>
							New Reminder
						</Button>
					</div>
					{!mobileFormFactor && <hr className={classes.largeBlueDivider} />}
				</Grid>
				{isLoading ? (
					<Grid item xs={12}>
						<div style={{ textAlign: "center", paddingTop: 40 }}>
							<CircularProgress style={{ color: theme.palette.secondaryResponse.main }} />
						</div>
					</Grid>
				) : (
					reminders.sort(orderBy.date((r) => r.scheduledTime, "Ascending")).map((reminder: Reminder, index: number) => {
						return (
							<React.Fragment key={index}>
								{index !== 0 && (
									<hr
										style={{
											height: 1,
											width: "100%",
											backgroundColor: theme.palette.secondaryResponse.main,
										}}
									/>
								)}
								<Grid item xs={12} key={index} style={{ marginBottom: 16 }}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 12,
											paddingBottom: 6,
										}}
									>
										<Clock style={{ marginRight: 4, color: theme.palette.primaryResponse.main }} />
										{getDateFromTimeFrom(reminder, journey.event!)}
										<div className={classes.grow} />
										<Typography
											onClick={() => (disabled ? null : setUserRequestDeletionReminder(reminder))}
											className={classes.linkText}
											style={{ color: theme.palette.error.main, marginRight: 8 }}
											variant="body1"
										>
											Delete
										</Typography>
										<Typography
											onClick={() => (disabled ? null : editReminder(reminder))}
											className={classes.linkText}
											variant="body1"
										>
											Edit
										</Typography>
									</div>
									<b>Message:</b>
									<Typography variant="subtitle1">
										<PersonalizedMessage
											message={reminder.message}
											journey={journey}
											user={user}
											reminder={reminder}
										/>
									</Typography>
								</Grid>
							</React.Fragment>
						);
					})
				)}
			</Grid>
			{reminders && showAddReminder && (
				<ReminderDialog
					reminders={reminders}
					isVisible={showAddReminder}
					disabled={disabled}
					onClose={() => {
						setShowAddReminder(false);
						setReminderInEdit(undefined);
					}}
					onSubmit={(duration: string, message: string) => submitReminder(duration, message)}
					reminderInEdit={reminderInEdit}
					journey={journey}
					user={user}
				/>
			)}
			<Dialog
				open={showMaxRemindersWarning}
				onClose={() => setShowMaxRemindersWarning(false)}
				fullScreen={mobileFormFactor}
			>
				<DialogTitle id="max-reminder-title">Reminder Limit</DialogTitle>
				<DialogContent>
					You've reached the limit for reminders on this journey, only 3 reminders can be set at a time.
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="default" size="medium" onClick={() => setShowMaxRemindersWarning(false)}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={userRequestDeletionReminder !== undefined}
				onClose={() => setUserRequestDeletionReminder(undefined)}
				fullScreen={mobileFormFactor}
			>
				<DialogTitle id="delete-reminder-title">Delete Reminder</DialogTitle>
				<DialogContent>Are you sure you want to delete this reminder?</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="default"
						size="medium"
						onClick={() => setUserRequestDeletionReminder(undefined)}
						disabled={disabled}
					>
						Cancel
					</Button>
					<Button variant="outlined" className={classes.deleteButton} size="medium" disabled={disabled} onClick={() => submitDelete()}>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ReminderTab;
