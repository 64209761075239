import React from "react";
import { ResponseTheme } from "../ReponseTheme";
import { ServerError } from "../services/server/ServerError";
import { MuiThemeProvider, CssBaseline, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ServerErrorContext } from "../hooks/useServerErrorAlert";
import { SuccessAlertContext } from "../hooks/useSuccessAlert";
import Auth from "../authorization/Auth";
import { UserContext } from "../hooks/useUser";

export function GlobalLayout(props: React.PropsWithChildren<{}>) {
	const [serverError, setServerError] = React.useState<ServerError | undefined>();
	const [successMessage, setSuccessMessage] = React.useState<string | undefined>();
	const [user, setUser] = React.useState(Auth.getJwtUser());

	const setJwtUser = (jwt?: string) => {
		if (jwt) {
			const user = Auth.setToken(jwt);
			setUser(user);
		} else {
			Auth.clearToken();
			setUser(undefined);
		}
	};

	return (
		<MuiThemeProvider theme={ResponseTheme}>
			<CssBaseline />
			<UserContext.Provider value={[user, setJwtUser]}>
				<ServerErrorContext.Provider value={setServerError}>
					<SuccessAlertContext.Provider value={setSuccessMessage}>{props.children}</SuccessAlertContext.Provider>
				</ServerErrorContext.Provider>
			</UserContext.Provider>

			<Snackbar open={serverError != null} autoHideDuration={6000} onClose={() => setServerError(undefined)}>
				<MuiAlert elevation={6} variant="standard" severity="error">
					{serverError?.message}
				</MuiAlert>
			</Snackbar>
			<Snackbar open={successMessage != null} autoHideDuration={6000} onClose={() => setSuccessMessage(undefined)}>
				<MuiAlert elevation={6} variant="standard" severity="success">
					{successMessage}
				</MuiAlert>
			</Snackbar>
		</MuiThemeProvider>
	);
}
