import { Grid, Typography, Button, makeStyles, Theme, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { GridGrow } from "../../../components/GridGrow";
import { routes } from "../../../routes";
import { SendDirectMessageDialog } from "../../Messages/Components/SendDirectMessageDialog";
import { usePeopleRouter } from "../PeoplePage";
import BulkGroupActionDialog from "./BulkGroupActionDIalog";

export function PeopleActions(props: { setWarning: (value: boolean) => void; showWarning: boolean }) {
	const classes = usePeopleStyles();
	const history = useHistory();
	const peopleRouter = usePeopleRouter();
	const { selectedAttendees } = peopleRouter;
	const selectedAttendeesLength = selectedAttendees.length;

	const [showAddToGroup, setShowAddToGroup] = useState(false);
	const [bulkWarningText, setBulkWarningText] = useState<string | null>(null);
	const [showRemoveFromGroup, setShowRemoveFromGroup] = useState(false);
	const [showSendMessageDialog, setShowSendMessageDialog] = useState(false);

	const handleAddToGroup = () => {
		if (selectedAttendeesLength === 0){
			setBulkWarningText("Select people to add to a group");
			return props.setWarning(true);
		}

		setShowAddToGroup(true);
	};

	const handleRemoveFromGroup = () => {
		if (selectedAttendeesLength === 0){
			setBulkWarningText("Select people to remove from a group");
			return props.setWarning(true);
		} 
		setShowRemoveFromGroup(true);
	};

	return (
		<Grid item md={3} className={classes.contentContainer}>
			<Paper className={classes.contentPaper}>
				<Grid container>
					<Grid item style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							Actions
						</Typography>
					</Grid>
					<GridGrow />
					<hr className={classes.largeBlueDivider} />
					{props.showWarning && (
						<Typography variant="body2" className={classes.errorText}>
							{ bulkWarningText }
						</Typography>
					)}
				</Grid>
				<Grid container direction="column" alignContent="center">
				<Button
						className={classes.bigButton}
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => setShowSendMessageDialog(true)}
					>
						Send Text Message
					</Button>
					<Button
						className={classes.bigButton}
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => handleAddToGroup()}
					>
						Add to group ({selectedAttendeesLength})
					</Button>
					<Button
						className={classes.bigButton}
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => handleRemoveFromGroup()}
					>
						Remove from group ({selectedAttendeesLength})
					</Button>
					<Button
						className={classes.bigButton}
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => history.push(routes.app.resolve.uploadCsvPage())}
					>
						Bulk import people
					</Button>
				</Grid>
			</Paper>
			{showAddToGroup && (
				<BulkGroupActionDialog
					action="Add"
					selectedAttendees={selectedAttendees}
					isOpen={showAddToGroup}
					onClose={() => setShowAddToGroup(false)}
				/>
			)}
			{showRemoveFromGroup && (
				<BulkGroupActionDialog
					action="Remove"
					selectedAttendees={selectedAttendees}
					isOpen={showRemoveFromGroup}
					onClose={() => setShowRemoveFromGroup(false)}
				/>
			)}
			<SendDirectMessageDialog key={`SendDirectMessageDialog-${selectedAttendees.length}`} isOpen={showSendMessageDialog} onClose={() => setShowSendMessageDialog(false)} selectedAttendee={selectedAttendees.length > 0 ? selectedAttendees[0] : undefined} />
		</Grid>
	);
}

const usePeopleStyles = makeStyles((theme: Theme) => ({
	bigButton: {
		marginTop: 10,
		marginBottom: 10,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 32,
		paddingRight: 32,
		borderRadius: 14,
		[theme.breakpoints.down("lg")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	contentPaper: {
		paddingLeft: 32,
		paddingRight: 32,
		paddingBottom: 10,
		height: "100%",
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	contentContainer: {
		paddingLeft: 32,
		paddingRight: 32,
		paddingBottom: 10,
		height: "fit-content",
		position: "sticky",
		top: 10,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 10,
			paddingRight: 10,
		},
	},
	errorText: {
		color: theme.palette.error.main,
	},
}));
