import { DialogTitle, DialogContent, DialogActions, Button, useTheme, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { Keyword } from "../../../entities/Keywords/Keyword";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { KeywordService } from "../../../services/KeywordService";
import { useGroupRouter } from "../GroupPage";

interface DeleteKeywordProps {
    isOpen: boolean;
    onClose: () => void;
    keyword: Keyword;
}

export function DeleteKeywordDialog(props: DeleteKeywordProps){
    const { keyword } = props;

    const theme = useTheme();    
    const successAlert = useSuccessAlert();
    const [disabled, setDisabled] = useState(false);
    const groupRouter = useGroupRouter();

    const onDeleteGroupKeyword = async() => {
        setDisabled(true);
        await KeywordService.delete(keyword.id);
        setDisabled(false);
        successAlert(`Deleted keyword`)
        groupRouter.setGroupKeyword(undefined)
    }
    return (
        <Dialog open={props.isOpen} onClose={() => props.onClose()}>
            <DialogTitle id="delete-Group-title">Delete {keyword.displayKeyword} keyword</DialogTitle>
            <DialogContent>Are you sure you want to delete {keyword.displayKeyword} keyword?</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="default"
                    size="medium"
                    onClick={props.onClose}
                    disabled={disabled}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    style={{color: theme.palette.error.main, borderColor: theme.palette.error.main}}
                    size="medium"
                    disabled={disabled}
                    onClick={() => onDeleteGroupKeyword()}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}