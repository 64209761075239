import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	makeStyles,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import FollowedUpIcon from "@material-ui/icons/CheckCircle";
import NotFollowedUpIcon from "@material-ui/icons/RadioButtonUnchecked";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/loaderSpinner";
import ResponseSwitch from "../../components/responseSwitch";
import { Journey } from "../../entities/Journey";
import JourneyResponse from "../../entities/JourneyResponse";
import { buildDateTimeString, SubtractDaysReturnDate } from "../../functions/datetime";
import { formatPhoneNumber } from "../../functions/prettyStrings";
import { useJourneyDetailContext } from "../../hooks/useJourneyDetailContext";
import { useJourneyNotifications } from "../../hooks/useJourneyNotifications";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { routes } from "../../routes";
import JourneyResponseService, { UpdateFollowUpStatusRequest } from "../../services/JourneyResponseService";
import JourneyService, { ChangeFollowUpStatusRequest } from "../../services/JourneyService";
import { ServerResult } from "../../services/server/ServerResult";
import { orderBy } from "../../utillity/orderBy";
import { useJourneyRouter } from "../Journeys/JourneyPage";

export const useStyles = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	input: {
		borderRadius: 8,
		height: 40,
		paddingLeft: 16,
		borderColor: "#D3DCE6",
		color: "#818A91",
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	dateSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
		[theme.breakpoints.down("md")]: {
			width: 240,
		},
	},
	checkboxLabelRoot: {
		marginRight: 0,
	},
	followUpCheckBox: {
		color: theme.palette.secondaryResponse.main,
	},
}));

enum ResponsesDateRange {
	Last_7_Days = 7,
	Last_30_Days = 30,
	All_Time = 0,
}

enum ResponseFilter {
	All = 0,
	Followed_Up = 1,
	Not_Followed_Up = 2,
}

const ResponsesTab = (props: {journey: Journey}) => {
	const { journey } = props;
	const classes = useStyles();
	const history = useHistory();
	const journeyDetailContext = useJourneyDetailContext();
	const journeyRouter = useJourneyRouter();
	const journeyResponses = journeyDetailContext.responses;
	const [isDisabled, setIsDisabled] = journeyDetailContext.disableState;

	const setErrorAlert = useServerErrorAlert();
	const notificationContext = useJourneyNotifications();

	const [dateRange, setDateRange] = useState(ResponsesDateRange.All_Time);
	const [filterByResponses, setFilterByResponses] = useState(ResponseFilter.All);
	const [searchText, startSearchPeople] = useState("");
	const [responses, setResponses] = useState(journeyResponses);

	useEffect(() => {
		const subscription = notificationContext.journeyNotificationSubscriptions.find((s) => s.journeyId === journey.id);
		if (subscription && subscription.unreadCount > 0) {
			notificationContext.markJourneyAsRead(subscription.subscriptionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!journeyResponses) return;
		let filteredResponses = journeyResponses;
		if (dateRange !== ResponsesDateRange.All_Time) {
			let lowerDate =
				dateRange === ResponsesDateRange.Last_7_Days
					? SubtractDaysReturnDate(new Date(Date.now()), 7)
					: SubtractDaysReturnDate(new Date(Date.now()), 30);

			filteredResponses = filteredResponses.filter((response) => response.lastTriggeredTimestampUtc > lowerDate);
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredResponses = filteredResponses.filter((response) =>
				(response.attendeeName + response.attendeePhoneNumber).toLowerCase().includes(queryText)
			);
		}

		if (filterByResponses === ResponseFilter.Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => response.haveFollowedUp);
		} else if (filterByResponses === ResponseFilter.Not_Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => !response.haveFollowedUp);
		}

		setResponses(filteredResponses);
	}, [dateRange, searchText, filterByResponses, journeyResponses]);

	const onJourneyFollowUpToggle = async () => {
		let req: ChangeFollowUpStatusRequest = {
			journeyId: journey.id,
			followUpEnabled: !journey.followUpEnabled,
		};
		setIsDisabled(true);
		let response = await JourneyService.ChangeFollowUpStatus(req);
		setIsDisabled(false);
		if (ServerResult.isSuccess(response)) {
			journeyRouter.updateJourney(response.data);
		} else {
			setErrorAlert(response);
		}
	};

	const onResponseFollowUpToggle = async (journeyResponse: JourneyResponse) => {
		let req: UpdateFollowUpStatusRequest = {
			responseId: journeyResponse.id,
			haveFollowedUp: !journeyResponse.haveFollowedUp,
		};
		setIsDisabled(true);
		let response = await JourneyResponseService.updateFollowUpStatus(req);
		setIsDisabled(false);
		if (ServerResult.isSuccess(response)) {
			journeyDetailContext.updateResponse(response.data);
		} else {
			setErrorAlert(response);
		}
	};

	useEffect(() => {
		if (!journeyResponses) return;

		let filteredResponses = journeyResponses;
		if (dateRange !== ResponsesDateRange.All_Time) {
			let lowerDate =
				dateRange === ResponsesDateRange.Last_7_Days
					? SubtractDaysReturnDate(new Date(Date.now()), 7)
					: SubtractDaysReturnDate(new Date(Date.now()), 30);

			filteredResponses = filteredResponses.filter((response) => response.lastTriggeredTimestampUtc > lowerDate);
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredResponses = filteredResponses.filter((response) =>
				(response.attendeeName + response.attendeePhoneNumber).toLowerCase().includes(queryText)
			);
		}

		if (filterByResponses === ResponseFilter.Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => response.haveFollowedUp);
		} else if (filterByResponses === ResponseFilter.Not_Followed_Up) {
			filteredResponses = filteredResponses.filter((response) => !response.haveFollowedUp);
		}

		filteredResponses = filteredResponses
			.filter((a) => a.messageHistory.some((a) => !a.fromSystem))
			.sort(
				orderBy.date(
					(a) =>
						a.messageHistory
							.sort(orderBy.date((h) => h.timestampUtc, "Ascending"))
							.filter((message) => !message.fromSystem)
							.pop()!.timestampUtc,
					"Descending"
				)
			);

		setResponses(filteredResponses);
	}, [dateRange, searchText, filterByResponses, journeyResponses]);

	const renderLoadingPage = () => {
		return (
			<div style={{ textAlign: "center", marginTop: 80, marginBottom: 50 }}>
				<LoadingSpinner isComplete={false} durationMessage={"Loading..."} />
			</div>
		);
	};

	if (!responses) {
		return renderLoadingPage();
	}

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid item xs={12} style={{ textAlign: "center", marginTop: 12 }}>
					<FormControl>
						<Select
							disableUnderline
							labelId="date-range-select-label"
							id="date-range-select"
							value={dateRange}
							className={classes.dateSelector}
							onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
								setDateRange(parseInt(event.target.value as string))
							}
						>
							<MenuItem value={ResponsesDateRange.Last_7_Days}>Last 7 Days</MenuItem>
							<MenuItem value={ResponsesDateRange.Last_30_Days}>Last 30 Days</MenuItem>
							<MenuItem value={ResponsesDateRange.All_Time}>All Time</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
						{responses.length} Response{responses.length !== 1 && "s"}
					</Typography>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid style={{ marginTop: 16 }} item xs={4}>
					{journey.followUpEnabled && (
						<FormControl>
							<Select
								disableUnderline
								labelId="sort-people-select-label"
								id="sort-people-select"
								value={filterByResponses}
								className={classes.dateSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setFilterByResponses(parseInt(event.target.value as string))
								}
							>
								<MenuItem value={ResponseFilter.All}>All Responses</MenuItem>
								<MenuItem value={ResponseFilter.Followed_Up}>Followed up</MenuItem>
								<MenuItem value={ResponseFilter.Not_Followed_Up}>Not Followed up</MenuItem>
							</Select>
						</FormControl>
					)}
				</Grid>
				<Grid item xs={4} style={{ textAlign: "center" }}>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchPeople(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
					/>
				</Grid>
				<Grid style={{ textAlign: "right" }} item xs={4}>
					<FormControlLabel
						disabled={isDisabled}
						control={
							<ResponseSwitch
								checked={journey.followUpEnabled}
								onChange={onJourneyFollowUpToggle}
								name="disable-enable"
							/>
						}
						label={`Follow-up ${journey.followUpEnabled ? "on" : "off"}`}
						style={{ marginRight: 0 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell align="left">Name</TableCell>
									<TableCell align="left">Response</TableCell>
									<TableCell align="left">Phone</TableCell>
									<TableCell align="left">Date/Time</TableCell>
									{journey.followUpEnabled && <TableCell align="center">Follow Up</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										{responses.length === 0 && (
											<Typography style={{ paddingTop: 24, fontWeight: 400 }} variant="h4">
												Nothing is here...
											</Typography>
										)}
									</TableCell>
									<TableCell />
									<TableCell />
									<TableCell />
									{journey.followUpEnabled && <TableCell />}
								</TableRow>
								{responses.map((response: JourneyResponse, index: number) => {
									return (
										<TableRow key={index}>
											<TableCell>
												{" "}
												<Link
													style={{ color: "rgb(25, 118, 210)" }}
													to={routes.app.resolve.peopleDetailPage(response.attendeeId)}
												>
													{response.attendeeName}
												</Link>
											</TableCell>
											<TableCell>
												<Typography
													onClick={() => history.push(routes.app.resolve.inboxDetailPage(response.attendeeId))}
													variant="body2"
													style={{
														color: "#1976d2",
														textDecoration: "underline",
														cursor: "pointer",
														maxWidth: 120,
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
													}}
												>
													{
														response.messageHistory
															.sort(orderBy.date((h) => h.timestampUtc, "Ascending"))
															.filter((a) => !a.fromSystem)
															.pop()?.text
													}
												</Typography>
											</TableCell>
											<TableCell>{formatPhoneNumber(response.attendeePhoneNumber)}</TableCell>
											<TableCell>
												{buildDateTimeString(
													response.messageHistory
														.sort(orderBy.date((h) => h.timestampUtc, "Ascending"))
														.filter((a) => !a.fromSystem)
														.pop()
														?.timestampUtc.toString() ?? ""
												)}
											</TableCell>
											{journey.followUpEnabled && (
												<TableCell align="center">
													<FormControlLabel
														disabled={isDisabled}
														classes={{
															root: classes.checkboxLabelRoot,
														}}
														control={
															<Checkbox
																icon={<NotFollowedUpIcon />}
																checkedIcon={<FollowedUpIcon />}
																checked={response.haveFollowedUp}
																onChange={() => onResponseFollowUpToggle(response)}
																className={classes.followUpCheckBox}
																color="default"
															/>
														}
														label=""
													/>
												</TableCell>
											)}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ResponsesTab;
