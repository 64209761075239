import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Auth from "../../authorization/Auth";
import { AuthService } from "../AuthService";
import { ServerError } from "./ServerError";
import { ServerResult } from "./ServerResult";
import { ServerSuccess } from "./ServerSuccess";
import { ServerValidationError } from "./ServerValidationError";

type ValidatedRequest<OutputModel> = ServerSuccess<OutputModel> | ServerValidationError;
type UnvalidatedRequest<OutputModel> = ServerSuccess<OutputModel>;

const runAxiosRequest = async <OutputModel, Return extends ValidatedRequest<OutputModel> | UnvalidatedRequest<OutputModel>>(
	url: string,
	config: AxiosRequestConfig
): Promise<ServerError | Return> => {
	try {
		const response = await axios(url, config);
		return response.data as Return;
	} catch (errorResult) {
		const result = await processAxiosError<OutputModel, Return>(errorResult as AxiosError, url, config);
		return result;
	}
}; 

 const processAxiosError = async <OutputModel, Return extends ValidatedRequest<OutputModel> | UnvalidatedRequest<OutputModel>>(
	errorResponse: AxiosError,
	requestUrl: string,
	config: AxiosRequestConfig
): Promise<ServerError | Return> => {
	// Auth is missing or expired
	if (errorResponse.response?.status === 401) {
		const jwt = await AuthService.refreshUser();

		// Retry with new token
		try {
			const response = await axios(requestUrl, {
				...config,
				headers: {
					...config.headers,
					Authorization: `Bearer ${jwt}`,
				},
			});
			return response.data as Return;
		} catch (errorResult) {
			return serializeAxiosError(errorResult as AxiosError);
		}
	}

	return serializeAxiosError(errorResponse);
};

export const serializeAxiosError = (errorResponse: AxiosError): ServerError => {
	let response = errorResponse.response;
	// Happens if the http request gets killed mid request or we're deploying
	if (!response) {
		return {
			message: "The server is down momentarily for an update. Please try again in a few seconds.",
			statusCode: 500,
		};
	}
	if (response.status === 404) {
		return {
			message: "Request was submitted to the incorrect location.",
			statusCode: response.status,
		};
	}
	if (response.status === 401) {
		return { message: "Sign in required", statusCode: response.status };
	}

	let standardServerResponse: ServerError = response.data;
	return { message: standardServerResponse.message, statusCode: response.status };
};

const convertSuccessResult = <ServerModel, OutputModel>(
	success: ServerSuccess<ServerModel>,
	conversion: (serverModel: ServerModel) => OutputModel
): ServerSuccess<OutputModel> => {
	return {
		...success,
		data: conversion(success.data),
	};
};

const getDefaultHeaders = async () => {
	let headers: any = {
		Pragma: "no-cache",
		ResponseClient: window.location.origin,
	};
	const token = Auth.getSavedToken();
	if (token) {
		const jwt = await AuthService.getJwt();
		headers["Authorization"] = `Bearer ${jwt}`;
	}
	return headers;
};

type DefaultServerResult<OutputModel> = ServerError | ServerSuccess<OutputModel>;
export type ValidatedServerResult<OutputModel> = ServerError | ServerSuccess<OutputModel> | ServerValidationError;

export const WebClient = {
	Get: async function <OutputModel>(url: string): Promise<DefaultServerResult<OutputModel>> {
		return runAxiosRequest<OutputModel, UnvalidatedRequest<OutputModel>>(url, {
			method: "get",
			headers: await getDefaultHeaders(),
			withCredentials: true,
		});
	},
	Put: {
		Validated: async function <OutputModel>(url: string, data: any): Promise<ValidatedServerResult<OutputModel>> {
			return runAxiosRequest<OutputModel, ValidatedRequest<OutputModel>>(url, {
				method: "put",
				data: data,
				headers: await getDefaultHeaders(),
				withCredentials: true,
			});
		},
		Unvalidated: async function <OutputModel>(url: string, data: any): Promise<DefaultServerResult<OutputModel>> {
			return runAxiosRequest<OutputModel, UnvalidatedRequest<OutputModel>>(url, {
				method: "put",
				data: data,
				headers: await getDefaultHeaders(),
				withCredentials: true,
			});
		},
	},
	Post: {
		Validated: async function <OutputModel>(url: string, data: any): Promise<ValidatedServerResult<OutputModel>> {
			return runAxiosRequest<OutputModel, ValidatedRequest<OutputModel>>(url, {
				method: "post",
				data: data,
				headers: await getDefaultHeaders(),
				withCredentials: true,
			});
		},
		Unvalidated: async function <OutputModel>(url: string, data: any): Promise<DefaultServerResult<OutputModel>> {
			return runAxiosRequest<OutputModel, UnvalidatedRequest<OutputModel>>(url, {
				method: "post",
				data: data,
				headers: await getDefaultHeaders(),
				withCredentials: true,
			});
		},
		File: async function<OutputModel>(url: string, file: File): Promise<DefaultServerResult<OutputModel>>{
			const formData = new FormData();
			formData.append("file", file, file.name);
			return runAxiosRequest<OutputModel, UnvalidatedRequest<OutputModel>>(url, {
				method: "post",
				data: formData,
				headers: await getDefaultHeaders(),
				withCredentials: true,
			});
		}
	},
	Delete: async (url: string): Promise<DefaultServerResult<unknown>> => {
		return runAxiosRequest(url, {
			method: "delete",
			headers: await getDefaultHeaders(),
		});
	},
	Convert: {
		Get: async function <ServerModel, OutputModel>(
			url: string,
			conversion: (serverModel: ServerModel) => OutputModel
		): Promise<DefaultServerResult<OutputModel>> {
			const result = await WebClient.Get<ServerModel>(url);
			if (ServerResult.isSuccess(result)) {
				return convertSuccessResult(result, conversion);
			}
			return result;
		},
		Put: {
			Validated: async function <ServerModel, OutputModel>(
				url: string,
				data: any,
				conversion: (serverModel: ServerModel) => OutputModel
			): Promise<ValidatedServerResult<OutputModel>> {
				const result = await WebClient.Put.Validated<ServerModel>(url, data);
				if (ServerResult.isSuccess(result)) {
					return convertSuccessResult(result, conversion);
				}
				return result;
			},
			Unvalidated: async function <ServerModel, OutputModel>(
				url: string,
				data: any,
				conversion: (serverModel: ServerModel) => OutputModel
			): Promise<DefaultServerResult<OutputModel>> {
				const result = await WebClient.Put.Unvalidated<ServerModel>(url, data);
				if (ServerResult.isSuccess(result)) {
					return convertSuccessResult(result, conversion);
				}
				return result;
			},
		},
		Post: {
			Validated: async function <ServerModel, OutputModel>(
				url: string,
				data: any,
				conversion: (serverModel: ServerModel) => OutputModel
			): Promise<ValidatedServerResult<OutputModel>> {
				const result = await WebClient.Post.Validated<ServerModel>(url, data);
				if (ServerResult.isSuccess(result)) {
					return convertSuccessResult(result, conversion);
				}
				return result;
			},
			Unvalidated: async function <ServerModel, OutputModel>(
				url: string,
				data: any,
				conversion: (serverModel: ServerModel) => OutputModel
			): Promise<DefaultServerResult<OutputModel>> {
				const result = await WebClient.Post.Unvalidated<ServerModel>(url, data);
				if (ServerResult.isSuccess(result)) {
					return convertSuccessResult(result, conversion);
				}
				return result;
			},
		},
	},
};
