import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ChurchDetails from "../../../entities/AdminTools/ChurchDetails";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import ResponseSubscriptionService from "../../../services/ResponseSubscriptionService";
import { ServerResult } from "../../../services/server/ServerResult";

export function UpdateStripeSubscription(props: { churchDetails: ChurchDetails }){
    const { churchDetails } = props;
    const [subscriptionId, setSubscriptionId] = useState(churchDetails.stripeSubscriptionId)
    const [disabled, setDisabled] = useState(false)

    const setSuccessAlert = useSuccessAlert();

    const submitNewStripeSubscription = async() => {
        if(!subscriptionId)
            return;

        setDisabled(true)
        const response = await ResponseSubscriptionService.changeSubscription(subscriptionId, churchDetails.churchId );
        setDisabled(false)

        if(ServerResult.isSuccess(response))
            setSuccessAlert("Success");
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Change Stripe Subscription</Typography>
                <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/subscriptions">Stripe Dashboard</a>
            </Grid>
            <Grid item xs={12}>
                <TextField disabled={disabled} onChange={(e) => setSubscriptionId(e.target.value)} value={subscriptionId} variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={submitNewStripeSubscription} variant="contained" color="primary" disabled={disabled}>
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}