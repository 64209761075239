import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Group } from "../../../entities/Groups/Group";
import { FieldValidationError } from "../../../services/server/ServerValidationError";
import { AddNewAttendeeForm, AddNewAttendeeRequest } from "./AddNewAttendeeForm";
import { GroupService, AddAttendeesToGroupRequest, AddNewContactToGroupRequest } from "../../../services/GroupService";
import { StringHelper } from "../../../utillity/StringHelper";
import { ServerResult } from "../../../services/server/ServerResult";
import { SelectExistingPeopleForGroup } from "./SelectExistingPeopleForGroup";
import Attendee from "../../../entities/Attendee";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useGroups } from "../../../providers/GroupProvider";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { VerticalCenter } from "../../../components/VerticalCenter";

enum AddPeopleOption {
	InviteNewPeople = 1,
	AddExistingPeople = 2,
}

interface AddToGroupViewProps {
	group: Group;
	onCompleteAdd: (attendees: Attendee[]) => void;
	onCancel: () => void;
}

export function AddToGroupView(props: AddToGroupViewProps) {
	const { group } = props;

	const errorAlert = useServerErrorAlert();
	const classes = useStyles();
	const groupContext = useGroups();
	const attendeeContext = useAttendees();

	const [disabled, setDisabled] = useState(false);
	const [selectedOption, setSelectedOption] = useState<AddPeopleOption | null>(null);
	const [addAttendeeRequest, setAddAttendeeRequest] = useState<AddNewAttendeeRequest | null>(null);
	const [selectedAttendees, setSelectedAttendees] = useState<Attendee[]>([]);
	const [addAttendeeRequestErrors, setAddAttendeeRequestErrors] = useState<FieldValidationError[]>([]);

	const onSelectAttendees = (attendees: Attendee[]) => {
		setSelectedAttendees(attendees);
	};

	const onSubmit = () => {
		if (selectedOption === AddPeopleOption.InviteNewPeople && addAttendeeRequest != null) inviteAttendee(addAttendeeRequest);
		if (selectedOption === AddPeopleOption.AddExistingPeople && selectedAttendees.length !== 0) {
			addAttendeesToGroup(selectedAttendees);
		}
	};

	const inviteAttendee = async (addAttendeeRequest: AddNewAttendeeRequest) => {
		setAddAttendeeRequestErrors([]);
		const request: AddNewContactToGroupRequest = {
			groupId: group.id,
			firstName: StringHelper.toTitleCase(addAttendeeRequest.firstName),
			lastName: StringHelper.toTitleCase(addAttendeeRequest.lastName),
			phoneNumber: `+1${addAttendeeRequest.phoneNumber.replace(/\D/g, "")}`,
			email: addAttendeeRequest.email,
		};

		setDisabled(true);
		const result = await GroupService.addNewContactToGroup(request);
		setDisabled(false);

		if (ServerResult.isValidationError(result)) {
			return setAddAttendeeRequestErrors(result.errors);
		}
		if (ServerResult.isSuccess(result)) {
			groupContext.update(result.data.group);
			attendeeContext.create(result.data.attendee);
			props.onCompleteAdd([result.data.attendee]);
		}
	};

	const addAttendeesToGroup = async (attendeesToAdd: Attendee[]) => {

		const request: AddAttendeesToGroupRequest = { attendeeIds: attendeesToAdd.map(a => a.id), groupId: group.id };
		setDisabled(true);
		var result = await GroupService.addAttendeesToGroup(request);
		setDisabled(false);

		if (ServerResult.isSuccess(result)) {
			groupContext.update(result.data);
			props.onCompleteAdd(attendeesToAdd);
		}

		if (ServerResult.isError(result)) return errorAlert(result);
	};

	return (
		<>
			<ResponseDialogContent>
				<Grid
					container
					direction="column"
					spacing={3}
					justify="center"
					style={{ height: "100%" }}
					wrap="nowrap"
				>
					<Grid item xs={"auto"} style={{ }}>
						<Grid container spacing={3} alignItems="center" justify="center">
							<Grid item xs={12}>
								<Typography
									align="center"
									variant="body2"
									style={{ fontSize: 22, opacity: selectedOption != null ? 0.5 : 1 }}
								>
									Who do you want to add to <strong>{group.name}</strong>?
								</Typography>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									className={classes.bigButton}
									style={{ opacity: selectedOption != null ? 0.5 : 1 }}
									variant={selectedOption === AddPeopleOption.AddExistingPeople ? "outlined" : "contained"}
									onClick={() => setSelectedOption(AddPeopleOption.InviteNewPeople)}
								>
									+ Invite New People
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									className={classes.bigButton}
									style={{ opacity: selectedOption != null ? 0.5 : 1 }}
									variant={selectedOption === AddPeopleOption.InviteNewPeople ? "outlined" : "contained"}
									onClick={() => setSelectedOption(AddPeopleOption.AddExistingPeople)}
								>
									+ Add Existing People
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={selectedOption ? 6 : "auto"} style={{ maxWidth: "unset" }}>
						<VerticalCenter>
							{selectedOption === AddPeopleOption.InviteNewPeople && (
								<AddNewAttendeeForm
									disabled={disabled}
									errors={addAttendeeRequestErrors}
									onRequestUpdated={setAddAttendeeRequest}
								/>
							)}
							{selectedOption === AddPeopleOption.AddExistingPeople && (
								<SelectExistingPeopleForGroup
									disabled={disabled}
									group={group}
									returnSelectedAttendees={onSelectAttendees}
									onNoMoreAttendees={() => setSelectedOption(AddPeopleOption.InviteNewPeople)}
								/>
							)}
						</VerticalCenter>
					</Grid>
				</Grid>
			</ResponseDialogContent>
			<ResponseDialogActions
				disabled={disabled}
				hideNextStep={
					selectedOption === null || (selectedOption === AddPeopleOption.AddExistingPeople && selectedAttendees.length === 0)
				}
				onConfirm={onSubmit}
				hideSecondaryButton={true}
				nextButtonText={selectedOption === AddPeopleOption.InviteNewPeople ? "Send Invite" : "Add To Group"}
				onBack={props.onCancel}
				backButtonText="Cancel"
			/>
		</>
	);
}

export const useStyles = makeStyles((theme: Theme) => ({
	bigButton: { paddingLeft: 65, paddingRight: 65, paddingTop: 15, paddingBottom: 15, borderRadius: 14 },
}));
