import { Checkbox, FormControl, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { v4 as uuid } from "uuid";
import { MultipleAnswer } from "../../../entities/Forms/Answer";
import { MultipleAnswerQuestion, QuestionType } from "../../../entities/Forms/Question";
import { FormError } from "../../../pages/Forms/FormResponder";
import { useAnswerCommonstyles } from "../AnswerInput";

interface MultipleAnswerInputProps {
	question: MultipleAnswerQuestion;
	answer?: MultipleAnswer;
	onAnswerChanged: (answer: MultipleAnswer) => void;
	onAnswerDeleted: (question: MultipleAnswerQuestion) => void;
	attendeeId: string;
	error: FormError[];
}

export function MultipleAnswerInput(props: MultipleAnswerInputProps) {
	const { question, answer, attendeeId, error } = props;
	const classes = useAnswerCommonstyles();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		const checkboxText = e.target.value as string;

		let textAnswers: string[] = answer?.texts ?? [];
		if (checked) {
			textAnswers.push(checkboxText);
		} else if (answer) {
			textAnswers = answer.texts.filter((a) => a !== checkboxText);
		}

		if (textAnswers.length > 0) {
			props.onAnswerChanged({
				id: answer ? answer.id : uuid(),
				questionId: question.id,
				questionType: QuestionType.MultipleAnswer,
				texts: textAnswers,
				attendeeId: attendeeId,
			});
		} else {
			props.onAnswerDeleted(question);
		}
	};

	return (
		<div className={classes.answerContainer}>
			<div>
				<div style={{ display: "flex", width: "100%" }}>
					<span style={{ fontWeight: "bold", fontSize: 16 }}>
						<span className={classes.numberQuestion}>{question.index + 1}.</span>
						<span className={classes.prompt}>{question.prompt}</span>
					</span>
					{question.required && (
						<Typography className={classes.required} variant="subtitle1">
							*Required
						</Typography>
					)}
					<div className={classes.grow} />
					{answer && (
						<Typography
							onClick={() => props.onAnswerDeleted(question)}
							className={classes.resetHeader}
							variant="subtitle1"
						>
							(Reset)
						</Typography>
					)}
				</div>
			</div>
			<Typography className={classes.infoHeader} variant="subtitle1">
				Check all that apply
			</Typography>
			{error.length > 0 && (
				<Typography className={classes.errorMessage} variant="subtitle1">
					{error.map((a) => `${a.error} `.trim())}
				</Typography>
			)}
			<FormControl component="fieldset">
				{question.choices.map((choice, i) => (
					<FormControlLabel
						key={i}
						control={
							<Checkbox
								onChange={onChange}
								disableRipple
								value={choice.text}
								classes={{ root: classes.radio, checked: classes.checked }}
								checked={answer != null && answer.texts.some((val) => val === choice.text)}
							/>
						}
						label={choice.text}
					/>
				))}
			</FormControl>
		</div>
	);
}
