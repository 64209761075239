import { InputAdornment, makeStyles, TextField, Theme } from "@material-ui/core";
import SuccessCheckMark from "@material-ui/icons/Done";
import ErrorMark from "@material-ui/icons/ErrorOutline";
import React from "react";
import { PhoneMask } from "./PhoneMask";

const useStyles = makeStyles((theme: Theme) => ({
	inputComponentContainer: {
		display: "grid",
	},
	inputLayout: {
		borderRadius: 12,
		color: theme.palette.grayTextColor.main,
		fontWeight: 500,
		fontSize: 16,
	},
	submissionResponse: {
		marginLeft: 6,
		fontStyle: "italic",
	},
	submissionResponseSuccess: {
		color: theme.palette.primaryResponse.main,
	},
	submissionResponseError: {
		color: theme.palette.errorResponse.main,
	},
	errorIcon: {
		color: theme.palette.errorResponse.main,
	},
	successIcon: {
		color: theme.palette.primaryResponse.main,
	},
	successOutline: {
		color: theme.palette.primaryResponse.main,
		borderColor: theme.palette.primaryResponse.main,
	},
	errorOutline: {
		color: theme.palette.errorResponse.main,
		borderColor: theme.palette.errorResponse.main,
	},
}));

const ResponseInputComponent: React.SFC<{
	value: string;
	errorMessage?: string;
	successMessage?: string;
	maxLength?: number;
	onChange(text: string): void;
	onEnter?: () => void;
	usePhoneNumberMask?: boolean;
	placeHolder?: string;
	type?: string;
	rowCount?: number;
	label?: string;
	required?: boolean;
	disabled?: boolean;
}> = (props) => {
	const classes = useStyles();
	const { value, errorMessage, successMessage, placeHolder, type, rowCount } = props;

	const inputAdorn = successMessage ? (
		<InputAdornment position="end">
			<SuccessCheckMark className={classes.successIcon} />
		</InputAdornment>
	) : errorMessage ? (
		<InputAdornment position="end">
			<ErrorMark className={classes.errorIcon} />
		</InputAdornment>
	) : undefined;

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if(props.onEnter && e.key === "Enter"){
			props.onEnter();
		}
	}

	const inputOutline = successMessage ? classes.successOutline : errorMessage ? classes.errorOutline : undefined;

	const maskIt = props.usePhoneNumberMask ? PhoneMask : undefined;

	return (
		<div>
			<TextField
				id="outlined-bare"
				defaultValue={value}
				variant="outlined"
				className={classes.inputComponentContainer}
				onChange={(e) => props.onChange(e.currentTarget.value)}
				placeholder={placeHolder}
				type={type}
				InputProps={{
					endAdornment: inputAdorn,
					classes: {
						notchedOutline: inputOutline,
						root: classes.inputLayout,
					},
					inputComponent: maskIt,
					
				}}
				inputProps={{
					maxLength: props.maxLength,
				}}
				onKeyPress={onKeyDown}
				rows={rowCount}
				multiline={rowCount ? true : false}
				label={props.label}
				required={props.required}
				disabled={props.disabled}
			/>
			<div className={classes.submissionResponse}>
				{successMessage && <span className={classes.submissionResponseSuccess}>{successMessage}</span>}
				{errorMessage && <span className={classes.submissionResponseError}>{errorMessage}</span>}
			</div>
		</div>
	);
};

export default ResponseInputComponent;
