import { makeStyles, Theme, Typography } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import InvoiceIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/Person";
import BooksIcon from "@material-ui/icons/LibraryBooks";
import { VerticalCenter } from "../../components/VerticalCenter";
import { PageTitle } from "../../components/PageTitle";

export const useSettingsSelectClasses = makeStyles((theme: Theme) => ({
    icon: {
        maxWidth: 35,
        fontSize: 35,
        color: theme.palette.primaryResponse.main
    },
	selection: {
		width: 460,
		padding: "0 45px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			padding: "0 15px",
		},
	},
	backdrop: {
		flex: 1,
		background: "#696969",
	},
	backdropLayer: {
		height: "100vh",
		opacity: 0.8,
		backgroundColor: "#FFF",
	},
	tile: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		width: "100%",
		height: 70,
		display: "flex",
		padding: 12,
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		marginBottom: 16,
		cursor: "pointer",
	},
	iconBox: {
		width: 54,
		paddingTop: 10,
		paddingLeft: 7,
	},
	seperator: {
		height: 34,
		marginTop: 7,
		width: 2,
		backgroundColor: "rgba(0,0,0, .1)",
	},
	tileText: {
		paddingTop: 15,
		paddingLeft: 8,
		flex: 1,
		whiteSpace: "nowrap",
	},
	arrowBox: {
		width: 30,
		paddingTop: 11,
		color: "#000",
		opacity: 0.3,
	},
}));

export function SettingsSelect() {
	const classes = useSettingsSelectClasses();

	return (
		<VerticalCenter>
			<PageTitle title="Settings"/>
			<div className={classes.selection}>
				<SettingsCardCard route={routes.app.churchInfoPage}/>
				<SettingsCardCard route={routes.app.personalInfoPage}/>
				<SettingsCardCard route={routes.app.subscriptionPage} />
				<SettingsCardCard route={routes.app.paymentMethodPage}/>
				<SettingsCardCard route={routes.app.invoicesPage} />
			</div>
			</VerticalCenter>
	);
}

const SettingsCardCard = (props: { route:string }) => {
    const history = useHistory();
	const classes = useSettingsSelectClasses();
	let iconElement: JSX.Element | undefined;
	let title = "";

	switch (props.route) {
		case routes.app.churchInfoPage:
			iconElement = <img className={classes.icon} alt="icon" src={"https://i.cdn-sc.com/Response/icons/ask-church-icon.svg"} />;
			title = "Church Info";
			break;
        case routes.app.personalInfoPage:
            iconElement = <PeopleIcon className={classes.icon} />;
			title = "Account Info";
			break;
        case routes.app.subscriptionPage:
			iconElement = <BooksIcon className={classes.icon}/>;
			title = "Subscription Info";
			break;
		case routes.app.paymentMethodPage:
			iconElement = <CreditCardIcon className={classes.icon} />
			title = "Payment Methods";
			break;
		case routes.app.invoicesPage:
			iconElement = <InvoiceIcon className={classes.icon}/>;
			title = "Invoices";
		    break;
		default:
			break;
	}

	return (
		<div className={classes.tile} onClick={() => history.push(props.route)}>
			<div className={classes.iconBox}>
                {iconElement}
			</div>
			<div className={classes.seperator} style={{ display: iconElement !== undefined ? "inline" : "none" }} />
			<div className={classes.tileText}>
				<Typography variant="h3">{title}</Typography>
			</div>
			<div className={classes.arrowBox}>
				<ArrowRight />
			</div>
		</div>
	);
};
	