import { Card, CardContent, CardHeader, Grid, List, ListItem, ListSubheader, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../components/LoadingPage";
import ChurchDetails from "../../entities/AdminTools/ChurchDetails";
import { TextMessageType } from "../../entities/Messages/TextMessageType";
import { formatPhoneNumber } from "../../functions/prettyStrings";
import ImpersonationMetricService from "../../services/ImpersonationMetricService";
import { ServerResult } from "../../services/server/ServerResult";
import { Enum } from "../../utillity/Enum";
import { DeleteChurch } from "./Components/DeleteChurch";
import { OptInPendingAttendees } from "./Components/OptInPendingAttendees";
import { UpdateResponsePhoneNumber } from "./Components/UpdateResponsePhoneNumber";
import { UpdateStripeSubscription } from "./Components/UpdateStripeSubscription";

export function AdminToolsDetailPage(props: { churchId: string}){
    const [church, setChurch] = useState<ChurchDetails | null>(null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
		async function loadChurches() {
			const result = await ImpersonationMetricService.GetChurchDetails(props.churchId);
			if (ServerResult.isSuccess(result)){
				setChurch(result.data);
                setLoading(false)
            }
		}
        loadChurches();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    if(loading || !church)
        return <LoadingPage />

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} container spacing={2} direction="column">
                <Grid item>
                    <Card elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CardHeader title="Church Info" style={{ paddingBottom: 0 }}/>
                                <CardContent>
                                    <Typography>Church Name: {church.name}</Typography>
                                    <Typography>Church PhoneNumber: {church.phoneNumber}</Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                        <CardHeader title="Church Admins" style={{ paddingTop: 0 , paddingBottom: 0 }}/>
                        <CardContent>
                            {church.admins.map((a) => 
                                <Typography>Name: {a.name}, PhoneNumber: {formatPhoneNumber(a.phoneNumber!)}</Typography>
                                )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card elevation={2}>
                        <CardHeader title="Campaign Info" style={{ paddingBottom: 0 }}/>
                        <CardContent>
                            <Typography>Campaign Registry CampaignId: {church.responsePhoneNumber.campaignRegistryCampaignId}</Typography>
                            <Typography>Campaign Registry BrandId: {church.responsePhoneNumber.campaignRegistryBrandId}</Typography>
                            <Typography>Signalwire CampaignId: {church.responsePhoneNumber.signalwireCampaignId}</Typography>
                            <Typography>Signalwire BrandId: {church.responsePhoneNumber.signalwireBrandId}</Typography>
                            <Typography>Response PhoneNumber: {formatPhoneNumber(church.responsePhoneNumber.phoneNumber)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} container spacing={3} direction="column">
                <Grid item>
                    <Card elevation={2}>
                        <CardHeader title="Update Church" style={{ paddingBottom: 0 }}/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <UpdateResponsePhoneNumber churchDetails={church}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <UpdateStripeSubscription churchDetails={church} />
                                </Grid>
                                <Grid item xs={12}>
                                    <OptInPendingAttendees churchId={church.churchId}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DeleteChurch churchId={church.churchId} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card elevation={2}>
                        <CardHeader title="Messages - 30 days" style={{ paddingBottom: 0 }}/>
                        <CardContent>
                            <List>
                                <ListSubheader>Total: {church.recentMessageCount}</ListSubheader>
                                {church.recentMessages.map(m => 
                                    <ListItem key={m.messageType}>{Enum.print(TextMessageType ,m.messageType)} - sent: {m.sentCount} - recieved: {m.recievedCount}</ListItem>
                                    )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}