import { Modify } from "../../utillity/modifyType";
import { QuestionMultipleChoice, QuestionSignupListChoice } from "./QuestionChoice";

interface QuestionBase {
	id: string;
	index: number;
	prompt: string;
	required: boolean;
}

export enum QuestionType {
	ShortAnswer = 1,
	SingleAnswer = 2,
	MultipleAnswer = 3,
	SignupList = 4,
	RSVP = 5,
}

export type ShortAnswerQuestion = Modify<
	QuestionBase,
	{
		questionType: QuestionType.ShortAnswer;
	}
>;

export type SingleAnswerQuestion = Modify<
	QuestionBase,
	{
		questionType: QuestionType.SingleAnswer;
		choices: QuestionMultipleChoice[];
	}
>;

export type MultipleAnswerQuestion = Modify<
	QuestionBase,
	{
		questionType: QuestionType.MultipleAnswer;
		choices: QuestionMultipleChoice[];
	}
>;

export type SignupListQuestion = Modify<
	QuestionBase,
	{
		questionType: QuestionType.SignupList;
		choices: QuestionSignupListChoice[];
	}
>;

export type RSVPQuestion = Modify<
	QuestionBase,
	{
		questionType: QuestionType.RSVP;
	}
>;

export type Question =
	| ShortAnswerQuestion
	| SingleAnswerQuestion
	| MultipleAnswerQuestion
	| SignupListQuestion
	| RSVPQuestion;
