import { JourneyType, MessageSchedule, SermonPromptJourney } from "../entities/Journey";
import { UpdateJourneyRequest } from "./JourneyService";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface UpdateSermonPromptJourneyRequest extends UpdateJourneyRequest {
	schedule: MessageSchedule;
}

export const SermonPromptJourneyService = {
    get(){
        return WebClient.Get<SermonPromptJourney>(`${apiRoot}/journeys/type/${JourneyType.Sermon_Prompts}`);
    },
    update(request: UpdateSermonPromptJourneyRequest) {
		return WebClient.Put.Validated<SermonPromptJourney>(`${apiRoot}/journeys/sermonprompt`, request);
	}
}