import { Button, Grid, Hidden, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Auth from "../../authorization/Auth";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationErrorUtils } from "../../services/server/ServerValidationError";

export const useSignInStyles = makeStyles((theme: Theme) => ({
	signInPaper: {
		padding: "0px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			margin: "0px",
			padding: "0px 30px",
			paddingTop: "5%",
			height: "100%",
		},
	},
	grid: {
		[theme.breakpoints.down("xs")]: {
			height: "100%",
		},
	},
	signInButton: {
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	responseBlueText: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
	textButton: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
	link: {
		textDecoration: "none",
	},
}));

const getRedirect = (location: Location) => {
	const urlParams = new URLSearchParams(location.search);
	const redirect = urlParams.get("redirect") ?? routes.app.dashBoardPage;
	return redirect.includes("/app") ? redirect : routes.app.dashBoardPage;
};

export function SignIn() {
	const query = useQueryParams();
	const classes = useSignInStyles();
	const setServerError = useServerErrorAlert();
	const [enabled, setEnabled] = useState(true);

	const [signedIn] = useState(Auth.signedIn());
	const [redirect] = useState(getRedirect(window.location));

	const [email, setEmail] = useState(query.get("emailAddress") ?? "");
	const [emailError, setEmailError] = useState<string | undefined>(query.get("errorMessage") ?? undefined);
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState<string | undefined>();

	const [signInError, setSignInError] = useState<string | undefined>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setEnabled(false);
		const result = await AuthService.signin({
			email,
			password,
		});

		if (ServerResult.isSuccess(result)) {
			window.location.replace(redirect);
		} else if (ServerResult.isValidationError(result)) {
			setEnabled(true);
			setPasswordError(FieldValidationErrorUtils.getFieldError(result.errors, "Password"));
			setEmailError(FieldValidationErrorUtils.getFieldError(result.errors, "Email"));
			setSignInError(FieldValidationErrorUtils.getFieldError(result.errors, "SignIn"));
		} else {
			setEnabled(true);
			setServerError(result);
		}
	};

	const onChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmailError(undefined);
		setEmail(event.target.value);
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setPasswordError(undefined);
		setPassword(event.target.value);
	};

	if (signedIn) {
		return <Redirect to={redirect} />;
	}

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item className={classes.grid}>
				<Paper className={classes.signInPaper}>
					<Grid container direction="column" justify="center" alignItems="center">
						<div style={{ maxWidth: 350 }}>
							<img
								alt="Response"
								src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
								style={{ maxWidth: "100%", height: "auto" }}
							/>
						</div>
						<Typography variant="h5">Sign In</Typography>
					</Grid>
					<form onSubmit={onSubmit}>
						<TextField
							label="Email"
							value={email}
							onChange={onChangeEmail}
							error={emailError != null}
							helperText={emailError}
							margin="normal"
							fullWidth
							required
							type="email"
							disabled={!enabled}
						/>
						<TextField
							label="Password"
							value={password}
							onChange={onChangePassword}
							error={passwordError != null || signInError != null}
							helperText={passwordError ? passwordError : signInError}
							margin="normal"
							fullWidth
							required
							type="password"
							disabled={!enabled}
						/>
						<Hidden smDown>
							<Grid container direction="row" justify="flex-end" alignItems="center">
								<Link to={`${routes.public.forgotPassword}`} className={classes.link}>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.textButton}
									>
										Forgot Password?
									</Button>
								</Link>
							</Grid>
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
								style={{ padding: "30px 0px 70px 0px" }}
							>
								<Link to={`${routes.public.registerPage}`} style={{ textDecoration: "none" }}>
									<Button
										variant="text"
										size="medium"
										color="primary"
										type="button"
										className={classes.responseBlueText}
										style={{ fontWeight: 600 }}
										disabled={!enabled}
									>
										Create Account
									</Button>
								</Link>
								<Button
									variant="contained"
									size="medium"
									color="primary"
									type="submit"
									disabled={!enabled}
									style={{ minWidth: 150 }}
									className={classes.signInButton}
								>
									Sign In
								</Button>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Grid container direction="row" justify="space-between" alignItems="center">
								<Link to={`${routes.public.registerPage}`} style={{ textDecoration: "none" }}>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.responseBlueText}
										style={{ fontWeight: 600 }}
										disabled={!enabled}
									>
										Create Account
									</Button>
								</Link>
								<Link to={`${routes.public.forgotPassword}`} className={classes.link}>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.textButton}
									>
										Forgot Password?
									</Button>
								</Link>
							</Grid>
							<Button
								fullWidth
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								disabled={!enabled}
								style={{ margin: "20px 0px 70px 0px" }}
								className={classes.signInButton}
							>
								Sign In
							</Button>
						</Hidden>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default SignIn;
