import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { MultistepDesktopLayout } from "./MultistepDesktopLayout";
import { MultistepMobileLayout } from "./MultistepMobileLayout";

type Step = {
	onConfirmStep: () => void;
    title: string;
    disabled: boolean;
    backButtonText?: string;
    nextButtonText?: string;
	hideBackStep?: boolean;
}

type SkipableStep = Step & {
    required: false;
	skip: boolean;
	onSkip: () => void;
};

type RequiredStep = Step & {
    required: true;
    hideNextStep: boolean
}

export type MultistepDialogStepLayoutProps = RequiredStep | SkipableStep;

export function MultistepDialogStepLayout(props: PropsWithChildren<MultistepDialogStepLayoutProps>){
    const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return <MultistepMobileLayout {...props} />;
	}
	return <MultistepDesktopLayout {...props} />;
}

export const useCommonLayoutStyle = makeStyles((theme: Theme) => ({
	titlebartitle: {
		color: "#FFF",
		width: "100%",
	},
	titleCloseIcon: {
		float: "right",
	},
	grow: {
		flex: 1,
	},
	actionButtons: {
		borderRadius: 8,
		height: 36,
		margin: 9,
		marginBottom: 14,
	},
	backButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
	nextButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
	},
}));