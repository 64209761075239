import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, makeStyles, MenuItem, Select, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { EventSchedule, InformationalJourney } from "../../../entities/Journey";
import { AddDaysReturnDate } from "../../../functions/datetime";
import { useAppUser } from "../../../hooks/useAppUser";
import { FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { FieldErrorStrings } from "../journeySetUp";
import { JourneyStepLayout, useJourneyStepStyles } from "../Steps/JourneyStepLayout";
import { TwelveHour } from "../Steps/SermonPromptScheduleStep";
import { useJourneySetupProgress } from "../useJourneySetupProgress";
import { AddMessageExtraLayout } from "./AddMessageExtraLayout";

const useStyles = makeStyles((theme: Theme) => ({
	hours: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderRight: "none",
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
	},
	minutes: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderLeft: "none",
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
		"& > div:focus": {
			backgroundColor: "#FFF",
		},
	},
	twelveHour: {
		"& > svg": {
			display: "none",
		},
		"& > fieldset": {
			borderColor: "rgba(0,0,0,.23) !important",
			borderWidth: "1px !important",
		},
		"& > div:focus": {
			backgroundColor: "#FFF",
		},
	},
	niceInputText: {
		color: "rgb(129, 138, 145)",
		fontSize: 20,
	},
	minutesInput: {
		paddingLeft: "9px !important",
		paddingRight: "18px !important",
		paddingTop: "23px !important",
		paddingBottom: "14px !important",
	},
	hoursInput: {
		paddingLeft: "18px !important",
		paddingRight: "9px !important",
		paddingTop: "23px !important",
		paddingBottom: "14px !important",
	},
	twelveHourInput: {
		paddingRight: "15px !important",
		paddingTop: "23px !important",
		paddingBottom: "14px !important",
	},
	colonSpan: {
		borderTop: "1px solid rgba(0,0,0,.23)",
		borderBottom: "1px solid rgba(0,0,0,.23)",
		fontSize: 27,
		paddingTop: 9,
		color: "rgb(129, 138, 145)",
		height: 61,
	},
	blueStyleText: {
		fontWeight: 500,
		color: theme.palette.secondaryResponse.main,
		fontSize: 16,
	},
	repeatButton: {
		padding: 16,
		borderRadius: 20,
		"&:hover": {
			backgroundColor: "rgba(0,0,0,.1)",
		},
	},
	selectedEventSchedule: {
		backgroundColor: "rgba(0,0,0,.2)",
	},
}));

type StepProps = {
	mode: "step";
	journey: InformationalJourney;
};

type AddProps = {
	mode: "add";
	journey: InformationalJourney;
	title: string;
	onCancel: () => void;
	onUpdate: (updatedJourney: InformationalJourney) => void;
};

type Props = StepProps | AddProps;

export function MessageEventCalendarView(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useStyles() };
	const progress = useJourneySetupProgress();
	const [user] = useAppUser();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const { journey } = props;

	let eventDate = AddDaysReturnDate(new Date(), 1);
	let setupHours = "09";
	let mins = "00";
	let defaultTwelveHour: TwelveHour = TwelveHour.AM;

	if (journey.event) {
		eventDate = journey.event.originalTime;

		let hr = journey.event.originalTime.getHours();

		defaultTwelveHour = hr < 12 ? TwelveHour.AM : TwelveHour.PM;

		if (hr > 12) {
			hr = hr % 12;
		}

		setupHours = hr.toString();

		if (setupHours.length === 1) {
			setupHours = "0" + setupHours;
		}

		mins = journey.event!.originalTime.getMinutes().toString();

		if (mins.length === 1) {
			mins = "0" + mins;
		}
	}

	const [twelveHour, setTwelveHour] = useState(defaultTwelveHour);
	const [scheduledDate, setScheduledDate] = useState<Date | null>(eventDate);
	const [minutes, setMinutes] = useState(mins);
	const [hours, setHours] = useState(setupHours);
	const [invalidDate, setInvalidDate] = useState<string | undefined>(undefined);
	const [eventSchedule, setEventSchedule] = useState(
		"event" in journey && journey.event ? journey.event.schedule : EventSchedule.DoesNotRepeat
	);

	useEffect(() => {
		function checkErrors() {
			if (FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.Event)) {
				setInvalidDate(FieldValidationErrorUtils.getFieldErrorSummary(progress.fieldErrors, FieldErrorStrings.Event));
			}
		}
		checkErrors();
	}, [progress.fieldErrors]);

	const handleDateChange = (date: Date | null) => {
		if (date != null) {
			if (date.getTime() < new Date().getTime()) {
				setInvalidDate("Date is in the past.");
			} else {
				setInvalidDate(undefined);
			}
			setScheduledDate(date);
		}
	};

	const handleSetupEvent = () => {
		if (scheduledDate == null || invalidDate !== undefined) {
			setInvalidDate("Date is invalid, please enter a date in the future. ");
			return;
		}

		let hoursNumber = +hours;

		if (twelveHour === TwelveHour.PM && hoursNumber !== 12) {
			hoursNumber = +hours + 12;
		}

		let buildDate = new Date(
			scheduledDate.getFullYear(),
			scheduledDate.getMonth(),
			scheduledDate.getDate(),
			hoursNumber,
			+minutes
		);

		if (FieldValidationErrorUtils.isFieldInError(progress.fieldErrors, FieldErrorStrings.Event)) {
			progress.setFieldErrors(progress.fieldErrors.filter((a) => a.field !== FieldErrorStrings.Event));
		}

		const updatedJourney: InformationalJourney = {
			...props.journey,
			event: { originalTime: buildDate, nextEventTime: buildDate, schedule: eventSchedule },
		};

		const dateChanged = journey.event && journey.event.originalTime.getTime() !== buildDate.getTime();
		if (!dateChanged && journey.event) {
			updatedJourney.event!.nextEventTime = journey.event.nextEventTime;
		}

		if (props.mode === "add") {
			props.onUpdate(updatedJourney);
		} else {
			progress.completeStep(updatedJourney);
		}
	};

	const view = () => {
		return (
			<Grid item xs={12}>
				<div style={{ width: mobileFormFactor ? "auto" : 400, margin: "0 auto" }}>
					<Typography className={classes.progressLabelText} variant="subtitle2">
						When is this event?
					</Typography>
					{!mobileFormFactor && (
						<div style={{ display: "flex" }}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									inputVariant="outlined"
									format="MM/dd/yyyy"
									margin="normal"
									id="date-picker-inline"
									value={scheduledDate}
									onChange={handleDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
									error={invalidDate !== undefined}
									helperText={invalidDate}
									style={{ width: 200, marginRight: 16 }}
									InputAdornmentProps={{ position: "start" }}
									InputProps={{
										classes: {
											root: classes.niceInputText,
										},
									}}
								/>
							</MuiPickersUtilsProvider>
							<FormControl style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
								<FormControl variant="outlined">
									<Select
										className={classes.hours}
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={hours}
										onChange={(e) => setHours(e.target.value as string)}
										inputProps={{
											classes: {
												root: classNames(classes.niceInputText, classes.hoursInput),
											},
										}}
									>
										<MenuItem value={"12"}>12</MenuItem>
										<MenuItem value={"01"}>01</MenuItem>
										<MenuItem value={"02"}>02</MenuItem>
										<MenuItem value={"03"}>03</MenuItem>
										<MenuItem value={"04"}>04</MenuItem>
										<MenuItem value={"05"}>05</MenuItem>
										<MenuItem value={"06"}>06</MenuItem>
										<MenuItem value={"07"}>07</MenuItem>
										<MenuItem value={"08"}>08</MenuItem>
										<MenuItem value={"09"}>09</MenuItem>
										<MenuItem value={"10"}>10</MenuItem>
										<MenuItem value={"11"}>11</MenuItem>
									</Select>
								</FormControl>
								<span className={classes.colonSpan}>:</span>
								<FormControl variant="outlined">
									<Select
										className={classes.minutes}
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={minutes}
										onChange={(e) => setMinutes(e.target.value as string)}
										inputProps={{
											classes: {
												root: classNames(classes.niceInputText, classes.minutesInput),
											},
										}}
									>
										<MenuItem value={"00"}>00</MenuItem>
										<MenuItem value={"15"}>15</MenuItem>
										<MenuItem value={"30"}>30</MenuItem>
										<MenuItem value={"45"}>45</MenuItem>
									</Select>
								</FormControl>
								<FormControl variant="outlined" style={{ marginLeft: 12 }}>
									<Select
										className={classes.twelveHour}
										labelId="ampm-select-outlined-label"
										id="ampm-select-outlined"
										value={twelveHour}
										onChange={(e) => setTwelveHour(+(e.target.value as string))}
										inputProps={{
											classes: {
												root: classNames(classes.niceInputText, classes.twelveHourInput),
											},
										}}
									>
										<MenuItem value={TwelveHour.AM}>AM</MenuItem>
										<MenuItem value={TwelveHour.PM}>PM</MenuItem>
									</Select>
								</FormControl>
							</FormControl>
						</div>
					)}
					{mobileFormFactor && (
						<Grid container>
							<Grid item xs={12}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										inputVariant="outlined"
										format="MM/dd/yyyy"
										margin="normal"
										id="date-picker-inline"
										value={scheduledDate}
										onChange={handleDateChange}
										KeyboardButtonProps={{
											"aria-label": "change date",
										}}
										error={invalidDate !== undefined}
										helperText={invalidDate}
										style={{ width: 200, marginRight: 16 }}
										InputAdornmentProps={{ position: "start" }}
										InputProps={{
											classes: {
												root: classes.niceInputText,
											},
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12}>
								<div style={{ display: "flex", marginTop: -12 }}>
									<FormControl style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
										<FormControl variant="outlined" style={{ height: 60 }}>
											<Select
												className={classes.hours}
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												value={hours}
												onChange={(e) => setHours(e.target.value as string)}
												inputProps={{
													classes: {
														root: classNames(classes.niceInputText, classes.hoursInput),
													},
												}}
											>
												<MenuItem value={"12"}>12</MenuItem>
												<MenuItem value={"01"}>01</MenuItem>
												<MenuItem value={"02"}>02</MenuItem>
												<MenuItem value={"03"}>03</MenuItem>
												<MenuItem value={"04"}>04</MenuItem>
												<MenuItem value={"05"}>05</MenuItem>
												<MenuItem value={"06"}>06</MenuItem>
												<MenuItem value={"07"}>07</MenuItem>
												<MenuItem value={"08"}>08</MenuItem>
												<MenuItem value={"09"}>09</MenuItem>
												<MenuItem value={"10"}>10</MenuItem>
												<MenuItem value={"11"}>11</MenuItem>
											</Select>
										</FormControl>
										<span className={classes.colonSpan}>:</span>
										<FormControl variant="outlined" style={{ height: 60 }}>
											<Select
												className={classes.minutes}
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												value={minutes}
												onChange={(e) => setMinutes(e.target.value as string)}
												inputProps={{
													classes: {
														root: classNames(classes.niceInputText, classes.minutesInput),
													},
												}}
											>
												<MenuItem value={"00"}>00</MenuItem>
												<MenuItem value={"15"}>15</MenuItem>
												<MenuItem value={"30"}>30</MenuItem>
												<MenuItem value={"45"}>45</MenuItem>
											</Select>
										</FormControl>
										<FormControl variant="outlined" style={{ marginLeft: 12 }}>
											<Select
												className={classes.twelveHour}
												labelId="ampm-select-outlined-label"
												id="ampm-select-outlined"
												value={twelveHour}
												onChange={(e) => setTwelveHour(+(e.target.value as string))}
												inputProps={{
													classes: {
														root: classNames(classes.niceInputText, classes.twelveHourInput),
													},
												}}
											>
												<MenuItem value={TwelveHour.AM}>AM</MenuItem>
												<MenuItem value={TwelveHour.PM}>PM</MenuItem>
											</Select>
										</FormControl>
									</FormControl>
								</div>
							</Grid>
						</Grid>
					)}

					<Typography variant="subtitle1" style={{ fontSize: 14, opacity: 0.8, marginTop: mobileFormFactor ? -4 : -8 }}>
						Note: all times displayed are in {user.tzdbTimeZone}
					</Typography>
					<div style={{ display: "flex", marginTop: 10 }}>
						<Typography variant="h2" style={{ marginRight: 8, paddingTop: 16 }} className={classes.blueStyleText}>
							Repeats:
						</Typography>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								className={classNames(
									classes.repeatButton,
									eventSchedule === EventSchedule.DoesNotRepeat ? classes.selectedEventSchedule : undefined
								)}
								onClick={() => setEventSchedule(EventSchedule.DoesNotRepeat)}
							>
								<Typography variant="h2" className={classes.blueStyleText}>
									Does not repeat
								</Typography>
							</div>
							<div
								className={classNames(
									classes.repeatButton,
									eventSchedule === EventSchedule.Weekly ? classes.selectedEventSchedule : undefined
								)}
								onClick={() => setEventSchedule(EventSchedule.Weekly)}
							>
								<Typography variant="h2" className={classes.blueStyleText}>
									Every week
								</Typography>
							</div>
							<div
								className={classNames(
									classes.repeatButton,
									eventSchedule === EventSchedule.BiWeekly ? classes.selectedEventSchedule : undefined
								)}
								onClick={() => setEventSchedule(EventSchedule.BiWeekly)}
							>
								<Typography variant="h2" className={classes.blueStyleText}>
									Every two weeks
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</Grid>
		);
	};

	if (props.mode === "add") {
		return (
			<AddMessageExtraLayout title={props.title} onCancel={props.onCancel} onSave={handleSetupEvent}>
				{view()}
			</AddMessageExtraLayout>
		);
	}

	return (
		<JourneyStepLayout required={true} onConfirmStep={handleSetupEvent}>
			{view()}
		</JourneyStepLayout>
	);
}
