import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChurchInfo from "../../pages/Settings/ChurchInfo";
import Invoices from "../../pages/Settings/Invoices";
import PaymentMethod from "../../pages/Settings/PaymentMethod";
import PersonalInfo from "../../pages/Settings/PersonalInfo";
import {SettingsSelect} from "../../pages/Settings/SettingsSelect";
import Subscription from "../../pages/Settings/Subscription";
import FormDigest from "../../pages/Forms/FormDigest";
import { Notifications } from "../../pages/Dashboard/Notifications";
import { CreateJourney } from "../../pages/Journeys/CreateJourney";
import JourneyListPage from "../../pages/Journeys/JourneyListPage";
import { routes } from "../../routes";
import { GroupPage } from "../../pages/Groups/GroupPage";
import { PeoplePage } from "../../pages/People/PeoplePage";
import { UploadCsvPage } from "../../pages/Settings/UploadCsvPage";
import { JourneyPage } from "../../pages/Journeys/JourneyPage";
import { MessagesPage } from "../../pages/Messages/MessagePage";
import { DashBoardPage } from "../../pages/Dashboard/DashboardPage";
import { useAppUser } from "../../hooks/useAppUser";
import { Role } from "../../entities/Role";
import { AdminToolsPage } from "../../pages/AdminTools/AdminToolsPage";

export function AppRoutes(props: React.PropsWithChildren<{}>) {
	const [user] = useAppUser()
	return (
		<Switch>
			<Route path={routes.app.acceptCollaboratorInvite}>
				<JourneyListPage />
			</Route>
			<Route exact path={[routes.app.messagesPage, routes.app.sendMessagePage, routes.app.inboxPage, routes.app.inboxDetailPage, routes.app.groupMessagePage, routes.app.groupMessageDetailPage]}>
				<MessagesPage />
			</Route> 
			<Route exact path={[routes.app.groupDetailPage, routes.app.groupListPage, routes.app.createGroupPage]}>
				<GroupPage />
			</Route> 
			<Route exact path={routes.app.journeyCreatePage}>
				<CreateJourney />
			</Route>
			<Route exact path={[routes.app.journeyListPage, routes.app.journeyDetailPage, routes.app.connectCardsPage, routes.app.prayerRequestsPage, routes.app.sermonPromptsPage]}>
				<JourneyPage />
			</Route>
			<Route exact path={[routes.app.peopleListPage, routes.app.invitePersonPage, routes.app.peopleDetailPage]}>
				<PeoplePage />
			</Route>
			<Route exact path={routes.app.settingsPage}>
				<ChurchInfo />
			</Route>
			<Route exact path={routes.app.settingsSelectPage}>
				<SettingsSelect />
			</Route>
			<Route exact path={routes.app.churchInfoPage}>
				<ChurchInfo />
			</Route>
			<Route exact path={routes.app.personalInfoPage}>
				<PersonalInfo />
			</Route>
			<Route exact path={routes.app.subscriptionPage}>
				<Subscription />
			</Route>
			<Route exact path={routes.app.paymentMethodPage}>
				<PaymentMethod />
			</Route>
			<Route exact path={routes.app.invoicesPage}>
				<Invoices />
			</Route>
			<Route exact path={routes.app.dashBoardPage}>
				<DashBoardPage />
			</Route>
			<Route exact path={routes.app.notifications}>
				<Notifications />
			</Route>
			<Route exact path={routes.app.formResultsPage}>
				<FormDigest />
			</Route>
			<Route exact path={routes.app.uploadCsvPage}>
				<UploadCsvPage/>
			</Route>
			{
				user.role === Role.UberAdmin
				&&
				<Route exact path={[routes.app.impersonateChurch, routes.app.AdminToolsDetailPage]}>
					<AdminToolsPage />
				</Route>
			}
            {props.children}
			<Redirect to={routes.app.dashBoardPage} />
        </Switch>
	);
}
