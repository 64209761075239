import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { MultistepDialogStepLayout } from "../../../components/MultistepDialog/Layouts/MultistepDialogStepLayout";
import { VerificationCodeInput } from "../../../components/VerificationCodeInput";
import { VerticalCenter } from "../../../components/VerticalCenter";
import { useAppUser } from "../../../hooks/useAppUser";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { AuthService } from "../../../services/AuthService";
import { RegistrationService } from "../../../services/RegistrationService";
import { ServerResult } from "../../../services/server/ServerResult";
import { ButtonLink } from "../../Groups/ButtonLink";
import { FieldValidationError, FieldValidationErrorUtils } from "../../../services/server/ServerValidationError";
import { useDialogSteps } from "../../../components/MultistepDialog/CurrentDialogStep";
import { PhoneTextField } from "../../../components/PhoneTextField";

interface VerifyTokenProps {}

export function VerifyToken(props: VerifyTokenProps) {
	const dialogContext = useDialogSteps();
	const [jwtUser, setJwt] = useAppUser();
	const setServerError = useServerErrorAlert();

	const [verificationCode, setVerificationCode] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>(jwtUser.phoneNumber?.replace(/\D/g, "").slice(1) ?? "");
	const [hasError, setHasError] = useState(false);
	const [errors, setErrors] = useState<FieldValidationError[]>([]);

	const [disabled, setDisabled] = useState(false);
	const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);

	const verifyCode = async () => {
		if (verificationCode.length !== 6 || isNaN(Number(verificationCode))) return;

		setHasError(false);
		setDisabled(true);
		const response = await RegistrationService.verifyPhone(verificationCode);
		setDisabled(false);

		if (ServerResult.isError(response)) return setServerError(response);

		if (ServerResult.isValidationError(response)) return setHasError(true);

		const refreshResult = await AuthService.refreshUser();

		if (ServerResult.isSuccess(refreshResult)) setJwt(refreshResult.data.jwt);

		dialogContext.nextStep();
	};

	const handlePhoneNumberChange = async () => {
		if (/\D/gi.test(phoneNumber) || phoneNumber.length !== 10)
			return setErrors([{ field: "phoneNumber", errors: ["Invalid phone number"] }]);

		setErrors([]);
		setDisabled(true);
		const result = await RegistrationService.setUserPhone(phoneNumber);
		setDisabled(false);

		if (ServerResult.isValidationError(result)) setErrors(result.errors);

		if (ServerResult.isError(result)) setServerError(result);

		setShowPhoneNumberInput(false);
	};

	return (
		<MultistepDialogStepLayout
			title="Verification"
			required={true}
			hideNextStep={false}
			hideBackStep={true}
			disabled={disabled}
			onConfirmStep={() => (showPhoneNumberInput ? handlePhoneNumberChange() : verifyCode())}
			nextButtonText={showPhoneNumberInput ? "Send" : "Submit"}
		>
			<VerticalCenter>
				<Grid container justify="center" alignContent="center" spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6" align="center">
							Verify your phone number
						</Typography>
					</Grid>
					<Grid item sm={8} xs={12} style={{ textAlign: "center" }}>
						{showPhoneNumberInput ? (
							<>
								<Typography align="center" variant="h4" style={{ marginBottom: 16, marginTop: 44 }}>
									Your personal mobile number:
								</Typography>
								<PhoneTextField
									style={{ width: "50%" }}
									variant="outlined"
									disabled={disabled}
									value={phoneNumber}
									onChangePhoneNumber={(value) => setPhoneNumber(value)}
									margin="normal"
									required
									type="text"
									error={FieldValidationErrorUtils.isFieldInError(errors, "PhoneNumber")}
									helperText={FieldValidationErrorUtils.getFieldErrorSummary(errors, "PhoneNumber")}
								/>
							</>
						) : (
							<>
								<Typography align="center" variant="h4" style={{ marginBottom: 16, marginTop: 44 }}>
									Please enter the code from your phone.
								</Typography>
								<VerificationCodeInput
									onInputChange={(v) => setVerificationCode(v)}
									hasError={hasError}
									disabled={disabled}
								/>
							</>
						)}
					</Grid>
					<Grid item sm={8} xs={12} style={{ textAlign: "center" }}>
						<ButtonLink onClick={() => setShowPhoneNumberInput(!showPhoneNumberInput)}>
							{showPhoneNumberInput ? "Input verification code" : "Try a different number"}
						</ButtonLink>
					</Grid>
				</Grid>
			</VerticalCenter>
		</MultistepDialogStepLayout>
	);
}
