import React from "react";
import { Journey } from "../entities/Journey";

interface Context {
    journeys: Journey[];
    updateJourney: (journey: Journey) => void; 
    addJourney: (journey: Journey) => void; 
    removeJourney: (journey: Journey) => void;
}

const journeyReactContext = React.createContext<Context>({} as any);

export function useJourneys() {
	return React.useContext(journeyReactContext);
}

export function JourneyProvider(props: React.PropsWithChildren<{ journeyState: [Journey[], (journeys: Journey[]) => void] }>) {
	const [journeys, setJourneys] = props.journeyState;

	const updateJourney = (journey: Journey) => {
		setJourneys(journeys.map(j => j.id === journey.id ? journey : j));
	}

	const addJourney = (journey: Journey) => setJourneys([...journeys, journey]);

	const removeJourney = (journey: Journey) => setJourneys(journeys.filter(j => j.id !== journey.id));

	return (
		<journeyReactContext.Provider
			value={{
				journeys,
				updateJourney,
				addJourney,
				removeJourney
			}}
		>
			{props.children}
		</journeyReactContext.Provider>
	);
}
