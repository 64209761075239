import { makeStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Group } from "../../../entities/Groups/Group";

interface GroupMultiSelectProps {
    groups: Group[];
    placeHolder?: string;
    style?: React.CSSProperties;
    onSelectedGroupsChange: (selectedGroups:Group[]) => void
}

export default function GroupMultiSelect(props: GroupMultiSelectProps) {
    const classes = useStyles();
	const filter = createFilterOptions<Group>();
	
    const [groupOptions] = useState<Group[]>(props.groups);
	const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.onSelectedGroupsChange(selectedGroups), [selectedGroups])

	return (
        <Autocomplete
            multiple
            value={selectedGroups}
            options={groupOptions}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(_, value) => setSelectedGroups(value)}
            autoHighlight
            clearOnBlur
            clearOnEscape
            handleHomeEndKeys
            filterSelectedOptions
            fullWidth
            style={{minHeight: 60, ...props.style}}
            classes={{tag: classes.chipStyle }}
            noOptionsText="No more groups to add"
            filterOptions={(options, params) => {
                return filter(options, params);
            }}
            getOptionLabel={(option: Group) => {
                return option.name;
            }}
            renderOption={(option: Group) => {
                return option.name;
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={ props.placeHolder ?? "Select group"} />}
            />
	);
}

const useStyles = makeStyles((theme: Theme) => ({
    chipStyle: {
        width: 150
    }
}));