import { makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Answer, RSVPAnswer } from "../../../../entities/Forms/Answer";

const RSVPOverviewDigestStyles = makeStyles((theme: Theme) => ({
	prompt: {
		fontSize: 16,
		color: theme.palette.secondaryResponse.main,
		fontWeight: 400,
	},
	grouping: {
		fontSize: 16,
		marginRight: 4,
	},
}));

interface Props {
	answers: Answer[];
}

export function RSVPOverviewDigest(props: Props) {
	const classes = RSVPOverviewDigestStyles();

	const [data, setData] = useState<number | undefined>(0);

	useEffect(() => {
		function setUpData() {
			let totalRSVP = 0;

			props.answers.forEach((answer) => {
				let rsvpAnswer = answer as RSVPAnswer;

				totalRSVP += rsvpAnswer.attendeeCount;
			});

			setData(totalRSVP);
		}

		setUpData();
	}, [props]);

	return (
		<div style={{ width: "100%", display: "flex", marginTop: 8 }}>
			<Typography className={classes.grouping}>Total RSVP'd:</Typography>
			<Typography className={classes.prompt}>{data}</Typography>
		</div>
	);
}
