import React, { useEffect, useState } from "react";
import { JourneyNotificationsContext, JourneyNotificationSubscription } from "../hooks/useJourneyNotifications";
import { JourneyNotificationSubscriptionService } from "../services/JourneyNotificationSubscriptionService";
import { ServerResult } from "../services/server/ServerResult";
import { useSuccessAlert } from "../hooks/useSuccessAlert";
import { useServerErrorAlert } from "../hooks/useServerErrorAlert";
import { Modify } from "../utillity/modifyType";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router";
import { routes } from "../routes";
import { Journey } from "../entities/Journey";
import { useHubEvent } from "../hooks/useHubEvent";
import { useHub } from "../hooks/useHub";
import { useHubSubscription } from "../hooks/useHubSubscription";
import { useAttendees } from "./AttendeeProvider";
import { InboxNotificationEvent } from "../entities/Notifications/InboxNotificationEvent";
import { JourneyCompletionEvent } from "../entities/Notifications/JourneyCompletionEvent";

export type ServerJourneyNotificationSubscription = Modify<
	JourneyNotificationSubscription,
	{
		lastMessageReceivedTime?: string;
	}
>;

const convert = (model: ServerJourneyNotificationSubscription): JourneyNotificationSubscription => {
	return {
		...model,
		lastMessageReceivedTime: model.lastMessageReceivedTime ? new Date(model.lastMessageReceivedTime) : undefined,
	};
};

interface NotificationEvent {
	message: string;
	redirectUrl: string;
}

interface CountChangeEvent {
	subscriptionId: string;
	count: number;
	recievedAt: string | undefined;
}

export function JourneyNotificationProvider(props: React.PropsWithChildren<{}>) {
    const history = useHistory();
	const hub = useHub();
	const attendeeContext = useAttendees();
	const setSuccessAlert = useSuccessAlert();
	const setErrorAlert = useServerErrorAlert();
	const attendees = attendeeContext.attendees;

	const [journeyNotificationSubscriptions, setJourneyNotificationSubscriptions] = useState<JourneyNotificationSubscription[]>(
		[]
	);
	const [countChange, setCountChange] = useState<CountChangeEvent | undefined>();
	const [subscriptionIdRemoved, setSubscriptionIdRemoved] = useState<string | undefined>();
	const [notificationEvent, setNotificationEvent] = useState<NotificationEvent | undefined>();

	useHubEvent("OnLoadJourneyNotificationSubscriptions", (subscriptions: ServerJourneyNotificationSubscription[]) => {
		setJourneyNotificationSubscriptions(subscriptions.map(convert));
	});

	useHubEvent("OnJourneyNotificationCountChange", (countChange: CountChangeEvent) => {
		setCountChange(countChange);
	});

	useHubEvent("OnJourneyNotificationDeleted", (event: { subscriptionId: string}) => {
		setSubscriptionIdRemoved(event.subscriptionId);
	});

	useHubEvent("OnJourneyCompletedNotificationEvent", (event: JourneyCompletionEvent) => {
		setNotificationEvent(() => {
			const message = `${event.attendeeName} responded to ${event.journeyName} with "${event.message}"`;
			const redirectUrl = routes.app.resolve.journeyDetailPage(event.journeyCategory, event.journeyId);
			return {message, redirectUrl}
		})
	});

	useHubEvent("OnInboxNotificationEvent", (event: InboxNotificationEvent) => {
		if(history.location.pathname.includes(routes.app.messagesPage))
			return;

		const attendee = attendees.filter(a => a.id === event.attendeeId)[0];
		setNotificationEvent(() => {

			const message = `${attendee.name}: ${event.message.slice(0, 10)}...`;
			const redirectUrl = routes.app.resolve.inboxDetailPage(event.attendeeId);
			return {message, redirectUrl} 
				
		});		
	}, [attendees]);

	useHubSubscription({methodName: "SubscribeToNotifications"});

    useEffect(() => {
        if(countChange){
			const { subscriptionId, count, recievedAt } = countChange;
			setJourneyNotificationSubscriptions(journeyNotificationSubscriptions.map(s => {
				if(s.subscriptionId === subscriptionId){
					return {...s, unreadCount: count, lastMessageReceivedTime: recievedAt ? new Date(recievedAt) : undefined};
				}
				return s;
			}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countChange])

	useEffect(() => {
        if(subscriptionIdRemoved){
			const subscriptionId = subscriptionIdRemoved;
			setJourneyNotificationSubscriptions(journeyNotificationSubscriptions.filter(s => s.subscriptionId !== subscriptionId));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionIdRemoved])

	const markJourneyAsRead = async (subsciptionId: string) => {
		const response = await JourneyNotificationSubscriptionService.clearJourneyNotifications(subsciptionId);
		if (ServerResult.isSuccess(response)) {
			const subscriptions = journeyNotificationSubscriptions.map((s) => {
				if (s.subscriptionId === subsciptionId) {
					const resetSubscription: JourneyNotificationSubscription = { ...s, unreadCount: 0 };
					return resetSubscription;
				}
				return s;
			});
			setJourneyNotificationSubscriptions(subscriptions);
		}
	};

	const subcribeToJourneyNotifications = async (journey: Journey) => {
		let response = await JourneyNotificationSubscriptionService.createJourneySubscription(journey.id);
		if (ServerResult.isSuccess(response)) {
			setSuccessAlert(`You will now receive notifications for ${journey.name}`);
			setJourneyNotificationSubscriptions([...journeyNotificationSubscriptions, response.data]);
			hub.invoke("SubscribeToJourneyNotifications", journey.id);
		} else {
			setErrorAlert(response);
		}
	};

	const unsubcribeToJourneyNotifications = async (subscriptionId: string, journey: Journey) => {
		let response = await JourneyNotificationSubscriptionService.disableSubscription(subscriptionId);
		if (ServerResult.isSuccess(response)) {
			setSuccessAlert(`You will no longer receive notifications for ${journey.name}`);
			setJourneyNotificationSubscriptions(
				journeyNotificationSubscriptions.filter((s) => s.subscriptionId !== subscriptionId)
			);
			hub.invoke("UnsubscribeFromJourneyNotifications", journey.id);
		} else {
			setErrorAlert(response);
		}
	};

    const onAcknowledgeAlert = (event: NotificationEvent) => { 
		history.push(event.redirectUrl)
        setNotificationEvent(undefined);
    }

	return (
		<JourneyNotificationsContext.Provider
			value={{
				journeyNotificationSubscriptions: journeyNotificationSubscriptions,
				markJourneyAsRead,
				subcribeToJourneyNotifications,
				unsubcribeToJourneyNotifications,
			}}
		>
			{props.children}
			<Snackbar open={notificationEvent != null} autoHideDuration={6000} onClose={() => setNotificationEvent(undefined)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				{notificationEvent && (
					<MuiAlert elevation={6} variant="standard" severity="info" action={
                        <Button color="inherit" size="small" variant="outlined" onClick={() => onAcknowledgeAlert(notificationEvent)}>
                          Check It Out
                        </Button>
                      }>
						{notificationEvent.message}
					</MuiAlert>
				)}
			</Snackbar>
		</JourneyNotificationsContext.Provider>
	);
}
