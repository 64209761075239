import { Typography, Grid, Paper, FormControlLabel } from "@material-ui/core";
import React, { useState } from "react";
import { GridGrow } from "../../components/GridGrow";
import ResponseSwitch from "../../components/responseSwitch";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import AuthService from "../../services/AuthService";
import { ServerResult } from "../../services/server/ServerResult";
import { MessagePageTabs, MessagesTab } from "./Components/MessagePageTabs";
import { DesktopGroupMessagePage } from "./GroupMessages/DesktopGroupMessagePage";
import { GroupMessagePreview } from "./GroupMessages/GroupMessagePreview";
import { DesktopInboxPage } from "./Inbox/DesktopInboxPage";
import { InboxMessagePreview } from "./Inbox/InboxMessagePreview";

interface MessagePageDeskTopLayoutProps {
	selectedTab: MessagesTab;
}

export function MessagePageDeskTopLayout(props: MessagePageDeskTopLayoutProps) {
	const [user, setUser] = useAppUser();
	const [isDisabled, setIsDisabled] = useState(false);
	const setError = useServerErrorAlert();
	const setSuccess = useSuccessAlert();

	const toggleInboxNotifications = async() => {
		setIsDisabled(true);
		const result = await AuthService.changeInboxNotificationStatus(!user.recieveInboxNotifications);
		setIsDisabled(false);

		if(ServerResult.isError(result))
			return setError(result);

		setUser(result.data.jwtToken);
		setSuccess(user.recieveInboxNotifications ? "Notifications will not be sent to your phone" : "Notifications will be sent to your phone")
	}

	return (
		<div style={{ height: "100%" }}>
			<Grid container  style={{ marginBottom: 20 }}>
				<Grid item>
					<Typography variant="h1">
						Messages
					</Typography>
				</Grid>
				<GridGrow />
				<Grid item>
					<FormControlLabel
						disabled={isDisabled}
						value={user.recieveInboxNotifications}
						control={
							<ResponseSwitch checked={user.recieveInboxNotifications} onChange={toggleInboxNotifications} name="notify-ignore" />
						}
						label={"Send notifications to my phone"}
						style={{ marginRight: 25, marginBottom: 10 }}
					/>
				</Grid>
			</Grid>
			<div style={{ width: 430 }}>
				<MessagePageTabs selectedTab={props.selectedTab} />
			</div>
			<Paper style={{ height: "100%", width: "100%", display: "flex" }}>
				<div style={{ height: "100%", flex: "0 0 430px", borderRight: "1px solid #e0e0e0" }}>
					{props.selectedTab === MessagesTab.Inbox && <InboxMessagePreview />}
					{props.selectedTab === MessagesTab.Group_Announcements && <GroupMessagePreview />}
				</div>
				<div style={{ height: "100%", flex: 1 }}>
					<Grid container direction="column" wrap="nowrap" style={{ height: "100%" }}>
						{props.selectedTab === MessagesTab.Inbox && <DesktopInboxPage />}
						{props.selectedTab === MessagesTab.Group_Announcements && <DesktopGroupMessagePage />}
					</Grid>
				</div>
			</Paper>
		</div>
	);
}
