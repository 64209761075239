import { Typography, Grid, TextField } from "@material-ui/core";
import { createFilterOptions, Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { ResponseDialogActions } from "../../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { ResponseDialog } from "../../../components/ResponseDialog/ResponseDialog";
import { VerticalCenter } from "../../../components/VerticalCenter";
import Attendee from "../../../entities/Attendee";
import { Group } from "../../../entities/Groups/Group";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { useGroups } from "../../../providers/GroupProvider";
import { GroupService } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";

interface BulkGroupActionDialogProps {
    action: "Add" | "Remove";
    selectedAttendees: Attendee[];
    isOpen: boolean;
    onClose: () => void;
}

export default function BulkGroupActionDialog(props:BulkGroupActionDialogProps){
    const setServerError = useServerErrorAlert();
    const setSuccessMessage = useSuccessAlert();
    const groupContext = useGroups();
    const [groupOptions] = useState<Group[]>(groupContext.groups);
    const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
    const [disabled, setDisabled] = useState(false);
    
    const filter = createFilterOptions<Group>({
        matchFrom: "any",
        stringify: (option: Group) => option.name,
    });

    const onSubmit = () => {
        if(selectedGroup)
            handleSubmit(selectedGroup)
    }

    const handleSubmit = async(group: Group) => {
        const request = {groupId: group.id, attendeeIds: props.selectedAttendees.map(a => a.id)} 
        setDisabled(true);
        const result = props.action === "Add" ? await GroupService.addAttendeesToGroup(request) : await GroupService.removeAttendeesFromGroup(request);
        setDisabled(false);

        if(ServerResult.isError(result))
            return setServerError(result);
        if(ServerResult.isSuccess(result))
            groupContext.update(result.data)
            setSuccessMessage(`${group.name} updated`)
            props.onClose()
    }

    return (
        <ResponseDialog 
            onClose={props.onClose} 
            open={props.isOpen} 
            title={`${props.action === "Add" ? "Add to" : "Remove from" } Group`}
        >
            <ResponseDialogContent>
                <VerticalCenter>
                    <Typography align="center" variant="body2" style={{ fontSize: 22, paddingBottom: 20 }}>
                        { props.action === "Add"
                            ? `Add ${props.selectedAttendees.length} ${props.selectedAttendees.length === 1 ? "person" :"people"} to group`
                            : `Remove ${props.selectedAttendees.length} ${props.selectedAttendees.length === 1 ? "person" :"people"} from group`
                        }
                    </Typography>
                    <Grid container justify="center">
                        <Grid item sm={6} xs={12}>
                            <Autocomplete<Group>
                                value={selectedGroup}
                                options={groupOptions}
                                onChange={(_, value) => setSelectedGroup(value)}
                                autoHighlight
                                clearOnBlur
                                clearOnEscape
                                handleHomeEndKeys
                                filterOptions={(options, params) => {
                                    return filter(options, params);
                                }}
                                getOptionLabel={(option: Group) => {
                                    return option.name;
                                }}
                                renderOption={(option: Group) => {
                                    return option.name;
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Group" />}
                            />
                        </Grid>
                    </Grid>
                </VerticalCenter>
            </ResponseDialogContent>
            <ResponseDialogActions		
                hideSecondaryButton={true}
                disabled={disabled}
                onConfirm={onSubmit}
                onBack={props.onClose}
                backButtonText="Cancel"
                nextButtonText={props.action}
            />
        </ResponseDialog>
    )
}
           