import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import React from "react";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

interface Props extends SwitchProps {
	classes: Styles;
}

const ResponseSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 34,
			height: 18,
			padding: 0,
			margin: theme.spacing(1),
		},
		switchBase: {
			padding: 1,
			"&$checked": {
				transform: "translateX(16px)",
				color: theme.palette.common.white,
				"& + $track": {
					backgroundColor: theme.palette.secondaryResponse.main,
					opacity: 1,
					border: "none",
				},
			},
			"&$focusVisible $thumb": {
				color: theme.palette.secondaryResponse.main,
				border: "6px solid #fff",
			},
		},
		thumb: {
			width: 16,
			height: 16,
		},
		track: {
			borderRadius: 26 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: theme.palette.mediumGrayBlue.main,
			opacity: 1,
			transition: theme.transitions.create(["background-color", "border"]),
		},
		checked: {},
		focusVisible: {},
	})
)(({ classes, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

export default ResponseSwitch;
