import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, List, ListItem, ListItemText, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useGroups } from "../../../providers/GroupProvider";
import { useMessageRouter } from "../MessagePage";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import { orderBy } from "../../../utillity/orderBy";
import { ButtonLink } from "../../Groups/ButtonLink";
import { MessagePreviewListItem } from "../Components/MessagePreviewListItem";
import { TextMessagePreviewStatus } from "../../../entities/Messages/TextMessagePreviewStatus";
import { ResponseDialog } from "../../../components/ResponseDialog/ResponseDialog";
import SelectGroupToMessage from "../../../layout/CreateNewDialog/SelectGroupToMessage";

interface GroupMessagePreviewProps {
}

export function GroupMessagePreview(props:GroupMessagePreviewProps){
    const history = useHistory();
    const classes = useStyles();
    const { groups } = useGroups();
    const messageRouter = useMessageRouter();
    const { selectedGroup } = messageRouter;

    const [showDialog, setShowDialog] = useState(false);
    const [displayGroups, setDisplayGroups] = useState(groups);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);

    useEffect(() => {
        let updatedGroups = [...groups];
        
        updatedGroups = updatedGroups.sort(orderBy.optional.date(g => g.lastMessageSentDate, "Ascending", "optionalEnd"))

        if(searchText){
            const searchRegex = new RegExp(searchText, "i");
            updatedGroups = updatedGroups.filter((group) => {
                if(searchRegex.test(group.name))
                    return true

                return group.messagesToGroup.some(m => searchRegex.test(m.message))
            }) 
        }

        setDisplayGroups(updatedGroups);
    }, [groups, searchText])


    return (
        <Grid container direction="column" wrap="nowrap" style={{ height: "100%" }}>
            <Grid item container alignContent="center" justify="space-between" style={{ padding: 5 }}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-bare"
                        defaultValue={searchText}
                        variant="outlined"
                        // className={classes.searchBar}
                        onChange={(e) => setSearchText(e.currentTarget.value)}
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: <Search style={{ marginLeft: -6 }} />,
                        }}
                        style={{ marginTop: 0, marginBottom: 5 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={() => setShowDialog(true)} fullWidth variant="contained" color="primary">
                        New Announcement
                    </Button>
                </Grid>
            </Grid>
            <Grid item style={{ overflowY: "auto" }}>
                <List style={{paddingBottom: 0, paddingTop: 0}}>
                    { displayGroups.length === 0 &&
                    <>
                    <ListItem className={classes.selectedGroup}>
                        <ListItemText
                            primary={<Typography>You don't have any group Announcements yet.</Typography>}
                            secondary={
                                <Typography>
                                    <ButtonLink onClick={() => history.push(routes.app.createGroupPage)}>Create a group</ButtonLink>
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider component="li" />
                    </>
                    }
                    {displayGroups.map((group, index) => {
                        const lastMessageSent =
                            group.messagesToGroup.length !== 0
                                ? group.messagesToGroup[group.messagesToGroup.length - 1]
                                : null;
                        const isSelected = selectedGroup?.id === group.id ?? false;
                        return (
                            <MessagePreviewListItem 
                            key={group.id} 
                            onClick={() => history.push(routes.app.resolve.groupMessageDetailPage(group.id))} 
                            message={lastMessageSent?.message ?? "No messages sent"} 
                            title={group.name} 
                            displayDate={lastMessageSent?.dateCreated} 
                            isSelected={isSelected}
                            status={TextMessagePreviewStatus.Read}/>
                        );
                    })}
                </List>
            </Grid>
            <ResponseDialog onClose={() => setShowDialog(false)} open={showDialog} title={"Send Group Announcement"}>
                <SelectGroupToMessage onClose={() => setShowDialog(false)} onBack={() => setShowDialog(false)} />
            </ResponseDialog>
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
	selectedGroup: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));
