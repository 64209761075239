import { Grid, Typography, Theme, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router";
import { routes } from "../../../routes";

export enum MessagesTab {
    Inbox,
    Group_Announcements,
}

export function MessagePageTabs(props:{ selectedTab: MessagesTab }){
    const { selectedTab } = props;
    const classes = useMessageTabStyles();
    const history = useHistory();

    const handleClick = (tab: MessagesTab) => {
        if(tab === MessagesTab.Group_Announcements){
            history.push(routes.app.groupMessagePage);
        } else {
            history.push(routes.app.inboxPage);
        }
    }
    
    return (
        <Grid container wrap="nowrap">
            <Grid item xs={5}>
                <Typography
                    key={`1-${selectedTab}`}
                    className={classNames(
                        classes.pageTabs,
                        selectedTab === MessagesTab.Inbox ? classes.activeTab : classes.hiddenTab
                    )}
                    variant="h2"
                    onClick={() => selectedTab === MessagesTab.Inbox ? null : handleClick(MessagesTab.Inbox)}
                >
                    Inbox
                </Typography>
            </Grid>
            
            <Grid item xs={7}>
                <Typography
                    key={`2-${selectedTab}`}
                    className={classNames(
                        classes.pageTabs,
                        selectedTab === MessagesTab.Group_Announcements ? classes.activeTab : classes.hiddenTab
                    )}
                    variant="h2"
                    onClick={() => selectedTab === MessagesTab.Group_Announcements ? null : handleClick(MessagesTab.Group_Announcements)}
                >
                    Group Announcements
                </Typography>
            </Grid>
        </Grid>
    )
}

export const useMessageTabStyles = makeStyles((theme: Theme) => ({
	hiddenTab: {
		opacity: 0.7,
		backgroundImage: "linear-gradient(#FFFFFF, #FBFBFB)",
		zIndex: 0,
	},
	activeTab: {
		backgroundColor: "#FFF",
	},
	pageTabs: {
		borderRadius: 5,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		border: "1px #E2E2E2 solid",
		borderBottom: "none",
		zIndex: 2,
		textAlign: "center",
		color: theme.palette.secondaryResponse.main,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 5,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
		height: 48,
		fontSize: 18,
		paddingTop: 12,
        [theme.breakpoints.up("md")]:{
            minWidth: 150,
        }
	},
}));