import { ResponseSubscriptionPlan } from "../entities/ResponseSubscriptionPlan";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export const ResponseSubscriptionPlanService = {
	get(planId: string) {
		return WebClient.Get<ResponseSubscriptionPlan | undefined>(`${apiRoot}/response-subscription-plans/${planId}`);
	},
	getPublicPlans() {
		return WebClient.Get<ResponseSubscriptionPlan[]>(`${apiRoot}/response-subscription-plans`);
	},
};

export default ResponseSubscriptionPlanService;
