
import TextMessageHistoryItem from "../entities/Messages/TextMessageHistoryItem";
import { TextMessageMedia } from "../entities/Messages/TextMessageMedia";
import TextMessagePreview from "../entities/Messages/TextMessagePreview";
import { Modify } from "../utillity/modifyType";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export type SerializedTextMessagePreview = Modify<TextMessagePreview, { orderByDate: string, lastTextMessageDate: string}>;
export type SerializedTextMessageHistoryItem = Modify<TextMessageHistoryItem, { dateCreated: string }>;

export const deserializeTextMessagePreview = (model: SerializedTextMessagePreview): TextMessagePreview => ({
	...model,
	lastTextMessageDate: new Date(model.lastTextMessageDate),
	orderByDate: new Date(model.orderByDate),
});

export const deserializeTextMessagehistory = (model: SerializedTextMessageHistoryItem): TextMessageHistoryItem => ({
	...model,
    dateCreated: new Date(model.dateCreated)
});

export interface SendDirectMessageRequest {
    attendeeId: string;
    message: string
	media:TextMessageMedia[]
}

export const MessageService = {
    getMessagePreviews: () => WebClient.Convert.Get<SerializedTextMessagePreview[], TextMessagePreview[]>(`${apiRoot}/messages/preview-messages`, previews => previews.map(deserializeTextMessagePreview)),
    getAttendeeMessageHistory: (attendeeId:string) => WebClient.Convert.Get<SerializedTextMessageHistoryItem[], TextMessageHistoryItem[]>(`${apiRoot}/messages/${attendeeId}`, messages => messages.map(deserializeTextMessagehistory)),
	sendDirectMessage: (request: SendDirectMessageRequest) => WebClient.Post.Validated(`${apiRoot}/messages`, request),
	markMessageAsRead: (previewMessageId: string) => WebClient.Put.Unvalidated(`${apiRoot}/messages/mark-as-read/${previewMessageId}`, {}),
};
