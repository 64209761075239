import { ListItem, ListItemText} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ResponseBadge } from "../components/ResponseBadge";
import { routes } from "../routes";
import { useLayoutStyles } from "./Layout";

const findFriendlyNameFromPath = (path: string) => {
	switch (path) {
		case routes.app.dashBoardPage:
			return "Dashboard";
		case routes.app.notifications:
			return "Notifications";
		case routes.app.journeyListPage:
			return "Journeys";
		case routes.app.messagesPage:
			return "Messages";
		case routes.app.groupListPage:
			return "Groups";
		case routes.app.connectCardsPage:
			return "- Connect Cards";
		case routes.app.prayerRequestsPage:
			return "- Prayer Requests";
		case routes.app.sermonPromptsPage:
			return "- Sermon Prompts";
		case routes.app.peopleListPage:
			return "People";
		case routes.app.settingsSelectPage:
		case routes.app.settingsPage:
			return "Settings"
		case routes.app.churchInfoPage:
			return "Church Info";
		case routes.app.personalInfoPage:
			return "Personal Info";
		case routes.app.subscriptionPage:
			return "Subscription";
		case routes.app.invoicesPage:
			return "Invoices";
		case routes.app.paymentMethodPage:
			return "Payment";
		case routes.app.journeyCreatePage:
			return "Create Journey";
		case routes.public.signOutPage:
			return "Logout";
		default:
			return "404";
	}
};

export function MenuListItem(props: { path: string; badge?: number; onClick?: () => void, journeyName?:string }) {
	const { path, onClick, journeyName } = props;
	const classes = useLayoutStyles();
	const location = useLocation();

	return (
		<Link onClick={onClick} className={classes.link} to={path}>
			<ListItem
				button
				className={classNames(classes.navListItem, location.pathname === path ? classes.listItemSelected : undefined)}
			>
				<ResponseBadge badgeContent={props.badge}>
					<ListItemText
						disableTypography
						style={{ fontWeight: 500 }}
						className={classNames(classes.whitenedText, "response-h2")}
						primary={journeyName ?? findFriendlyNameFromPath(path)}
					/>
				</ResponseBadge>
			</ListItem>
		</Link>
	);
}
