import React from "react";
import { MultistepDialog } from "../../../components/MultistepDialog/MultistepDialog";
import AcquireResponseNumber from "./AcquireResponseNumber";
import { SetupChurch } from "./SetupChurch";
import { VerifyToken } from "./VerifyToken";

interface ChurchSetupDialogProps {
}

export function ChurchSetupDialog(props:ChurchSetupDialogProps){   
    return (
        <MultistepDialog 
            steps={[
                {name: "Church Profile", step: <SetupChurch />},
                {name: "Verification", step: <VerifyToken />},
                {name: "Custom Number", step: <AcquireResponseNumber />},
            ]}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={() => {}} 
            open={true} 
        />
    )
}