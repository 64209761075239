import { IconButton, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import React from "react";
import { JourneyNotificationSubscription, useJourneyNotifications } from "../hooks/useJourneyNotifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { printTimeSinceDate } from "../functions/datetime";
import { useHistory } from "react-router";
import { routes } from "../routes";
import { useTextMessagePreviews } from "../providers/TextMessagePreviewProvider";
import { TextMessagePreviewStatus } from "../entities/Messages/TextMessagePreviewStatus";

const useStyles = makeStyles((theme: Theme) => ({
	hasNotification: {
		color: theme.palette.primaryResponse.main,
	},
	menuItemContainer: {
		display: "flex",
		width: "100%",
        paddingTop: 10,
        paddingBottom: 10
	},
	menuItemText: {
		lineHeight: 1.4,
		fontSize: 16,
		paddingTop: 0,
		paddingBottom: 0,
	},
	unreadText: {
		fontWeight: 400,
		marginLeft: 5,
	},
	timespanText: {
		fontWeight: 400,
		marginLeft: 4,
		lineHeight: 1.4,
		fontSize: 13,
		color: "#797979",
	},
}));

export function NotificationBell() {
	const classes = useStyles();
    const history = useHistory();
	const notificationContext = useJourneyNotifications();
	const previewMessagesContext = useTextMessagePreviews();
	const subscriptions = notificationContext.journeyNotificationSubscriptions;
	const previewMessages = previewMessagesContext.textMessagePreviews;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const onClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
	const onClose = () => setAnchorEl(null);
    const onSelect = (subscription: JourneyNotificationSubscription) => {
        history.push(routes.app.resolve.journeyDetailPageResponses(subscription.journeyCategory, subscription.journeyId))
        onClose();
    }

	const unreadMessages = previewMessages.filter((t) => t.status === TextMessagePreviewStatus.Unread)
	const hasUnreadMessages = unreadMessages.length !== 0;
	const hasNotification = subscriptions.some((s) => s.unreadCount > 0);

	return (
		<>
			<IconButton
				className={hasNotification || hasUnreadMessages ? classes.hasNotification : undefined}
				onClick={onClick}
				aria-controls="notification-menu"
				aria-haspopup="true"
			>
				{hasNotification || hasUnreadMessages ? <NotificationsActiveIcon /> : <NotificationsIcon />}
			</IconButton>
			<Menu
				id="notification-menu"
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				open={Boolean(anchorEl)}
				onClose={onClose}
			>
				{!hasNotification ? (
					<MenuItem onClick={onClose} className={classes.menuItemText}>
						No Notifications
					</MenuItem>
				) : (
					subscriptions
						.filter((s) => s.unreadCount > 0)
						.map((s, i) => (
							<MenuItem onClick={() => onSelect(s)} className={classes.menuItemText} key={s.subscriptionId}>
								<div style={{ borderTop: i > 0 ? "1px solid #c1c1c1" : undefined }} className={classes.menuItemContainer}>
									<div>
										{s.journeyName}
										<span className={classes.unreadText}>- {s.unreadCount} new,</span>
										<span className={classes.timespanText}>{printTimeSinceDate(s.lastMessageReceivedTime)}</span>
									</div>
									<div style={{ flex: 1 }} />
									<ChevronRightIcon />
								</div>
							</MenuItem>
						))
				)}
				{!hasUnreadMessages ? (
					<MenuItem onClick={onClose} className={classes.menuItemText}>
						No Unread Messages
					</MenuItem>
				) : (
					<MenuItem onClick={() => {history.push(routes.app.inboxPage); onClose()}} className={classes.menuItemText}>
						<div style={{ borderTop: "1px solid #c1c1c1" }} className={classes.menuItemContainer}>
							<div>
								Inbox Messages
								<span className={classes.unreadText}>- {unreadMessages.length} new,</span>
							</div>
							<div style={{ flex: 1 }} />
							<ChevronRightIcon />
						</div>
					</MenuItem>
				)}
			</Menu>
		</>
	);
}
