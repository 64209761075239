import { Grid, Typography } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { useDialogSteps } from "../../../components/MultistepDialog/CurrentDialogStep";
import { MultistepDialogStepLayout } from "../../../components/MultistepDialog/Layouts/MultistepDialogStepLayout";
import { MultistepDialog } from "../../../components/MultistepDialog/MultistepDialog";
import { VerticalCenter } from "../../../components/VerticalCenter";
import Church from "../../../entities/Church";
import { useAppUser } from "../../../hooks/useAppUser";
import { ChurchService } from "../../../services/ChurchService";
import { ServerResult } from "../../../services/server/ServerResult";
import { VerifyToken } from "../ChurchSetupDialog/VerifyToken";
import { UpdateChurchBrand } from "./UpdateChurchBrand";
import { VerifiedBrand } from "./VerifiedBrand";

export function VerifyBrandDialog() {
	const [isVerified, setIsVerified] = useState<boolean>(true);
	const [user] = useAppUser();
	const [activeChurch, setActiveChurch] = useState<Church>();

	useEffect(() => {
		async function loadStatus() {
			if(user.responsePhoneNumber === undefined)
				// TODO: need to find a better way for signup since church is not setup properly
				return setIsVerified(true);
			const result = await ChurchService.getActiveChurch();
			if (ServerResult.isSuccess(result)) {
				setIsVerified(result.data.brandVerified);
				setActiveChurch(result.data);
			}
		}
		loadStatus();
	}, [user]);

	return (
		<MultistepDialog
			steps={[
				{ name: "Update", step: <VerificationExplanation /> },
				{ name: "Church Information", step: activeChurch ? <UpdateChurchBrand church={activeChurch} /> : <VerificationExplanation/>},
				{ name: "Verification", step: <VerifyToken /> },
				{ name: "All Set", step: <VerifiedBrand onVerified={() => setIsVerified(true)} /> },
			]}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			onClose={() => {}}
			open={isVerified === false}
		/>
	);
}

function VerificationExplanation() {
	const dialogContext = useDialogSteps();

	return (
		<MultistepDialogStepLayout
			title="Verify Your Church"
			required={true}
			hideNextStep={false}
			hideBackStep={true}
			disabled={false}
			onConfirmStep={dialogContext.nextStep}
			nextButtonText={"Confirm Church Information"}
		>
			<VerticalCenter>
				<Grid container justify="center" alignContent="center">
					<Grid item xs={12} style={{ maxWidth: 600 }}>
						<Typography variant="body2" align="center" style={{ fontSize: 22}}>
							Due to new anti-spam regulations, your church's contact information must be re-verified. Please confirm below to continue.
						</Typography>
					</Grid>
				</Grid>
			</VerticalCenter>
		</MultistepDialogStepLayout>
	);
}
