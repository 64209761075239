import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import MoreOptionsIcon from "@material-ui/icons/AddCircleOutline";
import Clipboard from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MediaIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import LessOptionsIcon from "@material-ui/icons/RemoveCircleOutline";
import classNames from "classnames";
import React, { useState } from "react";
import { Journey, JourneyCategory, JourneyType } from "../../entities/Journey";
import { MessageExtraType } from "../../entities/MessageExtraType";
import { getShortWeekDay } from "../../functions/datetime";
import { FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import { JourneySetupStepsHelper, JourneyStep } from "../../utillity/JourneySetupStepsHelper";
import { FieldErrorStrings } from "./journeySetUp";
import { useJourneyMessagePreviewStyles } from "./Steps/JourneyMessagePreviewStep";
import { useJourneyStepStyles } from "./Steps/JourneyStepLayout";
import { useJourneySetupProgress } from "./useJourneySetupProgress";

interface JourneyMessageExtrasProps {
	journey: Journey;
	onAddPersonalization: (token: string) => void;
	onSetJourneyExtra: (extra: MessageExtraType) => void;
}

export function JourneyMessageExtras(props: JourneyMessageExtrasProps) {
	const classes = { ...useJourneyStepStyles(), ...useJourneyMessagePreviewStyles() };

	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const progress = useJourneySetupProgress();

	const { journey } = props;
	const { fieldErrors } = progress;

	const [showMoreOptions, setShowMoreOptions] = useState(false);

	const deleteStep = (step: JourneyStep) => {
		let updatedSteps = JourneySetupStepsHelper.removeStep(progress.steps, step);

		let journeyToUpdate = { ...journey };

		if (step === JourneyStep.Link) {
			let updatedJourney = { ...journeyToUpdate, extras: { ...journeyToUpdate.extras, link: undefined } };
			progress.removeStepUpdateJourney(updatedSteps, updatedJourney);
		} else if (step === JourneyStep.EventCalendar) {
			if (journeyToUpdate.category === JourneyCategory.Informational) {
				let updatedJourney = { ...journeyToUpdate, event: undefined };
				progress.removeStepUpdateJourney(updatedSteps, updatedJourney);
			}
		} else if (step === JourneyStep.Media) {
			let updatedJourney = { ...journeyToUpdate, extras: { ...journeyToUpdate.extras, mediaLink: undefined } };
			progress.removeStepUpdateJourney(updatedSteps, updatedJourney);
		} else if (step === JourneyStep.Address) {
			let updatedJourney = { ...journeyToUpdate, extras: { ...journeyToUpdate.extras, address: undefined } };
			progress.removeStepUpdateJourney(updatedSteps, updatedJourney);
		} else if (step === JourneyStep.Form) {
			let updatedJourney = { ...journeyToUpdate, extras: { ...journeyToUpdate.extras, formId: undefined } };
			progress.removeStepUpdateJourney(updatedSteps, updatedJourney);
		}
	};

	return (
		<>
			<Grid item xs={12} style={{ marginTop: 16, marginBottom: 8 }}>
				{!showMoreOptions && progress.fieldErrors.length === 0 ? (
					<div style={{ cursor: "pointer", display: "flex" }} onClick={() => setShowMoreOptions(true)}>
						<MoreOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
						<Typography className={classes.blueStrongText} style={{ cursor: "pointer" }} variant="subtitle2">
							More Options
						</Typography>{" "}
					</div>
				) : (
					<div style={{ cursor: "pointer", display: "flex" }} onClick={() => setShowMoreOptions(false)}>
						<LessOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
						<Typography className={classes.blueStrongText} variant="subtitle2">
							Less Options
						</Typography>{" "}
					</div>
				)}
			</Grid>
			{(showMoreOptions || progress.fieldErrors.length > 0) && (
				<>
					{progress.fieldErrors.length > 0 &&
						!FieldValidationErrorUtils.isFieldInError(fieldErrors, FieldErrorStrings.Event_Past_Due) && (
							<Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
								There are errors that need to be resolved to save the journey.
							</Typography>
						)}
					{journey.category === JourneyCategory.Informational && (journey.event != null || journey.id.length === 0) && (
						<Grid item xs={12} sm={6}>
							<div className={classes.option}>
								<img
									alt="icon"
									src="https://i.cdn-sc.com/Response/icons/event-date-icon.svg"
									className={classes.optionIcon}
								/>
								{journey.event ? (
									<>
										<Typography
											className={classes.optionText}
											style={{
												color: FieldValidationErrorUtils.isFieldInError(
													progress.fieldErrors,
													FieldErrorStrings.Event
												)
													? theme.palette.error.main
													: "inherit",
											}}
											variant="subtitle2"
										>
											{getShortWeekDay(journey.event.originalTime.getDay())},{" "}
											{journey.event.originalTime.getHours() > 12
												? journey.event.originalTime.getHours() % 12
												: journey.event.originalTime.getHours()}
											:
											{journey.event.originalTime.getMinutes() < 10
												? "0" + journey.event.originalTime.getMinutes()
												: journey.event.originalTime.getMinutes()}{" "}
											{journey.event.originalTime.getHours() > 11 ? "pm" : "am"}
										</Typography>
										<div className={classes.grow} />
										{journey.type !== JourneyType.Event_Sign_Up && (
											<DeleteIcon
												className={classNames(classes.icons, classes.optionEndIcon, classes.deleteIcon)}
												onClick={() => deleteStep(JourneyStep.EventCalendar)}
											/>
										)}

										<EditIcon
											onClick={() => props.onSetJourneyExtra(MessageExtraType.Event)}
											className={classNames(classes.icons, classes.optionEndIcon)}
										/>
									</>
								) : (
									<>
										<Typography className={classes.optionText} variant="subtitle2">
											Event
										</Typography>
										<div className={classes.grow} />
										<MoreOptionsIcon
											onClick={() => props.onSetJourneyExtra(MessageExtraType.Event)}
											className={classNames(classes.icons, classes.optionEndIcon)}
										/>
									</>
								)}
							</div>
						</Grid>
					)}

					<Grid item xs={12} sm={6}>
						<div className={classNames(classes.option, classes.borderRight, classes.borderBottom)}>
							<LinkIcon className={classNames(classes.optionIcon, classes.linkIcon)} />
							{journey.extras.link ? (
								<>
									<Typography
										className={classNames(classes.optionText, classes.optionFilledText)}
										variant="subtitle2"
									>
										{journey.extras.link}
									</Typography>
									<div className={classes.grow} />
									<DeleteIcon
										className={classNames(classes.icons, classes.optionEndIcon, classes.deleteIcon)}
										onClick={() => deleteStep(JourneyStep.Link)}
									/>
									<EditIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Link)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							) : (
								<>
									<Typography className={classes.optionText} variant="subtitle2">
										Link
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Link)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							)}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.option}>
							<img
								alt="icon"
								src="https://i.cdn-sc.com/Response/icons/location-icon.svg"
								className={classes.optionIcon}
								style={{ width: 16 }}
							/>
							{journey.extras.address ? (
								<>
									<Typography
										className={classNames(classes.optionText, classes.optionFilledText)}
										variant="subtitle2"
									>
										{journey.extras.address.street} {journey.extras.address.city}
									</Typography>
									<div className={classes.grow} />
									<DeleteIcon
										className={classNames(classes.icons, classes.optionEndIcon, classes.deleteIcon)}
										onClick={() => deleteStep(JourneyStep.Address)}
									/>
									<EditIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Address)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							) : (
								<>
									<Typography className={classes.optionText} variant="subtitle2" style={{ marginLeft: 18 }}>
										Address
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Address)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							)}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classNames(classes.option, classes.borderRight, classes.borderBottom)}>
							<MediaIcon className={classNames(classes.optionIcon, classes.mediaIcon)} />
							{journey.extras.mediaLink ? (
								<>
									<Typography
										className={classNames(classes.optionText, classes.optionFilledText)}
										variant="subtitle2"
									>
										{journey.extras.mediaLink}
									</Typography>
									<div className={classes.grow} />
									<DeleteIcon
										className={classNames(classes.icons, classes.optionEndIcon, classes.deleteIcon)}
										onClick={() => deleteStep(JourneyStep.Media)}
									/>
									<EditIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Media)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							) : (
								<>
									<Typography className={classes.optionText} variant="subtitle2" style={{ marginLeft: 17 }}>
										Image/Video
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Media)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							)}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classNames(classes.option, classes.borderRight, classes.borderBottom)}>
							<Clipboard className={classNames(classes.optionIcon, classes.formIcon)} />
							{journey.extras.formId ? (
								<>
									<Typography
										className={classNames(classes.optionText, classes.optionFilledText)}
										variant="subtitle2"
									>
										{`${journey.keywordTrigger} - Form`}
									</Typography>
									<div className={classes.grow} />
									<DeleteIcon
										className={classNames(classes.icons, classes.optionEndIcon, classes.deleteIcon)}
										onClick={() => deleteStep(JourneyStep.Form)}
									/>
									<EditIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Form)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							) : (
								<>
									<Typography className={classes.optionText} variant="subtitle2" style={{ marginLeft: 17 }}>
										Form
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon
										onClick={() => props.onSetJourneyExtra(MessageExtraType.Form)}
										className={classNames(classes.icons, classes.optionEndIcon)}
									/>
								</>
							)}
						</div>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 12 }}>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							<div
								className={classes.personalizedOption}
								onClick={() => props.onAddPersonalization("{{First_Name}}")}
							>
								<Typography className={classes.personalizedOptionText} variant="subtitle2">
									First Name
								</Typography>
								<div className={classes.grow} />
								<MoreOptionsIcon className={classes.personalizedOptionIcons} />
							</div>
							<div
								className={classes.personalizedOption}
								onClick={() => props.onAddPersonalization("{{Full_Name}}")}
							>
								<Typography className={classes.personalizedOptionText} variant="subtitle2">
									Full Name
								</Typography>
								<div className={classes.grow} />
								<MoreOptionsIcon className={classes.personalizedOptionIcons} />
							</div>
							<div className={classes.personalizedOption} onClick={() => props.onAddPersonalization("{{Keyword}}")}>
								<Typography className={classes.personalizedOptionText} variant="subtitle2">
									Keyword
								</Typography>
								<div className={classes.grow} />
								<MoreOptionsIcon className={classes.personalizedOptionIcons} />
							</div>
							<div
								className={classes.personalizedOption}
								style={{ marginBottom: mobileFormFactor ? 55 : 0 }}
								onClick={() => props.onAddPersonalization("{{Church}}")}
							>
								<Typography className={classes.personalizedOptionText} variant="subtitle2">
									Church
								</Typography>
								<div className={classes.grow} />
								<MoreOptionsIcon className={classes.personalizedOptionIcons} />
							</div>
						</div>
					</Grid>
				</>
			)}
		</>
	);
}
