import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { CurrentDialogStep } from "./CurrentDialogStep";


export interface DialogStep {
    name: string;
    step: ReactNode;
}

interface MultistepDialogProps {
    onClose: () => void;
    steps: DialogStep[];
    open: boolean;
	disableEscapeKeyDown?: boolean;
	disableBackdropClick?: boolean
}

export function MultistepDialog(props: MultistepDialogProps) {
    const classes = useStyles();
    const theme = useTheme();
    const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			classes={{
				paper: classNames(classes.backdropPaper, mobileFormFactor ? classes.mobileBackdrop : undefined),
			}}
			fullScreen={mobileFormFactor}
			disableBackdropClick={props.disableBackdropClick}
			disableEscapeKeyDown={props.disableEscapeKeyDown}
		>
			<CurrentDialogStep onClose={props.onClose} steps={props.steps}	key={`ShowMultistepDialog-${props.open}`}/>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	backdropPaper: {
		maxWidth: 1000,
		overflow: "hidden",
	},
	mobileBackdrop: {
		backgroundColor: "#F5F6F7",
	},
}));