import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Clipboard from "@material-ui/icons/Assignment";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import classNames from "classnames";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { QuestionType } from "../../../entities/Forms/Question";
import { Journey } from "../../../entities/Journey";
import { FormBuilder } from "../../Forms/FormBuilder";
import { FormBuilderStepLayout } from "../Steps/FormBuilderStepLayout";
import { JourneyStepLayout, useJourneyStepStyles } from "../Steps/JourneyStepLayout";
import { useJourneySetupProgress } from "../useJourneySetupProgress";

export const useFormStepStyles = makeStyles((theme: Theme) => ({
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		margin: "0 auto",
		[theme.breakpoints.down("sm")]: {
			marginBottom: 30,
		},
	},
	cardHover: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "rgb(250,250,250)",
		},
	},
	grow: {
		flex: 1,
	},
	formTitleInput: {
		fontSize: 20,
		fontWeight: 700,
		width: "100%",
	},
	formIntroInput: {
		fontSize: 16,
		fontWeight: 500,
		width: "100%",
	},
	inputText: {
		color: "rgb(0,0,0,.6)",
	},
	backDrop: {
		backgroundColor: "rgba(0,0,0,0)",
	},
	deleteText: {
		color: theme.palette.error.main,
		fontSize: 14,
		cursor: "pointer",
		marginTop: 6,
		"&:hover": {
			opacity: 0.7,
		},
	},
}));

type StepProps = {
	mode: "step";
	journey: Journey;
};

type AddProps = {
	mode: "add";
	journey: Journey;
	title: string;
	onCancel: () => void;
	onUpdate: (updatedJourney: Journey) => void;
};

type Props = StepProps | AddProps;

export function MessageFormView(props: Props) {
	const classes = { ...useJourneyStepStyles(), ...useFormStepStyles() };
	const progress = useJourneySetupProgress();
	const { journey } = props;
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [showFormBuilder, setShowFormBuilder] = useState(props.mode === "add" ? true : false);

	const onFormSave = (formId: string) => {
		if (props.mode === "add") {
			props.onUpdate({ ...props.journey, extras: { ...props.journey.extras, formId } });
		} else {
			progress.completeStep({ ...props.journey, extras: { ...props.journey.extras, formId } });
			setShowFormBuilder(false);
		}
	};

	const completeStep = () => {
		progress.completeStep(props.journey);
	};

	const skipStep = () => {
		progress.completeStep({
			...journey,
			extras: {
				...journey.extras,
			},
		});
	};

	const deleteForm = () => {
		progress.updateJourney({
			...journey,
			extras: {
				...journey.extras,
				formId: undefined,
			},
		});
	};

	const view = () => {
		return (
			<Grid item style={{ textAlign: "center" }} xs={12}>
				<Typography className={classes.progressLabelText} variant="subtitle2">
					We recommend you create a form to ask your church multiple questions
				</Typography>
				<div
					className={classNames(classes.cardGlowEffect, classes.cardHover)}
					style={{
						padding: "10px 6px 10px 6px",
						marginTop: 15,
						display: "flex",
						width: mobileFormFactor ? "100%" : 300,
					}}
					onClick={() => setShowFormBuilder(true)}
				>
					<Clipboard style={{ color: theme.palette.primaryResponse.main, fontSize: 22, marginTop: 3, marginLeft: 3 }} />
					<hr style={{ opacity: 0.4, width: 1, height: 26, margin: 0, marginTop: 1, marginLeft: 9 }} />
					<Typography
						variant="subtitle1"
						style={{
							fontSize: 16,
							fontWeight: 500,
							marginLeft: 8,
							color: theme.palette.secondaryResponse.main,
						}}
					>
						{journey.extras.formId ? `Edit current form?` : "Want to add a form?"}
					</Typography>
					<div className={classes.grow} />
					<RightArrow style={{ opacity: 0.4, margin: "2px 8px 0px 8px" }} />
				</div>
				{journey.extras.formId && (
					<Typography onClick={() => deleteForm()} className={classes.deleteText} variant="subtitle1">
						(Delete form)
					</Typography>
				)}
			</Grid>
		);
	};

	const onCancelFromBuilder = () => {
		if (props.mode === "add") {
			props.onCancel();
		} else {
			setShowFormBuilder(false);
		}
	};

	const formBuilder = () => {
		return (
			<FormBuilderStepLayout formId={journey.extras.formId} journeyId={journey.id}>
				{!journey.extras.formId ? (
					<FormBuilder
						onSaved={(formId: string) => onFormSave(formId)}
						onCancel={onCancelFromBuilder}
						mode="create"
						journeyId={journey.id}
						initialQuestions={[
							{
								id: uuid(),
								index: 0,
								required: false,
								prompt: "",
								questionType: QuestionType.ShortAnswer,
							},
						]}
					/>
				) : (
					<FormBuilder
						mode="edit"
						journeyId={journey.id}
						formId={journey.extras.formId}
						onSaved={(formId: string) => onFormSave(formId)}
						onCancel={onCancelFromBuilder}
					/>
				)}
			</FormBuilderStepLayout>
		);
	};

	if (props.mode === "add" || showFormBuilder) {
		return formBuilder();
	}

	return (
		<JourneyStepLayout required={false} onConfirmStep={completeStep} skip={true} onSkip={skipStep}>
			{view()}
		</JourneyStepLayout>
	);
}
