import { EventType } from "./EventTracking";

declare const gtag: (command: string, commandParameters?: any, additionalConfigInfo?: any) => any;

export const getDataLayer = (): any[] => {
	if (!(window as any).dataLayer) {
		(window as any).dataLayer = [];
		return (window as any).dataLayer;
	}
	return (window as any).dataLayer;
};

export class GTag {
	static event(event: EventType, params: Record<string, any>) {
		let windowObject: any = window;

		if (!windowObject.gtag) {
			const dataLayer = getDataLayer();
			(window as any).gtag = function () {
				dataLayer.push(arguments);
			};
		}

		if (windowObject.gtag && navigator.onLine) {
			gtag("event", event, params);
		}
	}
}
