import React, { useState } from "react";
import { ListItemText, Grid, Typography, ListItem, Divider, makeStyles, Theme, ListItemAvatar, Avatar, Box } from "@material-ui/core";
import { GridGrow } from "../../../components/GridGrow";
import ForwardIcon from "@material-ui/icons/ChevronRight";
import { printTimeSinceDate } from "../../../functions/datetime";
import { useInterval } from "../../../hooks/useInterval";
import { isMobile } from "../../../hooks/isMobile";
import { TextMessagePreviewStatus } from "../../../entities/Messages/TextMessagePreviewStatus";
import classNames from "classnames";

interface MessagePreviewListItemProps {
	onClick: () => void;
	message: string;
	title: string;
	displayDate?: Date;
	isSelected: boolean;
	status: TextMessagePreviewStatus;
}

export function MessagePreviewListItem(props: MessagePreviewListItemProps) {
	const mobile = isMobile();
	const classes = useStyles();
	const [timeSince, setTimeSince] = useState(printTimeSinceDate(props.displayDate));

	useInterval(() => setTimeSince(printTimeSinceDate(props.displayDate)), 30000);
	const isUnread = props.status === TextMessagePreviewStatus.Unread;

	return (
		<React.Fragment>
			<ListItem
				button
				onClick={props.onClick}
				className={classNames(
					props.isSelected
						? classes.selectedPreview
						: isUnread
						? classes.unreadStatus
						: classes.readStatus
				)}
			>
				{mobile && (
					<ListItemAvatar>
						<Avatar className={isUnread ? classes.unreadAvatar : undefined}>{props.title[0]}</Avatar>
					</ListItemAvatar>
				)}
				<ListItemText
					primary={
						<Grid container alignItems="center">
							<Grid item>
								<Typography
									style={{ fontWeight: isUnread ? "bold" : 400 }}
								>
									{props.title}
								</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Typography variant="body2">
									{props.displayDate ? timeSince : ""}
									{mobile && <ForwardIcon fontSize="small" style={{ verticalAlign: "bottom" }} />}
								</Typography>
							</Grid>
						</Grid>
					}
					secondary={
						<Box style={{maxWidth: 250, overflow: "hidden", textOverflow: "ellipsis", color: props.isSelected ? "#FFF" : undefined}}>
							<Typography
								variant="caption"
								noWrap
								style={{ fontWeight: isUnread ? "bold" : 400 }}
							>
								{props.message}
							</Typography>
						</Box>
					}
				/>
			</ListItem>
			<Divider component="li" />
		</React.Fragment>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	selectedPreview: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
	},
	unreadStatus: {
		borderLeftColor: theme.palette.secondary.main,
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	readStatus: {
		borderLeftColor: theme.palette.grey[400],
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	unreadAvatar: {
		backgroundColor: theme.palette.secondary.main
	}
}));
