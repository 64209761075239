import { Button, Grid, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Auth from "../../authorization/Auth";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useUser } from "../../hooks/useUser";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import MuiAlert from "@material-ui/lab/Alert";
import { ClientLink } from "../../components/ClientLink";

export const useSignInStyles = makeStyles((theme: Theme) => ({
	forgotPwPaper: {
		padding: "30px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			margin: "0px",
			padding: "0px 30px",
			paddingTop: "5%",
			height: "100%",
		},
	},
	grid: {
		[theme.breakpoints.down("xs")]: {
			height: "100%",
		},
	},
	textButton: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
	link: {
		textDecoration: "none",
	},
	buttonContainer: {
		padding: "30px 0px",
	},
	submitButton: {
		minWidth: "150px",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	submitButtonMobile: {
		paddingLeft: "0px",
		paddingRight: "0px",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
}));

export function SetPassword() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const classes = useSignInStyles();
	const history = useHistory();
	const [, setUser] = useUser();

	const errorAlert = useServerErrorAlert();

	const [enabled, setEnabled] = useState(true);
	const [badInviteMessage, setBadInviteMessage] = useState<string>();
	const [token] = useState(urlParams.get("token") ?? "");
	const [password, setPassword] = useState("");

	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setFieldErrors([]);

		setEnabled(false);
		const result = await AuthService.resetNewUserPassword(password, token);
		setEnabled(true);

		if (ServerResult.isSuccess(result)) {
			setUser(result.data.jwtToken);
			history.replace(routes.app.resolve.dashBoardPage());
		} else if (ServerResult.isValidationError(result)) {
			const badInviteError = result.errors
				.find((e) => e.field === "Password")
				?.errors.find((m) => m.includes("invite link has expired") || m.includes("invite has already been used"));

			if (badInviteError) {
				setEnabled(false);
				setBadInviteMessage(badInviteError);
			} else {
				setFieldErrors(result.errors);
			}
		} else if (ServerResult.isError(result)) {
			errorAlert(result);
		}
	};

	useEffect(() => {
		if (Auth.signedIn()) {
			AuthService.signOut().then(() => setUser(undefined));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!token) history.replace(routes.public.signInPage);
	}, [history, token]);

	const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		setPassword(event.target.value);

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item className={classes.grid}>
				<Paper className={classes.forgotPwPaper}>
					<Grid container direction="column" justify="center" alignItems="center">
						{badInviteMessage ? (
							<MuiAlert elevation={6} variant="standard" severity="warning" style={{ marginBottom: 20 }}>
								<Typography>You've already accepted this invite and cannot set the password again.</Typography>
								<Typography style={{ marginTop: 5 }}>
									If you know your password, please{" "}
									<ClientLink to={routes.public.resolve.signInPage()}>Sign In</ClientLink>
								</Typography>
								<Typography style={{ marginTop: 5 }}>
									Don't know your password?{" "}
									<ClientLink to={routes.public.resolve.forgotPassword()}>Reset Password</ClientLink>
								</Typography>
							</MuiAlert>
						) : (
							<>
								<img
									alt="Response"
									src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
									style={{ maxWidth: "100%", height: "auto" }}
								/>
								<Typography variant="h5" style={{ marginTop: 12 }}>
									Welcome!
								</Typography>
								<Typography variant="body2" style={{ marginTop: 8 }}>
									Enter a password for your new Response account
								</Typography>
							</>
						)}
					</Grid>
					<form onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									autoFocus
									fullWidth
									label="Password"
									type="password"
									disabled={!enabled}
									onChange={onChangePassword}
									error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Password")}
									helperText={FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "Password")}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Button
									fullWidth
									variant="contained"
									size="medium"
									color="primary"
									type="submit"
									disabled={!enabled}
									style={{ fontWeight: 600 }}
									className={classes.submitButtonMobile}
								>
									Set Password
								</Button>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default SetPassword;
