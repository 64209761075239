import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import Attendee from "../entities/Attendee";
import { Group } from "../entities/Groups/Group";
import { Journey } from "../entities/Journey";
import TextMessagePreview from "../entities/Messages/TextMessagePreview";
import { TextMessagePreviewStatus } from "../entities/Messages/TextMessagePreviewStatus";
import { useHubEvent } from "../hooks/useHubEvent";
import { useHubSubscription } from "../hooks/useHubSubscription";
import { AttendeeService, convertAttendee, ServerAttendee } from "../services/AttendeeService";
import { deserializeGroup, GroupService, SerializedGroup } from "../services/GroupService";
import JourneyService, { convertJourney } from "../services/JourneyService";
import { deserializeTextMessagePreview, MessageService, SerializedTextMessagePreview } from "../services/MessageService";
import { ServerError } from "../services/server/ServerError";
import { ServerResult } from "../services/server/ServerResult";
import { sounds } from "../utillity/sounds/Sounds";
import { AdminToolsProvider } from "./AdminToolsProvider";
import { AttendeeProvider } from "./AttendeeProvider";
import { GroupProvider } from "./GroupProvider";
import { JourneyProvider } from "./JourneyProvider";
import { TextMessagePreviewProvider } from "./TextMessagePreviewProvider";

interface AppDataContext {
	journeysLoading: boolean;
	journeyServerError: ServerError | null;
	groupsLoading: boolean;
	groupServerError: ServerError | null;
	attendeesLoading: boolean;
	attendeeServerError: ServerError | null;
	textMessagePreviewsLoading: boolean;
	textMessagePreviewServerError: ServerError | null;
}

const AppDataReactContext = createContext<AppDataContext>({} as any);

export function useAppData() {
	return useContext(AppDataReactContext);
}

export function AppDataProvider(props: PropsWithChildren<{}>) {
	const [textMessagePreviews, setTextMessagePreviews] = useState<TextMessagePreview[]>([]);
	const [textMessagePreviewsLoading, setTextMessagePreviewsLoading] = useState(true);
	const [textMessagePreviewServerError, setTextMessagePreviewServerError] = useState<ServerError | null>(null);

	const [journeys, setJourneys] = useState<Journey[]>([]);
	const [journeysLoading, setJourneysLoading] = useState(true);
	const [journeyServerError, setJourneyServerError] = useState<ServerError | null>(null);

	const [groups, setGroups] = useState<Group[]>([]);
	const [groupsLoading, setGroupsLoading] = useState(true);
	const [groupServerError, setGroupServerError] = useState<ServerError | null>(null);

	const [attendees, setAttendees] = useState<Attendee[]>([]);
	const [attendeesLoading, setAttendeesLoading] = useState(true);
	const [attendeeServerError, setAttendeeServerError] = useState<ServerError | null>(null);

	useEffect(() => {
		async function fetchTextMessagePreviews() {
			const result = await MessageService.getMessagePreviews();
			if (ServerResult.isSuccess(result)) {
				setTextMessagePreviews(result.data);
			} else {
				setTextMessagePreviewServerError(result);
			}
			setTextMessagePreviewsLoading(false);
		}
		fetchTextMessagePreviews();
	}, []);

	useEffect(() => {
		async function fetchChurchJourneys() {
			const result = await JourneyService.GetJourneys();
			if (ServerResult.isSuccess(result)) {
				setJourneys(result.data);
			} else {
				setJourneyServerError(result);
			}
			setJourneysLoading(false);
		}
		fetchChurchJourneys();
	}, []);

	useEffect(() => {
		async function fetchGroups() {
			const result = await GroupService.getAll();
			if (ServerResult.isSuccess(result)) {
				setGroups(result.data);
			} else {
				setGroupServerError(result);
			}
			setGroupsLoading(false);
		}
		fetchGroups();
	}, []);

	useEffect(() => {
		async function fetchAttendees() {
			const result = await AttendeeService.getAttendeesForChurch();
			if (ServerResult.isSuccess(result)) {
				setAttendees(result.data);
			} else {
				setAttendeeServerError(result);
			}
			setAttendeesLoading(false);
		}
		fetchAttendees();
	}, []);

	const onGroupChanged = (group: Group) => {
		setGroups((groupsState) =>
			groupsState.some((g) => g.id === group.id)
				? groupsState.map((g) => (g.id === group.id ? group : g))
				: [...groupsState, group]
		);
	};
	const onAttendeeChanged = (attendee: Attendee) => {
		setAttendees((attendeeState) =>
			attendeeState.some((a) => a.id === attendee.id)
				? attendeeState.map((a) => (a.id === attendee.id ? attendee : a))
				: [...attendeeState, attendee]
		);
	};
	const onJourneyChanged = (journey: Journey) => {
		setJourneys((journeyState) =>
			journeyState.some((j) => j.id === journey.id)
				? journeyState.map((j) => (j.id === journey.id ? journey : j))
				: [...journeyState, journey]
		);
	};

	const onPreviewChanged = (message: TextMessagePreview) => {
		setTextMessagePreviews((TextMessagePreviewState) => {
			if(message.status === TextMessagePreviewStatus.Unread)
				sounds.pop.play();
			if (TextMessagePreviewState.some((m) => m.attendeeId === message.attendeeId))
				return TextMessagePreviewState.map((m) => (m.attendeeId === message.attendeeId ? message : m));
			return [...TextMessagePreviewState, message];
		});
	};

	const onGroupDeleted = (group: Group) => setGroups((groupsState) => groupsState.filter((g) => g.id !== group.id));
	const onAttendeeDeleted = (attendee: Attendee) =>
		setAttendees((attendeeState) => attendeeState.filter((a) => a.id !== attendee.id));
	const onJourneyDeleted = (journey: Journey) => setJourneys((journeyState) => journeyState.filter((j) => j.id !== journey.id));
	const onPreviewDeleted = (preview: TextMessagePreview) =>
		setTextMessagePreviews((previewState) => previewState.filter((p) => p.id !== preview.id));

	useHubSubscription({ methodName: "SubscribeToGroups" });
	useHubSubscription({ methodName: "SubscribeToAttendees" });
	useHubSubscription({ methodName: "SubscribeToJourneys" });
	useHubSubscription({ methodName: "SubscribeToPreviewMessages" });

	useHubEvent("OnGroupSaveEvent", (serializedGroup: SerializedGroup) => onGroupChanged(deserializeGroup(serializedGroup)));
	useHubEvent("OnGroupDeleteEvent", (serializedGroup: SerializedGroup) => onGroupDeleted(deserializeGroup(serializedGroup)));
	useHubEvent("OnChurchAttendeeSaveEvent", (serializedAttendee: ServerAttendee) =>
		onAttendeeChanged(convertAttendee(serializedAttendee))
	);
	useHubEvent("OnChurchAttendeeDeleteEvent", (serializedAttendee: ServerAttendee) =>
		onAttendeeDeleted(convertAttendee(serializedAttendee))
	);
	useHubEvent("OnJourneySaveEvent", (serializedJourney: Journey) => onJourneyChanged(convertJourney(serializedJourney)));
	useHubEvent("OnJourneyDeleteEvent", (serializedJourney: Journey) => onJourneyDeleted(convertJourney(serializedJourney)));
	useHubEvent("OnPreviewMessageChangeEvent", (serializedPreview: SerializedTextMessagePreview) =>
		onPreviewChanged(deserializeTextMessagePreview(serializedPreview))
	);
	useHubEvent("OnPreviewMessageDeleteEvent", (serializedPreview: SerializedTextMessagePreview) =>
		onPreviewDeleted(deserializeTextMessagePreview(serializedPreview))
	);

	return (
		<AppDataReactContext.Provider
			value={{
				groupsLoading,
				groupServerError,
				attendeesLoading,
				attendeeServerError,
				journeysLoading,
				journeyServerError,
				textMessagePreviewsLoading,
				textMessagePreviewServerError,
			}}
		>
			<GroupProvider groupState={[groups, setGroups]}>
				<AttendeeProvider attendeeState={[attendees, setAttendees]}>
					<JourneyProvider journeyState={[journeys, setJourneys]}>
						<TextMessagePreviewProvider TextMessagePreviewState={[textMessagePreviews, setTextMessagePreviews]}>
							<AdminToolsProvider>{props.children}</AdminToolsProvider>
						</TextMessagePreviewProvider>
					</JourneyProvider>
				</AttendeeProvider>
			</GroupProvider>
		</AppDataReactContext.Provider>
	);
}
