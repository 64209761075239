import {
	Card,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import PeopleIcon from "@material-ui/icons/Person";
import React, { useEffect, useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import Attendee from "../../../entities/Attendee";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { orderBy } from "../../../utillity/orderBy";
import { usePeopleRouter } from "../PeoplePage";

const usePeopleStyles = makeStyles((theme: Theme) => ({
	sortSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 70,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
	personIcon: {
		backgroundColor: theme.palette.primaryResponse.main,
		color: "#FFF",
		borderRadius: "50%",
		height: 30,
		width: 30,
		padding: 3,
	},
}));

interface PeopleMobileListingProps {
}

enum SortMobile {
	MostRecentSMS,
	OldestSMS,
	NameAsc,
	NameDesc,
}

export default function PeopleMobileListing(props: PeopleMobileListingProps){
	const classes = usePeopleStyles();
	const peopleRouter = usePeopleRouter();
	const attendeeContext = useAttendees();
	const { attendees } = attendeeContext;

	const [searchText, startSearchPeople] = useState("");
	const [filteredAttendees, setFilteredAttendees] = useState(attendees);
	const [sortReadable, setSortReadable] = useState(SortMobile.NameAsc);


	useEffect(() => {
		let filteredResponses = [...attendees];

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredResponses = filteredResponses.filter((response) =>
				(response.name + response.phoneNumber).toLowerCase().includes(queryText)
			);
		}

		switch (sortReadable) {
			case SortMobile.NameAsc:
				filteredResponses = filteredResponses.sort(orderBy.string((a) => a.name, "Ascending"));
				break;
			case SortMobile.NameDesc:
				filteredResponses = filteredResponses.sort(orderBy.string((a) => a.name, "Descending"));
				break;
			case SortMobile.MostRecentSMS:
				filteredResponses = filteredResponses.sort(orderBy.date((a) => a.lastActivityDate, "Descending"));
				break;
			case SortMobile.OldestSMS:
				filteredResponses =filteredResponses.sort(orderBy.date((a) => a.lastActivityDate, "Ascending"));
				break;
		}
		setFilteredAttendees(filteredResponses);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText, sortReadable, attendees]);

	const changeSort = (sortValue: SortMobile) => {
		switch (sortValue) {
			case SortMobile.MostRecentSMS: {
				return setSortReadable(SortMobile.MostRecentSMS);
			}
			case SortMobile.OldestSMS: {
				return setSortReadable(SortMobile.OldestSMS);
			}
			case SortMobile.NameAsc: {
				return setSortReadable(SortMobile.NameAsc);
			}
			case SortMobile.NameDesc: {
				return setSortReadable(SortMobile.NameDesc);
			}
		}
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Card style={{padding: 12}}>
					<Grid container alignItems="center" style={{marginBottom: 5}}>
						<Typography variant="h2">
							{filteredAttendees.length} People
						</Typography>
						<GridGrow />
						<FormControl>
							<Select
								disableUnderline
								labelId="sort-people-select-label"
								id="sort-people-select"
								value={sortReadable}
								className={classes.sortSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
								changeSort(event.target.value as SortMobile)
								}
							>
								<MenuItem value={SortMobile.MostRecentSMS}>Most Recent</MenuItem>
								<MenuItem value={SortMobile.OldestSMS}>Oldest</MenuItem>
								<MenuItem value={SortMobile.NameAsc}>A-Z</MenuItem>
								<MenuItem value={SortMobile.NameDesc}>Z-A</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="outlined-bare"
							defaultValue={searchText}
							margin="normal"
							variant="outlined"
							className={classes.searchBar}
							onChange={(e) => startSearchPeople(e.currentTarget.value)}
							size="small"
							InputProps={{
								startAdornment: <Search style={{ marginLeft: -6 }} />,
							}}
							style={{ width: "100%", marginTop: 0 }}
							/>
					</Grid>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					{filteredAttendees.length === 0
					?
					<Grid item xs={12}>
						<Card style={{paddingLeft: 12, paddingRight: 12, height: 40}}>
							<Grid container direction="row" alignItems="center">
								<Typography variant="h4">
									No matches for {searchText}
								</Typography>
							</Grid>
						</Card>
					</Grid>
					:
					filteredAttendees.map((attendee: Attendee, index: number) => {
					return (
						<Grid item xs={12} key={index}						>
							<Card onClick={() => peopleRouter.selectAttendee(attendee)} style={{paddingLeft: 12, paddingRight: 12}}>
								<Grid container direction="row" alignItems="center">
									<Typography variant="h4">
										{attendee.name}
									</Typography>
									<GridGrow />
									<IconButton>
										<PeopleIcon className={classes.personIcon} />
									</IconButton>
								</Grid>
							</Card>
						</Grid>
						);
					})}
				</Grid>
			</Grid>
		</Grid>
	);
};