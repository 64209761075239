import { TextField, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { v4 as uuid } from "uuid";
import { ShortAnswer } from "../../../entities/Forms/Answer";
import { QuestionType, ShortAnswerQuestion } from "../../../entities/Forms/Question";
import { FormError } from "../../../pages/Forms/FormResponder";
import { useAnswerCommonstyles } from "../AnswerInput";

interface ShortAnswerInputProps {
	question: ShortAnswerQuestion;
	answer?: ShortAnswer;
	onAnswerChanged: (answer: ShortAnswer) => void;
	onAnswerDeleted: (question: ShortAnswerQuestion) => void;
	attendeeId: string;
	error: FormError[];
}

export function ShortAnswerInput(props: ShortAnswerInputProps) {
	const { question, answer, attendeeId, error } = props;
	const classes = useAnswerCommonstyles();

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const text = e.target.value;

		if (text.trim().length > 0) {
			props.onAnswerChanged({
				id: answer ? answer.id : uuid(),
				questionId: question.id,
				questionType: QuestionType.ShortAnswer,
				text,
				attendeeId: attendeeId,
			});
		} else {
			props.onAnswerDeleted(question);
		}
	};

	return (
		<div className={classNames(classes.answerContainer, classes.shortPrompAnswerContainer)}>
			<div className={classes.promptQuestionShortAnswer}>
				<div style={{ display: "flex", width: "100%" }}>
					<span style={{ fontWeight: "bold", fontSize: 16 }}>
						<span className={classes.numberQuestion}>{question.index + 1}.</span>
						<span className={classes.prompt}>{question.prompt}</span>
					</span>
					{question.required && (
						<Typography className={classes.required} variant="subtitle1">
							*Required
						</Typography>
					)}
					<div className={classes.grow} />
					{answer && (
						<Typography
							onClick={() => props.onAnswerDeleted(question)}
							className={classes.resetHeader}
							variant="subtitle1"
						>
							(Reset)
						</Typography>
					)}
				</div>
			</div>
			<TextField
				variant="outlined"
				placeholder="Type short answer here"
				multiline
				rowsMax={6}
				onChange={(e) => onChange(e)}
				value={answer ? answer.text : ""}
				helperText={error.map((a) => `${a.error} `.trim())}
				error={error.length > 0}
				className={classes.inputBox}
			/>
		</div>
	);
}
