type OptionalDirection = "optionalStart" | "optionalEnd";
export type SortDirection = "Ascending" | "Descending";

export const orderBy = {
	optional: {
		date<T>(
			selector: (obj: T) => Date | undefined | null,
			direction: SortDirection,
			order: OptionalDirection
		): (a: T, b: T) => number {
			return (a, b) => {
				const dateA = selector(a);
				const dateB = selector(b);

				if (dateA === dateB) {
					return 0;
				}

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (dateA == null || dateB == null) {
					return optionalDirection;
				}

				if (direction === "Ascending") {
					return dateA.getTime() - dateB.getTime();
				}

				return dateB.getTime() - dateA.getTime();
			};
		},
		number<T>(
			selector: (obj: T) => number | undefined,
			direction: SortDirection,
			order: OptionalDirection
		): (a: T, b: T) => number {
			return (a, b) => {
				const numberA = selector(a);
				const numberB = selector(b);

				if (numberA === numberB) {
					return 0;
				}

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (numberA == null || numberB == null) {
					return optionalDirection;
				}

				if (direction === "Ascending") {
					return numberA - numberB;
				}
				return numberB - numberA;
			};
		},
		string<T>(
			selector: (obj: T) => string | undefined | null,
			direction: SortDirection,
			order: OptionalDirection
		): (a: T, b: T) => number {
			return (a, b) => {
				let s1FieldValue = selector(a);
				let s2FieldValue = selector(b);

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (s1FieldValue == null || s2FieldValue == null) {
					return optionalDirection;
				}

				s1FieldValue = s1FieldValue.toLowerCase();
				s2FieldValue = s1FieldValue.toLowerCase();

				const value = s1FieldValue.localeCompare(s2FieldValue);
				if (value > 0) {
					return direction === "Ascending" ? 1 : -1;
				}

				if (value < 0) {
					return direction === "Ascending" ? -1 : 1;
				}

				return 0;
			};
		},
	},
	date<T>(selector: (obj: T) => Date, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) =>
			direction === "Ascending"
				? selector(a).getTime() - selector(b).getTime()
				: selector(b).getTime() - selector(a).getTime();
	},
	number<T>(selector: (obj: T) => number, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => (direction === "Ascending" ? selector(a) - selector(b) : selector(b) - selector(a));
	},
	string<T>(selector: (obj: T) => string, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => {
			const s1FieldValue = selector(a).toLowerCase();
			const s2FieldValue = selector(b).toLowerCase();

			const value = s1FieldValue.localeCompare(s2FieldValue);
			if (value > 0) {
				return direction === "Ascending" ? 1 : -1;
			}

			if (value < 0) {
				return direction === "Ascending" ? -1 : 1;
			}

			return 0;
		};
	},
};
