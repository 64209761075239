export const NumberHelper = {
	convertToDollarsReturnsSymbol: (n: number) => {
		const formattedNumber = parseFloat(n.toFixed(2)) / 1;
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		})
			.format(formattedNumber)
			.replace(/\.00$/, "");
	},
	convertToDollarsReturnsNumber: (n: number) => {
		const formattedNumber = parseFloat(n.toPrecision(2)) / 1;
		return formattedNumber.toFixed(2);
	},
	
};
