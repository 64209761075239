import { Dispatch, SetStateAction, useEffect, useState } from "react";
import urlRegex from "url-regex";
import { ServerResult } from "../services/server/ServerResult";
import { CreateShortLinkModel, ShortLink, ShortLinkService } from "../services/ShortLinkService";

interface AutoLinkShortenerContext {
    state: [string, Dispatch<SetStateAction<string>>];
    hasShortLinks: boolean;
    processingLinks: boolean;
}

export function useAutoShortLinkState(initialText: string, selectionIndex: number | null, options?: {groupId?: string}): AutoLinkShortenerContext 
{   
    const [textWithLinks, setTextWithLinks] = useState(initialText);
    const [detectedUrls, setDetectedUrls] = useState<{ url: string; isBeingTyped: boolean }[]>([]);
    const [processingUrls, setProcessingUrls] = useState<string[]>([]);
	const [shortLinks, setShortLinks] = useState<ShortLink[]>([]);

    useEffect(() => {
		const regex = urlRegex();
		const matches: string[] = textWithLinks.match(regex) ?? [];

		const uniqueMatches = Array.from(new Set(matches))
			.filter((url) => !url.includes("rspns.to"))
			.map((url) => ({ url, isBeingTyped: isBeingTyped(textWithLinks, url, selectionIndex) })); 
		setDetectedUrls(uniqueMatches);
	}, [textWithLinks, selectionIndex]);

    useEffect(() => {
		if (processingUrls.length > 0) return;

		const processedOriginalUrls = new Set(shortLinks.map((p) => p.url));
		let urlsToProcess = detectedUrls
            .filter((detectedUrl) => !processedOriginalUrls.has(detectedUrl.url))
            .filter((detectedUrl) => !detectedUrl.isBeingTyped);

		if (urlsToProcess.length > 0) {
			setProcessingUrls(urlsToProcess.map((detectedUrl) => detectedUrl.url));
		}
	}, [detectedUrls, processingUrls, shortLinks]);

    useEffect(() => {
		if (processingUrls.length === 0) return;

		const requestModel: CreateShortLinkModel = {
			createShortLinkRequests: processingUrls.map((url) => ({ url, groupId: options?.groupId })),
		};

		async function shortenUrl() {
			const response = await ShortLinkService.create(requestModel);
			if (ServerResult.isSuccess(response)) {
				setShortLinks((sl) => [...sl, ...response.data]);
			}
			setProcessingUrls([]);
		}

		if (requestModel.createShortLinkRequests.length > 0) {
			shortenUrl();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processingUrls]);

    useEffect(() => {
		const updatedText = textWithLinks
			.split(" ")
			.map((messagePart) => {
				const matchingShortLink = shortLinks.find((sl) => sl.url === messagePart);
				if (matchingShortLink && !isBeingTyped(textWithLinks, matchingShortLink.url, selectionIndex)) {
					return matchingShortLink.shortLink;
				}
				return messagePart;
			})
			.join(" ");

		if (updatedText !== textWithLinks) {
			setTextWithLinks(updatedText);
		}
	}, [textWithLinks, shortLinks, selectionIndex]);

    const processingLinks = processingUrls.length > 0 || detectedUrls.length > 0;

    return {state: [textWithLinks, setTextWithLinks], hasShortLinks: shortLinks.length > 0, processingLinks}
}

const isBeingTyped = (message: string, url: string, selectionIndex: number | null) => {
    if(selectionIndex == null)
        return false;

	const urlIndexStart = message.indexOf(url);
	const urlIndexEnd = urlIndexStart + url.length;
	return selectionIndex >= urlIndexStart && selectionIndex <= urlIndexEnd;
};