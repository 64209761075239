import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

export function ResponseDialogContent(props: React.PropsWithChildren<{}>) {
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	if (mobileFormFactor) {
		return (
			<Grid item xs={12} style={{ height: "100%" }}>
				<Grid container direction="column" justify="center" style={{ height: "100%"}}>
					<Grid item>{props.children}</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<div style={{ marginTop: 10, marginLeft: 20, marginRight: 20, minHeight: 450, width: "100%"}}>
			{props.children}
		</div>
	);
}
