import { Button, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import GoBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { OverviewFormDigest } from "../../components/Forms/Results/OverviewFormDigest";
import { ParticipantAnswersData, ParticipantsFormDigest } from "../../components/Forms/Results/ParticipantsFormDigest";
import { QuestionsFormDigest } from "../../components/Forms/Results/QuestionsFormDigest";
import LoadingSpinner from "../../components/loaderSpinner";
import Attendee from "../../entities/Attendee";
import { FormDigestTab } from "../../entities/formDigestTabs";
import { Answer } from "../../entities/Forms/Answer";
import { Form } from "../../entities/Forms/Form";
import { QuestionType } from "../../entities/Forms/Question";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { routes } from "../../routes";
import AttendeeService from "../../services/AttendeeService";
import { FormService } from "../../services/FormService";
import { ServerResult } from "../../services/server/ServerResult";

const useFormDigestStyles = makeStyles((theme: Theme) => ({
	formDigestRoot: {
		position: "relative",
		paddingLeft: 16,
		[theme.breakpoints.down("sm")]: {
			//backgroundColor: theme.palette.lightGrayBlue.main,
			height: "100vh",
			paddingLeft: 0,
			backgroundColor: "#FFF",
		},
	},
	contentPaper: {
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: 20,
		[theme.breakpoints.down("md")]: {
            boxShadow: "none"
        }
	},
	pageHeader: {
		color: theme.palette.secondaryResponse.main,
	},
	backText: {
		marginBottom: 40,
		color: theme.palette.secondaryResponse.main,
		[theme.breakpoints.down("sm")]: {
			backgroundColor: "#f3f3f3",
			marginBottom: 0,
			paddingBottom: 12,
		},
	},
	hiddenTab: {
		opacity: 0.4,
	},
	pageTabs: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 200,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
		},
	},
	tabIndicator: {
		backgroundColor: theme.palette.secondaryResponse.main,
		height: 3,
		position: "absolute",
		top: 24,
		transitionProperty: "all",
		transitionDuration: "300ms",
		transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
		transitionDelay: "0ms",
	},
	defaultTabStyle: {
		textAlign: "center",
		height: 48,
		boxShadow: "0 2px inset #d6d6d6, 0 -1px #c4c4c4",
		color: theme.palette.secondaryResponse.main,
		backgroundColor: theme.palette.lightGrayBlue.main,
		fontSize: 18,
		paddingTop: 12,
	},
	selectedTabStyle: {
		textDecoration: "underline",
		boxShadow: "0 -3px #dfe1e1",
		backgroundColor: "#FFF",
		fontWeight: 700,
	},
	grow: { flex: 1 },
	exportCSV: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		"&:hover": {
			opacity: 0.7,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
	},
	downloadButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		height: 40,
		marginTop: 16,
		marginRight: 20,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		},
	},
}));

const FormDigest = () => {
	const classes = useFormDigestStyles();

	const [, setPageTitle] = usePageTitle();
	setPageTitle("Form Results");

	const params = useParams<{ id: string; journeyId: string; categoryId: string; journeyType: string }>();
	const formId = params.id;
	const journeyId = params.journeyId;
	const categoryId = params.categoryId;

	const history = useHistory();
	const theme = useTheme();
	const setServerErrorAlert = useServerErrorAlert();
	const [answers, setAnswers] = useState<Answer[]>([]);
	const [form, setForm] = useState<Form | undefined>();
	const [attendees, setAttendees] = useState<Attendee[]>([]);

	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const [selectedTab, setSelectedTab] = useState(FormDigestTab.Overview);
	const tabIndicatorXPos = selectedTab === FormDigestTab.Overview ? 0 : selectedTab === FormDigestTab.Questions ? 200 : 400;
	const tabIndicatorWidth = selectedTab === FormDigestTab.Overview ? 100 : selectedTab === FormDigestTab.Questions ? 105 : 123;
	useEffect(() => {
		async function getForm(formId: string) {
			const results = await Promise.all([
				FormService.get(formId),
				FormService.getResults(formId),
				AttendeeService.getAttendeesForChurch(),
			]);

			const [formResponse, resultsResponse, attendeeResponse] = results;

			if (
				ServerResult.isError(formResponse) ||
				ServerResult.isError(resultsResponse) ||
				ServerResult.isError(attendeeResponse)
			) {
				setServerErrorAlert({ message: "The form data was unable to load, please try again.", statusCode: 0 });
				history.goBack();
			} else {
				setForm(formResponse.data);
				setAnswers(resultsResponse.data);
				setAttendees(attendeeResponse.data);
			}
		}

		getForm(formId);
	}, [formId, setServerErrorAlert, history]);

	const renderLoadingPage = () => {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={false} durationMessage={"Loading..."} />
			</div>
		);
	};

	const downloadCSV = () => {
		if (!form) {
			return;
		}

		const rows = [["Name", "Phone Number", form.questions.map((item) => item.prompt)]];

		let uniqueAttendees: ParticipantAnswersData[] = [];

		answers.forEach((answer) => {
			let index = uniqueAttendees.findIndex((a) => a.attendeeId === answer.attendeeId);
			let question = form.questions.find((a) => a.id === answer.questionId);

			if (index < 0) {
				let attendee = attendees.find((a) => a.id === answer.attendeeId);

				uniqueAttendees.push({
					attendeeId: answer.attendeeId,
					attendeeName: attendee ? attendee.name : "",
					attendeePhoneNumber: attendee ? attendee.phoneNumber : "",
					answers: [
						{
							questionPrompt: question ? question.prompt : "Unknown Question",
							questionType: question?.questionType,
							questionId: question?.id,
							answer: answer,
						},
					],
				});
			} else {
				uniqueAttendees[index].answers = [
					...uniqueAttendees[index].answers,
					{
						questionPrompt: question ? question.prompt : "Unknown Question",
						questionType: question?.questionType,
						questionId: question?.id,
						answer: answer,
					},
				];
			}
		});

		uniqueAttendees.forEach((attendee) => {
			let row = [];
			row.push(attendee.attendeeName);
			row.push(attendee.attendeePhoneNumber);

			form.questions.forEach((question) => {
				let answerToQuestion = attendee.answers.find((a) => a.questionId === question.id);

				if (answerToQuestion) {
					if (answerToQuestion.answer.questionType === QuestionType.ShortAnswer) {
						row.push(`"${answerToQuestion.answer.text}"`);
					} else if (answerToQuestion.answer.questionType === QuestionType.RSVP) {
						row.push(answerToQuestion.answer.attendeeCount);
					} else if (answerToQuestion.answer.questionType === QuestionType.SingleAnswer) {
						row.push(answerToQuestion.answer.text);
					} else if (answerToQuestion.answer.questionType === QuestionType.MultipleAnswer) {
						row.push(answerToQuestion.answer.texts.join(";"));
					} else if (answerToQuestion.answer.questionType === QuestionType.SignupList) {
						let list: string[] = [];

						answerToQuestion.answer.signupItems.forEach((item) => {
							list.push(`${item.text} ${item.quantity}`);
						});

						row.push(list.join(";"));
					}
				} else {
					row.push("");
				}
			});

			rows.push(row);
		});

		let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${form.title.replace(/\s/g, "_")}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};


	const renderDesktop = () => {
		return (
			<div>
				<Typography className={classes.pageHeader} variant="h1">
					Form Results
				</Typography>
				<div style={{ display: "flex" }}>
					<Typography variant="subtitle1" className={classes.backText}>
						<Link
							to={routes.app.resolve.journeyDetailPage(+categoryId, journeyId)}
							style={{
								textDecoration: "none",
								color: theme.palette.secondaryResponse.main,
								display: "flex",
								marginTop: 4,
								cursor: "pointer",
							}}
						>
							<GoBackIcon /> <span>Back to journey</span>
						</Link>
					</Typography>
					<div className={classes.grow} />
					<Button
						variant="contained"
						className={classes.downloadButton}
						startIcon={<Publish style={{ transform: "scaleY(-1)" }} />}
						onClick={() => downloadCSV()}
					>
						Download CSV
					</Button>
				</div>

				<Grid container style={{ position: "relative" }}>
					<Grid item xs={12} style={{ display: "flex" }}>
						<Typography
							className={classNames(
								classes.pageTabs,
								selectedTab !== FormDigestTab.Overview ? classes.hiddenTab : null
							)}
							variant="h2"
							onClick={() => setSelectedTab(FormDigestTab.Overview)}
						>
							Overview
						</Typography>
						<Typography
							className={classNames(
								classes.pageTabs,
								selectedTab !== FormDigestTab.Questions ? classes.hiddenTab : null
							)}
							variant="h2"
							onClick={() => setSelectedTab(FormDigestTab.Questions)}
						>
							Questions
						</Typography>
						<Typography
							className={classNames(
								classes.pageTabs,
								selectedTab !== FormDigestTab.Participants ? classes.hiddenTab : null
							)}
							variant="h2"
							onClick={() => setSelectedTab(FormDigestTab.Participants)}
						>
							Participants
						</Typography>
					</Grid>
					<span className={classes.tabIndicator} style={{ left: tabIndicatorXPos, width: tabIndicatorWidth }}></span>
					<Grid style={{ marginRight: 16 }} item xs={12}>
						<Paper className={classes.contentPaper}>
							{selectedTab === FormDigestTab.Overview && (
								<OverviewFormDigest questions={form!.questions} answers={answers} />
							)}
							{selectedTab === FormDigestTab.Questions && (
								<QuestionsFormDigest questions={form!.questions} answers={answers} attendees={attendees} />
							)}
							{selectedTab === FormDigestTab.Participants && (
								<ParticipantsFormDigest questions={form!.questions} answers={answers} attendees={attendees} />
							)}
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	};

	const renderMobile = () => {
		return (
			<div>
				<Typography variant="subtitle1" className={classes.backText}>
					<Link
						to={routes.app.resolve.journeyDetailPage(+categoryId, journeyId)}
						style={{
							textDecoration: "none",
							color: theme.palette.secondaryResponse.main,
							display: "flex",
							marginTop: 4,
						}}
					>
						<GoBackIcon /> <span>Back to journey</span>
					</Link>
				</Typography>
				<Grid container style={{ marginTop: -4 }}>
					<Grid item xs={4}>
						<div
							style={{
								borderTopLeftRadius: 9,
								borderBottomRightRadius: selectedTab === FormDigestTab.Questions ? 4 : undefined,
							}}
							className={classNames(
								classes.defaultTabStyle,
								selectedTab === FormDigestTab.Overview ? classes.selectedTabStyle : undefined
							)}
							onClick={() => setSelectedTab(FormDigestTab.Overview)}
						>
							Overview
						</div>
					</Grid>
					<Grid item xs={4}>
						<div
							style={{
								borderBottomLeftRadius: selectedTab !== FormDigestTab.Questions ? 4 : undefined,
								borderBottomRightRadius: selectedTab !== FormDigestTab.Questions ? 4 : undefined,
							}}
							className={classNames(
								classes.defaultTabStyle,
								selectedTab === FormDigestTab.Questions ? classes.selectedTabStyle : undefined
							)}
							onClick={() => setSelectedTab(FormDigestTab.Questions)}
						>
							Questions
						</div>
					</Grid>
					<Grid item xs={4}>
						<div
							style={{
								borderTopRightRadius: 9,
								borderBottomLeftRadius: selectedTab === FormDigestTab.Questions ? 4 : undefined,
							}}
							className={classNames(
								classes.defaultTabStyle,
								selectedTab === FormDigestTab.Participants ? classes.selectedTabStyle : undefined
							)}
							onClick={() => setSelectedTab(FormDigestTab.Participants)}
						>
							Participants
						</div>
					</Grid>
				</Grid>
				<SwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={selectedTab}
					onChangeIndex={(index: FormDigestTab) => setSelectedTab(index)}
					style={{ backgroundColor: "#FFF" }}
				>
					<div hidden={selectedTab !== FormDigestTab.Overview} dir={theme.direction}>
						<OverviewFormDigest questions={form!.questions} answers={answers} />
					</div>
					<div role="tabpanel" hidden={selectedTab !== FormDigestTab.Questions} dir={theme.direction}>
						<QuestionsFormDigest questions={form!.questions} answers={answers} attendees={attendees} />
					</div>
					<div role="tabpanel" hidden={selectedTab !== FormDigestTab.Participants} dir={theme.direction}>
						<ParticipantsFormDigest questions={form!.questions} answers={answers} attendees={attendees} />
					</div>
				</SwipeableViews>
			</div>
		);
	};

	if (!form) return renderLoadingPage();

	return <div className={classes.formDigestRoot}>{mobileFormFactor ? renderMobile() : renderDesktop()}</div>;
};

export default FormDigest;
