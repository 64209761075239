import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Journey, JourneyCategory, JourneyType } from "../../entities/Journey";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { InformationalJourneyService } from "../../services/InformationalJourneyService";
import { CreateJourneyRequest, UpdateJourneyRequest } from "../../services/JourneyService";
import { PromptJourneyService } from "../../services/PromptJourneyService";
import { SermonPromptJourneyService } from "../../services/SermonPromptJourneyService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../services/server/ServerValidationError";
import { Enum } from "../../utillity/Enum";
import { JourneyInitializer } from "../../utillity/JourneyInitializer";
import { JourneySetupStep, JourneySetupStepsHelper, JourneyStep } from "../../utillity/JourneySetupStepsHelper";
import { JourneySetUpStepView } from "./JourneySetUpStepView";
import { JourneySetupProgressContext } from "./useJourneySetupProgress";

const useStyles = makeStyles(() => ({
	backdropPaper: {
		maxWidth: 1000,
		overflow: "hidden",
	},
	mobileBackdrop: {
		backgroundColor: "#F5F6F7",
	},
}));

export enum CreateJourneyType {
	Volunteer_Sign_Up,
	Event_Sign_Up,
	Group_Sign_Up,
	Gather_Emails,
	Survey_Your_Church,
	Share_A_Link,
	Custom,
}

export enum FieldErrorStrings {
	Keyword = "KeywordTrigger.Keyword",
	Event = "Event.NextEventTime",
	MessageSchedule = "PromptMessageSchedule",
	Event_Past_Due = "UpdateEventRequest.NextEventTime",
}

type CreateProps = {
	mode: "create";
	onCreate: (journey: Journey) => void;
	onClose: () => void;
	type: CreateJourneyType;
};

type UpdateProps = {
	mode: "update";
	journey: Journey;
	onUpdate: (journey: Journey) => void;
	onClose: () => void;
};

export type JourneySetupProps = UpdateProps | CreateProps;

function JourneySetup(props: JourneySetupProps) {
	const classes = useStyles();
	const theme = useTheme();
	const setServerErrorAlert = useServerErrorAlert();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const [progressJourney, setStateJourney] = useState<Journey>(JourneyInitializer.getInitialJourney(props));

	const [steps, setSteps] = useState(
		JourneySetupStepsHelper.getInitialJourneySetupSteps(
			progressJourney,
			mobileFormFactor,
			props.mode === "create" ? props.type : undefined
		)
	);
	const [step, setStep] = useState(steps[0]);

	const [disabled, setDisabled] = useState(false);
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);
	const [jumpToStep, setJumpToStep] = useState<JourneyStep | undefined>(undefined);

	useEffect(() => {
		function setActiveStep() {
			if (jumpToStep != null) {
				const nextStep = steps.find((s) => s.step === jumpToStep);

				if (nextStep != null) {
					setStep(nextStep);
				}
			}
		}
		setActiveStep();
	}, [jumpToStep, steps]);

	const getJourneyName = (journey: Journey) => {
		switch (journey.type) {
			case JourneyType.Connect_Card:
			case JourneyType.Prayer_Requests:
			case JourneyType.Sermon_Prompts:
				return journey.name;
			default:
				return journey.keywordTrigger;
		}
	};

	const createJourney = (journey: Journey) => {
		let baseRequest: CreateJourneyRequest = {
			name: getJourneyName(journey),
			type: journey.type,
			keywordTrigger: journey.keywordTrigger,
			completionResponse: journey.completionResponse,
			extras: {
				...journey.extras,
			},
		};

		if (journey.category === JourneyCategory.Prompt) {
			return PromptJourneyService.create({ ...baseRequest, prompt: journey.prompt });
		}

		if (journey.category === JourneyCategory.Informational) {
			return InformationalJourneyService.create({ ...baseRequest, createEvent: journey.event });
		}

		return InformationalJourneyService.create(baseRequest);
	};

	const updateJourney = (journey: Journey) => {
		let baseRequest: UpdateJourneyRequest = {
			name: getJourneyName(journey),
			churchId: journey.churchId,
			journeyId: journey.id,
			keywordTrigger: journey.keywordTrigger,
			completionResponse: journey.completionResponse,
			enabled: true,
			extras: journey.extras,
		};

		if (journey.category === JourneyCategory.Prompt) {
			return PromptJourneyService.update({ ...baseRequest, prompt: journey.prompt });
		}

		if (journey.category === JourneyCategory.Sermon_Prompts) {
			return SermonPromptJourneyService.update({ ...baseRequest, schedule: journey.promptMessageSchedule });
		}

		if (journey.category === JourneyCategory.Informational) {
			return InformationalJourneyService.update({ ...baseRequest, updateEvent: journey.event });
		}

		return InformationalJourneyService.update(baseRequest);
	};

	const onStepNotFound = (journeyStep: JourneyStep) => {
		setServerErrorAlert({
			statusCode: 400,
			message: `Invalid Journey setup: (Cannot mount ${Enum.print(JourneyStep, journeyStep)})`,
		});
		props.onClose();
	};

	const completeStep = async (journey: Journey) => {
		setStateJourney(journey);
		if (step.nextStep) {
			goToStep(step.nextStep);
		} else {
			await save(journey);
		}
	};

	const backStep = async (journey: Journey) => {
		setStateJourney(journey);
		if (step.prevStep !== undefined) {
			goToStep(step.prevStep);
		} else {
			props.onClose();
		}
	};

	const updateJourneyState = (journey: Journey) => {
		setStateJourney(journey);
	};

	const goToStep = (step: JourneyStep) => {
		const nextStep = steps.find((s) => s.step === step);
		setStep(nextStep!);
	};

	const save = async (journey: Journey) => {
		setDisabled(true);
		let response = await (props.mode === "create" ? createJourney(journey) : updateJourney(journey));
		setDisabled(false);

		if (ServerResult.isSuccess(response)) {
			
			if (props.mode === "create") {
				props.onCreate(response.data);
			} else {
				props.onUpdate(response.data);
			}
		} else if (ServerResult.isValidationError(response)) {
			setFieldErrors(response.errors);

			if (FieldValidationErrorUtils.isFieldInError(response.errors, FieldErrorStrings.Keyword)) {
				goToStep(JourneyStep.Keyword);
			} else if (FieldValidationErrorUtils.isFieldInError(response.errors, FieldErrorStrings.Event)) {
				goToStep(JourneyStep.EventCalendar);
			} else if (FieldValidationErrorUtils.isFieldInError(response.errors, FieldErrorStrings.MessageSchedule)) {
				goToStep(JourneyStep.SermonPromptSchedule);
			}
		} else {
			setServerErrorAlert(response);
		}
	};

	const addStepGoToStep = (steps: JourneySetupStep[], step: JourneyStep) => {
		setSteps(steps);
		setJumpToStep(step);
	};

	const removeStepUpdateJourney = (steps: JourneySetupStep[], journey: Journey) => {
		setSteps(steps);
		setStateJourney(journey);
	};

	return (
		<Dialog
			open={true}
			onClose={props.onClose}
			aria-labelledby="journey-setup-modal"
			aria-describedby="journey-setup-modal-description"
			classes={{
				paper: classNames(classes.backdropPaper, mobileFormFactor ? classes.mobileBackdrop : undefined),
			}}
			fullScreen={mobileFormFactor}
		>
			<JourneySetupProgressContext.Provider
				value={{
					step,
					steps,
					updateMode: props.mode === "update",
					goToStep,
					closeSetup: props.onClose,
					disabled,
					setDisabled,
					fieldErrors,
					setFieldErrors,
					completeStep,
					backStep,
					addStepGoToStep,
					removeStepUpdateJourney,
					updateJourney: updateJourneyState,
				}}
			>
				<JourneySetUpStepView currentStep={step} journey={progressJourney} onStepNotFound={onStepNotFound} />
			</JourneySetupProgressContext.Provider>
		</Dialog>
	);
}

export default JourneySetup;
