import React, { useEffect, useState } from "react";
import {
	Grid,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { orderBy, SortDirection } from "../../../utillity/orderBy";
import { Group } from "../../../entities/Groups/Group";
import { GroupMembershipStatus } from "../../../entities/GroupMembershipStatus";

const useGroupListingStyle = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
	},
}));

interface GroupListingTableProps {
	groups: Group[];
    onSelect: (group: Group) => void;
}

export const GroupListingTable = (props: GroupListingTableProps) => {
	const [sortField, setSortField] = useState<"groupName" | "members" | "messagesSent" | "creator">("groupName");

	const classes = useGroupListingStyle();

	const [searchText, startSearchItems] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");
	const [displayedGroups, setDisplayedGroups] = useState(props.groups);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	useEffect(() => {
		let sortedGroups = props.groups;

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			sortedGroups = sortedGroups.filter((listItem) =>
				(listItem.name).toLowerCase().includes(queryText)
			);
		}
		switch (sortField) {
			case "groupName":
				sortedGroups.sort(orderBy.string((i) => i.name, sortDirection));
				break;
            case"members":
                sortedGroups.sort(orderBy.number((i) => i.groupMembers.length, sortDirection));
                break;
            case"messagesSent":
                sortedGroups.sort(orderBy.number((i) => i.messagesToGroup.length, sortDirection));
                break;
            default:
				break;
		}

		setDisplayedGroups(sortedGroups);
		setPage(0);
	}, [searchText, sortDirection, props.groups, sortField]);

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid container>
					<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							{displayedGroups.length} Group{displayedGroups.length === 1 ? "" : "s"}
						</Typography>
					</Grid>
					<div className={classes.grow}/>
					<Grid item>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchItems(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
						/>
					</Grid>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid container>
					<div className={classes.grow}/>
					<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={displayedGroups.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
							onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
							labelRowsPerPage="Show"
						/>
				</Grid>
					<Grid item xs={12} style={{ marginTop: 8 }}>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
											sortDirection={
												sortField === "groupName"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "groupName"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("groupName");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Group Name
											</TableSortLabel>
										</TableCell>
										<TableCell
											align="left"
											sortDirection={
												sortField === "members"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "members"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("members");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Active Members
											</TableSortLabel>
										</TableCell>
										<TableCell
											align="left"
											sortDirection={
												sortField === "members"
													? sortDirection === "Ascending"
														? "asc"
														: "desc"
													: false
											}
										>
											<TableSortLabel
												active={sortField === "messagesSent"}
												direction={sortDirection === "Ascending" ? "asc" : "desc"}
												onClick={() => {
													setSortField("messagesSent");
													setSortDirection(
														sortDirection === "Ascending" ? "Descending" : "Ascending"
													);
												}}
											>
												Messages Sent
											</TableSortLabel>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{displayedGroups.length === 0 && (
										<TableRow>
											<TableCell>
												<Typography style={{ paddingTop: 24 }} variant="h4">
													No Groups
												</Typography>
											</TableCell>
											<TableCell />
											<TableCell />
										</TableRow>
									)}
									{(rowsPerPage > 0
										? displayedGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: displayedGroups
									).map((group: Group, index: number) => {
										return (
											<TableRow
												key={index}
												hover
												onClick={() => props.onSelect(group)}
												onDoubleClick={() => props.onSelect(group)}
											>
												<TableCell>{group.name}</TableCell>
												<TableCell>{group.groupMembers.filter(m => m.groupMembershipStatus !== GroupMembershipStatus.Declined).length}</TableCell>
												<TableCell>{group.messagesToGroup.length}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
			</Grid>
		</div>
	);
};