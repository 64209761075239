import { CreateJourneyType } from "../components/JourneySetup/journeySetUp";
import { Journey, JourneyCategory, JourneyType } from "../entities/Journey";

export interface JourneySetupStep {
	prevStep?: JourneyStep;
	step: JourneyStep;
	nextStep?: JourneyStep;
}

export enum JourneyStep {
	Keyword,
	MobileKeywordAndPreview,
	SermonPromptSchedule,
	EventCalendar,
	Address,
	Form,
	Link,
	Media,
	JourneyPreview,
}

const pushStep = (steps: JourneySetupStep[], step: JourneyStep) => {
	const lastStep = steps[steps.length - 1];
	lastStep.nextStep = step;
	steps.push({ prevStep: lastStep.step, step });
};

export const JourneySetupStepsHelper = {
	getInitialJourneySetupSteps: (
		journey: Journey,
		mobileFormFactor: boolean,
		createJourneyType?: CreateJourneyType
	): JourneySetupStep[] => {
		const event = journey.category === JourneyCategory.Informational ? journey.event : undefined;
		const { address, formId, link, mediaLink } = journey.extras;

		// Dumb mobile only use case to make a single step setup
		// if (mobileFormFactor && !event && !address && !formId && !link && !mediaLink) {
		// 	return [{ prevStep: undefined, step: JourneyStep.MobileKeywordAndPreview, nextStep: undefined }];
		// }

		const steps: JourneySetupStep[] = [{ prevStep: undefined, step: JourneyStep.Keyword }];

		if (journey.type === JourneyType.Sermon_Prompts) {
			pushStep(steps, JourneyStep.SermonPromptSchedule);
		}

		if (
			event ||
			createJourneyType === CreateJourneyType.Event_Sign_Up ||
			createJourneyType === CreateJourneyType.Group_Sign_Up
		) {
			pushStep(steps, JourneyStep.EventCalendar);
		}

		if (
			address ||
			createJourneyType === CreateJourneyType.Event_Sign_Up ||
			createJourneyType === CreateJourneyType.Group_Sign_Up
		) {
			pushStep(steps, JourneyStep.Address);
		}

		if (formId || createJourneyType === CreateJourneyType.Survey_Your_Church) {
			pushStep(steps, JourneyStep.Form);
		}

		if (link || createJourneyType === CreateJourneyType.Share_A_Link) {
			pushStep(steps, JourneyStep.Link);
		}

		if (mediaLink) {
			pushStep(steps, JourneyStep.Media);
		}

		pushStep(steps, JourneyStep.JourneyPreview);

		return steps;
	},

	insertNewStep(steps: JourneySetupStep[], step: JourneyStep) {
		const firstSteps = [...steps];
		// This removes the last two steps from firstSteps as well
		const lastTwoSteps = firstSteps.splice(steps.length - 2);

		const oldPrevStep = lastTwoSteps[0];
		const oldNextStep = lastTwoSteps[1];

		const prevStep: JourneySetupStep = { ...oldPrevStep, nextStep: step };
		const nextStep: JourneySetupStep = { ...oldNextStep, prevStep: step };
		const newStep: JourneySetupStep = {
			step: step,
			nextStep: nextStep.step,
			prevStep: prevStep.step,
		};

		return [...firstSteps, prevStep, newStep, nextStep];
	},

	removeStep(steps: JourneySetupStep[], step: JourneyStep) {
		const stepsCopy = [...steps];

		const indexOfStep = stepsCopy.findIndex((s) => s.step === step);
		if (indexOfStep === -1) {
			return stepsCopy;
		}

		// Removes element from array by index
		let removedStep = stepsCopy.splice(indexOfStep, 1);

		const oldPrevStep = removedStep[0].prevStep;
		const oldNextStep = removedStep[0].nextStep;

		stepsCopy[indexOfStep - 1].nextStep = oldNextStep;
		stepsCopy[indexOfStep].prevStep = oldPrevStep;

		return stepsCopy;
	},
};
