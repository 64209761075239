import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { LoadingPage } from "../../components/LoadingPage";
import { ServerErrorPage } from "../../components/ServerErrorPage";
import { Journey } from "../../entities/Journey";
import { useAppData } from "../../providers/AppDataProvider";
import JourneyDetailProvider from "../../providers/JourneyDetailProvider";
import { useJourneys } from "../../providers/JourneyProvider";
import { routes } from "../../routes";
import { JourneyDetailPage } from "./Components/JourneyDetail";
import JourneyListPage from "./JourneyListPage";

export function JourneyPage(){
    const params = useParams<{ journeyId?: string, category?: string, }>();
	const appData = useAppData();
	const { groupsLoading, groupServerError, attendeesLoading, attendeeServerError, journeysLoading, journeyServerError} = appData;	

	if (attendeesLoading || groupsLoading || journeysLoading) {
		return <LoadingPage />;
	}

	if(attendeeServerError)
		return <ServerErrorPage serverError={attendeeServerError}/>

	if(groupServerError)
		return <ServerErrorPage serverError={groupServerError}/>

	if(journeyServerError)
		return <ServerErrorPage serverError={journeyServerError}/>

    return (
        <JourneyRouterProvider journeyId={params.journeyId} category={params.category}>
            <JourneyPageRouter />
        </JourneyRouterProvider>
    );
}

interface JourneyRouterContext {
    selectedJourney: Journey | undefined;
	selectJourney: (journey: Journey | undefined) => void;
	deleteJourney: (journey: Journey) => void;
	updateJourney: (journey: Journey) => void;
}

const JourneyRouterReactContext = createContext<JourneyRouterContext>({} as any);

export function useJourneyRouter() {
	return useContext(JourneyRouterReactContext);
}

function JourneyRouterProvider(props: PropsWithChildren<{ category: string | undefined, journeyId: string | undefined }>) {
	const history = useHistory();
	const journeyContext = useJourneys();
	const { journeyId } = props

	const [selectedJourney, setSelectedJourney] = useState(journeyContext.journeys.find((j) => j.id === journeyId));

	useEffect(() => {
		setSelectedJourney(journeyContext.journeys.find((j) => j.id === journeyId));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [journeyContext.journeys, journeyId]);

	const onSelect = (journey: Journey | undefined) => {
		setSelectedJourney(journey);
        if(journey){
            history.push(routes.app.resolve.journeyDetailPage(journey.category, journey.id));
        } else {
            history.push(routes.app.resolve.journeyListPage());
        }
	};

	const onUpdate = (journey: Journey) => {
		journeyContext.updateJourney(journey);
	};

    const onDelete = (journey: Journey) => {
        journeyContext.removeJourney(journey);
        onSelect(undefined);
    }

	return (
		<JourneyRouterReactContext.Provider value={{ 
			selectedJourney: selectedJourney, 
			selectJourney: onSelect,
			deleteJourney: onDelete,
			updateJourney: onUpdate,
		}}>
			{props.children}
		</JourneyRouterReactContext.Provider>
	);
}

function JourneyPageRouter() {
	const journeyRouter = useJourneyRouter();

	return (
		<Switch>
			<Route exact path={routes.app.journeyListPage}>
				<JourneyListPage />
			</Route>
			{journeyRouter.selectedJourney && (
				<Route exact path={routes.app.journeyDetailPage}>
					<JourneyDetailProvider journey={journeyRouter.selectedJourney}>
						<JourneyDetailPage selectedJourney={journeyRouter.selectedJourney}/>
					</JourneyDetailProvider>
				</Route>
			)}
			<Redirect to={routes.app.journeyListPage} />
		</Switch>
	);
}