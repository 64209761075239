import { MenuItem, Select, Typography } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { v4 as uuid } from "uuid";
import { RSVPAnswer } from "../../../entities/Forms/Answer";
import { QuestionType, RSVPQuestion } from "../../../entities/Forms/Question";
import { FormError } from "../../../pages/Forms/FormResponder";
import { useAnswerCommonstyles } from "../AnswerInput";

interface RSVPInputProps {
	question: RSVPQuestion;
	answer?: RSVPAnswer;
	onAnswerChanged: (answer: RSVPAnswer) => void;
	onAnswerDeleted: (question: RSVPQuestion) => void;
	attendeeId: string;
	error: FormError[];
}

export function RSVPInput(props: RSVPInputProps) {
	const { question, answer, attendeeId, error } = props;
	const classes = useAnswerCommonstyles();

	const onChange = (e: React.ChangeEvent<{ value: unknown }>) => {
		if (isNaN(Number(e.target.value))) {
			props.onAnswerDeleted(question);
		} else {
			props.onAnswerChanged({
				id: answer ? answer.id : uuid(),
				questionId: question.id,
				questionType: QuestionType.RSVP,
				attendeeCount: Number(e.target.value),
				attendeeId: attendeeId,
			});
		}
	};

	return (
		<div className={classes.answerContainer}>
			<div>
				<div style={{ display: "flex", width: "100%" }}>
					<span style={{ fontWeight: "bold", fontSize: 16 }}>
						<span className={classes.numberQuestion}>{question.index + 1}.</span>
						<span className={classes.prompt}>{question.prompt}</span>
					</span>
					{question.required && (
						<Typography className={classes.required} variant="subtitle1">
							*Required
						</Typography>
					)}
					<div className={classes.grow} />
					{answer && (
						<Typography
							onClick={() => props.onAnswerDeleted(question)}
							className={classes.resetHeader}
							variant="subtitle1"
						>
							(Reset)
						</Typography>
					)}
				</div>
			</div>
			<Typography className={classes.infoHeader} style={{ marginBottom: 6 }} variant="subtitle1">
				Select a number
			</Typography>
			<Select
				className={classes.quantityDropDown}
				value={answer ? answer.attendeeCount : "-"}
				onChange={(e) => onChange(e)}
				IconComponent={KeyboardArrowDown}
				error={error.length > 0}
			>
				<MenuItem key={-1} value={"-"}>
					{"-"}
				</MenuItem>
				{new Array(21).fill(null).map((_, i) => (
					<MenuItem key={i} value={i}>
						{i}
					</MenuItem>
				))}
			</Select>
			{error.length > 0 && (
				<Typography className={classes.errorMessage} variant="subtitle1">
					{error.map((a) => `${a.error} `.trim())}
				</Typography>
			)}
		</div>
	);
}
