import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthPage from "../../pages/Auth/AuthPage";
import FormResponder from "../../pages/Forms/FormResponder";
import { MessagePreferencesPage } from "../../pages/MessagePreferencesPage";
import ForgotPassword from "../../pages/SignIn/ForgotPassword";
import ResetPassword from "../../pages/SignIn/ResetPassword";
import SetPassword from "../../pages/SignIn/SetPassword";
import SignIn from "../../pages/SignIn/SignIn";
import { SignOut } from "../../pages/SignIn/SignOut";
import { CheckoutComplete } from "../../pages/SignUp/CheckoutComplete";
import CheckoutPlan from "../../pages/SignUp/CheckoutPlan";
import RegisterProfile from "../../pages/SignUp/RegisterProfile";
import { routes } from "../../routes";

export function PublicRoutes(props: React.PropsWithChildren<{}>) {
	return (
		<Switch>
			<Route exact path={routes.public.signInPage}>
				<SignIn />
			</Route>
			<Route exact path={routes.public.signOutPage}>
				<SignOut />
			</Route>
			<Route exact path={[routes.public.registerPage, "/sign-up"]}>
				<RegisterProfile />
			</Route>
			<Route exact path={routes.public.checkOutPage}>
				<CheckoutPlan />
			</Route>
			<Route exact path={routes.public.checkoutComplete}>
				<CheckoutComplete />
			</Route>
			<Route exact path={routes.public.forgotPassword}>
				<ForgotPassword />
			</Route>
			<Route exact path={routes.public.resetPassword}>
				<ResetPassword />
			</Route>
			<Route exact path={routes.public.setPassword}>
				<SetPassword />
			</Route>
			<Route exact path={routes.public.auth}>
				<AuthPage />
			</Route>
			<Route exact path={routes.public.formResponderPage}>
				<FormResponder />
			</Route>
			<Route exact path={routes.public.messagePreferences}>
				<MessagePreferencesPage/>
			</Route>
            {props.children}
        </Switch>
	);
}
