import Church from "../entities/Church";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface UpdateChurchRequest {
	// name?: string;
	phoneNumber?: string;
}

export const ChurchService  = {
	getActiveChurch: () => WebClient.Get<Church>(`${apiRoot}/churches`),
	getAllChurches: () => WebClient.Get<Church[]>(`${apiRoot}/churches/all`),
	getUserChurches: () => WebClient.Get<Church[]>(`${apiRoot}/churches/user-churches`),
	updateChurch: (request: UpdateChurchRequest) => WebClient.Put.Validated<Church>(`${apiRoot}/churches`, request),
	delete: (churchId:string) => WebClient.Delete(`${apiRoot}/churches/${churchId}`),	
	getChurchBrandStatus: () => WebClient.Get<{ brandVerified: boolean }>(`${apiRoot}/churches/brand-status`),
};
