import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { Close, Publish, Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { orderBy } from "../utillity/orderBy";

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: 600,
		height: 400,
		overflowY: "auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			height: "inherit",
		},
	},
	grow: {
		flex: 1,
	},
	title: {
		fontWeight: 600,
		color: theme.palette.secondaryResponse.main,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 15,
		},
	},
	downloadButton: {
		color: "#FFF",
		backgroundColor: theme.palette.secondaryResponse.main,
		height: 40,
		marginTop: 16,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: theme.palette.secondaryResponse.main,
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	searchBar: {
		width: 300,
		"& fieldset": {
			borderRadius: 8,
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	orderSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	nameText: {
		color: theme.palette.secondaryResponse.main,
		fontWeight: 700,
		fontSize: 16,
		width: 140,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	row: {
		display: "flex",
		height: 48,
		padding: 10,
		border: "1px solid rgb(222,222,222)",
	},
}));

export interface EmailListProps {
	emailList: { name: string; email: string; messageDate: Date }[];
	isOpen: boolean;
	onClose: () => void;
}

enum Sort {
	MostRecent = 0,
	Oldest = 1,
}

const EmailList = (props: EmailListProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { onClose, emailList, isOpen } = props;
	const [searchText, startSearchPeople] = useState("");
	const [emails, setEmails] = useState(emailList);
	const [sort, setSort] = useState(Sort.MostRecent);

	useEffect(() => {
		let filteredEmails = emailList;

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredEmails = filteredEmails.filter((entry) => (entry.name + entry.email).toLowerCase().includes(queryText));
		}

		if (sort === Sort.MostRecent) {
			filteredEmails.sort(orderBy.date((a) => a.messageDate, "Ascending"));
		} else {
			filteredEmails.sort(orderBy.date((a) => a.messageDate, "Descending"));
		}

		setEmails(filteredEmails);
	}, [searchText, emailList, sort]);

	const downloadCSV = () => {
		const rows = [["Name", "Email"]];

		emailList.forEach((e) => {
			rows.push([e.name, e.email]);
		});

		let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "emails.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Dialog open={isOpen} onClose={() => onClose()} fullScreen={mobileFormFactor} onBackdropClick={() => onClose()}>
			<DialogTitle id="email-list-title" disableTypography>
				{!mobileFormFactor ? (
					<Typography variant="h2" className={classes.title}>
						{emailList.length} Email{emailList.length !== 1 ? "s" : ""}
					</Typography>
				) : (
					<div style={{ display: "flex" }}>
						<Typography variant="h2" className={classes.title}>
							{emailList.length} Email{emailList.length !== 1 ? "s" : ""}
						</Typography>
						<div className={classes.grow} />
						<IconButton aria-label="close modeal" component="span" onClick={onClose}>
							<Close />
						</IconButton>
					</div>
				)}
				<hr className={classes.largeBlueDivider} />
			</DialogTitle>
			<DialogContent className={classes.container}>
				<div style={{ display: "flex" }}>
					{!mobileFormFactor && (
						<TextField
							id="outlined-bare"
							defaultValue={searchText}
							margin="normal"
							variant="outlined"
							className={classes.searchBar}
							onChange={(e) => startSearchPeople(e.currentTarget.value)}
							size="small"
							InputProps={{
								startAdornment: <Search style={{ marginLeft: -6 }} />,
							}}
						/>
					)}
					{mobileFormFactor && (
						<FormControl>
							<Select
								disableUnderline
								labelId="role-select-label"
								id="role-select"
								value={sort}
								className={classes.orderSelector}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setSort(parseInt(event.target.value as string))
								}
							>
								<MenuItem value={Sort.MostRecent}>Newest First</MenuItem>
								<MenuItem value={Sort.Oldest}>Oldest First</MenuItem>
							</Select>
						</FormControl>
					)}
					<div className={classes.grow} />
					<Button
						variant="contained"
						className={classes.downloadButton}
						startIcon={<Publish style={{ transform: "scaleY(-1)" }} />}
						onClick={() => downloadCSV()}
					>
						Download CSV
					</Button>
				</div>
				<Grid container style={{ marginBottom: 12 }}>
					{!mobileFormFactor && (
						<Grid item xs={12}>
							<FormControl>
								<Select
									disableUnderline
									labelId="role-select-label"
									id="role-select"
									value={sort}
									className={classes.orderSelector}
									onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
										setSort(parseInt(event.target.value as string))
									}
								>
									<MenuItem value={Sort.MostRecent}>Newest First</MenuItem>
									<MenuItem value={Sort.Oldest}>Oldest First</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}

					{mobileFormFactor && (
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<TextField
								id="outlined-bare"
								defaultValue={searchText}
								margin="normal"
								variant="outlined"
								className={classes.searchBar}
								onChange={(e) => startSearchPeople(e.currentTarget.value)}
								size="small"
								InputProps={{
									startAdornment: <Search style={{ marginLeft: -6 }} />,
								}}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						{emails.map((emailObj, index: number) => {
							return (
								<div key={index} className={classes.row}>
									<Typography variant="subtitle1" className={classes.nameText}>
										{emailObj.name}
									</Typography>
									<div className={classes.grow} />
									<a href={`mailto:${emailObj.email}`}>{emailObj.email}</a>
								</div>
							);
						})}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default EmailList;
