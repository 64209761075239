import { EventSchedule, InformationalJourney, JourneyEvent } from "../entities/Journey";
import { convertToLocalTime } from "../functions/datetime";
import { Modify } from "../utillity/modifyType";
import { CreateJourneyRequest, UpdateJourneyRequest } from "./JourneyService";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

type ServerInformationalJourney = Modify<
	InformationalJourney,
	{
		dateCreated: string;
		event?: ServerJourneyEvent;
	}
>;

type ServerJourneyEvent = Modify<
	JourneyEvent,
	{
		originalTime: string;
		nextEventTime: string;
	}
>;


export type CreateInformationalJourneyRequest  = CreateJourneyRequest & 
{
	createEvent?: {
		nextEventTime: Date,
		schedule: EventSchedule
	}
}

export type UpdateInformationalJourneyRequest  = UpdateJourneyRequest & 
{
	updateEvent?: {
		nextEventTime: Date,
		schedule: EventSchedule
	}
}

type ServerCreateInformationalJourneyRequest = Modify<
	CreateInformationalJourneyRequest,
	{
		createEvent?: {
			nextEventTime: string,
			schedule: EventSchedule
		}
	}
>;

type ServerUpdateInformationalJourneyRequest = Modify<
	UpdateInformationalJourneyRequest,
	{
		updateEvent?: {
			nextEventTime: string,
			schedule: EventSchedule
		}
	}
>;

const convert = (serverModel: ServerInformationalJourney): InformationalJourney => {
	return {
		...serverModel,
		dateCreated: new Date(serverModel.dateCreated),
		event: serverModel.event ? {
			...serverModel.event,
			originalTime: new Date(serverModel.event.originalTime),
			nextEventTime: new Date(serverModel.event.nextEventTime),
		} :
		undefined
	}
}

const toServerCreate = (model: CreateInformationalJourneyRequest): ServerCreateInformationalJourneyRequest => {
	return {
		...model,
		createEvent: model.createEvent ? {
			...model.createEvent,
			nextEventTime: convertToLocalTime(model.createEvent.nextEventTime),
		} : undefined
	}
}

const toServerUpdate = (model: UpdateInformationalJourneyRequest): ServerUpdateInformationalJourneyRequest => {
	return {
		...model,
		updateEvent: model.updateEvent ? {
			...model.updateEvent,
			nextEventTime: convertToLocalTime(model.updateEvent.nextEventTime),
		} : undefined
	}
}

export const InformationalJourneyService = {
	get(id: string) {
		return WebClient.Convert.Get(`${apiRoot}/journeys/informational/${id}`, convert);
	},
	create(request: CreateInformationalJourneyRequest){
		const createRequest = toServerCreate(request);
		return WebClient.Convert.Post.Validated(`${apiRoot}/journeys/informational`, createRequest, convert);
	},
    update(request: UpdateInformationalJourneyRequest){
		const updateRequest = toServerUpdate(request);
		return WebClient.Convert.Put.Validated(`${apiRoot}/journeys/informational`, updateRequest, convert);
	}
}