import JourneyResponse from "../entities/JourneyResponse";
import { ServerResult } from "./server/ServerResult";
import { ServerSuccess } from "./server/ServerSuccess";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface UpdateFollowUpStatusRequest {
	responseId: string;
	haveFollowedUp: boolean;
}

export const convertResponse = (response: JourneyResponse) => {
	return {
		...response,
		createdTimestampUtc: new Date(response.createdTimestampUtc),
		lastTriggeredTimestampUtc: new Date(response.lastTriggeredTimestampUtc),
		timeOfFollowUpUtc: response.timeOfFollowUpUtc ? new Date(response.timeOfFollowUpUtc) : undefined,
		messageHistory: response.messageHistory.map((m) => ({ ...m, timestampUtc: new Date(m.timestampUtc) })),
	};
}

export default class JourneyResponseService {
	public static async GetResponses(journeyId: string) {
		let response = await WebClient.Get<JourneyResponse[]>(`${apiRoot}/responses/${journeyId}`);

		if (ServerResult.isSuccess(response)) {
			let translatedResponse: ServerSuccess<JourneyResponse[]> = {
				data: response.data.map(convertResponse)
			};
			return translatedResponse;
		}
		return response;
	}

	public static async updateFollowUpStatus(request: UpdateFollowUpStatusRequest) {
		let response = await WebClient.Put.Unvalidated<JourneyResponse>(`${apiRoot}/responses/follow-up`, request);

		if (ServerResult.isSuccess(response)) {
			let translatedResponse: ServerSuccess<JourneyResponse> = {
				data: convertResponse(response.data),
			};
			return translatedResponse;
		}
		return response;
	}
}
