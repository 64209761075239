import React, { createContext, PropsWithChildren, useContext } from "react";
import Attendee from "../entities/Attendee";

interface AttendeeContext {
	attendees: Attendee[];
	create: (attendee: Attendee) => void;
	update: (attendee: Attendee) => void;
	delete: (attendee: Attendee) => void;
}

const AttendeeReactContext = createContext<AttendeeContext>({} as any);

export function useAttendees() {
	return useContext(AttendeeReactContext);
}

export function AttendeeProvider(props: PropsWithChildren<{ attendeeState: [Attendee[], (attendees: Attendee[]) => void] }>) {
	const [attendees, setAttendees] = props.attendeeState;

	const create = (attendee: Attendee) => {
        setAttendees([...attendees.filter(a => a.id !== attendee.id), attendee])
    };

	const update = (attendee: Attendee) => {
        const updatedAttendees = attendees.map(g => g.id === attendee.id ? attendee : g);
        setAttendees([...updatedAttendees]);
    };

	const deleteAttendee = (attendee: Attendee) => {
        const updatedAttendees = attendees.filter(g => g.id !== attendee.id);
        setAttendees([...updatedAttendees]);
    };

	return (
		<AttendeeReactContext.Provider
			value={{
				attendees,
				create,
				update,
				delete: deleteAttendee,
			}}
		>
			{props.children}
		</AttendeeReactContext.Provider>
	);
}