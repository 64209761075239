import JourneyResponse from "../entities/JourneyResponse";

const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

export const EmailHelper = {
	gatherEmailsFromResponse: (responses: JourneyResponse[]) => {
		let emailList: { name: string; email: string; messageDate: Date }[] = [];

		responses.forEach((response) => {
			response.messageHistory
				.filter((a) => a.fromSystem === false)
				.some((message) => {
					let email = message.text.match(emailRegExp);

					if (email != null) {
						emailList.push({
							name: response.attendeeName,
							email: email[0],
							messageDate: new Date(message.timestampUtc),
						});
						return true;
					}
					return false;
				});
		});

		return emailList;
	},
};
