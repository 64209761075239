import React from "react";
import TextMessagePreview from "../entities/Messages/TextMessagePreview";

interface Context {
    textMessagePreviews: TextMessagePreview[];
    updatePreview: (preview: TextMessagePreview) => void; 
}

const TextMessagePreviewReactContext = React.createContext<Context>({} as any);

export function useTextMessagePreviews() {
	return React.useContext(TextMessagePreviewReactContext);
}

export function TextMessagePreviewProvider(props: React.PropsWithChildren<{ TextMessagePreviewState: [TextMessagePreview[], (previews: TextMessagePreview[]) => void] }>) {
	const [textMessagePreviews, setTextMessagePreviews] = props.TextMessagePreviewState;

	const updatePreview = (preview: TextMessagePreview) => {
		setTextMessagePreviews(textMessagePreviews.map(p => p.attendeeId === preview.attendeeId ? preview : p));
	}

	return (
		<TextMessagePreviewReactContext.Provider
			value={{
				textMessagePreviews,
				updatePreview
			}}
		>
			{props.children}
		</TextMessagePreviewReactContext.Provider>
	);
}
