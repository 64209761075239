import { Divider, Grid, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClientLink } from "../../../components/ClientLink";
import { SubtractDaysReturnDate } from "../../../functions/datetime";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { routes } from "../../../routes";
import { PeopleGraph } from "./PeopleGraph";

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: 20,
	},
	cardTitle: {},
	totalCount: {
		color: theme.palette.secondaryResponse.main,
		paddingRight: 10,
		alignSelf: "center",
	},
	addedCount: {
		color: theme.palette.secondaryResponse.main,
	},
	icon: {
		fontSize: 14,
	},
}));

export function PeopleCard() {
	const { attendees } = useAttendees();
	const [recentAttendees, setRecentAttendees] = useState<number>(0);
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		const Thirtyago = SubtractDaysReturnDate(new Date(), 30);
		setRecentAttendees(attendees.filter((a) => a.dateCreated >= Thirtyago).length);
	}, [attendees]);

	return (
		<Paper elevation={4} className={classes.paper}>
			<Grid container direction="column">
				<Grid item xs={12}>
					<Typography variant="h4">
						NEW PEOPLE IN THE LAST 30 DAYS
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h1" className={classes.totalCount}>
								{recentAttendees}
							</Typography>
						</Grid>
						<Grid item>
							<PeopleGraph />
						</Grid>
					</Grid>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<ClientLink to={routes.app.invitePersonPage} style={{color: theme.palette.jacobsBlue.main}}>Invite more people now</ClientLink>
				</Grid>
			</Grid>				
		</Paper>
	);
}
