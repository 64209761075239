import Attendee from "../entities/Attendee";
import { Group } from "../entities/Groups/Group";
import { GroupMember } from "../entities/Groups/GroupMember";
import { MessageToGroup } from "../entities/Groups/MessageToGroup";
import { TextMessageMedia } from "../entities/Messages/TextMessageMedia";
import { Modify } from "../utillity/modifyType";
import { convertAttendee, ServerAttendee } from "./AttendeeService";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

type SerializedGroupMember = Modify<GroupMember, { timeJoined: string; timeLeft: string | null }>;
type SerializedMessageToGroup = Modify<
	MessageToGroup,
	{
		dateCreated: string;
		scheduledSendTime: string;
		timeSent: string | undefined;
	}
>;
export type SerializedGroup = Modify<
	Group,
	{
		dateCreated: string;
		dateModified: string;
		lastMessageSentDate: string | null;
		groupMembers: SerializedGroupMember[];
		messagesToGroup: SerializedMessageToGroup[];
	}
>;


const deserializeGroupMember = (model: SerializedGroupMember): GroupMember => ({
	...model,
	timeJoined: new Date(model.timeJoined),
	timeLeft: model.timeLeft ? new Date(model.timeLeft) : null,
});

const deserializeMessageToGroup = (model: SerializedMessageToGroup): MessageToGroup => ({
	...model,
	dateCreated: new Date(model.dateCreated),
	scheduledSendTime: new Date(model.scheduledSendTime),
	timeSent: model.timeSent ? new Date(model.timeSent) : undefined
});

export const deserializeGroup = (model: SerializedGroup): Group => ({
	...model,
	dateCreated: new Date(model.dateCreated),
	dateModified: new Date(model.dateModified),
	lastMessageSentDate: model.lastMessageSentDate ? new Date(model.lastMessageSentDate) : null,
    groupMembers: model.groupMembers.map(deserializeGroupMember),
	messagesToGroup: model.messagesToGroup.map(deserializeMessageToGroup)
});

export interface CreateGroupRequest {
    name: string;
}

export interface UpdateGroupRequest {
    groupId: string;
    name?: string;
}

export interface AddAttendeesToGroupRequest {
	attendeeIds: string[];
    groupId: string;
}

export interface RemoveAttendeesFromGroupRequest {
	attendeeIds: string[];
    groupId: string;
}

export interface AddNewContactToGroupRequest {
    groupId: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string | undefined;
}

export interface ScheduleMessageToGroupRequest {
	groupId: string;
	message: string;
	media: TextMessageMedia[];
	scheduledTime: Date;
}

interface AddNewContactServerResponse {
	group: SerializedGroup;
	attendee: ServerAttendee;
}

interface AddNewContactResponse {
	group: Group;
	attendee: Attendee;
}

export const GroupService = {
    getAll: () => WebClient.Convert.Get<SerializedGroup[], Group[]>(`${apiRoot}/groups/`, groups => groups.map(deserializeGroup)),
    create: (request: CreateGroupRequest) => WebClient.Convert.Post.Validated(`${apiRoot}/groups/`, request, deserializeGroup),
    update: (request: UpdateGroupRequest) => WebClient.Convert.Put.Validated(`${apiRoot}/groups/`, request, deserializeGroup),
    delete: (groupId: string) => WebClient.Delete(`${apiRoot}/groups/${groupId}`),
	addAttendeesToGroup: (request: AddAttendeesToGroupRequest) => WebClient.Convert.Post.Validated(`${apiRoot}/groups/member/attendees`, request, deserializeGroup),
	removeAttendeesFromGroup: (request: RemoveAttendeesFromGroupRequest) => WebClient.Convert.Put.Validated(`${apiRoot}/groups/member/remove`, request, deserializeGroup),
	addNewContactToGroup: (request: AddNewContactToGroupRequest) => {
		return WebClient.Convert.Post.Validated<AddNewContactServerResponse, AddNewContactResponse>(`${apiRoot}/groups/member/new`, request, m => ({group: deserializeGroup(m.group), attendee: convertAttendee(m.attendee)}));
	},
	scheduleMessageToGroup: (request: ScheduleMessageToGroupRequest) => WebClient.Convert.Post.Validated(`${apiRoot}/groups/message`, request, deserializeGroup),
	cancelScheduledMessageToGroup: (request: {groupId: string, broadcastMessageId: string}) => WebClient.Convert.Put.Validated(`${apiRoot}/groups/message/cancel`, request, deserializeGroup),
};
