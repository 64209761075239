import { PromptJourney } from "../entities/Journey";
import { CreateJourneyRequest, UpdateJourneyRequest } from "./JourneyService";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface CreatePromptJourneyRequest extends CreateJourneyRequest {
    prompt: string;
}

export interface UpdatePromptJourneyRequest extends UpdateJourneyRequest {
    prompt: string;
}

export const PromptJourneyService = {
    get(id: string) {
		return WebClient.Get<PromptJourney>(`${apiRoot}/journeys/prompt/${id}`);
	},
    create(request: CreatePromptJourneyRequest) {
		return WebClient.Post.Validated<PromptJourney>(`${apiRoot}/journeys/prompt`, request);
    },
    update(request: UpdatePromptJourneyRequest) {
		return WebClient.Put.Validated<PromptJourney>(`${apiRoot}/journeys/prompt`, request);
	}
}