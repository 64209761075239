import { Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MoreOptionsIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import LessOptionsIcon from "@material-ui/icons/RemoveCircleOutline";
import classNames from "classnames";
import React, { useState } from "react";
import { Journey } from "../entities/Journey";
import { JwtUser } from "../entities/JwtUser";
import { ActiveDay } from "../pages/JourneyComponents/MessageTab";
import { FieldValidationError, FieldValidationErrorUtils } from "../services/server/ServerValidationError";
import { PersonalizedMessage } from "../utillity/Personalization";

export const usePersonalizedStyles = makeStyles((theme: Theme) => ({
	messageBox: {
		color: "rgb(0,0,0, .8)",
		fontWeight: 400,
		fontSize: 16,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		padding: 8,
		height: 72,
		"& > textarea": {
			height: "100%",
		},
	},
	editBox: {
		width: "100%",
	},
	pretendBox: {
		minHeight: 72,
		border: "1px solid rgb(0,0,0,.2)",
		borderRadius: 4,
		width: "100%",
		padding: 8,
		paddingTop: 4,
		maxHeight: 240,
		overflowY: "auto",
	},
	disableBox: {
		opacity: 0.6,
		cursor: "not-allowed",
	},
	icons: {
		color: theme.palette.secondaryResponse.main,
		cursor: "pointer",
		marginRight: 8,
		marginTop: 7,
	},
	blueStrongText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		textDecoration: "underline",
	},
	option: {
		display: "flex",
		height: 40,
		width: 140,
		backgroundColor: "rgb(245,246,247)",
		border: "1px solid rgb(227, 231, 240)",
		cursor: "pointer",
		marginRight: 4,
		"&:hover": {
			opacity: 0.8,
		},
		[theme.breakpoints.down("sm")]: {
			width: "50%",
			marginRight: 0,
		},
	},
	optionText: {
		fontSize: 15,
		color: theme.palette.secondaryResponse.main,
		marginTop: 8,
		marginLeft: 14,
	},
	grow: {
		flex: 1,
	},
	deleteButton: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main
	}
}));

interface Props {
	message: string;
	journey: Journey;
	user: JwtUser;
	onChange: (activeDay: ActiveDay, text: string) => void;
	onBlur: (activeDay: ActiveDay) => void;
	activeDay: ActiveDay;
	onDelete: (activeDay: ActiveDay) => void;
	showAdornment: boolean;
	fieldErrors: FieldValidationError[];
}

const PersonalizedMessageTextBox = (props: Props) => {
	const classes = usePersonalizedStyles();
	const { message, journey, user, onChange, onBlur, activeDay, onDelete, showAdornment, fieldErrors } = props;
	const [hasFocus, setHasFocus] = useState(false);
	const [showOptions, setShowOptions] = useState(false);

	const onFocusClick = () => {
		if (activeDay.inFuture !== false) {
			setHasFocus(true);
		}
	};

	const addPersonalization = (token: string) => {
		onChange(activeDay, message + " " + token);
	};

	if (hasFocus) {
		return (
			<Grid container>
				<Grid item xs={12}>
					<TextField
						style={{ width: "100%" }}
						id="outlined-multiline-static"
						multiline
						rows={2}
						variant="outlined"
						className={classes.editBox}
						autoFocus
						onBlur={() => {
							setHasFocus(false);
							onBlur(activeDay);
						}}
						value={message}
						onChange={(e) => onChange(activeDay, e.currentTarget.value)}
						disabled={activeDay.inFuture === false}
						InputProps={{
							classes: {
								root: classes.messageBox,
							},
							endAdornment:
								showAdornment && activeDay.message && activeDay.inFuture ? (
									<InputAdornment position="end">
										<IconButton
											className={classes.deleteButton}
											onMouseDown={(e) => e.preventDefault()}
											onClick={() => onDelete(activeDay)}
										>
											<DeleteIcon />{" "}
										</IconButton>
									</InputAdornment>
								) : undefined,
						}}
						error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Message")}
					/>
				</Grid>
				{activeDay.inFuture && (
					<>
						<Grid item xs={12} style={{ marginTop: 4 }}>
							{!showOptions ? (
								<div
									style={{ cursor: "pointer", display: "flex" }}
									onMouseDown={(e) => {
										e.preventDefault();
										setShowOptions(true);
									}}
								>
									<MoreOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
									<Typography
										className={classes.blueStrongText}
										style={{ cursor: "pointer", marginTop: 6 }}
										variant="subtitle2"
									>
										More Options
									</Typography>{" "}
								</div>
							) : (
								<div
									style={{ cursor: "pointer", display: "flex" }}
									onMouseDown={(e) => {
										e.preventDefault();
										setShowOptions(true);
									}}
								>
									<LessOptionsIcon className={classes.icons} style={{ marginRight: 4 }} />{" "}
									<Typography className={classes.blueStrongText} variant="subtitle2" style={{ marginTop: 6 }}>
										Less Options
									</Typography>{" "}
								</div>
							)}
						</Grid>

						{showOptions && (
							<div style={{ display: "flex", flexWrap: "wrap" }}>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{First_Name}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										First Name
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Full_Name}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Full Name
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Keyword}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Keyword
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Link}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Link
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Media}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Media
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Address}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Address
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Church}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Church
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Event}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Event
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
								<div
									className={classes.option}
									onMouseDown={(e) => {
										e.preventDefault();
										addPersonalization("{{Form}}");
									}}
								>
									<Typography className={classes.optionText} variant="subtitle2">
										Form
									</Typography>
									<div className={classes.grow} />
									<MoreOptionsIcon className={classes.icons} />
								</div>
							</div>
						)}
					</>
				)}
			</Grid>
		);
	} else {
		return (
			<Grid container>
				<Grid
					item
					xs={12}
					onClick={() => onFocusClick()}
					className={classNames(classes.pretendBox, activeDay.inFuture === false && classes.disableBox)}
				>
					<Typography variant="subtitle1">
						<PersonalizedMessage message={message} journey={journey} user={user} />
					</Typography>
				</Grid>
			</Grid>
		);
	}
};

export default PersonalizedMessageTextBox;
