import { Grid, Typography, useTheme, Paper, FormControlLabel } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router";
import SwipeableViews from "react-swipeable-views";
import { GridGrow } from "../../components/GridGrow";
import ResponseSwitch from "../../components/responseSwitch";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";
import { ServerResult } from "../../services/server/ServerResult";
import { MessagesTab, useMessageTabStyles } from "./Components/MessagePageTabs";
import { GroupMessagePreview } from "./GroupMessages/GroupMessagePreview";
import { InboxMessagePreview } from "./Inbox/InboxMessagePreview";

export function MessagePageMobileLayout(props:{ selectedTab: MessagesTab }){
    const { selectedTab } = props;
    const classes = useMessageTabStyles();
    const history = useHistory();
	const theme = useTheme();
	const [user, setUser] = useAppUser();
	const [isDisabled, setIsDisabled] = useState(false);
	const setError = useServerErrorAlert();
	const setSuccess = useSuccessAlert();

	const toggleInboxNotifications = async() => {
		setIsDisabled(true);
		const result = await AuthService.changeInboxNotificationStatus(!user.recieveInboxNotifications);
		setIsDisabled(false);

		if(ServerResult.isError(result))
			return setError(result);

		setUser(result.data.jwtToken);
		setSuccess(user.recieveInboxNotifications ? "Notifications will not be sent to your phone" : "Notifications will be sent to your phone")
	}

    const handleClick = (tab: MessagesTab) => {
        if(tab === MessagesTab.Group_Announcements){
            history.push(routes.app.groupMessagePage);
        } else {
            history.push(routes.app.inboxPage);
        }
    }
    
    return (
		<div style={{height: "100%"}}>
			<Grid container wrap="nowrap">
				<Grid item xs={5}>
					<Typography
						key={`1-${selectedTab}`}
						className={classNames(
							classes.pageTabs,
							selectedTab === MessagesTab.Inbox ? classes.activeTab : classes.hiddenTab
							)}
							variant="h2"
							onClick={() => selectedTab === MessagesTab.Inbox ? null : handleClick(MessagesTab.Inbox)}
							>
						Inbox
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography
						key={`2-${selectedTab}`}
						className={classNames(
							classes.pageTabs,
							selectedTab === MessagesTab.Group_Announcements ? classes.activeTab : classes.hiddenTab
						)}
						variant="h2"
						onClick={() => selectedTab === MessagesTab.Group_Announcements ? null : handleClick(MessagesTab.Group_Announcements)}
						>
						Announcements
					</Typography>
				</Grid>
			</Grid>
			<Grid container style={{height: "90%"}}>
				<Paper style={{height: "100%", overflowY: "scroll", width: "100%"}}>
					<SwipeableViews
						axis={theme.direction === "rtl" ? "x-reverse" : "x"}
						index={selectedTab}
						onChangeIndex={(index: MessagesTab) => handleClick(index)}
						style={{ backgroundColor: "#FFF", width: "100%", paddingLeft: 8, paddingRight: 8 }}
					>
						<div style={{ width: "100%" }} hidden={selectedTab !== MessagesTab.Inbox} dir={theme.direction}>
							<Grid container  style={{ marginBottom: 10, marginTop: 10 }}>
								<GridGrow />
								<Grid item>
									<FormControlLabel
										disabled={isDisabled}
										value={user.recieveInboxNotifications}
										control={
											<ResponseSwitch checked={user.recieveInboxNotifications} onChange={toggleInboxNotifications} name="notify-ignore" />
										}
										label="Send notifications to my phone"
										/>
								</Grid>
							</Grid>
							<InboxMessagePreview />
						</div>
						<div
							style={{ width: "100%" }}
							role="tabpanel"
							hidden={selectedTab !== MessagesTab.Group_Announcements}
							dir={theme.direction}
						>
							<GroupMessagePreview />
						</div>
					</SwipeableViews>
				</Paper>
			</Grid>
		</div>
    )
}