import ChurchDetails from "../entities/AdminTools/ChurchDetails";
import { SubscriptionStatus } from "../entities/ResponseSubscription";
import { WebClient } from "./server/WebClient";
import { apiRoot } from "./Settings";

export interface ChurchMetrics {
	name: string;
	phoneNumber: string;
	responsePhoneNumber?: string;
	churchId: string;
	attendeeCount: number;
	journeyCount: number;
	status: SubscriptionStatus;
}

export default class ImpersonationMetricService {
	public static async GetAll() {
		return WebClient.Get<ChurchMetrics[]>(`${apiRoot}/impersonationmetric/all`);
	}
	public static async GetChurchDetails(churchId:string) {
		return WebClient.Get<ChurchDetails>(`${apiRoot}/impersonationmetric/${churchId}`);
	}
	public static async ChangeResponseNumber(request:{phoneNumber: string, churchId: string}) {
		return WebClient.Post.Validated(`${apiRoot}/impersonationmetric/change-response-number`, request);
	}
}
