import { useTheme } from "@material-ui/core";
import React from "react";
import { Checkmark } from 'react-checkmark'

export function AwesomeCheckbox(props: { size?: number, color?: "green" | "secondary" | "primary" }) {
    const theme = useTheme();
    let checkColor: string | undefined = props.color;
    if(props.color === "secondary"){
        checkColor = theme.palette.secondary.main;
    }

    if(props.color === "primary"){
        checkColor = theme.palette.primary.main;
    }

    if(props.color === "green"){
        checkColor = undefined;
    }

	return (
		<Checkmark size={props.size} color={checkColor}/>
	);
}
