import { FormControlLabel, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import OverlayInterrupt from "../../../components/firstTimeOverlay";
import JourneySetup from "../../../components/JourneySetup/journeySetUp";
import { PageTitle } from "../../../components/PageTitle";
import ResponseSwitch from "../../../components/responseSwitch";
import { Journey, JourneyCategory, JourneyType } from "../../../entities/Journey";
import { JourneyTab } from "../../../entities/journeyTab";
import { useJourneyNotifications } from "../../../hooks/useJourneyNotifications";
import MessageTab from "../../JourneyComponents/MessageTab";
import OverviewTab from "../../JourneyComponents/OverviewTab";
import OverviewTabMobile from "../../JourneyComponents/OverviewTabMobile";
import ReminderTab from "../../JourneyComponents/ReminderTab";
import ResponsesTabMobile from "../../JourneyComponents/ResponsesTabMobile";
import ResponsesTab from "../../JourneyComponents/ResponseTabs";
import { useJourneyRouter } from "../JourneyPage";
import JourneyDetailTabs from "./JourneyDetailTabs";

export const useJourneyStyles = makeStyles((theme: Theme) => ({
	contentPaper: {
		flexGrow: 1,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		marginTop: 20,
		[theme.breakpoints.down("md")]: {
			boxShadow: "none",
		},
	},
}));

const isAdminJourney = (journeyType:JourneyType) => {
	switch(journeyType){
		case JourneyType.Connect_Card:
		case JourneyType.Sermon_Prompts:
		case JourneyType.Prayer_Requests:
			return true;
		default:
			return false;
	}
}

const getAvailableTabs = (journey: Journey): JourneyTab[] => {
	if (journey.category === JourneyCategory.Informational) {
		return journey.event
			? [JourneyTab.Overview, JourneyTab.Responses, JourneyTab.Reminders]
			: [JourneyTab.Overview, JourneyTab.Responses];
	}
	if (journey.category === JourneyCategory.Sermon_Prompts) {
		return [JourneyTab.Overview, JourneyTab.Responses, JourneyTab.Messages];
	}
	return [JourneyTab.Overview, JourneyTab.Responses];
};

const getInitialTabFromRoute = (search: string): JourneyTab => {
	const tabQueryValue = new URLSearchParams(search).get("tab")?.toLowerCase();
	if (tabQueryValue === "overview") {
		return JourneyTab.Overview;
	}
	if (tabQueryValue === "responses") {
		return JourneyTab.Responses;
	}
	if (tabQueryValue === "messages") {
		return JourneyTab.Messages;
	}
	if (tabQueryValue === "reminders") {
		return JourneyTab.Reminders;
	}
	return JourneyTab.Overview;
};

interface JourneyDetailPageProps {
	selectedJourney: Journey;
}

export function JourneyDetailPage(props: JourneyDetailPageProps) {
	const journeyRouter = useJourneyRouter();
	const location = useLocation();
	const { selectedJourney } = props;
	const notifications = useJourneyNotifications();
	const [tabs, ] = useState(getAvailableTabs(selectedJourney));
	const [selectedTab, setSelectedTab] = useState(getInitialTabFromRoute(location.search));
	const [showSetupWizard, startWizard] = useState(false);
	const [showOverlayInterrupt, setShowOverlayInterrupt ] = useState(
		!selectedJourney.enabled && isAdminJourney(selectedJourney.type)
	);

	useEffect(() => {
		setShowOverlayInterrupt(!selectedJourney.enabled && isAdminJourney(selectedJourney.type))
	}, [selectedJourney]);

	if (showSetupWizard) {
		return (
			<JourneySetup
				mode="update"
				journey={selectedJourney}
				onUpdate={(updatedJourney) => {
					if (!selectedJourney.enabled && updatedJourney.enabled) {
						notifications.subcribeToJourneyNotifications(updatedJourney);
					}
					journeyRouter.updateJourney(updatedJourney);
					startWizard(false);
				}}
				onClose={() => startWizard(false)}
			/>
		);
	}

	return (
		<div>
			{showOverlayInterrupt && <OverlayInterrupt journey={selectedJourney} onButtonClick={() => startWizard(true)} />}
			<PageTitle title={selectedJourney.name} />
			<div style={{ opacity: showOverlayInterrupt ? 0.1 : 1 }}>
				<JourneyHeader journey={selectedJourney}/>
				<JourneyDetailTabs tabs={tabs} selectedTab={selectedTab} onChange={(tab) => setSelectedTab(tab)} />
				<JourneyViews
					selectedTab={selectedTab}
					tabs={tabs}
					journey={selectedJourney}
					onStartWizard={() => startWizard(true)}
					onTabSelected={(tab) => setSelectedTab(tab)}
				/>
			</div>
		</div>
	);
}

function JourneyHeader(props: {journey: Journey}) {
	const { journey } = props;
	const journeyId = journey.id;
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const notificationContext = useJourneyNotifications();
	const notificationSubscriptions = notificationContext.journeyNotificationSubscriptions;

	const [isDisabled, setIsDisabled] = useState(false);
	const [notificationsEnabled, setNotificationsEnabled] = useState(
		notificationSubscriptions.some((n) => n.journeyId === journeyId)
	);
	useEffect(
		() => setNotificationsEnabled(notificationSubscriptions.some((n) => n.journeyId === journeyId)),
		[notificationSubscriptions, journeyId]
	);

	const onNotificationToggle = async () => {
		setIsDisabled(true);
		if (notificationsEnabled) {
			const subscription = notificationSubscriptions.find((s) => s.journeyId === journeyId);
			await notificationContext.unsubcribeToJourneyNotifications(subscription!.subscriptionId, journey);
		} else {
			await notificationContext.subcribeToJourneyNotifications(journey);
		}
		setIsDisabled(false);
	};

	if (mobileFormFactor) {
		return (
			<Grid container alignContent="flex-end" justify="flex-end">
				<Grid item>
					<FormControlLabel
						disabled={isDisabled}
						value={journey.id}
						control={
							<ResponseSwitch checked={notificationsEnabled} onChange={onNotificationToggle} name="notify-ignore" />
						}
						label={notificationsEnabled ? "Notifications On" : "Notifications Off"}
						style={{ marginRight: 25, marginBottom: 10 }}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<div>
			<Typography gutterBottom variant="h1">
				{journey.name}
			</Typography>
			<div
				style={{
					display: "flex",
					width: "100%",
				}}
			>
				<div style={{ flex: 1 }} />
				<FormControlLabel
					disabled={isDisabled}
					value={journey.id}
					control={
						<ResponseSwitch checked={notificationsEnabled} onChange={onNotificationToggle} name="notify-ignore" />
					}
					label={notificationsEnabled ? "Notifications On" : "Notifications Off"}
					style={{ marginRight: 25 }}
				/>
			</div>
		</div>
	);
}

function JourneyViews(props: {
	selectedTab: JourneyTab;
	tabs: JourneyTab[];
	journey: Journey;
	onStartWizard: () => void;
	onTabSelected: (journeyTab: JourneyTab) => void;
}) {
	const { selectedTab, tabs, journey } = props;
	const classes = useJourneyStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const TabView = (tabViewProps: { tab: JourneyTab }) => {
		switch (tabViewProps.tab) {
			case JourneyTab.Overview:
				return mobileFormFactor ? (
					<OverviewTabMobile journey={journey} onEditJourney={props.onStartWizard} />
				) : (
					<OverviewTab journey={journey} onEditJourney={props.onStartWizard} onEnable={props.onStartWizard} />
				);
			case JourneyTab.Responses:
				return mobileFormFactor ? <ResponsesTabMobile journey={journey} /> : <ResponsesTab journey={journey}/>;
			case JourneyTab.Messages:
				return <MessageTab journey={journey}/>;
			case JourneyTab.Reminders:
				return journey.category === JourneyCategory.Informational ? <ReminderTab journey={journey} /> : null;
		}
	};

	if (mobileFormFactor) {
		return (
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={tabs.indexOf(selectedTab)}
				onChangeIndex={props.onTabSelected}
				style={{ backgroundColor: "#FFF" }}
			>
				{tabs.map((tab) => (
					<div hidden={selectedTab !== tab} dir={theme.direction}>
						<TabView tab={tab} />
					</div>
				))}
			</SwipeableViews>
		);
	}

	return (
		<Grid container>
			<Grid style={{ marginRight: mobileFormFactor ? 0 : 16 }} item xs={12}>
				<Paper className={classes.contentPaper}>
					<TabView tab={selectedTab} />
				</Paper>
			</Grid>
		</Grid>
	);
}

export default JourneyDetailPage;