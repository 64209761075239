import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { routes } from "../../../routes";
import { ChurchService } from "../../../services/ChurchService";
import { ServerResult } from "../../../services/server/ServerResult";

export function DeleteChurch(props:{ churchId: string }){
    const setSuccessAlert = useSuccessAlert();
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = async() => {
        setDisabled(true);
        const response = await ChurchService.delete(props.churchId);
        setDisabled(false);

        if(ServerResult.isSuccess(response)){
            setSuccessAlert("Church Deleted")
            return history.push(routes.app.impersonateChurch)
        }
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Typography>Delete church</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button disabled={disabled} onClick={() => setShowDialog(true)} variant="contained" style={{ backgroundColor: "red", color: "white" }}>
                        Delete
                    </Button>
                </Grid>
            </Grid>
            
            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                <DialogTitle id="cancel-sub-title">Are you sure you want to delete this church</DialogTitle>
                <DialogContent>
                        <Typography>Deleting this church will remove all attendees, message history, brand, campaign, phone number and users for this church.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="outlined" 
                        style={{color: "red", borderColor: "red"}}
                        size="medium" 
                        onClick={handleClick} 
                        >
                        Yes delete church
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        size="medium"
                        onClick={() => setShowDialog(false)}
                        disabled={disabled}
                        style={{ textDecoration: "underline"}}
                    >
                      Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}