import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import React from "react";
import JourneyResponse from "../../entities/JourneyResponse";

enum ContactAttendeeOption {
	Call = 1,
	Text = 2,
}

interface MobileContactModalProps {
	onClose: () => void;
	open: boolean;
	responder: JourneyResponse;
	followedUp: (responder: JourneyResponse) => void;
}

export default function MobileContactModal(props: MobileContactModalProps) {
	const { responder, open, onClose, followedUp } = props;

	const handleContactAttendee = (choice: ContactAttendeeOption) => {
		followedUp(responder);
		if (choice === ContactAttendeeOption.Call) {
			window.location.href = `tel:${responder.attendeePhoneNumber}`;
		} else if (choice === ContactAttendeeOption.Text) {
			window.location.href = `sms:${responder.attendeePhoneNumber}`;
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle id="contact-modal-title">How would you like to contact {responder.attendeeName}?</DialogTitle>
			<List>
				<ListItem button onClick={() => handleContactAttendee(ContactAttendeeOption.Call)}>
					<ListItemAvatar>
						<Avatar>
							<PhoneIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={"Call"} />
				</ListItem>
				<ListItem button onClick={() => handleContactAttendee(ContactAttendeeOption.Text)}>
					<ListItemAvatar>
						<Avatar>
							<SmsIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={"Text"} />
				</ListItem>
			</List>
		</Dialog>
	);
}
