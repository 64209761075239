import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ResponseInputComponent from "../../../components/responseInputComponent";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../../hooks/useSuccessAlert";
import { GroupService, UpdateGroupRequest } from "../../../services/GroupService";
import { ServerResult } from "../../../services/server/ServerResult";
import { useGroups } from "../../../providers/GroupProvider";
import { Group } from "../../../entities/Groups/Group";

export const useEditGroupStyles = makeStyles((theme: Theme) => ({
	cancelButton: {
		color: "rgb(129, 138, 145)",
		backgroundColor: "#FFF",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "rgb(182, 191, 197, .7)",
		},
	},
    groupNameInput: {
        maxWidth: 350
    },
}));

interface EditGroupDialogProps {
    selectedGroup: Group;
    isOpen: boolean;
    onClose: () => void;
}

export default function EditGroupDialog(props:EditGroupDialogProps) {
	const theme = useTheme();
    const classes = useEditGroupStyles();
    const groupsContext = useGroups();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
    const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [isEnabled, setEnabled] = useState<boolean>(true);
	const [groupName, setGroupName] = useState<string>(props.selectedGroup.name);
    const [groupExists, setGroupExists] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        setError(undefined)
        setGroupExists(false)

        if(groupsContext.groups){
            const existingGroup = groupsContext.groups.find(g => g.name === groupName);
            if(existingGroup && existingGroup.id !== props.selectedGroup.id){
                setError(`${existingGroup.name} already exists`)
                setGroupExists(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupName])

	const onSubmit = async () => {
        if(!groupName || error){
            if(!error){
                setError("Group name cannot be blank")
            }
            return;
        }


        setError(undefined)
        const request:UpdateGroupRequest = {name: groupName, groupId: props.selectedGroup.id}
		
        setEnabled(false);
        var result = await GroupService.update(request);
		setEnabled(true);

		if (ServerResult.isSuccess(result)) {
            setSuccessAlert(`${groupName} updated`)
            groupsContext.update(result.data);
            props.onClose();
		} else if (ServerResult.isValidationError(result)) {
			setError(result.errors[0].errors[0]);
		} else {
			setErrorAlert(result);
		}
	};

	return (
		<Dialog open={props.isOpen} maxWidth={"md"} fullScreen={mobileFormFactor} onBackdropClick={props.onClose}>
			<DialogTitle id="editGroup-dialog-title">Rename Group</DialogTitle>
			<DialogContent>
				<DialogContentText>
                    Rename {props.selectedGroup.name}
				</DialogContentText>
                    <Grid container justify="center" direction="row">
                        <div style={{ width: 350 }}>
                            <ResponseInputComponent
                                errorMessage={error}
                                value={groupName ?? ""}
                                onChange={setGroupName}
                                placeHolder="Group Name"
                                onEnter={onSubmit}
                            />
                        </div>
                    </Grid>
                    <Grid 
                        container 
                        direction="row" 
                        justify="flex-end"
                        alignItems='center'
                        spacing={2} 
                        style={{ padding: "15px 0px" }}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={classes.cancelButton}
                                onClick={props.onClose}
                                disabled={!isEnabled}
                                >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                style={{backgroundColor: theme.palette.secondaryResponse.main}}
                                disabled={!isEnabled || groupExists}
                                onClick={onSubmit}
                                >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
			</DialogContent>
		</Dialog>
	);
}
