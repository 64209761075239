import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
	makeStyles,
} from "@material-ui/core";
import MediaIcon from "@material-ui/icons/Image";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import LinkIcon from "@material-ui/icons/Link";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ClientLink } from "../../components/ClientLink";
import EmailList from "../../components/EmailList";
import ResponseSwitch from "../../components/responseSwitch";
import { DayOfWeek } from "../../entities/DayOfWeek";
import { Journey, JourneyCategory, JourneyType, MessageSchedule } from "../../entities/Journey";
import {
	AddDaysReturnDate,
	buildTimeSendDisplay,
	getDayWithTenseString,
	getLastSunday,
	getLongNameMonth,
	getShortWeekDay,
} from "../../functions/datetime";
import { formatPhoneNumber } from "../../functions/prettyStrings";
import { useAppUser } from "../../hooks/useAppUser";
import { useJourneyDetailContext } from "../../hooks/useJourneyDetailContext";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { useJourneys } from "../../providers/JourneyProvider";
import { routes } from "../../routes";
import { FormService } from "../../services/FormService";
import JourneyService from "../../services/JourneyService";
import { ServerResult } from "../../services/server/ServerResult";
import { EmailHelper } from "../../utillity/EmailHelper";
import { useJourneyRouter } from "../Journeys/JourneyPage";

export const useStyles = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	internalTopPadding: {
		paddingTop: 42,
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	grow: {
		flex: 1,
	},
	linkText: {
		textDecoration: "underline",
		cursor: "pointer",
		fontWeight: "normal",
	},
	disableToggleRow: {
		display: "flex",
		width: "100%",
		marginTop: 8,
	},
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
	cardHover: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "rgb(250,250,250)",
		},
	},
	optionIcon: {
		width: 20,
	},
	linkIcon: {
		color: theme.palette.primaryResponse.main,
		fontSize: 26,
		width: 30,
		marginTop: 3,
		transform: "rotate(-46deg)",
	},
	mediaIcon: {
		color: theme.palette.primaryResponse.main,
		width: 24,
		marginTop: 3,
	},
	metaCard: {
		padding: "12px 9px 6px 9px",
		marginTop: 15,
		display: "flex",
		height: 80,
		width: 200,
		borderRadius: 4,
		marginRight: 16,
		[theme.breakpoints.down("sm")]: {
			marginRight: 0,
		},
	},
	metaCardText: {
		marginLeft: 4,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		color: theme.palette.secondaryResponse.main,
	},
	addCollaboratorButton: {
		color: theme.palette.primaryResponse.main,
		borderStyle: "solid",
		borderColor: theme.palette.primaryResponse.main,
		borderWidth: 1,
		borderRadius: "50%",
		height: 40,
		width: 40,
	},
	deleteButton: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main
	}
}));

interface OverviewTabProps {
	onEditJourney: () => void;
	onEnable?: () => void;
	journey: Journey;
}

export const buildNextMessageString = (messageSchedule: MessageSchedule) => {
	if (messageSchedule.daysToRun.length === 0) {
		return "There are no upcoming messages scheduled";
	}

	let today = new Date();
	let dayOfWeek = today.getDay();
	let nextDay = messageSchedule.daysToRun.findIndex((a) => a >= dayOfWeek);
	let skipToNextWeek = 0;

	if (nextDay < 0) {
		nextDay = messageSchedule.daysToRun[0];
		skipToNextWeek = 7;
	} else {
		nextDay = messageSchedule.daysToRun[nextDay];
	}

	let lastSunday = dayOfWeek === 0 ? today : getLastSunday();

	let nextMessageDate = AddDaysReturnDate(lastSunday, nextDay + skipToNextWeek);

	return `${getLongNameMonth(nextMessageDate.getMonth())} ${getDayWithTenseString(
		nextMessageDate.getDate()
	)} @ ${buildTimeSendDisplay(messageSchedule.timeToRun)}`;
};

const OverviewTab = (props: OverviewTabProps) => {
	const classes = useStyles();
	const [appUser] = useAppUser();
	const history = useHistory();
	const setSuccessAlert = useSuccessAlert();
	const setErrorAlert = useServerErrorAlert();

	const { journey } = props;
	const journeyContext = useJourneys();
	const journeyDetailContext = useJourneyDetailContext();
	const journeyRouter = useJourneyRouter();
	const { responses } = journeyDetailContext;
	const [isDisabled, setIsDisabled] = journeyDetailContext.disableState;

	const [userRequestDeletion, setUserRequestDeletion] = useState(false);
	const [emails, setEmails] = useState<{ name: string; email: string; messageDate: Date }[]>([]);
	const [showEmailList, setShowEmailList] = useState(false);
	const [formSubmissionCount, setFormSubmissionCount] = useState<number>(0);
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(responses){
			setEmails(EmailHelper.gatherEmailsFromResponse(responses))
		}
	}, [responses]);

	useEffect(() => {
		if (!journey.extras.formId) return;

		async function getScheduledMessages(formId:string) {
			const result = await FormService.getResults(formId);
			if (ServerResult.isSuccess(result)) {
				setFormSubmissionCount(new Set(result.data.map((a) => a.attendeeId)).size);
			} else {
				// If it fails, that's fine.  This is just an extra, shouldn't worry user.
			}
		}
		getScheduledMessages(journey.extras.formId);
	}, [journey.extras.formId]);

	const onDisableJourney = async () => {
		if (!journey.enabled && props.onEnable) {
			props.onEnable();
			return;
		}

		setIsDisabled(true);
		let response = await JourneyService.Disable(journey); // Enable is just the opposite of this
		setIsDisabled(false);
		if (ServerResult.isSuccess(response)) {
			journeyRouter.updateJourney(response.data);
		} else {
			setErrorAlert(response);
		}
	};

	const onDeleteJourney = async () => {
		let response = await JourneyService.Delete(journey);
		setUserRequestDeletion(false);
		if (ServerResult.isSuccess(response)) {
			journeyContext.removeJourney(journey);
			setSuccessAlert("Journey successfully deleted");
			history.push(routes.app.journeyListPage);
		} else {
			setErrorAlert(response);
		}
	};
	

	return (
		<div className={classes.setUpContainer}>
			<Grid container>
				<div className={classNames(classes.disableToggleRow)}>
					<div className={classes.grow} />
					<FormControlLabel
						disabled={isDisabled}
						control={<ResponseSwitch checked={journey.enabled} onChange={onDisableJourney} name="disable-enable" />}
						label="Enabled"
						style={{ marginRight: 0 }}
					/>
				</div>

				<Grid className={classNames(classes.internalTopPadding)} item xs={12}>
					<div style={{ display: "flex" }}>
						<Typography style={{ fontWeight: "bold" }} variant="h3">
							Details
						</Typography>
						<div className={classes.grow} />
						{journey.type !== JourneyType.Connect_Card &&
							journey.type !== JourneyType.Prayer_Requests &&
							journey.type !== JourneyType.Sermon_Prompts && (
								<Typography
									onClick={() => (isDisabled ? null : setUserRequestDeletion(true))}
									className={classes.linkText}
									style={{ color: theme.palette.error.main, marginRight: 16 }}
									variant="body1"
								>
									Delete Journey
								</Typography>
							)}
						<Typography
							onClick={() => (isDisabled ? null : props.onEditJourney())}
							className={classes.linkText}
							variant="body1"
						>
							Edit Journey
						</Typography>
					</div>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid item xs={12}>
					{
						appUser.responsePhoneNumber
						?
						<Typography style={{ paddingTop: 12 }} variant="h3">
							People can now text <b>{journey.keywordTrigger}</b> to {formatPhoneNumber(appUser.responsePhoneNumber)} to
							start this Journey
						</Typography>
						:
						<Typography style={{ paddingTop: 12 }} variant="h3">
							<ClientLink to={routes.app.setupPhoneNumber}>Setup your Response phone number</ClientLink> so others can now text <b>{journey.keywordTrigger}</b> to
							start this Journey
						</Typography>
					}
				</Grid>

				{journey.category === JourneyCategory.Sermon_Prompts && (
					<>
						<Grid item xs={12}>
							<Typography style={{ paddingTop: 12 }} variant="h3">
								<b>Next message:</b>&nbsp;
								{buildNextMessageString(journey.promptMessageSchedule)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography style={{ paddingTop: 12 }} variant="h3">
								<strong>Message Schedule:</strong>&nbsp;
								{buildTimeSendDisplay(journey.promptMessageSchedule.timeToRun)} on
								{journey.promptMessageSchedule.daysToRun
									.sort((a, b) => (+a > +b ? 1 : -1))
									.map((day: DayOfWeek, index: number) => {
										return (
											" " +
											(index > 0
												? index < journey.promptMessageSchedule.daysToRun.length - 1
													? ", "
													: " and "
												: "") +
											DayOfWeek[day] +
											"s"
										);
									})}
							</Typography>
						</Grid>
					</>
				)}
				<div style={{ display: "flex", marginTop: 8 }}>
					{journey.category === JourneyCategory.Informational && journey.event && (
						<Grid item xs={12}>
							<div
								className={classNames(classes.cardGlowEffect, classes.metaCard)}
								style={{
									width: mobileFormFactor ? "100%" : 200,
								}}
							>
								<img
									alt="icon"
									src="https://i.cdn-sc.com/Response/icons/event-date-icon.svg"
									className={classes.optionIcon}
									style={{ width: 16, marginBottom: 30, marginRight: 5, marginLeft: 5 }}
								/>
								<Typography variant="subtitle1" className={classes.metaCardText} style={{ whiteSpace: "normal" }}>
									{`${getShortWeekDay(
										journey.event.nextEventTime.getDay()
									)}, ${journey.event.nextEventTime.toLocaleString("en-us", {
										month: "short",
									})} 
								${journey.event.nextEventTime.getDate()}
								${journey.event.nextEventTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
							`}
								</Typography>
							</div>
						</Grid>
					)}
					{journey.extras.address && (
						<Grid item xs={12}>
							<div
								className={classNames(classes.cardGlowEffect, classes.cardHover, classes.metaCard)}
								style={{
									width: mobileFormFactor ? "100%" : 200,
								}}
								onClick={() =>
									journey.extras.address
										? window.open(
												`http://maps.google.com/?q= ${journey.extras.address.street} ${journey.extras.address.city} ${journey.extras.address.zip}`
										  )
										: undefined
								}
							>
								<img
									alt="icon"
									src="https://i.cdn-sc.com/Response/icons/location-icon.svg"
									className={classes.optionIcon}
									style={{ width: 16, marginBottom: 30, marginRight: 5, marginLeft: 5 }}
								/>
								<Typography variant="subtitle1" className={classes.metaCardText} style={{ whiteSpace: "normal" }}>
									{journey.extras.address.street}&nbsp;
									{journey.extras.address.city}
								</Typography>
							</div>
						</Grid>
					)}
					{journey.extras.link && (
						<Grid item xs={12}>
							<div
								className={classNames(classes.cardGlowEffect, classes.cardHover, classes.metaCard)}
								style={{
									width: mobileFormFactor ? "100%" : 200,
								}}
								onClick={() =>
									window.open(
										journey.extras.link && journey.extras.link?.indexOf("https") < 0
											? `https://${journey.extras.link}`
											: journey.extras.link
									)
								}
							>
								<LinkIcon className={classNames(classes.optionIcon, classes.linkIcon)} />
								<Typography variant="subtitle1" className={classes.metaCardText}>
									{journey.extras.link}
								</Typography>
							</div>
						</Grid>
					)}
					{journey.extras.mediaLink && (
						<Grid item xs={12}>
							<div
								className={classNames(classes.cardGlowEffect, classes.cardHover, classes.metaCard)}
								style={{
									width: mobileFormFactor ? "100%" : 200,
								}}
								onClick={() =>
									window.open(
										journey.extras.mediaLink && journey.extras.mediaLink?.indexOf("https") < 0
											? `https://${journey.extras.mediaLink}`
											: journey.extras.mediaLink
									)
								}
							>
								<MediaIcon className={classNames(classes.optionIcon, classes.mediaIcon)} />
								<Typography variant="subtitle1" className={classes.metaCardText}>
									{journey.extras.mediaLink}
								</Typography>
							</div>
						</Grid>
					)}
				</div>
				{journey.type === JourneyType.Gather_Emails && (
					<Grid className={classNames(classes.internalTopPadding)} item xs={12}>
						<div style={{ display: "flex" }}>
							<Typography style={{ fontWeight: "bold" }} variant="h3">
								Emails
							</Typography>
							<div className={classes.grow} />
						</div>
						<hr className={classes.largeBlueDivider} />
						<div
							className={classNames(classes.cardGlowEffect, classes.cardHover)}
							style={{
								padding: "10px 6px 10px 6px",
								marginTop: 15,
								display: "flex",
								width: mobileFormFactor ? "100%" : 300,
							}}
							onClick={() => setShowEmailList(true)}
						>
							<Typography variant="subtitle1" style={{ fontSize: 14, fontWeight: 700, marginLeft: 8 }}>
								Email Addresses
							</Typography>
							<div className={classes.grow} />
							<div
								style={{
									width: emails.length < 100 ? 28 : 32,
									height: emails.length < 100 ? 28 : 32,
									borderRadius: emails.length < 100 ? 14 : 16,
									backgroundColor: theme.palette.primaryResponse.main,
									color: "#FFF",
									paddingTop: 4,
									textAlign: "center",
								}}
							>
								{emails.length}
							</div>
							<RightArrow style={{ opacity: 0.4, margin: "2px 8px 0px 8px" }} />
						</div>
					</Grid>
				)}
				{journey.extras.formId && (
					<Grid className={classNames(classes.internalTopPadding)} item xs={12}>
						<div style={{ display: "flex" }}>
							<Typography style={{ fontWeight: "bold" }} variant="h3">
								Forms
							</Typography>
							<div className={classes.grow} />
						</div>
						<hr className={classes.largeBlueDivider} />
						<Link
							to={`/app/form-results/${journey.extras.formId}/${journey.id}/${+journey.category}/${+journey.type}`}
							style={{ textDecoration: "none" }}
						>
							<div
								className={classNames(classes.cardGlowEffect, classes.cardHover)}
								style={{
									padding: "10px 6px 10px 6px",
									marginTop: 15,
									display: "flex",
									width: mobileFormFactor ? "100%" : 300,
								}}
							>
								<Typography
									variant="subtitle1"
									style={{
										fontSize: 14,
										fontWeight: 700,
										marginLeft: 8,
										color: theme.palette.secondaryResponse.main,
									}}
								>
									{`${journey.keywordTrigger} Form`}
								</Typography>
								<div className={classes.grow} />

								<div
									style={{
										width: emails.length < 100 ? 28 : 32,
										height: emails.length < 100 ? 28 : 32,
										borderRadius: emails.length < 100 ? 14 : 16,
										backgroundColor: theme.palette.primaryResponse.main,
										color: "#FFF",
										paddingTop: 4,
										textAlign: "center",
									}}
								>
									{formSubmissionCount}
								</div>

								<RightArrow
									style={{
										opacity: 0.4,
										margin: "2px 8px 0px 8px",
										color: theme.palette.secondaryResponse.main,
									}}
								/>
							</div>
						</Link>
					</Grid>
				)}
			</Grid>
			<Dialog open={userRequestDeletion} onClose={() => setUserRequestDeletion(false)}>
				<DialogTitle id="delete-journey-title">Delete Journey</DialogTitle>
				<DialogContent>Are you sure you want to delete this journey, this cannot be undone?</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="default"
						size="medium"
						onClick={() => setUserRequestDeletion(false)}
						disabled={isDisabled}
					>
						Cancel
					</Button>
					<Button
						variant="outlined"
						className={classes.deleteButton}
						size="medium"
						disabled={isDisabled}
						onClick={() => onDeleteJourney()}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			{showEmailList && <EmailList emailList={emails} isOpen={showEmailList} onClose={() => setShowEmailList(false)} />}
			{/* {showFormDetails && journey.extras.formId && (
				<FormDigest onClose={() => setShowFormDetails(false)} formId={journey.extras.formId} />
			)} */}
		</div>
	);
};

export default OverviewTab;
