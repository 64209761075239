import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { Group } from "../../entities/Groups/Group";
import { LoadingPage } from "../../components/LoadingPage";
import { routes } from "../../routes";
import { useGroups } from "../../providers/GroupProvider";
import { useAppData } from "../../providers/AppDataProvider";
import { ServerErrorPage } from "../../components/ServerErrorPage";
import { useAttendees } from "../../providers/AttendeeProvider";
import Attendee from "../../entities/Attendee";
import { isMobile } from "../../hooks/isMobile";
import { MobileInboxDetailPage } from "./Inbox/MobileInboxDetailPage";
import { MobileGroupMessageDetailPage } from "./GroupMessages/MobileGroupMessageDetailPage";
import { MessagePageDeskTopLayout } from "./MessagePageDeskTopLayout";
import { MessagesTab } from "./Components/MessagePageTabs";
import { PageTitle } from "../../components/PageTitle";
import { MessagePageMobileLayout } from "./MessagePageMobileLayout";

export function MessagesPage() {
	const params = useParams<{ groupId?: string, attendeeId?: string }>();
	const appData = useAppData();
	const { groupsLoading, groupServerError, attendeesLoading, attendeeServerError} = appData;

	if (groupsLoading || attendeesLoading) {
		return <LoadingPage />;
	}

	if(groupServerError)
		return <ServerErrorPage serverError={groupServerError}/>

	if(attendeeServerError)
		return <ServerErrorPage serverError={attendeeServerError}/>

	return (
		<MessageRouterProvider groupId={params.groupId} attendeeId={params.attendeeId} >
			<PageTitle title="Messages" />
			<MessagePageRouter groupId={params.groupId} attendeeId={params.attendeeId} />
		</MessageRouterProvider>
	);
}

interface MessageRouterContext {
    selectedGroup: Group | undefined;
	selectedAttendee: Attendee | undefined;
	selectGroup: (group: Group) => void;
	updateGroup: (group: Group) => void;
	selectAttendee: (attendee: Attendee) => void;
	updateAttendee: (attendee: Attendee) => void;
}

const MessageRouterReactContext = createContext<MessageRouterContext>({} as any);

export function useMessageRouter() {
	return useContext(MessageRouterReactContext);
}

function MessageRouterProvider(props: PropsWithChildren<{ groupId: string | undefined, attendeeId: string | undefined }>) {
	const { groupId, attendeeId } = props;
	const groupContext = useGroups();
	const attendeeContext = useAttendees();
	const { groups } = groupContext;
	const { attendees } = attendeeContext;

	const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(groups.find((g) => g.id === groupId));
	const [selectedAttendee, setSelectedAttendee] = useState<Attendee | undefined>(attendees.find((a) => a.id === attendeeId));

	useEffect(() => {
		setSelectedGroup(groups.find((g) => g.id === groupId));
	}, [groups, groupId]);

	useEffect(() => {
		setSelectedAttendee(attendees.find((a) => a.id === attendeeId));
	}, [attendees, attendeeId]);

	const onSelectGroup = (group: Group) => setSelectedGroup(group);
	const onUpdateGroup = (group: Group) => groupContext.update(group);

	const onSelectAttendee = (attendee:Attendee) => setSelectedAttendee(attendee);
	const onUpdateAttendee = (attendee:Attendee) => setSelectedAttendee(attendee);

	return (
		<MessageRouterReactContext.Provider value={{ 
			selectedGroup, 
			selectedAttendee,
			selectGroup: onSelectGroup, 
			updateGroup: onUpdateGroup,
			selectAttendee: onSelectAttendee, 
			updateAttendee: onUpdateAttendee,
		}}>
			{props.children}
		</MessageRouterReactContext.Provider>
	);
}

interface MessagePageRouterProps {
	groupId: string | undefined;
	attendeeId: string | undefined;
}

function MessagePageRouter(props: MessagePageRouterProps) {
	const mobile = isMobile();
	const messageRouterContext = useMessageRouter();

	if(mobile){
		return (
			<Switch>
				<Route exact path={[routes.app.inboxPage, routes.app.sendMessagePage]}>
					<MessagePageMobileLayout selectedTab={MessagesTab.Inbox} />
				</Route>
				<Route exact path={routes.app.inboxDetailPage}>
					{messageRouterContext.selectedAttendee && <MobileInboxDetailPage selectedAttendee={messageRouterContext.selectedAttendee}/>}
				</Route>
				<Route exact path={routes.app.groupMessagePage}>
					<MessagePageMobileLayout selectedTab={MessagesTab.Group_Announcements} />
				</Route>
				<Route exact path={routes.app.groupMessageDetailPage}>
					{messageRouterContext.selectedGroup && <MobileGroupMessageDetailPage selectedGroup={messageRouterContext.selectedGroup}/>}
				</Route>
				<Redirect to={routes.app.inboxPage} />
			</Switch>
		);
	}

	return (
		<Switch>
			<Route exact path={[routes.app.inboxPage, routes.app.inboxDetailPage, routes.app.sendMessagePage]}>
				<MessagePageDeskTopLayout selectedTab={MessagesTab.Inbox} />
			</Route>
			<Route exact path={[routes.app.groupMessagePage, routes.app.groupMessageDetailPage]}>
				<MessagePageDeskTopLayout selectedTab={MessagesTab.Group_Announcements} />
			</Route>
			<Redirect to={routes.app.inboxPage} />
		</Switch>
	);
}
