import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { TextMessageMedia } from "../entities/Messages/TextMessageMedia";

interface ImagePreviewListProps {
	images: TextMessageMedia[];
	removeImage: (image: TextMessageMedia) => void;
}

export function ImagePreviewList(props: ImagePreviewListProps) {
	const { images } = props;

	return (
		<div style={{ marginLeft: 5, marginRight: 5 }}>
			<Grid container wrap="nowrap" spacing={1}>
				{images.map((item, index) => (
					<Grid item key={index}>
						<div style={{ position: "relative" }}>
							<CloseIcon style={{ position: "absolute", top: 5, right: 5 }} color="secondary" onClick={() => props.removeImage(item)}/>
							<img
								src={item.url}
								alt={`uploaded ${index}`}
								style={{ height: "auto", maxHeight: 125, maxWidth: "100%" }}
							/>
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	);
}
