import { Button, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { GridGrow } from "../../components/GridGrow";
import LoadingSpinner from "../../components/loaderSpinner";
import { PageTitle } from "../../components/PageTitle";
import ResponseSubscription, { SubscriptionTerm } from "../../entities/ResponseSubscription";
import { ResponseSubscriptionPlan } from "../../entities/ResponseSubscriptionPlan";
import ResponseUpcomingInvoice from "../../entities/ResponseUpcomingInvoice";
import { buildDateTimeString } from "../../functions/datetime";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import ResponseSubscriptionPlanService from "../../services/ResponseSubscriptionPlanService";
import ResponseSubscriptionService from "../../services/ResponseSubscriptionService";
import { ServerResult } from "../../services/server/ServerResult";
import { NumberHelper } from "../../utillity/NumberHelper";
import { CancelDialog } from "./components/CancelDialog";
import { ChangePlanSelection } from "./components/ChangePlanSelection";
import TabHeaderBar, { SettingsTab } from "./TabHeaderBar";

export const useConnectStyles = makeStyles((theme: Theme) => ({
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	cancelButton: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
}));

const Subscription = () => {
	const classes = useConnectStyles();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));

	const setServerErrorAlert = useServerErrorAlert();

	const [annualBilling, setAnnualBilling] = useState(false);

	const [isLoading, setLoading] = useState(true);
	const [subscription, setSubscription] = useState<ResponseSubscription | undefined>();
	const [nextInvoice, setNextInvoice] = useState<ResponseUpcomingInvoice | undefined>();

	const [plans, setPlans] = useState<ResponseSubscriptionPlan[] | undefined>();

	const [showCancelPrompt, setShowCancelPrompt] = useState(false);

	React.useEffect(() => {
		const fetch = async () => {
			const result = await ResponseSubscriptionService.get();
			const nextInvoiceResult = await ResponseSubscriptionService.getUpcomingInvoice();

			setLoading(false);

			if (ServerResult.isSuccess<ResponseSubscription>(result)) {
				setSubscription(result.data);

				if (result.data.term === SubscriptionTerm.Annual) {
					setAnnualBilling(true);
				}
			} else {
				setServerErrorAlert(result);
			}

			if (ServerResult.isSuccess(nextInvoiceResult)) {
				if (nextInvoiceResult.data) setNextInvoice(nextInvoiceResult.data);
			} else {
				setServerErrorAlert(nextInvoiceResult);
			}
		};
		fetch();
	}, [setServerErrorAlert]);

	React.useEffect(() => {
		async function fetchPlans() {
			const result = await ResponseSubscriptionPlanService.getPublicPlans();
			if (ServerResult.isSuccess(result)) {
				setPlans(result.data);
			} else {
				setServerErrorAlert(result);
			}
		}
		fetchPlans();
	}, [setServerErrorAlert]);

	// const cancelSubscription = async () => {
	// 	setDisable(true);
	// 	const result = await ResponseSubscriptionService.cancelSubscription();
	// 	setDisable(false);
	// 	if (ServerResult.isSuccess(result)) {
	// 		setSuccessAlert("Subscription will expire at the end of this billing period.");
	// 		setSubscription(result.data);
	// 		setShowCancelPrompt(false);
	// 	} else {
	// 		setServerErrorAlert(result);
	// 	}
	// };

	if (isLoading) {
		return (
			<div style={{ textAlign: "center", marginTop: 150 }}>
				<LoadingSpinner isComplete={isLoading} durationMessage={"Fetching Subscription..."} />
			</div>
		);
	}

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title="Subscription" />

			{!mobileFormFactor && (
				<>
					<Grid item xs={12}>
						<Typography variant="h1">Settings</Typography>
					</Grid>
					<Grid item xs={12}>
						<TabHeaderBar tab={SettingsTab.Subscription} />
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Paper style={{ padding: 10 }}>
							<Grid container>
								<Grid item xs={12}>
									<Grid container alignItems="center">
										<Grid item>
											<Typography style={{ fontWeight: "bold" }} variant="h3">
												Details
											</Typography>
										</Grid>
										<GridGrow />
										{subscription && !subscription.cancelOn && (
											<Grid item>
												<Button
													variant="outlined"
													className={classes.cancelButton}
													onClick={() => setShowCancelPrompt(true)}
												>
													Cancel
												</Button>
											</Grid>
										)}
									</Grid>
									<hr className={classes.largeBlueDivider} />
								</Grid>
								{!subscription && (
									<Typography variant="body2">
										No subscription found...
									</Typography>
								)}
								{subscription && (
									<Grid item xs={12}>
										<Typography variant="body2">
											<strong>Plan Name: </strong> {subscription.name}
										</Typography>

										<Typography variant="body2">
											<strong>Type: </strong>{" "}
											{subscription.term === SubscriptionTerm.Annual ? "Annual Plan" : "Monthly Plan"}
											{nextInvoice &&
												nextInvoice.date &&
												`, next billing with be on ${buildDateTimeString(
													nextInvoice.date.toString(),
													true
												)} for ${NumberHelper.convertToDollarsReturnsSymbol(
													nextInvoice.amountDue / 100
												)}`}
										</Typography>
										<div style={{ display: "flex" }}>
											<Typography variant="body2">
												<strong>Start Date: </strong>{" "}
												{buildDateTimeString(subscription.startDate.toString(), true)}
											</Typography>

											{subscription.endDate && (
												<Typography variant="body2">
													&nbsp; <strong>End Date: </strong>{" "}
													{buildDateTimeString(subscription.endDate.toString(), true)}
												</Typography>
											)}
										</div>
										{subscription.trialStart && subscription.trialEnd && subscription.trialEnd > new Date() && (
											<Typography variant="body2">
												<strong>Trial Ends: </strong>{" "}
												{buildDateTimeString(subscription.trialEnd.toString(), true)}
											</Typography>
										)}
										{subscription.cancelOn && (
											<Typography variant="body2">
												{subscription.isCanceled ? (
													<>
														<strong>Status: </strong> Your plan is canceled
													</>
												) : (
													<>
														<strong>Status: </strong> Your plan is canceled, you will lose access on{" "}
														{buildDateTimeString(subscription.cancelOn.toString(), true)}
													</>
												)}
											</Typography>
										)}
									</Grid>
								)}
							</Grid>
							{plans && (
								<div style={{ marginTop: 30 }}>
									<ChangePlanSelection
										annualBilling={annualBilling}
										plans={plans}
										subscription={subscription}
										onSubscriptionUpdated={setSubscription}
									/>
								</div>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Grid>
			{subscription && (
				<CancelDialog
					open={showCancelPrompt}
					toggleClose={() => setShowCancelPrompt(false)}
					subscription={subscription}
					updateSubscription={(sub) => setSubscription(sub)}
				/>
			)}
		</Grid>
	);
};

export default Subscription;
