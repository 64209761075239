import { Grid, Snackbar, Typography, useTheme } from "@material-ui/core";
import React from "react";

interface NarniaMagicSnackBarProps {
    open: boolean;
    onClose: () => void;
}

export function NarniaMagicSnackBar(props:NarniaMagicSnackBarProps){
    const theme = useTheme();
    return(
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center"}}
            open={props.open}
            onClose={props.onClose}
            autoHideDuration={3000} 
            style={{
                backgroundColor: "#7ac142",
                border: `1px solid ${theme.palette.success.dark}`,
                borderRadius: 12,
            }}
        >
            <Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap">
                <Grid item>
                    <img src="/images/wand.png" alt="Wand" />
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" style={{ color: "#fff", fontSize: 15 }}>
                        Poof! Your link was magically shortened (we use Narnia-approved magic)
                    </Typography>
                </Grid>
            </Grid>
        </Snackbar>
    )
}