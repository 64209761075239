import { Slide, Fab, useMediaQuery, useTheme, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import React from "react";
import useScrollEvent from "../hooks/useScrollEvent";

export const useFabStyles = makeStyles((theme: Theme) => ({
    fabButton: {
        textTransform: "none",
        [theme.breakpoints.up("md")]: {
            width: 195,
            borderRadius: 8
        },
        color: "#FFF",
        backgroundColor: theme.palette.primaryResponse.main,
        "&:hover": {
            backgroundColor: theme.palette.primaryResponse.main,
            opacity: 0.8,
        },
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    }
}));

interface ResponseFabProps {
    onClick: () => void;
    extendedButtonText: string;
}

export function ResponseFab(props:ResponseFabProps){
    const theme = useTheme();
    const classes = useFabStyles();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
    const isScrolling = useScrollEvent(400);

    return (
        <Slide direction="left" appear={false} in={!isScrolling}>
            <Fab
                onClick={props.onClick} 
                variant={mobileFormFactor ? "round" : "extended"}
                classes={{ label: "response-h2" }}
                className={classNames(classes.fabButton, "response-h2")}>
                {mobileFormFactor ? <AddIcon /> : props.extendedButtonText}
            </Fab>
        </Slide>
    )
}