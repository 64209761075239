import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import Homework from "@material-ui/icons/HomeWork";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Church from "../entities/Church";
import { useAppUser } from "../hooks/useAppUser";
import { useServerErrorAlert } from "../hooks/useServerErrorAlert";
import { useUser } from "../hooks/useUser";
import { routes } from "../routes";
import AuthService from "../services/AuthService";
import { ChurchService } from "../services/ChurchService";
import { ServerResult } from "../services/server/ServerResult";

export const useChurchListStyles = makeStyles((theme: Theme) => ({
	churchItem: {
		padding: 12,

		borderLeft: "1px solid rgba(28,64,87,.3)",
		borderBottom: "1px solid rgba(28,64,87,.3)",
		borderRight: "1px solid rgba(28,64,87,.3)",
		"&:hover": {
			backgroundColor: "rgba(28,64,87, .1)",
		},
	},
	firstItem: {
		borderTop: "1px solid rgba(28,64,87,.3)",
	},
	selectedItem: {
		backgroundColor: "rgba(28,64,87, .2)",
	},
	churchContainer: {
		maxHeight: 400,
		overflowY: "auto",
	},
}));

interface AssociatedChurchListDialogProps {
	onClose: () => void;
}

const AssociatedChurchListDialog = (props: AssociatedChurchListDialogProps) => {
	const classes = useChurchListStyles();
	const [user] = useAppUser();
	const [, setJwt] = useUser();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const [userChurches, setUserChurches] = useState<Church[]>([]);
	const [disabled, setDisabled] = useState(false);
	const [selectedChangeChurch, setSelectedChangeChurch] = useState<Church | undefined>(undefined);
	const setServerErrorAlert = useServerErrorAlert();

	useEffect(() => {
		async function getAssociatedChurches() {
			const response = await ChurchService.getUserChurches();
			if (ServerResult.isSuccess<Church[]>(response)) {
				setUserChurches(response.data);
			} else {
				//ignore error
			}
		}

		if (user) {
			getAssociatedChurches();
		}
	}, [user]);

	const submitChange = async () => {
		if (!selectedChangeChurch) {
			return;
		}

		setDisabled(true);
		const result = await AuthService.updateChurch(selectedChangeChurch.churchId);
		setDisabled(false);

		if (ServerResult.isSuccess(result)) {
			setJwt(result.data.jwtToken);
			window.location.replace(routes.app.dashBoardPage);
		} else {
			setServerErrorAlert(result);
		}
	};

	const buildListBox = (item: Church, index: number) => {
		return (
			<div
				key={index}
				className={classNames(
					classes.churchItem,
					index === 0 && classes.firstItem,
					selectedChangeChurch && selectedChangeChurch.churchId === item.churchId && classes.selectedItem
				)}
				onClick={() => setSelectedChangeChurch(item)}
			>
				{item.name}
			</div>
		);
	};

	return (
		<Dialog open onClose={() => props.onClose()} fullScreen={mobileFormFactor}>
			<DialogTitle id="add-reminder-title" disableTypography>
				<div style={{ display: "flex" }}>
					<Homework style={{ marginRight: 4, color: theme.palette.primaryResponse.main }} />
					<Typography
						style={{ color: theme.palette.secondaryResponse.main, fontSize: 20, fontWeight: 600, paddingLeft: 2 }}
						variant="h2"
					>
						Associated Churches
					</Typography>
				</div>
			</DialogTitle>
			<DialogContent style={{ color: theme.palette.secondaryResponse.main }}>
				Here are your associated churches. You can switch to another church using the interface below.
				<Typography variant="subtitle1" style={{ marginTop: 8, marginBottom: 8 }}>
					<b>Active Church:</b>&nbsp;{user.activeChurchName}
				</Typography>
				<div className={classes.churchContainer}>
					{userChurches
						.filter((a) => a.churchId.replace(/-/g, "") !== user.activeChurchId)
						.map((church: Church, index: number) => {
							return buildListBox(church, index);
						})}
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="default" size="medium" onClick={() => props.onClose()} disabled={disabled}>
					Cancel
				</Button>
				<Button
					variant="outlined"
					color="primary"
					size="medium"
					disabled={disabled || !selectedChangeChurch}
					onClick={() => submitChange()}
				>
					Change Church
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AssociatedChurchListDialog;
