import React from "react";
import { Journey, JourneyCategory } from "../entities/Journey";


export interface JourneyNotificationSubscription {
    subscriptionId: string;
    journeyId: string;
    unreadCount: number;
    journeyName: string;
    journeyCategory: JourneyCategory;
    lastMessageReceivedTime?: Date
}

interface NotificationContext {
    journeyNotificationSubscriptions: JourneyNotificationSubscription[];
    markJourneyAsRead: (subsciptionId: string) => void;
    subcribeToJourneyNotifications: (journey: Journey) => Promise<unknown>;
    unsubcribeToJourneyNotifications: (subscriptionId: string, journey: Journey)  => Promise<unknown>;
}

export const JourneyNotificationsContext = React.createContext<NotificationContext>({} as any);

export function useJourneyNotifications() {
	return React.useContext(JourneyNotificationsContext);
}
