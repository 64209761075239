import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ResponseDialogActions } from "../../components/ResponseDialog/Layouts/ResponseDialogActions";
import { ResponseDialogContent } from "../../components/ResponseDialog/Layouts/ResponseDialogContent";
import { VerticalCenter } from "../../components/VerticalCenter";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useSuccessAlert } from "../../hooks/useSuccessAlert";
import { AddNewAttendeeForm, AddNewAttendeeRequest } from "../../pages/Groups/AddGroupMemberDialog/AddNewAttendeeForm";
import { useAttendees } from "../../providers/AttendeeProvider";
import AttendeeService, { InviteNewContactToChurchRequest } from "../../services/AttendeeService";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationError } from "../../services/server/ServerValidationError";
import { StringHelper } from "../../utillity/StringHelper";

interface AddPeopleViewProps {
	onClose: () => void;
	onBack: () => void;
}

export default function AddPeopleView(props: AddPeopleViewProps) {
	const attendeeContext = useAttendees();
	const setErrorAlert = useServerErrorAlert();
	const setSuccessAlert = useSuccessAlert();

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [addAttendeeRequest, setAddAttendeeRequest] = useState<AddNewAttendeeRequest | null>(null);
	const [addAttendeeRequestErrors, setAddAttendeeRequestErrors] = useState<FieldValidationError[]>([]);

	const onSubmit = async () => {
		if (!addAttendeeRequest) return;

		const request: InviteNewContactToChurchRequest = {
			firstName: StringHelper.toTitleCase(addAttendeeRequest.firstName),
			lastName: StringHelper.toTitleCase(addAttendeeRequest.lastName),
			phoneNumber: `+1${addAttendeeRequest.phoneNumber.replace(/\D/g, "")}`,
			email: addAttendeeRequest.email,
		};

		setIsDisabled(true);
		const result = await AttendeeService.inviteNewContactToChurch(request);
		setIsDisabled(false);

		if (ServerResult.isValidationError(result)) return setAddAttendeeRequestErrors(result.errors);

		if (ServerResult.isError(result)) return setErrorAlert(result);

		attendeeContext.create(result.data);
		setSuccessAlert("Invite Sent");
		props.onClose();
	};

	return (
		<>
			<ResponseDialogContent>
				<VerticalCenter>
					<Typography align="center" variant="body2" style={{ fontSize: 22, paddingBottom: 20 }}>
						Who would you like to invite?
					</Typography>
					<AddNewAttendeeForm
						disabled={isDisabled}
						errors={addAttendeeRequestErrors}
						onRequestUpdated={setAddAttendeeRequest}
					/>
				</VerticalCenter>
			</ResponseDialogContent>
			<ResponseDialogActions
				onConfirm={onSubmit}
				onBack={props.onBack}
				nextButtonText={"Invite"}
				backButtonText={"Back"}
				hideSecondaryButton={true}
				disabled={isDisabled}
			/>
		</>
	);
}
