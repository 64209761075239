import { Typography } from "@material-ui/core";
import React from "react";
import { useSignUpListStyles } from "./SignupListAnswerDetail";

export function RSVPAnswerDetail() {
	const classes = useSignUpListStyles();

	return (
		<div style={{ marginLeft: 4 }}>
			<Typography className={classes.infoHeader} variant="subtitle1">
				Max per person is 20
			</Typography>
		</div>
	);
}
