import {
	Checkbox,
	FormControl,
	Grid,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Attendee, { printAttendeeStatus } from "../../../entities/Attendee";
import { formatPhoneNumber } from "../../../functions/prettyStrings";
import { useAttendees } from "../../../providers/AttendeeProvider";
import { orderBy, SortDirection } from "../../../utillity/orderBy";
import { usePeopleRouter } from "../PeoplePage";
import CheckedIcon from "@material-ui/icons/CheckBox";
import NotCheckedIcon from "@material-ui/icons/CheckBoxOutlined";
import { useGroups } from "../../../providers/GroupProvider";
import { Group } from "../../../entities/Groups/Group";
import { GridGrow } from "../../../components/GridGrow";

const usePeopleListingStyle = makeStyles((theme: Theme) => ({
	setUpContainer: {
		minHeight: 400,
		paddingLeft: 48,
		paddingRight: 48,
		[theme.breakpoints.down("md")]: {
			paddingLeft: 16,
			paddingRight: 16,
		},
	},
	largeBlueDivider: {
		height: 3,
		width: "100%",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	dateSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
		[theme.breakpoints.down("md")]: {
			width: 240,
		},
	},
	pulsingCheckBox: {
		animation: "$greenPulse 2s infinite",
		borderRadius: "50%"
	},
	'@keyframes greenPulse': {
		"0%": {
			boxShadow: `0 0 0 0 ${fade(theme.palette.secondary.main , 1)}`,
			
		},
		'70%': {
			boxShadow: `0 0 0 10px ${fade(theme.palette.secondary.main , 0)}`,
		},
		'100%': {
			boxShadow: `0 0 0 0 ${fade(theme.palette.secondary.main , 0)}`,
		}
	},
}));

interface PeopleListingTableProps {
	showWarning: boolean;
}

const PeopleListingTable = (props: PeopleListingTableProps) => {
	const classes = usePeopleListingStyle();
	const theme = useTheme();
	const peopleRouter = usePeopleRouter();
	const attendeeContext = useAttendees();
	const groupContext = useGroups();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
	const { groups } = groupContext;
	const { attendees } = attendeeContext;
	const { selectedAttendees } = peopleRouter;

	const [searchText, startSearchPeople] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");
	const [sortField, setSortField] = useState<"name" | "phoneNumber" | "email">("name");
	const [filteredAttendees, setFilteredAttendees] = useState(attendees);
	const [filterByGroup, setFilterByGroup] = useState<Group | null>(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	useEffect(() => {
		let filteredAttendees = [...attendees];

		if(filterByGroup){
			filteredAttendees = filteredAttendees.filter(a => filterByGroup.groupMembers.some(m => m.attendeeId === a.id))
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredAttendees = filteredAttendees.filter((response) =>
				(response.name + response.phoneNumber + response.email + printAttendeeStatus(response)).toLowerCase().includes(queryText)
			);
		}

		switch (sortField) {
			case "name":
				filteredAttendees = filteredAttendees.sort(orderBy.string((a) => a.name, "Ascending"));
				break;
			case "email":
				filteredAttendees = filteredAttendees.sort(orderBy.optional.string((a) => a.email, "Ascending", "optionalEnd"));
				break;
			case "phoneNumber":
				filteredAttendees = filteredAttendees.sort(orderBy.string((a) => a.phoneNumber, "Ascending"));
				break;
		}

		if(sortDirection === "Descending")
		filteredAttendees = filteredAttendees.reverse();
		
		setFilteredAttendees(filteredAttendees);
		setPage(0);
	}, [searchText, sortDirection, attendees, sortField, filterByGroup]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setFilterByGroup(groups.find(g => g.id === filterByGroup?.id) ?? null), [groups])
	
	const handleSelectAllAttendees = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked)
		  return peopleRouter.selectAttendees(filteredAttendees);
		peopleRouter.selectAttendees([]);
	};
	
	const handleSelectAttendee = (selectedAttendee: Attendee) => {
		if(selectedAttendees.includes(selectedAttendee))
			return peopleRouter.selectAttendees(selectedAttendees.filter(a => a !== selectedAttendee))
		peopleRouter.selectAttendees([...selectedAttendees, selectedAttendee]);
	};

	return (
		<Grid item xs={9}>
		<Paper>
		<div className={classes.setUpContainer}>
			<Grid container>
				<Grid container>
					<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
						<Typography style={{ paddingTop: 12, fontWeight: "bold" }} variant="h3">
							{filteredAttendees.length} {filteredAttendees.length === 1 ? "Person" : "People" }
						</Typography>
					</Grid>
					<GridGrow />
					<Grid item>
					<TextField
						id="outlined-bare"
						defaultValue={searchText}
						margin="normal"
						variant="outlined"
						className={classes.searchBar}
						onChange={(e) => startSearchPeople(e.currentTarget.value)}
						size="small"
						InputProps={{
							startAdornment: <Search style={{ marginLeft: -6 }} />,
						}}
						/>
					</Grid>
					<hr className={classes.largeBlueDivider} />
				</Grid>
				<Grid container>
					<Grid item style={{alignSelf: "center", }}>
						<Typography variant="body1" display="inline" style={{textAlign: "center", verticalAlign: "sub"}}>Filter By: </Typography>
						<FormControl>
							<Select
								disableUnderline
								labelId="role-select-label"
								id="role-select"
								className={classes.dateSelector}
								onChange={(event) => {
									const value = event.target.value as string;
									if(value === "All")
									return setFilterByGroup(null);
									const selectedGroup = groups.findIndex(g => g.id === value)
									return setFilterByGroup(groups[selectedGroup])
								}}
								defaultValue="All"
								>
								<MenuItem value={"All"}> 
									All
								</MenuItem>
								{groups.map((group) => {
									return (
										<MenuItem key={group.id} value={group.id}> 
											{group.name}
										</MenuItem>
									)})
								}
							</Select>
						</FormControl>
					</Grid>
					<GridGrow />
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={attendees.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
						onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
							setRowsPerPage(parseInt(event.target.value, 10));
							setPage(0);
						}}
						labelRowsPerPage="Show"
					/>
				</Grid>
				<Grid item xs={12} style={{ marginTop: 8 }}>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table" size={mobileFormFactor ? "small" : "medium"} >
							<TableHead>
								<TableRow>
									<TableCell padding="none">
										<Checkbox
											icon={<NotCheckedIcon className={props.showWarning ? classes.pulsingCheckBox : undefined}/>}
											checkedIcon={<CheckedIcon />}
											onChange={handleSelectAllAttendees}
											checked={selectedAttendees.length !== 0}
											color="default"
										/>
									</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "name" ? (sortDirection === "Ascending" ? "asc" : "desc") : false
										}
									>
										<TableSortLabel
											active={sortField === "name"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("name");
												setSortDirection(sortDirection === "Ascending" ? "Descending" : "Ascending");
											}}
										>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">Status</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "phoneNumber" ? (sortDirection === "Ascending" ? "asc" : "desc") : false
										}
									>
										<TableSortLabel
											active={sortField === "phoneNumber"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("phoneNumber");
												setSortDirection(sortDirection === "Ascending" ? "Descending" : "Ascending");
											}}
										> Phone 
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="left"
										sortDirection={
											sortField === "email" ? (sortDirection === "Ascending" ? "asc" : "desc") : false
										}
									>
										<TableSortLabel
											active={sortField === "email"}
											direction={sortDirection === "Ascending" ? "asc" : "desc"}
											onClick={() => {
												setSortField("email");
												setSortDirection(sortDirection === "Ascending" ? "Descending" : "Ascending");
											}}
										>
											Email
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredAttendees.length === 0 && (
									<TableRow>
										<TableCell padding="none" />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
									</TableRow>
								)}
								{(rowsPerPage > 0
									? filteredAttendees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: filteredAttendees
								).map((attendee: Attendee, index: number) => {
									const isSelected = selectedAttendees.includes(attendee);
									return (
										<TableRow
											key={index}
										>
											<TableCell padding="none">
												<Checkbox
													icon={<NotCheckedIcon className={props.showWarning ? classes.pulsingCheckBox : undefined}/>}
													checkedIcon={<CheckedIcon />}
													onChange={() => handleSelectAttendee(attendee)}
													checked={isSelected}
													style={{opacity: isSelected ? 1 : 0.5}}
													color="default"
												/>
											</TableCell>
											<TableCell>
												<span
													onClick={() => peopleRouter.selectAttendee(attendee)} 
													style={{ color: "rgb(25, 118, 210)", textDecoration: "underline", cursor: "pointer" }}
												>
													{attendee.name}
												</span>
											</TableCell>
											<TableCell>{printAttendeeStatus(attendee)}</TableCell>
											<TableCell>{formatPhoneNumber(attendee.phoneNumber)}</TableCell>
											<TableCell>{attendee.email}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</div>
	</Paper>
	</Grid>
	);
};

export default PeopleListingTable;
