import {
	Box,
	Button,
	Collapse,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Warning from "@material-ui/icons/WarningRounded";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { SubscriptionStatus } from "../../entities/ResponseSubscription";
import { Role } from "../../entities/Role";
import { formatPhoneNumber } from "../../functions/prettyStrings";
import { useAppUser } from "../../hooks/useAppUser";
import { useServerErrorAlert } from "../../hooks/useServerErrorAlert";
import { useUser } from "../../hooks/useUser";
import { useAdminToolsContext } from "../../providers/AdminToolsProvider";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";
import { ChurchMetrics } from "../../services/ImpersonationMetricService";
import { ServerResult } from "../../services/server/ServerResult";
import { orderBy, SortDirection } from "../../utillity/orderBy";
import { useAdminRouter } from "./AdminToolsPage";

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: 16,
	},
	pageHeader: {
		color: theme.palette.secondaryResponse.main,
		marginBottom: 40,
	},
	toolbar: theme.mixins.toolbar,
	toolbarCustom: {
		[theme.breakpoints.down("sm")]: {
			// backgroundColor: "#FFF",
			minHeight: 110,
		},
	},
	searchBar: {
		width: 400,
		"& fieldset": {
			borderRadius: 8,
		},
		[theme.breakpoints.down("md")]: {
			width: 240,
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	dateSelector: {
		color: theme.palette.secondaryResponse.main,
		minWidth: 115,
		"& > svg": {
			color: theme.palette.secondaryResponse.main,
		},
	},
	warning: {
		color: "#d37833",
		backgroundColor: "#fbefe4",
		border: "1px solid #d37833",
		borderRadius: 4,
		padding: 10,
		fontSize: 15,
		marginBottom: 12,
		display: "flex",
	},
	cardGlowEffect: {
		webkitBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		MozBoxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
		boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
	},
	cardMainInfoText: {
		fontWeight: 600,
		fontSize: 16,
		width: "100%",
		marginBottom: 6,
		opacity: 9,
	},
	cardInfoText: {
		fontSize: 14,
		fontWeight: 400,
	},
}));

enum Sort {
	NameAsc = 0,
	NameDesc = 1,
	AttendeesAsc = 2,
	AttendeesDesc = 3,
	JourneysAsc = 4,
	JourneysDesc = 5,
}

export function ImpersonateChurch(props: {}) {
	const adminToolsContext = useAdminToolsContext();

	const classes = useStyles();
	const setErrorAlert = useServerErrorAlert();
	const [user] = useAppUser();
	const [, setJwt] = useUser();
	const theme = useTheme();
	const mobileFormFactor = useMediaQuery(theme.breakpoints.down("md"));
	const [searchText, startSearchPeople] = useState("");
	const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");
	const [sortField, setSortField] = useState<"name" | "attendees" | "journeys">("name");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [churches, setChurches] = useState<ChurchMetrics[]>(adminToolsContext.churches);
	const [filteredChurches, setFilteredChurches] = useState<ChurchMetrics[]>([]);
	const [statusFilter, setStatusFilter] = useState<SubscriptionStatus>(NaN);

	useEffect(() => setChurches(adminToolsContext.churches), [adminToolsContext])

	useEffect(() => {
		if (!churches) {
			return;
		}

		let filteredChurches = [...churches];

		if (filteredChurches === undefined) {
			return;
		}
		
		if (statusFilter) {
			filteredChurches = filteredChurches.filter((a) => a.status === statusFilter);
		}

		if (searchText.length > 0) {
			let queryText = searchText.toLowerCase();
			filteredChurches = filteredChurches.filter((response) =>
				(response.name + response.phoneNumber + response.responsePhoneNumber).toLowerCase().includes(queryText)
			);
		}

		if (sortField === "name") {
			filteredChurches.sort(orderBy.string((a) => a.name, sortDirection));
		} else if (sortField === "attendees") {
			filteredChurches.sort(orderBy.number((a) => a.attendeeCount, sortDirection));
		} else if (sortField === "journeys") {
			filteredChurches.sort(orderBy.number((a) => a.journeyCount, sortDirection));
		}

		setFilteredChurches(filteredChurches);
		setPage(0);
	}, [searchText, sortDirection, churches, sortField, statusFilter]);

	const onChurchSelected = async (id: string) => {
		const result = await AuthService.updateChurch(id);
		if (ServerResult.isSuccess(result)) {
			setJwt(result.data.jwtToken);
			window.location.href = routes.app.dashBoardPage;
		} else {
			setErrorAlert(result);
		}
	};

	const setSortAndDir = (sort: Sort) => {
		switch (sort) {
			case Sort.NameAsc:
				setSortField("name");
				setSortDirection("Ascending");
				break;
			case Sort.NameDesc:
				setSortField("name");
				setSortDirection("Descending");
				break;
			case Sort.AttendeesAsc:
				setSortField("attendees");
				setSortDirection("Ascending");
				break;
			case Sort.AttendeesDesc:
				setSortField("attendees");
				setSortDirection("Descending");
				break;
			case Sort.JourneysAsc:
				setSortField("journeys");
				setSortDirection("Ascending");
				break;
			case Sort.JourneysDesc:
				setSortField("journeys");
				setSortDirection("Descending");
				break;
		}
	};

	const setSortValue = (field: string, dir: SortDirection) => {
		if (field === "name" && dir === "Ascending") {
			return Sort.NameAsc;
		} else if (field === "name" && dir === "Descending") {
			return Sort.NameDesc;
		} else if (field === "attendees" && dir === "Ascending") {
			return Sort.AttendeesAsc;
		} else if (field === "attendees" && dir === "Descending") {
			return Sort.AttendeesDesc;
		} else if (field === "journeys" && dir === "Ascending") {
			return Sort.JourneysAsc;
		} else if (field === "journeys" && dir === "Descending") {
			return Sort.JourneysDesc;
		}
		return Sort.NameDesc
	};

	if (user.role !== Role.UberAdmin) {
		return null;
	}

	return (
		<div style={{ padding: 16 }}>
			<PageTitle title="Change Church" />
			{mobileFormFactor && <div className={classNames(classes.toolbar, classes.toolbarCustom)} />}
			<Typography className={classes.pageHeader} variant="h1">
				All Churches
			</Typography>
			<Paper className={classes.paper}>
				<Grid container>
					<Grid item xs={12}>
						<div className={classes.warning}>
							<div style={{ width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Warning style={{ fontSize: 28 }} />
							</div>
							<div>
								Warning! Impersonation in its current form should only be used to <b>View</b> church and not to
								perform any action against like "Create Journey", "Invite Admin", etc. Churches that have not
								completed the Stripe payment step are not shown below.
							</div>
						</div>
					</Grid>
					{!mobileFormFactor && (
						<>
							<Grid item xs={12} md={4} style={{ display: "flex", textAlign: "left", marginTop: 16 }}>
								<Grid item xs={2} style={{ textAlign: "left", marginTop: 16, minWidth: 120 }}>
									<FormControl>
										<Select
											disableUnderline
											labelId="role-select-label"
											id="role-select"
											value={statusFilter}
											className={classes.dateSelector}
											onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
												setStatusFilter(parseInt(event.target.value as string));
											}}
										>
											<MenuItem value={NaN}>All Statuses</MenuItem>
											<MenuItem value={SubscriptionStatus.Active}>Active</MenuItem>
											<MenuItem value={SubscriptionStatus.Trialing}>Trialing</MenuItem>
											<MenuItem value={SubscriptionStatus.Canceled}>Cancelled</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={2} style={{ textAlign: "left", marginTop: 16 }}>
									<FormControl>
										<Select
											disableUnderline
											labelId="role-select-label"
											id="role-select"
											value={setSortValue(sortField, sortDirection)}
											className={classes.dateSelector}
											onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
												setSortAndDir(parseInt(event.target.value as string))
											}
										>
											<MenuItem value={Sort.NameAsc}>Name Asc</MenuItem>
											<MenuItem value={Sort.NameDesc}>Name Desc</MenuItem>
											<MenuItem value={Sort.AttendeesAsc}>Attendee Asc</MenuItem>
											<MenuItem value={Sort.AttendeesDesc}>Attendee Desc</MenuItem>
											<MenuItem value={Sort.JourneysAsc}>Journeys Asc</MenuItem>
											<MenuItem value={Sort.JourneysDesc}>Journeys Desc</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</>
					)}
					<Grid item xs={12} md={4} style={{ textAlign: "left" }}>
						<TextField
							id="outlined-bare"
							defaultValue={searchText}
							margin="normal"
							variant="outlined"
							className={classes.searchBar}
							onChange={(e) => startSearchPeople(e.currentTarget.value)}
							size="small"
							InputProps={{
								startAdornment: <Search style={{ marginLeft: -6 }} />,
							}}
						/>
					</Grid>

					{mobileFormFactor && (
						<>
							<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
								<FormControl>
									<Select
										disableUnderline
										labelId="role-select-label"
										id="role-select"
											value={statusFilter ? statusFilter : "All Statuses"}
										className={classes.dateSelector}
										onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
											setStatusFilter(parseInt(event.target.value as string));
										}}
									>
										<MenuItem value={NaN}>All Statuses</MenuItem>
										<MenuItem value={SubscriptionStatus.Active}>Active</MenuItem>
										<MenuItem value={SubscriptionStatus.Trialing}>Trialing</MenuItem>
										<MenuItem value={SubscriptionStatus.Canceled}>Cancelled</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} style={{ textAlign: "left", marginTop: 16 }}>
								<FormControl>
									<Select
										disableUnderline
										labelId="role-select-label"
										id="role-select"
										value={setSortValue(sortField, sortDirection)}
										className={classes.dateSelector}
										onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
											setSortAndDir(parseInt(event.target.value as string))
										}
									>
										<MenuItem value={Sort.NameAsc}>Name Asc</MenuItem>
										<MenuItem value={Sort.NameDesc}>Name Desc</MenuItem>
										<MenuItem value={Sort.AttendeesAsc}>Attendee Asc</MenuItem>
										<MenuItem value={Sort.AttendeesDesc}>Attendee Desc</MenuItem>
										<MenuItem value={Sort.JourneysAsc}>Journeys Asc</MenuItem>
										<MenuItem value={Sort.JourneysDesc}>Journeys Desc</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</>
					)}

					<Grid item xs={12} md={4} />
					<Grid item xs={12} style={{ marginTop: 8 }}>
						{!mobileFormFactor && (
							<>
								<TableContainer>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												<TableCell />
												<TableCell />
												<TableCell />
												<TableCell
													align="left"
													sortDirection={
														sortField === "name"
															? sortDirection === "Ascending"
																? "asc"
																: "desc"
															: false
													}
												>
													<TableSortLabel
														active={sortField === "name"}
														direction={sortDirection === "Ascending" ? "asc" : "desc"}
														onClick={() => {
															setSortField("name");
															setSortDirection(
																sortDirection === "Ascending" ? "Descending" : "Ascending"
															);
														}}
													>
														Name
													</TableSortLabel>
												</TableCell>
												<TableCell>Status</TableCell>
												<TableCell
													align="right"
													sortDirection={
														sortField === "attendees"
															? sortDirection === "Ascending"
																? "asc"
																: "desc"
															: false
													}
												>
													<TableSortLabel
														active={sortField === "attendees"}
														direction={sortDirection === "Ascending" ? "asc" : "desc"}
														onClick={() => {
															setSortField("attendees");
															setSortDirection(
																sortDirection === "Ascending" ? "Descending" : "Ascending"
															);
														}}
													>
														Attendees
													</TableSortLabel>
												</TableCell>
												<TableCell
													align="right"
													sortDirection={
														sortField === "journeys"
															? sortDirection === "Ascending"
																? "asc"
																: "desc"
															: false
													}
												>
													{" "}
													<TableSortLabel
														active={sortField === "journeys"}
														direction={sortDirection === "Ascending" ? "asc" : "desc"}
														onClick={() => {
															setSortField("journeys");
															setSortDirection(
																sortDirection === "Ascending" ? "Descending" : "Ascending"
															);
														}}
													>
														Journeys
													</TableSortLabel>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{filteredChurches.length === 0 && (
												<TableRow>
													<TableCell />
													<TableCell />
													<TableCell />
													<TableCell>
														<Typography style={{ paddingTop: 24 }} variant="h4">
															No churches matched your search criteria
														</Typography>
													</TableCell>
												</TableRow>
											)}
											{(rowsPerPage > 0
												? filteredChurches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
												: filteredChurches
											).map((church: ChurchMetrics, index: number) => {
												return (
													<Row
														key={church.churchId}
														church={church}
														onClick={(id: string) => onChurchSelected(id)}
													/>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[10, 25, 50]}
									component="div"
									count={filteredChurches.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
									onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
										setRowsPerPage(parseInt(event.target.value, 10));
										setPage(0);
									}}
								/>
							</>
						)}
						{mobileFormFactor &&
							filteredChurches.map((church: ChurchMetrics, index: number) => {
								return (
									<Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }} key={index}>
										<div
											className={classes.cardGlowEffect}
											style={{
												backgroundColor: "#FFF",
												borderRadius: 8,
												width: "100%",
												padding: 12,
											}}
										>
											<Typography variant="h6" className={classes.cardMainInfoText}>
												{church.name}
											</Typography>
											<Grid container>
												<Grid item xs={6}>
													<Typography variant="h6" className={classes.cardInfoText}>
														<b style={{ opacity: 0.8 }}>Church:</b>
													</Typography>
													<Typography variant="h6" className={classes.cardInfoText}>
														{formatPhoneNumber(church.phoneNumber)}
													</Typography>
													<Typography variant="h6" className={classes.cardInfoText}>
														<b style={{ opacity: 0.8 }}>Response:</b>
													</Typography>
													<Typography variant="h6" className={classes.cardInfoText}>
														{church.responsePhoneNumber
															? formatPhoneNumber(church.responsePhoneNumber)
															: "Not Set"}
													</Typography>
													<Typography variant="h6" className={classes.cardInfoText}>
														<b style={{ opacity: 0.8 }}>Status:</b>
													</Typography>
													<Typography variant="h6" className={classes.cardInfoText}>
														{SubscriptionStatus[church.status]}
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography
														variant="h6"
														style={{ textAlign: "right" }}
														className={classes.cardInfoText}
													>
														<b style={{ opacity: 0.8 }}>Attendees:</b> {church.attendeeCount}
													</Typography>
													<Typography
														variant="h6"
														style={{ textAlign: "right" }}
														className={classes.cardInfoText}
													>
														<b style={{ opacity: 0.8 }}>Journeys:</b> {church.journeyCount}
													</Typography>
												</Grid>
											</Grid>
										</div>
									</Grid>
								);
							})}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}

function Row(props: { church: ChurchMetrics; onClick: (id: string) => void }) {
	const { church, onClick } = props;
	const [open, setOpen] = React.useState(false);
	const adminToolsRouter = useAdminRouter();

	return (
		<React.Fragment>
			<TableRow hover onClick={() => setOpen(!open)}>
				<TableCell padding="checkbox">
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell padding="checkbox">
					<Button variant="contained" size="small" color="primary" onClick={() => onClick(church.churchId)}>
						Impersonate
					</Button>
				</TableCell>
				<TableCell padding="checkbox">
					<Button variant="contained" size="small" color="primary" onClick={() => adminToolsRouter.selectChurch(church)}>
						Details
					</Button>
				</TableCell>
				<TableCell>{church.name}</TableCell>
				<TableCell>{SubscriptionStatus[church.status]}</TableCell>
				<TableCell align="right">{church.attendeeCount}</TableCell>
				<TableCell align="right">{church.journeyCount}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Details
							</Typography>
							<Typography variant="subtitle1">
								<b style={{ opacity: 0.8 }}>Church Phone Number:</b>&nbsp;{formatPhoneNumber(church.phoneNumber)}
							</Typography>
							<Typography variant="subtitle1">
								<b style={{ opacity: 0.8 }}>Response Phone Number:</b>&nbsp;
								{church.responsePhoneNumber ? formatPhoneNumber(church.responsePhoneNumber) : "Not Set"}
							</Typography>
							<Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
								<b style={{ opacity: 0.8 }}>Status:</b>&nbsp;{SubscriptionStatus[church.status]}
							</Typography>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
