import { CreateJourneyType, JourneySetupProps } from "../components/JourneySetup/journeySetUp";
import { EventSchedule, InformationalJourney, Journey, JourneyCategory, JourneyType, PromptJourney } from "../entities/Journey";
import { AddDaysReturnDate } from "../functions/datetime";
import { Enum } from "./Enum";

export const JourneyInitializer = {
	getInitialJourney: (props: JourneySetupProps): Journey => {
		if (props.mode === "update") {
			return { ...props.journey };
		}

		let unknownJourney: Journey = {
			id: "",
			churchId: "",
			type: JourneyInitializer.getCreatedJourneyType(props.type),
			category: JourneyCategory.Unknown,
			name: Enum.print(CreateJourneyType, props.type),
			enabled: true,
			completionResponse: JourneyInitializer.getInitialCompletionResponse(props.type),
			extras: {},
			keywordTrigger: "",
			followUpEnabled: props.type === CreateJourneyType.Volunteer_Sign_Up,
			hasSubscribers: false,
			dateCreated: new Date(),
		};

		if (props.type === CreateJourneyType.Gather_Emails) {
			const promptJourney: PromptJourney = {
				...unknownJourney,
				type: JourneyType.Gather_Emails,
				category: JourneyCategory.Prompt,
				prompt: "Hi there! May I get your email?",
			};
			return promptJourney;
		}

		const informationalJourney: InformationalJourney = {
			...unknownJourney,
			category: JourneyCategory.Informational,
		};

		if (props.type === CreateJourneyType.Event_Sign_Up) {
			let today = AddDaysReturnDate(new Date(), 1);
			today.setHours(9);
			today.setMinutes(0);

			informationalJourney.event = {
				originalTime: today,
				nextEventTime: today,
				schedule: EventSchedule.DoesNotRepeat,
			};
			return informationalJourney;
		}

		if (props.type === CreateJourneyType.Group_Sign_Up) {
			let today = AddDaysReturnDate(new Date(), 1);
			today.setHours(9);
			today.setMinutes(0);

			informationalJourney.event = {
				originalTime: today,
				nextEventTime: today,
				schedule: EventSchedule.Weekly,
			};
			return informationalJourney;
		}

		return informationalJourney;
	},
	getInitialCompletionResponse: (type: CreateJourneyType): string => {
		switch (type) {
			case CreateJourneyType.Volunteer_Sign_Up:
				return "Thank you! Someone will reach out to you this following week to plug you in.";
			case CreateJourneyType.Event_Sign_Up:
				return "Awesome, we'll see you {{Event}} at {{Address}}! You will receive reminders before it begins.";
			case CreateJourneyType.Group_Sign_Up:
				return "Hi there! We would like you to join us at {{Event}}! We meet at {{Address}}. You will receive occasional reminders about this group.";
			case CreateJourneyType.Gather_Emails:
				return "Thank you! I have added you to the email list.";
			case CreateJourneyType.Survey_Your_Church:
				return "Thank you, I have recorded your response. {{Form}}";
			case CreateJourneyType.Share_A_Link:
				return "Hi {{First_Name}}, you can find more info here:\n{{Link}}";
			case CreateJourneyType.Custom:
				return "This is a custom journey. Write whatever you want in and add in anything";
		}
	},
	getCreatedJourneyType: (type: CreateJourneyType): JourneyType => {
		switch (type) {
			case CreateJourneyType.Volunteer_Sign_Up:
				return JourneyType.Volunteer_Sign_Up;
			case CreateJourneyType.Event_Sign_Up:
				return JourneyType.Event_Sign_Up;
			case CreateJourneyType.Group_Sign_Up:
				return JourneyType.Group_Sign_Up;
			case CreateJourneyType.Gather_Emails:
				return JourneyType.Gather_Emails;
			case CreateJourneyType.Survey_Your_Church:
				return JourneyType.Survey_Your_Church;
			case CreateJourneyType.Share_A_Link:
				return JourneyType.Share_A_Link;
			case CreateJourneyType.Custom:
				return JourneyType.Custom;
		}
	},
};
