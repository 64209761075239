import { Button, Grid, Hidden, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Auth from "../../authorization/Auth";
import { routes } from "../../routes";
import AuthService from "../../services/AuthService";
import { ServerError } from "../../services/server/ServerError";
import { ServerResult } from "../../services/server/ServerResult";
import { FieldValidationError, FieldValidationErrorUtils } from "../../services/server/ServerValidationError";

export const useSignInStyles = makeStyles((theme: Theme) => ({
	forgotPwPaper: {
		padding: "0px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			margin: "0px",
			padding: "0px 30px",
			paddingTop: "5%",
			height: "100%",
		},
	},
	grid: {
		[theme.breakpoints.down("xs")]: {
			height: "100%",
		},
	},
	signInButton: {
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	responseBlueText: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
	textButton: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
	link: {
		textDecoration: "none",
	},
	buttonContainer: {
		padding: "30px 0px",
	},
	submitButton: {
		minWidth: "150px",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	submitButtonMobile: {
		paddingLeft: "0px",
		paddingRight: "0px",
		backgroundColor: theme.palette.secondaryResponse.main,
	},
}));

const getRedirect = (location: Location) => {
	const urlParams = new URLSearchParams(location.search);
	return urlParams.get("redirect") ?? routes.app.dashBoardPage;
};

export function ResetPassword() {
	const classes = useSignInStyles();
	const [enabled, setEnabled] = useState(true);

	const [signedIn] = useState(Auth.signedIn());
	const [redirect] = useState(getRedirect(window.location));

	const [resetSent, setResetSent] = useState(false);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const [token] = useState(urlParams.get("token") ?? "");
	const [password, setPassword] = useState("");
	const [fieldErrors, setFieldErrors] = useState<FieldValidationError[]>([]);

	const [resetSuccess, setResetSuccess] = useState(false);

	const [serverError, setServerError] = useState<ServerError | undefined>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setFieldErrors([]);

		setEnabled(false);
		const result = await AuthService.resetPassword(password, token);
		setEnabled(true);

		if (ServerResult.isSuccess(result)) {
			setResetSuccess(true);
		} else if(ServerResult.isValidationError(result)){
			setFieldErrors(result.errors)
		} else if (ServerResult.isError(result)) {
			setServerError(result);
		}
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(event.target.value);

	if (signedIn) {
		return <Redirect to={redirect} />;
	}

	if (resetSuccess) {
		return <Redirect to="/" />;
	}

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			{serverError && (
				<Typography variant="h1">
					Server Error: ({serverError.statusCode}) {serverError.message}
				</Typography>
			)}
			<Grid item className={classes.grid}>
				<Paper className={classes.forgotPwPaper}>
					{!resetSent && (
						<>
							<Grid container direction="column" justify="center" alignItems="center">
								<img
									alt="Response"
									src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
									style={{ maxWidth: "100%", height: "auto" }}
								/>
								<Typography variant="h5" style={{ marginTop: 12 }}>
									Reset your Password:
								</Typography>
								<Typography variant="body2" style={{ marginTop: 8 }}>
									Enter your new password below
								</Typography>
							</Grid>
							<form onSubmit={onSubmit}>
								<TextField
									required
									autoFocus
									fullWidth
									id="password"
									label="password"
									type="password"
									margin="normal"
									onChange={onChangePassword}
									error={FieldValidationErrorUtils.isFieldInError(fieldErrors, "Password")}
									helperText={FieldValidationErrorUtils.getFieldErrorSummary(fieldErrors, "Password")}
								/>
								<Hidden smDown>
									<Grid
										container
										direction="row"
										justify="space-between"
										alignItems="center"
										className={classes.buttonContainer}
									>
										<Link to="/" className={classes.link}>
											<Button
												variant="text"
												size="medium"
												color="primary"
												type="button"
												style={{ marginBottom: 12 }}
												className={classes.textButton}
											>
												Cancel
											</Button>
										</Link>
										<Button
											variant="contained"
											size="medium"
											color="primary"
											type="submit"
											disabled={!enabled}
											className={classes.submitButton}
										>
											Set New Password
										</Button>
									</Grid>
								</Hidden>
								<Hidden mdUp>
									<Button
										fullWidth
										variant="contained"
										size="medium"
										color="primary"
										type="submit"
										disabled={!enabled}
										style={{ fontWeight: 600 }}
										className={classes.submitButtonMobile}
									>
										Set New Password
									</Button>
									<Link to="/" className={classes.link}>
										<Button
											fullWidth
											variant="contained"
											size="medium"
											color="primary"
											type="button"
											style={{ marginTop: 8 }}
											className={classes.submitButtonMobile}
										>
											Cancel
										</Button>
									</Link>
								</Hidden>
							</form>
						</>
					)}
					{resetSent && (
						<>
							<Grid container direction="column" justify="center" alignItems="center">
								<img
									alt="Response"
									src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
									style={{ maxWidth: "100%", height: "auto" }}
								/>
								<Typography variant="h5" style={{ marginTop: 12 }}>
									Reset Password Link Sent
								</Typography>
								<Typography variant="body2" style={{ marginTop: 8 }}>
									Check your email for a link to reset your password.
								</Typography>
							</Grid>
							<Hidden smDown>
								<Grid
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									className={classes.buttonContainer}
								>
									<Link to="/" className={classes.link}>
										<Button
											variant="text"
											size="medium"
											color="primary"
											type="button"
											className={classes.textButton}
										>
											Sign In
										</Button>
									</Link>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.textButton}
										onClick={() => setResetSent(false)}
									>
										Resend Reset Password
									</Button>
								</Grid>
							</Hidden>
							<Hidden mdUp>
								<Grid container direction="row" justify="space-between" alignItems="center">
									<Link to="/" className={classes.link}>
										<Button
											variant="text"
											size="small"
											color="primary"
											type="button"
											className={classes.textButton}
										>
											Sign In
										</Button>
									</Link>
									<Button
										variant="text"
										size="small"
										color="primary"
										type="button"
										className={classes.textButton}
										onClick={() => setResetSent(false)}
									>
										Resend Reset Password
									</Button>
								</Grid>
							</Hidden>
						</>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}

export default ResetPassword;
